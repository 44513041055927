/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import addMonths from "date-fns/add_months";
import addWeeks from "date-fns/add_weeks";
import { format as formatTime } from "../../utils/formatAudioTime.js";
import { debounce } from "../../utils/helpers";
import { request, csrfHeaders } from "../../utils/request";
import { ToastManager } from "../../utils/Toast";
import { ToptipsManager } from "../../utils/Toptips";
import { Session, CheckedInput, NewSession } from "../../../admin-utils/Select";
const SpanClose = styled.div`
  background: #fff;
  color: #333;
  font-size: 30px;
  padding: 5px 10px;
  border: none;
  margin-top: -10px;
`;
const StyleAddLogModalDialog = styled.div`
  display: block;
  background: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  .modal-dialog {
    margin-top: 10vh !important;
    .flex_checkbox {
      span {
        border-radius: initial;
        border: none;
        padding: 0;
        color: #333;
        background: initial;
        cursor: pointer;
      }
    }
    .glyphicon {
      background: #eee;
      border: none;
      color: #555;
      padding: 3px 0;
    }
    .modal-content .modal-body {
      .bootstrap-datetimepicker-widget {
        .datepicker {
          table {
            thead {
              tr {
                background: #fff;
              }
            }
            tbody tr td {
              height: 20px;
              line-height: 20px;
              width: 20px;
              padding: 5px;
              background: #fff;
              &.active {
                background-color: #337ab7;
                color: #ffffff;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .modal-dialog {
      margin: 30px auto;
    }
  }

  .modal-title {
    text-align: center;
  }
  .flex_check_box {
    display: flex;
    /* flex: 1; */
    /* flex-wrap: no-wrap; */
    margin-right: 15px;
  }
  .flex_check_box span {
    white-space: nowrap;
    font-size: 14px;
    border-radius: initial;
    border: none;
    padding: 0;
    color: #333 !important;
    background: initial;
    cursor: pointer;
  }
  .flex_check_box input {
    margin-right: 12px;
  }
  .flex_check_box {
    .follow_up_type {
      margin-right: 35px;
      width: 60px;
      z-index: 2;
      position: relative;
    }
    .follow_up_type_span {
      margin-left: -50px;
      z-index: 1;
      position: relative;
    }
  }
  .flex_column_style {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex_style_wrapper {
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .flex_style {
    margin-top: 15px;
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .flex_style > select {
    flex: 1;
    max-width: 55%;
    margin-left: 15px;
  }
  .margin_left {
    font-size: 16px;
    margin-left: 15px;
  }
  .line_height {
    line-height: 51px;
  }
  .min_width {
    min-width: 80px;
    color: #888;
    font-size: 14px;
  }
  .flex_style {
    .btn-primary {
      border-radius: 50px;
      width: 100px;
      margin-right: 10px;
    }
    .finish {
      background-color: #fff;
      color: #2e6da4;
    }
  }
  .dig {
    transform: translateY(31%) !important;
    width: 50% !important;
    margin: 0 auto !important;
    height: 50% !important;
  }
  @media screen and (max-width: 414px) {
    .dig {
      width: 98% !important;
    }
  }
`;

const SelectLabel = ({ ...props }) => {
  const { options, emptyOptionHit, setOption } = props;
  const [selected, setSelected] = useState(() => {
    const index = options.findIndex((option) => option.selected);
    return index === -1 ? "" : index;
  });

  useEffect(() => {
    setOption && setOption(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 初始化时间选择插件
  useEffect(() => {
    $("#datetimepicker1").datetimepicker({
      locale: "zh-cn",
      format: "YYYY-MM-DD HH:mm Z",
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: "fa fa-chevron-left",
        next: "fa fa-chevron-right",
        today: "fa fa-check",
        clear: "fa fa-trash",
        close: "fa fa-times",
      },
    });
  }, []);

  return (
    <React.Fragment>
      <select
        className="form-control"
        onChange={(e) => {
          const value = e.target.value;
          setSelected(value);
          setOption && setOption(value);
        }}
        value={selected ?? ""}
      >
        <option value="">{emptyOptionHit}</option>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {option.name}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

const AddLogModalDialog = ({ ...props }) => {
  console.log("AddLogModalDialog_props: ", props);
  const {
    babyExtraInfo: { baby, user },
    addFollowUpLogUrl,
    grades,
    handleCloseModal,
    teachers,
  } = props;

  const [userInfoObject, setUserInfoObject] = useState({}); // 收集”家长情况“单选
  const [userInfoArray, setUserInfoArray] = useState([]); // 收集”家长情况“多选

  const [babyInfoObject, setBabyInfoObject] = useState({}); // 收集”孩子情况“单选
  const [babyInfoArray, setBabyInfoArray] = useState([]); // 收集”孩子情况“多选

  const [newSessions, setNewSessions] = useState(user?.sessions ? [] : [{}]);
  const [sessionsSelected, setSession] = useState(() => ({
    sessions: [],
  }));

  const [chooseUserInfoChecked, setUserInfo] = useState({}); // 家长情况的多选
  const [chooseBabyInfoChecked, setBabyInfo] = useState({}); // 孩子情况的多选
  useEffect(() => {
    console.log(sessionsSelected);
  }, [sessionsSelected]);

  useEffect(() => {
    const arr = [];
    for (const [index, check] of Object.entries(chooseUserInfoChecked)) {
      if (check) {
        arr.push(index);
        setUserInfoArray(arr);
      } else {
        const chooseReasonFilter = Object.values(chooseUserInfoChecked).filter(
          (item) => item != false
        );
        if (chooseReasonFilter.length <= 0) {
          setUserInfoArray([]);
        }
      }
    }
  }, [chooseUserInfoChecked]);
  useEffect(() => {
    const arr = [];
    for (const [index, check] of Object.entries(chooseBabyInfoChecked)) {
      if (check) {
        arr.push(index);
        setBabyInfoArray(arr);
      } else {
        const chooseReasonFilter = Object.values(chooseBabyInfoChecked).filter(
          (item) => item != false
        );
        if (chooseReasonFilter.length <= 0) {
          setBabyInfoArray([]);
        }
      }
    }
  }, [chooseBabyInfoChecked]);

  const addNewSessions = () => {
    setNewSessions((pre) => {
      return pre.concat([{}]);
    });
  };

  const untilTime = [
    {
      time: "一星期",
      value: formatTime(addWeeks(new Date(), 1), "YYYY-MM-DD HH:mm:ss"),
    },
    {
      time: "一个月",
      value: formatTime(addMonths(new Date(), 1), "YYYY-MM-DD HH:mm:ss"),
    },
    {
      time: "三个月",
      value: formatTime(addMonths(new Date(), 3), "YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const [ccLogTime, setCcLogTime] = useState(() =>
    user ? user.cc_log_alert_time : ""
  );
  const [followUpType, setFollowUpType] = useState("");

  var validation = {
    checkTime: function () {
      var alert_time_input = $("#datetimepicker1")
        .data("DateTimePicker")
        .date();
      if (!alert_time_input) {
        ToptipsManager.warn("请填写跟进提醒时间");
      }
      return !!alert_time_input;
    },
    checkLogContent: function () {
      var follow_up_log = $("#follow_up_log").val();
      if (!follow_up_log) {
        ToptipsManager.warn("请填写标记处理的理由");
      }
      return !!follow_up_log;
    },
    checkConfirmOperate: function () {
      return window.confirm("确定标记处理？");
    },

    checkSessionMarkHandle: function () {
      if (
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
      )
        return true;
      const markedSession = sessionsSelected.sessions.filter(
        (ses) => ses.handle
      );
      if (markedSession.length === 0) {
        ToptipsManager.warn("请至少勾选一个事件处理");
        return false;
      }
      return true;
    },

    checkAlertInputTime: function () {
      const see =
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
          ? newSessions
          : sessionsSelected.sessions.filter((ses) => ses.handle);
      console.log("see_checkAlertInputTime", see);
      for (let i = 0; i < see.length; i++) {
        console.log(see[i]);
        if (see[i].state === 0) {
          const alertTime = $("#alert_time_input").val();
          if (!alertTime) {
            ToptipsManager.warn("请填写下次提醒时间");
            return false;
          }
        }
      }

      return true;
    },
    checkSubEndReason: function () {
      const see =
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
          ? newSessions
          : sessionsSelected.sessions.filter((ses) => ses.handle);
      console.log("see_checkSubEndReason", see);
      for (let i = 0; i < see.length; i++) {
        if (see[i].end_reason === 2) {
          if (see[i].sub_end_reason === "") {
            ToptipsManager.warn("请填写选择不购买时的具体理由");
            return false;
          }
        }
      }
      return true;
    },

    checkStateReason: function () {
      const see =
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
          ? newSessions
          : sessionsSelected.sessions.filter((ses) => ses.handle);
      console.log("see_checkStateReason: ", see);
      for (let i = 0; i < see.length; i++) {
        if (
          (see[i].state === 1 &&
            (see[i].end_reason === "" || see[i].end_reason == undefined)) ||
          (see[i].state === 0 &&
            (see[i].in_progress_state === "" ||
              see[i].in_progress_state == undefined))
        ) {
          ToptipsManager.warn("请选择跟进结果");
          return false;
        }
      }
      return true;
    },

    checkSession: function () {
      const see =
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
          ? newSessions
          : sessionsSelected.sessions.filter((ses) => ses.handle);
      console.log("see_checkSession: ", see);
      const isAllUnhandled = see.some(
        (s) => s.state === 2 || s.state === "" || s.state == undefined
      );
      if (isAllUnhandled) {
        ToptipsManager.warn("请选择跟进结果");
        return false;
      }
      return true;
    },
    checkSourceReasons: function () {
      const sourceReasons = Array.from(
        document.getElementsByClassName("source-reason")
      );
      if (sourceReasons.length <= 0) {
        return true;
      }
      const isAllChoosedReason = sourceReasons
        .map((item) => item.value)
        .every((item) => item);
      if (!isAllChoosedReason) {
        ToptipsManager.warn("请选择用户来源");
        return false;
      }
      return true;
    },
    checkFollowUpType: function () {
      if (followUpType === "" && typeof followUpType == "string") {
        ToptipsManager.warn("请选择跟进方式");
        return false;
      }
      return true;
    },
    checkChooseReason: function () {
      const see =
        (newSessions[0] != null || newSessions[0] != undefined) &&
        Object.keys(newSessions[0]).length > 0
          ? newSessions
          : sessionsSelected.sessions.filter((ses) => ses.handle);
      console.log("see_checkChooseReason", see);
      const xinyaReasons = Array.from(
        document.getElementsByClassName("xinya_reason")
      );
      for (let i = 0; i < see.length; i++) {
        if (see[i].end_reason === 1) {
          const isAllFalsely = xinyaReasons.every((reason) => !reason.checked);
          if (isAllFalsely) {
            ToptipsManager.warn("请选择 选择心芽的原因");
            return false;
          }
        }
        if (see[i].in_progress_state === 11) {
          const isAllFalsely = xinyaReasons.every((reason) => !reason.checked);
          if (isAllFalsely) {
            ToptipsManager.warn("请选择 选择心芽的原因");
            return false;
          }
        }
      }
      return true;
    },
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAddFollowUPLog = (validationArr, extraParams) => {
    if (Array.isArray(validationArr)) {
      for (var i = 0; i < validationArr.length; i++) {
        if (typeof validationArr[i] === "function") {
          if (!validationArr[i]()) {
            return;
          }
        }
      }
    }

    const dataPickerDate = $("#datetimepicker1")
      ?.data("DateTimePicker")
      ?.date();

    const defaultAlertTimeInput = $("#alert_time_input").val()
      ? Math.floor(new Date($("#alert_time_input").val()).getTime() / 1000)
      : "";
    const ClickPickDate = dataPickerDate ? dataPickerDate.unix() : "";
    var alert_time_input = defaultAlertTimeInput || ClickPickDate;
    const targetUserId = user.id;
    var follow_up_log = $("#follow_up_log").val();
    var baby_grade = $("#baby_grade").val();

    var user_no_alert = $("#user_no_alert").val();
    const user_teacher = $("#user_teacher")?.val();
    const study_package_category_id = $("#study_package_category_id")?.val?.();

    const data = {
      user_id: Number(targetUserId),
      log_note: follow_up_log,
      grade: baby_grade == "" ? "" : baby_grade,
      alert_until_time: user_no_alert == "" ? "" : user_no_alert,
      cc_log_alert_time: alert_time_input,
      teacher_id: user_teacher ? user_teacher * 1 : "",
      study_package_category_id: study_package_category_id
        ? study_package_category_id * 1
        : "",
      sessions: sessionsSelected.sessions.filter((ses) => ses.handle),
      new_sessions: newSessions,
      // 选中的id列表
      user_info: {
        form_field_value_ids: [
          ...Object.values(userInfoObject).join(",").split(","),
          ...userInfoArray,
        ],
      },
      baby_info: {
        form_field_value_ids: [
          ...Object.values(babyInfoObject).join(",").split(","),
          ...babyInfoArray,
        ],
      },
      follow_up_type: followUpType,
      ...extraParams,
    };
    console.log("518_data: ", data);
    const toast = ToastManager.showLoading("拼命加载中...");
    request({
      url: `${addFollowUpLogUrl}`,
      method: "POST",
      headers: csrfHeaders,
      data,
    })
      .then((resp) => {
        console.log("data: ", resp);
        const data = resp.data;
        toast.cancel();
        if (data.success) {
          console.log("success");
          window.location.reload(true);
        } else if (!data.success) {
          console.log("error message");
          ToptipsManager.warn(data.message);
        }
        handleCloseModal();
        ToastManager.showLoading("数据提交中...");
      })
      .catch((_err) => {
        toast.cancel();
        ToptipsManager.warn("操作出错");
      });
  };

  const handleFollowUpLog = () => {
    if (!user?.operat_auth) {
      ToptipsManager.warn(
        "无操作权限，您若是课程顾问，此用户可能绑定在其他老师下"
      );
      return;
    }
    const baseParams = {};
    handleAddFollowUPLog(
      [
        validation.checkLogContent,
        validation.checkFollowUpType,
        validation.checkSessionMarkHandle,
        validation.checkSourceReasons,
        validation.checkSession,
        validation.checkStateReason,
        validation.checkSubEndReason,
        validation.checkChooseReason,
        validation.checkAlertInputTime,
      ],
      baseParams
    );
  };

  const handleAddExtraLog = () => {
    if (!user?.operat_auth) {
      ToptipsManager.warn(
        "无操作权限，您若是课程顾问，此用户可能绑定在其他老师下"
      );
      return;
    }
    const baseParams = {
      append_log: true,
    };
    handleAddFollowUPLog(
      [validation.checkLogContent, validation.checkFollowUpType],
      baseParams
    );
  };

  const handleTransformLog = () => {
    if (!user?.operat_auth) {
      ToptipsManager.warn(
        "无操作权限，您若是课程顾问，此用户可能绑定在其他老师下"
      );
      return;
    }
    const baseParams = {
      transfer: true,
    };
    handleAddFollowUPLog([], baseParams);
  };

  const handleAlertTimeInput = (e) => {
    const ccLogTime = e.target.value;
    setCcLogTime(ccLogTime);
  };

  const handleClickDatetimepickerIcon = (e) => {
    e.preventDefault();
  };

  // useEffect(() => {
  //   console.log("---obj--userInfoObject", userInfoObject);
  //   console.log("---arr--userInfoArray", userInfoArray);

  //   console.log("+++obj++babyInfoObject", babyInfoObject);
  //   console.log("+++arr++babyInfoArray", babyInfoArray);
  // }, [userInfoObject, babyInfoObject, userInfoArray, babyInfoArray]);

  return (
    <StyleAddLogModalDialog id="addLogModal" className="modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <button
              className="close"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
              onClick={handleCloseModal}
            >
              <SpanClose aria-hidden="true">&times;</SpanClose>
            </button>
            <h4>家长情况：</h4>
            {user?.user_info?.label_groups?.length > 0 &&
              user?.user_info?.label_groups.map((label_group, index) => (
                <div className="flex_style" key={index}>
                  <div className="min_width">{label_group.name}</div>
                  {label_group?.labels.map((label, index) => (
                    <SelectLabel
                      key={index}
                      options={label?.options}
                      emptyOptionHit={label.name}
                      setOption={(index) => {
                        const state = label?.options[index];
                        if (state?.id != undefined) {
                          setUserInfoObject((pre) => ({
                            ...pre,
                            [label.id]: state.id,
                          }));
                        }
                      }}
                    />
                  ))}
                </div>
              ))}
            {user?.user_info?.labels?.length > 0 &&
              user?.user_info?.labels.map((label, index) => (
                <div className="flex_style" key={index}>
                  <div className="min_width" style={{ width: "120px" }}>
                    {label.name}
                  </div>
                  <div
                    className="flex_style"
                    style={{ flexWrap: "wrap", marginTop: "0" }}
                  >
                    {label?.options.map((option, index) => (
                      <CheckedInput
                        key={index}
                        className={
                          label.name === "选择心芽原因" ? "xinya_reason" : ""
                        }
                        defaultChecked={option.selected}
                        name={option.id}
                        title={option.name}
                        setForm={setUserInfo}
                      />
                    ))}
                  </div>
                </div>
              ))}
            <h4 style={{ margin: "30px 0 0" }}>孩子信息：</h4>
            {teachers && teachers.length > 0 && (
              <div className="flex_style">
                <div className="min_width">专属老师</div>
                {
                  <select className="form-control" id="user_teacher">
                    <option value="">请选择专属老师</option>
                    {teachers.map((teacher, index) => (
                      <option
                        key={index}
                        value={teacher.id}
                        selected={
                          user &&
                          (teacher.id == user?.teacher?.id ? "selected" : "")
                        }
                      >
                        {teacher.name}
                      </option>
                    ))}
                  </select>
                }
              </div>
            )}
            {grades && grades.length > 0 && (
              <div className="flex_style">
                <div className="min_width">小孩年级</div>
                <select className="form-control" id="baby_grade">
                  <option value="">请选择年级</option>
                  {grades.map((grade) => (
                    <option
                      key={grade.id}
                      value={grade.en_title}
                      selected={
                        baby &&
                        (grade.zh_title == baby?.grade ? "selected" : "")
                      }
                    >
                      {grade.zh_title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {baby?.baby_info?.label_groups?.length > 0 &&
              baby?.baby_info?.label_groups.map((label_group, index) => (
                <div className="flex_style" key={index}>
                  <div className="min_width">{label_group.name}</div>
                  {label_group?.labels.length > 0 &&
                    label_group?.labels.map((label, index) => (
                      <SelectLabel
                        key={index}
                        options={label?.options}
                        emptyOptionHit={label.name}
                        setOption={(index) => {
                          const state = label?.options[index];
                          if (state?.id != undefined) {
                            setBabyInfoObject((pre) => ({
                              ...pre,
                              [label.id]: state.id,
                            }));
                          }
                        }}
                      />
                    ))}
                </div>
              ))}
            {baby?.baby_info?.labels?.length > 0 &&
              baby?.baby_info?.labels.map((label, index) => (
                <div className="flex_style" key={index}>
                  <div className="min_width" style={{ width: "120px" }}>
                    {label.name}
                  </div>
                  <div
                    className="flex_style"
                    style={{ flexWrap: "wrap", marginTop: "0" }}
                  >
                    {label?.options.map((option, index) => (
                      <CheckedInput
                        key={index}
                        defaultChecked={option.selected}
                        name={option.id}
                        title={option.name}
                        setForm={setBabyInfo}
                      />
                    ))}
                  </div>
                </div>
              ))}
            <h4 style={{ margin: "30px 0 0" }}>跟进日志：</h4>
            <div className="flex_style">
              <div className="min_width">日志内容</div>
              <textarea
                id="follow_up_log"
                cols="30"
                rows="5"
                className="margin_left form-control editable"
                style={{ maxWidth: "100%", height: "60px" }}
              ></textarea>
            </div>
            <div className="flex_style">
              <div className="min_width">跟进方式</div>
              <div className="flex_style">
                {[
                  { id: 0, name: "微信跟进" },
                  { id: 1, name: "语音跟进" },
                ].map((item) => (
                  <p
                    key={item.id}
                    className="flex_check_box"
                    style={{ flex: "initial", marginLeft: "-10px" }}
                  >
                    <input
                      className="follow_up_type"
                      type="radio"
                      checked={followUpType === item.id}
                      onChange={() => setFollowUpType(item.id)}
                    />
                    <span className="follow_up_type_span">{item.name}</span>
                  </p>
                ))}
              </div>
            </div>
            <div
              className="flex_style"
              style={{ flexWrap: "wrap", marginTop: "0" }}
            >
              {!!user?.sessions &&
                user?.sessions.map((session, index) => (
                  <Session
                    session={session}
                    key={index}
                    setForm={setSession}
                    sessionIndex={index}
                    isDefaultMarkHandle={user?.sessions.length === 1}
                  />
                ))}
            </div>

            {newSessions.length > 0 && (
              <div
                className="flex_style"
                style={{ flexWrap: "wrap", marginTop: "0" }}
              >
                {newSessions.map((_, index) => (
                  <NewSession
                    key={index}
                    session={user?.new_sessions}
                    sessionIndex={index}
                    setForm={setNewSessions}
                    onDeleteSession={() =>
                      setNewSessions((pre) => {
                        const copy = [...pre];
                        copy.splice(index, 1);
                        return copy;
                      })
                    }
                    isSessionExit={!!user?.sessions}
                  />
                ))}
              </div>
            )}

            <button
              className="btn btn-primary"
              type="button"
              onClick={addNewSessions}
              style={{ marginLeft: " 93px", marginTop: "20px" }}
            >
              新增跟进结果
            </button>
            <div className="flex_style">
              <div className="min_width">下次提醒</div>
              <div
                className="margin_left input-group date"
                id="datetimepicker1"
                style={{ display: "flex", flexWrap: "nowrap" }}
              >
                <input
                  className="form-control"
                  id="alert_time_input"
                  type="text"
                  onChange={handleAlertTimeInput}
                  defaultValue={ccLogTime}
                />
                <span
                  className="input-group-addon"
                  id="datetimepicker-icon"
                  onClick={handleClickDatetimepickerIcon}
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "center",
                    borderRadius: "0 4px 4px 0",
                    background: "#eee",
                    border: "1px solid #eee",
                    alignItems: "center",
                  }}
                >
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div className="flex_style_wrapper">
              <div className="flex_style">
                <div className="min_width">访问不再提醒</div>
                <select className="form-control" id="user_no_alert">
                  {user && user.alert_until_time ? (
                    <option value={user.alert_until_time} selected="selected">
                      {user.alert_until_time}
                    </option>
                  ) : (
                    <option value="">请选择不再提醒时间</option>
                  )}
                  {untilTime.map((time, index) => (
                    <option key={index} value={time.value}>
                      {time.time}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex_style">
              <button
                className="btn btn-primary"
                type="button"
                id="add_follow_up_log"
                onClick={debounce(handleFollowUpLog)}
              >
                提交
              </button>
              {/* {requireAudit && (
                <button
                  className="btn btn-primary"
                  id="submit_verify"
                  type="button"
                  onClick={debounce(handleSubmitVerify)}
                >
                  提交审核
                </button>
              )} */}
              {/* <button
                className="btn btn-primary finish"
                id="attention_handled"
                type="button"
                onClick={debounce(handleAttentionHandled)}
              >
                结束跟进
              </button> */}
              <button
                className="btn btn-primary finish"
                id="attention_handled"
                type="button"
                onClick={debounce(handleAddExtraLog)}
              >
                增补日志
              </button>
              <button
                className="btn btn-primary finish"
                id="attention_handled"
                type="button"
                onClick={debounce(handleTransformLog)}
              >
                流转
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyleAddLogModalDialog>
  );
};

export default memo(AddLogModalDialog);
