import React from "react";
import PropTypes from "prop-types";
// import UserRecommend from '../shared/UserRecommend'

import btnImg from "interview_exams/button_buy_invite.png";
import tipsImg from "tips_intro.png";
import bargain_des_one from "interview_exams/bargain_desc_one.png";
import bargain_des_two from "interview_exams/bargain_desc_two.png";
import bargain_des_three from "interview_exams/bargain_desc_three.png";
import bargain_des_four from "interview_exams/bargain_desc_four.png";
import quiz_desc from "interview_exams/quiz_desc.png";
import target_school from "interview_exams/target_school.png";

import bargainQA from "interview_exams/bargain_q_a.png";
import bargain_star from "interview_exams/bargain_star.png";

import "./BargainActivity.scss";

import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import CountDownTime from "../utils/CountDown";
import { ToastManager } from "../utils/Toast";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";
import { DirectBuy } from "../shared/DirectBuy";
import { reloadPage } from "../utils/helpers";

export default class BargainActivity extends DirectBuy {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      finalPrice: this.props.activity.price,
      end_time: this.props.end_time,
      initial_price: this.props.activity.price,
      invited_users_count: this.props.invited_users_count,
      // canGetFree: this.props.is_bought_study_package && this.props.is_comment,
      is_bought_study_package: this.props.is_bought_study_package,
      is_comment: this.props.is_comment,
    };
  }
  directBuy = () => {
    if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      var amount =
        this.props.activity.price - this.props.invited_users_count * 10.0;
      console.log(amount);
      if (amount <= 0) {
        this.withoutCharge({ source: "free" });
        return;
      }
      this.buy(this.props.buy_url, {
        amount: amount,
        id: this.props.activity.id,
      });
    }
  };
  withoutCharge = (params) => {
    request({
      url: this.props.free_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.activity.id,
        source: params["source"],
      },
    })
      .then((resp) => {
        if (resp.data) {
          const buttons = [
            {
              label: "确定",
              onClick: () => {
                this.confirmDialog.close();
                window.location.href = resp.data.redirect_url;
              },
            },
          ];
          this.confirmDialog = DialogManager.open(
            <Dialog buttons={buttons}>领取成功</Dialog>
          );
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  invitingUser = () => {
    if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      if (!this.props.poster_url) {
        return;
      }
      this.sharePosterProxy = new SharePosterProxy({
        posterUrl: this.props.poster_url,
        posterImgSrc: this.props.poster_img_src,
        free: this.props.free,
        callback: this.posterCallback,
      });
      this.sharePosterProxy.open();
    }
  };

  handleCountdownEnd = () => {
    this.setState({
      inviteHideDisplay: "none",
    });
  };

  posterCallback = () => {
    if (this.state.end_time) {
      return;
    }
    request({
      url: this.props.inviting_user_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.activity.id,
      },
    })
      .then((resp) => {
        console.log(resp.data);
        this.setState({ end_time: resp.data.end_time });
      })
      .catch((err) => {
        alert(err);
      });
  };

  // 引导关注微信公众号
  onWechatFollow = (e) => {
    this.wechatFollowClick.className = "guide_wechat_follow show";
  };

  // 关闭关注微信公众号 弹框
  onCloseDialog = (e) => {
    this.wechatFollowClick.className = "guide_wechat_follow hide";
  };

  render() {
    // const {end_time, finalPrice, initial_price, countDownEnd, canGetFree} = this.state
    const { end_time, finalPrice, initial_price, countDownEnd } = this.state;

    return (
      <div className="comp_interview_exam">
        <div className="interview_exam_wrapper">
          <div className="interview_exam_content ">
            <div className="discount_money_time">
              <div className="discount_progress">
                <div className="label">¥</div>
                <div className="discount_wrapper">
                  <div
                    className="gridNums"
                    style={{
                      top: "-" + Math.floor(finalPrice / 10.0) * 70 + "px",
                    }}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
                      return (
                        <div className="gridNum" key={i}>
                          {i}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="zero">{finalPrice % 10}</div>
              </div>
              <div className="original_price">
                <div className="text_title">原价：</div>
                <div className="price">¥197</div>
              </div>
              <div className="discount_time">
                <div className="content_text"></div>
                <span className="content_time">
                  {end_time ? (
                    <CountDownTime
                      endTime={new Date(end_time)}
                      overText="邀请时间结束"
                      onEnd={this.handleCountdownEnd}
                    >
                      {({ d, h, m, s }) => {
                        return (
                          <span className="timer">
                            邀请倒计时<span>{h}</span>:<span>{m}</span>:
                            <span>{s}</span>
                          </span>
                        );
                      }}
                    </CountDownTime>
                  ) : (
                    <span className="timer">
                      邀请倒计时
                      <span>24</span>:<span>00</span>:<span>00</span>
                    </span>
                  )}
                </span>
              </div>
            </div>
            {/* { 
              canGetFree ? 
              <div className="buy_invite_button">
                <a onClick={this.withoutCharge.bind(this, {source: 'presented'})} className='center_position'>免费领取</a>
              </div>
              : */}
            <div className="buy_invite_button">
              {initial_price < 0.0 ? (
                <a>无法购买</a>
              ) : (
                <a onClick={this.directBuy}>
                  {finalPrice === 0.0 ? "免费领取" : "立即购买"}
                </a>
              )}
              {finalPrice != 0.0 ? (
                <a
                  onClick={this.invitingUser}
                  style={{ display: this.state.inviteHideDisplay }}
                >
                  {end_time ? "继续邀请" : "开始邀请"}
                </a>
              ) : (
                ""
              )}
            </div>
            {/* } */}
            <div className="explain_text">
              <p className="big_attention">每邀请1位好友，立减10元！</p>
              <p>
                分享本页面，24小时内每邀请<span>1位新朋友</span>
              </p>
              <p>关注「心芽学堂」公众号，</p>
              <p>即可优惠¥10，最低可0元购买！</p>
            </div>
          </div>
        </div>
        <div className="interview_exam_info">
          <div className="title color_white">超全的面试真题题库</div>
          <div className="latitude color">
            <span className="item_flex">
              <span className="normal_font">113</span>
              <span className="small_font">知识点</span>
            </span>
            <span className="item_flex">
              <span className="normal_font">2011~2018</span>
              <span className="small_font">时间跨度</span>
            </span>
            <span className="item_flex">
              <span className="normal_font">36</span>
              <span className="small_font">覆盖名校</span>
            </span>
          </div>
        </div>

        <div className="selling_point_one selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>历年面试真题汇编</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>
              <strong>【题库】模块</strong>
            </p>
            <p style={{ paddingBottom: "15px" }}>
              提供113个幼升小思维知识点的历年真题
            </p>
            <p>
              <strong>【测试】模块</strong>
            </p>
            <p>每个知识点精选1道真题，考察孩子是否掌握</p>
            <p>
              共7大思维模块，精选<span style={{ color: "#ea3c60" }}>113</span>
              道真题
            </p>
            <p style={{ fontSize: "14px" }}>（在线题库，无纸质版）</p>
          </div>
          <img src={bargain_des_one} className="bottom_img" />
        </div>

        <div className="selling_point_two selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>真实、可在线测试的真题</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>模拟面试时的iPad机考限时形式</p>
            <p>
              点击<strong>【测评】</strong>按钮
            </p>
            <p>即可通过历年真题，开始思维能力测试</p>
          </div>
          <img src={quiz_desc} alt="" className="bottom_img" />
        </div>

        <div className="selling_point_three selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>及时的查漏补缺</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>根据孩子的测评情况</p>
            <p>
              系统还将推出有针对性的<strong>【模拟练习题】</strong>
            </p>
          </div>
          <img src={bargain_des_four} alt="" className="bottom_img" />
        </div>

        <div className="selling_point_four selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>专业的知识点分析</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>除了历年真题，真题汇编还提供</p>
            <p>每个知识点学习时的注意与技巧</p>
          </div>
          <img src={bargain_des_three} className="bottom_img" />
        </div>

        <div className="selling_point_five selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>孩子的面试考点掌握情况</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>基于面试真题测评，了解孩子真实水平</p>
            <p>
              可预判断<strong>心仪小学</strong>的考点掌握情况
            </p>
          </div>
          <img src={bargain_des_two} className="bottom_img" />
        </div>

        <div className="selling_point_six selling_point">
          <div className="selling_point_title">
            <img src={bargain_star} className="bargain_star" />
            <span>额外福利</span>
            <img src={bargain_star} className="bargain_star" />
          </div>
          <div className="selling_point_content">
            <p>购买后赠送【3所】目标学校面试真题文字版</p>
            <p style={{ fontSize: "14px" }}>(只能选择1次，且选择后无法修改)</p>
          </div>
          <img
            src={target_school}
            alt=""
            className="bottom_img"
            style={{ width: "65%" }}
          />
        </div>

        <div className="bargain_q_a">
          <img src={bargainQA} />
        </div>

        {/* <UserRecommend create_recommend_request_url={this.props.create_comment_url} comment_target={this.props.comment} ref={recommend => this.recommendDilog = recommend} callBack={() => {reloadPage()}}/> */}

        {/* {
          this.props.is_bought_study_package ? '' :
          <div className="free_interview_exam">
            <span>购买幼升小冲刺班课程，即可免费领取</span>
            <a href={this.props.latest_study_package_activity_url}>前往购买 »</a>
          </div>
        } */}

        <div
          className="guide_wechat_follow"
          ref={(wechatFollow) => (this.wechatFollowClick = wechatFollow)}
        >
          <div className="dialog_qrcode_body">
            <div className="btn-close-wechat" onClick={this.onCloseDialog}>
              &times;
            </div>
            <p>
              请先关注公众号
              <br />
              接收学习提醒及报告
            </p>
            <img src={this.props.qrcode_url} />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    var title = "幼升小面试真题 | 2017-2011年上海数学思维面试真题汇编";
    var desc = "还有基于真题测评结果的针对性练习题~";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    setTimeout(() => {
      this.setState({
        finalPrice:
          this.state.initial_price - this.state.invited_users_count * 10.0 < 0.0
            ? 0.0
            : this.state.initial_price - this.state.invited_users_count * 10.0,
      });
    }, 500);

    // if(this.state.is_bought_study_package){
    //   if(this.state.is_comment) {
    //     const buttons = [
    //       {
    //         label: "确定",
    //         onClick: () => this.confirmDialog.close()
    //       }
    //     ]
    //     this.confirmDialog = DialogManager.open(
    //       <Dialog buttons={buttons}>因为您是我们的课程用户，并且发表过课程评价，可免费领取《幼升小面试真题汇编》</Dialog>
    //     )
    //   }else {
    //     const buttons = [
    //       {
    //         label: "评价",
    //          onClick:() => {
    //           this.recommendDilog.setState({
    //             isFillInDisplay: 'block'
    //           })
    //           this.confirmDialog.close()
    //          }
    //       },
    //       {
    //         label: "取消",
    //         onClick: () => this.confirmDialog.close()
    //       },
    //     ]
    //     this.confirmDialog = DialogManager.open(
    //       <Dialog buttons={buttons}>您已经购买过我们的课程，只需要完成对之前课程的评价，即可免费领取《幼升小面试真题汇编》</Dialog>
    //     )
    //   }
    // }
  };
}

BargainActivity.propTypes = Object.assign({}, SharePoster.propTypes, {
  activity: PropTypes.object.isRequired,
});

BargainActivity.defaultProps = {
  free: "bargain_activity",
};
