/* eslint-disable react/prop-types */
import React, { useState, useCallback } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { ToastManager } from "../../utils/Toast";
import {
  request as axios,
  csrfHeaders,
  getAxiosErrorText,
} from "../../utils/request";
import { awaitWrapper } from "../../utils/helpers";
import ConsultantTabTableContent from "../../../admin-utils/ConsultantTabTableContent";
import TableFilterBtnGroup from "./TableFilterBtnGroup";

const request = axios.create({
  timeout: 15000,
});

const Modal = styled.div`
  margin-top: 20vh !important;
`;

const ModalBody = styled.div`
  textarea {
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 100%;
  }
`;

const ModalHeader = styled.div`
  border-bottom: 0px;
`;

const ModalFooter = styled.div`
  border-top: 0px;
  display: flex;
  justify-content: center;
  button {
    padding: 6px 20px;
    border-radius: 200px;
    color: white;
    outline: none;
  }
`;

const ModalTitle = styled.h4`
  text-align: center;
`;

const StyledTableThead = styled.thead`
  th {
    text-align: left;
    min-width: 70px;
    max-width: 200px;
    padding: 8px 0 !important;
  }
  th:first-of-type {
    padding: 8px 0 8px 15px !important;
  }
`;

const StickyUser = styled.td`
  position: sticky !important;
  left: 0;
  z-index: 100;
  background: white;
  display: block;
  padding-left: 15px;
  margin-top: 10px;
`;

const StickyUserTh = styled.th`
  position: sticky !important;
  left: 0;
  z-index: 100;
  display: block;
  padding-left: 15px;
`;

const StyledTableTbody = styled.tbody`
  td {
    vertical-align: middle !important;
    text-align: left;
    /* max-width: 200px; */

    white-space: initial !important;

    padding-right: 5px !important;
    p {
      margin: 0 !important;
    }
    .style_p {
      /* white-space: nowrap; */
      margin-bottom: 5px !important;
    }
  }
`;

const OperateBtnGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const UserBrief = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
  }
  .text {
    margin-left: 10px;
    a {
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
      color: #3c8af0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 70px;
      display: block;
      text-align: left;
    }
    p {
      font-size: 12px;
      color: #999;
      margin: 0;
    }
  }
`;

const StyledConsultantTabTableContent = styled(ConsultantTabTableContent)`
  & {
    margin: 20px 0 !important;
  }
`;

const StyledAuditReview = styled.div`
  min-width: 67px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 200px;
  max-width: 70px;
  .glyphicon {
    padding-right: 6px;
  }
  &:last-of-type {
    margin-left: 4px;
    margin-right: 10px;
  }
`;

const AuditUsers = (props) => {
  // console.log(query);
  const {
    records,
    followUpLogAuditUrl,
    courseConsultants,
    activitySources,
  } = props;
  const {
    query: {
      // date_from,
      // date_to,
      // course_consultant_id,
      audit_state,
      // activity_source,
    },
  } = queryString.parseUrl(window.location.href);
  console.log("auditState", auditState);
  const consultants = [{ name: "所有顾问", id: -1 }, ...courseConsultants];
  const [auditState, setAuditState] = useState(
    audit_state ? audit_state * 1 : 0
  );
  console.log(props);

  const [auditReason, setAuditReason] = useState("");

  const handleAuditReviewReject = useCallback(
    async (e) => {
      const id = e.currentTarget.getAttribute("data-audit");
      if (!auditReason) {
        alert("请填写驳回理由");
        return;
      }
      const toast = ToastManager.showLoading("提交中...");
      const [error] = await awaitWrapper(request)({
        method: "post",
        url: followUpLogAuditUrl,
        headers: csrfHeaders,
        data: {
          audit_id: Number(id),
          state: 2,
          reason: auditReason,
        },
      });

      if (error) {
        toast.cancel();
        // $("#myModal").modal("toggle");
        const errorMsg = getAxiosErrorText(error, "");
        alert(errorMsg);
      } else {
        $("#myModal").modal("toggle");
        window.location.reload();
      }
    },
    [auditReason, followUpLogAuditUrl]
  );

  const handleAuditReviewResolve = useCallback(
    async (e) => {
      const id = e.currentTarget.getAttribute("data-audit");
      const toast = ToastManager.showLoading("提交中...");
      const [error] = await awaitWrapper(request)({
        method: "post",
        url: followUpLogAuditUrl,
        headers: csrfHeaders,
        data: {
          audit_id: Number(id),
          state: 1,
        },
      });

      if (error) {
        toast.cancel();
        const errorMsg = getAxiosErrorText(error, "提交");
        alert(errorMsg);
      } else {
        window.location.reload();
      }
    },
    [followUpLogAuditUrl]
  );

  const formatCreateTime = (time) => {
    if (!time) return "";
    const newTime = new Date(time);
    return `${newTime.getFullYear()}.${(newTime.getMonth() + 1 + "").padStart(
      2,
      "0"
    )}.${(newTime.getDate() + "").padStart(2, "0")} ${(
      newTime.getHours() + ""
    ).padStart(2, "0")}:${(newTime.getMinutes() + "").padStart(2, "0")}`;
  };

  const handleTextAreaChange = useCallback((e) => {
    setAuditReason(e.target.value);
  }, []);

  const handleModalOpen = useCallback((e) => {
    const id = e.currentTarget.getAttribute("data-audit");
    $("#handle_audit_reject").attr("data-audit", id);
  }, []);

  return (
    <React.Fragment>
      <TableFilterBtnGroup
        consultants={consultants}
        setAuditState={setAuditState}
        activitySources={[{ name: "所有来源", id: -1 }, ...activitySources]}
        isShowAuditStateFilter
        isShowConsultantFilter
        isShowDateFilter
        isShowActivitySources
      />
      <StyledConsultantTabTableContent>
        <StyledTableThead>
          <tr className="ths">
            <StickyUserTh>用户</StickyUserTh>
            <th>高意向原因</th>
            <th>小孩年级</th>
            {/* <th>意向课程</th> */}
            <th>跟进日志</th>
            <th>跟进时间</th>
            <th>跟进老师</th>
            <th>跟进7日内购课</th>
            {auditState === 0 ? (
              <React.Fragment>
                <th>来源</th>
                <th>操作</th>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <th>状态</th>
                {/* <th>未通过原因</th> */}
                <th>审核人</th>
                {auditState === 2 && <th>来源</th>}
              </React.Fragment>
            )}
          </tr>
        </StyledTableThead>
        <StyledTableTbody>
          {records.map((record, index) => (
            <tr key={index}>
              <StickyUser>
                <UserBrief>
                  <img src={record.user?.photoUrl} alt="" />
                  <div className="text">
                    <a href={record.user?.userPath}>{record.user?.name}</a>
                    <p>{record.user?.mobile}</p>
                  </div>
                </UserBrief>
              </StickyUser>
              <td style={{ maxWidth: 350, minWidth: 150 }}>
                {record.activitySources &&
                  record.activitySources.length > 0 &&
                  record.activitySources.map((s, index) => (
                    <p key={index} className="style_p">
                      <>
                        <span>{s.sourceName}</span>
                        {s.activities && s.activities.length > 0 && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `（${s.activities
                                .map((a) => {
                                  if (a.url) {
                                    return `<a href=${a.url}>${a.name}</a>`;
                                  } else {
                                    return `<span>${a.name}</span>`;
                                  }
                                })
                                .join("、")}）`,
                            }}
                          ></span>
                        )}
                      </>
                    </p>
                  ))}
              </td>
              <td>{record.baby?.grade}</td>
              {/* <td>
                <p style={{ whiteSpace: "pre-line" }}>
                  {record.attentionCourses?.map?.((a) => a.name)?.join?.("\n")}
                </p>
              </td> */}
              <td>
                <p style={{ whiteSpace: "pre-wrap" }}>{record.log?.logNote}</p>
              </td>
              <td>{formatCreateTime(record.log?.createdAt)}</td>
              <td style={{ minWidth: 120 }}>
                <p>{record.courseConsultant?.employeeName}</p>
                <p style={{ fontSize: 12 }}> {record.courseConsultant?.name}</p>
              </td>
              <td>
                {record?.boughtCourses?.map?.((c) => {
                  if (c.state === 2)
                    return (
                      <p
                        style={{ color: "red" }}
                        key={c.id}
                      >{`${c.name}（已退款）`}</p>
                    );
                  return <p key={c.id}>{`${c.name}（已支付）`}</p>;
                })}
              </td>
              {auditState === 0 ? (
                <React.Fragment>
                  <td>{record?.audit?.highIntentionReason}</td>

                  <td>
                    {record?.audit?.canAudit && (
                      <React.Fragment>
                        <OperateBtnGroup>
                          <StyledAuditReview
                            className="btn-success"
                            data-audit={record.audit?.id}
                            onClick={handleAuditReviewResolve}
                          >
                            <span className="glyphicon glyphicon-ok"></span>
                            <span>通过</span>
                          </StyledAuditReview>
                          <StyledAuditReview
                            className="btn-danger"
                            data-toggle="modal"
                            data-target="#myModal"
                            id="open_modal"
                            data-audit={record.audit?.id}
                            onClick={handleModalOpen}
                          >
                            <span className="glyphicon glyphicon-remove"></span>
                            <span>驳回</span>
                          </StyledAuditReview>
                        </OperateBtnGroup>
                      </React.Fragment>
                    )}
                  </td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td
                    style={{
                      color:
                        record.audit?.state === "驳回"
                          ? "red"
                          : "rgb(92, 184, 92)",
                    }}
                  >
                    <p>{record.audit?.state}</p>
                    {record.audit?.state === "驳回" && (
                      <p>{record.audit?.reason}</p>
                    )}
                  </td>

                  <td>{record.audit?.userName}</td>
                  {auditState === 2 && (
                    <td>{record?.audit?.highIntentionReason}</td>
                  )}
                </React.Fragment>
              )}
            </tr>
          ))}
        </StyledTableTbody>
      </StyledConsultantTabTableContent>

      <Modal
        className="modal fade"
        id="myModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <ModalHeader className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <ModalTitle className="modal-title" id="myModalLabel">
                请填写驳回理由
              </ModalTitle>
            </ModalHeader>
            <ModalBody className="modal-body">
              <textarea
                name=""
                id=""
                rows="5"
                value={auditReason}
                onChange={handleTextAreaChange}
              ></textarea>
            </ModalBody>
            <ModalFooter className="modal-footer">
              <button
                id="handle_audit_reject"
                type="button"
                className="btn btn-danger"
                // data-dismiss="modal"
                onClick={handleAuditReviewReject}
              >
                确定驳回
              </button>
            </ModalFooter>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AuditUsers;
