import React, { useState } from "react";
import { ToptipsManager } from "../utils/Toptips";
import { ToastManager } from "../utils/Toast";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";

import "./CouponRecord.scss";

export default (props) => {
  const { url } = props;

  const [exchangeCode, setExchangeCode] = useState("");
  const [exchangeCourseStatus, setExchangeCourseStatus] = useState(false);
  const [viewCourseUrl, setViewCourseUrl] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setExchangeCode(value);
  };

  const handleRedeemNow = () => {
    if (exchangeCode.trim() === "") {
      ToptipsManager.warn("请输入兑换码");
    } else {
      const buttons = [
        {
          label: "取消",
          onClick: () => confirmDialog.close(),
        },
        {
          label: "确认",
          onClick: () => {
            confirmDialog.close();
            ajaxRequest();
          },
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          <div className="title">确认使用兑换码</div>
          <p>请确认输入的兑换码是否正确，一旦兑换无法取消</p>
        </Dialog>
      );
    }
  };

  const ajaxRequest = async () => {
    const toast = ToastManager.showLoading("正在兑换中...");
    try {
      const res = await request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          coupon_record: {
            code: exchangeCode,
          },
        },
      });
      toast.cancel();

      if (res.data.err_code === 0) {
        // 兑换成功 · 进入
        setExchangeCourseStatus(true);
        setViewCourseUrl(res.data.redirect_url);
      } else {
        ToastManager.showToastText("兑换码错误", 2000);
      }
    } catch (_err) {
      toast.cancel();
      ToastManager.showToastText("兑换码错误", 2000);
    }
  };

  return (
    <div className="comp_coupon_record">
      {exchangeCourseStatus ? (
        <div className="exchange_success_box">
          <p>兑换成功！</p>
          <a className="go_exchange" href={viewCourseUrl}>
            查看课程
          </a>
        </div>
      ) : (
        <div className="exchange_coupon_box">
          <div className="coupon_code">
            <input
              type="text"
              placeholder="请输入兑换码"
              value={exchangeCode}
              onChange={handleChange}
            />
          </div>
          <div className="go_exchange" onClick={() => handleRedeemNow()}>
            立即兑换
          </div>
          <div className="exchange_description">
            填写您的兑换码，兑换完成后，可在心芽学堂APP/公众号的“课程”中，查看您兑换的课程。
          </div>
        </div>
      )}
    </div>
  );
};
