import React from "react";
import PropTypes from "prop-types";

import UserAgent from "../utils/UserAgent";
import ReactMarkdown from "react-markdown";
import ProblemFold from "../utils/ProblemFold";

import mp_qr_code from "training_camp_activity/mp_qr_code.jpg";
import qrcode_taozi from "training_camp_activity/qrcode_taozi.png";

import { ExamDialogStartProxy } from "../utils/DialogSignExam";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import CountDownTime from "../utils/CountDown";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";
import { DirectBuy } from "../shared/DirectBuy";

import "./TrainingCampActivity.scss";
import "../utils/ReactMarkDown.scss";

export default class TrainingCampActivity extends DirectBuy {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      isDisplayTrainIntro: "block",
      activeSwitch1: "active",
      finalPrice: this.props.activity.price,
      end_time: this.props.end_time,
      initial_price: this.props.activity.price,
      invited_users_count: this.props.invited_users_count,
    };
  }

  //点击训练营介绍
  onClickTrain = () => {
    this.setState({
      isDisplayTrainIntro: "block",
      activeSwitch1: "active",
      activeSwitch2: "",
    });
    this.problemFoldContainer.setState({
      isCommonProblemBox: "none",
    });
  };

  //点击常见问题
  onClickCommon = () => {
    this.setState({
      isDisplayTrainIntro: "none",
      activeSwitch1: "",
      activeSwitch2: "active",
    });
    this.problemFoldContainer.setState({
      isCommonProblemBox: "block",
    });
  };

  handleCountdownEnd = () => {
    this.setState({
      inviteHideDisplay: "none",
    });
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        finalPrice:
          this.state.initial_price - this.state.invited_users_count * 10.0 < 0.0
            ? 0.0
            : this.state.initial_price - this.state.invited_users_count * 10.0,
      });
    }, 500);

    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    const share_title =
      "最全面的计算速度提升练习，搞定一年级所有加减要求！邀你一起报名~";
    const share_description =
      "58个关卡，从易到难，练习内容更系统，每种题型都练到";
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (this.props.limit_users_count > 0) {
      const buttons = [
        {
          label: "确定",
          onClick: () => this.confirmDialog.close(),
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons} className="contact_teacher">
          <p>本期名额已满</p>
          <p>有任何疑问请联系【桃子老师】</p>
          <img src={qrcode_taozi} />
        </Dialog>
      );
    } else {
      this.props.quiz_required === true
        ? new ExamDialogStartProxy({
            prepare_check_url: this.props.prepare_check_url,
            training_camp_url: this.props.training_camp_url,
            study_package_activity_url: this.props.study_package_activity_url,
          }).open()
        : "";
    }

    $(function () {
      $(window).scroll(function () {
        var offset_top = document.getElementById("course_container").offsetTop;
        var scroll_top = $(document).scrollTop();
        if (scroll_top > offset_top) {
          // console.log("fixed success")
          document
            .getElementById("switch_tab")
            .classList.add("switch_title_fixed");
        } else {
          document
            .getElementById("switch_tab")
            .classList.remove("switch_title_fixed");
        }
      });
    });
  };

  // 重新测评
  reviewTest = () => {
    this.confirmDialog.close();
    new ExamDialogStartProxy({
      prepare_check_url: this.props.prepare_check_url,
      training_camp_url: this.props.training_camp_url,
      study_package_activity_url: this.props.study_package_activity_url,
    }).open();
  };

  onceAgainConfirm = () => {
    //异常情况：已购买单课程，准备购买包含单课程的课程包组合 (partial)
    //异常情况：已购买课程包组合，准备购买组合中包含的单课程 (superset)
    //异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (
      this.props.study_package_group_state == "partial" ||
      this.props.study_package_group_state == "superset" ||
      this.props.study_package_group_state == "bought_other"
    ) {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => this.confirmDialog.close(),
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          {this.props.study_package_group_state == "partial"
            ? "当前课程中包含您已拥有的《" +
              this.props.bought_category_name +
              "》，请单独报名其它课程"
            : this.props.study_package_group_state == "superset"
            ? "您拥有的《" +
              this.props.bought_category_name +
              "》已包含当前课程，无需重复报名"
            : "为了孩子的学习效果，建议您不要同时报名多个课程"}
        </Dialog>
      );
      return;
    }

    if (this.props.calculate_eval_result === 1) {
      const buttons = [
        {
          label: "确定",
          onClick: () => this.confirmDialog.close(),
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons} className="review_test">
          根据之前的测评结果，小朋友100以内的加减法还没有掌握，不推荐报名此闯关练习哦！
          <p>
            测评回答有误？<span onClick={this.reviewTest}>重新测评</span>
          </p>
        </Dialog>
      );
    } else {
      const buttons = [
        {
          label: "取消",
          onClick: () => this.confirmDialog.close(),
        },
        {
          label: "去支付",
          onClick: () => {
            this.confirmDialog.close();
            this.directBuy();
          },
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons} className="refuse_refund">
          「速度闯关练习」：
          <br />
          1. 没有教学课程 <br />
          2. 适合已掌握100以内加减法、要提速的孩子 <br />
          3. 共2本纸质练习册，结合基于大数据的关卡设定、星级闯关激励等线上工具{" "}
          <br />
          <p>购买之后不支持退款哦！</p>
        </Dialog>
      );
    }
  };

  directBuy = () => {
    if (this.props.qrcode_url != null && !UserAgent.isMiniProgram()) {
      this.onWechatFollow();
    } else {
      var amount =
        this.props.activity.price - this.props.invited_users_count * 10.0;
      if (amount <= 0) {
        this.withoutCharge({ source: "free" });
        return;
      }
      this.buy(this.props.buy_url, {
        amount: amount,
        id: this.props.activity.id,
      });
    }
  };

  withoutCharge = (params) => {
    request({
      url: this.props.free_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.activity.id,
        source: params["source"],
      },
    })
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.err_code) {
        } else {
          alert("购买成功");
        }
        window.location.href = resp.data.redirect_url;
      })
      .catch((err) => {
        alert(err);
      });
  };

  // 点击开始邀请朋友
  invitingUser = () => {
    if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      if (!this.props.poster_url) {
        return;
      }
      this.sharePosterProxy = new SharePosterProxy({
        posterUrl: this.props.poster_url,
        posterImgSrc: this.props.poster_img_src,
        free: this.props.free,
        callback: this.posterCallback,
      });
      this.sharePosterProxy.open();
    }
  };

  posterCallback = () => {
    if (this.state.end_time) {
      return;
    }
    request({
      url: this.props.inviting_user_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.activity.id,
      },
    })
      .then((resp) => {
        console.log(resp.data);
        this.setState({ end_time: resp.data.end_time });
      })
      .catch((err) => {
        alert(err);
      });
  };

  // 引导关注微信公众号
  onWechatFollow = (e) => {
    this.wechatFollowClick.className = "guide_wechat_follow show";
  };
  // 关闭关注微信公众号 弹框
  onCloseDialog = (e) => {
    this.wechatFollowClick.className = "guide_wechat_follow hide";
  };

  // 引导识别小程序码
  wechatFollowProgram = (e) => {
    this.wechatFollowProgramClick.className = "guide_wechat_follow show";
  };
  onCloseDialogProgram = (e) => {
    this.wechatFollowProgramClick.className = "guide_wechat_follow hide";
  };

  goTraningClick = () => {
    if (
      UserAgent.isNativeClient() ||
      UserAgent.isWebBrowser() ||
      UserAgent.isWechat()
    ) {
      this.wechatFollowProgram();
    } else if (UserAgent.isMiniProgram()) {
      const url = "/pages/calculate_training/list";
      wx.ready(function () {
        wx.miniProgram.redirectTo({ url: url });
      });
    }
  };

  checkNoteClick = () => {
    if (UserAgent.isMiniProgram()) {
      const note_url = this.props.note_url;
      wx.ready(function () {
        let params = {
          path: "/bargain_activity_note",
          queryParams: {
            id: 2,
            client_from: "MiniProgram",
          },
        };
        let paramsString = JSON.stringify(params);
        wx.miniProgram.navigateTo({
          url: `/pages/webView/webView?url=${paramsString}`,
        });
      });
    } else if (
      UserAgent.isWechat() ||
      UserAgent.isWebBrowser() ||
      UserAgent.isNativeClient()
    ) {
      window.location.href = this.props.note_url;
    }
  };

  AfterBuySuccess() {
    return (
      <div className="after_buy_success">
        <a
          href="javascript:void(0)"
          className="check_explain"
          onClick={this.checkNoteClick}
        >
          查看使用说明
        </a>
        <a
          href="javascript:void(0)"
          className="go_traning_pratice"
          onClick={this.goTraningClick}
        >
          前往训练营
        </a>
      </div>
    );
  }

  render() {
    const { end_time, finalPrice, initial_price, countDownEnd } = this.state;

    let content = "";
    if (UserAgent.isNativeClient() || UserAgent.isWebBrowser()) {
      content = (
        <p className="distinguish_program">
          使用微信扫码或前往「心芽学堂」公众号打开
        </p>
      );
    } else if (UserAgent.isWechat()) {
      content = <p className="distinguish_program">长按识别小程序码</p>;
    }

    return (
      <div className="comp-training-camp-activiy">
        <img src={this.props.main_photo_url} />
        <div id="course_container"></div>
        <ul className="switchTitle" id="switch_tab">
          <li className={this.state.activeSwitch1} onClick={this.onClickTrain}>
            训练营介绍
          </li>
          <li className={this.state.activeSwitch2} onClick={this.onClickCommon}>
            常见问题
          </li>
        </ul>

        <div
          className="train_intro"
          style={{ display: this.state.isDisplayTrainIntro }}
        >
          <div className="markDownWrapper markdown_content">
            {this.props.description ? (
              <ReactMarkdown source={this.props.description} />
            ) : (
              ""
            )}
          </div>
        </div>

        <ProblemFold
          faq={this.props.faq}
          ref={(problemFold) => (this.problemFoldContainer = problemFold)}
        />

        {this.props.is_bought ? (
          this.AfterBuySuccess()
        ) : (
          <div className="training_camp_pay">
            <div className="invite_count_down">
              <div className="before_count_down_buy">
                报名截止时间:
                <br />
                2018.08.13
              </div>
              <div className="train_activity_intro">
                {this.props.limit_users_count == 0 ? (
                  end_time ? (
                    <CountDownTime
                      endTime={new Date(end_time)}
                      overText="邀请时间结束"
                      onEnd={this.handleCountdownEnd}
                    >
                      {({ d, h, m, s }) => {
                        return (
                          <span>
                            距离邀请结束：{h}:{m}:{s}
                          </span>
                        );
                      }}
                    </CountDownTime>
                  ) : (
                    <span>
                      24小时内，每邀请1位新朋友关注「心芽学堂」，立减10元！
                    </span>
                  )
                ) : (
                  ""
                )}

                {this.props.limit_users_count != 0 ? (
                  !this.props.max_count_exceed ? (
                    <span>
                      仅限前 {this.props.remaining_count} 位，报满即止！
                    </span>
                  ) : (
                    <span>本期名额已满</span>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="activity_box_foot">
              <div className="activitiy_price">
                {end_time ? (
                  <p>
                    ¥{finalPrice}
                    <span className="origin_price">
                      &nbsp;&nbsp;原价：
                      <del>¥198</del>
                    </span>
                  </p>
                ) : (
                  <p>
                    ¥{this.props.activity.lowest_price}-{finalPrice}
                    <span className="origin_price">
                      &nbsp;&nbsp;原价：
                      <del>¥198</del>
                    </span>
                  </p>
                )}
                <div className="price_subscribe">购买后不支持退款</div>
              </div>

              {this.props.study_package_group_state == "ok" ? (
                this.props.limit_users_count == 0 ? (
                  finalPrice != 0.0 ? (
                    <div
                      className="free_experience"
                      onClick={this.invitingUser}
                      style={{ display: this.state.inviteHideDisplay }}
                    >
                      {end_time ? "继续邀请" : "开始邀请"}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              {this.props.limit_users_count == 0 ? (
                !this.props.is_bought ? (
                  <div
                    className="activitiy_sign_in"
                    onClick={this.onceAgainConfirm}
                  >
                    {finalPrice != 0 ? "直接报名" : "免费领取"}
                  </div>
                ) : (
                  <div className="activitiy_sign_in">报名成功</div>
                )
              ) : (
                ""
              )}

              {this.props.limit_users_count != 0 ? (
                !this.props.max_count_exceed ? (
                  <div
                    className="activitiy_sign_in"
                    onClick={this.onceAgainConfirm}
                  >
                    直接报名
                  </div>
                ) : (
                  <div className="activitiy_sign_in activity_sign_end">
                    等待下期
                  </div>
                )
              ) : (
                <div
                  className="activitiy_sign_in"
                  onClick={this.onceAgainConfirm}
                >
                  直接报名
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="guide_wechat_follow"
          ref={(wechatFollow) => (this.wechatFollowClick = wechatFollow)}
        >
          <div className="dialog_qrcode_body">
            <div className="btn-close-wechat" onClick={this.onCloseDialog}>
              &times;
            </div>
            <p>
              请先关注公众号
              <br />
              接收学习提醒及报告
            </p>
            <img src={this.props.qrcode_url} />
          </div>
        </div>

        <div
          className="guide_wechat_follow"
          ref={(wechatFollowProgram) =>
            (this.wechatFollowProgramClick = wechatFollowProgram)
          }
        >
          <div className="dialog_qrcode_body">
            <div
              className="btn-close-wechat"
              onClick={this.onCloseDialogProgram}
            >
              &times;
            </div>
            {content}
            <img src={mp_qr_code} />
          </div>
        </div>
      </div>
    );
  }
}

TrainingCampActivity.propTypes = Object.assign({}, SharePoster.propTypes, {
  activity: PropTypes.object.isRequired,
});

TrainingCampActivity.defaultProps = {
  free: "training_camp_activity",
};
