import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ModalSimple.scss";

class ModalSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  componentDidMount() {
    document.querySelector("html, body").classList.add("no_scroll");
  }
  componentWillUnmount() {
    document.querySelector("html, body").classList.remove("no_scroll");
  }

  render() {
    return (
      <div className="modal_simple">
        <div className="modal_mask"></div>
        <div className="modal_content">{this.props.children}</div>
      </div>
    );
  }
}

ModalSimple.propTypes = {};

export default ModalSimple;
