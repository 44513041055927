import React from "react";
import PropTypes from "prop-types";
import { showDialog } from "../utils/helpers";
import Picker from "better-picker";
import { GA } from "../utils/Env";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import Raven from "raven-js";
import "./QuestionnaireX.scss";

const Years = [
  { text: "年", value: -1 },
  { text: "2019", value: 2019 },
  { text: "2018", value: 2018 },
  { text: "2017", value: 2017 },
  { text: "2016", value: 2016 },
  { text: "2015", value: 2015 },
  { text: "2014", value: 2014 },
  { text: "2013", value: 2013 },
  { text: "2012", value: 2012 },
  { text: "2011", value: 2011 },
  { text: "2010", value: 2010 },
  { text: "2009", value: 2009 },
  { text: "2008", value: 2008 },
  { text: "2007", value: 2007 },
  { text: "2006", value: 2006 },
  { text: "2005", value: 2005 },
  { text: "2004", value: 2004 },
  { text: "2003", value: 2003 },
  { text: "2002", value: 2002 },
  { text: "2001", value: 2001 },
  { text: "2000", value: 2000 },
];

const Months = [
  { text: "月", value: -1 },
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "11", value: 11 },
  { text: "12", value: 12 },
];

export default class QuestionnaireX extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      currentQuestion: props.questions[0],
      showResult:
        "activities" in props || "experiences_url" in props ? true : false,
      recommendations:
        props.activities && (props.activities.length ? props.activities : []),
      experiencesUrl: props.experiences_url ? props.experiences_url : "/",
    };
    this.answers = [];
  }

  componentDidMount() {
    const share_url = window.location.href;
    const share_image_url = this.props.share_image_url;
    var title = "快来测一测你家孩子适合上什么课程？";
    var desc = "合适的阶段上合适的课，你家孩子适合上什么课？";

    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        desc: desc,
        link: share_url,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  setAnswers(type) {
    const handleRadio = (selectedQuestionId, checkedOptionId, isCheck) => {
      if (this.answers.length === 0) {
        this.answers.push({
          question_id: selectedQuestionId,
          option_ids: [checkedOptionId],
        });
      } else {
        for (const [index, answer] of this.answers.entries()) {
          if (answer.question_id === selectedQuestionId) {
            this.answers.splice(index, 1);
            break;
          }
        }
        if (isCheck) {
          this.answers.push({
            question_id: selectedQuestionId,
            option_ids: [checkedOptionId],
          });
        }
      }
      console.log(this.answers);
    };

    const handleCheckbox = (selectedQuestionId, selectedOptionId, isCheck) => {
      let answeredQuestionIds = [];
      if (this.answers.length === 0) {
        this.answers.push({
          question_id: selectedQuestionId,
          option_ids: Array(1).fill(selectedOptionId),
        });
      } else {
        for (const [index, answer] of this.answers.entries()) {
          answeredQuestionIds.push(answer.question_id);
          if (answer.question_id === selectedQuestionId) {
            if (answer.option_ids.length === 0 || isCheck) {
              answer.option_ids.push(selectedOptionId);
            } else {
              answer.option_ids.forEach((optionId, idx) => {
                if (optionId === selectedOptionId) {
                  if (!isCheck) {
                    answer.option_ids.splice(idx, 1);
                  }
                }
              });
            }
            break;
          }
        }
        if (
          answeredQuestionIds.length &&
          answeredQuestionIds.indexOf(selectedQuestionId) === -1
        ) {
          this.answers.push({
            question_id: selectedQuestionId,
            option_ids: Array(1).fill(selectedOptionId),
          });
        }
        console.log(this.answers);
      }
    };

    const handleTypeIn = (selectedQuestionId, value) => {
      if (value.length > 0) {
        this.answers.push({
          question_id: selectedQuestionId,
          text: value,
        });
      }
    };

    const handleSelect = (selectedQuestionId, yearMonthArr) => {
      if (this.answers.length === 0 && yearMonthArr.length === 2) {
        yearMonthArr.push("01");
        this.answers.push({
          question_id: selectedQuestionId,
          text: yearMonthArr.join("-"),
        });
      } else {
        if (yearMonthArr.length === 2) {
          for (const [index, answer] of this.answers.entries()) {
            if (answer.question_id === selectedQuestionId) {
              this.answers.splice(index, 1);
              break;
            }
          }
          yearMonthArr.push("01");
          this.answers.push({
            question_id: selectedQuestionId,
            text: yearMonthArr.join("-"),
          });
        }
      }

      console.log(this.answers);
    };

    if (type === "radio") {
      return handleRadio;
    } else if (type === "checkbox") {
      return handleCheckbox;
    } else if (type === "select") {
      return handleSelect;
    } else if (type === "input") {
      return handleTypeIn;
    }
  }

  handleInputChange = (e) => {
    const inputType = e.currentTarget.type;
    const questionId = parseInt(e.currentTarget.dataset.questionId);
    const checkedOptionId = parseInt(e.currentTarget.dataset.optionId);
    this.setAnswers.call(this, inputType)(
      questionId,
      checkedOptionId,
      e.currentTarget.checked
    );

    console.log(this.state);
  };

  handleTextChange = (e) => {
    const inputType = "input";
    const questionId = parseInt(e.currentTarget.dataset.questionId);
    this.setAnswers.call(this, inputType)(questionId, e.currentTarget.value);
    // TODO: 重复
    console.log(this.state);
  };

  getInputType(question) {
    const { mode, single_selection } = question;
    if (mode === "customized") {
      return "";
    }
    if (mode === "standard" && single_selection) {
      return "单选";
    }
    if (mode === "standard" && !single_selection) {
      return "多选";
    }
    if (mode === "standardx") {
      return "附加内容";
    }
    if (mode === "input") {
      return "文本";
    }
    if (mode === "text") {
      return "长文本";
    }
  }

  renderOptions(question) {
    const getGroupName = function (name) {
      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
      };
      return `${name.split("").splice(0, 4).join("")}-${getRandomInt(1, 10)}`;
    };
    const baby = this.props.baby;
    const { mode, single_selection } = question;
    if (mode === "customized") {
      return (
        <YearMonthSelector
          setAnswer={this.setAnswers.call(this, "select")}
          questionId={question.id}
          defaultYear={
            baby && baby.day_of_birth ? baby.day_of_birth.slice(0, 4) : ""
          }
          defaultMonth={
            baby && baby.day_of_birth ? baby.day_of_birth.slice(5, 7) : ""
          }
        />
      );
    } else if (mode === "input") {
      return (
        <div className="question-text">
          <input
            name={getGroupName(question.name)}
            data-question-id={question.id}
            onBlur={this.handleTextChange}
            type="text"
            placeholder=""
          />
        </div>
      );
    } else if (mode === "text") {
      return (
        <div className="question-text">
          <textarea
            name={getGroupName(question.name)}
            data-question-id={question.id}
            onBlur={this.handleTextChange}
            rows="5"
            placeholder="请填写"
          />
        </div>
      );
    } else if (mode === "standard") {
      return (
        <OptionGroup
          name={getGroupName(question.name)}
          handleInputChange={this.handleInputChange}
          questionId={question.id}
        >
          {question.options.map((optionItem, index) => {
            return single_selection ? (
              <RadioItem
                key={index}
                optionId={parseInt(optionItem.id)}
                hint={optionItem.hint}
              >
                {optionItem.option}
              </RadioItem>
            ) : (
              <CheckBoxItem
                key={index}
                optionId={parseInt(optionItem.id)}
                hint={optionItem.hint}
              >
                {optionItem.option}
              </CheckBoxItem>
            );
          })}
        </OptionGroup>
      );
    } else if (mode === "standardx") {
      return (
        <div className="question_standardx">
          <OptionGroup
            name={getGroupName(question.name)}
            handleInputChange={this.handleInputChange}
            questionId={question.id}
          >
            {question.options.map((optionItem, index) => {
              return single_selection ? (
                <RadioItem
                  key={index}
                  len={question.options.length}
                  optionId={parseInt(optionItem.id)}
                  hint={optionItem.hint}
                >
                  {optionItem.option}
                </RadioItem>
              ) : (
                <CheckBoxItem
                  key={index}
                  len={question.options.length}
                  optionId={parseInt(optionItem.id)}
                  hint={optionItem.hint}
                >
                  {optionItem.option}
                </CheckBoxItem>
              );
            })}
          </OptionGroup>
          <div className="question-text hide">
            <textarea
              name={getGroupName(question.name)}
              data-question-id={question.id}
              onBlur={this.handleTextChange}
              rows="5"
              placeholder="请填写"
            />
            {/* <input type="text" placeholder="请填写" name={getGroupName(question.name)} data-question-id={question.id} onBlur={this.handleTextChange} /> */}
          </div>
        </div>
      );
    }
  }

  getQuestionIndex = () => {
    const { questions } = this.props;
    const { currentQuestion } = this.state;
    let index = questions.indexOf(currentQuestion);
    return index === -1 ? 1 : index + 1;
  };

  checkAnswer = () => {
    console.log(this.answers);
    const { questions } = this.props;
    const { currentQuestion } = this.state;
    let index = questions.indexOf(currentQuestion);
    let currentQuestionId = { ...currentQuestion }.id;
    let answeredQuestionIds = [];
    let showAlert = false;
    let dialogOptions = {
      text: "答案不能为空哦",
      mainLabel: "好的",
      mainCallback: () => {},
    };
    if (this.answers.length === 0) {
      showAlert = true;
    }
    for (const [idx, answer] of this.answers.entries()) {
      answeredQuestionIds.push(answer.question_id);
      if (answer.question_id === currentQuestionId) {
        if (!answer.option_ids && !answer.text) {
          showAlert = true;
          break;
        }
        if (
          answer.text &&
          (!answer.text.split("-")[0] || !answer.text.split("-")[1])
        ) {
          showAlert = true;
          break;
        }
        if (answer.option_ids && answer.option_ids.length === 0) {
          showAlert = true;
          break;
        }
      }
    }

    if (
      answeredQuestionIds.length &&
      answeredQuestionIds.indexOf(currentQuestionId) === -1
    ) {
      showAlert = true;
    }
    if (showAlert) {
      showDialog.call(this, dialogOptions);
    }
    return showAlert ? !!!showAlert : index;
  };

  goNext = () => {
    let result = this.checkAnswer();
    if (!(typeof result === "boolean")) {
      this.setNextQuestion(result + 1);
    }
  };

  setNextQuestion = (id) => {
    this.setState(
      {
        currentQuestion: this.props.questions[id],
      },
      () => {
        let index = this.props.questions.indexOf(this.state.currentQuestion);
        GA(
          "MiniExams",
          `Q${index === -1 ? 1 : index + 1}`,
          this.props.mini_exam_id
        );
      }
    );
  };

  submitAnswer = () => {
    // let result = this.checkAnswer()
    // if (typeof result === 'boolean') return

    let tmp = {};
    this.answers.map((answer) => {
      let key = answer["question_id"];
      tmp[key] = Object.assign({}, tmp[key], answer);
    });

    let mergedAnswers = Object.values(tmp);

    if (mergedAnswers.length < this.props.questions.length) {
      this.toast = ToastManager.showToastError("请填写完整后再提交");
      return;
    }
    this.toast = ToastManager.showLoading("提交中...");

    console.log(this.answers, mergedAnswers);

    request({
      url: this.props.questionnaire_result_url,
      method: "POST",
      headers: csrfHeaders,
      data: mergedAnswers,
    })
      .then((result) => {
        this.toast.cancel();

        if (result.data.redirect_url) {
          let dialogOptions = {
            text: "提交成功，非常感谢",
            mainLabel: "确定",
            mainCallback: () => {
              window.location.href = result.data.redirect_url;
            },
          };
          showDialog.call(this, dialogOptions);
          return;
        }
        console.log(result.data.activities);
        this.setState({
          recommendations: result.data.activities || [],
          experiencesUrl: result.data.experiences_url,
          showResult: true,
        });
        GA("MiniExams", "Complete", this.props.mini_exam_id);
      })
      .catch((err) => {
        this.toast.cancel();
        Raven.captureException(err);
      });
  };

  start = () => {
    this.setState(
      {
        isStart: true,
      },
      () => {
        GA("MiniExams", "Enter", this.props.mini_exam_id);
      }
    );
    this.setNextQuestion(0);
  };

  retry = () => {
    this.setState(
      () => ({
        showResult: false,
      }),
      () => {
        this.answers = [];
        GA("MiniExams", "Replay", this.props.mini_exam_id);
      }
    );
    this.setNextQuestion(0);
  };

  goExperience = () => {
    window.location.href = this.state.experiencesUrl || "/";
  };

  renderBody = (
    showResult,
    isStart,
    { currentQuestion, questions, description, questionIndex, questionType }
  ) => {
    if (showResult) {
      return (
        <ResultPage
          recommendations={this.state.recommendations}
          retry={this.retry}
          goExperience={this.goExperience}
        />
      );
    }
    // return <Brief
    //   description={description}
    //   start={this.start}
    // />
  };

  render() {
    const { currentQuestion, isStart, showResult } = this.state;
    const { questions, description } = this.props;
    const questionIndex = this.getQuestionIndex();
    const questionType = this.getInputType(currentQuestion);
    const renderBody = this.renderBody(showResult, isStart, {
      currentQuestion,
      questions,
      description,
      questionIndex,
      questionType,
    });
    const QuestionList = questions.map((question, index) => {
      return (
        <QuestionItem
          key={index}
          questionIndex={index}
          questionName={question.name}
          render={this.renderOptions(question)}
          questionType={this.getInputType(question)}
          questionLength={questions.length}
          submitAnswer={this.submitAnswer}
        />
      );
    });
    const SubmitButton = ButtonFactory({
      action: this.submitAnswer,
      text: "提交",
    });

    return (
      <div className="comp_questionnaire_x">
        <div
          className="question-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {/* <div className={`title_header_bg ${showResult ? 'result_header_bg' : ''}`}></div> */}
        {QuestionList}
        {/* {renderBody} */}
        <SubmitButton />
        {/* <div className="footer">
          
          <a className="brand" href="/?ref=questionnaire">心芽学堂</a>
          <span className="dot_divider">·</span>
          出品
        </div> */}
      </div>
    );
  }
}

const Brief = ({ start, description }) => {
  const StartButton = ButtonFactory({
    action: start,
    text: "开始",
    extendedStyle: "start_button",
  });
  return (
    <div className="question_ground">
      <div className="question_body">
        <div className="question_content brief">{description}</div>
        <StartButton />
      </div>
      <div className="question_footer"></div>
    </div>
  );
};

const ResultPage = ({ recommendations, goExperience, retry }) => {
  console.log(recommendations);
  if (recommendations.length) {
    return (
      <div className="question_ground">
        <div className="question_body">
          <p className="recommendation_text">根据测试结果，推荐课程</p>
          <div className="recommend_list">
            {recommendations.map((activity, index) => {
              let subAct = activity.sub_study_package_activities;
              if (subAct.length) {
                return subAct.map((sub, idx) => {
                  return (
                    <a
                      className="activity_item"
                      key={`${activity.id}-${sub.id}`}
                      href={activity.buy_url}
                    >
                      <div
                        className="activity_brief"
                        style={{
                          backgroundImage: `url(${activity.photo_url})`,
                        }}
                      >
                        <p className="act_name">{activity.name}</p>
                        {<p className="sub_name">{sub.name}</p>}
                        <div className="to_bottom">
                          <p className="sub_brief">{sub.brief}</p>
                          <p className="day_count">
                            <span>{sub.course_day_count}天</span>·
                            <span>
                              {sub.course_type === "poetry"
                                ? `${sub.media_courses_count}首诗`
                                : `${sub.media_lessons_count}节课`}
                            </span>
                          </p>
                        </div>
                      </div>
                      {!!sub.hint && (
                        <div className="activity_hint">{sub.hint}</div>
                      )}
                    </a>
                  );
                });
              } else {
                return (
                  <a
                    className="activity_item none_hint_height"
                    key={`${activity.id}`}
                    href={activity.buy_url}
                  >
                    <div
                      className="activity_brief none_hint"
                      style={{ backgroundImage: `url(${activity.photo_url})` }}
                    >
                      <p className="act_name">{activity.name}</p>
                    </div>
                  </a>
                );
              }
            })}
          </div>
        </div>
        <div className="question_footer retry_link" onClick={retry}>
          重试测评
        </div>
      </div>
    );
  } else {
    return (
      <div className="question_ground">
        <div className="question_body">
          <div className="question_content result">
            <p>暂时没有课程合适你家孩子，</p>
            <p>建议先去听听其他家长的经验分享。</p>
          </div>
        </div>
      </div>
    );
  }
};

const QuestionItem = ({
  questionIndex,
  questionName,
  render,
  questionType,
  goNext,
  questionLength,
  submitAnswer,
}) => {
  const ConfirmButton = ButtonFactory({ action: goNext, text: "下一题" });
  return (
    <div className="question_ground">
      <div className="question_body">
        <div className="question_content">
          <p className="question_index">Q{questionIndex + 1}</p>
          <p className="question_name">
            <span>{questionName}</span>
            {/* <span>
              {
                questionType ? `（${questionType}）` : ''
              }
            </span> */}
          </p>
        </div>
        {render}
      </div>
      {/* <div className="question_footer">
        {`${questionIndex}/${questionLength}`}
      </div> */}
    </div>
  );
};

class OptionGroup extends React.Component {
  constructor() {
    super();
    this.renderChildren = this.renderChildren.bind(this);
  }
  renderChildren() {
    return React.Children.map(this.props.children, (child, index) => {
      if (child.type) {
        return React.cloneElement(child, {
          name: this.props.name,
          id: index,
          handleInputChange: this.props.handleInputChange,
          questionId: this.props.questionId,
        });
      }
    });
  }
  render() {
    return <div className="group">{this.renderChildren()}</div>;
  }
}

class RadioItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  triggerLabel = (e) => {
    // $(`#${this.props.name}-${this.props.id}-label`).trigger("click")
    // this.props.handleInputChange
    let $this = $(`#${this.props.name}-${this.props.id}`);
    let $labels = $this.parent("label");
    $labels.siblings().removeClass("active");
    $labels.addClass("active");

    if (this.props.len) {
      $this.parents(".group").next().attr("class", "question-text hide");
      if (this.props.id == this.props.len - 1) {
        $this.parents(".group").next().toggleClass("hide");
      }
    }
  };
  render() {
    const { active } = this.state;
    return (
      <label
        className={active ? "input_item active" : "input_item"}
        onClick={this.triggerLabel}
      >
        <input
          type="radio"
          name={this.props.name}
          id={`${this.props.name}-${this.props.id}`}
          onChange={this.props.handleInputChange}
          data-question-id={this.props.questionId}
          data-option-id={this.props.optionId}
          className="regular-radio big-radio"
          key={`${this.props.name}-${this.props.id}-input`}
        />
        {/* <label htmlFor={`${this.props.name}-${this.props.id}`} ref={label => {
        this.labelRef = label
      }} id={`${this.props.name}-${this.props.id}-label`} key={`${this.props.name}-${this.props.id}-label`}></label> */}
        <div className="option_hit">
          <span className="option">{this.props.children}</span>
          {this.props.hint ? (
            <span className="hint">{`提示：${this.props.hint}`}</span>
          ) : null}
        </div>
      </label>
    );
  }
}

RadioItem.propTypes = {
  children: PropTypes.string.isRequired,
};

class CheckBoxItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  triggerLabel = (e) => {
    // this.setState((prevState) => ({ active: !prevState.active }))
    let $this = $(e.target);
    $this.toggleClass("active");

    if (this.props.len) {
      if (this.props.id == this.props.len - 1) {
        $this.parent(".group").next().toggleClass("hide");
      }
    }
  };
  render() {
    const { active } = this.state;
    return (
      <label
        className={active ? "input_item active" : "input_item"}
        onClick={this.triggerLabel}
      >
        <input
          type="checkbox"
          name={this.props.name}
          id={`${this.props.name}-${this.props.id}`}
          onChange={this.props.handleInputChange}
          data-question-id={this.props.questionId}
          data-option-id={this.props.optionId}
          className="regular-checkbox big-checkbox"
          key={`${this.props.name}-${this.props.id}-input`}
        />
        {/* <label htmlFor={`${this.props.name}-${this.props.id}`} ref={label => {
        this.labelRef = label
      }} id={`${this.props.name}-${this.props.id}-label`} key={`${this.props.name}-${this.props.id}-label`}></label> */}
        <div className="option_hit">
          <span className="option">{this.props.children}</span>
          {this.props.hint ? (
            <span className="hint">{`提示：${this.props.hint}`}</span>
          ) : null}
        </div>
      </label>
    );
  }
}

CheckBoxItem.propTypes = {
  children: PropTypes.string.isRequired,
};

class YearMonthSelector extends React.Component {
  constructor(props) {
    super(props);
    this.answer = {};
  }

  generatePicker(
    defaultValue,
    pickerDocElement,
    arrayData,
    showAlert,
    showSelectTex
  ) {
    let picker = new Picker({
      data: [arrayData],
      selectedIndex: defaultValue && [
        arrayData.findIndex(
          (item) => defaultValue == { ...item }.text.padStart(2, 0)
        ),
      ],
    });
    picker.on("picker.select", (selectedVal, selectedIndex) => {
      if (selectedVal[0] === -1 || selectedVal[1] === -1) {
        showAlert();
        return;
      }
      showSelectTex(selectedIndex);
    });

    const init = () => {
      pickerDocElement.addEventListener("click", function () {
        picker.show();
      });
    };

    return {
      init,
    };
  }
  setAnswer = (withDefault = false) => {
    const { defaultYear, defaultMonth, questionId, setAnswer } = this.props;

    if (withDefault) {
      let answer = [
        defaultYear ? defaultYear : "",
        defaultMonth + "" ? (defaultMonth + "").padStart(2, 0) : "",
      ];
      if (defaultYear) {
        this.answer.year = defaultYear;
      }
      if (defaultMonth) {
        this.answer.month = defaultMonth;
      }
      setAnswer(questionId, answer);
      return;
    }
    if (this.answer.year && this.answer.month) {
      let answer = [this.answer.year, (this.answer.month + "").padStart(2, 0)];
      setAnswer(questionId, answer);
    }
    console.log(111, this.answers);
  };
  componentDidMount() {
    const { defaultYear, defaultMonth } = this.props;
    this.setAnswer(true);
    let pickerYearEl = document.getElementById("year_selector");
    let pickerMonthEl = document.getElementById("month_selector");
    const emptyYearAlert = () => {
      let dialogOptions = {
        text: "年份不能为空哦",
        mainLabel: "好的",
        mainCallback: () => {},
      };
      showDialog.call(this, dialogOptions);
    };
    const emptyMonthAlert = () => {
      let dialogOptions = {
        text: "月份不能为空哦",
        mainLabel: "好的",
        mainCallback: () => {},
      };
      showDialog.call(this, dialogOptions);
    };
    const yearToShow = (selectedIndex) => {
      pickerYearEl.firstElementChild.style.cssText += "color:black";
      pickerYearEl.firstElementChild.innerText = Years[selectedIndex[0]].text;
      this.answer.year = Years[selectedIndex[0]].text;
      this.setAnswer();
    };
    const monthToShow = (selectedIndex) => {
      pickerMonthEl.firstElementChild.style.cssText += "color:black";
      pickerMonthEl.firstElementChild.innerText = Months[selectedIndex[0]].text;
      this.answer.month = Months[selectedIndex[0]].text;
      this.setAnswer();
    };

    this.generatePicker(
      defaultYear ? defaultYear : "2010",
      pickerYearEl,
      Years,
      emptyYearAlert,
      yearToShow
    ).init();
    this.generatePicker(
      defaultMonth ? defaultMonth : "06",
      pickerMonthEl,
      Months,
      emptyMonthAlert,
      monthToShow
    ).init();
  }
  render() {
    const { defaultYear, defaultMonth } = this.props;
    return (
      <div className="year_month_item">
        <div className="year_selector" id="year_selector">
          <span className={`selector ${defaultYear ? "active" : ""}`}>
            {defaultYear ? defaultYear : "请选择"}
          </span>
          <span>年</span>
        </div>
        <div className="month_selector" id="month_selector">
          <span className={`selector ${defaultMonth ? "active" : ""}`}>
            {defaultMonth ? defaultMonth : "请选择"}
          </span>
          <span>月</span>
        </div>
      </div>
    );
  }
}

const ButtonFactory = ({ action, text, extendedStyle }) => {
  return class BaseButton extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <div className={`confirm_button ${extendedStyle}`} onClick={action}>
          {text}
        </div>
      );
    }
  };
};
