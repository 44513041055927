import React from "react";
import { request, csrfHeaders } from "../utils/request";
import PropTypes from "prop-types";
import UserAgent from "../utils/UserAgent";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
import { ToastManager } from "../utils/Toast";
import CityArea from "../utils/city_area/CityArea.jsx";

import "./GiftCommodities.scss";

const defaultAreaText = "请选择省市区";

export default class GiftCommodies extends React.Component {
  constructor(props) {
    super(props);

    console.log(props);

    if (this.props.image_url === "") {
      alert("没有课程奖励");
      window.history.back();
      return;
    } else if (this.props.isBack) {
      alert("该礼品到店自取，无需填写地址");
      window.history.back();
      return;
    } else {
      this.state = {
        name: this.props.name || "",
        phone_num: this.props.phone_num || "",
        address: this.props.address || "",
        note: this.props.note || "",
        babyName: this.props.babyName || "",
        submitted: false,
        commodiesState: false,
        area: this.props.area,
        editable: !this.props.tracking_no,
      };
    }
  }
  handleClear() {
    this.setState({
      name: '',
      phone_num: '',
      address: '',
      note: '',
      babyName: '',
      selectValue: null,
      area: null,
    })
  }
  handleSubmit() {
    event.preventDefault();
    // console.log("image_url: " + this.props.image_url)
    let { selectProvinceId, selectSubCityId } = this.state;
    const { area } = this.props;

    if (this.state.name === "") {
      alert("姓名不能为空");
    } else if (!/^1[23456789]\d{9}$/.test(this.state.phone_num)) {
      alert("手机号填写有误");
    } else if (
      document.getElementsByClassName("text")[0].innerText == defaultAreaText
    ) {
      alert("请选择相应的省市区");
    } else if (this.state.address === "") {
      alert("具体地址不能为空");
    } else if (this.props.gift_type === 3 ? "" : this.state.babyName === "") {
      alert("宝宝姓名不能为空");
    } else {
      this.toast = ToastManager.showLoading("提交中...");
      let params = {
        name: this.state.name,
        phone_num: this.state.phone_num,
        address: this.state.address,
        note: this.state.note,
        gift_id: this.props.gift_id,
        baby_name: this.state.babyName,

        province_id: parseInt(
          selectProvinceId ? selectProvinceId : area && area.province.id
        ),
        city_id: parseInt(
          selectSubCityId ? selectSubCityId : area && area.city.id
        ),
      };
      request({
        url: this.props.address_url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          this.toast.cancel();
          let data = resp.data;
          console.log("data: ", data);

          if (data.err_code) {
            alert(data.err_msg);
            return;
          } else {
            window.location = this.props.redirect_url;
            // this.setState({
            //   submitted: true,
            // });
          }
        })
        .catch((err) => {
          alert(err);
          this.toast.cancel();
        });
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        // console.log(this.state)
      }
    );
  }

  // 获取 CityArea 组件的索引
  getScrollAreaTop = (selectIndex, selectSubIndex) => {
    this.setState({
      selectIndex,
      selectSubIndex,
    });
  };

  selectCityAreaClick = () => {
    if (!this.state.editable) {
      return;
    }

    this.setState(
      {
        displayPickerDialog: true,
        commodiesState: true,
      },
      () => {
        // 点击之后唤醒scrollTop
        $("#province_list").scrollTop(40 * this.state.selectIndex);
        $("#sub_city_list").scrollTop(40 * this.state.selectSubIndex);
      }
    );
  };

  displayPickerDialog = (bool = false) => {
    this.setState({
      displayPickerDialog: bool,
    });
  };

  // 获取 CityArea模块中的省市
  getAddressCityId = (
    selectProvinceId,
    selectSubCityId,
    selectValue,
    selectSubValue
  ) => {
    this.setState({
      selectProvinceId,
      selectSubCityId,
      selectValue,
      selectSubValue,
    });
  };

  getCloseDialog = () => {
    this.setState({
      commodiesState: false,
    });
  };

  render() {
    const { gift_type, dispatch_days, entity_dispatch_note } = this.props;
    let { selectValue, selectSubValue, editable } = this.state;

    let noteContent = (
      <div className="success-attention">提交成功，请注意查收快递！</div>
    );
    if (gift_type == 3) {
      noteContent = (
        <div className="success_traning_note">
          <span>提交成功！</span>
          <p>纸质练习册将于【七个工作日】之内通过快递发出，请留意短信通知。</p>
        </div>
      );
    }
    // if (gift_type == 5 && dispatch_days) {
    //   noteContent = <div className="success-attention">{`提交成功，我们将按报名的先后顺序，在${dispatch_days}个工作日内安排发货。`}</div>
    // }
    if (dispatch_days) {
      noteContent = (
        <div className="success-attention">
          {gift_type == 6 ? (
            <div>
              <span>提交成功！</span>
              <p>{`我们将在${dispatch_days}个工作日内安排发货。`}</p>
            </div>
          ) : (
            `提交成功，我们将按报名的先后顺序，在${dispatch_days}个工作日内安排发货。`
          )}
        </div>
      );
    }

    const content = this.state.submitted ? (
      <div className="traning_note_box">
        {
          // noteContent
          <div className="success-attention">提交成功！</div>
        }
        {this.props.redirect_url && (
          <a className="traning_declare" href={this.props.redirect_url}>
            {gift_type == 5 || gift_type == 6
              ? "确定"
              : "查看课程安排"}
          </a>
        )}
      </div>
    ) : (
      <div>
        <p className="gift-attention">{editable ? '请填写快递信息' : '快递信息'}</p>
        <div className="address-form-wrapper">
          <div className="formField">
            {gift_type === 3 ? (
              ""
            ) : (
              <div className="field-wrapper">
                <p>宝宝姓名：</p>
                <input
                  type="text"
                  name="babyName"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.babyName}
                  placeholder="宝宝姓名"
                  disabled={!editable}
                />
              </div>
            )}

            <div className="field-wrapper">
              <p>收件人姓名：</p>
              <input
                type="text"
                name="name"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.name}
                placeholder="收件人姓名"
                disabled={!editable}
              />
            </div>
            <div className="field-wrapper">
              <p>手机：</p>
              <input
                type="text"
                name="phone_num"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.phone_num}
                placeholder="手机号"
                disabled={!editable}
              />
            </div>
            <div className="field-wrapper">
              <p>省市区：</p>
              {/* <div className="sign_school_text" onClick={this.handleChooseClick}>
                请选择报名学校
              </div> */}
              {selectValue != undefined ? (
                <div
                  className="text area_text"
                  onClick={this.selectCityAreaClick}
                >
                  {`${selectValue}  ${selectSubValue}`}
                </div>
              ) : this.state.area ? (
                <div
                  className="text area_text"
                  onClick={this.selectCityAreaClick}
                >
                  {`${this.state.area.province.name}  ${this.state.area.city.name}`}
                </div>
              ) : (
                <div className="text" onClick={this.selectCityAreaClick}>
                  请选择省市区
                </div>
              )}
            </div>
            <div className="field-wrapper">
              <p>详细地址：</p>
              <textarea
                type="text"
                name="address"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.address}
                placeholder="请填写详细的收货地址"
                disabled={!editable}
              />
            </div>
            <div className="field-wrapper">
              <p>备注：</p>
              <textarea
                type="text"
                name="note"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.note}
                placeholder="备注"
                disabled={!editable}
              />
            </div>
          </div>
          {editable &&
            <div className="container-buttons">
              {this.props.address &&
                <button className="clearBtn" onClick={this.handleClear.bind(this)}>
                  重新填写
                </button>
              }
              <button className="submitBtn" style={{ width: this.props.address ? 120 : 295 }} onClick={this.handleSubmit.bind(this)}>
                确定
              </button>
            </div>
          }
        </div>
      </div>
    );

    return (
      <div className="comp-gift-commodies">
        <div className="gift-wrapper">
          <img src={this.props.image_url} />
          <p>{this.props.content}</p>
          <p className="entity_dispatch_note">{entity_dispatch_note}</p>
        </div>
        {content}
        {
          <CityArea
            provinces={this.props.provinces}
            area={this.props.area}
            getAddressCityId={this.getAddressCityId}
            displayPickerDialog={this.displayPickerDialog}
            giftCommodies={true}
            propCommodiesState={this.state.commodiesState}
            getCloseDialog={this.getCloseDialog}
            getScrollAreaTop={this.getScrollAreaTop}
          />
        }
      </div>
    );
  }
}
GiftCommodies.propTypes = {
  image_url: PropTypes.string.isRequired,
  address_url: PropTypes.string.isRequired,
};
