import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import styled from "styled-components";
import { ToastManager } from "../../utils/Toast";
import Button from "../../../admin-utils/Button";
import Input from "../../../admin-utils/Input";
import DropdownMenu, {
  MenuItem,
  Menu,
  DropdownButton,
} from "../../../admin-utils/DropdownMenu";

const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  border-radius: 200px;
`;

const StyleInput = styled(Input)`
  & {
    margin-top: 10px;
    height: 28px;
  }
`;

const NoneDate = styled.li`
  color: #337ab7;
  margin-top: 10px;
  text-align: center;
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: 20px;
  margin-top: 10px;
  ${MenuItem} {
    text-align: center;
  }
  ${Menu} {
    padding: 10px;
    overflow-y: initial !important;
    overflow-x: initial !important;
  }
  &:first-of-type {
    margin-left: 0;
  }
  /* &:nth-of-type(2) {
    margin-right: 20px;
  } */
  &:last-of-type {
    /* margin-left: 0; */
    ${DropdownButton} {
      min-width: 150px;
    }
    ${Menu} {
      padding: 10px;
      width: 200px;
    }
  }
`;

const AUDIT_STATES = ["待审核", "已审核", "所有日志"];

const TableFilterBtnGroup = memo(
  ({
    consultants,
    setAuditState,
    activitySources,
    isShowAuditStateFilter,
    isShowConsultantFilter,
    isShowDateFilter,
    isShowActivitySources,
  }) => {
    // console.log(
    //   consultants,
    //   setAuditState,
    //   isShowAuditStateFilter,
    //   isShowConsultantFilter,
    //   isShowDateFilter
    // );
    const {
      query: {
        date_from,
        date_to,
        course_consultant_id,
        audit_state,
        activity_source,
      },
    } = queryString.parseUrl(window.location.href);

    const [consultantId, setConsultantId] = useState(
      course_consultant_id ?? -1
    );
    console.log("date_from", date_from);
    const [startDate, setStartDate] = useState(date_from ?? "");
    // const [filterRecords, setFilterRecord] = useState(records);
    const [endDate, setEndDate] = useState(date_to ?? "");
    const [reviewStatusFilterOption, setReviewStatusFilterOption] = useState(
      audit_state ? audit_state * 1 : 0
    );
    const [isChooseDate, setIsChooseDate] = useState(false);
    const [activitySource, setActivitySource] = useState(
      activity_source ? activity_source * 1 + 1 : 0
    );

    const pageMounted = useRef();

    const onInputStartTime = useCallback((value) => {
      setStartDate(value);
      // console.log(value);
    }, []);

    const onInputEndTime = useCallback((value) => {
      setEndDate(value);
      // console.log(value);
    }, []);

    const onNoneDateClick = useCallback(() => {
      setEndDate("");
      setStartDate("");
      setIsChooseDate(true);
    }, []);

    const handleActivitySources = useCallback((index) => {
      setActivitySource(index);
    }, []);

    const onChooseDate = () => {
      setIsChooseDate(true);
    };

    const handleConsultantClick = useCallback(
      (clickedOptionIndex) => {
        setConsultantId(consultants[clickedOptionIndex]?.id ?? -1);
      },
      [consultants]
    );

    const handleReviewMenuOptionClick = useCallback(
      (clickedOptionIndex) => {
        // console.log(clickedOptionIndex)
        setReviewStatusFilterOption(clickedOptionIndex);
        setAuditState && setAuditState(clickedOptionIndex);
      },
      [setAuditState]
    );

    const renderOptions = useCallback(() => {
      return (
        <React.Fragment>
          <StyleInput
            isDatePicker
            defaultValue={startDate}
            format="yyyy-mm-dd"
            placeholder="起始日期"
            onChange={onInputStartTime}
          />
          <StyleInput
            isDatePicker
            defaultValue={endDate}
            format="yyyy-mm-dd"
            placeholder="截止日期"
            onChange={onInputEndTime}
          />
          <StyledButton className="btn-primary" onClick={onChooseDate}>
            确定
          </StyledButton>
          <li
            role="separator"
            className="divider"
            style={{ marginTop: 10 }}
          ></li>
          <NoneDate onClick={onNoneDateClick}>所有日期</NoneDate>
        </React.Fragment>
      );
    }, [endDate, onInputEndTime, onInputStartTime, onNoneDateClick, startDate]);

    useEffect(() => {
      if (pageMounted.current) {
        console.log("consultantId", consultantId);
        ToastManager.showLoading("加载中...");
        const { query, url } = queryString.parseUrl(window.location.href);
        delete query.page;

        if (isShowAuditStateFilter) {
          query.audit_state = reviewStatusFilterOption;
        }

        if (isShowConsultantFilter) {
          query.course_consultant_id =
            consultantId === -1 ? null : consultantId;
        }

        if (isShowDateFilter) {
          query.date_from = startDate.replace(/\./g, "-");
          query.date_to = endDate.replace(/\./g, "-");
        }

        if (isShowActivitySources) {
          query.activity_source =
            activitySources[activitySource].id === -1
              ? null
              : activitySources[activitySource].id;
        }

        window.location.href = url + "?" + queryString.stringify(query);
        console.log(window.location.href);
      } else {
        pageMounted.current = true;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      reviewStatusFilterOption,
      consultantId,
      isChooseDate,
      endDate,
      activitySource,
    ]);

    return (
      <>
        {isShowAuditStateFilter && (
          <StyledDropdownMenu
            defaultActiveOptionIndex={audit_state ? audit_state * 1 : 0}
            options={AUDIT_STATES}
            onOptionClick={handleReviewMenuOptionClick}
            isOptionClickedChange
          />
        )}
        {isShowConsultantFilter && (
          <StyledDropdownMenu
            // title={"所有顾问"}
            defaultActiveOptionIndex={
              course_consultant_id
                ? // eslint-disable-next-line eqeqeq
                  consultants.findIndex((c) => c.id == course_consultant_id)
                : 0
            }
            onOptionClick={handleConsultantClick}
            options={consultants.map((c) => c.name)}
            isOptionClickedChange
          />
        )}
        {isShowDateFilter && (
          <StyledDropdownMenu
            title={
              startDate || endDate ? `${startDate} - ${endDate}` : "所有日期"
            }
            renderOptions={renderOptions}
          />
        )}

        {isShowActivitySources && (
          <StyledDropdownMenu
            defaultActiveOptionIndex={
              activity_source ? activity_source * 1 + 1 : 0
            }
            options={activitySources.map((s) => s.name)}
            onOptionClick={handleActivitySources}
            isOptionClickedChange
          />
        )}
      </>
    );
  }
);

TableFilterBtnGroup.propTypes = {
  activitySources: PropTypes.array,
  consultants: PropTypes.array,
  setAuditState: PropTypes.func,
  isShowAuditStateFilter: PropTypes.bool,
  isShowConsultantFilter: PropTypes.bool,
  isShowDateFilter: PropTypes.bool,
  isShowActivitySources: PropTypes.bool,
};

export default TableFilterBtnGroup;
