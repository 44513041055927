import React from "react";
import PropTypes from "prop-types";

import GiftsDialogProxy from "./GiftsDialog";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";

import "./FloatingGiftBtn.scss";

export default class FloatingGiftBtn extends React.Component {
  onClick = (e) => {
    if (typeof ga === "function") {
      switch (this.props.action) {
        case "FreeExperience":
          ga("send", "event", "Users", "Free", "Experiences");
          break;
        case "FreeExperienceAlbum":
          ga("send", "event", "Users", "Free", "ExperienceAlbums");
          break;
      }
    }

    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          free: this.props.free,
        });
      }
      this.sharePosterProxy.open();
    } else {
      new GiftsDialogProxy().open();
    }
  };

  render() {
    return (
      <a
        className="comp-floating-gift-btn"
        href="javascript:void(0);"
        onClick={this.onClick}
      ></a>
    );
  }
}

FloatingGiftBtn.propTypes = Object.assign({}, SharePoster.propTypes, {
  action: PropTypes.oneOf(["FreeExperience", "FreeExperienceAlbum"]),
});

FloatingGiftBtn.defaultProps = {
  free: "normal",
};
