import styled from "styled-components";

import icon_small_ticket from "promotion/icon_small_ticket.png";
import icon_ticket from "promotion/icon_ticket.png";
import icon_big_ticker from "promotion/icon_big_ticker.png";
import icon_banner_bottom from "promotion/icon_banner_bottom.png";
import icon_left_guide from "promotion/icon_left_guide.png";
import icon_right_guide from "promotion/icon_right_guide.png";

export const StyledPromotionActivityContainer = styled.div`
  min-height: 100vh;
  background-color: #ffbf3f;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;
export const StyledActivityBanner = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: url(${icon_banner_bottom}) bottom center;
    background-size: 100% 100%;
    width: 100%;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  img {
    width: 100%;
  }
  .available_coupons_container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 85px;
    @media screen and (orientation: landscape) and (min-width: 1024px) {
      bottom: 120px;
    }
    .available_coupons_box {
      position: relative;
      text-align: center;
      img {
        width: 136px;
        height: 62px;
      }
      .available_coupons_text {
        background-color: #f7d8ac;
        max-width: 190px;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        margin: -8px auto 0;
        padding: 0 10px;
        border: 3px solid #cf9f74;
        color: #744134;
        /* font-size: 16px; */
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-around;
        white-space: nowrap;
        .text {
          background: url(${icon_big_ticker}) no-repeat right center/22px;
          min-width: 130px;
        }
        span {
          color: #fc6616;
          padding-left: 10px;
        }
      }
    }
  }
`;

export const StyledGiftCenter = styled.div`
  margin: 0 15px;
  padding-bottom: 50px;
`;
export const StyledTopBanner = styled.div`
  position: relative;
  img {
    width: 100%;
    height: 75px;
    display: block;
  }
  span {
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    color: #fff;
    padding-top: 5px;
    top: 0;
  }
`;
export const StyledBottomBanner = styled.div`
  img {
    width: calc(100% + 2px);
    margin-left: -1px;
    height: 51px;
    display: block;
  }
`;

export const StyledGiftTicketTitleAndCount = styled.div`
  text-align: center;
  .gift_title {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: #744134;
    font-size: 22px;
    padding-bottom: 15px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 33px;
      height: 15px;
      top: 10px;
    }
    &::before {
      left: -45px;
      background: url(${icon_left_guide}) no-repeat left center/33px;
    }
    &::after {
      right: -45px;
      background: url(${icon_right_guide}) no-repeat right center/33px;
    }
  }
  .ticket_count {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #744134;
    padding: 0 0 30px;
    .ticket_text {
      background: url(${icon_big_ticker}) no-repeat right center/22px;
      padding-right: 28px;
    }
    span {
      color: #fc6616;
      padding-left: 6px;
      font-size: 16px;
    }
  }
`;

export const StyledGiftCenterBox = styled.div`
  border-radius: 0px 0px 2px 2px;
  margin: -2px 0 -2px;
  background-color: #fef8e8;
  padding-top: 40px;
  &:last-child {
    padding-top: 0px;
    .course_content_box {
      ul {
        padding: 0;
        border-bottom: none;
      }
    }
  }
`;
export const StyledCourseContentBox = styled.div`
  margin: 0 10px;
  border-bottom: 3px dashed #ffd787;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 0 30px;
    li {
      flex: 1;
      text-align: center;
      img {
        border-radius: 16px;
        max-width: 100px;
        min-width: 100px;
        max-height: 100px;
        min-height: 100px;
        background-color: #fff;
        display: block;
        margin: 0 auto;
      }
      .content {
        padding: 10px 0 5px;
        .text {
          color: #744134;
          font-size: 14px;
          max-width: 85px;
          height: 40px;
          line-height: 1.3em;
          display: inline-flex;
        }
        .price {
          color: #fe6415;
          font-size: 14px;
        }
      }
      .button {
        width: 94px;
        height: 28px;
        line-height: 28px;
        background: linear-gradient(to top, #ff6d23, #ffbf3f);
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        &.already_exchange_button {
          background: linear-gradient(to top, #fcad86, #ffe1a2);
        }
      }
    }
  }
`;

// 精美实物礼品
export const StyledExquisiteGift = styled.div`
  margin: 0 10px;
  padding-top: 10px;
  border-bottom: 3px dashed #ffd787;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 0 30px;
    li {
      flex: 1;
      text-align: center;
      .img_box {
        border-radius: 16px;
        max-width: 100px;
        min-width: 100px;
        max-height: 100px;
        min-height: 100px;
        background-color: #fff;
        display: block;
        margin: 0 auto;
        position: relative;
        img {
          position: absolute;
          border-radius: 16px;
          /* background-color: #FFE9BF; */
          display: block;
          margin: 0 auto;
          left: 50%;
          top: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
        }
      }
      .content {
        padding: 10px 0 5px;
        .text {
          color: #744134;
          font-size: 14px;
          max-width: 85px;
          height: 40px;
          line-height: 1.3em;
          display: inline-flex;
        }
        .price {
          color: #fe6415;
          font-size: 14px;
        }
      }
      .button {
        width: 94px;
        min-height: 28px;
        background: linear-gradient(to top, #ff6d23, #ffbf3f);
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        &.already_exchange_button {
          background: linear-gradient(to top, #fcad86, #ffe1a2);
        }
        .ticket_number {
          color: #fff;
          font-size: 12px;
          margin-top: -3px;
          display: flex;
          align-items: center;
          span {
            background: url(${icon_ticket}) right center;
            background-size: 100% 100%;
            width: 15px;
            height: 10px;
            display: block;
            margin: 0 4px 2px 2px;
          }
        }
      }
    }
  }
`;
export const StyledVoucherCollection = styled.div`
  margin: 0 15px;
  padding-bottom: 50px;
`;

export const StyledCollectContentBox = styled.div`
  border-radius: 0px 0px 2px 2px;
  margin: -2px 0 -2px;
  padding-bottom: 10px;
  background-color: #fef8e8;
`;

export const StyledCollectContent = styled.div`
  display: flex;
  padding: 10px 5px;
  border-radius: 12px;
  background-color: #fff;
  justify-content: space-between;
  margin: 0 10px;
  .content_text_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .finish_text,
    .upload_text {
    }
    .finish_text {
      color: #744134;
      font-weight: 700;
      font-size: 14px;
      padding-bottom: 10px;
      span {
        color: #fc6616;
      }
    }
    .upload_text {
      color: #746261;
      font-size: 12px;
      span {
        color: #fc6616;
        background: url(${icon_small_ticket}) no-repeat left center / 16px;
        padding-left: 18px;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .finish_button,
    .upload_button {
      width: 74px;
      height: 26px;
      line-height: 26px;
      border-radius: 50px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .finish_button {
      /* 去完成 */
      background: linear-gradient(to top, #ff6d23, #ffbf3f);

      /* 已完成 */
      &.already_finish_button {
        background: linear-gradient(to top, #fcad86, #ffe1a2);
        margin-bottom: 0;
      }
      margin-bottom: 10px;
    }
    .upload_button {
      background: linear-gradient(to top, #ffa700, #ffca63);
    }
  }
`;

export const StyledActivityRuleBox = styled.div`
  margin: 0 15px;
  padding-bottom: 50px;
`;
export const StyledActivityRuleContent = styled.div`
  background-color: #fef8e8;
  border-radius: 0px 0px 2px 2px;
  margin: -2px 0 -2px;
  ul {
    li {
      color: #744134;
      display: flex;
      align-items: flex-start;
      padding: 0 20px 5px;
      span {
        border-radius: 50%;
        min-width: 15px;
        height: 15px;
        line-height: 15px;
        background: #fdb236;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #fff;
        margin-right: 8px;
        margin-top: 4px;
      }
      .activity_rule_text {
        font-size: 14px;
        display: flex;
        align-items: center;
        .weight {
          color: #744134;
          font-weight: 700;
        }
        p {
        }
      }
    }
  }
`;
