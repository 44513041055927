import React from "react";
import PropTypes from "prop-types";
import { SharePosterProxy, SharePoster } from "./SharePoster";
import { ToptipsManager } from "../utils/Toptips";
import { WechatFollow } from "../study_package_activities/WechatFollow";
import FooterMemberCardBtn from "../shared/FooterMemberCardBtn";
import { print } from "../utils/helpers";
import "./PromotionActivityFooter.scss";
import iconLimitTime from "new_experience/icon_limit_time.png";

export default class PromotionActivityFooter extends React.Component {
  static ModeEnum = {
    live: "live",
    market: "market",
    assist: "assist",
  };
  componentDidMount() {
    if (this.props.popup_poster) {
      this.popUpped = true;
      this.onFreeListenClick();
    }
    // var url = new URL(window.location.href)
    // var isReload = url.searchParams.get("xinyaReload")

    var isReload = window.location.href.includes("xinyaReload=true");
    if (!isReload) {
      if (this.props.assist_status) {
        switch (this.props.assist_status) {
          case 2:
            ToptipsManager.primary("你已为别人助力过！");
            break;
          case 1:
            ToptipsManager.warn("助力成功！");
            break;
          default:
            break;
        }
      }
    }
  }

  onFreeListenClick = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Users", "Free", "PromotionActivities");
    }

    if (this.props.qrcode_url) {
      this.onWechatFollow();
      return;
    }

    if (this.props.setModalOpen) {
      return this.props.setModalOpen({
        isPopupPoster: this.popUpped || false,
      });
    }

    if (!this.sharePosterProxy) {
      this.sharePosterProxy = new SharePosterProxy({
        posterUrl: this.props.poster_url,
        posterImgSrc: this.props.poster_img_src,
        posterTemplate: this.props.poster_photo_template_url,
        posterCreation: this.props.poster_creation,
        mode: "front_end",

        free: this.props.free,
        promotionMode: this.props.mode,
        targetType: this.props.target_type,
        maxInviteUsersCount: this.props.max_invite_users_count,
      });
    }
    this.sharePosterProxy.open();
  };

  onBuy = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Users", "Buy", "PromotionActivities");
    }

    this.toast = ToastManager.showLoading("提交中...");
    $.ajax({
      url: this.props.join_activity_url,
      method: "POST",
      complete: () => {
        this.toast.cancel();
      },
      success: (data) => {
        // console.log(data.redirect_url);
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
          location.reload();
        } else {
          alert("网络异常，请重新尝试");
        }
      },
    });
  };

  footerMemberCardBtnBeforeShowDialog = () => {
    if (typeof ga === "function") {
      ga("send", "event", "Users", "Buy", "PromotionActivities");
    }
    if (this.props.qrcode_url) {
      this.onWechatFollow();
      return;
    }
  };

  createRightBtn(btnLabel) {
    return (
      <button className="btn-buy" onClick={this.onBuy}>
        {btnLabel}
      </button>
    );
  }

  // 引导关注微信公众号
  onWechatFollow = (e) => {
    this.wechatFollowRef.showWechatFollow();
  };

  visitedClick = (e) => {
    this.onFreeListenClick(e);
  };

  render() {
    const {
      membership_free,
      super_card,
      trial_coupon,
      card,
      card_amount,
      member_card,
      free,
      max_invite_users_count,
      mode,
      limit_users_count,
      total_count,
      target_type,
      isLimitedTimeOver,
      bought,
    } = this.props;
    print("PromotionActivityFooter", this.props);

    let rightBtn;
    if (membership_free) {
      rightBtn = this.createRightBtn("立即报名(VIP会员免费)");
    } else if (super_card) {
      rightBtn = this.createRightBtn("立即报名(畅听卡免费)");
    } else if (trial_coupon) {
      rightBtn = this.createRightBtn("立即报名(体验券)");
    } else if (card) {
      rightBtn = this.createRightBtn("立即报名(趣学卡" + card_amount + "点)");
    } else if (member_card) {
      rightBtn = this.createRightBtn("立即报名(" + card_amount + "点)");
    } else {
      rightBtn = (
        <FooterMemberCardBtn
          {...this.props}
          beforeShowDialog={this.footerMemberCardBtnBeforeShowDialog}
          isReturn={!!this.props.qrcode_url}
        />
      );
    }

    // 库存剩余的人数
    let subtractCount = Number(limit_users_count) - Number(total_count);
    let remainUserCount = subtractCount > 0 ? subtractCount : 0;

    let bottom = "";
    switch (mode) {
      case PromotionActivityFooter.ModeEnum.live:
        bottom = (
          <BottomCommonSection
            isLimitedTimeOver={isLimitedTimeOver}
            isNewExperience={target_type === "Experience"}
            rightBtn={rightBtn}
            onFreeListenClick={this.onFreeListenClick}
            maxInviteUsersCount={max_invite_users_count}
            text={"邀请免费听"}
          />
        );
        break;
      case PromotionActivityFooter.ModeEnum.market:
        bottom = (
          <NowJoinActivity
            remainUserCount={remainUserCount}
            render={() => (
              <BottomCommonSection
                isLimitedTimeOver={isLimitedTimeOver}
                rightBtn={rightBtn}
                onFreeListenClick={this.onFreeListenClick}
                maxInviteUsersCount={max_invite_users_count}
                text="邀请免费领"
              />
            )}
          />
        );
        break;
      case PromotionActivityFooter.ModeEnum.assist:
        bottom = (
          <AssistForFree visitedClick={this.visitedClick} bought={bought} />
        );
        break;
      default:
        bottom = "";
    }

    return this.props.hide_buy ? (
      ""
    ) : (
      <div className="comp-prom-activity-ft">
        {free == "complete_free" ? (
          <FreeJoinListen onFreeListenClick={this.onFreeListenClick} />
        ) : (
          bottom
        )}
        {
          <WechatFollow
            ref={(ref) => (this.wechatFollowRef = ref)}
            qrcodeUrl={this.props.qrcode_url}
          />
        }
      </div>
    );
  }
}

const BottomCommonSection = ({
  rightBtn,
  onFreeListenClick,
  maxInviteUsersCount,
  text,
  isNewExperience,
  isLimitedTimeOver,
}) => {
  return (
    <div>
      {rightBtn}
      <button
        className="btn-free-listen"
        id="btn-free-listen"
        type="button"
        onClick={onFreeListenClick}
      >
        {isNewExperience && !isLimitedTimeOver && (
          <img className="limit_corner_icon" src={iconLimitTime} alt="" />
        )}
        <p>{text}</p>
        <span>{`最多${maxInviteUsersCount}人关注即可`}</span>
      </button>
    </div>
  );
};

const NowJoinActivity = ({ remainUserCount, render }) => {
  return (
    <div>
      {remainUserCount !== 0 && (
        <div className="limit_bounds">仅剩{remainUserCount}位，报满即止！</div>
      )}
      {remainUserCount !== 0 ? (
        render()
      ) : (
        <div className="btn-now-listen activity_end">活动已结束，等待下期</div>
      )}
    </div>
  );
};

const FreeJoinListen = ({ onFreeListenClick }) => {
  return (
    <button
      className="btn-now-listen"
      type="button"
      onClick={onFreeListenClick}
    >
      免费报名
    </button>
  );
};

const AssistForFree = ({ visitedClick, bought }) => {
  return (
    <button className="btn-now-visited" type="button" onClick={visitedClick}>
      {bought ? "领取成功" : "免费领取"}
    </button>
  );
};

PromotionActivityFooter.propTypes = Object.assign(
  {},
  SharePoster.propTypes,
  FooterMemberCardBtn.propTypes,
  {
    free: PropTypes.string.isRequired,
    btn_label: PropTypes.string,
    membership_free: PropTypes.bool,
    super_card: PropTypes.bool,
    card: PropTypes.bool,
    member_card: PropTypes.bool,
    trial_coupon: PropTypes.bool,
  }
);
