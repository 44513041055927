import React, { Component } from "react";
import PropTypes from "prop-types";
import { request, csrfHeaders } from "../utils/request";
import { debounce } from "../utils/helpers";
import { ToptipsManager } from "../utils/Toptips";
import { Dialog, DialogManager } from "../utils/Dialog";
import { GA } from "../utils/Env";
import UserAgent from "../utils/UserAgent";
import { onMiniProgram } from "../utils/helpers";
import Raven from "raven-js";
import { ToastManager } from "../utils/Toast";
import icon_progress_pass from "c4/icon_progress_pass.png";
import xinya_log from "../../../assets/images/home_logo_footer.png";
import "./NewMobile.scss";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";

const HeaderContent = () => {
  return (
    <div className="check_title">
      <h3>填写上课手机号</h3>
      <p>
        此手机号将是<span>上课</span>的<span>唯一</span>验证号码
      </p>
    </div>
  );
};
export default class NewMobile extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      phone: "",
      sms_code: "",
      count: 60,
      liked: true,
      agreeFlag: false,
    };
  }

  // Onchange
  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
    this.getSubmitHighLight();
  };

  getSubmitHighLight = () => {
    this.setState(({ phone, sms_code }) => ({
      canBeSubmit: phone && sms_code ? true : false,
      canBeSmsCode: phone ? true : false,
    }));
  };

  //获取短信验证码
  handleSmsCodeClick = () => {
    if (!this.state.liked) {
      return;
    }
    this.setState({
      liked: false,
    });

    if (!/^1[23456789]\d{9}$/.test(this.state.phone)) {
      ToptipsManager.warn("请输入正确的手机号码");
      return;
    } else {
      const url = this.props.sms_code_path;
      request({
        url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          mobile_number: this.state.phone,
          type: "bind",
        },
      })
        .then((resp) => {})
        .catch((err) => {
          Raven.captureException(err);
        });

      let count = this.state.count;
      // console.log(this.state.liked, count)
      const timer = setInterval(() => {
        this.setState(
          {
            count: count--,
          },
          () => {
            if (count === 0) {
              clearInterval(timer);
              this.setState({
                liked: true,
                count: 60,
              });
            }
          }
        );
      }, 1000);
    }
  };

  unRegisterDialog(url) {
    const buttons = [
      {
        label: "微信账号登录",
        onClick: () => {
          this.confirmDialog.close();
          if (UserAgent.isMiniProgram()) {
            onMiniProgram(e, url, {});
            return;
          }
          window.location.href = url;
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        <p style={{ fontSize: "16px" }}>该手机号尚未注册</p>
        <p style={{ fontSize: "16px" }}>请通过微信账号进行登录</p>
      </Dialog>
    );
  }

  // 登录请求
  handleReqeust = () => {
    if (!this.state.agreeFlag) {
      const toast = ToastManager.showToastText(
        "请先勾选阅读并同意《用户隐私政策》与《用户服务协议》"
      );
      // const toast = ToastManager.showToastError("请填写完整后再提交");
      setTimeout(() => {
        toast.cancel();
      }, 3000);
      return;
    }
    this.toast = ToastManager.showLoading("登录中...");
    let params = {
      mobile_number: this.state.phone,
      code: this.state.sms_code,
    };
    const url = this.props.mobile_users_path;
    request({
      url,
      method: "POST",
      headers: csrfHeaders,
      timeout: 30000,
      data: params,
    })
      .then((resp) => {
        let data = resp.data;
        console.log("register api callback: ", data);
        this.toast.cancel();
        if (data.errno) {
          switch (data.errno) {
            case 1:
              alert("验证码不匹配");
              break;
            case 2:
              alert("验证码过期");
              break;
            case 3:
              alert(
                "该手机号已绑定过其他微信：" +
                  data.bind_user_name +
                  "。请更换手机号，或使用绑定微信登录"
              );
              break;
            case 4:
              this.unRegisterDialog(this.props.wechat_login_url);
              break;
            case 5:
              alert("已尝试超过最大次数，请重新获取验证码");
              break;
          }
        } else {
          if (UserAgent.isNativeClient()) {
            if (data.is_reload) {
              window.location.href = data.redirect_url;
            } else {
              // TODO App 兼容
              let redirect_url = decodeURIComponent(data.redirect_url);
              if (redirect_url.includes("trial")) {
                XinYaNativeBridge.navigate({
                  href: redirect_url,
                  type: "after_login",
                  target: "_self",
                });
              } else {
                XinYaNativeBridge.navigate({
                  href: redirect_url,
                  type: "after_login",
                });
              }
            }
          } else {
            if (data.redirect_url == "") {
              window.location.href = "/";
            } else {
              window.location.href = data.redirect_url;
            }
          }
        }
      })
      .catch((err) => {
        this.toast.cancel();
        Raven.captureException(err);
      });

    // 填写手机号并进入下一步ga统计
    GA("Register", "Phone", this.props.user && this.props.user.id);
  };

  componentDidMount() {
    // 进入手机页面ga统计
    GA("Register", "Enter", this.props.user && this.props.user.id);
  }

  render() {
    const { canBeSubmit, canBeSmsCode } = this.state;
    const { is_signin } = this.props;
    const is_xinyaonline = location.hostname === 'xinyaonline.cn';

    return (
      <div className="comp_register_box">
        <div className="sigm-in-icon">
          <img src={xinya_log}></img>
          <div>{is_xinyaonline ? '心芽在线' : '心芽学堂'}</div>
        </div>
        <div className="phone-sign-in" style={{ visibility: is_xinyaonline ? 'hidden' : 'visible' }}>
          <div className="phone-sign-title">请登录学习账号</div>
          <div>1.手机号是您在【心芽学堂】APP、网页上课的唯一凭证</div>
          <div>2.支持多个设备同步课程进度，以及长期回看</div>
        </div>
        {/* {
          // is_signin === false && <HeaderContent />
          <HeaderContent />
        } */}
        <div className="user_register_box">
          <div className="register_info">
            <div className="phone_number">
              <div className="common_text">手机号:</div>
              <input
                type="tel"
                name="phone"
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </div>
            <div className="smscode_message">
              <div
                className={`${!this.state.liked && "disabled"} get_smscode`}
                onClick={
                  canBeSmsCode ? debounce(this.handleSmsCodeClick, 500) : null
                }
                style={{ background: canBeSmsCode ? "#6ad33b" : "#ddd" }}
              >
                {this.state.liked ? (
                  <span>获取验证码</span>
                ) : (
                  <span className="count_second">{this.state.count + "s"}</span>
                )}
              </div>
              <div className="code_text_input">
                <div className="common_text">验证码:</div>
                <input
                  id="single-factor-code-text-field"
                  type="tel"
                  name="sms_code"
                  autoComplete="one-time-code"
                  value={this.state.sms_code}
                  onChange={this.handleChange}
                  maxLength="6"
                />
              </div>
            </div>
          </div>
          <div
            className="register_next_step"
            style={{ background: canBeSubmit ? "#6ad33b" : "#ddd" }}
            onClick={canBeSubmit ? debounce(this.handleReqeust) : null}
          >
            {is_signin === false ? "下一步" : "确定"}
          </div>
          <div className="user_privacy_policy">
            <div
              className="selected-service"
              style={{ backgroundColor: this.state.agreeFlag ? "#49c114" : "" }}
              onClick={() => {
                this.setState((preState) => ({
                  agreeFlag: !preState.agreeFlag,
                }));
              }}
            >
              {this.state.agreeFlag ? <img src={icon_progress_pass}></img> : ""}
            </div>
            <div className="selected-services">
              <span
                onClick={() => {
                  this.setState((preState) => ({
                    agreeFlag: !preState.agreeFlag,
                  }));
                }}
              >
                我已阅读并同意
              </span>
              <a href={is_xinyaonline ? '/privacy_policy' : '/privacy'}>《用户隐私政策》</a>与
              <a href={is_xinyaonline ? '/agreements' : '/agreement'}>《用户服务协议》</a>
            </div>
            {/* <span>登录即表示阅读并同意</span>
            <a href="https://xinya.me/privacy">《用户隐私政策》</a> */}
          </div>
        </div>
        {/* <div className="user_treaty">注册即代表阅读并同意<a href="#">《用户使用协议》</a></div> */}
      </div>
    );
  }
}
