import React, { Component } from "react";
import PropTypes from "prop-types";
import { SOE } from "../utils/soe";
import QuizRecorder from "./Recorder";
import "./Demo.scss";

class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_text:
        "山村咏怀，宋，邵雍。一去二三里，烟村四五家，亭台六七座，八九十枝花。",
      s_mp3:
        "https://upload.fireflybaby.cn/audio/985ba47c20caef1a5c71b59b161fbbfd.mp3",
      s_result: null,
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  Evaluation = () => {
    let that = this;
    let url = $("#s_mp3").val();
    let text = $("#s_text").val();
    if (url == "" || text == "") {
      alert("空空如也");
      return;
    } else {
      $("#s_btn").attr("disabled", true);
    }
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var codes = new Uint8Array(xhr.response);
      var bin;
      for (var i = 0; i < codes.byteLength; i++) {
        bin += String.fromCharCode(codes[i]);
      }
      // var bin = String.fromCharCode.apply(null, codes);
      var b64 = btoa(bin);
      // 分析
      SOE(b64, text, that.ShowResult);
    };
    xhr.open("GET", url);
    xhr.responseType = "arraybuffer";
    xhr.send();

    // this.setState({
    //   s_result: data,
    // })
  };

  ShowResult = (res) => {
    // 结果显示
    console.log(111, res);

    $("#s_btn").attr("disabled", false);

    var result = res.Response;
    var total_score = result.SuggestedScore;
    var words = result.Words;
    $("#total_score").text(Math.round(total_score * 10) / 10);
    function num2color(n) {
      var color = "black";
      if (n < 40) {
        color = "red";
      } else if (n > 90) {
        color = "green";
      } else {
        color = "#333";
      }
      return color;
    }
    $.each(words, function (i, item) {
      // console.log(i, item.Word, item.PronAccuracy);
      let score = Math.abs(item.PronAccuracy);
      var R = (255 * score) / 100;
      var G = (255 * (100 - score)) / 100;
      var B = 0;

      $(
        '<span style="color:' +
          num2color(score) +
          ';" score=' +
          item.PronAccuracy +
          " />"
      )
        .text(item.Word)
        .appendTo("#text_result");
    });
  };

  // 录音模块
  onRecordStartAction = () => {
    console.log("Action:", "start...");
    const alertCountDown = (times) => {
      let countDownNumber = times - 1;
      this.setState({
        countDownNumber: times,
      });

      const alert = setInterval(() => {
        if (countDownNumber > 0) {
          console.log(countDownNumber);
          this.setState({
            countDownNumber: countDownNumber--,
          });
        } else {
          console.log("finished");
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
          this.countDownStop();
          this.setState({
            showRecordCountDown: false,
            disableMaskTap: false,
          });
          this.setIntervalID = alert;
        }
      }, 1000);
      this.setIntervalID = alert;
    };

    const alert = setTimeout(() => {
      console.log("showRecordCountDown");
      this.setState(
        {
          showRecordCountDown: true,
        },
        () => {
          console.log(this.state.showRecordCountDown);
        }
      );
      alertCountDown(10);
    }, 50000);
    this.setTimeoutID = alert;
  };

  onRecordStopAction = () => {
    console.log("Action:", "stop...");
    this.setState({
      showCaption: false,
      showRecordCountDown: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    return;
  };

  onRecordCompleteAction = () => {
    console.log("Action:", "complete...");
    this.setState({
      isRecording: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    window.location.reload(true);
    return;
  };

  onRecordReStartAction = () => {
    console.log("Action:", "restart...");
    this.onRecordStartAction();
  };

  clearIntervalAndTimeOut(interval, timeout) {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }

  countDownStop = () => {
    this.quizRecorderEL.handleAudioRecorderStop();
  };
  // 录音模块

  render() {
    let { s_mp3, s_text, s_result } = this.state;
    let {
      baby_id,
      auth_token,
      quiz_id,
      raven_msg,
      isRemoveRecord,
      recoder_audo_url,
    } = this.props;
    console.log(this.props);
    let mp3 = recoder_audo_url || s_mp3;
    return (
      <div className="soe-wrap">
        <h3>SOE TEST</h3>
        <div className="form">
          <div className="form-group">
            <label>Mp3 链接：</label>
            <input
              id="s_mp3"
              type="text"
              className="form-control"
              defaultValue={recoder_audo_url}
            />
            <pre className="small">{mp3}</pre>
            <audio src={mp3} controls></audio>
          </div>
          <div className="form-group">
            <label>文本：</label>
            <textarea id="s_text" className="form-control"></textarea>
            <small>{s_text}</small>
          </div>
          <button
            type="button"
            id="s_btn"
            onClick={() => this.Evaluation()}
            className="btn btn-primary"
          >
            分析
          </button>
          <hr />
          <h2>结果</h2>
          <h3 id="total_score"></h3>
          <div className="well" id="text_result">
            {s_result}
          </div>
        </div>
        <br />
        <hr />
        <h3>说明</h3>
        <p>
          录音完成后，上面MP3输入框会更新为录制的音频链接，请手动在文本框里输入对应的文字。
        </p>
        <div className="audio_recorder_bar">
          <QuizRecorder
            ref={(quizRecorderEL) => (this.quizRecorderEL = quizRecorderEL)}
            isLastQuiz={true}
            babyId={baby_id}
            authToken={auth_token}
            quizId={quiz_id}
            onRecordStartAction={this.onRecordStartAction}
            onRecordStopAction={this.onRecordStopAction}
            onRecordCompleteAction={this.onRecordCompleteAction}
            onRecordReStartAction={this.onRecordReStartAction}
            isRemoveRecord={isRemoveRecord}
            ravenMsg={raven_msg}
          />
        </div>
      </div>
    );
  }
}

Demo.propTypes = {};

export default Demo;
