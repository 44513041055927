/* eslint-disable react/prop-types */
import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import echarts from "echarts";
import { MyProvider, useSelector } from "../utils/react-global-state";
import DetailStore from "./DetailsStore.js";
import LazyloadDimension from "./LazyLoadDimension";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import { formatAudioTime } from "../utils/formatAudioTime.js";
import { request } from "../utils/request";
import { ToastManager } from "../utils/Toast.jsx";
import {
  YourSelfAnswer,
  CompareAnswer,
  CorrectPinyinVoice,
  ComparePinyinVoice,
} from "../day_study_reports/StudyReport";
import Picker from "better-picker";
import age_0 from "age/age_0.png";
import age_1 from "age/age_1.png";
import age_2 from "age/age_2.png";
import age_3 from "age/age_3.png";
import age_4 from "age/age_4.png";
import age_5 from "age/age_5.png";
import age_6 from "age/age_6.png";
import kb_logo from "kb_system/kb_logo.png";

import "./Details.scss";

const KB_LEVELS = {
  小班: "T1小班阶段",
  中班: "T2中班阶段",
  大班: "T3大班阶段",
  "一年级・上": "T4一年级上学期",
  "一年级・下": "T5一年级下学期",
};

const KB_DEGREES = {
  基础: "课内基础版",
  拓展: "延伸拓展版",
  详细: "详细版",
  超常: "延伸拓展版",
};

const Details = () => {
  const theory = useSelector((state) => state.theory);
  const share = useSelector((state) => state.share);

  const dimensionExamination = useSelector(
    (state) => state.dimensionExamination
  );
  const standalone = useSelector((state) => state.standalone);
  useEffect(() => {
    const share_url = share.url;
    const share_title =
      "重磅来袭！心芽匠心打造的《思维能力等级测评》早鸟免费测！";
    const share_description = "600+知识点，1000+题型，精准定位孩子思维水平~";
    const share_image_url = share.image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }, [share.image_url, share.url]);
  return (
    <div className="comp_kb_dimension_details">
      <DetailScoreShow />
      {(dimensionExamination.desc || !standalone) && <SummaryAnalysis />}
      {(theory.abstract || theory.description) && <GradePhase />}
      {/* <RadarChartTestGuide /> */}
      <QuizzesSwitchList />
      <SynthesizeAnalysisFixed />
    </div>
  );
};

const DetailScoreShow = memo(function DetailScoreShow() {
  const quizzes = useSelector((state) => state.quizzes);
  const redirectUrl = useSelector((state) => state.redirectUrl);
  const correctCount = useSelector((state) => state.correctCount);
  const dimensionExamination = useSelector(
    (state) => state.dimensionExamination
  );
  const totalTime = useSelector((state) => state.totalTime);
  const score = useSelector((state) => state.score);
  const standalone = useSelector((state) => state.standalone);
  const formatTotalTime = formatAudioTime(totalTime);
  const quizzes_result = [
    {
      label: "总题数",
      emphasis: `${quizzes.length}题`,
    },
    {
      label: "答对",
      emphasis: `${correctCount}题`,
    },
    {
      label: "用时",
      emphasis: `${formatTotalTime}`,
    },
  ];

  const [initailgTextHeight, setInitailgTextHeight] = useState(0);
  useEffect(() => {
    const gradeLevelTextHeight = document.getElementsByClassName(
      "grade_level_text"
    )[0].offsetHeight;
    setInitailgTextHeight(gradeLevelTextHeight);
  }, []);

  const leftArrowStyle = useMemo(
    () => ({
      left: 0,
      borderLeft: "10px solid #d3f8f5",
      borderTop: `${initailgTextHeight / 2}px solid transparent`,
      borderBottom: `${initailgTextHeight / 2}px solid transparent`,
    }),
    [initailgTextHeight]
  );

  const rightArrowStyle = useMemo(
    () => ({
      right: 0,
      borderRight: "10px solid #d3f8f5",
      borderTop: `${initailgTextHeight / 2}px solid transparent`,
      borderBottom: `${initailgTextHeight / 2}px solid transparent`,
    }),
    [initailgTextHeight]
  );

  const { name, kb_dimension_cat, grade, stage } = dimensionExamination;

  return (
    <div className="detail_score_show">
      {!standalone && (
        <a className="kb_logo" href={redirectUrl}>
          <img src={kb_logo} alt="" />
        </a>
      )}
      <div className="score_grade_section">
        <div className="score_section">
          <div
            className="score"
            style={{ paddingTop: kb_dimension_cat ? "" : "15px" }}
          >
            {score}
            <span>分</span>
          </div>
        </div>
        <div className="level_text_container">
          <div
            className="level_cat_text"
            style={{ visibility: kb_dimension_cat ? "visible" : "hidden" }}
          >
            <span>{kb_dimension_cat}测评</span>
          </div>
          <div className="grade_level_text">
            <div className="arrow_symbol" style={leftArrowStyle}></div>
            <div className="level_title">
              <span className="title">{`${name}`}</span>
              {kb_dimension_cat && (
                <span className="text">
                  {`（${
                    kb_dimension_cat === "专项"
                      ? KB_LEVELS[grade] || grade
                      : KB_DEGREES[stage]
                  }）`}
                </span>
              )}
            </div>
            <div className="arrow_symbol" style={rightArrowStyle}></div>
          </div>
        </div>
      </div>
      <div className="quizzes_times">
        <ul>
          {quizzes_result.map((result, index) => (
            <li key={index}>
              <div className="label">{result.label}</div>
              <div className="emphasis">{result.emphasis}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
const SummaryAnalysis = memo(function SummaryAnalysis() {
  const dimensionExamination = useSelector(
    (state) => state.dimensionExamination
  );
  const nickname = useSelector((state) => state.nickname);
  const standalone = useSelector((state) => state.standalone);
  const redirectUrl = useSelector((state) => state.redirectUrl);
  return (
    <div className="summary_analysis">
      {dimensionExamination.desc && (
        <LazyloadDimension content={dimensionExamination.desc} />
      )}
      {!standalone && (
        <div className="analysis_desc">
          本次测评数据将汇总到{nickname}的心芽思维能力等级测评中，点击查看
          <a href={redirectUrl}>综合分析 »</a>
        </div>
      )}
    </div>
  );
});

const GradePhase = memo(function GradePhase() {
  const theory = useSelector((state) => state.theory);
  const dimension = useSelector((state) => state.dimension);
  const [closeDialog, setCloseDialog] = useState(false);
  const { kb_grade, grade, age_from, age_to, abstract } = theory;
  const kb_grade_number = Number(theory.kb_grade.slice(1, 2));
  const age_childs = [age_0, age_1, age_2, age_3, age_4, age_5, age_6];
  const handleDialog = (boolState) => {
    setCloseDialog(boolState);
    if (boolState) {
      document.querySelector("html, body").classList.add("no_scroll");
    } else {
      document.querySelector("html, body").classList.remove("no_scroll");
    }
  };
  return (
    <div className="grade_phase">
      <div className="flex_dimention_box">
        <div className="dimension_content_markdown">
          <div className="grade_rank">
            <div>{`${kb_grade}${grade}${
              grade.includes("一年级・下") || grade.includes("一年级・上")
                ? "学期"
                : "阶段"
            }${dimension ? `：${dimension}` : ""}`}</div>
            <p>{`${age_from.split("个月")[0]}~${age_to.split("周岁")[1]}`}</p>
          </div>
          <LazyloadDimension content={abstract} />
        </div>
        <div className="img_illustration">
          <img src={age_childs[kb_grade_number]} alt="" />
        </div>
      </div>
      {theory && theory.description && (
        <div
          className="detail_introduce"
          onClick={handleDialog.bind(this, true)}
        >
          专业解读 »
        </div>
      )}
      {closeDialog && (
        <DialogDetailIntroduce handleCloseDialog={handleDialog} />
      )}
    </div>
  );
});

const DialogDetailIntroduce = memo(({ handleCloseDialog }) => {
  const theory = useSelector((state) => state.theory);
  // const { kb_grade, grade } = theory;
  const dimension = useSelector((state) => state.dimension);
  const [theoryContent, setTheoryContent] = useState(theory);
  const prevClick = () => {
    getTheoryRequestInfo(theoryContent.prev.get_grade_url);
  };

  const nextClick = () => {
    getTheoryRequestInfo(theoryContent.next.get_grade_url);
  };

  const getTheoryRequestInfo = useCallback((getGradeUrl) => {
    const toast = ToastManager.showLoading("加载中...");
    request({
      url: getGradeUrl,
      method: "get",
    })
      .then((resp) => {
        console.log("resp", resp);
        setTheoryContent(resp.data.theory);
        toast.cancel();
      })
      .catch((_err) => {
        toast.cancel();
        alert("请求出错");
      });
  }, []);

  // const gradePhase = grade => {
  //   return grade.indexOf("年级") > -1
  //     ? `${grade.split("(")[0]} · ${grade.slice(4, 5)}`
  //     : grade;
  // };

  return (
    <div className="dialog_detail_introduce">
      <div className="dialog_container">
        <div className="title_section">
          <div
            className="prev_level"
            onClick={prevClick}
            style={{
              visibility: theoryContent.prev ? "visible" : "hidden",
            }}
          >
            <div className="level_title">
              {theoryContent.prev && theoryContent.prev.kb_grade}阶段
            </div>
            <p>({theoryContent.prev && theoryContent.prev.grade})</p>
          </div>
          <div className="current_title">
            <div className="title">
              {dimension ||
                `${theoryContent.kb_grade}${theoryContent.grade}阶段`}
            </div>
            {dimension && (
              <p>
                {theoryContent.kb_grade}
                {`${theoryContent.grade}阶段`}
              </p>
            )}
          </div>
          <div
            className="next_level"
            onClick={nextClick}
            style={{
              visibility: theoryContent.next ? "visible" : "hidden",
            }}
          >
            <div className="level_title">
              {theoryContent.next && theoryContent.next.kb_grade}阶段
            </div>
            <p>({theoryContent.next && theoryContent.next.grade})</p>
          </div>
        </div>
        <div
          className="close_button_right"
          onClick={handleCloseDialog.bind(this, false)}
        ></div>
        <LazyloadDimension content={theoryContent.description} />
      </div>
    </div>
  );
});

// eslint-disable-next-line no-unused-vars
const RadarChartTestGuide = memo(function RadarChartTestGuide() {
  const nickname = useSelector((state) => state.nickname);
  // const kb_dimensions = useSelector(state => state.kbDimensions);
  // const radarLayers = useSelector(state => state.radarLayers);
  const radarLayers = 1;
  const kb_dimension_groups = [
    {
      id: 1,
      name: "量与换算",
      score: {
        band: "T",
        grade_num: 5,
        grade: "大班",
        major: "T3",
        minor: 46,
        unified_score: 47,
      },
    },
    {
      id: 5,
      name: "数概念",
      score: {
        band: "T",
        grade_num: 5,
        grade: "大班",
        major: "T3",
        minor: 20,
        unified_score: 21,
      },
    },
    {
      id: 8,
      name: "立体方位",
      score: {
        band: "T",
        grade_num: 5,
        grade: "大班",
        major: "T3",
        minor: 40,
        unified_score: 41,
      },
    },
  ];
  console.log("kb_dimension_groups", kb_dimension_groups);
  useEffect(() => {
    const indicatorArray = []; // 雷达各个边界相应数值
    const schoolAbilityValues = ["83", "85", "100", "87", "86", "93"]; // 心芽孩子能力值 TODO:
    const babyAbilityValues = ["45", "65", "80", "47", "76", "60"]; // 宝宝能力值
    kb_dimension_groups.map((kb_group, index) => {
      indicatorArray.push({
        name: "",
        max: 100,
        color: "#333",
      });
      // babyAbilityValues.push(
      //   kb_group.score ? kb_group.score.unified_score : 0.001
      // );
    });
    console.log("babyAbilityValues 宝宝能力值: ", babyAbilityValues);
    console.log("schoolAbilityValues 学校能力值: ", schoolAbilityValues);
    console.log("indicatorArray: 雷达图显示区域", indicatorArray);
    // 基于准备好的容器(这里的容器是id为chart1的div)，初始化echarts实例
    var chart1 = echarts.init(document.getElementById("chart1"));

    // 指定图表的配置项和数据
    var option = {
      radar: [
        {
          // 雷达图坐标系组件，只适用于雷达图。
          center: ["25%", "50%"], // 圆中心坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: "50%", // 圆的半径，数组的第一项是内半径，第二项是外半径。
          startAngle: 90, // 坐标系起始角度，也就是第一个指示器轴的角度。[ default: 90 ]
          name: {
            formatter: [
              `{value}`, // 名称
              // `{detailStyle|详情}` // 详情
            ].join("\n"),

            textStyle: {
              // align: "center",
              lineHeight: 18,
              color: "#333",
            },
          },
          nameGap: 15, // 指示器名称和指示器轴的距离。[ default: 15 ]
          splitNumber: `${radarLayers}`, // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
          shape: "circle", // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
          axisLine: {
            // (圆内的几条直线)坐标轴轴线相关设置
            lineStyle: {
              color: "#ccc", // 坐标轴线线的颜色
              opacity: 0.5,
              width: 1, // 坐标轴线线宽。
              type: "solid", // 坐标轴线线的类型。
            },
          },
          splitLine: {
            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
            lineStyle: {
              color: "#f0f0f0", // 分隔线颜色
              opacity: 0.7,
              width: 1.5, // 分隔线线宽
            },
          },
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,1)", "rgba(255,255,255,1)"],
              // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          indicator: indicatorArray,
          triggerEvent: true, // add event
        },
      ],
      series: [
        {
          name: "雷达图", // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
          type: "radar", // 系列类型: 雷达图
          itemStyle: {
            // 折线拐点标志的样式。
            normal: {
              // 普通状态时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 0, // 控制每个折点的显示/隐藏
            },
            emphasis: {
              // 高亮时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 1,
            },
          },
          data: [
            {
              name: "心芽孩子能力水平",
              value: schoolAbilityValues,
              symbol: "circle",
              symbolSize: schoolAbilityValues.length,
              label: {
                normal: {
                  show: false,
                  position: "top",
                  distance: 5,
                  color: "#8080ff",
                  fontSize: 14,
                },
              },
              itemStyle: {
                normal: {
                  color: "#8080ff",
                  borderColor: "#8080ff",
                  borderWidth: 1,
                },
              },
              lineStyle: {
                normal: {
                  type: "dashed",
                  color: "#8080ff",
                  opacity: 0.5,
                },
              },
              areaStyle: {
                normal: {
                  opacity: 0.2,
                  color: "rgb(128, 128, 255)",
                },
              },
            },
            {
              name: "小宝能力值",
              value: babyAbilityValues,
              symbol: "circle",
              symbolSize: babyAbilityValues.length,
              label: {
                normal: {
                  show: false,
                  position: "top",
                  distance: 5,
                  color: "#ffc600",
                  fontSize: 14,
                },
              },
              itemStyle: {
                normal: {
                  color: "#ffc600",
                  borderColor: "#ffc600",
                  borderWidth: 1,
                },
              },
              lineStyle: {
                normal: {
                  opacity: 0.5,
                },
              },
              areaStyle: {
                normal: {
                  opacity: 0.8,
                  color: "#ffc600",
                },
              },
            },
          ],
        },
      ],
    };

    chart1.setOption(option);
    window.onresize = function () {
      chart1.resize();
    };
  }, [kb_dimension_groups]);
  return (
    <div className="radar_chart_container">
      <div className="course_commend">课程推荐</div>
      <div className="radar_chart_guide">
        <div className="radar_info_describe">
          小宝的数学思维能力相当优秀，牛娃们都在上的《
          <strong>心芽T4阶段思维训练课</strong>
          》，有助于继续巩固和发挥这个优势！
        </div>
        <div className="echart_box">
          <div id="chart1" className="e_chart" />
          <div className="main_text_section">
            <div className="baby_skill_value">{nickname}</div>
            <div className="school_skill_value">T4阶段</div>
          </div>
        </div>
      </div>
      <a className="check_course" href={`#`}>
        查看课程 »
      </a>
    </div>
  );
});

const QuizzesSwitchList = memo(function QuizzesSwitchList() {
  const quizzes = useSelector((state) => state.quizzes);
  const splitedList = [];
  const copy = [...quizzes];
  // const activeTabIdx = useRef();
  while (copy.length) {
    const sliced = copy.splice(0, 5);
    const reduceSplitedList = splitedList.reduce(
      (sum, l) => sum + l.value.length,
      0
    );

    const dealReduceSplitedList = `${reduceSplitedList + 1}`;
    const dealReduceSplitedListLength = `${sliced.length + reduceSplitedList}`;

    const lastIndexScope =
      dealReduceSplitedList != dealReduceSplitedListLength
        ? `${dealReduceSplitedList}-${dealReduceSplitedListLength}题`
        : dealReduceSplitedList;
    splitedList.push({
      text: lastIndexScope,
      value: sliced,
    });
  }
  // console.log("splitedList", splitedList);

  const failQuizzes = useSelector((state) => state.failQuizzes);
  const firstValue = splitedList[0].value;
  // console.log('firstValue: ', firstValue);
  const defaultIndexScope = `1-${firstValue.length}题`;
  const [pickSelectedIndex, setPickSelectedIndex] = useState(0);
  const [pickerQuizValue, setPickerQuizValue] = useState(defaultIndexScope);
  const [sliceQuizzesScope, setSliceQuizzesScope] = useState(firstValue);
  const [tabActiveArrow, setTabActiveArrow] = useState(false);
  useEffect(() => {
    const quizDefaultSelected = splitedList.findIndex((item) => item.text);
    var pickerQuiz = new Picker({
      data: [splitedList],
      selectedIndex: quizDefaultSelected,
    });
    pickerQuiz.on("picker.select", (selectedVal, selectedIndex) => {
      // console.log("selectedIndex: ", selectedIndex);
      const selectedText = splitedList[selectedIndex[0]].text;
      const selectedValue = splitedList[selectedIndex[0]].value;
      setPickSelectedIndex(selectedIndex[0]);
      setPickerQuizValue(selectedText);
      setSliceQuizzesScope(selectedValue);
      setTabActiveArrow(true);
      console.log("selectedValue: ", selectedValue);
    });

    const iidx = failQuizzes.length > 0 ? 2 : 1;
    console.log("iidx: ", iidx);
    $(".picker_quiz")
      .eq(0)
      .click(() => {
        // if (activeTabIdx.current == iidx) {
        //   console.log("this.activeTabIdx.current: ", activeTabIdx.current);
        pickerQuiz.show();
        // }
      });

    const scroll = (e) => {
      const offset_top = document.getElementById("reference_container")
        .offsetTop;
      const scroll_top = window.pageYOffset;
      if (scroll_top > offset_top) {
        document
          .getElementById("_tabs_group_container")
          .classList.add("tabs_fixed");
      } else {
        document
          .getElementById("_tabs_group_container")
          .classList.remove("tabs_fixed");
      }
    };
    window.addEventListener("scroll", scroll);
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  let titleMenu = [];
  if (failQuizzes.length > 0) {
    titleMenu = [
      {
        text: "所有题目",
        quizzes: quizzes,
      },
      {
        text: "只看错题",
        quizzes: failQuizzes,
      },
      {
        text: pickerQuizValue,
        quizzes: sliceQuizzesScope || firstValue,
      },
    ];
  } else {
    titleMenu = [
      {
        text: "所有题目",
        quizzes: quizzes,
      },
      {
        text: pickerQuizValue,
        quizzes: sliceQuizzesScope || firstValue,
      },
    ];
  }
  // console.log(335, titleMenu);
  const tabClick = (e) => {
    const tabIdx = e.currentTarget.getAttribute("data-id");
    console.log("tabIdx: ", tabIdx);
    if (failQuizzes.length > 0) {
      dealTabIdx(tabIdx, 2);
    } else {
      dealTabIdx(tabIdx, 1);
    }
    // activeTabIdx.current = tabIdx;
    // console.log("activeTabIdx.current: ", activeTabIdx.current);
  };

  const dealTabIdx = (tabIdx, idx) => {
    if (tabIdx !== `${idx}`) {
      setPickSelectedIndex(0);
      setTabActiveArrow(false);
    }
  };

  const failQuizzesLengthIdx = () => {
    return failQuizzes.length > 0 ? 2 : 1;
  };
  return (
    <div className="quizzes_switch_list">
      <div id="reference_container"></div>

      <Tabs styles="tabs" defaultAction={tabClick} defaultActiveIndex={0}>
        {titleMenu.map((menu, index) => (
          <TabPane
            key={index}
            tab={menu.text}
            activeStyle="active"
            styles={`tab ${
              index == failQuizzesLengthIdx() ? "picker_quiz" : ""
            } ${tabActiveArrow ? "tab_active_arrow" : ""}`}
          >
            <div className={`quiz_detail_container`}>
              {menu.quizzes.map((quiz, index) => (
                <QuizDetailIntroduce
                  key={quiz.id}
                  quiz={quiz}
                  index={index}
                  pickSelectedIdx={pickSelectedIndex}
                />
              ))}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
});

const QuizDetailIntroduce = memo(function QuizDetailIntroduce({ ...props }) {
  const { quiz, index, pickSelectedIdx } = props;
  const correct_url_judge = quiz.correct_url != "" && quiz.correct_url != null;
  const error_url_judge = quiz.error_url != "" && quiz.error_url != null;
  const correct_audio_url_judge =
    quiz?.correct_audio_url != "" && quiz?.correct_audio_url != null;
  const error_audio_url_judge =
    quiz?.error_audio_url != "" && quiz?.error_audio_url != null;

  // console.log("pickSelectedIdx: ", pickSelectedIdx);
  const nextKbCoursesData = useMemo(
    () =>
      quiz.kb_course.next_kb_courses &&
      quiz.kb_course.next_kb_courses.reduce((acc, cur, index) => {
        if (index === quiz.kb_course.next_kb_courses.length - 1) {
          return acc + `“${cur.name}”`;
        }
        return acc + `“${cur.name}”、`;
      }, ""),
    [quiz.kb_course.next_kb_courses]
  );

  return (
    <div className="quiz_detail_introduce">
      <div className="quiz_description">
        <div
          className={`${
            quiz.correct ? "correct_circle" : "fail_circle"
          } quiz_index_circle`}
        >
          <span>
            {quiz.fail_index
              ? quiz.fail_index
              : index + (4 * pickSelectedIdx + (pickSelectedIdx + 1))}
          </span>
        </div>
        <div className="quiz_title">{quiz.question}</div>
        {quiz.question_photo_url && (
          <div className="quiz_image">
            <img
              className="lazyload"
              data-src={quiz.question_photo_url}
              alt=""
            />
          </div>
        )}
        {error_audio_url_judge || correct_audio_url_judge ? (
          ""
        ) : (
          <div className="options_wrapper">
            {quiz.options &&
              quiz.options.map((option, ix) => (
                <div className="options" key={ix}>
                  {option.photo ? (
                    <img src={option.photo.url} />
                  ) : (
                    option.content
                  )}
                </div>
              ))}
          </div>
        )}
        {error_audio_url_judge || correct_audio_url_judge ? (
          <>
            {error_audio_url_judge ? (
              <ComparePinyinVoice
                quiz={quiz}
                correct_audio_url_judge={correct_audio_url_judge}
                error_audio_url_judge={error_audio_url_judge}
              />
            ) : (
              <CorrectPinyinVoice
                quiz={quiz}
                correct_audio_url_judge={correct_audio_url_judge}
                text="你的答案"
              />
            )}
          </>
        ) : (
          <div
            className={`${
              quiz.correct ? "correct_answer" : "fail_answer"
            } quiz_answer`}
          >
            {quiz.correct ? (
              <YourSelfAnswer
                quiz={quiz}
                correct_url_judge={correct_url_judge}
              />
            ) : (
              <CompareAnswer
                quiz={quiz}
                correct_url_judge={correct_url_judge}
                error_url_judge={error_url_judge}
              />
            )}
          </div>
        )}
      </div>
      <div className="quiz_knowledge_md_desc">
        <div className="knowledge_desc_title">
          本题考察的是《{quiz.kb_course.name}》知识点
          {`${
            quiz.kb_course.description || quiz.kb_course.next_kb_courses
              ? "："
              : ""
          }`}
        </div>
        {quiz.kb_course.description && (
          <LazyloadDimension content={quiz.kb_course.description} />
        )}
        {quiz.kb_course.next_ids && quiz.kb_course.next_ids.length > 0 && (
          <div className="next_kb_courses">
            本知识点会影响后序{nextKbCoursesData}
            {`${quiz.kb_course.next_ids.length > 3 ? "等" : ""}`}
            {quiz.kb_course.next_ids.length}个知识点的学习。
          </div>
        )}
      </div>
    </div>
  );
});

const SynthesizeAnalysisFixed = memo(function SynthesizeAnalysisFixed() {
  const nickname = useSelector((state) => state.nickname);
  const redirectUrl = useSelector((state) => state.redirectUrl);
  const standalone = useSelector((state) => state.standalone);
  return (
    <a className="synthesize_analysis_fixed" href={redirectUrl}>
      {standalone ? "查看推荐课程 »" : `查看“${nickname}”的综合分析 »`}
    </a>
  );
});

const NewProvider = (props) => {
  console.log(props);
  const failQuizzes = [];
  if (props.result.quizzes) {
    props.result.quizzes.forEach((quiz, index) => {
      if (quiz.correct === false) {
        // console.log("错误的index: ", index + 1);
        failQuizzes.push({
          ...quiz,
          fail_index: index + 1,
        });
      }
    });
  }
  console.log("failQuizzes: ", failQuizzes);
  return (
    <MyProvider
      store={DetailStore.build({
        nickname: props.baby.nickname,
        dimension: props.dimension,
        // kbDimensions: props.kb_dimensions,
        // radarLayers: props.radar_layers,
        redirectUrl: props.result.redirect_url,
        score: props.result.score,
        correctCount: props.result.correct_count,
        errorCount: props.result.error_count,
        quizzes: props.result.quizzes,
        totalTime: props.result.total_time,
        dimensionExamination: props.dimension_examination,
        theory: props.theory,
        share: props.share,
        standalone: props.standalone,
        failQuizzes,
      })}
    >
      <Details />
    </MyProvider>
  );
};

export default NewProvider;
