import React from "react";
import styled from "styled-components";

const Table = styled.table`
  tbody {
    tr {
      padding: 20px 10px;
    }
    tr:nth-of-type(odd) {
      background: #f3f3f3 !important;
    }
  }
`;

// eslint-disable-next-line react/prop-types
const ConsultantTabTableContent = ({ children, className }) => {
  return (
    <div className={`${className} tab-content`}>
      <div className="course_content">
        <div className="panel-body table-responsive">
          <Table className="table table-striped">{children}</Table>
        </div>
      </div>
    </div>
  );
};

export default ConsultantTabTableContent;
