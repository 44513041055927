import React, { Component } from "react";
import PropTypes from "prop-types";
import { request, csrfHeaders } from "../utils/request";
import { daysInMonth } from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import { ToptipsManager } from "../utils/Toptips";
import { formatDate } from "../utils/formatAudioTime";
import UserAgent from "../utils/UserAgent";
import Picker from "better-picker";
import plupload from "plupload";
import Raven from "raven-js";

import uploadDefaultPhoto from "avatar_empty.png";
import "./UserBaby.scss";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.genderMap = ["女", "男"];
    this.state = {
      // 设置初始值
      pickerGradeValue: this.props.baby.grade ? this.props.baby.grade : -1,
      pickerRelationValue: this.props.user_baby.relationship,

      pickerYearValue:
        this.props.baby.day_of_birth &&
        this.props.baby.day_of_birth.slice(0, 4),
      pickerMonthValue:
        this.props.baby.day_of_birth &&
        this.props.baby.day_of_birth.slice(5, 7),
      pickerDayValue:
        this.props.baby.day_of_birth &&
        this.props.baby.day_of_birth.slice(8, 10),

      selected:
        this.props.baby.gender === 0 || this.props.baby.gender > 0
          ? this.genderMap[this.props.baby.gender]
          : -1,

      filePhoto: this.props.baby.photo_url || uploadDefaultPhoto,
      nickName: this.props.baby.nickname || "",
      gender: this.props.baby.gender,
      grade: this.props.baby.grade || "",
      relationship: this.props.user_baby.relationship || "",
      id: this.props.user_baby.id,
      canBeSubmit:
        this.props.baby.nickname &&
        this.props.baby.day_of_birth &&
        this.props.user_baby.relationship &&
        (this.props.baby.gender === 0 || this.props.baby.gender > 0
          ? this.genderMap[this.props.baby.gender]
          : -1) !== -1
          ? true
          : false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { canBeSubmit } = this.state;
    const last_pick_date = new Date(
      this.state.pickerYearValue +
        "-" +
        this.state.pickerMonthValue +
        "-" +
        (this.state.pickerDayValue != undefined
          ? this.state.pickerDayValue
          : "01")
    );
    console.log("last_pick_date: ", last_pick_date);
    console.log(
      "last_pick_date /: ",
      formatDate(last_pick_date).replace(/-/g, "/")
    );
    console.log(
      "system_time /: ",
      formatDate(this.props.system_time).replace(/-/g, "/")
    );
    if (
      new Date(formatDate(last_pick_date).replace(/-/g, "/")) >
      new Date(formatDate(this.props.system_time).replace(/-/g, "/"))
    ) {
      ToptipsManager.warn("您选择的月份不能大于当前月份, 请重新选择");
      return;
    } else {
      if (!canBeSubmit) {
        return;
      }
      this.toast = ToastManager.showLoading("提交中...");
      let params = {
        user_baby: {
          baby_attributes: {
            nickname: this.state.nickName,
            day_of_birth:
              this.state.pickerYearValue +
              "-" +
              this.state.pickerMonthValue +
              "-" +
              (this.state.pickerDayValue != null
                ? this.state.pickerDayValue
                : "01"),
            gender: this.genderMap.indexOf(this.state.selected),
            // grade: this.state.pickerGradeValue,
            id: this.props.baby.id,
            secrect_level: this.state.pickerDayValue != null ? 1 : 0,
          },
          relationship: this.state.pickerRelationValue,
        },
      };
      console.log(params);
      request({
        url: this.props.user_baby_url,
        method: "PATCH",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          this.toast.cancel();
          let data = resp.data;
          console.log("success data: ", data);

          if (UserAgent.isNativeClient()) {
            XinYaNativeBridge.navigate({ href: data.redirect_url });
          } else {
            window.location.href = data.redirect_url;
          }
        })
        .catch((err) => {
          Raven.captureException(err);
          this.toast.cancel();
        });
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
    this.makeSubmitAttention();
  };

  receiveDayItems = () => {
    var days = daysInMonth(
      this.props.baby.day_of_birth && this.props.baby.day_of_birth.slice(0, 4),
      this.props.baby.day_of_birth && this.props.baby.day_of_birth.slice(5, 7)
    );
    var objDay = [];
    for (let index = 1; index <= days; index++) {
      objDay.push({ text: index, value: index });
    }
    objDay.unshift({ text: "日", value: -1 });
    console.log("days: ", days);
    this.pickDaysItems = [
      new Picker({
        data: [objDay],
        selectedIndex: this.props.baby.day_of_birth && [
          objDay.findIndex(
            (item) =>
              this.props.baby.day_of_birth.split("-")[2].replace(/^0/, "") ==
              item.value
          ),
        ],
      }),
    ];

    let pickerDayEl = document.getElementById("picker_day");
    // console.log(pickerDayEl.innerHTML)
    if (pickerDayEl.innerHTML == "可不填") {
      pickerDayEl.style.cssText = `color: lightgrey`;
    }

    this.pickDaysItems[0].on("picker.select", (selectedVal, selectedIndex) => {
      console.log(
        "日",
        "selectedVal:" + selectedVal,
        "selectedIndex:" + selectedIndex
      );
      if (selectedVal[0] === -1) {
        ToptipsManager.warn("请认真填写您孩子的出生日");
        return;
      } else {
        pickerDayEl.innerText = objDay[selectedIndex[0]].text;
        console.log("日", objDay[selectedIndex[0]].text);
        this.setState({
          pickerDayValue: `${selectedVal[0]}`,
        });

        // console.log(pickerDayEl.innerHTML)
        if (pickerDayEl.innerHTML != "可不填") {
          pickerDayEl.style.cssText = `color: #333`;
        }
      }
    });
  };

  componentDidMount() {
    // 初始化·获取相应月份的天数
    this.receiveDayItems();

    var generate_token_url = this.props.generate_token_url;
    // console.log(this.props.months.findIndex((item)=>this.props.baby.day_of_birth.split('-')[1] == {...item}.value.toString().padStart(2,0)))
    var uploader = this.initSinglePlupload("");
    uploader.bind("FilesAdded", function (uploader, file) {
      this.toast = ToastManager.showLoading("图片上传中...");
      request({
        url: generate_token_url,
        method: "GET",
        params: {
          type: "baby",
        },
        headers: csrfHeaders,
      })
        .then((res) => {
          // console.log(res.data.token)
          uploader.setOption("multipart_params", {
            token: res.data.token,
          });
          uploader.start();
        })
        .catch((err) => {
          Raven.captureException(err);
          this.toast.cancel();
        });
    });

    uploader.init();

    const { baby, user_baby, grades, relations, months, years } = this.props;

    // let pickerGradeEl = document.getElementById('picker_grade');
    let pickerYearEl = document.getElementById("picker_year");
    let pickerMonthEl = document.getElementById("picker_month");
    let pickerDayEl = document.getElementById("picker_day");
    let pickerRelationEl = document.getElementById("picker_relation");

    const gradeDefaultSelected = grades.findIndex(
      (item) => baby.grade === item.value
    );
    const relationDefaultSelected = relations.findIndex(
      (item) => item.text === user_baby.relationship
    );

    // var pickerGrade = new Picker({
    //   data: [grades],
    //   selectedIndex: gradeDefaultSelected !== -1 && [gradeDefaultSelected]
    // });
    this.props.years.unshift({ text: "年", value: -1 });
    this.props.months.unshift({ text: "月", value: -1 });

    var pickerYear = new Picker({
      // data: [years, months],
      // selectedIndex: baby.day_of_birth && [years.findIndex((item) => baby.day_of_birth.split('-')[0] === item.text), months.findIndex((item) => baby.day_of_birth.split('-')[1].replace(/^0/, '') == item.value)]
      data: [years],
      selectedIndex: baby.day_of_birth && [
        years.findIndex(
          (item) => baby.day_of_birth.split("-")[0] === item.text
        ),
      ],
    });

    var pickerMonth = new Picker({
      data: [months],
      selectedIndex: baby.day_of_birth && [
        months.findIndex(
          (item) =>
            baby.day_of_birth.split("-")[1].replace(/^0/, "") == item.value
        ),
      ],
    });

    var pickerRelation = new Picker({
      data: [relations],
      selectedIndex: relationDefaultSelected !== -1 && [
        relationDefaultSelected,
      ],
    });

    // 年级
    // pickerGrade.on('picker.select', (selectedVal, selectedIndex) => {
    //   pickerGradeEl.innerText = grades[selectedIndex[0]].text
    //   this.setState({
    //     pickerGradeValue: selectedVal[0]
    //   })
    // });
    // pickerGradeEl.addEventListener('click', function () {
    //   pickerGrade.show();
    // });

    // 关系
    pickerRelation.on("picker.select", (selectedVal, selectedIndex) => {
      pickerRelationEl.innerText = relations[selectedIndex[0]].text;
      this.setState({
        pickerRelationValue: pickerRelationEl.innerText,
      });
      this.makeSubmitAttention();
    });

    pickerRelationEl.addEventListener("click", function () {
      pickerRelation.show();
    });

    // 出生年份
    pickerYear.on("picker.select", (selectedVal, selectedIndex) => {
      console.log(
        "年份",
        "selectedVal:" + selectedVal,
        "selectedIndex:" + selectedIndex
      );
      if (selectedVal[0] === -1) {
        ToptipsManager.warn("请认真填写您孩子的出生年份");
        return;
      } else {
        // months[selectedIndex[1]].text + "月"
        // `${selectedVal[1]}-01`

        pickerYearEl.innerText = years[selectedIndex[0]].text;
        console.log("年份", years[selectedIndex[0]].text);
        this.setState({
          pickerYearValue: `${selectedVal[0]}`,
        });
        this.makeSubmitAttention();
      }
    });
    pickerYearEl.addEventListener("click", function () {
      pickerYear.show();
    });

    // 出生月份
    pickerMonth.on("picker.select", (selectedVal, selectedIndex) => {
      console.log("前一项的年份", this.state.pickerYearValue);
      console.log(
        "月份",
        "selectedVal:" + selectedVal,
        "selectedIndex:" + selectedIndex
      );
      if (selectedVal[0] === -1) {
        ToptipsManager.warn("请认真填写您孩子的出生月份");
        return;
      } else {
        pickerMonthEl.innerText = months[selectedIndex[0]].text;
        console.log("月份", months[selectedIndex[0]].text);
        this.setState({
          pickerMonthValue: `${selectedVal[0]}`,
        });
        this.makeSubmitAttention();

        console.log(this.state.pickerYearValue);
        console.log(months[selectedIndex[0]].text);

        var days = daysInMonth(
          this.state.pickerYearValue,
          months[selectedIndex[0]].text
        );

        var objDay = [];
        for (let index = 1; index <= days; index++) {
          objDay.push({ text: index, value: index });
        }
        objDay.unshift({ text: "日", value: -1 });
        console.log("天数days: ", days);

        var pickerDay = new Picker({
          data: [objDay],
          selectedIndex: baby.day_of_birth && [
            objDay.findIndex(
              (item) =>
                baby.day_of_birth.split("-")[2].replace(/^0/, "") == item.value
            ),
          ],
        });
        this.pickDaysItems.push(pickerDay);
        pickerDay.on("picker.select", (selectedVal, selectedIndex) => {
          console.log(
            "日",
            "selectedVal:" + selectedVal,
            "selectedIndex:" + selectedIndex
          );
          if (selectedVal[0] === -1) {
            ToptipsManager.warn("请认真填写您孩子的出生日");
            return;
          } else {
            pickerDayEl.innerText = objDay[selectedIndex[0]].text;
            console.log("日", objDay[selectedIndex[0]].text);
            this.setState({
              pickerDayValue: `${selectedVal[0]}`,
            });

            if (objDay[selectedIndex[0]].text != "可不填") {
              pickerDayEl.style.cssText = `color: #333`;
            }

            this.makeSubmitAttention();
          }
        });
        pickerDayEl.addEventListener("click", () => {
          console.log(
            "pickerDayEl value: ",
            this.state.pickerYearValue +
              "-" +
              this.state.pickerMonthValue +
              "-" +
              this.state.pickerDayValue
          );
          if (this.state.pickerYearValue == null) {
            ToptipsManager.warn("请选择年份");
            return;
          } else if (this.state.pickerMonthValue == null) {
            ToptipsManager.warn("请选择月份");
            return;
          } else {
            console.log("enter pickerDayEl");
            this.pickDaysItems[this.pickDaysItems.length - 1].show();
          }
        });
      }
    });
    pickerMonthEl.addEventListener("click", () => {
      console.log("开始选择月份");
      pickerMonth.show();
    });
    pickerDayEl.addEventListener("click", () => {
      console.log(
        "pickerDayEl value: ",
        this.state.pickerYearValue +
          "-" +
          this.state.pickerMonthValue +
          "-" +
          this.state.pickerDayValue
      );
      if (this.state.pickerYearValue == null) {
        ToptipsManager.warn("请选择年份");
        return;
      } else if (this.state.pickerMonthValue == null) {
        ToptipsManager.warn("请选择月份");
        return;
      } else {
        console.log("enter pickerDayEl");
        this.pickDaysItems[this.pickDaysItems.length - 1].show();
      }
    });
  }

  // getDayValue = () => {
  //   this.setState(({ days }) => ({
  //     days: days,
  //   }))
  //   console.log(this.state.days)
  // }

  makeSubmitAttention = () => {
    this.setState(
      ({
        nickName,
        pickerYearValue,
        pickerMonthValue,
        pickerRelationValue,
        selected,
      }) => ({
        canBeSubmit:
          nickName &&
          pickerYearValue &&
          pickerMonthValue &&
          pickerRelationValue &&
          selected !== -1
            ? true
            : false,
        pickerYearValue: pickerYearValue,
        pickerMonthValue: pickerMonthValue,
      })
    );
  };

  // 通过 value 找到 key值
  getValue = () => {
    const { baby, grades } = this.props;
    let getValue = "";
    grades.some((grade) => {
      getValue = grade.text;
      return grade.value === baby.grade;
    });
    return getValue;
  };
  handelGenderSelect = (e) => {
    this.setState({
      selected: e.currentTarget.value,
    });
  };

  // Plupload头像上传
  initSinglePlupload = () => {
    var uploader = new plupload.Uploader({
      //创建实例的构造方法
      runtimes: "html5, flash", //上传插件初始化选用那种方式的优先级顺序
      browse_button: "user-photo-placeholder", // 上传按钮 引发上传事件的按钮的id
      url: "https://upload.qiniup.com", //远程上传地址 后台地址
      filters: {
        max_file_size: "10mb", //最大上传文件大小（格式100b, 10kb, 10mb, 1gb）
        // mime_types: [
        //   { title: "JPG/PNG文件", extensions: "jpg,jpeg,JPG,JPEG,png,PNG,gif,GIF" }
        // ]
      },
      multi_selection: false, //true多文件上传, false 单文件上传
      multipart_params: {
        token: "",
      },
      multipart: true, //为true时将以multipart/form-data的形式来上传文件
      resize: {
        width: 1600,
        height: 1600,
      },
      init: {
        // FilesAdded: function (uploader, files) { //文件上传前
        //   this.toast = ToastManager.showLoading("图片上传中...");
        //   uploader.start();
        // },
        FileUploaded: function (up, file, result) {
          //文件上传成功的时候触发
          console.log(result);
          location.reload();
          this.toast.cancel();
        },
        Error: function (up, err) {
          //上传出错的时候触发
          this.toast.cancel();
          Raven.captureException(err);
        },
      },
    });
    return uploader;
  };

  render() {
    const {
      pickerYearValue,
      pickerMonthValue,
      pickerDayValue,
      pickerRelationValue,
      canBeSubmit,
    } = this.state;
    return (
      <div
        id="baby_form_2"
        className="babies-new user_has_babay"
        style={{ transform: "translateY(-50%)", top: "50%", marginTop: "0" }}
      >
        <div id="form_user_baby">
          <div id="user-photo" style={{ paddingBottom: "20px" }}>
            <div id="user-photo-placeholder">
              <img src={this.state.filePhoto} />
            </div>
          </div>

          <div className="form-row">
            <label className="label">小名:</label>
            <div className="input">
              <input
                type="text"
                value={this.state.nickName}
                name="nickName"
                onChange={this.handleChange}
                style={{ paddingLeft: 38 }}
              />
            </div>
          </div>

          <div className="form-row babies_sex">
            <label className="label">性别:</label>
            <div className="input gender">
              <div className="input_raido">
                <input
                  type="radio"
                  value="男"
                  checked={this.state.selected === "男"}
                  onChange={this.handelGenderSelect}
                />
                <label>男宝</label>
              </div>
              <div className="input_raido">
                <input
                  type="radio"
                  value="女"
                  checked={this.state.selected === "女"}
                  onChange={this.handelGenderSelect}
                />
                <label>女宝</label>
              </div>
            </div>
          </div>

          <div className="form-row babies_birth">
            <label className="label label_static_width">出生年月:</label>
            <div className="baby_birth_box">
              <div className="common_div">
                <span
                  id="picker_year"
                  className={`birth ${!pickerYearValue && "placeholder"}`}
                  style={{ paddingLeft: 0 }}
                >
                  {this.props.baby.day_of_birth
                    ? this.props.baby.day_of_birth.slice(0, 4)
                    : "请选择"}
                </span>
                <span>年</span>
              </div>
              <div className="common_div">
                <span
                  id="picker_month"
                  className={`month ${!pickerMonthValue && "placeholder"}`}
                >
                  {this.props.baby.day_of_birth
                    ? this.props.baby.day_of_birth.slice(5, 7)
                    : "请选择"}
                </span>
                <span>月</span>
              </div>
              <div className="common_div">
                <span
                  id="picker_day"
                  className={`day ${!pickerDayValue && "placeholder"}`}
                >
                  {this.props.baby.secrect_level == "保密"
                    ? "可不填"
                    : this.props.baby.day_of_birth
                    ? this.props.baby.day_of_birth.slice(8, 10)
                    : "可不填"}
                </span>
                <span>日</span>
              </div>
            </div>
          </div>

          {/* <div className="form-row babies_grade" id="babies_grade">
            <label className="label">年级:</label>
            <div id="picker_grade" className="select_area">
              {
                this.props.baby.grade
                  ?
                  this.getValue()
                  :
                  "选择年级"
              }
            </div>
          </div> */}

          <div className="form-row babies_rank">
            <label className="label">你是:</label>
            <div
              id="picker_relation"
              className={`select_area area ${
                !pickerRelationValue && "placeholder"
              }`}
            >
              {this.props.user_baby.relationship
                ? this.props.user_baby.relationship
                : "请选择家长身份"}
            </div>
          </div>

          <button
            className="handle_submit"
            type="submit"
            onClick={canBeSubmit ? this.handleSubmit : null}
            style={{ background: canBeSubmit ? "#6ad33b" : "lightgrey" }}
          >
            确定
          </button>
        </div>
      </div>
    );
  }
}

Edit.propTypes = {
  grades: PropTypes.array,
  months: PropTypes.array,
  relations: PropTypes.array,
  years: PropTypes.array,
  user_baby_url: PropTypes.string,
};
