import React from "react";
import { clipboard } from "../utils/helpers.js";
import "./CourseConsultant.scss";

const ConsultantGroupCode = (props) => {
  const copyCode = () => {
    clipboard(window, document, navigator).copy(props.group_code);
  };
  return (
    <div className="comp_course_consultant">
      {props.renderContent(copyCode)}
    </div>
  );
};

export default ConsultantGroupCode;
