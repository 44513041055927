import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MDContentRender from "./MDContentRender.jsx";

const SectionDescription = React.memo(function SectionDescription({
  sectionDescription,
  isTopDisplay,
}) {
  const renderedSectionDescription = useMemo(
    () => MDContentRender(sectionDescription),
    [sectionDescription]
  );
  if (isTopDisplay) {
    return (
      <div className="section_desc_bought">
        <div className="section_desc_bought_wrapper">
          {renderedSectionDescription}
        </div>
      </div>
    );
  }
  return (
    <div className="section_desc_none_bought">{renderedSectionDescription}</div>
  );
});

SectionDescription.propTypes = {
  sectionDescription: PropTypes.string,
  isTopDisplay: PropTypes.bool,
};

export default SectionDescription;
