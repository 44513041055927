import React, { Component } from "react";
import PropTypes from "prop-types";
import { request } from "../utils/request";
import HeaderMenu from "../utils/xinya_header_menu/HeaderMenu";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import {
  GalleryGroupContainer,
  GalleryItem,
} from "../utils/GalleryGroup/GalleryGroup.js";
import { formatAudioTime } from "../utils/formatAudioTime";
import MediaLessonPlayer from "../utils/media_lesson_player/MediaLessonPlayer.jsx";

import "../utils/media_lesson_player/MediaLessonPlayer.scss";
import "../utils/GalleryGroup/GalleryGroup.scss";
import "./Experiences.scss";

class ExperienceMediaLesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSectionIndex: props.activeSectionIndex,
      currentQuizIndex: 0,
    };
  }
  componentWillUpdate(nextProps) {
    if (nextProps.activeSectionIndex !== this.state.activeSectionIndex) {
      this.setState({
        activeSectionIndex: nextProps.activeSectionIndex,
        currentQuizIndex: 0,
      });
    }
  }
  // onLoadedMetadata = (playerCtx) => {
  //   console.log("onLoadedMetadata");
  // };
  // onCanPlayThrough = (playerCtx) => {
  //   console.log("onCanPlayThrough");
  // };
  // onPlay = (playerCtx) => {
  //   console.log("onPlay");
  // };
  // onPause = (playerCtx) => {
  //   console.log("onPause");
  // };
  onTimeUpdate = (playerCtx) => {
    if (isNaN(playerCtx.totalTime)) {
      return;
    }
    const current = playerCtx.currentTime;
    this.setState((preState) => {
      const currentQuizIndex = this.getCurrentQuizIndex(current);
      if (preState.currentQuizIndex !== currentQuizIndex) {
        return {
          currentQuizIndex,
        };
      }
    });
  };
  onEnded = (playerCtx) => {
    const audioListLength = this.props.audioList.length;
    const nextAudioIndex =
      this.state.activeSectionIndex >= audioListLength - 1
        ? audioListLength - 1
        : this.state.activeSectionIndex + 1;
    this.setState({
      activeSectionIndex: nextAudioIndex,
      currentQuizIndex: 0,
    });
    this.props.updateSectionIndex(nextAudioIndex);
  };
  createProgressPoints = () => {
    const activeSectionIndex = this.state.activeSectionIndex;
    let counter = [];
    let audioEndTime = this.props.mediaLessonList[activeSectionIndex][
      this.props.mediaLessonList[activeSectionIndex].length - 1
    ].endTime;
    if (!audioEndTime) {
      return counter;
    }
    this.props.mediaLessonList[activeSectionIndex].forEach((quiz) => {
      if (quiz.isMarked) {
        counter.push({
          left: Math.round(100 * (quiz.endTime / audioEndTime)) + "%",
        });
      }
    });
    return counter;
  };

  getCurrentQuizIndex = (currentTime) => {
    const activeSectionIndex = this.state.activeSectionIndex;
    const quizzes = this.props.mediaLessonList[activeSectionIndex];
    const index = quizzes.findIndex(
      (quiz) => currentTime >= quiz.startTime && currentTime <= quiz.endTime
    );
    return index === -1 ? 0 : index;
  };
  onPlayerStateChange = (state) => {
    console.log("onPlayerStateChange: ", state);
  };
  render() {
    const { audioList, mediaLessonList } = this.props;

    const { activeSectionIndex, currentQuizIndex } = this.state;

    const quizzes = mediaLessonList[activeSectionIndex];
    const currentQuiz = quizzes[currentQuizIndex];
    return (
      <div className="experience_media_lesson">
        <MediaLessonPlayer
          audioList={audioList}
          createProgressPoints={this.createProgressPoints}
          activeAudioIndex={activeSectionIndex}
          renderViewImg={() => {
            return (
              <div className="view_img">
                <img src={currentQuiz.showPhoto.url} />
              </div>
            );
          }}
          onPlayerStateChange={this.onPlayerStateChange}
          // onLoadedMetadata={this.onLoadedMetadata}
          // onCanPlayThrough={this.onCanPlayThrough}
          // onPlay={this.onPlay}
          // onPause={this.onPause}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onEnded}
          showProgressBar={true}
          autoPlay={true}
          debug={false}
        />
      </div>
    );
  }
}

class ExperienceSlideContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftAndRightControllerWidth: 80,
    };
  }
  componentDidMount() {
    this.setState({
      leftAndRightControllerWidth:
        (this.sliderCon.offsetWidth - this.sectionCon.sectionDiv.offsetWidth) /
        2,
    });
  }

  render() {
    const {
      experienceSections,
      activeSectionIndex,
      updateActiveIndex,
    } = this.props;
    const { leftAndRightControllerWidth } = this.state;
    return (
      <div
        className="gallery_container"
        ref={(sliderCon) => (this.sliderCon = sliderCon)}
      >
        <GalleryGroupContainer
          animation={{
            transition: "left .8s",
          }}
          styles={"gallery_items"}
          defaultActiveIndex={activeSectionIndex}
          scrollStep={0.5}
          indexDots={{
            containerStyles: "dots",
            dotUnActiveStyle: "in_active_dot",
            dotActiveStyle: "active_dot",
          }}
          leftAndRightControllers={{
            containerStyles: "left_right_step_click",
            leftControllerStyle: () => {
              return (
                <div
                  style={{
                    width: leftAndRightControllerWidth,
                  }}
                  className="left step_click"
                />
              );
            },
            rightControllerStyle: () => {
              return (
                <div
                  style={{
                    width: leftAndRightControllerWidth,
                  }}
                  className="right step_click"
                />
              );
            },
          }}
          activeIndexUpdater={updateActiveIndex}
        >
          {experienceSections.map((section, index) => (
            <GalleryItem
              styles={`item_container`}
              key={index}
              activeStyle={"active"}
              unActiveStyle={"in_active"}
            >
              {
                <SimpleExperienceSection
                  ref={(sectionCon) => (this.sectionCon = sectionCon)}
                  key={index}
                  section={section}
                  index={index}
                />
              }
            </GalleryItem>
          ))}
        </GalleryGroupContainer>
      </div>
    );
  }
}

class SimpleExperienceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioTime: "00:00",
    };
  }
  getAudioInfo = (audioUrl) => {
    return request({
      url: `${audioUrl}?avinfo`,
      method: "get",
    }).then((resp) => parseInt(resp.data.format.duration));
  };
  componentDidMount() {
    this.getAudioInfo(this.props.section.mediaLesson.audioUrl).then(
      (duration) => {
        this.setState({
          audioTime: formatAudioTime(duration),
        });
      }
    );
  }

  render() {
    const { audioTime } = this.state;
    return (
      <div
        className="simple_experience_section"
        ref={(section) => (this.sectionDiv = section)}
      >
        <div className="experience_title">
          <i>{this.props.index + 1}.</i>
          <div className="title">{this.props.section.name}</div>
        </div>
        <div className="date_and_progress">
          <div className="date">{audioTime}</div>
          <div className="listen_progress">已听0%</div>
        </div>
      </div>
    );
  }
}

const ExperienceTextContentBoard = ({ conclusion, tags, sectionName }) => {
  const HeaderContent = () => {
    return (
      <div className="header_title">
        <div className="title">{sectionName}</div>
        <div className="tag">
          {tags.map((tag, id) => (
            <span key={id}>{tag.name}</span>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="simple_experience_markdown">
      <HeaderContent />
      <Tabs styles="tabs">
        <TabPane tab="详情" styles="tab" activeStyle="active">
          <LazyloadContent content={"详情-markdown-内容"} />
        </TabPane>
        <TabPane tab="讲义" styles="tab" activeStyle="active">
          <LazyloadContent content={conclusion} />
        </TabPane>
      </Tabs>
    </div>
  );
};

ExperienceTextContentBoard.propTypes = {
  conclusion: PropTypes.string,
  tags: PropTypes.array,
  sectionName: PropTypes.string,
};

export default class Experiences extends Component {
  constructor(props) {
    super(props);
    console.log("props :", props);
    this.state = {
      activeSectionIndex: 0,
    };
  }

  updateActiveIndex = (activeSectionIndex) => {
    const { experienceSections } = this.props;
    if (activeSectionIndex > experienceSections.length - 1) {
      return;
    }
    this.setState({
      activeSectionIndex,
    });
  };
  groupAudioAndMediaLessonList = () => {
    const audioList = [];
    const mediaLessonList = [];
    const { experienceSections } = this.props;
    experienceSections.forEach((section) => {
      audioList.push({
        url: section.mediaLesson.audioUrl,
        isLoaded: false,
        duration: 0,
      });
      mediaLessonList.push(section.mediaLesson.quizzes);
    });
    return { audioList, mediaLessonList };
  };
  render() {
    const { activeSectionIndex } = this.state;
    const { experienceSections } = this.props;
    const experienceSection = experienceSections[activeSectionIndex];
    const { conclusion, tags, name } = experienceSection;
    const { audioList, mediaLessonList } = this.groupAudioAndMediaLessonList();
    return (
      <div className="comp_experience_units_detail">
        <HeaderMenu />
        <ExperienceMediaLesson
          mediaLessonList={mediaLessonList}
          audioList={audioList}
          activeSectionIndex={activeSectionIndex}
          updateSectionIndex={this.updateActiveIndex}
        />
        <ExperienceSlideContainer
          experienceSections={experienceSections}
          activeSectionIndex={activeSectionIndex}
          updateActiveIndex={this.updateActiveIndex}
        />
        <ExperienceTextContentBoard
          key={activeSectionIndex}
          conclusion={conclusion}
          tags={tags}
          sectionName={name}
        />
      </div>
    );
  }
}

Experiences.propTypes = {};
