import React, { useState, useEffect } from "react";
import "../shared/tailwindcss.scss";
import { request, csrfHeaders } from "../../components/utils/request";
import Capsule from "../shared/Capsule";

const ExchangeResult = ({ commodity, create_exchange_url, study_package_activity_id }) => {
  const [response, setResponse] = useState(null)
  useEffect(() => {
    console.log('start to request')
    const data = {
      amount: commodity.point_cost,
      commodity_id: commodity.id,
      shipment_address_id: queryParams.shipment_address_id,
    }
    if (study_package_activity_id) {
      data.study_package_activity_id = study_package_activity_id
    }
    request({
      url: create_exchange_url,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    }).then((res) => {
      if (res.data.code === 200) {
        console.log(res.data)
        setResponse(res.data.data)
      }
    });
  }, [])

  const dispatchText = () => {
    return (
      <>
        <div className="tex-gray">{response.text}</div>
        <Capsule text="确定" />
      </>
    )
  }
  return (
    <div className="flex flex-col justify-center">
      <img src={commodity.photo} className="w-full" />
      <div className="flex flex-col items-center mt-3">
        {response ? dispatchText() : null}
      </div>
    </div>
  )
}

export default ExchangeResult