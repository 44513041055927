import React from "react";
import PropTypes from "prop-types";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";

const ExperienceMerit = React.memo(function ExperienceMerit({
  experienceMerit,
}) {
  return (
    <div className="experience_merit">
      <div className="merit_title">收听亮点</div>
      {!!experienceMerit && (
        <div className="merit_desc">
          <LazyloadContent content={experienceMerit} />
        </div>
      )}
    </div>
  );
});

ExperienceMerit.propTypes = {
  experienceMerit: PropTypes.string,
};

export default ExperienceMerit;
