import React, { useMemo } from "react";
import PropTypes from "prop-types";

const SectionTags = React.memo(function SectionTags({ tags }) {
  const tagNames = useMemo(
    () =>
      tags.reduce((pre, tag, index) => {
        if (tag && index % 2) {
          return pre + ` | ${tag.name}`;
        } else if (tag && !(index % 2)) {
          return pre + `${tag.name}`;
        } else {
          return pre;
        }
      }, ""),
    [tags]
  );

  return !!tagNames && <div className="section_tag">{tagNames}</div>;
});

SectionTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      position: PropTypes.number,
    })
  ),
};

export default SectionTags;
