import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ExperienceList.scss";

export default class ExperienceList extends Component {
  render() {
    const photo_url =
      "https://upload.fireflybaby.cn/experience/ebf57abd32430ae0fc64b2769e5a8e03.jpg";
    return (
      <div className="experience_list_box">
        <ul>
          <li style={{ backgroundImage: `url(${photo_url})` }}>
            <a href="">
              <h2>包玉刚</h2>
              <h3 className="topic_description">
                2019年包玉刚上岸，上实国际/上中/协和/星校/包校
                超多面经打包带走！
              </h3>
              <div className="user_info">包玉刚·新一家长</div>
              <div className="number_subscribers">2019-06-29 · 1378人收听</div>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

ExperienceList.propTypes = {};
