import React, { Component } from "react";
import PropTypes from "prop-types";
import "./HeaderMenu.scss";

export default class HeaderMenu extends Component {
  render() {
    return (
      <div className="comp_xinya_header_menu">
        <a href="/home" className="xinya_logo"></a>
        <ul>
          <li>
            <a href="/home">课程</a>
          </li>
          <li>
            <a className="selected" href="/experiences">
              经验
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

HeaderMenu.propTypes = {};
