import React, { Component } from "react";
import PropTypes from "prop-types";

import "./RecommendExams.scss";

class RecommendExams extends Component {
  componentDidMount() {
    console.log(this.props);
    document.querySelector("html, body").classList.add("no_scroll");
  }

  componentWillUnmount() {
    document.querySelector("html, body").classList.remove("no_scroll");
  }

  render() {
    const {
      baby,
      recommend_first,
      recommend_title,
      recommend_exams,
    } = this.props;
    const step_title = ["后序", "推荐", "前序"];
    const show_outgrade =
      recommend_exams && recommend_exams[0].type === "default";
    const show_noexam = recommend_exams && recommend_exams[0].type === "none";
    return (
      <div className="modal_simple">
        <div className="modal_mask"></div>
        <div className="modal_content">
          <div
            className={
              !show_outgrade
                ? "kb_recommend_exams_wrap"
                : "kb_recommend_exams_wrap grade_out"
            }
          >
            <div className="kb_recommend_exams_head">
              <h3>{recommend_title}</h3>
              {!recommend_first && (
                <span onClick={this.props.modalClose}>&times;</span>
              )}
            </div>
            {show_outgrade && (
              <div className={"kb_recommend_exams_warning"}>
                很抱歉！{baby.nickname}
                的年级不在『心芽思维能力等级测评』范围（小班 ~
                一年级）内，暂时没有合适的测评推荐。
                <p>以下是与{baby.nickname}年级接近的测评：</p>
              </div>
            )}

            {/* <div className="kb_recommend_exams_tip">
              你有一次兑换权益可用于兑换以下任一评测。
            </div> */}
            <div className={"kb_recommend_exams_main"}>
              {show_noexam && (
                <div className="kb_recommend_exams_none">
                  {recommend_exams[0].text}
                </div>
              )}

              {!show_noexam &&
                recommend_exams &&
                recommend_exams.map((item, idx) => (
                  <div key={idx} className={"exam exam_" + idx}>
                    <span>{item.exams.length > 0 && step_title[idx]}</span>
                    {item.exams.length > 0 && (
                      <ul className={idx === 1 ? "active" : ""}>
                        {item.exams.map((exam, i) => (
                          <li key={i}>
                            <div className="exam_info">
                              <span className={"stage-" + exam.stage_id}>
                                {exam.stage}测评
                              </span>
                              <h5>{exam.name}</h5>
                              {idx === 1 && <p>{exam.brief}</p>}
                            </div>
                            <a href={exam.url}>
                              {exam.answer_state < 0 ? "去测评" : "结果解读"}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecommendExams.propTypes = {
  recommend_exams: PropTypes.array,
  modalClose: PropTypes.func,
};

export default RecommendExams;
