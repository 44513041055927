import { useEffect, useMemo } from "react";
import plupload from "plupload";
import Raven from "raven-js";

export const usePlupload = ({
  eleId,
  isMultiSelection = false,
  isFormData = true,
  resize = {
    width: 1600,
    height: 1600,
  },
  filesAdded = (up, files) => {
    up.start();
  },
  fileUploaded = () => {},
  fileUploadError = (_, error) => {
    console.log(error);
    Raven.captureException(error);
  },
  token = "",
}) => {
  // https://www.plupload.com/docs/v2/Uploader#Init-event
  const uploader = new plupload.Uploader({
    // 创建实例的构造方法
    runtimes: "html5, flash", // 上传插件初始化选用那种方式的优先级顺序
    browse_button: eleId, // 上传按钮 引发上传事件的按钮的id
    url: "https://upload.qiniup.com", // 远程上传地址 后台地址
    filters: {
      max_file_size: "10mb", // 最大上传文件大小（格式100b, 10kb, 10mb, 1gb）
    },
    multi_selection: isMultiSelection, // true多文件上传, false 单文件上传
    multipart_params: {
      token,
    },
    multipart: isFormData, // 为true时将以multipart/form-data的形式来上传文件
    resize,
    init: {
      FilesAdded: filesAdded,
      // 文件上传成功的时候触发
      FileUploaded: fileUploaded,
      Error: fileUploadError,
    },
  });

  return uploader;
};
