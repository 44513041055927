import React from "react";
import PropTypes from "prop-types";
import { SharePosterProxy, SharePoster } from "./SharePoster";
import { GA } from "../utils/Env";
import "./PromotionEarnAwards.scss";

export default class PromotionEarnAwards extends React.Component {
  // onFreeListenClick = e => {
  //   GA("Users", "Free", "virtual");

  //   // 购买前
  //   const btnFreeListen = document.getElementsByClassName("btn-free-listen");
  //   // 购买后
  //   const compPromActivityPassBox = document.getElementsByClassName(
  //     "comp-prom-activity-pass-box"
  //   );

  //   let textDescribe;
  //   if (btnFreeListen && btnFreeListen[0]) {
  //     textDescribe = false;
  //   }
  //   if (compPromActivityPassBox && compPromActivityPassBox[0]) {
  //     textDescribe = true;
  //   }

  //   if (!this.sharePosterProxy) {
  //     this.sharePosterProxy = new SharePosterProxy({
  //       posterUrl: this.props.poster_url,
  //       posterImgSrc: this.props.poster_img_src,
  //       posterTemplate: this.props.poster_photo_template_url,
  //       posterCreation: this.props.poster_creation,
  //       mode: "front_end",

  //       free: this.props.free,
  //       promotionMode: this.props.mode,
  //       textDescribe: textDescribe,
  //       targetType: this.props.target_type,
  //       maxInviteUsersCount: this.props.max_invite_users_count
  //     });
  //   }
  //   this.sharePosterProxy.open();
  // };

  render() {
    console.log("PromotionEarnAwards: ", this.props);
    const {
      virtual_accounts_url,
      money,
      bonus,
      virtual_account_records_url,
    } = this.props;

    const ruleInfos = [
      {
        text: "若邀请的朋友关注后取关，将扣除相应奖学金 ",
        // bonus: `+${bonus.subscribe}元/人`
      },
      {
        text: "若取关的朋友过多，您将无权再参与其他活动 ",
        // bonus: `+${bonus.experience_buy}元/次`
      },
    ];
    return (
      <div className="rewards">
        <h2>奖学金余额：￥{money}</h2>
        <div className="poster_invite_content rule_one">
          <span>+5元/人</span>
          <p>
            <i>1.</i>
            点击右下角“邀请免费听”，生成专属邀请海报。每邀请1位新用户关注公众号：
          </p>
        </div>
        <div className="poster_invite_content">
          <p>
            <i>2.</i>
            奖学金获取诚信为主，不可作弊：
          </p>
          <ul>
            {ruleInfos.map((rule, index) => (
              <li key={index}>
                <div className="left_text">
                  <i></i>
                  {rule.text}
                </div>
                {/* <span>{rule.bonus}</span> */}
              </li>
            ))}
          </ul>
        </div>
        <div className="relative_award">
          {/* <a href={virtual_accounts_url}>奖学金规则</a> */}
          <a
            className="earn_awards"
            href={virtual_account_records_url}
            // onClick={this.onFreeListenClick}
          >
            邀请记录
          </a>
        </div>
      </div>
    );
  }
}

PromotionEarnAwards.propTypes = {
  free: PropTypes.string.isRequired,
  target_type: PropTypes.string,
  poster_url: PropTypes.string,
  poster_img_src: PropTypes.string,
};
