// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_share_point.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icon_recommend.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/icon_participate_point.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".comp-gifts-dialog .tips_box{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.6);z-index:1002;display:block!important}.comp-gifts-dialog .tips_box a{text-decoration:underline;color:#49c114}.comp-gifts-dialog .tips_main_content{position:absolute;top:50%;left:20px;right:20px;margin-top:-164px;border-radius:8px;text-align:center;background-color:#fff}.comp-gifts-dialog .box_share_points .title{font-size:18px;font-weight:700;padding-top:15px}.comp-gifts-dialog .box_share_points .box_points{padding:5px 10px}.comp-gifts-dialog .box_share_points .box_points li{width:100%;border-bottom:1px solid #ddd;padding:20px 0 15px 30px;background-size:24px;background-repeat:no-repeat;background-position:left 16px}.comp-gifts-dialog .box_share_points .box_points li a{text-decoration:none}.comp-gifts-dialog .box_share_points .box_points li a span{background:#6ad33b;color:#fff;float:right;border-radius:15px;width:45px;height:23px;line-height:23px;margin-top:-2px;text-align:center;font-size:12px}.comp-gifts-dialog .box_share_points .box_points li a p{color:#666;font-size:13px;line-height:1.4em;margin:0;text-align:left}.comp-gifts-dialog .box_share_points .box_points li.icon_share{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.comp-gifts-dialog .box_share_points .box_points li.icon_recommend{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.comp-gifts-dialog .box_share_points .box_points li.icon_participate{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.comp-gifts-dialog .box_share_points .box_points li:last-child{border-bottom:none;padding-bottom:10px}.comp-gifts-dialog .close_btn_right{width:20px;height:24px;position:absolute;top:0;right:10px;font-size:20px}", ""]);
// Exports
module.exports = exports;
