import React, { useState } from "react";
import AddLogModalDialog from "./AddLogModalDialog";
import { request, csrfHeaders } from "../../utils/request";
import { ToastManager } from "../../utils/Toast";

import styled from "styled-components";

const StyleFollowupLog = styled.div`
  display: inline-block;
  margin-top: -4px;
  border-radius: 30px;
  font-size: 14px;
  color: white;
  background: #3c8af0;
  cursor: pointer;
  padding: 4px 6px;
  text-align: center;
`;

const FollowupLog = ({ className, ...props }) => {
  console.log("props_FollowupLog", props);
  // console.log("MarkDeal_props: ", props);
  const [openDialog, setOpenDialog] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);

  // eslint-disable-next-line react/prop-types
  const { followUpLogUrl, btnName } = props;
  const [babyExtraInfo, setBabyExtraInfo] = useState({});
  const [addFollowUpLogUrl, setAddFollowUpLogUrl] = useState("");
  const [grades, setGrades] = useState("");
  const [sourceReasons, setSourcesReasons] = useState([]);
  const [calculationBasics, setCalculationBasics] = useState("");
  const [literacies, setLiteracies] = useState("");
  const [teachers, setTeachers] = useState([]);
  const handleLogModal = (e) => {
    console.log("followUpLogUrl", followUpLogUrl);
    setOpenDialog(true);
    ajaxRequest(followUpLogUrl);
  };
  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  const ajaxRequest = (url) => {
    const toast = ToastManager.showLoading("拼命加载中...");
    request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        toast.cancel();
        const data = resp.data;
        console.log("data: ", data);
        console.log("正确返回");
        setBabyExtraInfo({
          user: data.user,
          baby: data.baby,
        });
        setAddFollowUpLogUrl(data.add_follow_up_log_url);
        setGrades(data.grades);
        setSourcesReasons(data.source_reasons);
        setCalculationBasics(data.calculation_basics);
        setLiteracies(data.literacies);
        setTeachers(data.teachers);
        setRequestSuccess(true);
      })
      .catch((err) => {
        toast.cancel();
        console.log("错误返回");
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <StyleFollowupLog
        className={`attention_unhandled ${className}`}
        onClick={handleLogModal}
      >
        {window.location.href.includes("?attention_courses=true")
          ? "添加日志"
          : btnName || "标记处理"}
      </StyleFollowupLog>
      {openDialog && requestSuccess && (
        <AddLogModalDialog
          babyExtraInfo={babyExtraInfo}
          addFollowUpLogUrl={addFollowUpLogUrl}
          grades={grades}
          calculationBasics={calculationBasics}
          literacies={literacies}
          handleCloseModal={handleCloseModal}
          teachers={teachers}
          sourceReasons={sourceReasons}
        />
      )}
    </React.Fragment>
  );
};

export default FollowupLog;
