// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".schools-media_lessons .xinyaui-dialog .xinyaui-dialog__ft .xinyaui-dialog__btn{color:#333}.schools-media_lessons .xinyaui-dialog .xinyaui-dialog__ft .xinyaui-dialog__btn:first-of-type{font-weight:400}.schools-media_lessons{background-color:#f9f7ef}.schools-media_lessons .container{padding:0}.comp_school_exams_list .interview_exam_emphases{padding:15px;font-size:16px;font-weight:700}.comp_school_exams_list .school_exams_box{background-color:#fff;box-shadow:0 1px 2px 0 rgba(0,1,0,.15);margin-bottom:10px}.comp_school_exams_list .school_exams_box:last-child{margin-bottom:0}.comp_school_exams_list .school_exams_box .exam_title{font-size:16px;color:#49c114;padding:15px;border-bottom:.5px solid #eee;font-weight:700}.comp_school_exams_list .school_exams_box ul li{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:right 15px center;background-size:8px;padding:15px}.comp_school_exams_list .school_exams_box ul li img{width:25px;height:24px;margin-right:8px}.comp_school_exams_list .school_exams_box ul li span{font-weight:700;font-size:16px}.comp_school_exams_list .school_exams_box ul>li+li{border-top:.5px solid #eee}@media screen and (orientation:landscape)and (min-width:1024px){.comp_school_exams_list,.interview_school_list .school_list{max-width:500px;margin:0 auto}}", ""]);
// Exports
module.exports = exports;
