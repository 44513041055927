import React, { useState, useEffect} from "react";
import "../shared/tailwindcss.scss";
import Capsule from "../shared/Capsule";
import BookIcon from "point_rewards/book.png";
import BoxIcon from "point_rewards/box.png";
import MoneyIcon from "point_rewards/money.png";
import NotFoundIcon from "point_rewards/not-found.png";
import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up'
BScroll.use(Pullup)
import { request, csrfHeaders } from "../../components/utils/request";


const PointExchangeRecords = (props) => {
  const lineStyle = {
    width: '44px',
    height: 0,
    borderTop: '1px solid #FFC000',
  }
  const capusleStyle = {
    backgroundColor: '#FFC000',
    color: 'white',
  }
  const boxShadowStyle = {
    boxShadow: '0px -3px 6px rgba(255, 192, 0, 0.15)',
  }
  // let myrecordItems
  let page = props.page

  let myrecordItems = [...props.records]

  const [recordItems, setRecordItems] = useState(myrecordItems)
  const [shouldLoadMore, setshouldLoadMore] = useState(props.has_more)
  /* 处理上拉加载更多数据 */
  let bscroll
  let loadMore = props.has_more
  useEffect(() => {
    initBscroll()
  }, [])
  useEffect(() => {
    setshouldLoadMore(loadMore)
  }, [loadMore])
  useEffect(() => {
  }, [recordItems])
  const initBscroll = () => {
    bscroll = new BScroll('.container', {
      pullUpLoad: true,
      click: true,
    })
    bscroll.on('pullingUp', pullingUpHandler)
  }
 
  const pullingUpHandler = () => {
    if (!loadMore) {
      return
    }
    fetchRecords().then((res) => {
      page = res.data.page
      loadMore = res.data.has_more
      if (res.data.records) {
        myrecordItems=[...myrecordItems,...res.data.records]
        setRecordItems([...myrecordItems])
        bscroll.refresh()
      }
      if (loadMore) {
        bscroll.finishPullUp()
      } else {
        setshouldLoadMore(false)
        bscroll.closePullUp()
      }
    }).catch((err) => {
      console.log(err)
      bscroll.finishPullUp()
    })
  }

  function fetchRecords() {
    const url = `${location.origin}${location.pathname}?page=${parseInt(page) + 1}`
    return request({
      url: url,
      method: "get",
      headers: csrfHeaders
    })
  }
  /* 处理上拉加载更多数据 */

  const Rules = () => {
    return (
      <div className="bg-yellow flex flex-col items-center p-px-15 mx-px-15 mt-px-15" style={{borderTopLeftRadius: '15px', borderTopRightRadius: '15px'}}>
        <Capsule text="水晶规则" style={capusleStyle} />
        <div className="mt-3.5">
          <div className="flex flex-row items-start">
              <div className=" items-center justify-center flex pt-0.5">
                <div className="w-4 h-4 text-center leading-4 bg-yellow-dark rounded-full text-px-14 text-white">1</div>
              </div>
              <div className="ml-px-10 text-gray-dark text-px-14">水晶可通过首次上课/答题获取，课程重新学习不再有水晶奖励；</div>
          </div>
          <div className="flex flex-row items-start mt-2">
            <div className=" items-center justify-center flex pt-0.5">
              <div className="w-4 h-4 text-center leading-4 bg-yellow-dark rounded-full text-px-14 text-white">2</div>
            </div>
              <div className="ml-px-10 text-gray-dark text-px-14">水晶有效期为一年，有新水晶收入时，按最新的时间往后延一年；</div>
          </div>
          <div className="flex flex-row items-start mt-2">
            <div className=" items-center justify-center flex pt-0.5">
              <div className="w-4 h-4 text-center leading-4 bg-yellow-dark rounded-full text-px-14 text-white">3</div>
            </div>
              <div className="ml-px-10 text-gray-dark text-px-14">水晶仅限于水晶兑换商城使用，不可提现。</div>
          </div>
        </div>
      </div>
    )
  }

  const ItemImage = (item) => {
    if (item.kind === 'reward' || item.kind === 'compensate') {
      return BookIcon
    } else if (item.kind === "exchange") {
      return BoxIcon
    } else if (item.kind === "refund") {
      return MoneyIcon
    }
  }

  const pointCostClassName = (item) => {
    if (item.kind === 'refund') {
      return 'font-bold text-gray-dark text-px-14'
    }
    return 'font-bold text-yellow text-px-14'
  }

  const pointCostText = (item) => {
    if (item.kind === 'exchange') {
      return `-${item.amount}`
    }
    return `+${item.amount}`
  }

  const ItemList = () => {
    return (
      <div className="px-px-15">
        {recordItems.map((item, i) => {
          return (
            <div key={item.id}>
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center">
                  <img src={ItemImage(item)} style={{width: '24px', height: '24px'}}/>
                  <div className="font-bold text-gray-dark text-px-14 ml-2">{item.kind_text}</div>
                </div>
                <div className={pointCostClassName(item)}>{pointCostText(item)}</div>
              </div>
              <div className="ml-6 mt-2 text-gray-dark text-px-14">《{item.kind === 'reward' || item.kind === 'compensate' ? item.course_name : item.commodity_name}》</div>
              <div className="ml-7 mt-2 text-gray-light" style={{fontSize: '11px'}}>{item.created_at}</div>
              {i !== recordItems.length - 1 && <div className="w-100" style={{margin: '12px 0', borderTop: '1px solid #E7E7E7'}}></div>}
            </div>
          )
        })}
      </div>
    )
  }

  const NotFound = () => {
    return (
      <div className="flex flex-col items-center justify-center" style={{marginTop: '68px'}}>
        <img src={NotFoundIcon} style={{width: '122px', height: '122px'}}/>
        <div className="text-center text-gray text-base mt-5">这里目前什么都没有哦~</div>
      </div>
    )
  }

  return (
    <div className="bg-white min-h-screen flex flex-col">
      {Rules()}
      <div className="text-center flex flex-row items-center justify-center px-px-15 py-px-12" style={boxShadowStyle}>
        <div style={lineStyle}></div>
        <div className="text-yellow text-lg px-3 font-bold">水晶明细</div>
        <div style={lineStyle}></div>
      </div>
      {
        recordItems.length > 0
        ?
        ItemList()
        :
        NotFound()
      }
    </div>
  )
}

export default PointExchangeRecords