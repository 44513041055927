// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/time_sale.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp-footer-member-card-btn{text-align:center;font-weight:700;color:#fff;height:50px;line-height:50px;border:none;font-size:15px;outline:none;border-radius:0;background-color:#6ad33b;background-repeat:no-repeat}.comp-footer-member-card-btn.on-sale{text-align:right;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:9px 18px;background-size:70px}", ""]);
// Exports
module.exports = exports;
