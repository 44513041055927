import React from "react";
import PropTypes from "prop-types";
import { ToastManager } from "../utils/Toast";
import {
  MemberCardRechargeDialog,
  MemberCardRechargeDialogProxy,
} from "./MemberCardRechargeDialog";
import { reloadPage, checkDecimal } from "../utils/helpers";
import { request, csrfHeaders } from "../utils/request";

import "./FooterMemberCardBtn.scss";

// TODO remove btn_buy id
export default class FooterMemberCardBtn extends React.Component {
  constructor(props) {
    super(props);
  }
  getForFree = () => {
    console.log("getForFree", this.props.target_id, this.props.target_type);
    this.placeOrderToast = ToastManager.showLoading("提交中...");
    request({
      url: this.props.experience_order_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        target_id: this.props.target_id,
        target_type: this.props.target_type,
      },
    })
      .then((resp) => {
        this.placeOrderToast.cancel();
        const data = resp.data;
        if (data.errno) {
          ToastManager.showError("购买失败");
          return;
        }

        // TODO notify users the error
        if (data.err_code && data.redirect_url) {
          window.location = data.redirect_url;
          return;
        }

        reloadPage();
      })
      .catch((err) => {
        this.placeOrderToast.cancel();
        ToastManager.showError("购买失败");
      });
  };
  onClick = (e) => {
    if (typeof this.props.beforeShowDialog === "function") {
      this.props.beforeShowDialog();
      if (this.props.isReturn) {
        return;
      }
    }
    if (parseInt(this.props.price) === 0) {
      this.getForFree();
      return;
    }
    if (!this.dialogProxy) {
      this.dialogProxy = new MemberCardRechargeDialogProxy({
        ...this.props,
      });
    }
    this.dialogProxy.open();
  };

  render() {
    const {
      btn_label,
      on_sale,
      price,
      target_type,
      virtual_account,
      ecoupons_available,
      ecoupons,
    } = this.props;
    // console.log("--FooterMemberCardBtn--", this.props);

    // 奖学金余额
    let virtualAccountMoney = virtual_account
      ? Number(virtual_account.money)
      : 0;

    // 可用券余额
    let AvailableCouponPrice = ecoupons_available
      ? Number(ecoupons[0].price)
      : 0;

    // 最终抵扣后支付的金额（注：小于等于 0 则取 0）
    let lastDeductionValue =
      Number(price) - AvailableCouponPrice - virtualAccountMoney;

    if (checkDecimal(lastDeductionValue)) {
      lastDeductionValue = lastDeductionValue.toFixed(2);
    }

    console.log(
      "奖学金: ",
      virtualAccountMoney,
      "可用券: ",
      AvailableCouponPrice
    );
    console.log(
      "最终支付: ",
      lastDeductionValue,
      "判断后最终支付: ",
      lastDeductionValue > 0 ? lastDeductionValue : 0
    );
    const isExperience =
      target_type === "Experience" || target_type === "PromotionActivity";
    return (
      <button
        id="btn_buy"
        className={`comp-footer-member-card-btn ${
          (on_sale ? "on-sale" : "", isExperience ? "promotion_self" : "")
        }`}
        type="button"
        onClick={this.onClick}
        style={{
          width: parseInt(price) === 0 ? "80%" : "",
        }}
      >
        {parseInt(price) !== 0 ? btn_label : "免费领取"}

        {
          // 仅显示经验活动
          isExperience &&
            (lastDeductionValue !== Number(price) ? (
              <p>
                抵扣后仅
                <span>{`¥${
                  lastDeductionValue > 0 ? lastDeductionValue : "0"
                }`}</span>
              </p>
            ) : (
              <p>尚无奖学金可抵扣</p>
            ))
        }
      </button>
    );
  }
}

FooterMemberCardBtn.propTypes = Object.assign(
  {},
  MemberCardRechargeDialog.propTypes,
  {
    btn_label: PropTypes.string.isRequired,

    beforeShowDialog: PropTypes.func,
  }
);

FooterMemberCardBtn.defaultProps = {
  isReturn: false,
};
