import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import icon_play_normal from "../../../../assets/images/icon_play_normal.png";
import icon_play_selected from "../../../../assets/images/icon_play_selected.gif";
import answer_line from "term_review/icon_report_lie.png";
import icon_report_up from "term_review/icon_report_up.png";
import icon_wrongs from "term_review/icon_wrongs.png";
import icon_write_white from "term_review/icon_write_white.png";
import icon_report_pen from "term_review/icon_report_pen.png";
import icon_report_download from "term_review/icon_report_download.png";
import icon_R2_again from "reading/icon_R2_again.png";
import { clipboard } from "../../../components/utils/helpers.js";
import "./ReadingSpecial.scss";
import ReportHeaderDay from "../../utils/study_report_info/ReportHeaderDay";
import RightFloatIcons from "../../study_package_activities/RightFloatIcons";
import icon_study_go from "reading/icon_study_go.png";
import styled from "styled-components";
import icon_good_words from "reading/icon_good_words.png";
import icon_status_back1 from "c4/icon_status_back1.png";
import icon_status_back2 from "c4/icon_status_back2.png";
import icon_status_back3 from "c4/icon_status_back3.png";
import icon_status_back4 from "c4/icon_status_back4.png";

import icon_up1 from "c4/icon_up1.png";
import icon_up2 from "c4/icon_up2.png";
import icon_up3 from "c4/icon_up3.png";
import icon_up4 from "c4/icon_up4.png";
import icon_clock from "c4/icon_clck.png";
import icon_to from "c4/icon_to.png";
import icon_upper from "c4/icon_upper.png";
import icon_upper_gery from "c4/icon_upper_gery.png";
import icon_index from "c4/icon_index.png";
import icon_play from "c4/icon_play.png";
import icon_points from "c4/icon_points.png";
import icon_border_green from "c4/icon_border_green.png";
import icon_border_blue from "c4/icon_border_blue.png";
import icon_book from "c4/icon_book.png";
import icon_groups from "c4/icon_groups.png";
import icon_wrong from "c4/icon_wrong.png";
import Word from "../components/Word";
import "../../utils/ReactMarkDown.scss";
import arrow_right from "../../../../assets/images/arrow-right-white.png";
import { PROPS_ATTR } from "react_ujs";
const studyStatus = [
  {
    name: "未学习",
    backColor: "#333",
    fontColor: "#333",
    icon: icon_up1,
    backImg: icon_status_back1,
  },
  {
    name: "未通过",
    backColor: "#FFEFEF",
    fontColor: "#FF6868",
    icon: icon_up1,
    backImg: icon_status_back1,
  },
  // {
  //   name: "用时久",
  //   backColor: "#FFEFD5",
  //   fontColor: "#FFA000",
  //   icon: icon_up2,
  //   backImg: icon_status_back2,
  // },
  {
    name: "掌握",
    backColor: "#E7F0FF",
    fontColor: "#7BA6EF",
    icon: icon_up3,
    backImg: icon_status_back3,
  },
  {
    name: "熟练掌握",
    backColor: "#E4FFE4",
    fontColor: "#55C656",
    icon: icon_up4,
    backImg: icon_status_back4,
  },
];
const PreStatus = styled.div`
  width: 62px;
  height: 20px;
  display: flex;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: ${({ index }) => studyStatus[index].fontColor};
  background: url(${({ index }) => studyStatus[index].backImg}) 0 0 no-repeat;
  background-size: 100% 100%;
`;

const NextStatus = styled.div`
  width: 72px;
  height: 20px;
  display: flex;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  color: ${({ index }) => studyStatus[index].fontColor};
  background: url(${({ index }) => studyStatus[index].backImg}) 0 0 no-repeat;
  background-size: 100% 100%;
`;

const QuizItem = (props) => {
  console.log("QuizItem", props);
  const [isClose, setIsClose] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [prev_grasp_state, set_prev_grasp_state] = useState(
    props.prev_grasp_state && !isNaN(props.prev_grasp_state)
      ? Number(props.prev_grasp_state)
      : 0
  );
  const [current_grasp_state, set_current_grasp_state] = useState(
    props.current_grasp_state && !isNaN(props.current_grasp_state)
      ? Number(props.current_grasp_state)
      : 0
  );
  return (
    <div className="classes-quizs">
      <div className="quizs-item">
        <div className="quizs-desc">
          <div className="quizs-desc-left">
            <div>{props.name}</div>
            {(current_grasp_state || prev_grasp_state) && !props.subjective ? (
              <div className="status">
                {prev_grasp_state ? (
                  <PreStatus index={prev_grasp_state}>
                    {studyStatus[prev_grasp_state].name}
                  </PreStatus>
                ) : (
                  ""
                )}
                {/* <div className="pre-status">11</div> */}
                {prev_grasp_state &&
                current_grasp_state &&
                current_grasp_state !== prev_grasp_state ? (
                  <img src={icon_to} className="icon-to"></img>
                ) : (
                  ""
                )}
                {current_grasp_state ? (
                  current_grasp_state &&
                  current_grasp_state !== prev_grasp_state ? (
                    <NextStatus
                      index={current_grasp_state}
                      className="next-status"
                    >
                      <span> {studyStatus[current_grasp_state].name}</span>
                      {prev_grasp_state ? (
                        <img
                          src={studyStatus[current_grasp_state].icon}
                          className={
                            current_grasp_state < prev_grasp_state
                              ? "icon-transfeom"
                              : ""
                          }
                        ></img>
                      ) : (
                        ""
                      )}
                    </NextStatus>
                  ) : (
                    ""
                  )
                ) : current_grasp_state ? (
                  <PreStatus index={current_grasp_state}>
                    {studyStatus[current_grasp_state].name}
                  </PreStatus>
                ) : (
                  // <NextStatus
                  //   index={current_grasp_state}
                  //   className="next-status"
                  // >
                  //   <span> {studyStatus[current_grasp_state].name}</span>
                  //   {/* <img
                  //     src={studyStatus[2].icon}
                  //     className="icon-transfeom"
                  //   ></img> */}
                  // </NextStatus>
                  ""
                )}
                {/* <div className="next-status">22</div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          {props.current_grasp_state > 1 || props.subjective ? (
            ""
          ) : (
            <div
              className="quizs-desc-right"
              onClick={() => {
                window.location.href = props.url;
                // if (isClose) {
                //   setIsClose(false);
                // } else {
                //   setIsClose(true);
                // }
              }}
              // style={{
              //   backgroundColor:
              //     current_grasp_state || prev_grasp_state
              //       ? studyStatus[current_grasp_state || prev_grasp_state]
              //           .backColor
              //       : "",
              //   color:
              //     current_grasp_state || prev_grasp_state
              //       ? studyStatus[current_grasp_state || prev_grasp_state]
              //           .fontColor
              //       : "",
              // }}
            >
              复习
              <div className="icon_study_go">
                <img src={icon_study_go}></img>
              </div>
            </div>
          )}
        </div>
        {isClose ? (
          <div className="quiz-items">
            {props.quizzes.map((itm, inx) => {
              return (
                <div
                  className="quiz-item"
                  style={{ marginTop: inx == 0 ? 0 : "" }}
                  key={itm.id + "-" + inx}
                >
                  <div className="quiz-question">
                    <div className="question-index">{inx + 1}</div>
                    <div className="question-body">
                      {itm.question}
                      {/* <ReactMarkdown
                          source={itm.question}
                          className="markdown_content"
                        ></ReactMarkdown> */}
                    </div>
                  </div>
                  <div className="quiz-answer">
                    <div className="user-answer ">
                      <span>你的答案：</span>
                      <span
                        style={{
                          color: itm.input_correct ? "#66D167" : "#FF6868",
                        }}
                      >
                        {itm.is_img
                          ? ""
                          : itm.input_answer.audio
                          ? ""
                          : itm.input_answer}
                        {/* {itm.question_type == 1
                            ? itm.input_answer.content
                            : itm.input_answer} */}
                      </span>
                      <span
                        style={{
                          color: itm.input_correct ? "#66D167" : "#FF6868",
                        }}
                      >{`(${itm.input_correct ? "正确" : "错误"})`}</span>
                      {itm.is_img ? (
                        <div
                          className="img-answer"
                          style={{
                            border: "1px solid #E9E9E9",
                            // border: `2px solid  ${
                            //   itm.input_correct ? "#5AC2F9" : "#FF6868"
                            // }`,
                            // backgroundColor: itm.input_correct
                            //   ? "#E2F5FF"
                            //   : "#FFF0F0",
                            marginBottom: itm.input_correct ? 6 : 20,
                          }}
                        >
                          <img src={itm.input_answer}></img>
                        </div>
                      ) : itm.input_answer.audio ? (
                        <>
                          <img
                            className="icon_play_normal"
                            src={
                              this.state.selectedId ==
                              itm.input_answer.audio.id + "top"
                                ? icon_play_selected
                                : icon_play_normal
                            }
                            onClick={() => {
                              this.setState(
                                {
                                  selectedId: t.input_answer.audio.id + "top",
                                },
                                () => {
                                  this.promisifiedPlayEffectAudio(
                                    itm.input_answer.audio.id + "top"
                                  ).then(() => {
                                    this.setState({
                                      selectedId: "",
                                    });
                                  });
                                }
                              );
                            }}
                          ></img>
                          <audio
                            id={itm.input_answer.audio.id + "top"}
                            url={itm.input_answer.audio.url}
                          ></audio>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {!itm.input_correct ? (
                      <div className="correct-answer">
                        <span>正确答案：</span>
                        <span style={{ color: "#66D167" }}>
                          {itm.is_img ? "" : itm.correct_answer}
                          {/* {itm.correct_answer} */}
                          {/* {itm.question_type == 1
                              ? itm.correct_answer.content
                              : itm.correct_answer} */}
                        </span>
                        {itm.is_img ? (
                          <div
                            className="img-answer"
                            style={{
                              border: "1px solid #E9E9E9",
                              // border: "2px solid  #5AC2F9",
                              // backgroundColor: "#E2F5FF",
                              marginBottom: 6,
                            }}
                          >
                            <img src={itm.correct_answer}></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {(current_grasp_state && current_grasp_state == 2) ||
                    (!current_grasp_state &&
                      prev_grasp_state &&
                      prev_grasp_state == 2) ? (
                      <div className="quiz-times">
                        <img src={icon_clock} className="icon_clock"></img>
                        <div style={{ color: "#5AC2F9" }}>
                          {itm.answer_time
                            ? `你的耗时：${mapTotime(itm.answer_time)}`
                            : ""}
                        </div>
                        {itm.answer_time && itm.avg_time ? (
                          <span
                            style={{
                              height: 11,
                              display: "inline-block",
                              margin: "0px 4px",
                              borderRight: "1px solid #e8e8e8",
                            }}
                          ></span>
                        ) : (
                          ""
                        )}
                        <div
                          style={{ color: "#999999" }}
                          // style={{ paddingLeft: 10 }}
                        >
                          {itm.avg_time
                            ? `平均耗时：${mapTotime(itm.avg_time)}`
                            : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}

            <div
              className="quiz-no-open"
              onClick={() => {
                setIsClose(false);
              }}
            >
              <span style={{ marginRight: 6 }}>收起</span>
              <img src={icon_upper_gery}></img>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const QuestionTitle = styled.div`
  position: relative;
  display: inline;
  padding-left: 25px;
  white-space: pre-line;
  line-height: 26px;
  &::before {
    content: "${({ index }) => index}";
    font-size: 14px;
    display:block;
    position:absolute;
    left:0;
    top: 4px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;
    background: #ff973c;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 4px;
    margin-right: 8px;
  }
`;
const CountText = styled.span`
  color: #ff973c;
  font-weight: 700;
`;
const promisifiedPlayEffectAudio = (ElementId) => {
  console.log("------------", ElementId);
  return new Promise((resolve, reject) => {
    let audio = document.getElementById(ElementId);
    if (!audio) {
      return;
    }
    console.log("------------", audio);
    audio.play();
    setTimeout(() => {
      resolve();
    }, 500);
  });
};
const Wrongs = ({ it, renderAnswer, ix, isChinese, isLast, indexStar }) => {
  const [showArticle, setShowArticle] = useState(false);
  // let it=poetry_articles

  return (
    <div
      className="question-items"
      key={"question-items" + ix}
      style={{
        marginBottom: isLast ? 0 : it.description ? "" : 20,
      }}
    >
      {(it.name || it.description) && (
        <div className="question-item question-item-article first-item">
          <div className="practice_question_connects">
            <div
              className="article_title"
              onClick={() => {
                setShowArticle((per) => !per);
              }}
            >
              <div>{"《" + it.name + "》"}</div>
              <img
                src={icon_report_up}
                className={`icon_report_up ${showArticle ? "" : "trans"}`}
              ></img>
            </div>

            {showArticle ? (
              <div className="article_content">
                <ReactMarkdown
                  className="markdown_content"
                  source={it.description}
                  escapeHtml={false}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {it.wrong_quizzes &&
        it.wrong_quizzes.length > 0 &&
        it.wrong_quizzes.map((itm, idx) => {
          indexStar++;
          return (
            <div key={"answer_line" + idx}>
              {(it.description || it.poetry_article_title || idx !== 0) && (
                <div className="answer_line">
                  <img src={answer_line} className="answer_line-left"></img>
                  <img src={answer_line} className="answer_line-right"></img>
                </div>
              )}
              <div
                className={
                  it.description || it.poetry_article_title || idx !== 0
                    ? "question-item question-item-im"
                    : "question-item question-item-im last-item"
                }
              >
                <div className="practice_question_question_title">
                  <QuestionTitle className="question_title" index={indexStar}>
                    {itm.question}
                  </QuestionTitle>
                </div>

                {itm.question_photo_url || itm.question_body ? (
                  <div
                    className="practice_question_question_questionaire"
                    style={{ marginTop: 20 }}
                  >
                    <div className="questionaire_type2">
                      {itm.question_body && (
                        <ReactMarkdown
                          className="markdown_content"
                          source={itm.question_body}
                          escapeHtml={false}
                        />
                      )}

                      {itm.question_photo_url && (
                        <div
                          className="img_photo_url"
                          style={{ padding: "0px 15px 20px" }}
                        >
                          <img
                            className="img_photo"
                            src={itm.question_photo_url}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%", height: 20 }}></div>
                )}
                {itm.quiz_type == 3
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.materials || null,
                      itm.subjective
                    )
                  : itm.quiz_type == 2
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.materials || null,
                      itm.subjective
                    )
                  : itm.quiz_type == 1
                  ? renderAnswer(
                      itm.input_correct,
                      itm.quiz_type,
                      itm.input_answer_url
                        ? {
                            url: itm.input_answer_url,
                            id: itm.input_answer_id,
                          }
                        : itm.input_answer,
                      itm.correct_answer_url
                        ? {
                            url: itm.correct_answer_url,
                            id: itm.correct_answer_id,
                          }
                        : itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.materials || null,
                      itm.subjective
                    )
                  : renderAnswer(
                      itm.input_correct,
                      0,
                      itm.input_answer,
                      itm.correct_answer,
                      itm.hint,
                      itm.material_title || "",
                      itm.materials || null,
                      itm.subjective
                    )}
                {/* {      <div
                  className="quiz-bottom"
                  style={{ justifyContent: isChinese ? "center" : "" }}
                >
                  <div
                    className="quiz-modify"
                    style={{ opacity: itm.revise_state ? 0.6 : "" }}
                    onClick={() => {
                      if (itm.revise_state) {
                        return;
                      }
                      window.location.href = itm.wrong_quiz_revisal_url;
                    }}
                  >
                    <img src={icon_write_white}></img>
                    <div>{itm.revise_state ? "已订正" : "订正"}</div>
                  </div>
                  {isChinese ? (
                    ""
                  ) : (
                    <div
                      className="quiz-silimar"
                      onClick={() => {
                        window.location.href = itm.practise_url;
                      }}
                    >
                      <img src={icon_report_pen}></img>
                      <div>{"类似题练习"}</div>
                    </div>
                  )}
                </div>} */}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default class Report extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "report");
    this.state = {
      showDownLoad: false,
      showMore: false,
    };
    this.quizIndex = 0;
  }
  renderMaterials = (materials) => {
    let str = "";
    materials.map((i, d) => {
      str = str + i.content;
    });
    return str;
  };
  renderAnswer = (
    ans_correct,
    type = 0,
    input_ans,
    correct_ans,
    hint,
    material_title,
    materials,
    subjective
  ) => {
    return input_ans ? (
      <>
        <div className="back_tari_course">
          <div
            className="back_tari_course_connect_top"
            style={{
              borderRadius:
                hint && !ans_correct ? "10px 10px 0px 0px " : "10px",
            }}
          >
            <div className="back_tari_course_connect_top_1">
              {input_ans && (
                <div>
                  {"你的答案："}
                  {input_ans.url ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        color: ans_correct ? "" : "#FF6868",
                      }}
                    >
                      {input_ans}
                    </div>
                  )}

                  {!subjective && (
                    <span
                      style={{
                        color: ans_correct ? "#66D167" : "#FF6868",
                        fontSize: 16,
                      }}
                    >
                      {ans_correct ? "（正确）" : "（错误）"}
                    </span>
                  )}
                  {type == 3 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 2 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}

                  {type == 1 && input_ans.url && (
                    <div className="error_ans_img">
                      <img className="ans_img" src={input_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
              {(subjective || !ans_correct) && correct_ans && (
                <div>
                  {subjective ? "参考答案：" : "正确答案："}
                  {correct_ans.url ? (
                    ""
                  ) : (
                    <div style={{ display: "inline", color: "#66d167" }}>
                      {correct_ans}
                    </div>
                  )}

                  {type == 2 && input_ans.url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "correct_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "correct_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "correct_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"correct_" + input_ans.id}
                        src={correct_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 1 && correct_ans.url && (
                    <div className="correct_ans_img">
                      <img className="ans_img" src={correct_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {(hint || material_title || materials) && !ans_correct && (
            <div
              className="back_tari_course_connect_bottom"
              // style={{ paddingBottom: hint && material_title ? "" : 0 }}
            >
              <div className="style_line">
                <div
                  style={{
                    height: 15,
                    borderBottom: "1px dashed #FFCD01",
                  }}
                ></div>
              </div>
              {material_title && !ans_correct && (
                <div
                  className="style_ans_analysis"
                  style={{ marginBottom: 20 }}
                >
                  <div className="style_ans_title">本题考点</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      className="markdown_content"
                      // source={hint}
                      // escapeHtml={false}
                    >
                      {material_title}
                    </div>
                  </div>
                </div>
              )}
              {materials && !ans_correct && (
                <div
                  className="style_ans_analysis"
                  style={{ marginBottom: 20 }}
                >
                  <div className="style_ans_title">积累的素材</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      className="markdown_content"
                      // source={hint}
                      // escapeHtml={false}
                    >
                      {this.renderMaterials(materials)}
                    </div>
                  </div>
                </div>
              )}
              {hint && !ans_correct && (
                <div className="style_ans_analysis">
                  <div className="style_ans_title">答案解析</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <ReactMarkdown
                      className="markdown_content"
                      source={hint}
                      escapeHtml={false}
                    ></ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  handlePdfPractice(boolState) {
    this.setState({ showDownLoad: boolState });
  }
  handleCopyUrl(e) {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    const parentUrl = e.currentTarget.getAttribute("data-url");
    console.log("parentIndex: ", parentUrl, parentIndex, this.state.pdfUrl);

    let currentClickUrl = this.state.pdfUrl;
    // trial_period_notice?.document?.download_url || "zhantie";
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  }
  KonwPoins = (media_lesson_grasp) => {
    console.log(media_lesson_grasp, "kb_courses_grasp---------");
    return (
      <div>
        <div className="title-style">
          <img src={icon_book} style={{ width: 30, height: 26 }}></img>
          <div className="title-font">知识点掌握情况</div>
        </div>
        {media_lesson_grasp.media_lessons &&
          media_lesson_grasp.media_lessons.map((it, index) => {
            return (
              <div
                className="classes"
                key={"kb_courses_grasp" + index}
                style={{ marginTop: index == 0 ? 0 : "" }}
              >
                {/* <div className="classes-title">
                  <div className="media-lesson-icon">
                    <img src={arrow_right}></img>
                  </div>
                  {it.media_lesson_name || ""}
                </div> */}
                {it.kb_lessons.map((item, idx) => {
                  return idx > 1 && !this.state.showMore ? (
                    ""
                  ) : (
                    <div key={"item" + idx}>
                      <QuizItem {...item}></QuizItem>
                    </div>
                  );
                })}
                {it.kb_lessons.length > 2 ? (
                  <div
                    className="showMore"
                    onClick={() => {
                      this.setState((preState) => ({
                        showMore: !preState.showMore,
                      }));
                    }}
                  >
                    {this.state.showMore ? "收起" : "展开全部"}
                    <img
                      src={icon_report_up}
                      className={this.state.showMore ? "" : "trans"}
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
    );
  };
  render() {
    this.quizIndex = 0;
    const { prev_day_url, next_day_url, day } = this.props;
    return (
      <>
        {prev_day_url || next_day_url ? (
          <ReportHeaderDay
            prevDayUrl={prev_day_url}
            day={day}
            nextDayUrl={next_day_url}
          />
        ) : (
          ""
        )}
        <div className="term-review-back" style={{}}>
          <div className="term-review-info">
            <div className="term-review-info-name">{this.props.name || ""}</div>
          </div>
          {this.props.kb_lessons ? (
            <div
              className="term-review-wrongs"
              style={{
                paddingBottom:
                  this.props.kb_lessons && this.props.kb_lessons.length > 2
                    ? 20
                    : "",
              }}
            >
              <div className="know-points add-shadow">
                {this.KonwPoins(
                  {
                    media_lessons: [{ kb_lessons: this.props.kb_lessons }],
                  } || null,
                  this.props.study_suggest
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {this.props.sub_subjects && this.props.sub_subjects.length > 0 && (
            <div className="term-review-wrongs">
              <div className="title-style">
                <img
                  src={icon_good_words}
                  style={{ width: 30, height: 28 }}
                ></img>
                <div className="title-font">{"好词好句积累汇总"}</div>
              </div>
              <div>
                {this.props.sub_subjects.map((itm, idx) => {
                  return (
                    <Word
                      key={"materials" + idx}
                      type={itm.material_type == 3 ? 1 : 0}
                      name={itm.name}
                      materials={[...itm.materials]}
                      material_index={idx}
                      isReport={false}
                      // name={material_item.name}
                      // materials={material_item.materials}
                      // material_index={material_index}
                    ></Word>
                  );
                })}
              </div>
            </div>
          )}
          {(this.props.poetry_articles &&
            this.props.poetry_articles.length > 0) ||
          (this.props.wrong_quizzes && this.props.wrong_quizzes.length > 0) ? (
            <div className="term-review-wrongs">
              <div className="title-style">
                <img src={icon_wrongs} style={{ width: 28, height: 28 }}></img>
                <div className="title-font">{"错题汇总"}</div>
              </div>
              <div>
                {this.props.poetry_articles &&
                  this.props.poetry_articles.length > 0 &&
                  this.props.poetry_articles.map((article, article_index) => {
                    this.quizIndex =
                      this.quizIndex + article.wrong_quizzes.length;
                    console.log(this.quizIndex);
                    return (
                      <Wrongs
                        it={article}
                        ix={article_index}
                        indexStar={
                          this.quizIndex - article.wrong_quizzes.length
                        }
                        isLast={
                          true
                          // article_index ==
                          //   this.props.poetry_articles.length - 1 &&
                          // !this.props.wrong_quizzes
                        }
                        key={"article" + article_index}
                        renderAnswer={this.renderAnswer.bind(this)}
                        isChinese={
                          this.props.course_type == "chinese" ? true : false
                        }
                      ></Wrongs>
                    );
                  })}
                {this.props.wrong_quizzes &&
                  this.props.wrong_quizzes.map((quiz_item, quiz_index) => {
                    this.quizIndex++;
                    return (
                      <Wrongs
                        it={{ wrong_quizzes: [quiz_item] }}
                        ix={quiz_index}
                        indexStar={this.quizIndex - 1}
                        isLast={
                          quiz_index == this.props.wrong_quizzes.length - 1
                        }
                        key={"quizItem" + quiz_index}
                        renderAnswer={this.renderAnswer.bind(this)}
                        isChinese={
                          this.props.course_type == "chinese" ? true : false
                        }
                      ></Wrongs>
                    );
                  })}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {this.state.showDownLoad && (
            <PdfPracticeNoteCopy
              handlePdfPractice={this.handlePdfPractice.bind(this)}
              copyUrl={this.handleCopyUrl.bind(this)}
              unitPdfUrl={
                this.state.pdfUrl
                  ? this.state.pdfUrl
                  : "暂时没有课件，尽快联系老师吧！"
                // "这是粘贴内容"
                // trial_period_notice?.document?.download_url || "这是粘贴内容"
              }
              unitName={this.state.pdfName}
              is_android_os={this.props.is_android_os}
              // activityCode={study_package_activity_code}
            />
          )} */}
        </div>
        <RightFloatIcons
          isShowBack={true}
          onBackClick={() => {
            window.location.href = this.props.study_package_url;
          }}
        />
        {this.props.study_url ? (
          <div className="report-bottom">
            <div
              className="report-bottom-again"
              onClick={() => {
                window.location.href = this.props.study_url;
              }}
            >
              {"再做一遍"}
              <img src={icon_R2_again}></img>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* {this.state.showDownLoad && (
          <PdfPracticeNoteCopy
            handlePdfPractice={this.handlePdfPractice.bind(this)}
            copyUrl={this.handleCopyUrl.bind(this)}
            unitPdfUrl={
              this.state.pdfUrl
                ? this.state.pdfUrl
                : "暂时没有课件，尽快联系老师吧！"
              // "这是粘贴内容"
              // trial_period_notice?.document?.download_url || "这是粘贴内容"
            }
            unitName={this.state.pdfName}
            is_android_os={this.props.is_android_os}
            // activityCode={study_package_activity_code}
          />
        )} */}
      </>
    );
  }
}
