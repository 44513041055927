import React from "react";
import PropTypes from "prop-types";

import FooterMemberCardBtn from "../shared/FooterMemberCardBtn";
import GiftsDialogProxy from "./GiftsDialog";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { reloadPage } from "../utils/helpers";

import "./ExperienceFooter.scss";

import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";

// TODO remove btn_buy id
export default class ExperienceFooter extends React.Component {
  onTrialListen = (e) => {
    if (typeof ga === "function") {
      switch (this.props.target_type) {
        case "Experience":
          ga("send", "event", "Audios", "Audition", "Experiences");
          break;
        case "ExperienceAlbum":
          ga("send", "event", "Audios", "Audition", "ExperienceAlbums");
          break;
      }
    }
    if (typeof this.props.onTrialListenClick === "function") {
      this.props.onTrialListenClick();
    }
  };

  onFreeListen = (e) => {
    if (typeof ga === "function") {
      switch (this.props.target_type) {
        case "Experience":
          ga("send", "event", "Users", "Free", "Experiences");
          break;
        case "ExperienceAlbum":
          ga("send", "event", "Users", "Free", "ExperienceAlbums");
          break;
      }
    }
    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          free: this.props.free,
        });
      }
      this.sharePosterProxy.open();
    } else {
      new GiftsDialogProxy().open();
    }
  };

  sendStats() {
    if (typeof ga === "function") {
      switch (this.props.target_type) {
        case "Experience":
          ga("send", "event", "Users", "Buy", "Experiences");
          break;
        case "ExperienceAlbum":
          ga("send", "event", "Users", "Buy", "ExperienceAlbums");
          break;
      }
    }
  }

  footerMemberCardBtnBeforeShowDialog = () => {
    this.sendStats();
  };

  onTrialCoupon = (e) => {
    this.sendStats();

    const buttons = [
      {
        label: "取消",
        onClick: () => this.confirmDialog.close(),
      },
      {
        label: "确认兑换",
        onClick: () => {
          this.confirmDialog.close();

          this.placeOrder();
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        本次购买将使用免费体验券兑换，确认兑换吗？
      </Dialog>
    );
  };

  onBuy = (e) => {
    this.sendStats();

    const buttons = [
      {
        label: "取消",
        onClick: () => this.confirmDialog.close(),
      },
      {
        label: "确认购买",
        onClick: () => {
          this.confirmDialog.close();

          this.placeOrder();
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        本次购买将扣除{this.props.card_amount}个会员点，确认购买吗？
      </Dialog>
    );
  };

  placeOrder() {
    this.placeOrderToast = ToastManager.showLoading("提交中...");
    request({
      url: this.props.experience_order_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        target_id: this.props.target_id,
        target_type: this.props.target_type,
      },
    })
      .then((resp) => {
        this.placeOrderToast.cancel();

        const data = resp.data;
        if (data.errno) {
          ToastManager.showError("购买失败");
          return;
        }

        // TODO notify users the error
        if (data.err_code && data.redirect_url) {
          window.location = data.redirect_url;
          return;
        }

        reloadPage();
      })
      .catch((err) => {
        this.placeOrderToast.cancel();
        ToastManager.showError("购买失败");
      });
  }

  createRightBtnTrialCoupon() {
    return (
      <button
        id="btn_buy"
        className="btn-buy"
        onClick={this.onTrialCoupon}
        style={{ width: this.props.price == 0 ? "80%" : "" }}
      >
        <span>
          原价<del>¥{this.props.price}</del>
        </span>
        收听（体验券）
      </button>
    );
  }

  createRightBtn() {
    return (
      <button
        id="btn_buy"
        className="btn-buy"
        onClick={this.onBuy}
        style={{ width: this.props.price == 0 ? "80%" : "" }}
      >
        <span>
          原价<del>¥{this.props.price}</del>
        </span>
        收听（{this.props.card_amount}点）
      </button>
    );
  }

  render() {
    console.log(this.props);
    let rightBtn;

    if (this.props.trial_coupon) {
      rightBtn = this.createRightBtnTrialCoupon();
    } else if (this.props.card || this.props.member_card) {
      rightBtn = this.createRightBtn();
    } else {
      rightBtn = (
        <FooterMemberCardBtn
          {...this.props}
          beforeShowDialog={this.footerMemberCardBtnBeforeShowDialog}
        />
      );
    }

    return this.props.hide_buy ? (
      ""
    ) : (
      <div className="comp-experience-ft">
        <div className="btn_listen" id="btn_trial_listen">
          <div className="play_logo" onClick={this.onTrialListen}>
            试听
          </div>
        </div>

        {parseInt(this.props.price) != 0 && (
          <div className="free_listen" onClick={this.onFreeListen}>
            免费听
          </div>
        )}

        {rightBtn}
      </div>
    );
  }
}

ExperienceFooter.propTypes = Object.assign(
  {},
  FooterMemberCardBtn.propTypes,
  SharePoster.propTypes,
  {
    price: PropTypes.any.isRequired,
    // card_amount: PropTypes.number.isRequired,

    card: PropTypes.bool,
    // member_card: PropTypes.bool,
    // trial_coupon: PropTypes.bool,

    target_id: PropTypes.number.isRequired,
    target_type: PropTypes.string.isRequired,

    experience_order_url: PropTypes.string.isRequired,
  }
);

ExperienceFooter.defaultProps = {
  free: "normal",
};
