import React, { Component } from "react";
import PropTypes from "prop-types";

import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";

import "./UserRecommend.scss";

const recommendTexts = [
  {
    id: 1,
    text: "这段时间每天课程坚持下来，我感觉孩子进步很大",
  },
  {
    id: 2,
    text: "知识点很全，也是这个阶段的孩子能够接受的",
  },
  {
    id: 3,
    text:
      "作为幼升小，内容全，老师讲解循序渐进，举一反三，小朋友容易理解，而且网上学习方便灵活",
  },
  {
    id: 4,
    text: "针对性比较强，覆盖面也广，特别是题型来自于真题",
  },
  {
    id: 5,
    text: "这个课程对于训练孩子的逻辑思维能力很好",
  },
  {
    id: 6,
    text: "比外面上课方便，内容多，价格还便宜",
  },
  {
    id: 7,
    text: "我们孩子很喜欢，并且能养成每天打卡的习惯，非常不错",
  },
  {
    id: 8,
    text: "课程设计很用心，我家很喜欢",
  },
  {
    id: 9,
    text: "设计合理，时间控制不错，孩子容易接受互动和视频相结合的网课",
  },
  {
    id: 10,
    text: "课程方便，形式好，时间灵活，可反复看，大人省心，价格便宜",
  },
];

export default class UserRecommend extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      isChooseDisplay: "none",
      recommendText: this.props.current_user_comment
        ? this.props.current_user_comment
        : "",
      isFillInDisplay: "none",
    };
  }

  chooseRecommend = (e) => {
    if (this.state.isChooseDisplay == "none") {
      this.setState({
        isFillInDisplay: "none",
        isChooseDisplay: "block",
      });
    }
  };

  btnCloseFillInClick = (e) => {
    this.setState({
      isFillInDisplay: "none",
    });
  };

  btnCloseRecommendListClick = (e) => {
    this.setState({
      isChooseDisplay: "none",
      isFillInDisplay: "block",
    });
  };

  handleSubmit = (e) => {
    // e.preventDefault()
    if (this.state.recommendText === "") {
      alert("请填写或选择推荐语");
    } else {
      if (this.state.recommendText.length >= 20) {
        this.setState({
          isFillInDisplay: "none",
          recommendText: this.state.recommendText,
        });

        this.toast = ToastManager.showLoading("提交中...");
        let params = {
          text: this.state.recommendText,
          commentable_id: this.props.comment_target.commentable_id,
          commentable_type: this.props.comment_target.commentable_type,
          reply_to_id: this.props.comment_target.reply_to_id,
        };
        var url = this.props.create_recommend_request_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            this.toast.cancel();
            const data = resp.data;
            console.log("正确返回");
            console.log(data);
            if (this.props.callBack) {
              this.props.callBack();
            }
            console.log(this.props.bonus);
          })
          .catch((err) => {
            console.log("错误返回");
            console.log(err);
          });

        if (this.props.poster_url) {
          if (!this.sharePosterProxy) {
            this.sharePosterProxy = new SharePosterProxy({
              posterUrl: this.props.poster_url,
              posterImgSrc: this.props.poster_img_src,
              free: this.props.free,
              bonus: this.props.bonus,
            });
          }
          this.sharePosterProxy.open();
        }
      } else {
        alert("填写推荐语字数应大于20个字");
      }
    }
  };

  senetnceItemClick = (e) => {
    var el = e.target;
    if (el.nodeName === "LI") {
      var index = parseInt(el.getAttribute("sentence-selection"));
      var selected = recommendTexts[index].text;
      console.log(selected);

      this.setState({
        recommendText: selected,
        isChooseDisplay: "none",
        isFillInDisplay: "block",
      });
    }
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.setState({
      recommendText: value,
    });
  };

  render() {
    return (
      <div className="comp-user-recommend">
        <div
          className="box_user_recommend"
          style={{ display: this.state.isFillInDisplay }}
        >
          <div className="user_recommend_dialog">
            <div className="box_recommend_content">
              <div className="recommend_title">
                <span
                  className="choose_recommend_text"
                  onClick={this.chooseRecommend.bind(this)}
                  style={{ display: "none" }}
                >
                  选择推荐语
                </span>
                <p>请填写推荐语</p>
              </div>
              <div className="field_textarea">
                <textarea
                  type="text"
                  placeholder="孩子开始上课后的收获？成绩方面、学习习惯方面等（填写推荐语字数应大于20字）..."
                  value={this.state.recommendText}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <button onClick={this.handleSubmit.bind(this)}>确定</button>
            </div>
            <div className="close_btn_right" onClick={this.btnCloseFillInClick}>
              &times;
            </div>
          </div>
        </div>
        <div
          className="recommend_choose_list"
          style={{ display: this.state.isChooseDisplay }}
        >
          <div className="recommend_list_content">
            <h3>请选择推荐语</h3>
            <ul className="ul-sentence" onClick={this.senetnceItemClick}>
              {recommendTexts.map((recommendText, index) => (
                <li key={recommendText.id} sentence-selection={index}>
                  {recommendText.text}
                </li>
              ))}
            </ul>
            <div
              className="close_btn_right"
              style={{ top: 4 }}
              onClick={this.btnCloseRecommendListClick}
            >
              &times;
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserRecommend.Proptypes = Object.assign({}, SharePoster.propTypes, {});

UserRecommend.defaultProps = {
  free: "study_package",
};
