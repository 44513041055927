import React from "react";
import { request, csrfHeaders } from "../utils/request";
import PropTypes from "prop-types";
import Raven from "raven-js";
import { debounce } from "../utils/helpers";
import {
  ConfirmDialog,
  ConfirmDialogManager,
} from "../utils/confirm_dialog/ConfirmDialog";

import { SharePosterProxy, SharePoster } from "./SharePoster";
import qrcode from "qrcode_xiaoya.jpg";

import "./PromotionActivityPassBox.scss";

export default class PromotionActivityPassBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onInviteClick = (e) => {
    if (!this.sharePosterProxy) {
      this.sharePosterProxy = new SharePosterProxy({
        posterUrl: this.props.poster_url,
        posterImgSrc: this.props.poster_img_src,
        posterTemplate: this.props.poster_photo_template_url,
        posterCreation: this.props.poster_creation,
        mode: "front_end",

        free: this.props.free,
        promotionMode: this.props.mode,
        textDescribe: true,
        targetType: this.props.target_type,
        maxInviteUsersCount: this.props.max_invite_users_count,
        groupMsg: this.props.group_msg,
      });
    }
    this.sharePosterProxy.open();
  };

  handleInvisible = (boolState) => {
    this.setState({
      invisible: boolState,
    });
  };

  componentDidMount() {
    if (this.props.pay_record) {
      const { order_confirm_url } = this.props.pay_record;
      const buttons = [
        {
          label: this.props.mode == "market" ? "我需要" : "我需要，去收听讲座",
          onClick: () => {
            this.confirmDialog.close();
            debounce(this.ajaxRequest.call(this, order_confirm_url));
          },
        },
        {
          label: "不需要，请退回奖学金",
          onClick: () => {
            this.confirmDialog.close();
            debounce(this.againConfirmDialog.call());
          },
        },
      ];
      this.confirmDialog = ConfirmDialogManager.open(
        <ConfirmDialog buttons={buttons}>
          该订单为奖学金全额抵扣，<span>免费报名</span>
          的订单。请确认您是否需要？
        </ConfirmDialog>
      );
    }
  }

  ajaxRequest(url) {
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {
        console.log(resp);
        const redirect_url = resp.data.redirect_url;
        if (redirect_url) {
          window.location.href = redirect_url;
          return;
        }
        window.location.href = location.href + "?time=" + new Date().getTime();
        // window.location.reload(true);
      })
      .catch((err) => {
        Raven.captureException(err);
      });
  }

  againConfirmDialog = () => {
    const { order_confirm_url, refund_url } = this.props.pay_record;
    const buttons = [
      {
        label: "不取消",
        onClick: () => {
          this.confirmDialog.close();
          debounce(this.ajaxRequest.call(this, order_confirm_url));
        },
      },
      {
        label: "请取消",
        onClick: () => {
          this.confirmDialog.close();

          // 跳转到报名页-未报名状态（奖学金被退回）
          debounce(this.ajaxRequest.call(this, refund_url));
        },
      },
    ];
    this.confirmDialog = ConfirmDialogManager.open(
      <ConfirmDialog buttons={buttons}>
        活动名额有限，是否确认取消订单？
      </ConfirmDialog>
    );
  };

  render() {
    console.log("PromotionActivityPassBox", this.props);

    const { group_msg, cc_qrcode_url, mode, gift_commodities_url } = this.props;
    const { invisible } = this.state;

    if (mode === "none") {
      return null;
    }
    return mode == "live" ? (
      <LiveBoughtSuccess
        invisible={invisible}
        groupMsg={group_msg}
        qrcodeUrl={cc_qrcode_url}
        onInviteClick={this.onInviteClick}
        handleInvisible={this.handleInvisible}
      />
    ) : (
      <MarketBoughtSuccess
        handleFreeListen={this.onInviteClick}
        giftUrl={gift_commodities_url}
      />
    );
  }
}

const LiveBoughtSuccess = ({
  invisible,
  groupMsg,
  qrcodeUrl,
  onInviteClick,
  handleInvisible,
}) => {
  return (
    <div
      className="comp-prom-activity-pass-box"
      style={{ display: invisible ? "none" : "block" }}
    >
      <p className="btn_hide" onClick={handleInvisible.bind(this, true)}>
        &times;
      </p>
      <div className="text_describe">
        <p>您已报名成功！添加老师微信，并发送关键字入分享群！</p>
        <p>
          <span>
            添加老师后请发送：
            {groupMsg ? groupMsg : 108}
          </span>
        </p>
      </div>
      <img src={qrcodeUrl ? qrcodeUrl : qrcode} />
      <button className="btn_pass" type="button" onClick={onInviteClick}>
        邀请好友报名
      </button>
    </div>
  );
};

const MarketBoughtSuccess = ({ handleFreeListen, giftUrl }) => {
  return (
    <div className="comp-prom-activity-pass-box">
      {giftUrl && (
        <button className="update-address">
          <a href={giftUrl}>修改地址</a>
        </button>
      )}
      <button
        className="invite-friend"
        onClick={handleFreeListen}
        style={giftUrl ? {} : { width: "100%" }}
      >
        <p>邀请好友</p>
        <span>赚取奖学金</span>
      </button>
    </div>
  );
};

PromotionActivityPassBox.propTypes = Object.assign(
  {},
  SharePoster.propTypes,
  {}
);
