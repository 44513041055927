import React, { Component } from "react";
import PropTypes from "prop-types";
import UserAgent from "../utils/UserAgent";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import interview_finish from "interview_exams/interview_finish.png";

import "./Interview.scss";

const TargetSchoolList = ({ target_schools, handleTargetSchoolUrlClick }) => {
  return (
    <div className="target_school_list">
      <ul>
        {target_schools.map((school, index) => (
          <li
            key={index}
            onClick={handleTargetSchoolUrlClick}
            data-url={school.url}
          >
            <img src={school.photo_url} alt="" />
            <div className="content_title">
              <p>{school.name}</p>
              <span>{school.area}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const InterviewSchoolList = ({
  all_schools,
  handleIconImg,
  selectSchoolRequest,
  handleTargetSchoolClick,
  list,
}) => {
  return (
    <div className="interview_school_list">
      <div className="school_list">
        <div className="school_list_dialog">
          <div className="school_list_content">
            <h3>请选择目标学校（最多3所）</h3>
            <ul>
              {all_schools.map((school, idx) => (
                <li
                  key={idx}
                  onClick={handleIconImg}
                  data-index={idx}
                  data-school={school.id}
                >
                  <img
                    src={interview_finish}
                    style={{ display: list[idx] ? "none" : "block" }}
                  />
                  {school.name}
                </li>
              ))}
            </ul>
            <div className="school_choose">
              <span className="school_sure" onClick={selectSchoolRequest}>
                确定
              </span>
            </div>
          </div>
          <div className="close_btn_right" onClick={handleTargetSchoolClick}>
            &times;
          </div>
        </div>
      </div>
    </div>
  );
};

class TargetSchools extends Component {
  constructor(props) {
    super(props);

    let selectedSchoolIDS = [];
    // 遍历 target_schools 取出 id
    if (this.props.target_schools) {
      this.props.target_schools.forEach((element) => {
        selectedSchoolIDS.push(`${element.id}`);
      });
    }

    let allSchoolIDS = [];
    // 遍历 all_schools 取出 id
    if (this.props.all_schools) {
      this.props.all_schools.forEach((ele) => {
        allSchoolIDS.push(`${ele.id}`);
      });
    }

    let list = Array(
      this.props.all_schools ? this.props.all_schools.length : 0
    ).fill(true);

    // 双层遍历，寻找两个数组中相同的id，如果相等设为false
    allSchoolIDS.forEach((element, index) => {
      selectedSchoolIDS.forEach((ele, idx) => {
        if (element == ele) {
          list[index] = false;
        }
      });
    });

    this.state = {
      selectedSchoolIDS: selectedSchoolIDS || [],
      isDisplay: false,
      list,
    };
  }

  handleTargetSchoolUrlClick = (e) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute("data-url");
    console.log("TargetSchoolUrl", url);
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  handleTargetSchoolClick = () => {
    // 点击显示、隐藏目标选择弹框
    if (this.state.isDisplay == false) {
      this.setState({
        isDisplay: true,
      });
    } else {
      this.setState({
        isDisplay: false,
      });
    }
  };

  //点击选中学校，显示勾选框
  handleIconImg = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    let school_id = e.currentTarget.getAttribute("data-school");
    // console.log("idx: ", idx + " " + "school_id: ", school_id)
    if (this.state.selectedSchoolIDS.length < 3) {
      this.state.list.splice(parseInt(idx), 1, !this.state.list[parseInt(idx)]);
      this.setState(
        {
          list: this.state.list,
        },
        () => {
          if (!this.state.list[parseInt(idx)]) {
            var schoolIDs = this.state.selectedSchoolIDS;
            schoolIDs.push(school_id);
            this.state.selectedSchoolIDS = [...new Set(schoolIDs)];
            // console.log("72: ",this.state.selectedSchoolIDS)
          } else {
            var schoolIDs = this.state.selectedSchoolIDS;
            schoolIDs.splice(schoolIDs.indexOf(school_id), 1);
            this.setState({
              selectedSchoolIDS: schoolIDs,
            });
            // console.log("79: ",this.state.selectedSchoolIDS)
          }
        }
      );
    } else {
      var schoolIDs = this.state.selectedSchoolIDS;
      if (schoolIDs.includes(school_id)) {
        this.state.list.splice(
          parseInt(idx),
          1,
          !this.state.list[parseInt(idx)]
        );
        console.log(schoolIDs.includes(school_id));
        this.setState(
          {
            list: this.state.list,
          },
          () => {
            if (!this.state.list[parseInt(idx)]) {
              var schoolIDs = this.state.selectedSchoolIDS;
              schoolIDs.push(school_id);
              this.state.selectedSchoolIDS = [...new Set(schoolIDs)];
              // console.log(">= 3 selectedSchoolIDS !list: ",this.state.selectedSchoolIDS)
            } else {
              var schoolIDs = this.state.selectedSchoolIDS;
              schoolIDs.splice(schoolIDs.indexOf(school_id), 1);
              this.setState({
                selectedSchoolIDS: schoolIDs,
              });
              // console.log(">= 3 selectedSchoolIDS list: ",this.state.selectedSchoolIDS)
            }
          }
        );
      } else {
        alert("目标学校最多只能选三所哦～");
      }
    }
  };

  //3所学校选中后，点击确定发起request请求
  selectSchoolRequest = (e) => {
    if (this.state.selectedSchoolIDS.length == 0) {
      alert("请先选择您的目标学校～");
    } else {
      this.toast = ToastManager.showLoading("提交中...");
      let url = this.props.school_select_url;
      let params = {
        school_ids: this.state.selectedSchoolIDS,
      };
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((res) => {
          this.toast.cancel();
          const data = res.data;
          console.log(data);

          if (this.state.isDisplay == true) {
            this.setState({
              isDisplay: false,
            });
          }
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  AddTargetSchool = () => {
    return (
      <div className="target_school_content">
        <div
          className="button_target_school"
          onClick={this.handleTargetSchoolClick}
        >
          <span>添加目标学校</span>
        </div>
        <p>可添加3所目标学校在此，方便查看</p>
      </div>
    );
  };

  render() {
    const { target_schools, all_schools } = this.props;
    const { list } = this.state;
    const targetSchools = target_schools && target_schools.length > 0;
    return (
      <div className="target_school_box">
        {targetSchools && (
          <div className="change_school" onClick={this.handleTargetSchoolClick}>
            更换学校
          </div>
        )}
        <div className="target_school_text">我的目标学校</div>

        {targetSchools ? "" : this.AddTargetSchool()}
        {
          // 添加的三所目标学校
          targetSchools && (
            <TargetSchoolList
              target_schools={target_schools}
              handleTargetSchoolUrlClick={this.handleTargetSchoolUrlClick}
            />
          )
        }

        {
          // 弹框选择学校
          this.state.isDisplay == true && (
            <InterviewSchoolList
              all_schools={all_schools}
              handleIconImg={this.handleIconImg}
              selectSchoolRequest={this.selectSchoolRequest}
              handleTargetSchoolClick={this.handleTargetSchoolClick}
              list={list}
            />
          )
        }
      </div>
    );
  }
}

class OtherSchools extends Component {
  handleOtherSchoolUrlClick = (e) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute("data-url");
    console.log("OtherSchoolUrl", url);
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  render() {
    return (
      <div className="other_school_box">
        <div className="target_school_text">其他学校</div>
        <div className="school_lists">
          <ul>
            {this.props.other_schools.map((school, index) => (
              <li
                key={index}
                onClick={this.handleOtherSchoolUrlClick}
                data-url={school.url}
              >
                {school.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default class Interview extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const {
      all_schools,
      target_schools,
      other_schools,
      school_select_url,
    } = this.props;
    return (
      <div className="comp_schools_exam">
        <TargetSchools
          all_schools={all_schools}
          target_schools={target_schools}
          school_select_url={school_select_url}
        />
        <OtherSchools other_schools={other_schools} />
      </div>
    );
  }
}

Interview.propTypes = {
  all_schools: PropTypes.array,
  other_schools: PropTypes.array,
  school_select_url: PropTypes.string,
};
