import React from "react";
import "./CourseConsultant.scss";

const ScanQrCodePattern = ({ wxScene, url, scanTargetText }) => {
  return (
    <div className="comp_wechat_attention">
      <div className="attention_step">
        <img src={url} alt="" />
        <div className="step">
          <div className="add_text">{scanTargetText}</div>
          {!wxScene && (
            <div className="link_text">
              <p>
                领<strong>10元</strong>新人优惠券
              </p>
              <span>（仅限首次添加可领取）</span>
            </div>
          )}
          <div className="attention">
            {wxScene ? (
              <p>根据提示报名活动</p>
            ) : (
              <p>
                <strong>福利价</strong>报名活动
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseConsultant = (props) => {
  if (props.is_register) {
    // wx_scene ? 关注公众号 : 添加老师
    return (
      <ScanQrCodePattern
        wxScene={props.wx_scene}
        url={props.wx_scene ? props.scene_qrcode_url : props.cc_qrcode_url}
        scanTargetText={`扫码${props.wx_scene ? "关注公众号" : "添加老师"}`}
      />
    );
  } else {
    // 学习顾问
    return (
      <div className="comp_course_consultant">{props.renderContent()}</div>
    );
  }
};

export default CourseConsultant;
