/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
// import styled from "styled-components";

const SearchRecords = ({ signInRecords }) => {
  useEffect(() => {
    $("#datetimepicker1").datetimepicker({
      locale: "zh-cn",
    });

    $("#datetimepicker2").datetimepicker({
      locale: "zh-cn",
    });

    let isShowUaMeta = false;
    $(".is_show").each(function () {
      $(this).css({
        display: "none",
      });
    });
    $("#is_show_ua_meta").on("click", function () {
      if (isShowUaMeta) {
        isShowUaMeta = false;
        $(this).removeClass("check_box").addClass("check_box_checked");
        $(".is_show").each(function () {
          $(this).css({
            display: "block",
            "max-width": "400px",
          });
        });
      } else {
        isShowUaMeta = true;
        $(".is_show").each(function () {
          $(this).css({
            display: "none",
          });
        });

        $(this).removeClass("check_box_checked").addClass("check_box");
      }
    });
  }, []);
  return (
    <table className="table table-striped table-responsive">
      <thead>
        <tr className="ths">
          <th>ID</th>
          <th>姓名</th>
          <th>IP</th>
          <th>设备(系统型号-设备型号)</th>
          <th>方式</th>
          <th>记录更新时间</th>
          <th>此设备上课次数</th>
          <th className="is_show">上课设备UA原始数据</th>
        </tr>
      </thead>
      <tbody>
        {signInRecords.map(
          ({
            id,
            userId,
            name,
            ip,
            device,
            signInType,
            createdAt,
            attendCourseCount,
            uaMeta,
          }) => {
            return (
              <tr key={id}>
                <td>{userId}</td>
                <td>{name}</td>
                <td>{ip}</td>
                <td>{device}</td>
                <td>{signInType}</td>
                <td>{createdAt}</td>
                <td>{attendCourseCount}</td>
                <td className="is_show">{uaMeta}</td>
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};

export default SearchRecords;
