import React from "react";
import Raven from "raven-js";
import PropTypes from "prop-types";
import { formatAudioTime } from "../formatAudioTime";
import { request } from "../request";
import { detectedTypeOf } from "../../utils/helpers.js";
import "./MediaLessonPlayer.scss";

const PLAYER_STATE = {
  PLAY: "PLAY",
  PAUSE: "PAUSE",
  LOADING: "LOADING",
  STOP: "STOP",
  ERROR: "ERROR",
  LOCKED: "LOCKED",
};

const debug = (isConsole) => {
  if (isConsole) {
    const log = console.warn;
    return log;
  } else {
    return () => {};
  }
};

const beforeCheck = function (beforeFn, fn) {
  return function (...args) {
    if (beforeFn.apply(this, args)) {
      return;
    }
    return fn.apply(this, args);
  };
};
class ProgressBar extends React.Component {
  render() {
    const {
      handleMouseEnd,
      handleMouseMove,
      containerProgressClick,
      progressPoints,
      handleMouseStart,
      handleTouchStart,
      handleTouchEnd,
      handleTouchMove,
      audioTotalTime,
      playerStateCssClass,
      btnPlayerClick,
      audioCurrentTime,
      audioPlayProgress,
      renderProgressBar,
    } = this.props;
    if (typeof renderProgressBar === "function") {
      return renderProgressBar({
        ...this.props,
      });
    }
    return (
      <div className="player_progress_bar">
        <div className="flex_box">
          <div className={playerStateCssClass} onClick={btnPlayerClick} />
          <div className="played_time">{audioCurrentTime}</div>
          <div
            className="progress_bar"
            onMouseUp={handleMouseEnd}
            onMouseLeave={handleMouseEnd}
            onMouseMove={handleMouseMove}
            onClick={containerProgressClick}
            ref={(r) => (this.bar = r)}
          >
            {progressPoints.length > 0 &&
              progressPoints.map((point, index) => (
                <div
                  className="break_point"
                  key={index}
                  style={{ left: point.left }}
                />
              ))}

            <div
              className="progress_bar_status"
              style={{ width: `${audioPlayProgress}%` }}
            >
              <span
                className="handler"
                onMouseDown={handleMouseStart}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchMove={handleTouchMove}
              />
            </div>
          </div>
          <div className="player_duration_time">{audioTotalTime}</div>
          <div className="full_screen" />
        </div>
      </div>
    );
  }
}
/**
 * MediaLessonPlayer 支持audioList播放模式
 * audioIndex 的更新需要在onEnded prop 里面更新父组件，并把更新后的audioIndex 传入props
 * getPlayer('ctx')方法允许后续使用者添加需要的参数，但是不适宜暴露MediaLessonPlayer 的上下文this
 * 后续TODO：支持MediaLessonPlayer内部更新 audioIndex 并 在非依赖注入的前提下可以调用playNext方法
 */
class MediaLessonPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.debug = debug(props.debug);
    this.debug("MediaLessonPlayer props debug: ", props.debug);
    this.state = {
      activeAudioIndex: props.activeAudioIndex || 0,
      playerState: PLAYER_STATE.PAUSE,
      audioPlayProgress: 0,
      progressPoints: [],
      currentAudioDuration: 0,
    };
    this.lastTime = 0;
    this.initPlayer();
  }

  getPlayer = (valueType, ...args) => {
    const valueTypes = {
      ctx: () => ({
        pause: this.audioPause,
        play: this.audioPlay,
        lock: this.audioLock,
        currentTime: this.audioEl.currentTime,
        totalTime: this.audioEl.duration,
      }),
      stateCssClass: (state) => {
        const stateGroup = {
          PLAY: "pause_play_btn",
          PAUSE: "start_play_btn",
          STOP: "start_play_btn",
          LOADING: "loading_play_btn",
          LOCKED: "start_play_btn",
        };
        return stateGroup[state];
      },
    };
    return !!valueTypes[valueType] && valueTypes[valueType].apply(null, args);
  };

  setPlayerState = (states, callBack, callBackImmediately = false) => {
    if (detectedTypeOf(states) === "object") {
      const playerState = states["playerState"];
      if (playerState) {
        this.setState(
          {
            ...states,
            playerState: PLAYER_STATE[playerState.toUpperCase()],
          },
          () =>
            callBackImmediately && typeof callBack === "function" && callBack()
        );
      }
    } else {
      this.setState(
        {
          playerState: PLAYER_STATE[states.toUpperCase()],
        },
        () =>
          callBackImmediately && typeof callBack === "function" && callBack()
      );
    }
    if (!callBackImmediately && typeof callBack === "function") {
      callBack();
    }
  };

  fetchAudioInfo = (audioUrl) => {
    return request({
      url: `${audioUrl}?avinfo`,
      method: "get",
    }).then((resp) => {
      this.debug(`获取当前音频${audioUrl}数据：`, resp);
      return Math.floor(resp.data.format.duration);
    });
  };

  getCurrentAudioInfo = (valueType, ...args) => {
    const info = {
      currentTime: () => {
        if (this.audioEl) {
          return Math.round(this.audioEl.currentTime);
        } else {
          return 0;
        }
      },
      duration: () => {
        if (this.audioEl) {
          return Math.floor(this.audioEl.duration || 0);
        }
      },
      audio: () => {
        const { audioList } = this.props;
        const { activeAudioIndex } = this.state;
        return audioList[activeAudioIndex];
      },
    };
    return !!info[valueType] && info[valueType].apply(null, args);
  };

  checkProp = (prop, type) => {
    return detectedTypeOf(this.props[prop]) === type;
  };

  // TODO：同步 playerState
  checkPlayerLocked = () => this.doProp("isPlayerLocked");

  doProp = (prop, ...args) => {
    try {
      if (this.checkProp(prop, "function")) {
        this.debug("MediaLessonPlayer 执行传入的prop：", prop);
        return this.props[prop].apply(null, args);
      }
    } catch (e) {
      console.error(`MediaLessonPlayer 执行传入的prop ${prop} 错误：`, e);
    }
  };

  preloadAudio = (audioUrl) => {
    return new Promise((resolve) => {
      let audio = new Audio();
      audio.src = audioUrl;
      audio.load();
      const loaded = () => {
        resolve(audioUrl);
        audio.removeEventListener("canplaythrough", loaded);
      };
      audio.addEventListener("canplaythrough", loaded, false);
    });
  };

  initPlayer = () => {
    // this.doProp("initPlayer", this.state, PLAYER_STATE);

    // 设置正确的playerState
    if (this.checkPlayerLocked()) {
      this.state.playerState = PLAYER_STATE.LOCKED;
    }

    // 添加检测player locked
    const addPlayerLockedChecker = [
      "componentDidUpdate",
      "btnPlayerClick",
      "containerProgressClick",
      "handleTouchStart",
      "handleTouchMove",
      "handleTouchEnd",
      "handleMouseStart",
      "handleMouseMove",
      "handleMouseEnd",
    ];
    addPlayerLockedChecker.forEach((t) => {
      this[t] = beforeCheck(this.checkPlayerLocked, this[t]);
    });
  };

  componentDidMount() {
    this.doProp("children", {
      play: this.getPlayer("ctx").play,
      pause: this.getPlayer("ctx").pause,
    });
    this.createProgressPoints();

    const audio = this.getCurrentAudioInfo("audio");
    if (audio.url) {
      this.fetchAudioInfo(audio.url).then((duration) =>
        this.setState({
          currentAudioDuration: duration,
        })
      );
    }

    // 预加载audio
    // Promise.all(
    //   this.props.audioList.map(audio => this.preloadAudio.call(null, audio.url))
    // ).catch(err => console.log(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.playerState !== this.state.playerState) {
      this.doProp(
        "onPlayerStateChange",
        this.state.playerState,
        this.getPlayer("ctx")
      );
    }
    if (prevProps.activeAudioIndex != this.props.activeAudioIndex) {
      this.createProgressPoints();
      this.playNext(
        this.props.activeAudioIndex,
        this.props.activeAudioIndex !== 0
      );
    }
  }

  createProgressPoints = () => {
    const points = this.doProp("createProgressPoints");
    console.log(points, "points");
    this.setState({
      progressPoints: points,
    });
  };

  onPlay = () => {
    this.setPlayerState(
      "loading",
      this.doProp.bind(null, "onPlay", this.getPlayer("ctx")),
      true
    );
  };

  onLoadedMetadata = () => {
    this.doProp("onLoadedMetadata", this.getPlayer("ctx"));
  };

  onCanPlayThrough = () => {
    this.doProp("onCanPlayThrough", this.getPlayer("ctx"));
  };

  onPause = () => {
    // this.doProp("onPause", this.getPlayer("ctx"));
  };

  onTimeUpdate = () => {
    const current = this.getCurrentAudioInfo("currentTime");
    if (this.onTimeUpdate.lastTime !== current) {
      this.setState({
        currentTime: current,
      });
      this.setPlayerState(
        "play",
        this.doProp.bind(null, "onTimeUpdate", this.getPlayer("ctx"))
      );
      const duration = this.getCurrentAudioInfo("duration");
      this.updateProgress(current, duration);
      this.onTimeUpdate.lastTime = current;
    }
  };

  onEnded = () => {
    this.setPlayerState(
      "stop",
      this.doProp.bind(null, "onEnded", this.getPlayer("ctx")),
      true
    );
    // 播放下一个audio 在componentDidUpdate中（依赖父组件activeAudioIndex更新）
  };

  playNext = (nextAudioIndex, loopAutoPlay = true) => {
    // 初始化播放参数
    this.onTimeUpdate.lastTime = 0;
    const audioListLength = this.props.audioList.length;
    this.setPlayerState(
      {
        playerState: !loopAutoPlay ? "pause" : "loading",
        audioPlayProgress: 0,
        activeAudioIndex: nextAudioIndex,
        currentAudioDuration: 0,
      },
      () => {
        this.audioEl.src = this.props.audioList[nextAudioIndex].url;
        if (nextAudioIndex <= audioListLength - 1) {
          const playTime = this.doProp("initFirstPlay", nextAudioIndex);
          this.audioPlay(playTime || 0);
        }
      },
      true
    );
  };

  audioPause = () => {
    this.audioEl.pause();
    this.debug("语音暂停 下一个状态", this.state.playerState);
    this.setPlayerState(
      "pause",
      this.doProp.bind(null, "onPause", this.getPlayer("ctx")),
      true
    );
  };

  audioLock = () => {
    this.setPlayerState("locked");
  };

  catchTimeToPlay = (time) => {
    if (time != undefined) {
      this.audioEl.pause();
      this.audioEl.play();
      this.audioEl.currentTime = time;
    }
  };

  catchErrorToPlay = (err, time) => {
    this.debug("内部播放错误: ", err);

    // err 设置loading状态
    this.setState(() => ({
      playerState: PLAYER_STATE.LOADING,
    }));

    // 只触发一次播放【避免infinite loop】
    if (!this.audioPlay.catchTry) {
      this.catchPlay(time || 0);
      this.audioPlay.catchTry = true;
    }

    Raven.captureException(err);
  };

  catchPlay = (time) => {
    let playPromise = null;

    if (this.audioEl) {
      playPromise = this.audioEl.play();
      this.debug("语音播放 下一个状态", this.state.playerState);
    }

    if (playPromise) {
      playPromise
        .then(() => {
          this.catchTimeToPlay(time);
        })
        .catch((err) => {
          this.catchErrorToPlay(err);
        });
    } else {
      // 老版本浏览器没有支持audio play promise化
      try {
        this.catchTimeToPlay(time);
      } catch (err) {
        this.catchErrorToPlay(err, time);
      }
    }
  };

  audioPlay = (time) => {
    if (time != undefined) {
      this.catchPlay(time);
      return;
    }

    const audio = this.getCurrentAudioInfo("audio");
    if (!this.state.currentAudioDuration && audio.url) {
      this.fetchAudioInfo(audio.url)
        .then((duration) =>
          this.setState({
            currentAudioDuration: duration,
          })
        )
        .then(() => {
          this.catchPlay();
        })
        .catch((err) => {
          Raven.captureException(err);
          this.catchPlay();
        });
    } else {
      this.catchPlay();
    }
  };

  btnPlayerClick = () => {
    if (
      this.state.playerState === PLAYER_STATE.PAUSE ||
      this.state.playerState === PLAYER_STATE.STOP
    ) {
      if (!this.btnPlayerClick.first) {
        this.btnPlayerClick.first = true;
        const playTime = this.doProp(
          "initFirstPlay",
          this.state.activeAudioIndex
        );
        this.audioPlay(playTime);
      } else {
        this.audioPlay();
      }
    } else {
      this.audioPause();
    }
  };

  containerProgressClick = (e) => {
    if (!this.getProgressBarRef()) {
      return;
    }
    const relativeLeft =
      e.clientX - this.containerProgress.bar.getBoundingClientRect().left;
    this.debug("点击进度条移动：", relativeLeft);
    this.seek(relativeLeft);
  };

  updateProgress = (current, totalTime) => {
    if (Math.round(current) >= totalTime) {
      this.setState(() => ({
        audioPlayProgress: 100,
      }));
      return;
    }
    this.setState(() => ({
      audioPlayProgress: Number(((current * 100) / totalTime).toFixed(2)),
    }));
  };

  doProgressBarEndMoving(endProgressTouchPointX) {
    if (!this.getProgressBarRef()) {
      return;
    }

    let relativeLeft =
      endProgressTouchPointX -
      this.containerProgress.bar.getBoundingClientRect().left;
    if (relativeLeft > this.containerProgress.bar.offsetWidth) {
      this.seek(this.containerProgress.bar.offsetWidth);
      return;
    }
    if (relativeLeft < 0) {
      this.seek(0);
      return;
    }

    if (Number.isNaN(relativeLeft)) {
      return;
    }
    this.seek(relativeLeft);
  }

  doProgressBarMoving(movedProgressTouchPointX) {
    if (!this.getProgressBarRef()) {
      return;
    }
    const relativeLeft =
      movedProgressTouchPointX -
      this.containerProgress.bar.getBoundingClientRect().left;
    const progressBarWidth = this.containerProgress.bar.offsetWidth;
    this.updateProgress(relativeLeft, progressBarWidth);
  }

  handleMouseStart = (e) => {
    this.handleProgressSelected = true;
    this.audioEl.pause();
  };

  handleMouseEnd = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    this.handleProgressSelected = false;
    let endProgressTouchPointX = e.clientX;
    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };

  handleMouseMove = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    let movedProgressTouchPointX = e.clientX;
    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };

  handleTouchStart = (e) => {
    if (!this.handleTouchStart.isTriggered) {
      this.audioEl.play();
      this.handleTouchStart.isTriggered = true;
    } else {
      this.audioEl.pause();
    }
  };

  handleTouchEnd = (e) => {
    let endProgressTouchPointX = e.changedTouches[0].pageX;
    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    let movedProgressTouchPointX = e.changedTouches[0].pageX;
    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };

  seek = (relativeLeft) => {
    if (!this.getProgressBarRef()) {
      return;
    }
    if (isNaN(relativeLeft)) {
      return;
    }

    let percent = 0;
    const progressBarWidth = this.containerProgress.bar.offsetWidth;
    percent = relativeLeft / progressBarWidth;
    if (percent > 1) {
      percent = 1;
    }

    this.updateProgress(relativeLeft, progressBarWidth);

    if (percent < 1 && percent >= 0) {
      // 拖动后自动播放
      const current = Number(
        Math.floor(this.audioEl.duration * percent).toFixed(0)
      );
      // this.audioEl.currentTime = current);
      this.audioPlay(current);
    }
  };

  getProgressBarRef = () => {
    let _bar_ = document.getElementById("_progress_bar_");
    if (_bar_) {
      return (this.containerProgress.bar = _bar_);
    }
    return !!this.containerProgress ? this.containerProgress.bar : null;
  };

  render() {
    const {
      playerState,
      audioPlayProgress,
      progressPoints,
      currentTime,
    } = this.state;
    // const quizzes = this.props.mediaLessonList[activeAudioIndex];

    const playerStateCssClass = this.getPlayer("stateCssClass", playerState);
    const audio = this.getCurrentAudioInfo("audio");
    const audioCurrentTime = formatAudioTime(
      currentTime || this.getCurrentAudioInfo("currentTime")
    );
    const audioTotalTime = formatAudioTime(
      this.getCurrentAudioInfo("duration") || this.state.currentAudioDuration
    );
    const audioLeftTime = formatAudioTime(
      (this.getCurrentAudioInfo("duration") ||
        this.state.currentAudioDuration) -
        (currentTime || this.getCurrentAudioInfo("currentTime"))
    );
    return (
      <div className="comp_audio_player">
        {typeof this.props.renderLockedView === "function" &&
          this.props.renderLockedView()}
        <audio
          id="audio_el"
          src={audio.url}
          ref={(audioEl) => (this.audioEl = audioEl)}
          preload="metadata"
          onTimeUpdate={this.onTimeUpdate}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onLoadedMetadata={this.onLoadedMetadata}
          onCanPlayThrough={this.onCanPlayThrough}
          onEnded={this.onEnded}
        />
        <div onClick={this.btnPlayerClick}>{this.props.renderViewImg()}</div>
        {this.props.showProgressBar && (
          <ProgressBar
            handleMouseEnd={this.handleMouseEnd}
            handleMouseMove={this.handleMouseMove}
            containerProgressClick={this.containerProgressClick}
            progressPoints={progressPoints}
            handleMouseStart={this.handleMouseStart}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
            handleTouchMove={this.handleTouchMove}
            audioLeftTime={audioLeftTime}
            playerStateCssClass={playerStateCssClass}
            btnPlayerClick={this.btnPlayerClick}
            audioPlayProgress={audioPlayProgress}
            audioCurrentTime={audioCurrentTime}
            ref={(r) => (this.containerProgress = r)}
            renderProgressBar={this.props.renderProgressBar}
          />
        )}
      </div>
    );
  }
}
MediaLessonPlayer.proTypes = {
  // call every time when new audio is invoked to be played, even in the first time to play
  initFirstPlay: PropTypes.func,
  audioList: PropTypes.array,
  createProgressPoints: PropTypes.func,
  activeAudioIndex: PropTypes.func,
  renderViewImg: PropTypes.func,
  onPlayerStateChange: PropTypes.func,
  onLoadedMetadata: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onTimeUpdate: PropTypes.func,
  onEnded: PropTypes.func,
  showProgressBar: PropTypes.bool,
  debug: PropTypes.bool,
};
export default MediaLessonPlayer;
