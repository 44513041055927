import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { request, csrfHeaders } from "../request";
import { ToptipsManager } from "../Toptips";
import { ToastManager } from "../Toast";
import { Dialog, DialogManager } from "../Dialog";
import { reloadPage } from "../helpers";
import ReactMarkdown from "react-markdown";

import "./CommentLists.scss";
import "../ReactMarkDown.scss";

// 活动评论规则
class ActivityRuleDialog extends PureComponent {
  constructor(props) {
    super(props);
  }

  btnCloseRuleClick = () => {
    this.props.hideActivityRule();
  };

  render() {
    const { promotion } = this.props;
    return (
      <div className="activity_rule">
        <div className="activity_rule_content">
          <div className="rule_content">
            <h3>活动规则</h3>
            <div className="react_markdown">
              {promotion && <ReactMarkdown source={promotion.rule} />}
            </div>
            <div
              className="close_btn_right"
              style={{ top: -4 }}
              onClick={this.btnCloseRuleClick}
            >
              &times;
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// 弹框评论（等级和内容）
class CommentsFill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIndex: this.props.rate ? this.props.rate - 1 : -1,
      rate: this.props.rate ? this.props.rate : "",
      recommendText: this.props.current_user_comment
        ? this.props.current_user_comment
        : "",
    };
  }

  btnCloseRecommendListClick = () => {
    this.props.hideDialog();
  };

  onSelectLimitClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const value = e.currentTarget.getAttribute("data-value");
    this.setState({
      selectIndex: index,
      rate: value,
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.setState({
      recommendText: value,
    });
  };

  handleSubmit = () => {
    console.log("评论需要展示的内容 ", this.state.recommendText);

    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请您给课程打个分");
      return;
    } else if (this.state.recommendText === "") {
      ToptipsManager.warn("请先填写您的推荐语");
      return;
    } else {
      if (this.state.recommendText.length >= 20) {
        this.setState({
          recommendText: this.state.recommendText,
        });
        this.toast = ToastManager.showLoading("提交中...");
        let params = {
          text: this.state.recommendText,
          rate: this.state.rate,
          commentable_id: this.props.comment_target.commentable_id,
          commentable_type: this.props.comment_target.commentable_type,
          reply_to_id: this.props.comment_target.reply_to_id,
          note: this.props.bought_phase,
        };
        if (this.props.promotion && this.props.promotion.reward) {
          params.reward_id = this.props.promotion.reward.id;
        }
        var url = this.props.create_recommend_request_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            this.toast.cancel();
            const data = resp.data;
            console.log(data);
            this.setState({
              isChooseDisplay: "none",
            });
            if (data.state === "new") {
              this.getRewardDialog();
            } else {
              window.location.href =
                window.location.href.replace("#comment", "") + "#comment";
              window.location.reload();
            }
            if (this.props.callBack) {
              this.props.callBack();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ToptipsManager.warn("填写推荐语字数应大于20个字");
        return;
      }
    }
  };

  getRewardDialog() {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => {
          this.confirmDialog.close();
          window.location.href =
            window.location.href.replace("#comment", "") + "#comment";
          window.location.reload();
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        评价提交成功，恭喜您获得{this.props.promotion.reward.name}
        ，请留意『心芽学堂』公众号通知
      </Dialog>
    );
  }

  render() {
    return (
      <div
        className="recommend_score_choose"
        style={{ display: this.state.isChooseDisplay }}
      >
        <div className="overlay"></div>
        <div className="course_list_container">
          <h3>评价</h3>
          {this.props.name && (
            <div className="course_name">《{this.props.name}》</div>
          )}
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={this.btnCloseRecommendListClick}
          >
            &times;
          </div>
          <div className="recommend_score">
            <div className="text">
              您是否愿意把课程推荐给朋友？（1-10分请打分）
            </div>
            <div className="number_list">
              <ul>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                  <li
                    key={index}
                    data-index={index}
                    data-value={item}
                    onClick={this.onSelectLimitClick.bind(this)}
                    className={
                      this.state.selectIndex == index ? "highLight" : ""
                    }
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <div className="degree">
                <span>
                  非常
                  <br />
                  不愿意
                </span>
                <span>一般</span>
                <span>
                  非常
                  <br />
                  愿意
                </span>
              </div>
            </div>
            <div className="fill_recommend">
              <p>请填写评价</p>
              <div className="field_textarea">
                <textarea
                  type="text"
                  placeholder="说说你对课程的感受或者收获吧，给别人一个参考。(请不少于20字)"
                  value={this.state.recommendText}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <button onClick={this.handleSubmit.bind(this)}>确定</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// 分级评论课程列表
export default class CommentLists extends React.Component {
  constructor(props) {
    super(props);

    let contents = "";
    if (this.props.comment_state == "course_need_to_finished") {
      contents = "为保证评价真实有效，请在课程结束后进行评价。";
    } else if (this.props.comment_state == "more_study_required") {
      contents = "为保证评价真实有效，请在开课7天后进行评价。";
    }

    const hasComment = [];
    const noHasComment = [];
    if (this.props.subStudyPackageActivities) {
      this.props.subStudyPackageActivities.forEach((element) => {
        if (element.current_user_comment != undefined) {
          hasComment.push({
            bought_phase: element.bought_phase,
            comment_state: element.comment_state,
            comment_target: element.comment_target,
            create_recommend_request_url: element.create_recommend_request_url,
            current_user_comment: element.current_user_comment,
            current_user_comment_state: element.current_user_comment_state,
            id: element.id,
            name: element.name,
            promotion: element.promotion,
            rate: element.rate,
            state: element.state,
          });
        } else {
          noHasComment.push({
            bought_phase: element.bought_phase,
            comment_state: element.comment_state,
            comment_target: element.comment_target,
            create_recommend_request_url: element.create_recommend_request_url,
            current_user_comment: element.current_user_comment,
            current_user_comment_state: element.current_user_comment_state,
            id: element.id,
            name: element.name,
            promotion: element.promotion,
            rate: element.rate,
            state: element.state,
          });
        }
      });
    }

    this.state = {
      isCourseEvaluateContainer: "none",
      comments: this.props.comments,
      page: 2,
      recommentDialogListState: false,
      showRuleDialog: false,
      subCommentListHasComment: "none",
      subCommentListNoHasComment: "none",

      contents: contents,
      hasComment: hasComment,
      noHasComment: noHasComment,
    };
  }

  // loading more comments
  loadingMoreCommentClick = () => {
    this.toast = ToastManager.showLoading("拼命加载中...");
    var request_comment_url = this.props.request_comment_url;
    let url = request_comment_url;
    let params = {
      input: {
        page: this.state.page,
        count: 10,
      },
    };
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        console.log(data);
        this.setState({
          comments: this.state.comments.concat(data.comments),
          page: this.state.page + 1,
        });
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
        // alert(data)
      });
  };

  // 弹框选择/修改推荐语
  handleAwakenClick = () => {
    console.log("hasComment: ", this.state.hasComment);
    console.log("noHasComment: ", this.state.noHasComment);

    if (this.props.subStudyPackageActivities) {
      console.log("subStudyPackageActivities 存在");
      this.setState({
        isCourseEvaluateContainer: "block", // 没填写时，“发布”模块
        subCommentListHasComment: "block", // 选择所要评价的课程,有评论列表
      });
    } else {
      this.setState({
        recommentDialogListState: true, //评论填写commentFill
        isCourseEvaluateContainer: "block", // 没填写时，“发布”模块
      });
    }

    if (this.state.hasComment.length == 1) {
      console.log("hasComment 只有一个值");
      this.setState({
        recommentDialogListState: true, // 选择所要评价的课程,有评论列表
        subCommentListHasComment: "none",
        subCommentData: this.state.hasComment[0],
      });
      return;
    }
  };

  // 填写推荐语
  handleAwakenNoCommentClick = () => {
    console.log("填写 hasComment: ", this.state.hasComment);
    console.log("填写 noHasComment: ", this.state.noHasComment);

    let findUncommented = [];
    let courseNeedToFinished;
    let moreStudyRequire;
    this.state.noHasComment.forEach((comment) => {
      if (comment.comment_state == "uncommented") {
        findUncommented.push({
          bought_phase: comment.bought_phase,
          comment_state: comment.comment_state,
          comment_target: comment.comment_target,
          create_recommend_request_url: comment.create_recommend_request_url,
          current_user_comment: comment.current_user_comment,
          current_user_comment_state: comment.current_user_comment_state,
          id: comment.id,
          name: comment.name,
          promotion: comment.promotion,
          rate: comment.rate,
          state: comment.state,
        });
      }
      if (comment.comment_state == "course_need_to_finished") {
        courseNeedToFinished = comment.comment_state;
      } else if (comment.comment_state == "more_study_required") {
        moreStudyRequire = comment.comment_state;
      }
    });
    // console.log("moreStudyRequire",moreStudyRequire, "courseNeedToFinished", courseNeedToFinished)
    // console.log("（发布，填写）当前未评论数组中只有一个uncommented的情况: ", findUncommented)

    if (this.props.subStudyPackageActivities) {
      this.setState({
        isCourseEvaluateContainer: "block", // 没填写时，“发布”模块
        subCommentListNoHasComment: "block", // 选择所要评价的课程，无评论列表
      });
    }

    // 已填写评论，但当前为评论状态只有一个值的情况
    if (this.state.noHasComment.length == 1) {
      this.setState({
        recommentDialogListState: true,
        subCommentListNoHasComment: "none",
        subCommentData: this.state.noHasComment[0],
      });
    }

    // 当前状态只有一种是 uncommented 的情况
    if (courseNeedToFinished || moreStudyRequire) {
      console.log("当前有课程时间少于7天或大于7天的情况");
      this.setState({
        subCommentListNoHasComment: "block", // 选择所要评价的课程，无评论列表
        recommentDialogListState: false,
      });
    } else if (findUncommented.length == 1) {
      this.setState({
        recommentDialogListState: true,
        subCommentListNoHasComment: "none",
        subCommentData: findUncommented[0],
      });
    }
  };

  hideDialog = () => {
    this.setState({
      recommentDialogListState: false,
    });
  };

  // 活动规则显示
  activityRuleClick = () => {
    this.setState({
      showRuleDialog: true,
    });
  };

  // 活动规则隐藏
  hideActivityRule = () => {
    this.setState({
      showRuleDialog: false,
    });
  };

  // 关闭分级弹框
  btnCloseSubCommentsClick = () => {
    this.setState({
      subCommentListHasComment: "none", // 当前页隐藏
    });
  };

  btnCloseNoSubCommentsClick = () => {
    this.setState({
      subCommentListNoHasComment: "none", // 当前页隐藏
    });
  };

  // 点击分级 唤醒有评论（修改）
  awakenFillCommentClick = (e) => {
    const data_index = e.currentTarget.getAttribute("data-index");
    const subCommentData = this.state.hasComment[data_index];
    console.log("分级数据（修改）： ", subCommentData);
    console.log("有评论内容（填写）：", subCommentData.current_user_comment);

    this.setState({
      recommentDialogListState: true,
      subCommentData: subCommentData,
      subCommentListHasComment: "none",
    });
  };
  // 点击分级 唤醒无评论（填写）
  awakenFillNoCommentClick = (e) => {
    const data_index = e.currentTarget.getAttribute("data-index");
    const subCommentData = this.state.noHasComment[data_index];
    console.log("分级数据（填写）： ", subCommentData);
    console.log("无评论内容（填写）：", subCommentData.current_user_comment);

    this.setState({
      recommentDialogListState: true,
      subCommentData: subCommentData,
      subCommentListNoHasComment: "none",
    });
  };

  // 有分级课程包（去发布评论）
  hasSubCourseIssue() {
    return (
      <div>
        {this.props.comment_state == "uncommented" && (
          <div
            className="pre_buy_but_no_commnets"
            onClick={
              this.state.hasComment.length == 0
                ? this.handleAwakenNoCommentClick
                : this.handleAwakenClick
            }
          >
            <div className="issue">发布</div>
            <div className="field_guide">
              <p>
                说说你对课程的感受或者收获吧，给别人一个参考。（请不少于20字）
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }

  // 无分级，单课程包（去发布 评论）
  simpleCourseIssue() {
    return (
      <div>
        {this.props.comment_state == "uncommented" &&
          !this.props.current_user_comment && (
            <div
              className="pre_buy_but_no_commnets"
              onClick={this.handleAwakenClick}
            >
              <div className="issue">发布</div>
              <div className="field_guide">
                <p>
                  说说你对课程的感受或者收获吧，给别人一个参考。（请不少于20字）
                </p>
              </div>
            </div>
          )}
      </div>
    );
  }

  // 活动评论规则
  activityCommentRule() {
    return (
      <div>
        {(this.props.comment_state == "uncommented" ||
          this.props.comment_state == "commented") &&
          this.props.promotion.brief && (
            <div className="activity_rule_text">
              {this.props.promotion.brief}，
              <span onClick={this.activityRuleClick}>活动规则</span>
            </div>
          )}
      </div>
    );
  }

  // 分级评论课程列表(修改)
  editSubCourseList() {
    // console.log("分级评论课程列表(修改)", this.state.subCommentListHasComment)
    return (
      <div
        className="sub_activity_box"
        style={{ display: this.state.subCommentListHasComment }}
      >
        <div className="overlay"></div>
        <div className="sub_comment_head">
          <div className="dialog_title">选择所要修改的课程</div>
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={this.btnCloseSubCommentsClick}
          >
            &times;
          </div>
        </div>
        <div className="sub_course_list">
          <ul>
            {this.state.hasComment.map((hasCom, index) => (
              <li
                key={hasCom.id}
                onClick={this.awakenFillCommentClick}
                data-index={index}
              >
                <p>{hasCom.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  // 分级评论课程列表(填写)
  fillSubCourseList() {
    return (
      <div
        className="sub_activity_box"
        style={{ display: this.state.subCommentListNoHasComment }}
      >
        <div className="overlay"></div>
        <div className="sub_comment_head">
          <div className="dialog_title">选择所要评价的课程</div>
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={this.btnCloseNoSubCommentsClick}
          >
            &times;
          </div>
        </div>
        <div className="sub_course_list">
          <ul>
            {this.state.noHasComment.map((noHasCom, index) => (
              <li
                key={noHasCom.id}
                onClick={
                  noHasCom.comment_state == "course_need_to_finished" ||
                  noHasCom.comment_state == "more_study_required"
                    ? null
                    : this.awakenFillNoCommentClick
                }
                data-index={index}
                style={{
                  display:
                    noHasCom.comment_state == "unpermitted" ? "none" : null,
                  color:
                    noHasCom.comment_state == "course_need_to_finished" ||
                    noHasCom.comment_state == "more_study_required"
                      ? "#999"
                      : null,
                }}
              >
                <p>{noHasCom.name}</p>
                {noHasCom.comment_state == "course_need_to_finished" ? (
                  <span>为保证评价真实有效，请在课程结束后进行评价。</span>
                ) : (
                  noHasCom.comment_state == "more_study_required" && (
                    <span>为保证评价真实有效，请在开课7天后进行评价。</span>
                  )
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  // 无分级课程包 待审核的状态
  commentAudit() {
    return (
      <div>
        {this.props.comment_state == "commented" && (
          <div className="verify_status_content">
            <div className="verify_status">
              <div className="verify_status_top">
                {this.props.current_user_comment_state === "audit" && (
                  <div className="status">待审核</div>
                )}
                <h3>我的评价</h3>
              </div>
              <div>
                <div className="verify_text">
                  {this.props.current_user_comment}
                </div>
                <div className="verify_comment_bottom">
                  <div
                    className="recommend_edit"
                    onClick={this.handleAwakenClick}
                  >
                    修改
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  // 有分级课程包 待审核的状态
  hasSubCommentAudit() {
    return (
      <div>
        {this.props.comment_state == "commented" && (
          <div
            className="verify_status_content"
            style={{
              padding: this.props.comment_state == "uncommented" ? 0 : null,
            }}
          >
            <div className="verify_status">
              <div className="verify_status_top">
                <h3>我的评价</h3>
              </div>
              <div className="sub_comment_box">
                {this.props.subStudyPackageActivities.map((subComment, idx) => (
                  <div
                    key={idx}
                    className="sub_comment_list"
                    style={{
                      borderTop: subComment.current_user_comment
                        ? null
                        : "none",
                      padding: subComment.current_user_comment ? null : "0",
                    }}
                  >
                    <div className="sub_comment_top">
                      {subComment.current_user_comment_state === "audit" && (
                        <div className="sub_status">待审核</div>
                      )}
                      {subComment.current_user_comment && (
                        <div className="sub_name">{subComment.name}</div>
                      )}
                    </div>
                    <div className="sub_verify_text">
                      {subComment.current_user_comment}
                    </div>
                  </div>
                ))}
              </div>
              <div className="verify_comment_bottom">
                <div
                  className="recommend_edit"
                  onClick={this.handleAwakenClick}
                >
                  修改
                </div>
                {this.props.subStudyPackageActivities &&
                  this.props.uncommented == true && (
                    <div
                      className="write_comment"
                      onClick={this.handleAwakenNoCommentClick}
                    >
                      写评价
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      subStudyPackageActivities,
      comment_state,
      total_comments_count,
    } = this.props;
    const { subCommentData, contents, comments } = this.state;

    return (
      <div
        className="course_evaluate_container"
        style={{ display: this.state.isCourseEvaluateContainer }}
      >
        {
          // 活动评论规则
          this.activityCommentRule()
        }
        {
          // 发布·模块
          subStudyPackageActivities
            ? this.hasSubCourseIssue()
            : this.simpleCourseIssue()
        }

        {
          // 待审核·评论内容模块
          subStudyPackageActivities
            ? this.hasSubCommentAudit()
            : this.commentAudit()
        }

        {(comment_state == "course_need_to_finished" ||
          comment_state == "more_study_required") && (
          <div className="verify_status_content">
            <div className="verify_status verify_status_recommend">
              <div className="verify_status_top">
                <h3>我的评价</h3>
              </div>
              <div className="verify_judge">{contents}</div>
            </div>
          </div>
        )}

        <div className="comment_list_content">
          <ul>
            {comments.map((comment, idx) => (
              <li key={idx}>
                <div className="user_evaluate_list">
                  <img data-src={comment.photo_url} className="lazyload" />
                  <div className="author">
                    <span>{comment.name}</span>&nbsp;
                    {comment.phase && "(" + comment.phase + ")"}
                  </div>
                  <div className="text">{comment.comment}</div>
                </div>
              </li>
            ))}
          </ul>
          {total_comments_count > 10 && (
            <div onClick={this.loadingMoreCommentClick}>
              {comments.length < total_comments_count && (
                <div className="loading_comment">更多评价</div>
              )}
            </div>
          )}
        </div>

        {subStudyPackageActivities && this.editSubCourseList()}

        {subStudyPackageActivities && this.fillSubCourseList()}

        {this.state.recommentDialogListState && (
          <CommentsFill
            create_recommend_request_url={
              subStudyPackageActivities
                ? subCommentData.create_recommend_request_url
                : this.props.create_recommend_request_url
            }
            comment_target={
              subStudyPackageActivities
                ? subCommentData.comment_target
                : this.props.comment_target
            }
            current_user_comment={
              subStudyPackageActivities
                ? subCommentData.current_user_comment
                : this.props.current_user_comment
            }
            bought_phase={
              subStudyPackageActivities
                ? subCommentData.bought_phase
                : this.props.bought_phase
            }
            promotion={
              subStudyPackageActivities
                ? subCommentData.promotion
                : this.props.promotion
            }
            rate={
              subStudyPackageActivities ? subCommentData.rate : this.props.rate
            }
            name={subStudyPackageActivities ? subCommentData.name : null}
            hideDialog={this.hideDialog}
          />
        )}
        {this.state.showRuleDialog && (
          <ActivityRuleDialog
            promotion={this.props.promotion}
            hideActivityRule={this.hideActivityRule}
          />
        )}
      </div>
    );
  }
}

CommentLists.propTypes = {
  create_recommend_request_url: PropTypes.string,
  comment_target: PropTypes.object,
};
