// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon-fav-64.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icon-faved-64.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/icon-gift.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".comp-school-ft{background:#fff;width:100%;height:50px;box-shadow:0 -1px 1px rgba(0,0,0,.1);display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.comp-school-ft .btn-buy{width:60%;height:100%;background:#6ad33b;color:#fff;text-align:center;line-height:50px;font-weight:700;font-size:15px;outline:none;border:none;border-radius:0}.comp-school-ft .btn-buy span{font-size:12px;font-weight:400;margin-right:15px}.comp-school-ft .btn_fav{width:20%;height:100%}.comp-school-ft .btn_fav .icon{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:18px;background-position:50% 8px;background-repeat:no-repeat;text-align:center;padding-top:30px;font-size:12px}.comp-school-ft .btn_fav .icon.faved{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.comp-school-ft .free_listen{width:20%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:18px;background-position:50% 8px;text-align:center;padding-top:30px;font-size:12px;height:100%}.comp-school-ft .comp-footer-member-card-btn{width:60%;height:100%}", ""]);
// Exports
module.exports = exports;
