import React, { useEffect, useState, useCallback, useRef, memo } from "react";
import ErrorBoundary from "../utils/ErrorBoundary";
import { ToastManager } from "../utils/Toast";
import { defaultFunc, showDialog, debounce } from "../utils/helpers";
import { onNavigate } from "../utils/helpers";
import { request, csrfHeaders } from "../utils/request";
import {
  StyledPromotionActivityContainer,
  StyledActivityBanner,
  StyledGiftCenter,
  StyledTopBanner,
  StyledBottomBanner,
  StyledGiftTicketTitleAndCount,
  StyledGiftCenterBox,
  StyledCourseContentBox,
  StyledVoucherCollection,
  StyledCollectContentBox,
  StyledCollectContent,
  StyledActivityRuleBox,
  StyledActivityRuleContent,
  StyledExquisiteGift,
} from "./Activity.styled";

import {
  SuccessRedemptionModal,
  GoFinishExchangeModal,
  UploadScreenShotModal,
  FinishPhotoUploadModal,
} from "../utils/DialogTemplate";

import icon_activity_banner from "promotion/icon_activity_banner1.png";
import icon_acc_frame from "promotion/icon_acc_frame.png";

import icon_top_shade from "promotion/icon_top_shade.png";
import icon_bottom_shade from "promotion/icon_bottom_shade.png";

const ActivityBanner = memo(({ pointsAvailable }) => {
  return (
    <StyledActivityBanner>
      <img src={icon_activity_banner} alt="" />
      <div className="available_coupons_container">
        <div className="available_coupons_box">
          <img src={icon_acc_frame} alt="" />
          <div className="available_coupons_text">
            <div className="text">我的可用兑换券</div>
            <span> x {pointsAvailable}</span>
          </div>
        </div>
      </div>
    </StyledActivityBanner>
  );
});

const TopBannerTitle = memo(({ title }) => {
  return (
    <StyledTopBanner>
      <img src={icon_top_shade} alt="" />
      <span>{title}</span>
    </StyledTopBanner>
  );
});

const BottomBanner = memo(() => {
  return (
    <StyledBottomBanner>
      <img src={icon_bottom_shade} alt="" />
    </StyledBottomBanner>
  );
});

const GiftTicketTitleAndCount = memo(({ title, count, isFlag }) => {
  return (
    <StyledGiftTicketTitleAndCount>
      <div className="gift_title">{title}</div>
      {!isFlag && (
        <div className="ticket_count">
          <div className="ticket_text">需兑换券</div>
          <span> x {count}</span>/个
        </div>
      )}
    </StyledGiftTicketTitleAndCount>
  );
});

const handleExchange = (e, exchangeUrl, pointsAvailable, setSuccess) => {
  console.log(
    "handleExchange exchangeUrl-pointsAvailable: ",
    exchangeUrl,
    pointsAvailable
  );
  const id = e.currentTarget.getAttribute("data-id");
  const class_name = e.currentTarget.getAttribute("data-class-name");
  const point = e.currentTarget.getAttribute("data-point");
  console.log("point: ", point);

  if (pointsAvailable < point) {
    // 领取足够优惠券，才可获取
    GoFinishExchangeModal();
  } else {
    // 免费兑换成功
    const toast = ToastManager.showLoading("兑换中...");
    const data = {
      target_id: id,
      target_type: class_name,
    };
    request({
      url: exchangeUrl,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    })
      .then((resp) => {
        console.warn("resp: ", resp);
        const { success, redirect_url, err_msg } = resp.data;
        if (!success) {
          const attention = err_msg || "兑换失败，请刷新页面重试";
          ToastManager.showToastError(attention);
          return;
        }
        setSuccess(success);
        if (success) {
          SuccessRedemptionModal();
        } else {
          GoFinishExchangeModal();
        }
        if (redirect_url) {
          window.location.href = redirect_url;
        }
        toast.cancel();
      })
      .catch((err) => {
        toast.cancel();
        console.log(err);
      });
  }
};

const CourseContentBox = ({ contents, exchangeUrl, pointsAvailable }) => {
  const [success, setSuccess] = useState(false);
  console.log("contents: ", contents);
  console.log("exchangeUrl: ", exchangeUrl);

  const copy = [...contents];
  const splitedList = [];
  while (copy?.length) {
    const sliced = copy.splice(0, 3);
    splitedList.push({
      values: sliced,
    });
  }
  console.log("splitedList: ", splitedList);
  return (
    <StyledCourseContentBox className="course_content_box">
      {splitedList.map((item, index) => {
        return (
          <ul key={index}>
            {item?.values?.map((value, idx) => (
              <li key={idx}>
                <div onClick={(e) => onNavigate(e, value.buy_url)}>
                  <img src={value?.photo_url} alt="" />
                  <div className="content">
                    <div className="text">
                      {value?.name.length > 12
                        ? `${value?.name.slice(0, 10)}...`
                        : value?.name}
                    </div>
                    {/* {value?.price && (
                      <div className="price">{`￥${value?.price}`}</div>
                    )} */}
                  </div>
                </div>
                <div
                  className={`button ${
                    value.state == 1 ? "already_exchange_button" : ""
                  }`}
                  data-id={value.id}
                  data-class-name={value.class_name}
                  data-point={value.point}
                  onClick={(e) =>
                    value.state == 1
                      ? null
                      : handleExchange(
                          e,
                          exchangeUrl,
                          pointsAvailable,
                          setSuccess
                        )
                  }
                >
                  {value.state == 1 ? "已兑换" : "免费兑换"}
                </div>
              </li>
            ))}
          </ul>
        );
      })}
    </StyledCourseContentBox>
  );
};

const GiftCenterBox = ({ renderContent, renderCourseContentBox, isPeiyou }) => {
  return (
    <StyledGiftCenterBox style={isPeiyou ? { paddingTop: 0 } : {}}>
      {renderContent()}
      {renderCourseContentBox()}
    </StyledGiftCenterBox>
  );
};

// 精美实物礼品
const ExquisiteGift = ({ goods, exchangeUrl, pointsAvailable }) => {
  const [success, setSuccess] = useState(false);
  console.log("goods: ", goods);
  const copy = [...goods];
  const splitedList = [];
  while (copy?.length) {
    const sliced = copy.splice(0, 3);
    splitedList.push({
      values: sliced,
    });
  }
  console.log("精美实物礼品 splitedList: ", splitedList);
  return (
    <StyledExquisiteGift>
      {splitedList.map((item, index) => (
        <ul key={index}>
          {item?.values.map((value, idx) => (
            <li key={idx}>
              <div onClick={(e) => onNavigate(e, value.buy_url)}>
                <div className="img_box">
                  <img src={value?.photo_url} alt="" />
                </div>
                <div className="content">
                  <div className="text">
                    {value?.name.length > 12
                      ? `${value?.name.slice(0, 10)}...`
                      : value?.name}
                  </div>
                  {/* {value?.price && (
                    <div className="price">{`￥${value?.price}`}</div>
                  )} */}
                </div>
              </div>
              <div
                className={`button ${
                  value.state == 1 ? "already_exchange_button" : ""
                }`}
                data-id={value.id}
                data-class-name={value.class_name}
                data-point={value.point}
                onClick={(e) =>
                  value.state == 1
                    ? null
                    : handleExchange(
                        e,
                        exchangeUrl,
                        pointsAvailable,
                        setSuccess
                      )
                }
              >
                <p>{value.state == 1 ? "已兑换" : "免费兑换"}</p>
                <div className="ticket_number">
                  需<span></span> x {value?.point}
                </div>
              </div>
            </li>
          ))}
        </ul>
      ))}
    </StyledExquisiteGift>
  );
};

const GiftCenter = ({ ...props }) => {
  const {
    experiences,
    goods,
    good_courses,
    thinking_courses,
    points_available,
    exchange_url,
  } = props;
  return (
    <StyledGiftCenter>
      <TopBannerTitle title={"礼品中心"} />
      {thinking_courses?.length > 0 && (
        <GiftCenterBox
          renderContent={() => {
            return <GiftTicketTitleAndCount title="培优课特惠权益" count="1" />;
          }}
          renderCourseContentBox={() => {
            return (
              <CourseContentBox
                contents={thinking_courses}
                exchangeUrl={exchange_url}
                pointsAvailable={points_available}
              />
            );
          }}
          isPeiyou={true}
        />
      )}
      {goods?.length > 0 && (
        <GiftCenterBox
          renderContent={() => {
            return (
              <GiftTicketTitleAndCount
                title="精美实物礼品"
                count="9"
                isFlag={true}
              />
            );
          }}
          renderCourseContentBox={() => {
            // 精美实物礼品
            return (
              <ExquisiteGift
                goods={goods}
                exchangeUrl={exchange_url}
                pointsAvailable={points_available}
              />
            );
          }}
        />
      )}
      {good_courses?.length > 0 && (
        <GiftCenterBox
          renderContent={() => {
            return <GiftTicketTitleAndCount title="主题好课" count="1" />;
          }}
          renderCourseContentBox={() => {
            return (
              <CourseContentBox
                contents={good_courses}
                exchangeUrl={exchange_url}
                pointsAvailable={points_available}
              />
            );
          }}
        />
      )}
      {experiences?.length > 0 && (
        <GiftCenterBox
          renderContent={() => {
            return <GiftTicketTitleAndCount title="家长课堂" count="1" />;
          }}
          renderCourseContentBox={() => {
            return (
              <CourseContentBox
                contents={experiences}
                exchangeUrl={exchange_url}
                pointsAvailable={points_available}
              />
            );
          }}
        />
      )}
      <BottomBanner />
    </StyledGiftCenter>
  );
};

const CollectContentBox = ({ renderContent }) => {
  return <StyledCollectContentBox>{renderContent()}</StyledCollectContentBox>;
};

// const CollectContent1 = ({ generateTokenUrl, assignedArticle }) => {
//   const [uploadSuccess, setUplodSuccess] = useState(
//     assignedArticle?.state == 1
//   );
//   const handleExchangeShotBack = () => {
//     UploadScreenShotModal(`${assignedArticle?.token_type}`);
//   };

//   const uploadToQiNiu = (file, token) =>
//     new Promise((resolve, reject) => {
//       const fd = new FormData();
//       fd.append("file", file);
//       fd.append("name", "");
//       fd.append("token", token);
//       const xhr = new XMLHttpRequest();
//       xhr.open("POST", "https://upload.qiniup.com", true);
//       xhr.onreadystatechange = () => {
//         if (xhr.readyState == 4) {
//           if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
//             const obj = JSON.parse(xhr.responseText);
//             console.log("obj: ", obj);
//             if (obj?.url) {
//               FinishPhotoUploadModal();
//               setUplodSuccess(true);
//             }
//             resolve(obj);
//           } else {
//             reject(JSON.parse(xhr.responseText).error);
//           }
//         }
//       };
//       xhr.onerror = (e) => {
//         console.error(e);
//         reject(e);
//       };

//       xhr.send(fd);
//     });

//   const uploadFile = async (file) => {
//     const res = await request({
//       url: generateTokenUrl,
//       method: "GET",
//       headers: csrfHeaders,
//       params: {
//         type: `${assignedArticle?.token_type}`,
//       },
//     });
//     const token = res.data.token;
//     console.log("token: ", token);
//     const photo = await uploadToQiNiu(file, token);
//     console.log(photo);
//     return { id: photo.id };
//   };

//   const inputOnChangeArticle = async (e) => {
//     const file = e.target.files[0];
//     console.log("inputOnChangeArticle file: ", file);
//     uploadFile(file);
//   };

//   return (
//     <StyledCollectContent>
//       {/* <div className="content_text_box">
//         <div
//           className="finish_text"
//           dangerouslySetInnerHTML={{
//             __html: `完成指定文章“在看”+“评论”，并截图回传`,
//           }}
//         ></div>
//         <div className="upload_text">
//           审核通过后可得兑换券<span>x1</span>，仅限参与1次
//         </div>
//       </div> */}
//       {/* <div
//         className="buttons"
//         style={uploadSuccess ? { justifyContent: "center" } : {}}
//       >
//         <div
//           className={`finish_button ${
//             uploadSuccess ? "already_finish_button" : ""
//           }`}
//           onClick={(e) =>
//             onNavigate(e, "https://mp.weixin.qq.com/s/9gD0lYonMA7FmRW3INYtJg")
//           }
//         >
//           {uploadSuccess ? "已完成" : "去完成"}
//         </div>
//         {!uploadSuccess && (
//           <div
//             className="upload_button"
//             as="label"
//             htmlFor="answer_photos"
//             id="file_label"
//             onClick={(e) => handleExchangeShotBack(e)}
//           >
//             截图回传
//           </div>
//         )} */}
//         {/* <input
//           type="file"
//           id="answer_photos_article"
//           accept="image/jpeg,image/jpg,image/png"
//           style={{ display: "none" }}
//           onChange={(e) => inputOnChangeArticle(e)}
//           multiple
//         />
//       </div> */}
//     </StyledCollectContent>
//   );
// };
const CollectContent2 = ({ generateTokenUrl, sharePoster }) => {
  const [uploadSuccess, setUplodSuccess] = useState(sharePoster?.state == 1);
  const handleExchangeShotBack = () => {
    UploadScreenShotModal(`${sharePoster?.token_type}`);
  };

  const uploadToQiNiu = (file, token) =>
    new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("name", "");
      fd.append("token", token);
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://upload.qiniup.com", true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            const obj = JSON.parse(xhr.responseText);
            console.log("obj==: ", obj);
            if (obj?.url) {
              FinishPhotoUploadModal();
              setUplodSuccess(true);
            }
            resolve(obj);
          } else {
            reject(JSON.parse(xhr.responseText).error);
          }
        }
      };
      xhr.onerror = (e) => {
        console.error(e);
        reject(e);
      };

      xhr.send(fd);
    });

  const uploadFile = async (file) => {
    const res = await request({
      url: generateTokenUrl,
      method: "GET",
      headers: csrfHeaders,
      params: {
        type: `${sharePoster?.token_type}`,
      },
    });
    const token = res.data.token;
    console.log("token: ", token);
    const photo = await uploadToQiNiu(file, token);
    console.log(photo);
    return { id: photo.id };
  };

  const inputOnChangePoster = async (e) => {
    const file = e.target.files[0];
    console.log("inputOnChangePoster file: ", file);

    uploadFile(file);
  };
  return (
    <StyledCollectContent>
      <div className="content_text_box">
        <div
          className="finish_text"
          dangerouslySetInnerHTML={{
            __html: `分享“<span>数学培优课海报</span>”+“文案”到 朋友圈集30个赞，并截图回传`,
          }}
        ></div>
        <div className="upload_text">
          审核通过后可得兑换券
          <span>x1</span>
          ，仅限参与1次
        </div>
      </div>
      <div
        className="buttons"
        style={uploadSuccess ? { justifyContent: "center" } : {}}
      >
        <div
          className={`finish_button ${
            uploadSuccess ? "already_finish_button" : ""
          }`}
          onClick={(e) =>
            onNavigate(e, "/posters?study_package_activity_id=364")
          }
        >
          {uploadSuccess ? "已完成" : "去完成"}
        </div>
        {!uploadSuccess && (
          <div
            className="upload_button"
            as="label"
            htmlFor="answer_photos"
            id="file_label"
            onClick={(e) => handleExchangeShotBack(e)}
          >
            截图回传
          </div>
        )}
        <input
          type="file"
          id="answer_photos_poster"
          accept="image/jpeg,image/jpg,image/png"
          style={{
            display: "none",
          }}
          onChange={(e) => inputOnChangePoster(e)}
          multiple
        />
      </div>
    </StyledCollectContent>
  );
};
const CollectContent3 = () => {
  return (
    <StyledCollectContent>
      <div className="content_text_box">
        <div
          className="finish_text"
          dangerouslySetInnerHTML={{
            __html: `邀请<span>新用户</span>报名“数学培优系列 (1元学一周)”课程`,
          }}
        ></div>
        <div className="upload_text">
          审核通过后可得兑换券<span>x1</span>，不限参与次数
        </div>
      </div>
      <div className="buttons" style={{ justifyContent: "center" }}>
        <div
          className="finish_button"
          style={{ marginBottom: 0 }}
          onClick={(e) =>
            onNavigate(e, "/posters?study_package_activity_id=364")
          }
        >
          去完成
        </div>
      </div>
    </StyledCollectContent>
  );
};
const CollectContent4 = () => {
  return (
    <StyledCollectContent>
      <div className="content_text_box">
        <div
          className="finish_text"
          dangerouslySetInnerHTML={{
            __html: `邀请<span>新老用户</span>报名任意正价课程`,
          }}
        ></div>
        <div className="upload_text">
          审核通过后可得兑换券<span>x1</span>，不限参与次数
        </div>
      </div>
      <div className="buttons" style={{ justifyContent: "center" }}>
        <div
          className="finish_button"
          style={{ marginBottom: 0 }}
          onClick={(e) => onNavigate(e, "/posters")}
        >
          去完成
        </div>
      </div>
    </StyledCollectContent>
  );
};

const VoucherCollection = ({ ...props }) => {
  const { generate_token_url, promotion_center } = props;
  return (
    <StyledVoucherCollection id="exchange_collection">
      <TopBannerTitle title={"兑换券获取"} />
      {/* <CollectContentBox
        renderContent={() => {
          return (
            <CollectContent1
              generateTokenUrl={generate_token_url}
              assignedArticle={promotion_center?.assigned_article}
            />
          );
        }}
      /> */}
      <CollectContentBox
        renderContent={() => {
          return (
            <CollectContent2
              generateTokenUrl={generate_token_url}
              sharePoster={promotion_center?.share_poster}
            />
          );
        }}
      />
      <CollectContentBox
        renderContent={() => {
          return <CollectContent3 />;
        }}
      />
      <CollectContentBox
        renderContent={() => {
          return <CollectContent4 />;
        }}
      />
      <BottomBanner />
    </StyledVoucherCollection>
  );
};

const ActivityRuleContent = memo(() => {
  return (
    <StyledActivityRuleContent>
      <ul>
        {/* <li>
          <span>1</span>
          <div className="activity_rule_text">
            <div className="weight">活动有效期：</div>
            <p>2020.11.3~2020.11.12</p>
          </div>
        </li> */}
        <li>
          <span>1</span>
          <div className="activity_rule_text">
            <div className="weight">新用户：</div>
            <p>未购买过心芽课程的用户</p>
          </div>
        </li>
        <li>
          <span>2</span>
          <div className="activity_rule_text">
            <p>礼品数量有限，领完即止</p>
          </div>
        </li>
        <li>
          <span>3</span>
          <div className="activity_rule_text">
            <p>活动不可作弊，一旦发现将取消参与活动资格</p>
          </div>
        </li>
        <li>
          <span>4</span>
          <div className="activity_rule_text">
            <p>实物奖品将在领取后2周内为您寄出</p>
          </div>
        </li>
        <li>
          <span>5</span>
          <div className="activity_rule_text">
            <p>活动最终解释权归上海芽圃教育科技有限公司所有</p>
          </div>
        </li>
      </ul>
    </StyledActivityRuleContent>
  );
});

const ActivityRuleBox = () => {
  return (
    <StyledActivityRuleBox>
      <TopBannerTitle title={"活动规则"} />
      <ActivityRuleContent />
      <BottomBanner />
    </StyledActivityRuleBox>
  );
};

export default ({ ...props }) => {
  console.log("PromotionActivity_1111-2020-props: ", props);
  return (
    <ErrorBoundary>
      <StyledPromotionActivityContainer>
        <ActivityBanner pointsAvailable={props?.points_available} />
        <GiftCenter {...props} />
        <VoucherCollection {...props} />
        <ActivityRuleBox />
      </StyledPromotionActivityContainer>
    </ErrorBoundary>
  );
};
