import React from "react";
import PropTypes from "prop-types";
import { debounce, print, tracking } from "../utils/helpers";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";

export default class QuizRecorder extends React.Component {
  constructor(props) {
    super(props);
    this.AudioRecorderManager = this.createAudioRecorderManager(
      this.props.isRemoveRecord,
      this.props.quizId,
      this.props.babyId,
      this.props.isLastQuiz,
      this.props.authToken,
      this.props.ravenMsg
    );
    this.AudioRecorderManagerHandlers = this.AudioRecorderManager.initHandlers();
    this.state = {
      startRecordBar: true,
    };
  }

  componentDidMount() {
    console.log("start recording");
    this.handleAudioRecorderStart();
  }

  createAudioRecorderManager(
    isRemoveRecord,
    quizId,
    babyId,
    isLastQuiz,
    authToken,
    ravenMsg
  ) {
    const delegate = function (client, delegation) {
      if (!("buttonPressed" in delegation)) {
        throw new Error("状态必须实现buttonPressed方法");
      }
      return {
        buttonPressed: function () {
          return delegation.buttonPressed.apply(client, arguments);
        },
      };
    };

    const handleAudioRecordId = (function () {
      let localAudioRecordIds = [];
      return {
        setId: function (localId) {
          localAudioRecordIds.length = 0;
          localAudioRecordIds.push(localId);
          print("localAudioRecordIds", localAudioRecordIds);
        },
        getId: function () {
          return localAudioRecordIds.splice(0, 1)[0];
        },
        getIdForPlayVoice: function () {
          return localAudioRecordIds[0];
        },
      };
    })();

    // //结束录音，或者微信录音一分钟后自动结束
    // const onEndRecord = (function () {
    //   return {
    //     onVoiceRecordEnd: (onAutoStopRecord) => {
    //       // 录音时间超过一分钟没有停止的时候会执行 complete 回调
    //       wx.onVoiceRecordEnd({
    //         complete: res => {
    //           handleAudioRecordId.setId(res.localId)
    //           onAutoStopRecord()
    //         }
    //       })
    //     },
    //     // 点击停止录音
    //     onStopRecordEnd: (res) => {
    //       handleAudioRecordId.setId(res.localId)
    //     }
    //   }
    // })()

    const AudioRecorderFSM = {
      // 开始录音
      startRecord: {
        buttonPressed: function () {
          print("startRecord buttonPressed", "next state is stopRecordState");
          let onRecordStart = Array.prototype.shift.call(arguments);
          let onAutoStopRecord = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            print("afterStopRecord", this);
            print("this.stopRecordState", this.stopRecordState);
            this.currentState = this.stopRecordState;
          };
          // onEndRecord.onVoiceRecordEnd(stateFixOnAutoStopRecord)

          XinYaNativeBridge.startRecord(
            { quizId: quizId, babyId: babyId, authToken: authToken },
            stateFixOnAutoStopRecord
          );

          onRecordStart();
          this.currentState = this.stopRecordState;

          if (ravenMsg) {
            let msg = ravenMsg;
            msg["quiz_id"] = `${quizId}`;
            let event = `startRecord`;
            tracking(msg, event);
          }

          // print(sessionStorage.getItem('auth') != 2)
          // if (sessionStorage.getItem('auth') != 2) {
          //   wx.startRecord({
          //     success: () => {
          //       print('start success')
          //       onRecordStart()
          //       this.currentState = this.stopRecordState
          //     },
          //     fail: res => {
          //       print('录音请求失败')
          //     },
          //     cancel: res => {
          //       sessionStorage.removeItem('auth')
          //       sessionStorage.setItem('auth', 2)
          //       alert('请授权录音，否则无法录音')
          //     },
          //   })
          // } else {
          //   let dialogOptions = {
          //     text: '请刷新页面，重新授权录音',
          //     mainLabel: "确定",
          //     mainCallback: () => {
          //       sessionStorage.removeItem('auth')
          //       window.location.reload(true)
          //     },
          //   }
          //   showDialog.call(this, dialogOptions)
          // }

          // if (isRemoveRecord) {
          //   onRecordStart()
          //   this.currentState = this.stopRecordState
          // }
        },
      },
      // 停止录音
      stopRecord: {
        buttonPressed: function () {
          print(
            "stopRecord buttonPressed",
            "next state is playVoice or completeRecord or reStartRecord"
          );
          let onRecordStop = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            this.currentState = this.stopRecordGroupsState;
            print("afterStopRecord", this);
            print("afterStopRecord.currentState", this.currentState);
            onRecordStop();
          };
          XinYaNativeBridge.stopRecord(stateFixOnAutoStopRecord);
          // wx.stopRecord({
          //   success: function (res) {
          //     onEndRecord.onStopRecordEnd(res)
          //     if (onRecordStop) {
          //       onRecordStop()
          //     }
          //     print('wx.stopRecord success')
          //     // this.currentState = this.stopRecordGroupsState
          //   }
          // })
          // if (isRemoveRecord) {
          //   onRecordStop()
          // }
          // onEndRecord.onStopRecordEnd(res)
          // onRecordStop()
          // this.currentState = this.stopRecordGroupsState

          if (ravenMsg) {
            let msg = ravenMsg;
            msg["quiz_id"] = `${quizId}`;
            let event = "stopRecord";
            tracking(msg, event);
          }
        },
      },
      // 播放录音
      playVoice: {
        buttonPressed: function () {
          print(
            "playVoice buttonPressed",
            "next state is playVoice or completeRecord or reStartRecord"
          );
          let onRecordPlay = Array.prototype.shift.call(arguments);
          let onAutoPlayEnd = Array.prototype.shift.call(arguments);
          XinYaNativeBridge.playVoice(onAutoPlayEnd);
          onRecordPlay();
          // let recordId = handleAudioRecordId.getIdForPlayVoice()
          // print(recordId)
          // if (recordId) {
          //   wx.onVoicePlayEnd({
          //     success: function (res) {
          //       var localId = res.localId; // 返回音频的本地ID
          //       if (onAutoPlayEnd) {
          //         onAutoPlayEnd()
          //       }
          //     }
          //   })
          //   wx.playVoice({
          //     localId: recordId
          //   })
          //   if (onRecordPlay) {
          //     onRecordPlay()
          //   }
          //   print('wx.playVoice success recordId:', recordId)
          //   // this.currentState = this.stopRecordGroupsState
          // } else {
          //   print('no local id')
          // }
          // if (isRemoveRecord) {
          //   onRecordPlay()
          // }
          // // if (onRecordPlay) {
          // //   onRecordPlay()
          // // }
          this.currentState = this.stopRecordGroupsState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "playVoice";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
      // 结束录音
      completeRecord: {
        buttonPressed: function () {
          print("completeRecord buttonPressed");
          let onRecordComplete = Array.prototype.shift.call(arguments);
          // onRecordComplete()
          XinYaNativeBridge.completeRecord(
            { isLastQuiz: isLastQuiz },
            onRecordComplete
          );
          // if (onRecordComplete) {
          //   onRecordComplete()
          // }
          this.currentState = this.stopRecordGroupsState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "completeRecord";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
      reStartRecord: {
        buttonPressed: function () {
          print("reStartRecord buttonPressed", "next state is stopRecordState");
          let onRecordStart = Array.prototype.shift.call(arguments);
          let onAutoStopRecord = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            onAutoStopRecord();
            this.currentState = this.stopRecordState;
          };
          // onEndRecord.onVoiceRecordEnd(stateFixOnAutoStopRecord)

          XinYaNativeBridge.reStartRecord(stateFixOnAutoStopRecord);
          onRecordStart();
          this.currentState = this.stopRecordState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "reStartRecord";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
    };

    let AudioRecorder = function () {
      let playVoiceState = delegate(this, AudioRecorderFSM.playVoice);
      let completeRecordState = delegate(this, AudioRecorderFSM.completeRecord);
      let startRecordState = delegate(this, AudioRecorderFSM.startRecord);
      let reStartRecordState = delegate(this, AudioRecorderFSM.reStartRecord);

      this.startRecordState = startRecordState;
      this.stopRecordState = delegate(this, AudioRecorderFSM.stopRecord);
      this.stopRecordGroupsState = {
        playVoiceState: playVoiceState,
        completeRecordState: completeRecordState,
        reStartRecord: reStartRecordState,
      };

      this.currentState = this.startRecordState; // 初始化状态--开始录音
    };

    AudioRecorder.prototype.getAudioRecordId = function () {
      return handleAudioRecordId.getId();
    };

    AudioRecorder.prototype.initHandlers = function () {
      let handleAudioRecorderStart = (onRecordStart, onAutoStopRecord) => {
        print("handleAudioRecorderStart");
        this.currentState.buttonPressed.call(
          null,
          onRecordStart,
          onAutoStopRecord
        );
      };
      let handleAudioRecorderStop = (onRecordStop) => {
        print("handleAudioRecorderStop");
        print("handleAudioRecorderStop this", this);
        print("handleAudioRecorderStop this.currentState", this.currentState);
        try {
          console.log(this.currentState);
          this.currentState.buttonPressed.call(null, onRecordStop);
        } catch (e) {
          console.log(this.currentState, typeof this.currentState);
          console.log(e);
          Raven.captureException(e);
        }
      };
      let handleAudioRecorderPlay = (onRecordPlay, onAutoPlayEnd) => {
        print("handleAudioRecorderPlay");
        this.currentState.playVoiceState.buttonPressed.call(
          null,
          onRecordPlay,
          onAutoPlayEnd
        );
      };
      let handleAudioRecorderComplete = (onRecordComplete) => {
        print("handleAudioRecorderComplete");
        this.currentState.completeRecordState.buttonPressed.call(
          null,
          onRecordComplete
        );
      };
      let handleAudioRecorderReStart = (onRecordReStart, onAutoStopRecord) => {
        print("handleAudioRecorderReStart");
        this.currentState.reStartRecord.buttonPressed.call(
          null,
          onRecordReStart,
          onAutoStopRecord
        );
      };
      return {
        start: handleAudioRecorderStart,
        stop: handleAudioRecorderStop,
        play: handleAudioRecorderPlay,
        complete: handleAudioRecorderComplete,
        reStart: handleAudioRecorderReStart,
      };
    };

    return new AudioRecorder();
  }

  handleAudioRecorderStart = () => {
    const onRecordStart = () => {
      this.setState(() => ({
        startRecordBar: false,
        onRecordingBar: true,
      }));
      this.props.onRecordStartAction();
    };
    const onAutoStopRecord = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      // this.props.onRecordCompleteAction(this.AudioRecorderManager.getAudioRecordId)
    };
    this.AudioRecorderManagerHandlers.start(onRecordStart, onAutoStopRecord);
    // if (this.props.ravenMsg) {
    //   let msg = this.props.ravenMsg
    //   print(msg)
    //   let event = 'handleAudioRecorderStart'
    //   tracking(msg, event)
    // }
  };

  handleAudioRecorderStop = () => {
    const onRecordStop = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      this.props.onRecordStopAction();
    };
    this.AudioRecorderManagerHandlers.stop(onRecordStop);
    // if (this.props.ravenMsg) {
    //   let msg = this.props.ravenMsg
    //   let event = 'handleAudioRecorderStop'
    //   tracking(msg, event)
    // }
  };

  handleAudioRecorderPlay = () => {
    if (this.state.recordIsPlaying) {
      return;
    }
    this.setState({
      recordIsPlaying: true,
    });
    const onRecordPlay = () => {};
    const onAutoPlayEnd = () => {
      this.setState({
        recordIsPlaying: false,
      });
    };
    this.AudioRecorderManagerHandlers.play(onRecordPlay, onAutoPlayEnd);
    // if (this.props.ravenMsg) {
    //   let msg = this.props.ravenMsg
    //   let event = 'handleAudioRecorderPlay'
    //   tracking(msg, event)
    // }
  };

  handleAudioRecorderComplete = () => {
    const onRecordComplete = () => {
      this.setState(() => ({
        endRecordBar: false,
        recordIsPlaying: false,
      }));
      this.props.onRecordCompleteAction(
        this.AudioRecorderManager.getAudioRecordId
      );
    };
    this.AudioRecorderManagerHandlers.complete(onRecordComplete);
    // if (this.props.ravenMsg) {
    //   let msg = this.props.ravenMsg
    //   let event = 'handleAudioRecorderComplete'
    //   tracking(msg, event)
    // }
  };

  handleAudioRecorderReStart = () => {
    this.setState(() => ({
      onRecordingBar: true,
      endRecordBar: false,
      recordIsPlaying: false,
    }));
    const onRecordReStart = () => {
      this.props.onRecordReStartAction();
    };
    const onAutoStopRecord = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      // this.props.onRecordCompleteAction(this.AudioRecorderManager.getAudioRecordId)
    };
    this.AudioRecorderManagerHandlers.reStart(
      onRecordReStart,
      onAutoStopRecord
    );
    // if (this.props.ravenMsg) {
    //   let msg = this.props.ravenMsg
    //   let event = 'handleAudioRecorderReStart'
    //   tracking(msg, event)
    // }
  };

  render() {
    const {
      startRecordBar,
      onRecordingBar,
      endRecordBar,
      recordIsPlaying,
    } = this.state;
    const { isRemoveRecord } = this.props;
    return (
      <div className="bar_for_recorder">
        {startRecordBar && (
          <div
            className="start_record"
            onClick={debounce(this.handleAudioRecorderStart, 1000)}
          >
            <span>开始</span>
          </div>
        )}
        {onRecordingBar && (
          <div
            className={`on_recording ${
              isRemoveRecord ? "no_record_skill" : ""
            }`}
            onClick={debounce(this.handleAudioRecorderStop, 1000)}
          >
            <span>
              {isRemoveRecord ? "没有录音功能，点击结束" : "录音中，点击结束"}
            </span>
          </div>
        )}
        {endRecordBar && (
          <div
            className="end_recording"
            style={{ justifyContent: isRemoveRecord ? "center" : "" }}
          >
            {!isRemoveRecord && (
              <div
                className={
                  recordIsPlaying
                    ? "play_record_active common_record_style"
                    : "play_record  common_record_style"
                }
                onClick={debounce(this.handleAudioRecorderPlay, 1000)}
              >
                <span>播放</span>
              </div>
            )}

            {!isRemoveRecord && (
              <div
                className="re_record common_record_style"
                onClick={debounce(this.handleAudioRecorderReStart, 1000)}
              >
                <span>重读</span>
              </div>
            )}
            <div
              className="complete_record common_record_style"
              onClick={debounce(this.handleAudioRecorderComplete, 1000)}
            >
              <span>完成</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
QuizRecorder.propTypes = {
  // startRecordBar: PropTypes.bool.isRequired,
  // onRecordingBar: PropTypes.bool.isRequired,
  // endRecordBar: PropTypes.bool.isRequired,
  onRecordStartAction: PropTypes.func.isRequired,
  onRecordStopAction: PropTypes.func.isRequired,
  onRecordCompleteAction: PropTypes.func.isRequired,
  onRecordReStartAction: PropTypes.func.isRequired,
  isRemoveRecord: PropTypes.bool.isRequired,
};
