export const mediaLessonsArrays = [
  {
    tab: 0,
    title: "数与计算思维",
    mediaLessonsArray: [
      {
        id: 1,
        media_lesson_name: "排队问题",
        schools: [
          { id: 1, name: "盛大花园小学2016、2015年考过" },
          { id: 2, name: "浦外附小2015年考过" },
          { id: 3, name: "星河湾双语学校2014年考过" },
          { id: 4, name: "宏星小学2011年考过" },
        ],
      },
      {
        id: 2,
        media_lesson_name: "倍数与多方均分",
        schools: [
          { id: 1, name: "盛大花园2017年考过" },
          { id: 2, name: "同济大学实验学校2017年考过" },
          { id: 3, name: "培佳双语学校2016年考过" },
          { id: 4, name: "金洲小学2016年考过" },
          { id: 5, name: "爱菊小学2015、2013、2012年考过" },
          { id: 6, name: "阳浦小学2014年考过" },
        ],
      },
      {
        id: 3,
        media_lesson_name: "一半问题",
        schools: [
          { id: 1, name: "浦外附小2016年考过" },
          { id: 2, name: "七宝外国语小学2011年考过" },
          { id: 3, name: "宏星小学2011年考过" },
        ],
      },
      {
        id: 4,
        media_lesson_name: "两数平分",
        schools: [
          { id: 1, name: "福山正达外国语小学2017年考过" },
          { id: 2, name: "阳浦小学2014年考过" },
        ],
      },
      {
        id: 5,
        media_lesson_name: "间隔次数问题",
        schools: [{ id: 1, name: "盛大花园小学2016年考过" }],
      },
      {
        id: 6,
        media_lesson_name: "单双数的加减规律",
        schools: [{ id: 1, name: "上实2016年考过" }],
      },
      {
        id: 7,
        media_lesson_name: "单双数的复位问题",
        schools: [{ id: 1, name: "世界外国语小学2012年考过" }],
      },
      {
        id: 8,
        media_lesson_name: "符号代入",
        schools: [
          { id: 1, name: "七宝外国语小学2016年考过" },
          { id: 2, name: "同济大学实验学校2015年考过" },
          { id: 3, name: "逸夫小学2011年考过" },
        ],
      },
      {
        id: 9,
        media_lesson_name: "数独问题",
        schools: [{ id: 1, name: "上外静小2017年考过" }],
      },
      {
        id: 10,
        media_lesson_name: "火柴棒的等式变化",
        schools: [{ id: 1, name: "宏星小学2016年考过" }],
      },
      {
        id: 11,
        media_lesson_name: "数字的规律",
        schools: [
          { id: 1, name: "平和双语学校2017、2016年考过" },
          { id: 2, name: "爱菊小学2017年考过" },
          { id: 3, name: "新世界实验小学2016年考过" },
          { id: 4, name: "协和双语学校2015年考过" },
        ],
      },
    ],
  },
  {
    tab: 1,
    title: "图形与空间思维",
    mediaLessonsArray: [
      {
        id: 1,
        media_lesson_name: "数平面图形",
        schools: [
          { id: 1, name: "上海中学（国际部）2017年考过" },
          { id: 2, name: "七宝外国语2017年考过" },
          { id: 3, name: "培佳双语2017年考过" },
          { id: 4, name: "盛大花园小学（境内部）2016年考过" },
          { id: 5, name: "东方阶梯双语学校2013年考过" },
        ],
      },
      {
        id: 2,
        media_lesson_name: "图形旋转",
        schools: [
          { id: 1, name: "世界外国语小学（境内部）2015年考过" },
          { id: 2, name: "上外附小2014年考过" },
        ],
      },
      {
        id: 3,
        media_lesson_name: "数三维积木",
        schools: [
          { id: 1, name: "民办打一2017、2016、2015年考过" },
          { id: 2, name: "盛大花园小学2016年考过" },
          { id: 3, name: "宏星小学2016年考过" },
          { id: 4, name: "七宝外国语小学2015年考过" },
          { id: 5, name: "协和双语学校2015年考过" },
          { id: 6, name: "浦外附小2015年考过" },
        ],
      },
      {
        id: 4,
        media_lesson_name: "图形分割和拼图",
        schools: [
          { id: 1, name: "同济大学实验学校2017、2016年考过" },
          { id: 2, name: "协和尚音2017年考过" },
          { id: 3, name: "民办四中心2017年考过" },
          { id: 4, name: "上实2016年考过" },
          { id: 5, name: "七宝外国语小学2015年考过" },
        ],
      },
      {
        id: 5,
        media_lesson_name: "对称关系和镜面成像",
        schools: [
          { id: 1, name: "星河湾双语学校2015年考过" },
          { id: 2, name: "协和双语学校2015年考过" },
          { id: 3, name: "平和双语学校（国内部）2012年考过" },
        ],
      },
      {
        id: 6,
        media_lesson_name: "平面图形的折叠",
        schools: [{ id: 1, name: "当前还没有学校考过" }],
      },
      {
        id: 7,
        media_lesson_name: "七巧板",
        schools: [
          { id: 1, name: "平和双语学校2017年考过" },
          { id: 2, name: "上外附小2017年考过" },
          { id: 3, name: "上外附属双语学校2016年考过" },
          { id: 4, name: "民办打一2016年考过" },
          { id: 5, name: "扬波外国语小学2016年考过" },
          { id: 6, name: "童园实验小学2016年考过" },
          { id: 7, name: "复旦万科实验学校（B班）2016年考过" },
          { id: 8, name: "协和双语学校2015年考过" },
          { id: 9, name: "盛大花园小学2015年考过" },
        ],
      },
      {
        id: 8,
        media_lesson_name: "火柴棒的图形变化",
        schools: [
          { id: 1, name: "培佳双语2017年考过" },
          { id: 2, name: "民办新世纪小学2016年考过" },
          { id: 3, name: "盛大花园小学（境内部）2015、2014年考过" },
          { id: 4, name: "平和双语学校2014、2012年考过" },
        ],
      },
      {
        id: 9,
        media_lesson_name: "立体几何体的展开",
        schools: [
          { id: 1, name: "浦外附小2016年考过" },
          { id: 2, name: "逸夫小学2013年考过" },
        ],
      },
      {
        id: 10,
        media_lesson_name: "立体物的平面视图",
        schools: [
          { id: 1, name: "民办打一2017年考过" },
          { id: 2, name: "浦外附小2015年考过" },
          { id: 3, name: "世界外国语小学（境内部）2014年考过" },
        ],
      },
      {
        id: 11,
        media_lesson_name: "重复类型的简单规律",
        schools: [
          { id: 1, name: "平和双语2017年考过" },
          { id: 2, name: "协和双语2017年考过" },
          { id: 3, name: "同济大学实验学校2017年考过" },
          { id: 4, name: "阳浦小学2017年考过" },
          { id: 5, name: "上实2016年考过" },
          { id: 6, name: "星河湾双语学校2016年考过" },
          { id: 7, name: "尚德实验学校（国际部）2016年考过" },
          { id: 8, name: "培佳双语学校2015年考过" },
        ],
      },
    ],
  },
  {
    tab: 2,
    title: "常识与思维逻辑",
    mediaLessonsArray: [
      {
        id: 1,
        media_lesson_name: "认识时钟、整点、半点",
        schools: [
          { id: 1, name: "星河湾双语学校2016年考过" },
          { id: 2, name: "上外附小2015年考过" },
        ],
      },
      {
        id: 2,
        media_lesson_name: "认识几点几分",
        schools: [{ id: 1, name: "宏星小学2014年考过" }],
      },
      {
        id: 3,
        media_lesson_name: "计算日期和时间",
        schools: [{ id: 1, name: "福山正达外国语小学2016年考过" }],
      },
      {
        id: 4,
        media_lesson_name: "自由分类",
        schools: [
          { id: 1, name: "金洲小学2017年考过" },
          { id: 2, name: "上外附属双语学校2016年考过" },
          { id: 3, name: "民办打一2015年考过" },
          { id: 4, name: "浦外附小2015年考过" },
        ],
      },
      {
        id: 5,
        media_lesson_name: "自然测量",
        schools: [
          { id: 1, name: "世外小学2017年考过" },
          { id: 2, name: "浦外附小2017年考过" },
          { id: 3, name: "上实2013年考过" },
          { id: 4, name: "逸夫小学2012年考过" },
        ],
      },
      {
        id: 6,
        media_lesson_name: "等量代换",
        schools: [
          { id: 1, name: "盛大花园小学2017年考过" },
          { id: 2, name: "七宝外国语小学2017年考过" },
          { id: 3, name: "上外附小2017年考过" },
          { id: 4, name: "同济大学实验学校2017年考过" },
          { id: 5, name: "金洲小学2016年考过" },
          { id: 6, name: "民办永昌学校2016年考过" },
          { id: 7, name: "平和双语学校（国内部）2015年考过" },
          { id: 8, name: "协和双语学校2015年考过" },
        ],
      },
      {
        id: 7,
        media_lesson_name: "传递性关系推理",
        schools: [{ id: 1, name: "福山正达外国语小学2017年考过" }],
      },
      {
        id: 8,
        media_lesson_name: "理解示意图",
        schools: [{ id: 1, name: "七宝外国语小学2017年考过" }],
      },
      {
        id: 9,
        media_lesson_name: "第三方视角的二维序列",
        schools: [{ id: 1, name: "当前还没有学校考过" }],
      },
    ],
  },
];
