// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/schooser/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body.controller-schooser.schooser-collect_show{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:50px}.component_collect_show{position:absolute;top:10px;bottom:10px;left:9px;right:9px;background:#fff}.component_collect_show .text{padding:65px 20px 0}.component_collect_show .school_qrcode{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.component_collect_show .school_qrcode img{width:180px}.component_collect_show .school_qrcode p{font-size:12px;text-align:center;color:#aaa}", ""]);
// Exports
module.exports = exports;
