import React from "react";
import ReactDOM from "react-dom";
import "./ConfirmDialog.scss";

const ConfirmDialogSection = ({ ...props }) => {
  const { children, buttons } = props;
  return (
    <div className="comp_confirm_dialog">
      <div className="content_container">
        <div className="content">
          <p>{children}</p>
        </div>

        <div className="button_list">
          <a href="javascript: void(0)" onClick={buttons[0].onClick}>
            {buttons[0].label}
          </a>
          <a href="javascript: void(0)" onClick={buttons[1].onClick}>
            {buttons[1].label}
          </a>
        </div>
      </div>
    </div>
  );
};

class ConfirmDialog extends React.Component {
  render() {
    console.log(this.props);
    return <ConfirmDialogSection {...this.props} />;
  }
}

class ConfirmDialogProxy {
  constructor(component) {
    this.node = document.createElement("div");
    document.body.appendChild(this.node);
    ReactDOM.render(component, this.node);
  }
  close() {
    ReactDOM.unmountComponentAtNode(this.node);
  }
}

const ConfirmDialogManager = {
  open(component) {
    const proxy = new ConfirmDialogProxy(component);
    return proxy;
  },

  showMsg(options) {
    let dialog;
    let { msg, btnLabel = "确认", onClick = () => dialog.close() } = options;
    const buttons = [
      {
        label: btnLabel,
        onClick: () => {
          if (typeof onClick == "function") {
            onClick();
          }
        },
      },
    ];

    return (dialog = ConfirmDialogManager.open(
      <ConfirmDialog buttons={buttons}>{msg}</ConfirmDialog>
    ));
  },
};

export { ConfirmDialog, ConfirmDialogManager };
