/* eslint-disable */
import React, { PureComponent } from "react";

import PropTypes from "prop-types";

import Uri from "jsuri";
import UserAgent from "../utils/UserAgent";
import ReactMarkdown from "react-markdown";
import ProblemFold from "../utils/ProblemFold";
import ListContainer from "../utils/ListContainer/ListContainer.jsx";
// import PoetryBabyAudio from "../study_packages/poetries/v2/components/PoetryBabyAudio.jsx";
import $ from "jquery";
import {
  convertURLToJSON,
  print,
  onNavigate,
  debounce,
} from "../utils/helpers";
import {
  DateMinus,
  chineseMonthDayDate,
  formatHourMinute,
  format as formatTime,
} from "../utils/formatAudioTime";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import {
  DialogCourseBuy,
  DialogCourseBuyManager,
} from "../utils/DialogCourseBuy";
import { Dialog, DialogManager } from "../utils/Dialog";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { DoubleElevenModal } from "../utils/DialogTemplate";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import OldCourseList from "../utils/course_sign_page/OldCourseList";
import CountDownTime from "../utils/CountDown";
import RightFloatIcons from "./RightFloatIcons.jsx";
import { onGroupBuy, onDirectBuy } from "./ShoppingBar";
import { WechatFollow } from "./WechatFollow";
import { GA, GA_new } from "../utils/Env";
import {
  calcActivityLowestPrice,
  calcDiscountPercentPrice,
  filterSubActivityOweMediaCount,
  courseDiscountDialog,
  findExperienceCourse,
  showQuestionnaireActivityModal,
} from "./StudyPackageActivityTemplate.jsx";
import MinCommentLists from "../utils/comment_lists/MinCommentLists";

import CommentTags from "../utils/comment_tags/CommentTags";

import CourseCoupons from "./CourseCoupons";

import banner from "media_lesson_activity/banner.jpg";
import icon_group_bg from "icon_group.png";
import questionnaire_banner from "study_package_activity/banner.png";

import "./StudyPackageActivity.scss";
import "../utils/ReactMarkDown.scss";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import ChoosePhase from "./ChoosePhase";
import TrialChoose from "./TrialChoose";
import CourseLists from "./CourseListsNew";
import ActivityCouponTicket from "./ActivityCouponTicket.jsx";
import DiscountPriceDialog from "./DiscountPriceDialog.jsx";
import CouponTicketCouponDown from "./coupon_ticket_count_down/CouponTicketCouponDown.jsx";
import ChooseGradeModal from "../utils/choose_grade_modal/ChooseGradeModal.jsx";
import {
  beforeSale,
  beforeSaleHeader,
  handleBeforeSale,
  trialShow,
} from "./BaseStudyPackageActivityNew";
import {
  ActivityBanner,
  ActivityRightFloatIcons,
  ActivityPeriodStatus,
  ActivityCourseFitDescribe,
  ActivityUserRecommend,
} from "./activity_components";
import { ActivityTabGroup } from "./activity_components/ActivityTabGroup";

// 优惠价格
const discount = 0;

// 提醒使用相应的优惠券
class UserSoleCouponDialog extends React.Component {
  componentDidMount() {
    this.soleCouponDialog.call(this, this.props.UserSoleCouponDialog);
  }

  soleCouponDialog(UserSoleCouponDialog) {
    const buttons = [
      {
        label: "前往报名",
        onClick: () => {
          confirmDialog.close();
          window.location.href = UserSoleCouponDialog;
        },
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        您有一张免拼团直接报名的优惠券，可用于当前课程报名
      </Dialog>
    );
  }

  render() {
    return <div></div>;
  }
}

const singleCourseJudge = (subActivities) => {
  return (
    subActivities.length == 1 &&
    subActivities[0]?.owned_media_lesson_count >=
      subActivities[0]?.media_lessons_count
  );
};

export const ActivityExperienceButton = ({
  activities,
  handleExperienceCourse,
  sub_course_type,
}) => {
  console.log("sub_course_type-------", sub_course_type);
  const { state, batch_ecoupons_amount } = findExperienceCourse(activities)[0];
  return (
    <div
      className="trial_course_button"
      // onClick={() => {
      //   console.log("=============");
      // }}
      onClick={handleExperienceCourse}
      data-state={state}
    >
      <div className="trial_course_icon"></div>
      <div>
        {sub_course_type == "reading"
          ? "试听领券"
          : batch_ecoupons_amount
          ? "试听领券"
          : "试听"}
      </div>
    </div>
  );
};

export const PriceTextShow = ({ ...props }) => {
  console.log("StudyPackageActivity_PriceTextShow: ", props);
  const { price, isExist, subActivities, saleMode } = props;

  const hasDiscountText = () => {
    return (
      <>
        <span style={{ fontSize: "16px", fontWeight: "normal", marginLeft: 0 }}>
          抵扣价：
        </span>
        {`￥${price}`}
      </>
    );
  };
  return singleCourseJudge(subActivities) ? (
    <p style={{ fontWeight: "bold" }}>
      免费领取&nbsp;
      {subActivities[0]?.list_price > 0 && (
        <span style={{ fontWeight: "normal" }}>
          <del>{`¥${subActivities[0]?.list_price}`}</del>
        </span>
      )}
    </p>
  ) : (
    <p style={{ fontWeight: "bold" }}>
      {typeof isExist == "object" ? hasDiscountText() : `￥${price}`}
      {saleMode == "normal" && subActivities.length <= 1 ? (
        ""
      ) : (
        <span className="text_min">起</span>
      )}
    </p>
  );
};

export const ActivitySignIn = ({ ...props }) => {
  const { sub_study_package_activities: subActivities, sale_mode } = props;
  // const firstMinDiscountPrice = calcActivityLowestPrice(subActivities)[0]
  //   .discountPrice;
  // console.log("计算后的折扣最低价格：", firstMinDiscountPrice);
  if (singleCourseJudge(subActivities)) {
    return "免费领取";
  }
  return "立即报名";
};

export default class StudyPackageActivity extends React.Component {
  static activityType = {
    normal: 0,
    group: 1,
  };

  static specialCategory = {
    presells: [],
    whiteBook: 24,
    interview: 17,
    experience: 23,
    thinkingSeries: 18,
    techAidPackages: [33], //jump to 教具包
  };

  constructor(props) {
    super(props);
    print(props);
    console.log("StudyPackageActivity--props", props);
    var finalPrice;
    let inviteRewardPrice = this.props.money_per_user;
    // 砍价模式 bargain activity
    if (this.props.activity) {
      if (
        this.props.activity.price -
          this.props.invited_users_count * inviteRewardPrice <
        this.props.activity.lowest_price
      ) {
        print("lowest_price：" + this.props.activity.lowest_price);
        finalPrice = this.props.activity.lowest_price;
      } else {
        print("invited_users_count：" + this.props.invited_users_count);
        finalPrice =
          this.props.activity.price -
          this.props.invited_users_count * inviteRewardPrice;
      }
    }

    console.log("init_finalPrice: ", finalPrice);
    if (this.props.sale_mode === "invite") {
      finalPrice =
        this.props.price - this.props.invited_users_count * inviteRewardPrice;
      if (finalPrice <= 0) {
        finalPrice = 0.0;
      }
    }

    if (this.props.sub_study_package_activities) {
      var x = [...this.props.sub_study_package_activities];
      var y = [...this.props.sub_study_package_activities];
      var z = [...this.props.sub_study_package_activities];
      x.sort(function (a, b) {
        return a.min_price - b.min_price;
      });
      y.sort(function (a, b) {
        return b.max_price - a.max_price;
      });
      z.sort(function (a, b) {
        return b.list_price - a.list_price;
      });
      var trial_activities = this.props.sub_study_package_activities
        .filter((e) => e.trial_activity)
        .map((e) => e.trial_activity);
    }

    print("pre_has_bought：" + this.props.pre_has_bought);
    print("sale_mode：" + this.props.sale_mode);
    print("study_package_group_state：" + this.props.study_package_group_state);
    print("finalPrice: " + finalPrice);

    if (
      !trial_activities ||
      (trial_activities && trial_activities.length <= 0)
    ) {
      if (this.props.trial_activity) {
        trial_activities = [this.props.trial_activity];
      }
    }

    this.state = {
      study_package_url: this.props.study_package_url,
      price: this.props.price,
      group_price: this.props.group_price,
      study_package_activity_buy_url: this.props.study_package_activity_buy_url,
      max_count_exceed: this.props.max_count_exceed,
      grade_exam_url: this.props.grade_exam_url,
      grade_exam_finished: this.props.grade_exam_finished,
      qrcode_url: this.props.qrcode_url,
      logged_in: this.props.logged_in,
      login_url: this.props.login_url,
      remaining_count: this.props.remaining_count,
      share_image_url: this.props.share_image_url,
      remaining_shopping_group_count: this.props.remaining_shopping_group_count,
      shopping_group: this.props.shopping_group,
      isNotExpirdOfGroupon: this.props.isNotExpirdOfGroupon,
      canJoinThisGroupon: this.props.canJoinThisGroupon,
      can_owner: this.props.can_owner,
      can_pay_remaining: this.props.can_pay_remaining,
      isCourseContainer: "block",
      activeSwitch1: "active",
      selectIndex1: -1,

      finalPrice: finalPrice,
      end_time: this.props.end_time,
      initial_price: this.props.activity ? this.props.activity.price : 0,
      invited_users_count: this.props.invited_users_count,

      sort_min_price: this.props.sub_study_package_activities
        ? x[0]?.min_price
        : "",
      sort_min_price_last: this.props.sub_study_package_activities
        ? x[x.length - 1]?.min_price
        : "",
      sort_max_price: this.props.sub_study_package_activities
        ? y[0]?.max_price
        : "",
      sort_max_price_last: y ? y[y.length - 1]?.max_price : "",

      sort_list_price: this.props.sub_study_package_activities
        ? z[0]?.list_price
        : "",
      sort_list_price_last: this.props.sub_study_package_activities
        ? z[z.length - 1]?.list_price
        : "",
      isAlertSoleCoupon: false,
      handleCourseCousultant: false,
      studyPackageActivityType: this.props.study_package_activity_type,
      phases: this.props.phases,
      isShowStartTime: this.props.is_show_start_time,
      absenteeActivity: this.props.absentee_activity,
      trialActivities: trial_activities,
      hasBoughtAny: this.props.has_bought_any,
      current_tag_id: 0,
      ecoupons_available: this.props.ecoupons_available,
      ecoupons: this.props.ecoupons,
      clickNowSignButton: false,
      showCourseCouponTicket: false,
      isExistOweMediaCountObject: undefined,
    };
  }

  showActivityPrice = () => {
    // 系列课
    if (
      this.props.sub_study_package_activities &&
      this.props.sub_study_package_activities.length >= 2
    ) {
      const sortedByMaxPrice = [
        ...this.props.sub_study_package_activities,
      ].sort(function (a, b) {
        return a.max_price - b.max_price;
      });

      const sortedByMinPrice = [...this.props.sub_study_package_activities]
        .sort(function (a, b) {
          return a.min_price - b.min_price;
        })
        .filter((p) => p.min_price > 0);

      if (this.props.sale_mode === "shopping_group") {
        return {
          minPrice: sortedByMinPrice[0].min_price,
          maxPrice: null,
          listPrice: null,
          // 是否是系列课
          isSeries: true,
        };
      }

      // 两种模式都取max_price
      // 普通模式
      if (this.props.sale_mode === "normal") {
        return {
          minPrice: sortedByMinPrice[0].min_price,
          maxPrice: sortedByMaxPrice[0].max_price,
          listPrice: null,
          isSeries: true,
        };
      }

      // 砍价/购买砍价模式
      if (
        this.props.sale_mode == "bargain" ||
        this.props.sale_mode == "buy_bargain"
      ) {
        return {
          minPrice: sortedByMinPrice[0].min_price,
          maxPrice: sortedByMaxPrice[0].max_price,
          listPrice: null,
          isSeries: true,
        };
      }
    } else {
      // 单课程
      // 两种模式都取max_price
      // 单课程要展示list_price
      const activity = this.props.sub_study_package_activities[0];
      const minPrice = activity.min_price;
      const maxPrice = activity.max_price;
      const listPrice = activity.list_price;

      if (this.props.sale_mode === "shopping_group") {
        return {
          minPrice,
          maxPrice: null,
          listPrice: null,
          isSeries: false,
        };
      }

      if (this.props.sale_mode === "normal") {
        return { minPrice: null, maxPrice, listPrice, isSeries: false };
      }

      // 砍价/购买砍价模式
      if (
        this.props.sale_mode == "bargain" ||
        this.props.sale_mode == "buy_bargain"
      ) {
        return { minPrice: null, maxPrice, listPrice, isSeries: false };
      }
    }

    return {
      minPrice: null,
      maxPrice: this.props.sub_study_package_activities[0].max_price,
      listPrice: null,
      isSeries: false,
    };
  };

  // 测评和购买
  onBuyCourse = (buy, params) => {
    if (buy == this.onDirectBuy) {
      buy(params["amount"]);
    } else if (buy == this.onGroupBuy) {
      buy(params["amount"], params["group_state"]);
    }
  };

  // 引导关注微信公众号
  onWechatFollow = (e) => {
    this.wechatFollowRef.showWechatFollow();
  };

  // login
  newGroupBuy = (e) => {
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }
    print("new Group");
    // // 显示优惠券功能
    // this.courseCoupon.setState({
    //   isCouseCouponDisplay: 'block',
    //   orderGuideDisplay: 'block'
    // })

    //（团购模式下模式下）Doing
    //异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (this.props.study_package_group_state == "bought_other") {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          为了孩子的学习效果，建议您不要同时报名多个课程
        </Dialog>
      );
      return;
    }

    if (!this.props.sub_study_package_activities) {
      GA("Courses", "newGroupBuy", this.props.study_package_activity_id);
    }
    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        let login_url = decodeURIComponent(this.props.login_url);
        XinYaNativeBridge.navigate({ href: login_url });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else if (!this.props.grade_exam_finished) {
      print("enter else if");
      if (this.props.need_redirect_by_ecoupon === true) {
        this.setState({
          isAlertSoleCoupon: true,
        });
      } else {
        this.props.sub_study_package_activities
          ? this.subCourseDilog?.setState({
              isChooseDisplay: "block",
            })
          : this.alertBuy(this.props.group_price, "new_groupon");
      }
    } else {
      print("enter else");
      if (this.props.need_redirect_by_ecoupon === true) {
        this.setState({
          isAlertSoleCoupon: true,
        });
      } else {
        this.props.sub_study_package_activities
          ? this.subCourseDilog?.setState({
              isChooseDisplay: "block",
            })
          : this.alertBuy(this.props.group_price, "new_groupon");
      }
    }
  };

  preOnGroupBuy = (groupon_amount, state, selectActivityId, selectName) => {
    print("pre_on_direct_buy 分级拼团购买");
    var amount = groupon_amount;
    if (
      new Uri(window.location.href).hasQueryParam("from_user") &&
      !this.props.isCurrentUser
    ) {
      amount = amount - discount;
    }

    if (this.props.need_redirect_by_ecoupon === true) {
      this.setState({
        isAlertSoleCoupon: true,
      });
    } else {
      this.showCourseCouponPay(
        groupon_amount,
        state,
        selectActivityId,
        selectName,
        "group"
      );
      // this.courseCoupon.setState({
      //   isCouseCouponDisplay: 'block',
      //   orderGuideDisplay: 'block',
      //   coursePayFinalPrice: groupon_amount,
      //   coursePayFinalState: state,
      //   selectActivityId: selectActivityId,
      //   selectName: selectName
      // })
      // this.setState({
      //   coursePayMode: 'group'
      // })
    }
    // this.onGroupBuy(amount, state, selectActivityId)
  };

  preOnDirectBuy = (
    groupon_amount,
    selectActivityId,
    selectName,
    selectIndex
  ) => {
    print("pre_on_direct_buy 分级直接购买");
    var directPrice = groupon_amount;
    if (
      new Uri(window.location.href).hasQueryParam("from_user") &&
      !this.props.isCurrentUser
    ) {
      directPrice = directPrice - discount;
    }

    if (this.props.need_redirect_by_ecoupon === true) {
      this.setState({
        isAlertSoleCoupon: true,
      });
    } else {
      this.showCourseCouponPay(
        groupon_amount,
        "direct",
        selectActivityId,
        selectName,
        "direct",
        selectIndex
      );
      // this.courseCoupon.setState({
      //   isCouseCouponDisplay: 'block',
      //   orderGuideDisplay: 'block',
      //   coursePayFinalPrice: groupon_amount,
      //   coursePayFinalState: 'direct',
      //   selectActivityId: selectActivityId,
      //   selectName: selectName
      // })
      // this.setState({
      //   coursePayMode: 'direct',
      // })
    }

    // this.onDirectBuy(directPrice, selectActivityId)
  };

  // alert choose direct buy or groupon
  alertBuy = (groupon_amount, state) => {
    var directPrice = this.props.price;
    var amount = groupon_amount;
    if (
      new Uri(window.location.href).hasQueryParam("from_user") &&
      !this.props.isCurrentUser
    ) {
      directPrice = directPrice - discount;
      amount = amount - discount;
    }
    const buttons = [
      {
        label: <div className="close_dialog_group_amount">{"X"}</div>,
        onClick: () => confirmDialog.close(),
      },
      {
        label:
          this.props.groupon_direct_buy === true ? "免拼团购买" : `开始拼团`,
        onClick: () => {
          confirmDialog.close();

          this.showCourseCouponPay(groupon_amount, state, null, null, "group");
          // 显示优惠券功能  新拼团购买
          // this.courseCoupon.setState({
          //   isCouseCouponDisplay: 'block',
          //   orderGuideDisplay: 'block',
          //   coursePayFinalPrice: groupon_amount,
          //   coursePayFinalState: state,
          // })
          // this.setState({
          //   coursePayMode: 'group'
          // })

          // this.onGroupBuy(amount, state)
        },
      },

      !this.props.groupon_direct_buy && {
        label: "不拼团，直接报名 ¥" + directPrice,
        onClick: () => {
          confirmDialog.close();
          this.showCourseCouponPay(directPrice, "", "", "", "direct");
          // 直接购买
          // this.courseCoupon.setState({
          //   isCouseCouponDisplay: 'block',
          //   orderGuideDisplay: 'block',
          //   coursePayFinalPrice: directPrice,
          //   coursePayFinalState: ''
          // })
          // this.setState({
          //   coursePayMode: 'direct'
          // })

          print("价格： ", directPrice);
          // this.onDirectBuy(directPrice)
        },
      },
    ];

    const confirmDialog = DialogCourseBuyManager.open(
      <DialogCourseBuy buttons={buttons}>
        <div className="shop_group_price">{`${this.props.group_max_count}人拼团价：¥${amount}`}</div>
        <span>
          原价：<del>¥{this.props.list_price}</del>
        </span>
      </DialogCourseBuy>
    );
    if (this.props.groupon_direct_buy === true) {
      let buttonTwo = document.getElementsByClassName("xinyaui-dialog__btn")[1];
      buttonTwo.style.cssText = "margin-bottom: 25px;";

      let buttonLast = document.getElementsByClassName(
        "xinyaui-dialog__btn"
      )[2];
      buttonLast.style.cssText = "display: none;";
    }
  };

  // 加入拼团（购买方法）
  joinGroupBuy = () => {
    GA("Courses", "joinGroupBuy", this.props.study_package_activity_id);
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }
    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
      // this.alertBuy(this.props.group_price, 'join_groupon')
    }
    // else if (!this.props.grade_exam_finished) {
    //   this.alertBuy(this.props.group_price, 'join_groupon')
    // }
    else {
      if (this.props.remaining_shopping_group_count <= 0) {
        if (this.props.need_redirect_by_ecoupon === true) {
          this.setState({
            isAlertSoleCoupon: true,
          });
        } else {
          this.props.sub_study_package_activities
            ? this.subCourseDilog?.setState({
                isChooseDisplay: "block",
              })
            : this.joinGroupModule(this.props.group_price, "join_groupon");
        }
        // this.onGroupBuy(this.props.group_price, 'join_groupon')
      } else {
        if (this.props.need_redirect_by_ecoupon === true) {
          this.setState({
            isAlertSoleCoupon: true,
          });
        } else {
          this.props.sub_study_package_activities
            ? this.subCourseDilog?.setState({
                isChooseDisplay: "block",
              })
            : this.alertBuy(this.props.group_price, "join_groupon");
        }
      }
    }
  };

  // 选择优惠券，加入拼团模块
  joinGroupModule = (group_price, join_groupon) => {
    this.showCourseCouponPay(
      group_price,
      join_groupon,
      null,
      null,
      "join_groupon"
    );
    // this.courseCoupon.setState({
    //   isCouseCouponDisplay: 'block',
    //   orderGuideDisplay: 'block',
    //   coursePayFinalPrice: group_price,
    //   coursePayFinalState: join_groupon
    // })
    // this.setState({
    //   coursePayMode: 'join_groupon'
    // })
  };

  remaingPay = (e) => {
    GA("Courses", "remaingPay", this.props.study_package_activity_id);
    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
      // this.onBuyCourse(this.onGroupBuy, {amount: this.props.price-this.props.group_price, group_state: 'pay_remaining'})

      //} else if (!this.props.grade_exam_finished) {

      // this.onBuyCourse(this.onGroupBuy, { amount: this.props.price - this.props.group_price, group_state: 'pay_remaining' })
    } else {
      this.showCourseCouponPay(
        this.props.price - this.props.group_price,
        "pay_remaining"
      );
      // this.courseCoupon.setState({
      //   isCouseCouponDisplay: 'block',
      //   orderGuideDisplay: 'block',
      //   coursePayFinalPrice: (this.props.price - this.props.group_price),
      //   coursePayFinalState: 'pay_remaining',
      //   selectActivityId: this.props.id,
      //   selectName: this.props.name,
      // })
      // this.setState({
      //   coursePayMode: 'pay_remaining'
      // })
      // this.onGroupBuy(this.props.price - this.props.group_price, 'pay_remaining')
    }
  };

  showCourseCouponPay(
    finalPrice,
    finalState,
    selectActivityId,
    selectName,
    coursePayMode,
    selectIndex,
    isCouseCouponDisplay = "block",
    orderGuideDisplay = "block"
  ) {
    console.log("not_group_selectIndex: ", selectIndex);
    console.log("showCourseCouponPay");
    if ("sub_study_package_activities" in this.props) {
      let activity = this.props.sub_study_package_activities.find((ac) => {
        return ac.id === selectActivityId || `${ac.id}` === selectActivityId;
      });
      print("showCourseCouponPay....");
      print(this.props.sub_study_package_activities, selectActivityId);
      let defa_ticket;
      if (
        activity &&
        "activity_ecoupons" in activity &&
        activity.activity_ecoupons.length > 0
      ) {
        defa_ticket = activity.activity_ecoupons.find((ticket, index) => {
          ticket["id"] = ticket.id;
          ticket["index"] = index;
          return ticket;
        });
      }
      print(activity);
      print(defa_ticket);
      let ecoupons_available = this.props.ecoupons_available;
      let ecoupons = this.props.ecoupons;
      if (activity && defa_ticket) {
        ecoupons = activity.activity_ecoupons;
        ecoupons_available = activity.activity_ecoupons.length > 0;
      }
      this.courseCoupon?.setState({
        isCouseCouponDisplay: "block",
        orderGuideDisplay: "block",
        coursePayFinalPrice: finalPrice,
        coursePayFinalState: finalState,
        selectActivityId: selectActivityId,
        selectName: selectName,
        selectedActivityIndex: selectIndex,
        defaultSelectCouponId:
          ecoupons_available && defa_ticket ? defa_ticket.id : null,
        defaultSelectPrice:
          ecoupons_available && defa_ticket && defa_ticket.price,
      });
      this.setState({
        coursePayMode: coursePayMode,
        ecoupons_available: ecoupons_available,
        ecoupons: ecoupons,
      });
    } else {
      this.courseCoupon?.setState({
        isCouseCouponDisplay: "block",
        orderGuideDisplay: "block",
        coursePayFinalPrice: finalPrice,
        coursePayFinalState: finalState,
        selectActivityId: selectActivityId,
        selectName: selectName,
        selectedActivityIndex: selectIndex,
      });
      this.setState({
        coursePayMode: coursePayMode,
      });
    }
  }

  // 关闭选择年级弹框
  closeChooseGradeModalStatus = () => {
    this.setState({
      chooseGradeModalStatus: false,
    });
  };

  componentWillUnmount() {
    clearInterval(this.courseCousultantInterval);
  }

  componentDidMount() {
    const {
      sub_study_package_activities,
      get_owned_media_lessons_url,
      get_questions_mini_exam_url,
      popup_questions,
      sale_mode,
      sub_course_type,
    } = this.props;
    // let log = logPageUnMount(this.props.page_track_url)
    // // 检测页面关闭而非刷新
    // log.listenPageClose()
    // // 检测浏览器回退
    // log.listenPageBack()

    if (this.props.promotion_20201111) {
      if (sub_course_type == "reading") {
        DoubleElevenModal("reading");
      }
      if (sub_course_type != "reading" && sub_course_type != "thinking") {
        DoubleElevenModal("all");
      }
    }

    // 报名页的用户，如没有添加小孩，则弹出年级选择窗口
    if (this.props.grades) {
      this.setState({
        chooseGradeModalStatus: true,
      });
    }

    if (popup_questions) {
      const questionnaireModal = showQuestionnaireActivityModal(
        get_questions_mini_exam_url,
        this.handleQuestionaireResultClose
      );
      questionnaireModal.open();
    }

    if (!UserAgent.isNativeClient()) {
      const handler = (e) => {
        // window.location.href = window.location.href.includes("#comment")

        if (!window.sessionStorage.getItem("popstate")) {
          window.removeEventListener("popstate", handler);
          window.sessionStorage.setItem("popstate", "1");
          window.location.reload();
        }
      };
      window.addEventListener("popstate", handler);
    }

    if (UserAgent.isNativeClient()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }

    let videoStyle = document.getElementById("video");
    if (videoStyle && this.props.study_package_category_id === 18) {
      videoStyle.style.cssText =
        "padding: 0 15px;background: #f5fffe;margin-bottom: 0;display: block;";
    }
    let cacheOwnedMediaCount = filterSubActivityOweMediaCount(
      sub_study_package_activities
    );

    // DidMount渲染完之后处理折价功能
    this.setState({
      isExistOweMediaCountObject: cacheOwnedMediaCount,
    });

    if (
      typeof cacheOwnedMediaCount == "object" &&
      !this.props.had_joined_groupon
    ) {
      cacheOwnedMediaCount.filterOwnedCountArray.length > 0 &&
        !props.bought_legacy_c4 &&
        courseDiscountDialog(
          cacheOwnedMediaCount.getLoopList,
          cacheOwnedMediaCount.calcSortLastDerateValue,
          get_owned_media_lessons_url,
          sub_study_package_activities,
          sale_mode
        );
    }

    // 页面渲染后之后，弹框显示课程活动优惠券领取
    if (this.props.batch_ecoupon) {
      this.setState({
        showCourseCouponTicket: true,
      });
    }

    const {
      has_bought_any,
      access_count,
      share_url,
      time_line_share_url,
      app_message_share_url,
      share_title,
      share_description,
      share_image_url,
    } = this.props;
    print("access_count:", access_count);
    print("has_bought_any: ", has_bought_any);

    const remaining_shopping_group_count = this.props
      .remaining_shopping_group_count;
    // var title = ''
    // if (remaining_shopping_group_count == 0) {
    //   title = '已成团，可直接加入！150节幼升小考点课只要￥' + (this.props.group_price - discount) + '~'
    // } else {
    //   title = '还差' + remaining_shopping_group_count + '人成团！150节幼升小考点课只要￥' + (this.props.group_price - discount) + '~'
    // }

    // 课程咨询老师弹窗,单期访问第2次且未购买课程，过30秒弹出添加课程顾问窗口：
    if (has_bought_any === false && access_count && access_count >= 3) {
      this.courseCousultantInterval = setInterval(() => {
        this.setState(
          {
            handleCourseCousultant: true,
          },
          () => {
            clearInterval(this.courseCousultantInterval);
          }
        );
        print("time end");
      }, 30000);
    }

    wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        this.setState({
          isInMiniProgram: true,
        });
      }
    });

    wx.ready(function () {
      function on_share_success() {}
      var sharedJsonTimeLine = {
        link: time_line_share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      var sharedJsonAppMessage = {
        link: app_message_share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJsonTimeLine);
      wx.onMenuShareAppMessage(sharedJsonAppMessage);

      wx.miniProgram.getEnv((res) => {
        print(res.miniprogram); // true
        if (res.miniprogram) {
          var shareURL = share_url;
          if (!shareURL) {
            shareURL = window.location.href;
          }
          let share_string = convertURLToJSON(shareURL);
          wx.miniProgram.postMessage({
            data: {
              title: share_title,
              imgUrl: share_image_url,
              link: share_string,
            },
          });
        }
      });
    });

    // 已购买当前课程的老用户（非当期）访问
    if (this.props.pre_has_bought == true) {
      GA("Courses", "Purchased", this.props.study_package_activity_id);
    }

    if (
      this.props.pre_has_bought == true &&
      window.location.search == "?id=83"
    ) {
      const buttons = [
        {
          label: "确定",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          本课程针对非上海地区的一年级学生，与你已报名的《幼升小考点冲刺班》课程内容一样，无需重复报名。
        </Dialog>
      );
    }
    // 报名之前的弹框测试
    // {
    //   this.props.quiz_required === true ?
    //   new ExamDialogStartProxy({
    //     prepare_check_url: this.props.prepare_check_url,
    //     training_camp_url: this.props.training_camp_url,
    //     study_package_activity_url: this.props.study_package_activity_url
    //   }).open()
    //   : ''
    // }

    //异常情况：已购买单课程，准备购买包含单课程的课程包组合 (partial)
    //异常情况：已购买课程包组合，准备购买组合中包含的单课程 (superset)
    //异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (
      this.props.study_package_group_state == "partial" ||
      this.props.study_package_group_state == "superset"
    ) {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          {this.props.study_package_group_state == "partial"
            ? "您已拥有当前课程中的" +
              this.props.bought_category_name +
              "，请单独报名其它课程"
            : this.props.study_package_group_state == "superset"
            ? "您报名的《" +
              this.props.bought_category_name +
              "》已包含当前课程，无需重复报名"
            : ""}
        </Dialog>
      );
    }
    const switchBarItem = () => {
      // 分级内容块section
      let sub_tab_index = new Uri(window.location.href).getQueryParamValue(
        "tab"
      );

      if (sub_tab_index && $("#switch_tab li[data-index]")) {
        $($("#switch_tab li[data-index]")[sub_tab_index - 1]).trigger("click");
        GA(
          "Courses",
          `Tab${sub_tab_index}`,
          this.props.study_package_activity_id
        );
      }

      if (sub_tab_index == "question") {
        $("#problem_tab").trigger("click");
        GA("tabQuestion", this.props.study_package_activity_id);
      }

      if (window.location.href.includes("#comment")) {
        this.setState({
          isCourseContainer: "none",
          activeSwitch1: "",
          activeSwitch2: "",
          activeSwitch3: "active",
        });
        this.problemFoldContainer?.setState({
          isCommonProblemBox: "none",
        });

        this.isCourseContainer?.setState({
          isCourseEvaluateContainer: "block",
        });
        return;
      }
      var switch_offset_top = document.getElementById("switch_tab");
      window.onload = () => {
        window.onload = null;
        if (window.location.href.includes("#tab")) {
          print(switch_offset_top.offsetTop);
          window.scrollTo(0, switch_offset_top.offsetTop);
        }
      };

      // 判断在小程序中，根据参数 comment 定位到用户评论语块
      if (UserAgent.isMiniProgram()) {
        if (window.location.href.includes("#comment")) {
          this.setState({
            isCourseContainer: "none",
            activeSwitch1: "",
            activeSwitch2: "",
            activeSwitch3: "active",
          });
          this.problemFoldContainer?.setState({
            isCommonProblemBox: "none",
          });
          this.isCourseContainer?.setState({
            isCourseEvaluateContainer: "block",
          });
        }
        return;
      }

      if (this.props.study_package_category_id === 18) {
        // if (window.location.href.includes("tab=" + sub_tab_index)) {
        //   this.setState({
        //     selectIndex1: sub_tab_index,
        //     isCourseContainer: "none",
        //     activeSwitch1: "",
        //     activeSwitch3: ""
        //   });
        //   this.problemFoldContainer?.setState({
        //     isCommonProblemBox: "none"
        //   });
        //   this.isCourseContainer?.setState({
        //     isCourseEvaluateContainer: "none"
        //   });
        // }

        // 分级内容块,小程序中，根据参数 sub_course的索引 定位到相应的section块
        if (UserAgent.isMiniProgram()) {
          if (window.location.href.includes("tab=" + sub_tab_index)) {
            this.setState({
              selectIndex1: sub_tab_index,
              isCourseContainer: "none",
              activeSwitch1: "",
              activeSwitch3: "",
            });
            this.problemFoldContainer?.setState({
              isCommonProblemBox: "none",
            });
            this.isCourseContainer?.setState({
              isCourseEvaluateContainer: "none",
            });
          }
        }
      }
    };

    switchBarItem();
    // 判断时间结束/价格为零时，开始/邀请 按钮隐藏
    if (
      new Date(this.props.system_time) < new Date(this.props.buy_endtime) &&
      (this.state.end_time
        ? new Date(this.state.end_time) > new Date(this.props.system_time)
        : !this.state.end_time)
    ) {
      print("时间：成立");
      this.setState({
        inviteHideDisplay: "block",
      });
    } else {
      print("时间：不成立");
      this.setState({
        inviteHideDisplay: "none",
      });
    }

    // 不是每日限量购买的情况下, 刷新页面加载完之后, 直接弹框显示
    // 非教具包, 刷新页面加载完之后, 直接弹框显示
    if (
      !StudyPackageActivity.specialCategory.techAidPackages.includes(
        this.props.study_package_category_id
      )
    ) {
      if (this.props.is_show_alert) {
        const buttons = [
          {
            label: "确定",
            onClick: () => {
              confirmDialog.close();
            },
          },
        ];
        const confirmDialog = DialogManager.open(
          <Dialog
            buttons={buttons}
          >{`您报名的《${this.props.pre_study_package_name}》已包含当前课程，无需重复购买`}</Dialog>
        );
      }
    }

    print("absentee_activity", this.props.absentee_activity);
    if (
      this.props.current_user_shopping_group &&
      this.props.current_user_shopping_group.state.includes("已支付押金") &&
      !this.state.absenteeActivity
    ) {
      this.alertPoster();
    }
    if (this.props.popup_poster) {
      this.inviteFriendDrawCash.call(null, false);
    }

    this.addGAForTabs();
  }

  addGAForTabs = () => {
    const tabsContainer = document.getElementsByClassName("switchTitle")[0];
    const tags = tabsContainer?.childNodes;
    if (tags) {
      const tagsArr = Array.from(tags);
      tagsArr.forEach((tag, index) => {
        tag.addEventListener("click", () => {
          GA(
            "Courses",
            `Tab${index + 1}`,
            this.props.study_package_activity_id
          );
        });
      });
    }
  };

  clickTrialChoose = (e) => {
    this.setState({
      isClickTrial: !this.state.isClickTrial,
    });
  };

  whatShopGroupStateToShow() {
    var dev_env = window.location.hash;
    if (this.props.bought && !this.props.study_package_activity.absentee) {
      if (dev_env == "#dev_env") {
        alert("has_bought");
      }
      // 已购买
      return this.hasbought();
    } else if (
      this.props.bought &&
      this.props.study_package_activity.absentee
    ) {
      if (dev_env == "#dev_env") {
        alert("has_bought && absentee");
      }
      // 可囤课系列课已购买还可以再次购买,团购
      return this.preBuyShopGroupState();
    } else if (
      this.props.pre_has_bought &&
      !StudyPackageActivity.specialCategory.techAidPackages.includes(
        this.props.study_package_category_id
      )
    ) {
      if (dev_env == "#dev_env") {
        alert("pre_has_bought");
      }
      //当属于进位计算训练分类时底部显示立即报名按钮，点击立即报名，弹框提示直接购买教具包（preBoughtCourseDialog 方法）
      // 往期已购买用户进入报名页后的页面状态
      return this.showPreviousUserBuyState();
    } else if (
      this.props.study_package_group_state == "partial" ||
      this.props.study_package_group_state == "superset"
    ) {
      if (dev_env == "#dev_env") {
        alert("pre_has_bought/partial or superset");
      }
      // the user already bought partial of this category
      return this.showPreviousUserBuyState();
    } else if (this.props.hasNotGroupon) {
      if (dev_env == "#dev_env") {
        alert("has_not_groupon");
      }
      //新用户非团购购买，比如直接购买 / 砍价状态
      return this.hasNotGroupon();
    } else if (
      this.state.can_pay_remaining &&
      this.props.current_user_shopping_group
    ) {
      if (dev_env == "#dev_env") {
        alert("payRemainingPrice");
      }
      // 未拼团成功 补差价
      return this.payRemainingPrice();
    } else if (!this.state.isNotExpirdOfGroupon) {
      if (dev_env == "#dev_env") {
        alert("isNotExpirdOfGroupon");
      }
      // 已过期
      return this.expiredShopGroupState();
    } else if (!this.props.shopping_group) {
      if (dev_env == "#dev_env") {
        alert("preBuyShopGroupState");
      }
      // 新用户团购购买页
      return this.preBuyShopGroupState();
    } else if (!this.props.canJoinThisGroupon) {
      if (dev_env == "#dev_env") {
        alert("!canJoinThisGroupon");
      }
      //邀请好友拼团 + 补差价
      return this.nextBuyInviteFriend();
    } else {
      if (dev_env == "#dev_env") {
        alert("canJoinThisGroupon");
      }
      //加入拼团 + 直接报名
      return this.onJoinShopGroup();
    }
  }

  // 已过期
  expiredShopGroupState() {
    const { minPrice } = this.showActivityPrice();
    return (
      <div className="activity_box_foot">
        <div className="activitiy_amount">
          <span>本期名额已满</span>
        </div>
        <div className="activity_box_bg clearfix">
          <div className="activitiy_price">
            <PriceTextShow
              // price={minPrice}
              price={`${
                calcActivityLowestPrice(
                  this.props.sub_study_package_activities
                )[0].discountPrice
              }`}
              isExist={this.state.isExistOweMediaCountObject}
              subActivities={this.props.sub_study_package_activities}
              saleMode={this.props.sale_mode}
            />
            <div className="price_subscribe">{this.props.refund_policy}</div>
          </div>
          <div className="activitiy_sign_in activity_sign_end">等待下期</div>
          {trialShow(
            this.state.trialActivities,
            this.props.study_package_activity?.id
          )}
        </div>
      </div>
    );
  }

  // 开课之前 最开始状态, 报名初始状态, 团购模式
  preBuyShopGroupState() {
    // var groupon_price = this.props.group_price;
    // var isFromUser = false;
    // if (
    //   new Uri(window.location.href).hasQueryParam("from_user") &&
    //   !this.props.isCurrentUser
    // ) {
    //   groupon_price = groupon_price - discount;
    //   isFromUser = true;
    // }
    // var showPrice = this.state.sort_min_price + "-" + this.state.sort_max_price
    // if (this.state.sort_max_price == this.state.sort_min_price) {
    //   showPrice = this.state.sort_max_price
    // }
    // var showPrice;

    // const isNormalMode =
    //   [...this.props.sub_study_package_activities][0].sale_mode == "normal"
    //     ? true
    //     : false;

    // print("preBuyShopGroupState_isNormalMode: ", isNormalMode);

    // var price;
    // if (isNormalMode) {
    //   price = `¥${this.state.sort_max_price}`;
    // } else {
    //   price = `¥${this.state.sort_max_price_last}-${this.state.sort_max_price}`;
    // }

    // if (this.state.sort_max_price == this.state.sort_max_price_last) {
    //   price = `¥${this.state.sort_max_price}`;
    // }

    // if (this.state.hasBoughtAny) {
    //   var listPrice = `¥${this.state.sort_list_price_last}-${this.state.sort_list_price}`
    //   if (this.state.sort_list_price == this.state.sort_list_price_last) {
    //     listPrice = `¥${this.state.sort_list_price_last}`
    //   }
    //   showPrice = (
    //     <div className="activitiy_price">
    //       <div className="price_subscribe">
    //         <span><del>原价: {`${listPrice}`}</del></span>
    //       </div>
    //       <div className="renewal_price">
    //         <h5>续课价:</h5>
    //         <span>{price}</span>
    //       </div>
    //     </div>
    //   )
    // } else {
    // }
    const { minPrice } = this.showActivityPrice();
    let showPrice = (
      <div className="activitiy_price">
        <p className="price">{minPrice}</p>
        <div className="price_subscribe">{this.props.refund_policy}</div>
      </div>
    );
    // if (this.state.sort_max_price === "") {
    //   showPrice = (
    //     <div className="activitiy_price">
    //       <p>
    //         ¥{this.props.price}
    //         <span className="origin_price">
    //           &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
    //         </span>
    //       </p>
    //       <div className="price_subscribe">{this.props.refund_policy}</div>
    //     </div>
    //   );
    // }
    const header = () => {
      if (beforeSale(this.props)) {
        return beforeSaleHeader(this.props);
      } else {
        if (
          this.props.group_price > 0 &&
          this.props.sale_mode === "shopping_group"
        ) {
          return this.showJoinGrouponUser();
        }
        if (this.props.sale_mode === "normal") {
          return this.showLimitedUser();
        }
        return "";
      }
    };

    return (
      <div>
        <div className="activity_box_foot">
          {header()}
          <div className="activity_box_bg clearfix">
            {showPrice}
            <div
              className={`activitiy_sign_in ${
                beforeSale ? "activity_sign_end" : ""
              }`}
              onClick={this.newGroupBuy}
            >
              <ActivitySignIn {...this.props} />
            </div>
            {trialShow(
              this.state.trialActivities,
              this.props.study_package_activity?.id
            )}
          </div>
          {
            // showPrice
          }
          {/* <div className="activitiy_price"> */}
          {/* {
              this.props.sub_study_package_activities
                ?
                <p>
                  {"¥" + showPrice}
                </p>
                :
                (
                  isFromUser
                    ?
                    <p>{this.props.group_max_count}人拼团价: ¥{groupon_price}</p>
                    :
                    <p>{this.props.group_max_count}人拼团价: ¥{groupon_price}</p>
                )
            } */}
          {/* <div className="price_subscribe">
              <span>原价: <del>¥{this.props.list_price}</del></span>&nbsp;&nbsp;{this.props.refund_policy}
            </div> */}
          {/* <div className="price_subscribe">
              {this.props.refund_policy}
            </div> */}
          {/* </div> */}
          {/* {trialShow(
            this.state.trialActivities,
            this.props.study_package_activity?.id
          )} */}
          {/* 拼团模式下 */}
          {/* <div className="activitiy_sign_in" onClick={this.newGroupBuy}>立即报名</div> */}
        </div>
      </div>
    );
  }

  //邀请好友拼团 + 补差价
  nextBuyInviteFriend() {
    // bonus_type【0: cash， 1: virtual】
    let url = this.props.note_url;
    if (this.props.course_type === "poetry") {
      url = this.props.current_bought_study_package_url;
    }
    return (
      <div className="activity_box_ft">
        {this.showJoinGrouponUser()}
        {
          // this.props.course_type === 'poetry'
          //   ? <a className="free_experience" href={this.props.current_bought_study_package_url}>开课须知</a>
          //   : <a className="free_experience" href={this.props.note_url}>开课须知</a>
        }
        <a className="free_experience" href={url}>
          开课须知
        </a>
        <div className="activitiy_price" onClick={this.remaingPay}>
          <div className="activitiy_direct_buy">
            <span className="buy_price">
              ¥{this.props.price - this.props.group_price}
            </span>
            <br />
            <span>补差价购买</span>
          </div>
        </div>
        <div
          className="study_group_price"
          onClick={this.inviteFriend.bind(null, true)}
        >
          <div className="invite_friends_group">
            <span>邀请好友</span>
          </div>
        </div>
      </div>
    );
  }

  // 未拼团成功 补差价
  payRemainingPrice() {
    return (
      <div className="activity_pay_remaining_price">
        {this.showPayRemainingTime()}
        <div className="pay_remaining_buy" onClick={this.remaingPay}>
          <span className="remaining_price">
            ¥{this.props.price - this.props.group_price}
          </span>
          <br />
          <span>补差价购买</span>
        </div>
      </div>
    );
  }

  //加入拼团 + 直接报名
  onJoinShopGroup() {
    // var showPrice = this.state.sort_min_price + "-" + this.state.sort_min_price_last
    // if (this.state.sort_min_price == this.state.sort_min_price_last) {
    //   showPrice = this.state.sort_min_price
    // }

    // var showPrice;
    // const isNormalMode =
    //   [...this.props.sub_study_package_activities][0].sale_mode == "normal"
    //     ? true
    //     : false;

    // print("onJoinShopGroup_isNormalMode: ", isNormalMode);

    // var price;
    // if (isNormalMode) {
    //   price = `¥${this.state.sort_max_price}`;
    // } else {
    //   price = `¥${this.state.sort_max_price_last}-${this.state.sort_max_price}`;
    // }

    // if (this.state.sort_max_price == this.state.sort_max_price_last) {
    //   price = `¥${this.state.sort_max_price}`;
    // }
    const { minPrice } = this.showActivityPrice();
    let showPrice = (
      <div className="activitiy_price">
        <p className="price">{minPrice}</p>
        <div className="price_subscribe">{this.props.refund_policy}</div>
      </div>
    );
    // if (price == "") {
    //   showPrice = (
    //     <div className="activitiy_price">
    //       <p>
    //         ¥{this.props.group_price}
    //         <span className="origin_price">
    //           &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
    //         </span>
    //       </p>
    //       <div className="price_subscribe">{this.props.refund_policy}</div>
    //     </div>
    //   );
    // }
    const header = () => {
      if (beforeSale(this.props)) {
        return beforeSaleHeader(this.props);
      } else {
        if (
          this.props.group_price > 0 &&
          this.props.sale_mode === "shopping_group"
        ) {
          return this.showJoinGrouponUser();
        }
        if (this.props.sale_mode === "normal") {
          return this.showLimitedUser();
        }
        return "";
      }
    };

    return (
      <div className="activity_box_foot">
        {header()}
        <div className="activity_box_bg clearfix">
          {showPrice}
          <div
            className={`study_group_price ${before ? "activity_sign_end" : ""}`}
            onClick={this.joinGroupBuy}
          >
            {this.props.groupon_direct_buy === true ? "直接报名" : "加入拼团"}
          </div>
          {trialShow(
            this.state.trialActivities,
            this.props.study_package_activity?.id
          )}
        </div>
      </div>
    );
  }

  hasbought() {
    const {
      bought,
      complete_users,
      sale_mode,
      course_type,
      current_bought_study_package_url,
      note_url,
      is_parent_class,
    } = this.props;

    let linkUrl, afterBoughtTextStyle;
    if (course_type === "poetry") {
      linkUrl = current_bought_study_package_url;
    } else {
      linkUrl = note_url;
    }

    if (sale_mode == "normal") {
      if (is_parent_class) {
        afterBoughtTextStyle = { width: "100%" };
      } else {
        afterBoughtTextStyle = { width: "66%" };
      }
    } else {
      afterBoughtTextStyle = { width: "100%" };
    }

    return (
      <div className="activity_bought">
        {/* {this.showJoinGrouponUser()} */}
        {/* <div className="sign_up_success">
          {bought ? <span>报名成功！</span> : ''}
        </div> */}
        {complete_users
          ? ""
          : sale_mode == "shopping_group" && this.showJoinGrouponUser()}
        <a
          className="free_experience course_start_notice"
          href={linkUrl}
          style={afterBoughtTextStyle}
        >
          开课须知
        </a>
        {sale_mode == "normal" && !is_parent_class && (
          <div
            className="study_group_price"
            onClick={this.inviteFriend.bind(null, false)}
          >
            <div className="invite_friends_group">
              <span>邀请好友</span>
            </div>
          </div>
        )}
      </div>
    );
  }

  // 往期已购买用户进入报名页后的页面状态
  showPreviousUserBuyState() {
    const { maxPrice, listPrice } = this.showActivityPrice();
    var price = "";
    switch (this.props.sale_mode) {
      case "normal":
        price = (
          <div className="activitiy_price">
            <p className="price">
              {`￥${maxPrice}`}
              {this.props.sub_study_package_activities.length > 1 && (
                <span className="text_min">起</span>
              )}
              {listPrice > 0 && (
                <span>
                  <del>¥{this.props.list_price}</del>
                </span>
              )}
              {/* <span className="origin_price">
                &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
              </span> */}
            </p>
            <div className="price_subscribe">{this.props.refund_policy}</div>
          </div>
        );
        break;
      case "bargain":
        price = (
          <div className="activitiy_price">
            {/* {this.props.activity ? (
              <p>
                ¥
                {this.props.activity.lowest_price +
                  "-" +
                  this.props.activity.price}
              </p>
            ) : (
              <p>
                ¥{this.props.price}
                <span className="origin_price">
                  &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
                </span>
              </p>
            )} */}
            {singleCourseJudge(this.props.sub_study_package_activities) ? (
              <p className="price">免费领取</p>
            ) : (
              <p className="price">
                {`￥${maxPrice}`}
                <span className="text_min">起</span>
                {listPrice > 0 && (
                  <span>
                    <del>¥{this.props.list_price}</del>
                  </span>
                )}
              </p>
            )}

            <div className="price_subscribe">{this.props.refund_policy}</div>
          </div>
        );
        break;
      case "buy_bargain":
        price = (
          <div className="activitiy_price">
            {/* {this.props.activity ? (
              <p>¥{this.props.activity.price}</p>
            ) : (
              <p>
                ¥{this.props.price}
                <span className="origin_price">
                  &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
                </span>
              </p>
            )} */}
            {singleCourseJudge(this.props.sub_study_package_activities) ? (
              <p className="price">免费领取</p>
            ) : (
              <p className="price">
                {`￥${maxPrice}`}
                <span className="text_min">起</span>
                {listPrice > 0 && (
                  <span>
                    <del>¥{this.props.list_price}</del>
                  </span>
                )}
              </p>
            )}
            <div className="price_subscribe">{this.props.refund_policy}</div>
          </div>
        );
        break;
      case "invite":
        price = (
          <div className="activitiy_price">
            {singleCourseJudge(this.props.sub_study_package_activities) ? (
              <p className="price">免费领取</p>
            ) : (
              <p className="price">
                {`￥${maxPrice}`}
                <span className="text_min">起</span>
                {listPrice > 0 && (
                  <span>
                    <del>¥{this.props.list_price}</del>
                  </span>
                )}
              </p>
            )}
            <div className="price_subscribe">{this.props.refund_policy}</div>
          </div>
        );
        break;
      default:
        price = (
          <div className="activitiy_price">
            <p>
              {this.props.group_max_count}人拼团价：¥{this.props.group_price}
            </p>
            <div className="price_subscribe">
              <span>
                原价:<del>¥{this.props.list_price}</del>
              </span>
              &nbsp;{this.props.refund_policy}
            </div>
          </div>
        );
        break;
    }
    return (
      <div className="activity_box_foot">
        {/* {this.showJoinGrouponUser()} */}
        <div className="activity_box_bg clearfix">
          {price}
          <div
            className="activitiy_sign_in"
            onClick={this.inviteFriendRecommend}
          >
            邀请报名
          </div>
          {this.props.study_package_group_state == "partial" ||
          this.props.study_package_group_state == "superset" ? (
            <a
              className="free_experience"
              href={this.props.study_package_activities_url}
            >
              我的课程
            </a>
          ) : (
            <a className="free_experience" href={this.props.study_package_url}>
              进入课程
            </a>
          )}
        </div>
      </div>
    );
  }

  // 砍价活动👇👇👇 Start

  // 倒计时通用
  bargainCountDownTime(inviteRewardPrice) {
    // 没有用到 {d}天 {h}:{m}:{s} 所有不会显示倒计时，只有文案
    return (
      <CountDownTime
        endTime={new Date(this.props.buy_endtime)}
        overText="邀请已结束"
        onEnd={this.handleCountdownEnd}
      >
        {({ d, h, m, s }) => {
          return (new Date(this.props.buy_endtime).getTime() -
            new Date(this.props.system_time).getTime()) /
            1000 /
            60 /
            60 >
            this.props.bargain_duration_hours ? (
            <span>
              {this.props.bargain_duration_hours}
              小时内，每邀请1位新朋友关注『心芽学堂』减{inviteRewardPrice}
              元，最高可减至
              {this.props.activity?.lowest_price}
              元购买！
            </span>
          ) : (
            <span>
              {~~h}小时内，每邀请1位新朋友关注『心芽学堂』减{inviteRewardPrice}
              元，最高可减至
              {this.props.activity?.lowest_price}
              元购买！
            </span>
          );
        }}
      </CountDownTime>
    );
  }

  buyBargainPromptText() {
    return (
      <span>
        {`每邀请1位朋友成功报名《${this.getBargainActivityLastName()}》即减${
          this.props.money_per_user
        }元，最高可0元领！`}
      </span>
    );
  }

  // 获取bargain_study_package_activities最后一项中的name
  getBargainActivityLastName = () => {
    const { bargain_study_package_activities } = this.props;
    let lastBargainActivityName;
    if (
      bargain_study_package_activities &&
      bargain_study_package_activities.length > 0
    ) {
      lastBargainActivityName = [
        ...bargain_study_package_activities,
      ].reverse()[0].name;
    }
    console.log("lastBargainActivityName: ", lastBargainActivityName);
    return lastBargainActivityName;
  };

  // 砍价模式·点击开始邀请朋友
  invitingUser = () => {
    let inviteRewardPrice = this.props.money_per_user;
    if (
      this.props.sale_mode === "bargain" &&
      this.props.sub_study_package_activities
    ) {
      print("bargain and sub_study_package_activities");
      GA("Courses", "shareFree", this.props.study_package_activity_id);
    } else {
      if (this.props.sale_mode === "bargain") {
        print("sale_mode：bargain");
        GA("Courses", "sharePoster", this.props.study_package_activity_id);
      }
    }

    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      if (!this.props.poster_url) {
        return;
      }
      if (this.props.poster_url) {
        print("进入海报生成");

        let free_class;
        const calcTimeStamp =
          (new Date(this.props.buy_endtime).getTime() -
            new Date(this.props.system_time).getTime()) /
            1000 /
            60 /
            60 >
          this.props.bargain_duration_hours;
        if (this.props.sale_mode === "bargain") {
          if (calcTimeStamp) {
            free_class = "more_than_bargain_activity_hour";
          } else {
            free_class = "less_than_bargain_activity_hour";
          }
        } else if (this.props.sale_mode === "buy_bargain") {
          free_class = "buy_bargain_text";
        } else if (this.props.sale_mode === "invite") {
          free_class = "invite_mode_invite_friend";
        } else {
          free_class = "invite_draw_cash";
        }

        //   if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterTemplate: this.props.poster_photo_template_url,
          posterCreation: this.props.poster_creation,
          mode: "front_end",
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          free: free_class,
          bargain_duration_hours: this.props.bargain_duration_hours,
          activity: this.props.activity,
          buyBargainActivityName: this.getBargainActivityLastName(),
          moneyPerUser: this.props.money_per_user,
          end_time: this.props.end_time,
          buy_endtime: this.props.buy_endtime,
          system_time: this.props.system_time,
          callback:
            this.props.sale_mode === "bargain"
              ? this.posterCallback.bind(this)
              : "",
          invite_count_required: this.props.invite_count_required,
          inviteRewardPrice: inviteRewardPrice,
          avatar_location: this.props.avatar_location,
        });
        // }
        this.sharePosterProxy.open();

        if (
          this.props.sale_mode === "bargain" ||
          this.props.sale_mode === "buy_bargain"
        ) {
          this.posterCallback();
        }
      }
    }

    if (this.props.sale_mode === "invite") {
      print("sale_mode：invite");
      GA("Courses", "shareFree", this.props.study_package_activity_id);
      request({
        url: this.props.inviting_user_url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          id: this.props.study_package_activity_id,
        },
      })
        .then((resp) => {
          print(resp.data);
        })
        .catch((err) => {
          print(err);
        });
    }
  };

  // 点击体验课按钮触发
  handleExperienceCourse = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(this.props.logged_in, this.props.qrcode_url, "--------------");
    let dataState = e.currentTarget.getAttribute("data-state");
    console.log("dataState: ", dataState);
    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      if (dataState == "ok") {
        this.alertBargainBuy();
        // alone_sign_price;
        $($("#course_list_container_ul li[data-index]")[0]).trigger("click");
        // $($(".alone_sign_price")[0]).trigger("click");
      } else {
        onNavigate(
          e,
          `${this.props.trial_study_package_activity.url}&ref=trial_ecoupon`
        );
      }
    }
  };

  // 判断用户通过海报邀请
  posterCallback = () => {
    if (this.state.end_time) {
      return;
    }
    request({
      url: this.props.inviting_user_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.activity.id,
      },
    })
      .then((resp) => {
        print(resp.data);
        this.setState({
          end_time: resp.data.end_time,
        });
      })
      .catch((err) => {
        print(err);
      });
  };

  alertBargainBuy = () => {
    console.log("支付模式：alertBargainBuy");
    //还没开售
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }

    if (this.preBoughtCarryCalc()) {
      this.preBoughtCourseDialog();
      return;
    }

    if (this.props.sub_study_package_activities) {
      this.subCourseDilog?.setState({
        isChooseDisplay: "block",
      });
      this.setState({
        coursePayMode: "direct",
      });
    } else {
      this.singleCourseInvited();
    }
  };

  singleCourseInvited = () => {
    let inviteRewardPrice = this.props.money_per_user;
    // （砍价模式下）
    //异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (this.props.study_package_group_state == "bought_other") {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          为了孩子的学习效果，建议您不要同时报名多个课程
        </Dialog>
      );
      return;
    }
    const end_time = this.state.end_time;
    const finalPrice = this.state.finalPrice;
    const id = this.props.study_package_activity.id;
    const name = this.props.study_package_activity.name;

    const buttons = [
      {
        label: <div className="close_dialog_bargain">{"X"}</div>,
        onClick: () => confirmDialog.close(),
      },
      {
        label: finalPrice ? (
          finalPrice != 0 && (
            <div className="alert_bargain_invite">
              {end_time ? "继续邀请" : "开始邀请"}
            </div>
          )
        ) : (
          <div className="alert_bargain_invite">
            {this.props.invited_users_count > 0 ? "继续邀请" : "开始邀请"}
          </div>
        ),
        onClick: () => {
          confirmDialog.close();
          this.props.sale_mode === "bargain"
            ? this.invitingUser()
            : this.inviteFriendDrawCash.call(null, false);
        },
      },
      {
        label: "不邀请，直接付款",
        onClick: () => {
          confirmDialog.close();
          // this.onDirectBuy(finalPrice)
          this.courseCoupon?.setState({
            isCouseCouponDisplay: "block",
            orderGuideDisplay: "block",
            coursePayFinalPrice: finalPrice,
            coursePayFinalState: "direct",
            selectActivityId: id,
            selectName: name,
          });
          this.setState({
            coursePayMode: "direct",
          });
          // this.directBuyCourse()
        },
      },
    ];

    const confirmDialog = DialogCourseBuyManager.open(
      <DialogCourseBuy buttons={buttons}>
        <div className="shop_group_price">
          {this.props.activity ? (
            end_time ? (
              <i>¥{this.props.activity ? finalPrice : this.props.price}</i>
            ) : (
              <i>
                {this.props.activity
                  ? "¥" +
                    this.props.activity.lowest_price +
                    "-" +
                    this.props.activity.price
                  : this.props.price}
              </i>
            )
          ) : (
            <i>¥{this.props.price}</i>
          )}
        </div>
        <span>
          原价：<del>¥{this.props.list_price}</del>
        </span>

        {this.props.sale_mode === "bargain" ? (
          <div className="dialog_count_time">
            {this.bargainCountDownTime(inviteRewardPrice)}
          </div>
        ) : (
          <div className="dialog_count_time">
            邀请{this.props.invite_count_required}
            位新朋友关注『心芽学堂』公众号即可免费报名
          </div>
        )}
      </DialogCourseBuy>
    );
  };
  // 如果之前购买过并且购买的是进位计算训练营（category id is 33），则弹框购买教具包
  preBoughtCarryCalc = () => {
    return (
      StudyPackageActivity.specialCategory.techAidPackages.includes(
        this.props.study_package_category_id
      ) && this.props.pre_has_bought
    );
  };
  // 每日限量抢购，并之前已经购买过相应的课程
  preBoughtCourseDialog() {
    if (this.preBoughtCarryCalc()) {
      const buttons = [
        {
          label: "取消",
          onClick: () => confirmDialog.close(),
        },
        {
          label: "去购买",
          onClick: () => {
            confirmDialog.close();
            window.location.href = this.props.teaching_aid_redirect_url;
          },
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          您报名的《{`${this.props.pre_study_package_name}`}
          》已包含当前课程，建议您直接购买教具包即可
        </Dialog>
      );
      return;
    }
  }

  // 选择相应的子课程
  signCourseClick = () => {
    console.log("支付模式：signCourseClick");
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }

    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      print(" 分级购买子课程包 选择相应的子课程");
      if (this.props.sale_mode == "normal") {
        if (this.props.need_redirect_by_ecoupon === true) {
          this.setState({
            isAlertSoleCoupon: true,
          });
          return;
        }
      }
      this.subCourseDilog?.setState({
        isChooseDisplay: "block",
      });
    }
  };

  directBuyCourse = (
    selectMinMoney,
    selectMaxMoney,
    activityTargetId,
    selectName
  ) => {
    console.log("支付模式：directBuyCourse");
    print("走的直接购买");
    // TODO: 邀请满员支付的时候出现NAN问题
    print(
      "直接购买_selectMinMoney:",
      selectMinMoney,
      "selectMaxMoney:",
      selectMaxMoney
    );

    // 还没开售
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }

    // 砍价活动, 邀请一个人奖励的钱
    let inviteRewardPrice = this.props.money_per_user;

    // （普通模式下）
    // 每日限量模式下, 弹框提示
    this.preBoughtCourseDialog();

    //异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (this.props.study_package_group_state == "bought_other") {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          为了孩子的学习效果，建议您不要同时报名多个课程
        </Dialog>
      );
      return;
    }

    GA("Courses", "directBuy", this.props.study_package_activity_id);

    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    } else {
      var amount;
      if (this.props.sub_study_package_activities) {
        // amount = selectMaxMoney
        if (this.props.sale_mode == "invite") {
          // amount = selectMinMoney;
          amount = selectMaxMoney;
          print("邀请中/待支付：" + amount);
          this.courseCoupon?.setState({
            isCouseCouponDisplay: "block",
            orderGuideDisplay: "block",
            coursePayFinalPrice: amount,
            coursePayFinalState: "direct",
            selectActivityId: activityTargetId,
            selectName: selectName,
          });
          this.setState({
            coursePayMode: "direct",
          });
          return;
        }
        if (
          this.props.sale_mode == "bargain" ||
          this.props.sale_mode == "buy_bargain"
        ) {
          if (
            selectMaxMoney -
              this.props.invited_users_count * inviteRewardPrice <=
            selectMinMoney
          ) {
            amount = Number(selectMinMoney);
            print("邀请中/待支付：" + amount <= 0 ? 0 : amount);
            this.courseCoupon?.setState({
              isCouseCouponDisplay: "block",
              orderGuideDisplay: "block",
              coursePayFinalPrice: amount,
              coursePayFinalState: "direct",
              selectActivityId: activityTargetId,
              selectName: selectName,
            });
            this.setState({
              coursePayMode: "bargain",
            });
          } else {
            amount =
              selectMaxMoney -
              this.props.invited_users_count * inviteRewardPrice;

            console.log("--2241-待支付：", amount <= 0 ? 0 : amount);
            console.log(
              "2243_砍价模式/最大：" +
                selectMaxMoney +
                "元," +
                "最小：" +
                selectMinMoney +
                "元,待支付buy：" +
                amount
            );

            this.courseCoupon?.setState({
              isCouseCouponDisplay: "block",
              orderGuideDisplay: "block",
              coursePayFinalPrice: amount,
              coursePayFinalState: "direct",
              selectActivityId: activityTargetId,
              selectName: selectName,
            });
            this.setState({
              coursePayMode: "bargain",
            });

            this.showCourseCouponPay(
              amount,
              "direct",
              activityTargetId,
              selectName,
              "bargin",
              null,
              amount == 0 ? "none" : "block"
            );
          }
        }
      } else {
        if (this.props.activity) {
          if (
            this.props.activity.price -
              this.props.invited_users_count * inviteRewardPrice <
            this.props.activity.lowest_price
          ) {
            amount = this.props.activity.lowest_price;
            // print("amount-2330---: ", amount)
            this.courseCoupon?.setState({
              isCouseCouponDisplay: "block",
              orderGuideDisplay: "block",
              coursePayFinalPrice: amount,
              coursePayFinalState: "direct",
              selectActivityId: activityTargetId,
              selectName: selectName,
            });
            this.setState({
              coursePayMode: "bargain",
            });
          } else {
            // 用户刚开始邀请
            amount =
              this.props.activity.price -
              this.props.invited_users_count * inviteRewardPrice;
            print("用户刚开始邀请: ", amount);
            this.showCourseCouponPay(
              amount,
              "direct",
              "",
              selectName,
              "bargin",
              null,
              amount == 0 ? "none" : "block"
            );
            // this.courseCoupon.setState({
            //   // "免费领取", 不需要弹“订单确认”, 直接领取即可
            //   isCouseCouponDisplay: amount == 0 ? 'none' : 'block',
            //   orderGuideDisplay: 'block',
            //   coursePayFinalPrice: amount,
            //   coursePayFinalState: 'direct',
            //   selectName: selectName
            // })
            // this.setState({
            //   coursePayMode: 'bargain'
            // })
          }
        } else {
          amount = this.props.price;
          print("直接购买");

          if (this.props.need_redirect_by_ecoupon === true) {
            this.setState({
              isAlertSoleCoupon: true,
            });
          } else {
            print("普通模式or限量, 直接购买");
            // 显示优惠券功能  普通模式直接购买
            // 注: 如果之前没购买过, 则弹框支付
            !this.props.pre_has_bought &&
              this.showCourseCouponPay(
                amount,
                "direct",
                "",
                selectName,
                "direct"
              );
            // this.courseCoupon.setState({
            //   isCouseCouponDisplay: 'block',
            //   orderGuideDisplay: 'block',
            //   coursePayFinalPrice: amount,
            //   coursePayFinalState: 'direct',
            //   selectName: selectName
            // })
            // this.setState({
            //   coursePayMode: 'direct'
            // })
          }
        }
        if (this.props.invited_users_count) {
          if (
            this.props.invited_users_count >= this.props.invite_count_required
          ) {
            amount = 0;
            print("是这里了");
            this.courseCoupon?.setState({
              isCouseCouponDisplay: "none",
              orderGuideDisplay: "none",
            });
          }
        }
      }

      const freeAmount =
        Number(amount) - Number(this.props.virtual_account?.money ?? 0);
      const freePayAmount = freeAmount <= 0 ? 0 : freeAmount;

      print("最终显示要支付的砍价金额amount：", freePayAmount);
      // 现在所有涉及到支付相关的，都走courseCoupons.jsx
      return;
      if (freePayAmount == 0) {
        print("邀请到对应的用户人数，就走到免费领取这里（旧的模式）");
        this.withoutCharge({ source: "free" });
        return;
      }
      let defaultBuyParams = {
        amount: amount,
        id: this.props.activity ? this.props.activity.id : "",
      };
      if (activityTargetId !== undefined) {
        defaultBuyParams = Object.assign({}, defaultBuyParams, {
          target_id: activityTargetId,
        });
      }
      print(defaultBuyParams);
      // 暂时先注释 直接支付, 现在一律走优惠券入口.....
      // this.buy(
      //   this.props.buy_url ? this.props.buy_url : this.props.study_package_activity_buy_url, defaultBuyParams
      // )
    }
  };

  withoutCharge = (params) => {
    console.log("StudyPackageActivity_withoutCharge: ", params);
    const toast = ToastManager.showLoading("正在免费领取中 请耐心等待");
    request({
      url: this.props.free_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.study_package_activity_id,
        source: params["source"],
      },
    })
      .then((resp) => {
        consttoast.cancel();
        print(resp.data);
        if (resp.data.err_code) {
          window.location.href = resp.data.redirect_url;
        } else {
          ToastManager.showToastText("领取成功", 2000);
          if (this.props.course_type === "poetry") {
            window.location.reload(true);
            return;
          }
          window.location.href = this.props.note_url;
        }
      })
      .catch((err) => {
        toast.cancel();
        print(err);
      });
  };

  // 仅针对邀请模式的免费领取
  inviteWithoutCharge = () => {
    const toast = ToastManager.showLoading("正在免费领取中 请耐心等待");
    request({
      url: this.props.free_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: this.props.study_package_activity_id,
        source: "free",
      },
    })
      .then((resp) => {
        toast.cancel();
        print(resp.data);
        if (resp.data.err_code) {
          window.location.href = resp.data.redirect_url;
        } else {
          ToastManager.showToastText("领取成功", 2000);
          if (this.props.has_teaching_aid_package) {
            // 如果教具包存在 就跳到地址填写页
            window.location.href = this.props.gift_commodities_url;
            return;
          }

          if (resp.data.redirect_url) {
            window.location.href = resp.data.redirect_url;
            return;
          }

          if (this.props.note_url) {
            window.location.href = this.props.note_url;
            return;
          }
          window.location.reload(true);
        }
      })
      .catch((err) => {
        toast.cancel();
        print(err);
      });
  };

  calcBargainDiscount = (activity, index) => {
    // const { subStudyPackageActivities } = this.props;
    // const filterOwnedCountArray = subStudyPackageActivities.filter(
    //   sub_activity => sub_activity.owned_media_lesson_count > 0
    // );
    // if (filterOwnedCountArray.length == 0) return;
    let loopGetDiscountMinusPrice = [];
    loopGetDiscountMinusPrice.push({
      discountPrice: calcDiscountPercentPrice(
        this.props.sub_study_package_activities,
        activity.max_price,
        activity.base_price,
        index
      ),
      listPrice: activity.max_price,
    });
    let sortDescendingArray = loopGetDiscountMinusPrice.sort(function (a, b) {
      return a.discountPrice - b.discountPrice;
    });
    const lastSubDescendingArray =
      sortDescendingArray[sortDescendingArray.length - 1];
    const calcSortLastDerateValue =
      lastSubDescendingArray.listPrice - lastSubDescendingArray.discountPrice;
    return {
      calcSortLastDerateValue, // 计算可被抵扣的价格（原价 - 抵扣价）
    };
  };

  // 不是拼团，是直接购买 / 砍价状态
  //新用户非团购购买，比如直接购买 / 砍价状态
  hasNotGroupon = () => {
    const end_time = this.state.end_time; //邀请结束时间（海报生成时间 + 24.hours)
    const finalPrice = this.state.finalPrice;
    const system_time = this.props.system_time;
    let now = new Date(system_time);
    let buyEndTime = new Date(this.props.buy_endtime);
    let inviteRewardPrice = this.props.money_per_user;
    let activitySignClassName = `activitiy_sign_in 111 ${
      beforeSale(this.props) ? "activity_sign_end" : ""
    }`;
    const isBuyBargainRemainCount =
      this.props.sale_mode === "buy_bargain" && this.props.remaining_count <= 0;

    const sysTimeOverBuyEndTime =
      new Date(this.props.system_time) > new Date(this.props.buy_endtime);

    // print("finalPrice: ", finalPrice)
    const phaseAndDayUserRemaining = () => {
      print("in phaseAndDayUserRemaining function");
      print("this.props.buy_endtime:", this.props.buy_endtime);
      print("now:", now);
      print("buyEndTime:", buyEndTime);
      if (now - buyEndTime > 0) {
        print("过期了。。。");
        return (
          <div className="activitiy_sign_in activity_sign_end">等待下期</div>
        );
      }

      if (
        this.props.day_limit_users_count &&
        this.props.day_remaining_count > 0
      ) {
        return (
          <div
            className={activitySignClassName}
            onClick={
              this.props.sub_study_package_activities
                ? this.signCourseClick
                : this.directBuyCourse
            }
          >
            <ActivitySignIn {...this.props} />
            {/* 普通模式 limit_users_count 不为0*/}
          </div>
        );
      }

      if (
        this.props.day_limit_users_count &&
        this.props.day_remaining_count <= 0
      ) {
        return (
          <div className="activitiy_sign_in activity_sign_end">
            <ActivitySignIn {...this.props} />
          </div>
        );
      }

      if (this.props.limit_users_count && this.props.remaining_count) {
        return (
          <div
            className={activitySignClassName}
            onClick={
              this.props.sub_study_package_activities
                ? this.signCourseClick
                : this.directBuyCourse
            }
          >
            <ActivitySignIn {...this.props} />
            {/* 普通模式 limit_users_count 不为0*/}
          </div>
        );
      }

      if (this.props.limit_users_count && this.props.remaining_count <= 0) {
        return (
          <div className="activitiy_sign_in activity_sign_end">
            <ActivitySignIn {...this.props} />
          </div>
        );
      }
    };

    const countdownTimeBar = () => {
      if (end_time) {
        // 砍价海报邀请结束时间
        if (
          this.props.activity.price -
            this.props.invited_users_count * inviteRewardPrice <=
          this.props.activity.lowest_price
        ) {
          return (
            <CountDownTime
              endTime={new Date(this.props.buy_endtime)}
              overText="报名已结束"
              onEnd={this.handleCountdownEnd}
            >
              {({ d, h, m, s }) => {
                return (new Date(this.props.buy_endtime).getTime() -
                  new Date(this.props.system_time).getTime()) /
                  1000 /
                  60 /
                  60 >
                  this.props.bargain_duration_hours ? (
                  <span>
                    报名截止时间：{d}天 {h}:{m}:{s}
                  </span>
                ) : (
                  <span>
                    报名截止时间：{h}:{m}:{s}
                  </span>
                );
              }}
            </CountDownTime>
          );
        } else {
          return this.props.sale_mode == "bargain" ? (
            <CountDownTime
              endTime={
                new Date(this.props.buy_endtime) > new Date(end_time)
                  ? new Date(end_time)
                  : new Date(this.props.buy_endtime)
              }
              overText="邀请已结束"
              onEnd={this.handleCountdownEnd}
            >
              {({ d, h, m, s }) => {
                return (new Date(this.props.buy_endtime).getTime() -
                  new Date(this.props.system_time).getTime()) /
                  1000 /
                  60 /
                  60 >
                  this.props.bargain_duration_hours ? (
                  <span>
                    剩余邀请时间：{h}:{m}:{s}
                  </span>
                ) : (
                  <span>
                    剩余邀请时间：{h}:{m}:{s}
                  </span>
                );
              }}
            </CountDownTime>
          ) : (
            <React.Fragment>
              <div className="before_count_down_buy">
                <CountDownTime
                  endTime={new Date(this.props.buy_endtime)}
                  overText="活动已结束"
                  onEnd={this.handleCountdownEnd}
                >
                  {({ d, h, m, s }) => {
                    return (
                      <span>
                        距离活动结束:
                        <br />
                        {d}天 {h}:{m}:{s}
                      </span>
                    );
                  }}
                </CountDownTime>
              </div>
              <span
                className="before_sign_text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                已邀请{this.props.invited_users_count}
                人，再邀请
                {this.props.invite_count_required -
                  this.props.invited_users_count}
                人即可免费领取。
              </span>
            </React.Fragment>
          );
        }
      } else {
        let beforeCountDownBuy = "";
        let condition = false;
        if (this.props.sale_mode === "bargain") {
          if (
            "invited_users_count" in this.props &&
            !this.props.invite_count_required
          ) {
            condition = true;
          }
        } else if (this.props.sale_mode === "buy_bargain") {
          if (
            "invited_users_count" in this.props &&
            this.props.invite_count_required > 0
          ) {
            condition = true;
          }
        } else if (this.props.sale_mode === "invite") {
          if (
            "invite_count_required" in this.props &&
            this.props.invited_users_count < this.props.invite_count_required
          ) {
            condition = true;
          }
        }
        if (condition) {
          beforeCountDownBuy = (
            <div className="before_count_down_buy">
              <CountDownTime
                endTime={new Date(this.props.buy_endtime)}
                overText="活动已结束"
                onEnd={this.handleCountdownEnd}
              >
                {({ d, h, m, s }) => {
                  return (
                    <span>
                      {this.props.sale_mode === "buy_bargain"
                        ? "距离活动结束:"
                        : "距离报名截止:"}
                      <br />
                      {d}天 {h}:{m}:{s}
                    </span>
                  );
                }}
              </CountDownTime>
            </div>
          );
        }

        // 砍价是xx小时内，邀请xx人，可以一直邀请
        // 邀请是邀请xx人，不可以一直邀请
        let beforeSignHtml = "";
        if (this.props.sale_mode === "bargain") {
          beforeSignHtml = (
            <span className="before_sign_text">
              {this.bargainCountDownTime(inviteRewardPrice)}
            </span>
          );
        } else if (
          this.props.sale_mode === "invite" ||
          this.props.sale_mode === "buy_bargain"
        ) {
          if (this.props.invited_users_count <= 0) {
            if (this.props.sale_mode === "buy_bargain") {
              beforeSignHtml = (
                <span className="before_sign_text">
                  {this.buyBargainPromptText()}
                </span>
              );
            } else {
              beforeSignHtml = (
                <span className="before_sign_text">
                  邀请{this.props.invite_count_required}
                  位新朋友关注『心芽学堂』公众号即可免费报名
                </span>
              );
            }
          } else if (
            this.props.invited_users_count < this.props.invite_count_required
          ) {
            beforeSignHtml = (
              <span className="before_sign_text">
                已邀请{this.props.invited_users_count}
                人，再邀请
                {this.props.invite_count_required -
                  this.props.invited_users_count}
                人即可免费报名。
              </span>
            );
          } else {
            beforeSignHtml = (
              <span
                className="before_sign_text"
                style={{
                  lineHeight: "38px",
                  paddingLeft: "10px",
                  marginRight: "0",
                  borderRight: "none",
                }}
              >
                已邀请{this.props.invite_count_required}
                人，获得免费课程资格，请立即领取。
              </span>
            );
          }
        }
        return (
          <div>
            {beforeCountDownBuy}
            {beforeSignHtml}
          </div>
        );
      }
    };

    const headerBar = () => {
      print("headerBar beforeSale: " + beforeSale(this.props));
      if (beforeSale(this.props)) {
        let headHtml = beforeSaleHeader(this.props);
        return headHtml;
      }

      let inviteRecordHtml = "";
      if (end_time) {
        if (this.props.sale_mode === "bargain") {
          inviteRecordHtml = (
            <div className="invite_count_record">
              已邀请{this.props.invited_users_count}人
            </div>
          );
        }
        // else {
        //   inviteRecordHtml = (
        //     <div className="before_count_down_buy">
        //       <CountDownTime
        //         endTime={new Date(this.props.buy_endtime)}
        //         overText="活动已结束"
        //         onEnd={this.handleCountdownEnd}
        //       >
        //         {({ d, h, m, s }) => {
        //           return (
        //             <span>
        //               距离活动结束:
        //               <br />
        //               {d}天 {h}:{m}:{s}
        //             </span>
        //           );
        //         }}
        //       </CountDownTime>
        //     </div>
        //   );
        // }
      }
      return (
        <div>
          {
            inviteRecordHtml
            // end_time ?
            //     <div className="invite_count_record">
            //       已邀请{this.props.invited_users_count}人
            //     </div> : ''
          }
          <div
            className="invite_count_down"
            // style={{
            //   padding:
            //     this.props.invited_users_count >=
            //     this.props.invite_count_required
            //       ? "0"
            //       : ""
            // }}
          >
            {
              countdownTimeBar()
              // (
              //     end_time ?
              //         (
              //             (this.props.activity.price - this.props.invited_users_count * inviteRewardPrice) <= this.props.activity.lowest_price
              //                 ?
              //                 <CountDownTime endTime={new Date(this.props.buy_endtime)} overText="报名已结束" onEnd={this.handleCountdownEnd}>
              //                   {({ d, h, m, s }) => {
              //                     return (
              //                         ((new Date(this.props.buy_endtime).getTime() - (new Date(this.props.system_time).getTime())) / 1000 / 60 / 60) > this.props.bargain_duration_hours
              //                             ?
              //                             <span>报名截止时间：{d}天 {h}:{m}:{s}</span>
              //                             :
              //                             <span>报名截止时间：{h}:{m}:{s}</span>
              //                     )
              //                   }}
              //                 </CountDownTime>
              //                 :
              //                 <CountDownTime endTime={new Date(this.props.buy_endtime) > new Date(end_time) ? new Date(end_time) : new Date(this.props.buy_endtime)} overText="邀请已结束" onEnd={this.handleCountdownEnd}>
              //                   {({ d, h, m, s }) => {
              //                     return (
              //                         ((new Date(this.props.buy_endtime).getTime() - (new Date(this.props.system_time).getTime())) / 1000 / 60 / 60) > this.props.bargain_duration_hours
              //                             ?
              //                             <span>剩余邀请时间：{h}:{m}:{s}</span>
              //                             :
              //                             <span>剩余邀请时间：{h}:{m}:{s}</span>
              //                     )
              //                   }}
              //                 </CountDownTime>
              //         )
              //         :
              //         (
              //             <div>
              //               {
              //                 this.props.invited_users_count >= this.props.invite_count_required ? '' :
              //                     <div className="before_count_down_buy">
              //                       <CountDownTime endTime={new Date(this.props.buy_endtime)} overText="活动已结束" onEnd={this.handleCountdownEnd}>
              //                         {({ d, h, m, s }) => {
              //                           return (<span>距离报名截止:<br />{d}天 {h}:{m}:{s}</span>)
              //                         }}
              //                       </CountDownTime>
              //                     </div>
              //               }
              //
              //               {
              //                 this.props.sale_mode === "bargain"
              //                     ?
              //                     <span className="before_sign_text">
              //                 {this.bargainCountDownTime(inviteRewardPrice)}
              //               </span>
              //                     :
              //                     (
              //                         this.props.invited_users_count > 0
              //                             ?
              //                             (
              //                                 this.props.invited_users_count < this.props.invite_count_required ?
              //                                     <span className="before_sign_text">
              //                         已邀请{this.props.invited_users_count}人，再邀请{this.props.invite_count_required - this.props.invited_users_count}人即可免费报名。
              //             </span>
              //                                     :
              //                                     <span
              //                                         className="before_sign_text"
              //                                         style={{ lineHeight: '38px', paddingLeft: '10px', marginRight: '0', borderRight: 'none' }}
              //                                     >
              //                         已邀请{this.props.invite_count_required}人，获得免费课程资格，请立即领取。
              //             </span>
              //                             )
              //                             :
              //                             <span className="before_sign_text">
              //                     邀请{this.props.invite_count_required}位新朋友关注『心芽学堂』公众号即可免费报名
              //           </span>
              //                     )
              //               }
              //             </div>
              //         )
              // )
            }
          </div>
        </div>
      );
    };

    const startInvited = () => {
      if (this.props.sale_mode === "normal") {
        return "";
      }

      if (
        this.props.sale_mode === "invite" &&
        this.props.invite_count_required <= this.props.invited_users_count
      ) {
        return "";
      }

      if (beforeSale(this.props)) {
        return "";
      }

      if (this.props.study_package_group_state !== "ok") {
        return "";
      }

      if (this.props.sale_mode === "buy_bargain") {
        // 购买模式，所售份额已经全部卖完
        const isBuyBargainRemainCount = this.props.remaining_count <= 0;

        // 总价格减去邀请相应的人数奖励的价格值
        const calcInviteBuyBargainPrice =
          (this.props.activity ? this.props.activity.price : this.props.price) -
          this.props.invited_users_count * inviteRewardPrice;

        const bargainPriceValue =
          calcInviteBuyBargainPrice > 0 ? calcInviteBuyBargainPrice : 0;

        console.log("bargainPriceValue: ", bargainPriceValue);
        console.log("isBuyBargainRemainCount: ", isBuyBargainRemainCount);

        if (isBuyBargainRemainCount || bargainPriceValue == 0) {
          return "";
        }
      }

      console.log("startInvited_finalPrice: ", finalPrice);
      console.log("startInvited_end_time: ", end_time);
      if (finalPrice) {
        if (finalPrice != 0) {
          let text = "";
          if (end_time) {
            if (this.props.overall_bought !== true) {
              if (
                this.props.activity.price -
                  this.props.invited_users_count * inviteRewardPrice >
                this.props.activity.lowest_price
              ) {
                text = "继续邀请";
              }
            }
          } else {
            if (this.props.overall_bought !== true) {
              text = "开始邀请";
            }
          }
          console.log("2953_text", text);
          return text ? (
            <div
              className="free_experience"
              onClick={this.invitingUser}
              style={{
                display: this.state.inviteHideDisplay,
              }}
            >
              {text}
            </div>
          ) : (
            ""
          );
        }
      } else {
        let text = "";
        if (this.props.invited_users_count <= 0) {
          text = "开始邀请";
        } else if (
          this.props.invited_users_count < this.props.invite_count_required
        ) {
          text = "继续邀请";
        }
        console.log("2975_text", text);
        return text ? (
          <div
            className="free_experience"
            onClick={this.invitingUser}
            style={{
              display: this.state.inviteHideDisplay,
            }}
          >
            {
              // "免费邀请"模式
              text
            }
          </div>
        ) : (
          ""
        );
      }
    };

    const buyNow = () => {
      console.log("buyNow");
      const calcCourseEndTime =
        new Date(this.props.system_time) > new Date(this.props.buy_endtime);
      if (
        this.props.limit_users_count != 0 ||
        this.props.day_limit_users_count != 0
      ) {
        if (
          this.props.max_count_exceed ||
          this.props.remaining_count <= 0 ||
          calcCourseEndTime
        ) {
          return (
            <div className="activitiy_sign_in activity_sign_end">等待下期</div>
          );
        } else {
          if (this.props.sale_mode === "normal") {
            return phaseAndDayUserRemaining();
          } else {
            let textButton = "";
            let onClick;
            console.log("finalPrice_3116: ", finalPrice);
            if (
              this.props.sale_mode == "bargain" ||
              this.props.sale_mode == "buy_bargain"
            ) {
              if (finalPrice >= 0) {
                if (finalPrice == 0) {
                  print("enter_1_finalPrice == 0");
                  // onClick = this.alertBargainBuy;
                  onClick = this.inviteWithoutCharge;
                  textButton = "免费领取";
                } else {
                  print("enter_2_finalPrice !== 0");
                  if (end_time) {
                    if (new Date(end_time) < new Date(system_time)) {
                      // onClick = this.directBuyCourse;
                      onClick = this.alertBargainBuy;
                    } else {
                      if (
                        this.props.activity.price -
                          this.props.invited_users_count * inviteRewardPrice <=
                        this.props.activity.lowest_price
                      ) {
                        // onClick = this.directBuyCourse;
                        // onClick = this.alertBargainBuy;
                        // TODO:
                        onClick = this.inviteWithoutCharge;
                        textButton = "免费领取";
                      } else {
                        onClick = this.alertBargainBuy;
                      }
                    }
                  } else {
                    onClick = this.alertBargainBuy;
                  }
                }
              }
            } else if (this.props.sale_mode == "invite") {
              console.log("免费邀请_3147: ", this.props.invited_users_count);
              // “免费邀请”模式
              if (
                this.props.invited_users_count <
                this.props.invite_count_required
              ) {
                onClick = this.alertBargainBuy;
              } else {
                onClick = this.inviteWithoutCharge;
              }
            }
            return (
              <div className={`${activitySignClassName}`} onClick={onClick}>
                {textButton ? textButton : <ActivitySignIn {...this.props} />}
              </div>
            );
          }
        }
      } else {
        if (this.props.bought) {
          return <div className="activitiy_sign_in">报名成功</div>;
        }
        // 销售时间截止
        print("销售时间截止");
        const isBuyBargainRemainCount =
          this.props.sale_mode === "buy_bargain" &&
          "remaining_count" in this.props &&
          "day_remaining_count" in this.props &&
          this.props.remaining_count <= 0;

        if (
          new Date(this.props.system_time) > new Date(this.props.buy_endtime) ||
          isBuyBargainRemainCount
        ) {
          return (
            <div>
              <div className="activitiy_sign_in activity_sign_end">
                等待下期
              </div>
              {trialShow(
                this.state.trialActivities,
                this.props.study_package_activity?.id
              )}
            </div>
          );
        }

        if (this.props.sale_mode === "normal") {
          return (
            // class: activity_discount_icon 活动6.5折扣角标
            <div
              className={`${activitySignClassName} ${
                this.props.sub_course_type != "reading" ? "" : ""
              }`}
              onClick={
                this.props.sub_study_package_activities
                  ? this.signCourseClick
                  : this.directBuyCourse
              }
            >
              <ActivitySignIn {...this.props} />
              {/* 普通模式 */}
            </div>
          );
        } else {
          let onClick = null;
          let text = "";
          if (
            this.props.sale_mode == "bargain" ||
            this.props.sale_mode == "buy_bargain"
          ) {
            // "砍价" / "购买砍价" 模式
            if (finalPrice === 0) {
              // onClick = this.directBuyCourse;
              onClick = this.inviteWithoutCharge;
              text = "免费领取";
            } else {
              // "砍价"模式
              if (end_time) {
                // end_time 24小时邀请时间已结束
                if (new Date(end_time) < new Date(system_time)) {
                  if (this.props.sub_study_package_activities) {
                    onClick = this.signCourseClick;
                    text = "立即报名";
                  } else {
                    onClick = this.directBuyCourse;
                    text = "立即报名";
                  }
                } else {
                  // end_time 24小时邀请时间正在继续
                  if (this.props.sub_study_package_activities) {
                    onClick = this.signCourseClick;
                    text = "立即报名";
                  } else {
                    if (
                      this.props.activity.price -
                        this.props.invited_users_count * inviteRewardPrice <=
                      this.props.activity.lowest_price
                    ) {
                      // onClick = this.directBuyCourse;
                      onClick = this.inviteWithoutCharge;
                      text = "免费领取";
                    } else {
                      onClick = this.alertBargainBuy;
                      text = "立即报名";
                    }
                  }
                }
              } else {
                if (this.props.sub_study_package_activities) {
                  onClick = this.signCourseClick;
                  text = "立即报名";
                } else {
                  if (
                    this.props.activity.price -
                      this.props.invited_users_count * inviteRewardPrice <=
                    this.props.activity.lowest_price
                  ) {
                    // onClick = this.directBuyCourse;
                    onClick = this.inviteWithoutCharge;
                    text = "免费领取";
                  } else {
                    onClick = this.alertBargainBuy;
                    text = "立即报名";
                  }
                }
              }
            }
          } else {
            if (this.props.sale_mode == "invite") {
              console.log("“免费邀请”模式", this.props.invited_users_count);
              // “免费邀请”模式
              if (
                this.props.invited_users_count <
                this.props.invite_count_required
              ) {
                onClick = this.alertBargainBuy;
                text = "立即报名";
              } else {
                // onClick = this.directBuyCourse;
                onClick = this.inviteWithoutCharge;
                text = "免费领取";
              }
            }
          }
          return (
            <div className={activitySignClassName} onClick={onClick}>
              {text}
            </div>
          );
        }
      }
    };

    // `${this.state.sort_max_price}` replace `${this.props.activity.price}`
    let lastBargainPrice;
    if (
      new Date(this.props.system_time) > new Date(this.props.buy_endtime) ||
      (this.props.remaining_count <= 0 && this.props.limit_users_count > 0)
    ) {
      lastBargainPrice = this.state.sort_max_price;
    } else {
      if (this.props.activity) {
        lastBargainPrice =
          this.props.activity.price -
          this.props.invited_users_count * inviteRewardPrice;
      } else {
        lastBargainPrice = this.state.sort_max_price;
      }
    }

    // 砍价模式单课程减去已有课程的抵扣价
    if (this.props.sub_study_package_activities?.length === 1) {
      lastBargainPrice =
        lastBargainPrice -
        this.calcBargainDiscount(this.props.sub_study_package_activities[0], 0)
          .calcSortLastDerateValue;
    }

    console.log("lastBargainPrice: ", lastBargainPrice);

    // 购买砍价模式，剩余x份
    const justRemainAmount = () => {
      const { remaining_count, limit_users_count } = this.props;
      // const isBuyBargainRemainCount = this.props.remaining_count <= 0;
      // const sysTimeOverBuyEndTime =
      //   new Date(this.props.system_time) > new Date(this.props.buy_endtime);

      // if (this.props.sale_mode !== "buy_bargain") {
      //   return "";
      // }

      // if (isBuyBargainRemainCount || sysTimeOverBuyEndTime) {
      //   return "";
      // }

      // if (
      //   this.props.sale_mode === "buy_bargain" &&
      //   this.props.remaining_count > 0
      // ) {
      return limit_users_count > 0 ? (
        <span style={{ color: "#666", fontSize: "13px" }}>
          剩余{remaining_count > 0 ? remaining_count : 0}份
        </span>
      ) : (
        ""
      );
      // }
    };
    const {
      minPrice,
      maxPrice,
      listPrice,
      isSeries,
    } = this.showActivityPrice.call(this);

    const noShowExperienceButton =
      this.props.trial_activity || this.state.trialActivities.length > 0;
    return (
      <div className="has_not_groupon">
        {this.props.sale_mode === "normal" ? (
          beforeSale(this.props) &&
          this.props.limit_users_count === 0 &&
          this.props.day_limit_users_count === 0 ? (
            beforeSaleHeader(this.props)
          ) : (
            ""
          )
        ) : // 非普通模式（邀请/砍价/购买砍价）, 普通模式下空字符串
        sysTimeOverBuyEndTime || isBuyBargainRemainCount ? (
          <div className="invite_count_down">
            <span className="current_sign_end">本期报名已结束</span>
          </div>
        ) : (
          headerBar()
        )}

        <div className="activity_box_foot">
          {
            // 计算体验课正常模式，定时开售，限量
            // 每日限量的优先级要高于总限量的优先级
            // this.props.day_limit_users_count > 0
            //   ?
            //   this.dayLimitedBargin()
            //   :
            //   (
            //     this.props.limit_users_count != 0 && this.showLimitedUser()
            //   )
            this.showLimitedUser()
          }
          <div className="activity_box_bg clearfix">
            <div className="activitiy_price">
              {this.props.activity ? (
                end_time ? (
                  this.props.sub_study_package_activities ? (
                    <p className="price">
                      {!isSeries ? (
                        <>
                          {`¥${lastBargainPrice > 0 ? lastBargainPrice : 0}`}
                          {listPrice && (
                            <span>
                              <del>¥{listPrice}</del>
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {`￥${maxPrice}`}
                          {this.props.sale_mode == "normal" &&
                          this.props.sub_study_package_activities.length <=
                            1 ? (
                            ""
                          ) : (
                            <span className="text_min">起</span>
                          )}
                        </>
                      )}
                      {justRemainAmount()}
                    </p>
                  ) : (
                    <p className="price">
                      ¥{finalPrice}
                      {listPrice && (
                        <span style={{ fontWeight: "normal" }}>
                          <del>¥{listPrice}</del>
                        </span>
                      )}
                      {/* <span className="origin_price">
                        &nbsp;&nbsp;原价：<del>¥{this.props.list_price}</del>
                      </span> */}
                    </p>
                  )
                ) : this.props.sub_study_package_activities ? (
                  <p className="price">
                    {!isSeries ? (
                      <>
                        {`¥${lastBargainPrice > 0 ? lastBargainPrice : 0}`}
                        {listPrice > 0 && (
                          <span
                            style={{
                              fontWeight: "normal",
                            }}
                          >
                            <del>¥{listPrice}</del>
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {`￥${maxPrice}`}
                        <span className="text_min">起</span>
                      </>
                    )}
                    {justRemainAmount()}
                  </p>
                ) : (
                  <PriceTextShow
                    // price={maxPrice}
                    price={`${
                      calcActivityLowestPrice(
                        this.props.sub_study_package_activities
                      )[0].discountPrice
                    }`}
                    isExist={this.state.isExistOweMediaCountObject}
                    subActivities={this.props.sub_study_package_activities}
                    saleMode={this.props.sale_mode}
                  />
                )
              ) : !isSeries ? (
                singleCourseJudge(this.props.sub_study_package_activities) ? (
                  <PriceTextShow
                    price={`0`}
                    isExist={this.state.isExistOweMediaCountObject}
                    subActivities={this.props.sub_study_package_activities}
                    saleMode={this.props.sale_mode}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <PriceTextShow
                      price={`${
                        calcActivityLowestPrice(
                          this.props.sub_study_package_activities
                        )[0].discountPrice
                      }`}
                      // price={maxPrice}
                      isExist={this.state.isExistOweMediaCountObject}
                      subActivities={this.props.sub_study_package_activities}
                      saleMode={this.props.sale_mode}
                    />
                    {listPrice > 0 && (
                      <span
                        style={{
                          fontWeight: "normal",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        <del>¥{listPrice}</del>
                      </span>
                    )}
                  </div>
                )
              ) : (
                // 多课程
                <PriceTextShow
                  // price={maxPrice}
                  price={`${
                    calcActivityLowestPrice(
                      this.props.sub_study_package_activities
                    )[0].discountPrice
                  }`}
                  isExist={this.state.isExistOweMediaCountObject}
                  subActivities={this.props.sub_study_package_activities}
                  saleMode={this.props.sale_mode}
                />
              )}
              <div className="price_subscribe">{this.props.refund_policy}</div>
            </div>

            {
              //立即报名
              buyNow()
            }
            {
              // 开始/继续 邀请
              startInvited()
            }
            {
              // (this.props.sale_mode === "normal" && (new Date(this.props.buy_endtime) > new Date(this.props.system_time))) &&
              // <div className="free_experience" style={{ fontSize: "15px" }} onClick={this.inviteFriendDrawCash.bind(null, false)}>免费领</div>
            }
            {
              //免费试听
              trialShow(
                this.state.trialActivities,
                this.props.study_package_activity?.id
              )
            }
            {/* {
              // 体验课·唤醒入口
              showExperienceButton
            } */}

            {this.props.sale_mode === "normal" &&
              !noShowExperienceButton &&
              findExperienceCourse(this.props.sub_study_package_activities)
                .length > 0 && (
                <ActivityExperienceButton
                  sub_course_type={this.props.sub_course_type}
                  activities={this.props.sub_study_package_activities}
                  handleExperienceCourse={this.handleExperienceCourse}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  // 每期和每日限量
  showLimitedUser() {
    if (this.props.sale_mode !== "normal") {
      return "";
    }
    let expired =
      new Date(this.props.system_time) > new Date(this.props.buy_endtime);
    if (expired) {
      return "";
    }
    if (this.props.day_limit_users_count > 0) {
      return this.dayLimitedBargin();
    } else if (this.props.limit_users_count > 0) {
      if (beforeSale(this.props)) {
        return beforeSaleHeader(this.props);
      } else {
        return this.limitedBargain();
      }
    }
  }

  // 砍价活动 👆👆👆 End
  limitedBargain() {
    return (
      <div className="activitiy_amount">
        {this.props.limit_users_count != 0 &&
          (!this.props.max_count_exceed ? (
            <span>
              本期课程，仅剩 {this.props.remaining_count} 位，报满即止！
            </span>
          ) : (
            <span>本期名额已满</span>
          ))}
      </div>
    );
  }

  dayLimitedBargin() {
    let startDateStr = formatHourMinute(this.props.buy_starttime);
    let date = new Date(this.props.buy_starttime);
    let now = new Date();
    let todayStart = new Date();
    todayStart.setHours(date.getHours());
    todayStart.setMinutes(date.getMinutes());

    return (
      <div className="activitiy_amount">
        {"day_remaining_count" in this.props &&
        "day_remaining_count" in this.props &&
        this.props.day_remaining_count > 0 ? (
          now - todayStart < 0 ? (
            <span>
              将于每天{startDateStr}开始报名，限量
              {this.props.day_limit_users_count}份
            </span>
          ) : (
            <span>仅剩 {this.props.day_remaining_count} 位，报满即止！</span>
          )
        ) : (
          <span>今日名额已满，请记得明天{startDateStr}来抢报哦~</span>
        )}
      </div>
    );
  }

  showJoinGrouponUser() {
    if (
      !this.props.shopping_group &&
      this.props.buy_endtime &&
      (new Date(this.props.buy_endtime) - new Date()) / (60 * 60 * 24 * 1000) <
        7 &&
      !this.props.bought
    ) {
      return (
        <div className="activitiy_amount">
          <CountDownTime
            endTime={new Date(this.props.buy_endtime)}
            overText="活动已结束"
            onEnd={this.handleCountdownEnd}
          >
            {({ d, h, m, s }) => {
              return (
                <span>
                  距离报名截止：{d}天 {h}:{m}:{s}
                </span>
              );
            }}
          </CountDownTime>
        </div>
      );
    }
    if (
      (!this.props.shopping_group ||
        !this.props.shopping_group.user_shopping_groups) &&
      !this.props.bought
    ) {
      return;
    }
    var users = [];
    if (this.props.bought && this.props.complete_users) {
      for (let i = 0; i < this.props.group_max_count; i++) {
        const completeUser = this.props.complete_users[i];
        if (completeUser) {
          users.push(completeUser.photo_url);
        } else {
          users.push(icon_group_bg);
        }
      }
    } else if (this.props.shopping_group.user_shopping_groups.length) {
      for (let i = 0; i < this.props.group_max_count; i++) {
        const user_shopping_group = this.props.shopping_group
          .user_shopping_groups[i];
        if (user_shopping_group) {
          users.push(user_shopping_group.user.photo_url);
        } else {
          users.push(icon_group_bg);
        }
      }
    }
    // else {
    //   users = this.props.shopping_group.user_shopping_groups.map((user_shopping_group) =>
    //     user_shopping_group.user.photo_url
    //   )
    // }
    return (
      <div className="group_head_amount">
        {!this.props.bought &&
        (new Date(this.props.buy_endtime) - new Date()) /
          (60 * 60 * 24 * 1000) <
          7 ? (
          <CountDownTime
            endTime={new Date(this.props.buy_endtime)}
            overText="活动已结束"
            onEnd={this.handleCountdownEnd}
          >
            {({ d, h, m, s }) => {
              return (
                <span>
                  结束倒计时：{d}天 {h}:{m}:{s}
                </span>
              );
            }}
          </CountDownTime>
        ) : this.props.bought ? (
          <span>报名成功！</span>
        ) : (
          ""
        )}
        <div>
          {users.map((photo_url, index) => (
            <img
              className="lazyload"
              data-src={photo_url}
              data-sizes="auto"
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }

  // 补差价 延迟报名放宽至24小时内
  showPayRemainingTime() {
    return (
      <div className="group_pay_remaining">
        <CountDownTime
          endTime={new Date(this.props.pay_remaining_endtime)}
          overText="活动已结束"
          onEnd={this.handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                补差价倒计时：{d}天 {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      </div>
    );
  }

  handleCountdownEnd = (e) => {
    if (this.props.can_pay_remaining) {
      this.setState({
        can_pay_remaining: this.props.can_pay_remaining,
      });
    } else {
      this.setState({
        isNotExpirdOfGroupon: false,
      });
    }
  };

  //邀请领取奖励
  inviteFriendDrawCash = (bool) => {
    print("InviteFriendDrawCh_bool: ", bool);

    if (this.props.sale_mode === "invite") {
      print("sale_mode：invite");
      GA("Courses", "Share", this.props.study_package_activity_id);
    }

    if (this.props.bonus > 0) {
      GA("Courses", "shareNormal", this.props.study_package_activity_id);
    } else {
      GA("Courses", "Share", this.props.study_package_activity_id);
    }

    let saleModeResult = "";
    switch (this.props.sale_mode) {
      case "normal":
        saleModeResult = "collect_zan_free";
        break;
      case "shopping_group":
        saleModeResult = "study_package";
        break;
      case "invite":
        saleModeResult = "invite_mode_invite_friend";
        break;
      default:
        saleModeResult = "invite_draw_cash_or_share_friend";
        break;
    }

    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.is_parent_class === false) {
      if (this.props.qrcode_url != null) {
        // 如果不是家长微课，就检测是否关注公众号
        this.onWechatFollow();
      }
      // if (this.props.poster_url) {
      console.log(
        `this.props.poster_url ${this.props.poster_url}`,
        "mode: front_end"
      );
      if (bool === true) {
        /**
         * 当bool为true的时候，模式是右上角分享给好友的状态，这时候的海报样式统一走拼团模式的状态
         * free: "study_package"
         * sale_mode: "shopping_group"
         * */
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          posterTemplate: this.props.poster_photo_template_url,
          posterCreation: this.props.poster_creation,
          mode: "front_end",
          free: "study_package",
          bonus: this.props.bonus,
          bonus_type: this.props.bonus_type,
          sale_mode: "shopping_group",
          remaining_shopping_group_count: this.props
            .remaining_shopping_group_count,
          avatar_location: this.props.avatar_location,
        });
        this.sharePosterProxy.open();
      } else {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          posterTemplate: this.props.poster_photo_template_url,
          posterCreation: this.props.poster_creation,
          mode: "front_end",
          free: saleModeResult,
          bonus: this.props.bonus,
          invite_count_required: this.props.invite_count_required,
          avatar_location: this.props.avatar_location,
        });
        this.sharePosterProxy.open();
      }
      // }
    } else {
      /** mode:'front_end' 为前端产生海报,以下字段必须  
       getUserPhotoUrl:this.props.get_user_photo_url,

       posterQRCode 可选
       posterTemplate 
       posterUrl: this.props.poster_url,后端产生海报
       **/

      if (bool === true) {
        this.sharePosterProxy = new SharePosterProxy({
          posterTemplate: this.props.poster_photo_template_url,
          posterCreation: this.props.poster_creation,
          // mode: 'front_end',
          free: "study_package",
          bonus: this.props.bonus,
          bonus_type: this.props.bonus_type,
          sale_mode: "shopping_group",
          remaining_shopping_group_count: this.props
            .remaining_shopping_group_count,
          avatar_location: this.props.avatar_location,
        });
        this.sharePosterProxy.open();
      } else {
        this.sharePosterProxy = new SharePosterProxy({
          posterTemplate: this.props.poster_photo_template_url,
          posterCreation: this.props.poster_creation,
          // mode: 'front_end',
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          free: saleModeResult,
          bonus: this.props.bonus,
          invite_count_required: this.props.invite_count_required,
          avatar_location: this.props.avatar_location,
        });
        this.sharePosterProxy.open();
      }
    }
  };

  inviteFriend = (bool) => {
    // new ShareHintDialogProxy("点击右上角分享给朋友<br><span>邀请好友参加拼团</span>").open()
    if (this.props.sale_mode == "normal") {
      GA("Courses", "shareNormal", this.props.study_package_activity_id);
    } else {
      GA("Courses", "shareGroup", this.props.study_package_activity_id);
    }
    print("InviteFriend bool", bool);

    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterTemplate: this.props.poster_photo_template_url,
          posterUrl: this.props.poster_url,
          posterCreation: this.props.poster_creation,
          mode: "front_end",
          posterImgSrc: this.props.poster_img_src,
          free: this.props.free,
          bonus: this.props.bonus,
          bonus_type: this.props.bonus_type,
          sale_mode: bool ? this.props.sale_mode : null,
          remaining_shopping_group_count: bool
            ? this.props.remaining_shopping_group_count
            : null,
          has_teaching_aid_package: this.props.has_teaching_aid_package,
          avatar_location: this.props.avatar_location,
        });
      }
      this.sharePosterProxy.open();
    }
  };

  // 往期已购买用户进入报名页，点击邀请好友
  inviteFriendRecommend = (e) => {
    GA("Courses", "shareCourse", this.props.study_package_activity_id);
    if (this.props.poster_url) {
      // if (!this.sharePosterProxy) {
      this.sharePosterProxy = new SharePosterProxy({
        posterTemplate: this.props.poster_photo_template_url,
        posterCreation: this.props.poster_creation,
        mode: "front_end",
        posterUrl: this.props.poster_url,
        posterImgSrc: this.props.poster_img_src,
        free:
          this.props.sale_mode === "invite"
            ? "none_shopping_group"
            : this.props.free,
        bonus: this.props.bonus,
        avatar_location: this.props.avatar_location,
      });
      // }
      this.sharePosterProxy.open();
    }
  };

  //点击课程介绍
  onClickCourse = (e) => {
    this.setState({
      isCourseContainer: "block",
      activeSwitch1: "active",
      selectIndex1: -1,
      activeSwitch2: "",
      activeSwitch3: "",
    });

    // 针对分类/分级id来判断
    this.props.study_package_category_id === 18 &&
      this.setState({
        selectIndex: 0, // 临时的值
      });

    this.problemFoldContainer?.setState({
      isCommonProblemBox: "none",
    });

    this.props.comments.length > 0 &&
      this.isCourseContainer?.setState({
        isCourseEvaluateContainer: "none",
      });
  };

  // 分级子课程包事件
  onClickSubActivity = (e) => {
    e.preventDefault();
    let idx = e.currentTarget.getAttribute("data-index");
    let tab = e.currentTarget.getAttribute("data-tab");

    GA("Courses", tab, this.props.study_package_activity_id);

    this.setState({
      selectIndex1: idx,
      isCourseContainer: "none",
      activeSwitch1: "",
      activeSwitch3: "",
    });
    this.problemFoldContainer?.setState({
      isCommonProblemBox: "none",
    });

    this.props.comments.length > 0 &&
      this.isCourseContainer?.setState({
        isCourseEvaluateContainer: "none",
      });
  };

  //点击常见问题
  onClickProblem = (e) => {
    this.setState({
      isCourseContainer: "none",
      activeSwitch1: "",
      activeSwitch2: "active",
      activeSwitch3: "",
    });
    this.problemFoldContainer?.setState({
      isCommonProblemBox: "block",
    });
    this.props.comments.length > 0 &&
      this.isCourseContainer?.setState({
        isCourseEvaluateContainer: "none",
      });
  };

  //点击用户评价
  onClickEvalute = (e) => {
    var url = this.props.share_url;
    const {
      study_package_category_id,
      sub_study_package_activities,
    } = this.props;

    if (this.props.comments_url) {
      onNavigate(e, this.props.comments_url);
    }

    const share_url = url + "#comment";
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    const share_image_url = this.props.share_image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        link: share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
      wx.miniProgram.getEnv(function (res) {
        print(res.miniprogram); // true
        if (res.miniprogram) {
          var shareURL = share_url;
          if (!shareURL) {
            shareURL = window.location.href;
          }
          let share_string = convertURLToJSON(shareURL);
          wx.miniProgram.postMessage({
            data: {
              title: share_title,
              imgUrl: share_image_url,
              link: share_string,
            },
          });
        }
      });
    });
  };

  selectSubClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const activity_id = e.currentTarget.getAttribute("data-id");
    const max_value = e.currentTarget.getAttribute("data-max-value");
    const min_value = e.currentTarget.getAttribute("data-min-value");
    this.setState({
      selectIndex: index,
      selectActivityId: activity_id,
      selectMaxMoney: max_value,
      selectMinMoney: min_value,
    });
    this.subCourseDilog?.setState({
      isChooseDisplay: "block",
    });
  };

  // 课程开课日期·天数·课时
  // signPeriodStatus() {
  //   const {
  //     phase,
  //     start_date,
  //     course_day_count,
  //     media_lessons_count,
  //     study_package_activity,
  //     brief,
  //     is_spell,
  //   } = this.props;
  //   const instanceIsFalse =
  //     study_package_activity && !study_package_activity.instance;
  //   return (
  //     <div className="periods_time_box">
  //       <div className="periods_time">{phase}</div>
  //       {instanceIsFalse ? (
  //         <div className="course_time_count_box">
  //           <span>{start_date}开课</span>
  //           {course_day_count ? <span>{course_day_count}天</span> : ""}
  //           {/* {media_lessons_count
  //             ? !is_spell && <span>{media_lessons_count}节课</span>
  //             : ""} */}
  //         </div>
  //       ) : (
  //         <div
  //           className="course_time_count_box"
  //           style={{
  //             paddingLeft: "95px",
  //             paddingRight: 5,
  //           }}
  //         >
  //           {brief}
  //         </div>
  //       )}
  //     </div>
  //   );
  // }

  // 分级课程开课时间·课时
  // subSignPeriodStutus() {
  //   const { phase, sub_study_package_activities } = this.props;
  //   return (
  //     <div className="sub_periods_time_box">
  //       <div className="navigate_time_box">
  //         <div className="box_left">{phase}</div>
  //         <div className="box_right">
  //           {[...sub_study_package_activities].reverse().map((sub, idx) => (
  //             <div key={idx} className="sub_count_box">
  //               <h3>{sub.navigate_name}：</h3>
  //               <p>
  //                 {chineseMonthDayDate(sub.start_time)} -{" "}
  //                 {chineseMonthDayDate(sub.end_time)}
  //               </p>
  //               <span>{sub.media_lessons_count}节课</span>
  //             </div>
  //           ))}
  //           <div className="sub_count_box navigate_bottom_text">
  //             <p>课程进度可根据学习情况自行调整</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // absenteePeriodStatus() {
  //   const { phases, study_package_activity } = this.props;
  //   if (phases == null) {
  //     return "";
  //   }
  //   if (study_package_activity && study_package_activity.instance) {
  //     return "";
  //   }
  //   return (
  //     <div className="absentee_periods_time_box">
  //       {
  //         <div className="absentee_time_tip">
  //           <h3>
  //             可选择&nbsp;“半年内”&nbsp;
  //             <br />
  //             任何一期开课
  //           </h3>
  //         </div>
  //       }
  //       {phases.map((phase, idx) => {
  //         return (
  //           <div key={idx} className="sub_count_box">
  //             <h3>{phase.name}</h3>
  //             <p>{chineseMonthDayDate(phase.start_time)}开课</p>
  //             <p>{chineseMonthDayDate(phase.end_time)}结课</p>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  // periodStatus() {
  //   if (
  //     !this.props.start_time ||
  //     this.props.is_experience ||
  //     this.props.is_parent_class
  //   ) {
  //     return "";
  //   }
  //   const studyPackageActivityType = {
  //     series: 0,
  //     normal: 1,
  //     absenteeSeries: 2,
  //     absenteeNormal: 3,
  //     seriesThinking: 4,
  //   };
  //   switch (this.state.studyPackageActivityType) {
  //     case studyPackageActivityType.seriesThinking:
  //       return this.subSignPeriodStutus(); // 系列课
  //       break;
  //     case studyPackageActivityType.normal:
  //     case studyPackageActivityType.series:
  //       return this.signPeriodStatus(); // 正价课/体验课/试听课
  //       break;
  //     case studyPackageActivityType.absenteeSeries:
  //     case studyPackageActivityType.absenteeNormal:
  //       return this.absenteePeriodStatus();
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // courseFitDescribe() {
  //   const { suitable_brief, is_experience } = this.props;
  //   return (
  //     <div className="course_fit_describe">
  //       {is_experience === true ? "" : <div className="fit_title">适合：</div>}
  //       <div className="fit_text">{suitable_brief}</div>
  //     </div>
  //   );
  // }

  // userRecommendWrapper = () => {
  //   const {
  //     share_user: {
  //       baby_audio_recorders,
  //       share_user_comment,
  //       photo_url,
  //       name,
  //       phase,
  //       baby,
  //     },
  //     total_comments_count,
  //   } = this.props;

  //   return (
  //     <div className="recommand_comments">
  //       <div className="header">
  //         <div className="user_info">
  //           <img data-src={photo_url} className="lazyload" data-sizes="auto" />
  //           <p>
  //             {name}
  //             <span>
  //               {phase && "(" + phase + ")"}
  //               向你推荐
  //             </span>
  //           </p>
  //         </div>
  //         {total_comments_count > 0 && (
  //           <div className="remommand" onClick={this.onClickEvalute}>
  //             更多评价 »
  //           </div>
  //         )}
  //       </div>
  //       {share_user_comment && (
  //         <div className="recommand_text">
  //           <div className="text">{share_user_comment}</div>
  //         </div>
  //       )}
  //       {baby_audio_recorders && baby_audio_recorders.length > 0 && (
  //         <div className="poetry_baby_audios">
  //           <p>听听我家{baby ? baby.nickname : ""}读的诗：</p>
  //           <div className="scroll_container">
  //             <ListContainer
  //               list={baby_audio_recorders}
  //               listItem={({ itemIndex, ...audio }) => (
  //                 <PoetryBabyAudio
  //                   key={itemIndex}
  //                   index={itemIndex}
  //                   audioSrc={audio.entire_record_url}
  //                   poetryName={audio.name}
  //                   baby={baby ? baby.nickname : ""}
  //                   audioInfoUrl={audio.audio_info_url}
  //                 />
  //               )}
  //             />
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  // 点击关闭课程咨询老师弹框
  onCloseCousultantDialog = () => {
    this.setState({
      handleCourseCousultant: false,
    });
  };

  addCourseCousultantDialog() {
    return (
      <div className="add_cc_dialog">
        <div className="cc_wrapper">
          <div className="cc_content">
            <div
              className="btn-close-cc"
              onClick={this.onCloseCousultantDialog}
            >
              &times;
            </div>
            <div className="cc_teacher_title">咨询课程老师</div>
            <div className="palyContent">
              <p>加老师微信，咨询报名、上课等问题</p>
              <img
                data-src={this.props.cc_qrcode_url}
                className="lazyload"
                data-sizes="auto"
              />
              <div className="identify_qrcode">
                长按识别二维码，添加我为好友
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  //可以唤起相应的分级报名页
  subSignPackageActivity() {
    let inviteRewardPrice = this.props.money_per_user;
    return (
      <div className="course_sub_list">
        <ul>
          {this.props.sub_study_package_activities.map((activity, index) => (
            <li
              key={activity.id}
              onClick={
                activity.state == "pre_has_bought" ||
                activity.state == "superset" ||
                activity.state == "bought" ||
                activity.state == "pre_paid" ||
                new Date(this.props.buy_endtime) <
                  new Date(this.props.system_time) ||
                this.props.bought ||
                ("had_joined_groupon" in this.props &&
                  this.props.had_joined_groupon)
                  ? null
                  : this.selectSubClick.bind(this, activity)
              }
              data-index={index}
              data-id={activity.id}
              data-max-value={activity.max_price}
              data-min-value={activity.min_price}
              className={
                activity.state == "pre_has_bought" ||
                activity.state == "superset" ||
                activity.state == "bought" ||
                new Date(this.props.buy_endtime) <
                  new Date(this.props.system_time) ||
                this.props.bought ||
                ("had_joined_groupon" in this.props &&
                  this.props.had_joined_groupon)
                  ? null
                  : this.state.selectIndex == index
                  ? "highLight"
                  : null
              }
              style={{ paddingBottom: activity.brief ? null : "35px" }}
            >
              <a href="javascript:void(0)">
                <div className="content_top">
                  {activity.state == "pre_has_bought" ||
                  activity.state == "superset" ||
                  activity.state == "bought" ||
                  activity.state == "pre_paid" ? (
                    <div className="sign_success">
                      {activity.state == "pre_paid" ? "团购中" : "已报名"}
                    </div>
                  ) : (
                    <div className="course_min_price">
                      {this.props.sale_mode == "normal" ? (
                        "¥" + activity.max_price
                      ) : this.props.sale_mode == "shopping_group" ? (
                        activity.state === "ok" && "¥" + activity.max_price
                      ) : // (
                      //   (
                      //     this.props.grouponDirectBuy === true
                      //       ?
                      //       "免拼团: ¥"
                      //       :
                      //       `${this.props.group_max_count + "人团: ¥"}`
                      //   )
                      //   +
                      //   activity.min_price
                      // )
                      this.props.sale_mode == "bargain" ? (
                        <p>
                          {activity.max_price -
                            this.props.invited_users_count * inviteRewardPrice <
                          activity.min_price ? (
                            <span>
                              ¥{activity.min_price}
                              &nbsp;<del>¥{activity.max_price}</del>
                            </span>
                          ) : activity.max_price -
                              this.props.invited_users_count *
                                inviteRewardPrice <
                            activity.max_price ? (
                            <span>
                              ¥
                              {activity.max_price -
                                this.props.invited_users_count *
                                  inviteRewardPrice}
                              &nbsp;<del>¥{activity.max_price}</del>
                            </span>
                          ) : (
                            <span>
                              {"¥" +
                                (activity.max_price -
                                  this.props.invited_users_count *
                                    inviteRewardPrice)}
                            </span>
                          )}
                        </p>
                      ) : (
                        <span>{"¥" + activity.min_price}</span>
                      )}
                    </div>
                  )}
                  <div className="course_title">{activity.name}</div>
                </div>
                <div className="content_bottom">
                  <div className="course_max_price">
                    {this.props.sale_mode == "shopping_group" ? (
                      // activity.state === 'ok' && ("单独报名: ¥" + activity.max_price)
                      (this.props.grouponDirectBuy === true
                        ? "免拼团: ¥"
                        : `${this.props.group_max_count + "人团: ¥"}`) +
                      activity.min_price
                    ) : this.props.sale_mode == "bargain" ||
                      this.props.sale_mode == "buy_bargain" ? (
                      activity.state === "ok" && (
                        <span>
                          可通过邀请减至<i>¥{activity.min_price}</i>
                        </span>
                      )
                    ) : (
                      <del>¥{activity.list_price}</del>
                    )}
                  </div>
                  {activity.brief && (
                    <div className="course_desc">{activity.brief}</div>
                  )}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  headFigureBaner() {
    const { main_video_url, main_photo_url } = this.props;
    let headBaner;
    if (main_video_url) {
      headBaner = (
        <video
          style={{ display: "block" }}
          controls
          width="100%"
          preload="none" // if poster has value, setting preload="none"
          poster={main_photo_url}
          src={main_video_url}
        ></video>
      );
    } else {
      headBaner = <img src={main_photo_url} data-sizes="auto" />;
    }

    return (
      <div>
        <div className="periods_time_top">{headBaner}</div>
      </div>
    );
  }

  // tabBarItem() {
  //   const hasCommentedAuth =
  //     this.props.comment_state == "uncommented" ||
  //     this.props.comment_state == "commented";
  //   const {
  //     start_time,
  //     study_package_category_id,
  //     sub_study_package_activities,
  //     faq,
  //     total_comments_count,
  //     is_experience,
  //     activity_category_type,
  //     is_calc_series,
  //   } = this.props;

  //   const {
  //     selectIndex1,
  //     activeSwitch1,
  //     activeSwitch2,
  //     activeSwitch3,
  //   } = this.state;

  //   let courseFirstTabName, courseProblemName;
  //   if (study_package_category_id === 18) {
  //     // courseFirstTabName = "总览";
  //     courseFirstTabName = "课程介绍";
  //   } else if (is_experience === true) {
  //     courseFirstTabName = "活动介绍";
  //   } else {
  //     courseFirstTabName = "课程介绍";
  //   }

  //   courseProblemName = "常见问题";

  //   return (
  //     <div
  //       className={`sign_tab ${
  //         activity_category_type == 4 ? "remove_margin" : ""
  //       }`}
  //       id="switch_tab"
  //     >
  //       <ul
  //         className="switchTitle"
  //         style={{ marginTop: start_time ? null : "0" }}
  //       >
  //         <li
  //           className={activeSwitch1}
  //           onClick={this.onClickCourse}
  //           data-index="0"
  //         >
  //           {courseFirstTabName}
  //         </li>

  //         {
  //           // tab_bar_item
  //           !is_calc_series &&
  //             [...this.props.content_activities].map(
  //               (content_activity, index) => (
  //                 <li
  //                   key={content_activity.id}
  //                   className={selectIndex1 == index + 1 ? "active" : ""}
  //                   onClick={this.onClickSubActivity}
  //                   data-index={index + 1}
  //                   data-tab={`tabL${4 - index}`}
  //                 >
  //                   {content_activity.navigate_name}
  //                 </li>
  //               )
  //             )
  //         }

  //         {faq && (
  //           <li
  //             className={activeSwitch2}
  //             onClick={this.onClickProblem}
  //             id="problem_tab"
  //           >
  //             {courseProblemName}
  //           </li>
  //         )}
  //         {/* {is_experience === true ? (
  //           ""
  //         ) : total_comments_count > 0 ? (
  //           <li
  //             className={activeSwitch3}
  //             onClick={this.onClickEvalute}
  //             // data-index={
  //             //   sub_study_package_activities &&
  //             //   sub_study_package_activities.length + 1
  //             // }
  //           >
  //             评价&nbsp;
  //             {"(" +
  //               (total_comments_count >= "10000"
  //                 ? "9999+"
  //                 : total_comments_count) +
  //               ")"}
  //           </li>
  //         ) : (
  //           ""
  //           //     hasCommentedAuth &&
  //           //     <li className={activeSwitch3} onClick={this.onClickEvalute}>
  //           //       评价
  //           // </li>
  //         )} */}
  //       </ul>
  //     </div>
  //   );
  // }

  onRedMoneyClick = () => {
    const {
      had_joined_groupon,
      shopping_group,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      bonus,
      bonus_type,
      remaining_shopping_group_count,
      bought,
      avatar_location,
    } = this.props;
    console.log("bought", bought);
    new SharePosterProxy({
      // posterUrl: this.props.poster_url,
      // posterImgSrc: this.props.poster_img_src,
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free:
        (had_joined_groupon ||
          (shopping_group && had_joined_groupon === false)) &&
        remaining_shopping_group_count > 0 &&
        !bought
          ? "in_or_open_shopping_group"
          : "none_shopping_group",
      bonus,
      bonus_type,
      sale_mode: "shopping_group",
      remaining_shopping_group_count,
      avatar_location: avatar_location,
    }).open();

    GA("shortcut", "shareNormal", this.props.study_package_activity_id);
  };

  onCommentClick = (e) => {
    // if (!window.location.hash) {
    //   window.location.href = window.location.href + "#comment";
    //   window.location.reload(true);
    // }
    if (this.props.comments_url) {
      onNavigate(e, this.props.comments_url);
    }

    GA("shortcut", "packageComment", this.props.study_package_activity_id);
  };

  handleDrawCash = () => {
    // v1
    // const { bonus_type, invitation_poster_url } = this.props
    // /**
    //  * 奖学金上线前【0】：走点击事件生成海报
    //  * 奖学金上线后【1】：走链接跳转
    // */
    // return (
    //   <a
    //     className="invite_draw_cash"
    //     onClick={bonus_type === 0 ? this.inviteFriendDrawCash.bind(null, true) : null}
    //     href={bonus_type === 0 ? 'javascript:void(0)' : invitation_poster_url}
    //   ></a>
    // )
    // v2
    let isShowComment = false;
    if (this.props.total_comments_count > 0) {
      isShowComment = true;
    }
    return (
      <RightFloatIcons
        bottomPosition={110}
        isShowComment={isShowComment}
        onCommentClick={this.onCommentClick}
        isShowRedMoney={!!this.props.bonus}
        onRedMoneyClick={this.onRedMoneyClick}
      />
    );
  };

  choosePhaseClick = (phase) => {
    const absentee_time_url = this.props.absentee_time_url;
    var params = {};
    if (phase.id == -1) {
      params = {
        start_time: phase.start_time,
      };
    } else {
      params = {
        id: phase.id,
      };
    }
    if (this.state.absenteeActivity.order_id) {
      params["order_id"] = this.state.absenteeActivity.order_id;
    }
    if (this.state.absenteeActivity.groupon_id) {
      params["groupon_id"] = this.state.absenteeActivity.groupon_id;
    }
    if (this.state.absenteeActivity) {
      params["absentee_activity_id"] = this.state.absenteeActivity.id;
    }
    const toast = ToastManager.showLoading("请稍等");
    request({
      url: absentee_time_url,
      data: params,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((res) => {
        toast.cancel();
        window.location.href = res.data.redirect_url;
      })
      .catch((err) => {
        toast.cancel();
      });
  };

  choosePhaseDiss = () => {
    if (
      this.state.absenteeActivity &&
      this.props.current_user_shopping_group &&
      this.props.current_user_shopping_group.state.includes("已支付押金")
    ) {
      this.alertPoster();
    }
  };

  alertPoster = () => {
    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          posterImgSrc: this.props.poster_img_src,
          free: this.props.free,
          bonus: this.props.bonus,
          bonus_type: this.props.bonus_type,
          sale_mode: this.props.sale_mode,
          remaining_shopping_group_count: this.props
            .remaining_shopping_group_count,
          avatar_location: this.props.avatar_location,
        });
      }

      // <SharePoster
      //   imgSrc={this.poster_img_src}
      //   bonus={this.bonus}
      //   free={this.free}
      //   sale_mode={this.sale_mode}
      //   bonus_type={this.bonus_type}
      //   remaining_shopping_group_count={this.remaining_shopping_group_count}
      //   invite_count_required={this.invite_count_required}
      //   bargain_duration_hours={this.bargain_duration_hours}
      //   inviteRewardPrice={this.inviteRewardPrice}
      //   activity={this.activity}
      //   end_time={this.end_time}
      //   buy_endtime={this.buy_endtime}
      //   system_time={this.system_time}
      //   nickName={this.nickName}
      //   ruleUrl={this.ruleUrl}
      //   reportCardRuleUrl={this.reportCardRuleUrl}
      //   onClose={() => { this.cancel() }}
      // />
      this.sharePosterProxy.open();
    }
  };

  handleTagID = (tag_id) => {
    const { study_package_activity_id } = this.props;
    GA("Comments", study_package_activity_id + "_tag", tag_id);

    this.setState({
      current_tag_id: tag_id,
    });
  };
  commentsMore = (e, tag_id) => {
    // 关联状态过多，冗余
    const { study_package_activity_id } = this.props;
    GA("Comments", study_package_activity_id + "_more", tag_id);

    if (this.props.comments_url) {
      onNavigate(e, this.props.comments_url);
    }

    // let selectIndex1 = $("#switch_tab")
    //   .find("li:last")
    //   .data("index");
    // this.setState({
    //   activeSwitch1: "",
    //   activeSwitch3: "active",
    //   current_tag_id: tag_id,
    //   selectIndex1,
    //   isCourseContainer: "none"
    // });
  };

  // 回调获取关闭优惠券弹框的状态
  getActivityCouponTicketCloseStatus = (boolState) => {
    this.setState({
      showCourseCouponTicket: boolState,
    });
  };

  isDisplayRightFloatIcons = () => {
    let isShowShareButtonFixed = false;
    const {
      is_parent_class,
      bonus,
      sale_mode,
      bought,
      buy_endtime,
      system_time,
    } = this.props;

    if (bonus != 0) {
      if (sale_mode == "bargain") {
        if (bought != null && bought != false) {
          print("购买成功显示[邀请按钮]");
          // 购买成功 显示 “邀请按钮”
          isShowShareButtonFixed = true;
          // showShareButtonFixed = this.handleDrawCash();
        } else {
          if (new Date(buy_endtime) < new Date(system_time)) {
            print("当前系统时间 大于 课程售卖最后截止时间 显示“邀请按钮”");
            // 当前系统时间 大于 课程售卖最后截止时间 显示“邀请按钮”
            isShowShareButtonFixed = true;
          } else if (
            this.state.end_time != null &&
            new Date(this.state.end_time) < new Date(system_time)
          ) {
            print("用户砍价时间结束 显示 “邀请按钮”");
            // 用户砍价时间结束 显示 “邀请按钮”
            isShowShareButtonFixed = true;
          }
        }
      } else if (sale_mode == "invite" && is_parent_class === true) {
        print("不显示[邀请有奖]按钮");
        // showShareButtonFixed = "";
      } else {
        print("其它模式下 均显示右上角邀请按钮");
        // 其它模式下 均显示右上角邀请按钮
        isShowShareButtonFixed = true;
      }
    }
    // return isShowShareButtonFixed;
    return true
  };

  render() {
    const media_lesson = this.props.current_select;
    const qrcode = this.props.qrcode_url;
    const {
      is_experience,
      is_parent_class,
      bonus,
      activity_category_type,
      comment_tags,
      total_comments_count,
    } = this.props;
    const {
      current_tag_id,
      selectIndex1,
      activeSwitch3,
      activeSwitch2,
      activeSwitch1,
    } = this.state;

    let init_comment_tag_id = 0;

    // if (comment_tags && comment_tags.length > 0) {
    //   init_comment_tag_id = comment_tags[0]['id']
    // }

    return (
      <div className="comp-study-package-activity">
        <div className="activity_list">
          <div className="activity_pic_list activity_pic">
            <ActivityBanner
              mainVideo={this.props.main_video_url}
              mainPhotoUrl={this.props.main_photo_url}
            />
            {/* {
              // 顶部 photo/video banner
              this.headFigureBaner()
            } */}
            {this.isDisplayRightFloatIcons() && (
              <ActivityRightFloatIcons
                isShowComment={this.props.total_comments_count > 0}
                onCommentClick={this.onCommentClick}
                bonus={this.props.bonus || this.props.half_price_ecoupon_promotion}
                onRedMoneyClick={this.onRedMoneyClick}
                bottomPosition={110}
                onConsultantClick={() => {
                  window.location.href =
                    this.props.promotion_method === "scan"
                      ? "/course_consultants?scenario=22&ref=study_package_activity_details&promotion_method=scan"
                      : "/course_consultants?scenario=22&ref=study_package_activity_details";
                }}
                onMoreCourseClick={() => {
                  window.location.href = "/activity_groups?ref=more_course";
                }}
              />
            )}
            {/* {
              // 分享给朋友以及邀请领现金 (右上角fixed)
              showShareButtonFixed
            } */}
            {
              // "2019幼升小白皮书（视频精华版）"不需要显示
              // !is_parent_class &&
              // (
              //   // 课程开课日期·天数·课时，“名校加油站”报名页不需要显示
              //   is_experience === true ? '' :
              //   (
              //     // 报名页可能没有开始时间
              //     this.props.start_time &&
              //     (
              //       this.props.study_package_category_id === 18
              //         ?
              //         this.subSignPeriodStutus() // 系列课
              //         :
              //         this.signPeriodStatus() // 正价课/体验课/试听课
              //     )
              //   )
              // )
              // "2019幼升小白皮书（视频精华版）" 或 “名校加油站”报名页 不需要显示
              activity_category_type !== 4 && (
                <ActivityPeriodStatus
                  startTime={this.props.start_time}
                  isExperience={this.props.is_experience}
                  isParentClass={this.props.is_parent_class}
                  studyPackageActivityType={
                    this.props.study_package_activity_type
                  }
                  studyPackageActivity={this.props.study_package_activity}
                  phases={this.props.phases}
                  phase={this.props.phase}
                  startDate={this.props.start_date}
                  courseWeekCount={this.props.course_week_count}
                  courseDayCount={this.props.course_day_count}
                  brief={this.props.brief}
                  subStudyPackageActivities={
                    this.props.sub_study_package_activities
                  }
                  studyPackageActivityType={this.state.studyPackageActivityType}
                  isReadingActivity={this.props.sub_course_type === "reading"}
                />
              )
            }

            {
              // 适合报名该课程的标准
              !!this.props.suitable_brief && (
                <ActivityCourseFitDescribe
                  suitableBrief={this.props.suitable_brief}
                  isExperience={this.props.is_experience}
                />
              )
            }

            {
              // 用户推荐, 从别人的分享页进入可以看到
              !is_experience && !!this.props.share_user && (
                <ActivityUserRecommend
                  shareUser={this.props.share_user}
                  totalCommentsCount={this.props.total_comments_count}
                  onClickEvalute={this.onClickEvalute}
                />
              )
            }
          </div>
          <ActivityTabGroup {...this.props} />
          {/* {
            // 导航 tabF
            this.tabBarItem()
          } */}

          {/* <div
            className={`course_container ${
              activity_category_type == 3 ? "is_series_container" : ""
            }`}
            style={{ display: this.state.isCourseContainer }}
          >
            {activeSwitch1 == "active" &&
              comment_tags.length > 0 &&
              total_comments_count > 0 && (
                <div>
                  <div className="overview_comments_head">
                    <h3>
                      家长评价 <span>({this.props.total_comments_count})</span>
                    </h3>
                  </div>
                  {!this.props.is_calc_series && (
                    <CommentTags
                      tags={comment_tags}
                      current_tag_id={current_tag_id || init_comment_tag_id}
                      handle={this.handleTagID.bind(this)}
                    />
                  )}

                  <MinCommentLists
                    ref={(isCourseContainer) =>
                      (this.isCourseContainer = isCourseContainer)
                    }
                    request_comment_url={this.props.request_comment_url}
                    comments={this.props.comments}
                    create_recommend_request_url={
                      this.props.create_recommend_request_url
                    }
                    comment_target={this.props.comment_target}
                    total_comments_count={this.props.total_comments_count}
                    pre_has_bought={this.props.pre_has_bought}
                    bought={this.props.bought}
                    current_user_comment={this.props.current_user_comment}
                    comment_state={this.props.comment_state}
                    rate={this.props.rate}
                    bought_phase={this.props.bought_phase}
                    promotion={this.props.promotion}
                    system_time={this.props.system_time}
                    current_user_comment_state={
                      this.props.current_user_comment_state
                    }
                    subStudyPackageActivities={
                      this.props.sub_study_package_activities
                    }
                    studyPackageActivity={this.props.study_package_activity}
                    uncommented={this.props.uncommented}
                    current_tag_id={current_tag_id || init_comment_tag_id}
                    current_tab="overview"
                    handle={this.handleTagID.bind(this)}
                  />

                  <div className="overview_comments_foot">
                    <span
                      onClick={(e) =>
                        this.commentsMore(
                          e,
                          current_tag_id || init_comment_tag_id
                        )
                      }
                    >
                      更多评价 >
                    </span>
                  </div>
                </div>
              )} */}

          {/* {
              //总览（tabitem 第一项）
              this.props.display_courses == true ? (
                <OldCourseList />
              ) : (
                <div className="markDownWrapper">
                  <LazyloadContent
                    content={this.props.description}
                    noneLazy={this.props.study_package_category_id === 18}
                  />
                </div>
              )
            } */}
          {
            // 用来判断小程序状态下隐藏分级报名模块
            // this.state.isInMiniProgram == true ? '' :
            //   (
            //     (this.props.sub_study_package_activities && (this.props.study_package_category_id != 18)) &&
            //     this.subSignPackageActivity()
            //   )
          }
          {/* // </div> */}
          {/* 
          {
            // tab_bar_content
            !this.props.is_calc_series &&
              [
                ...this.props.content_activities,
              ].map((content_activity, index) => (
                <div key={content_activity.id}>
                  {
                    <LazyloadContent
                      content={content_activity.description}
                      noneLazy={this.props.study_package_category_id === 18}
                      selectIndex={this.state.selectIndex1}
                      position={index + 1}
                    />
                  }
                </div>
              ))
          } */}
          {/* 
          {
            // 页面加载完成后提示用户使用优惠券
            this.state.isAlertSoleCoupon && (
              <UserSoleCouponDialog
                UserSoleCouponDialog={this.props.ecoupon_redirect_url}
              />
            )
          } */}

          {/* 试听/常见问题 */}
          {/* <ProblemFold
            faq={this.props.faq}
            ref={(problemFold) => (this.problemFoldContainer = problemFold)}
          /> */}
          {/* {
            // 评论
            activeSwitch3 == "active" && total_comments_count > 0 && (
              <div>
                {comment_tags && (
                  <CommentTags
                    tags={comment_tags}
                    current_tag_id={current_tag_id || 0}
                    handle={this.handleTagID.bind(this)}
                  />
                )}
                <MinCommentLists
                  ref={(isCourseContainer) =>
                    (this.isCourseContainer = isCourseContainer)
                  }
                  request_comment_url={this.props.request_comment_url}
                  comments={this.props.comments}
                  create_recommend_request_url={
                    this.props.create_recommend_request_url
                  }
                  comment_target={this.props.comment_target}
                  total_comments_count={this.props.total_comments_count}
                  pre_has_bought={this.props.pre_has_bought}
                  bought={this.props.bought}
                  current_user_comment={this.props.current_user_comment}
                  comment_state={this.props.comment_state}
                  rate={this.props.rate}
                  bought_phase={this.props.bought_phase}
                  promotion={this.props.promotion}
                  system_time={this.props.system_time}
                  current_user_comment_state={
                    this.props.current_user_comment_state
                  }
                  subStudyPackageActivities={
                    this.props.sub_study_package_activities
                  }
                  studyPackageActivity={this.props.study_package_activity}
                  uncommented={this.props.uncommented}
                  current_tag_id={current_tag_id}
                  current_tab="comment"
                  handle={this.handleTagID.bind(this)}
                />
              </div>
            )
          } */}

          <div>
            <WechatFollow
              ref={(ref) => (this.wechatFollowRef = ref)}
              qrcodeUrl={this.props.qrcode_url}
            />

            {
              // 多课程体系（子课程包分级购买）
              "sub_study_package_activities" in this.props && (
                <CourseLists
                  ref={(course) => (this.subCourseDilog = course)}
                  directBuyCourse={this.directBuyCourse}
                  preOnGroupBuy={this.preOnGroupBuy}
                  preOnDirectBuy={this.preOnDirectBuy}
                  invitingUser={this.invitingUser}
                  // selectSubClick={this.selectSubClick}
                  selectIndexCourse={this.state.selectIndex}
                  selectActivityId={this.state.selectActivityId}
                  selectMaxMoney={this.state.selectMaxMoney}
                  selectMinMoney={this.state.selectMinMoney}
                  subStudyPackageActivities={
                    this.props.sub_study_package_activities
                  }
                  saleMode={this.props.sale_mode}
                  groupMaxCount={this.props.group_max_count}
                  bargainDurationHours={this.props.bargain_duration_hours}
                  buyEndtime={this.props.buy_endtime}
                  systemTime={this.props.system_time}
                  endTime={this.state.end_time}
                  invitedUsersCount={this.props.invited_users_count}
                  inviteCountRequired={this.props.invite_count_required}
                  overallBought={this.props.overall_bought}
                  shoppingGroup={this.props.shopping_group}
                  remainingShoppingGroupCount={
                    this.props.remaining_shopping_group_count
                  }
                  bargainActivity={this.props.activity}
                  grouponDirectBuy={this.props.groupon_direct_buy}
                  isShowStartTime={this.state.isShowStartTime}
                  hasBoughtAny={this.state.hasBoughtAny}
                  moneyPerUser={this.props.money_per_user}
                  getSimpleSubActivityValue={this.getSimpleSubActivityValue}
                  isPoetry={this.props.is_poetry}
                  studyPackageActivityId={this.props.study_package_activity_id}
                  baby={this.props.baby}
                />
              )
            }

            {/* 优惠券 */}
            <CourseCoupons
              ref={(coupon) => (this.courseCoupon = coupon)}
              systemTime={this.props.system_time}
              eCouponData={this.state.ecoupons}
              ecouponsAvailable={this.state.ecoupons_available}
              studyPackageActivityId={this.props.study_package_activity_id}
              studyPackageActivity={this.props.study_package_activity}
              courseName={this.props.study_package_activity.name}
              instance={this.props.study_package_activity.instance}
              totalPrice={this.totalPrice}
              courseStartDate={this.props.start_date}
              freeUrl={this.props.free_url}
              groupFreeUrl={this.props.group_free_url}
              courseType={this.props.course_type}
              noteUrl={this.props.note_url}
              subStudyPackageActivities={
                this.props.sub_study_package_activities
              }
              virtualAccount={this.props.virtual_account}
              payClickAction={
                this.state.coursePayMode === "group" ? onGroupBuy : onDirectBuy
              }
              coursePayMode={this.state.coursePayMode}
              isShowStartTime={this.state.isShowStartTime}
              activityCategoryType={activity_category_type}
              hasTeachingAidPackage={this.props.has_teaching_aid_package}
              giftCommoditiesUrl={this.props.gift_commodities_url}
              activity={this.props.activity}
              {...this.props}
            />

            {/* Bottom bar 报名入口 */}
            {this.props.hide_buy ? (
              ""
            ) : (
              <div className="activity_pay">
                {this.whatShopGroupStateToShow()}
              </div>
            )}
          </div>

          {this.state.absenteeActivity && (
            <ChoosePhase
              absenteeActivity={this.state.absenteeActivity}
              sureBtnAction={this.choosePhaseClick}
              closeBtnAction={this.choosePhaseDiss}
              isUserStudyPackage={this.props.is_user_study_package}
            />
          )}
          {
            // 单期访问第2次且未购买课程（系列课中未购买任意级别的课程），过30秒弹窗：
            // 30秒弹框逻辑在 DidMount 里
            // this.state.handleCourseCousultant && this.addCourseCousultantDialog()
          }
          {this.state.showCourseCouponTicket && (
            <ActivityCouponTicket
              batchEcoupon={this.props.batch_ecoupon}
              getActivityCouponTicketCloseStatus={
                this.getActivityCouponTicketCloseStatus
              }
            />
          )}
          {
            // 优惠券为当前报名页可用
            // 最近即将过期的优惠券时间距离当前时间在3天内
            this.props.recent_overdue_ecoupon &&
              DateMinus(
                this.props.system_time,
                this.props.recent_overdue_ecoupon.end_time
              ) <= 10 && (
                <CouponTicketCouponDown
                  half_price={this.props.recent_overdue_ecoupon.half_price}
                  price={this.props.recent_overdue_ecoupon.price}
                  endTime={this.props.recent_overdue_ecoupon.end_time}
                />
              )
          }
          {
            // 弹框选择年级
            this.state.chooseGradeModalStatus && (
              <ChooseGradeModal
                grades={this.props.grades}
                createUserBabyGrade={this.props.create_user_baby_grade}
                chooseGradeModalStatus={this.closeChooseGradeModalStatus}
              />
            )
          }
        </div>
      </div>
    );
  }
}

StudyPackageActivity.propTypes = Object.assign({}, SharePoster.propTypes, {
  trial_activity: PropTypes.object,
  study_package_url: PropTypes.string,
  qrcode_url: PropTypes.string,
  share_url: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
});

StudyPackageActivity.defaultProps = {
  free: "study_package",
};
