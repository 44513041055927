import React from "react";
// import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
// import {
//   debounce,
//   print,
//   tracking,
//   onNavigate,
//   promptPageBack,
// } from "../utils/helpers";
import styled from "styled-components";
import RewardExchangeProgress from "../point_reward/RewardExchangeProgress";
import { ToastManager } from "../utils/Toast.jsx";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import RightFloatIcons from "../study_package_activities/RightFloatIcons";
import practice_go from "practice_courses/practice_go.png";
import practice_title from "practice_courses/practice_title.png";
import study_status_to from "practice_courses/study_status_to.png";
// import exam_go_icon from "practice_courses/exam_go_icon.png";
// import exam_pass from "practice_courses/exam_pass.png";
// import fail_star from "practice_courses/fail_star.png";
import practice_dot from "practice_courses/practice_dot.png";
import practice_lock from "practice_courses/practice_lock.png";
import go_right_course from "practice_courses/go_right_course.png";
import light_star from "practice_courses/light_star.png";
import gery_star from "practice_courses/gery_star.png";
import study_status_to_up from "practice_courses/study_status_to_up.png";
import study_status_to_down from "practice_courses/study_status_to_down.png";
import practice_lock_girl from "practice_courses/practice_lock_girl.png";
import practice_lock_girl_hand from "practice_courses/practice_lock_girl_hand.png";
import practice_corse_back1 from "practice_courses/practice_corse_back1.png";
import practice_corse_back2 from "practice_courses/practice_corse_back2.png";
import practice_corse_back3 from "practice_courses/practice_corse_back3.png";
import practice_corse_back4 from "practice_courses/practice_corse_back4.png";
import practice_corse_back5 from "practice_courses/practice_corse_back5.png";
import practice_corse_back6 from "practice_courses/practice_corse_back6.png";

import go_right_course_grey from "practice_courses/go_right_course_grey.png";

import one_star from "practice_courses/one_star.gif";
import tow_star from "practice_courses/tow_star.gif";
import three_star from "practice_courses/three_star.gif";
import zero_star from "practice_courses/zero_star.gif";

import word_icon from "practice_courses/word_icon.png";

import practice_once from "practice_courses/practice_once.png";
import go_study_back from "practice_courses/go_study_back.png";
// import sucess_star from "practice_courses/sucess_star.png";
import btn_close from "study_package/btn_close.png";
// import UserAgent from "../utils/UserAgent";

import "../utils/ReactMarkDown.scss";
import "./PracticeCourses.scss";
import "./PracticeArticle.scss";

const accumulations = [
  { img: practice_corse_back1, w: "98px", h: "72px", t: "-27px" },
  { img: practice_corse_back2, w: "98px", h: "69px", t: "-24px" },
  { img: practice_corse_back3, w: "98px", h: "73px", t: "-22px" },
  { img: practice_corse_back4, w: "98px", h: "72px", t: "-27px" },
  { img: practice_corse_back5, w: "98px", h: "69px", t: "-24px" },
  { img: practice_corse_back6, w: "98px", h: "73px", t: "-22px" },
];

const studyStatus = [
  { name: "未测试", backColor: "#FF6868" },
  { name: "未通过", backColor: "#FF6868" },
  { name: "掌握", backColor: "#88B1F7" },
  { name: "熟练掌握", backColor: "#66D167" },
];
const stars = [zero_star, one_star, tow_star, three_star];
export const AccumulationSelf = styled.div`
  &::before {
    width: 98px;
    height: ${({ idx }) =>
      idx == 0 ? accumulations[0].h : accumulations[idx % 6].h};
    top: ${({ idx }) =>
      idx == 0 ? accumulations[0].t : accumulations[idx % 6].t};
    background: url(${({ idx }) =>
      idx == 0 ? accumulations[0].img : accumulations[idx % 6].img});
    background-size: 100%;
  }
`;

export const renderKnowlege = function (item, index, pass) {
  return item && item.materials && item.materials.length !== 0 ? (
    <AccumulationSelf
      className={`accumulation ${
        index == 0
          ? "accumulation-left"
          : index % 2 !== 0
          ? "accumulation-right"
          : "accumulation-left"
      }`}
      key={"m" + index}
      idx={index}
      style={{ marginTop: index == 0 ? "20px" : "" }}
    >
      <div className="accumulation-title">{item.poetry_article_name}</div>
      {item.materials.map((cli, idx) => {
        return (
          <div className="accumulation-item" key={"mm" + idx}>
            <div className="accumulation-item-belong">
              <div className="accumulation-item-belong-a">
                {cli.kb_course_name == "美词佳句" ? (
                  <img src={word_icon} className="word_icon"></img>
                ) : (
                  <img src={practice_dot} className="practice_dot"></img>
                )}
              </div>
              <div className="accumulation-item-belong-b">
                {cli.kb_course_name}
              </div>
              {cli.kb_lesson_name && (
                <div className="accumulation-item-belong-c">
                  {cli.kb_lesson_name}
                </div>
              )}
            </div>
            <div className="material_names">
              {cli.material_names &&
                cli.material_names.map((itm, i) => {
                  return (
                    <div key={"material_names" + i} className="material_name">
                      {itm}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
      {!item.pass && (
        <div className="mengceng-lock">
          <div className="connect">
            {item.materials_type_statistics && (
              <>
                <img
                  src={practice_lock_girl}
                  className="practice_lock_girl"
                ></img>
                <img
                  src={practice_lock_girl_hand}
                  className="practice_lock_girl_hand"
                ></img>
                <div className="collect">{item.materials_type_statistics}</div>
              </>
            )}
          </div>
        </div>
      )}
    </AccumulationSelf>
  ) : (
    ""
  );
};

export default class PracticeArticle extends React.Component {
  constructor(props) {
    super(props);
    console.log("PracticeArticle_props", props);
    this.state = { showDig: false, showUpper: true, showMore: false };
  }

  onInvitationIconClick = () => {
    const {
      float_menu: {
        poster_photo_template_url: posterTemplate,
        poster_creation: posterCreation,
        share_url: get_shopping_group_data_url,
        bonus: propsBonus,
      },
      // get_shopping_group_data_url,
      // poster_photo_template_url: posterTemplate,
      // poster_creation: posterCreation,
      // study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus || propsBonus || 0,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };

  expandRight(falg1, flag2, rank) {
    if (falg1) {
      if (flag2) {
        return (
          <div className="star_icons  star_icons-flex">
            {/* {rank && ( */}
            <img
              src={rank ? light_star : gery_star}
              className="star_icons-flex-icon"
            ></img>
            {/* // )} */}
            <div className="go_icons">
              <img src={go_right_course}></img>
            </div>
          </div>
        );
      } else {
        return (
          <div className="star_icons  star_icons-flex">
            <div className="go_icons" style={{ backgroundColor: " #66d167" }}>
              <img src={go_right_course}></img>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="star_icons1">
          <div className="go_icons1">
            <img src={practice_lock}></img>
          </div>
        </div>
      );
    }
  }

  mapToStudyStatus(i) {
    return studyStatus[i];
  }
  componentDidMount() {
    this.setState({
      rankSrc: stars[this.props.rank] + "?" + Math.random(),
      audioSrc:
        this.props.rank >= 2
          ? "https://upload.fireflybaby.cn/reading_practice_success.mp3" +
            "?" +
            Math.random()
          : "https://upload.fireflybaby.cn/reading_practice_fail.mp3" +
            "?" +
            Math.random(),
    });
  }
  render() {
    const {
      kb_courses_group,
      is_unit_practice,
      other_poetry_articles_group,
      pass,
      rank,
      unit_practice_poetry_articles_url,
      get_quizzes_url,
      reward_commodity,
      total_point,
      create_reward_exchange_url,
      study_package_activity_id,
    } = this.props;
    const crystalMarginTop = !is_unit_practice && pass && this.props.kb_courses_grasp ? 0 : '20px'
    return (
      <>
        <div className="new-course  new-course-connect clear_scroll_bar">
          {/* 得星 */}
          {!is_unit_practice && pass && this.props.kb_courses_grasp && (
            <>
              {this.state.audioSrc ? (
                <audio
                  src={this.state.audioSrc}
                  // src={
                  //   rank >= 2
                  //     ? "https://upload.fireflybaby.cn/reading_practice_success.mp3" +
                  //       "?" +
                  //       Math.random()
                  //     : "https://upload.fireflybaby.cn/reading_practice_fail.mp3" +
                  //       "?" +
                  //       Math.random()
                  // }
                  id="play"
                  // muted
                  autoPlay
                ></audio>
              ) : (
                ""
              )}
              {this.state.rankSrc ? (
                <img
                  src={this.state.rankSrc}
                  // src={stars[rank] + "?" + Math.random()}
                  className="resulte-img-header"
                ></img>
              ) : (
                ""
              )}
            </>
          )}
          {/* 得星 */}
          {/* 开始----------知识点 */}

          {/* 兑换奖励进度 */}
          {reward_commodity && <div style={{marginBottom: '44px', marginTop: crystalMarginTop}}>
            <RewardExchangeProgress
            commodity={reward_commodity}
            list={this.props.exchange_commodities}
            totalPoint={total_point}
            create_reward_exchange_url={create_reward_exchange_url}
            study_package_activity_id={study_package_activity_id}
            className="without-border"
            style={{margin: 0}} />
            </div>}


          {pass &&
            this.props.kb_courses_grasp &&
            this.props.kb_courses_grasp.kb_courses &&
            this.props.kb_courses_grasp.kb_courses.length > 0 && (
              <div
                className={
                  !is_unit_practice && pass
                    ? "course-consolidate first-item"
                    : "course-consolidate"
                }
                style={{ marginTop: is_unit_practice ? 54 : 0 }}
              >
                {/* 边框 */}
                <div className="course-title">
                  <img
                    src={practice_title}
                    className="course-title-back course-title-back-length"
                  ></img>
                  <div className="course-title-font ">知识点掌握情况</div>
                </div>
                {/* 边框 ---------------- 知识点列表 */}
                {this.props.kb_courses_grasp.kb_courses.map((item, index) => {
                  return index < 2 || (index >= 2 && this.state.showMore) ? (
                    <div
                      className={`knowledge-points ${
                        index == 0 ? "first-item" : ""
                      }`}
                      key={"rr" + index}
                    >
                      <div className="knowledge-point">
                        <div className="knowledge-point-icon"></div>
                        <div className="knowledge-point-title">
                          {item.kb_course_name}
                        </div>
                      </div>
                      {item.kb_lessons.map((im, ix) => {
                        return (
                          <div
                            className="knowledge-point-item"
                            key={"rrr" + ix}
                            onClick={() => {
                              window.location.href =
                                im.kb_lesson_grasp_details_url;
                            }}
                          >
                            <div className="knowledge-point-left">
                              <div className="knowledge-point-left-top">
                                <div className="knowledge-point-left-top-dot"></div>
                                <span className="knowledge-point-left-top-font">
                                  {im.kb_lesson_name}
                                </span>
                              </div>
                              <div
                                className="knowledge-point-left-bottom"
                                style={{ marginTop: im.subjective ? 5 : "" }}
                              >
                                {im.subjective ? (
                                  <div className="subjective-tips">
                                    (主观题，请自判对错)
                                  </div>
                                ) : (
                                  im.prev_grasp_state &&
                                  im.current_grasp_state !==
                                    im.prev_grasp_state && (
                                    <>
                                      <div className="per-study-status">
                                        {studyStatus[im.prev_grasp_state].name}
                                      </div>
                                      <div className="per-study-status-to">
                                        <img src={study_status_to}></img>
                                      </div>
                                    </>
                                  )
                                )}
                                {im.subjective ||
                                im.current_grasp_state === null ||
                                im.current_grasp_state === undefined ? (
                                  ""
                                ) : (
                                  <div
                                    className="now-study-status"
                                    style={{
                                      backgroundColor:
                                        studyStatus[im.current_grasp_state]
                                          .backColor,
                                    }}
                                  >
                                    <div className="now-study-status-tit">
                                      {studyStatus[im.current_grasp_state].name}
                                    </div>
                                    {im.prev_grasp_state &&
                                      im.current_grasp_state !==
                                        im.prev_grasp_state && (
                                        <div className="now-study-status-icon">
                                          <img
                                            src={
                                              im.prev_grasp_state >
                                              im.current_grasp_state
                                                ? study_status_to_down
                                                : study_status_to_up
                                            }
                                          ></img>
                                        </div>
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              src={go_right_course_grey}
                              className="knowledge-point-right"
                            ></img>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  );
                })}

                {/*  知识点列表------------结束 */}
                {this.props.kb_courses_grasp.kb_courses &&
                this.props.kb_courses_grasp.kb_courses.length > 2 ? (
                  <div
                    className={`points-status ${
                      this.state.showMore
                        ? "points-status-show"
                        : "points-status-hide"
                    }`}
                    onClick={() => {
                      const { showMore } = this.state;
                      this.setState({
                        showMore: !showMore,
                      });
                    }}
                  >
                    {this.state.showMore ? "收起" : "展开全部"}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          {/* 知识点------------课内巩固 */}
          {!is_unit_practice && kb_courses_group && (
            <div
              className={
                is_unit_practice || kb_courses_group.pass
                  ? "course-consolidate"
                  : " course-consolidate course-consolidate-no "
              }
              style={{
                marginTop: is_unit_practice
                  ? 44
                  : this.props.kb_courses_grasp &&
                    this.props.kb_courses_grasp.kb_courses.length > 0
                  ? kb_courses_group.pass
                    ? 44
                    : 54
                  : kb_courses_group.pass
                  ? 0
                  : 54,
              }}
            >
              {/* 边框 */}
              <div className="course-title">
                <img src={practice_title} className="course-title-back"></img>
                <div className="course-title-font">课文巩固</div>
              </div>
              {/* 边框 ---------------- 主图 */}
              {kb_courses_group.poetry_article_image_url && (
                <div className="course-main-imgs">
                  <img
                    src={kb_courses_group.poetry_article_image_url}
                    className="course-main-img"
                  ></img>
                </div>
              )}
              {/* 主图 ------------- 学习内容 */}
              <div className="course-main-connect">
                <div className="course-main-connect-intro">知识点提炼</div>
                <div className="course-main-connect-group">
                  {kb_courses_group.kb_courses.map((item, index) => {
                    return (
                      <div
                        key={"item" + index}
                        className={
                          kb_courses_group &&
                          kb_courses_group.kb_courses &&
                          kb_courses_group.kb_courses.length == 1
                            ? "course-main-connect-dots  course-main-connect-dots-width"
                            : "course-main-connect-dots "
                        }
                      >
                        <div className="dots-title">{item.kb_course_name}</div>
                        <div className="dots">
                          <div className="dot">
                            {item.kb_lessons.map((im, ix) => {
                              return (
                                <div key={"im" + ix} className="dot-style">
                                  <span className="dot-dashed-style"></span>
                                  <span className="dot-font-style"> {im}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*  学习内容---------- 学习结果 或去学习 */}
              <div className="course-main-go course-main-result">
                <div
                  className="next_quizes"
                  onClick={() => {
                    // console.log("122");
                    window.location.href =
                      kb_courses_group.get_quizzes_url ||
                      unit_practice_poetry_articles_url;
                  }}
                >
                  {pass ? (
                    <div className="next_quize next_quize_once ">
                      <div className="next_quize1"></div>
                      <div className="next_quize2"></div>
                      <div className="next_quize3">
                        <img
                          src={kb_courses_group.rank ? light_star : gery_star}
                          className="practice_star"
                        ></img>
                        <span>再练一遍</span>
                        <img
                          src={practice_once}
                          className="practice_once"
                        ></img>
                      </div>
                    </div>
                  ) : (
                    <div className="next_quize ">
                      <div className="next_quize1"></div>
                      <div className="next_quize2"></div>
                      <div className="next_quize3">
                        <span>去闯关</span>
                        <img src={practice_go} className="practice_go"></img>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*  学习结果 或去学习 */}
            </div>
          )}
          {/* 课文巩固---------------课外拓展 */}
          {other_poetry_articles_group && !is_unit_practice && (
            <div
              className={
                is_unit_practice || pass
                  ? "course-consolidate"
                  : " course-consolidate course-consolidate-no "
              }
            >
              {/* 边框 */}
              <div className="course-title">
                <img src={practice_title} className="course-title-back"></img>
                <div className="course-title-font">课外拓展</div>
              </div>
              {/* 边框 ---------------- 课时 */}
              {other_poetry_articles_group &&
                other_poetry_articles_group.poetry_articles.map((item, idx) => {
                  return (
                    <div
                      className={
                        idx == 0 ? "expand-class first-item" : "expand-class"
                      }
                      key={"kk" + idx}
                      onClick={() => {
                        if (item.lock) {
                          this.setState({ showDig: true });
                        } else {
                          window.location.href = item.get_quizzes_url;
                        }
                      }}
                    >
                      <div className="expand-item">
                        <div className="expand-left">
                          {"《" + item.poetry_article_name + "》"}
                        </div>
                        <div className="expand-right">
                          {this.expandRight(!item.lock, item.pass, item.rank)}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/*  课时 */}
            </div>
          )}
          {/* 课外拓展---------------素材积累 */}
          {this.props.materials_group && (
            <div
              className={
                is_unit_practice || pass
                  ? "course-consolidate"
                  : " course-consolidate course-consolidate-no "
              }
            >
              {/* 边框 */}
              <div className="course-title">
                <img src={practice_title} className="course-title-back"></img>
                <div className="course-title-font">好词好句积累</div>
              </div>
              {/* 边框 ---------------- 课时问题 */}
              {this.props.materials_group.materials.map((item, index) => {
                return renderKnowlege(item, index, pass);
              })}

              {/*  课时问题 */}
            </div>
          )}
        </div>
        {/* 页面主内容------------------------弹窗 */}
        {this.state.showDig && (
          <div className="show-dig">
            <div
              className="show-dig-center"
              onClick={() => {
                this.setState({ showDig: false }, () => {
                  this.scrollToDay(this.state.selcedId + "grounp");
                });
              }}
            >
              <div className="show-dig-img">
                <img src={go_study_back}></img>
              </div>
              <div className="show-dig-tips">前面的闯关练习尚未完成哦~</div>
              <div className="show-dig-bottom">
                <div
                  className="next_quize"
                  onClick={() => {
                    this.setState({ showDig: false }, () => {
                      window.location.href =
                        get_quizzes_url || unit_practice_poetry_articles_url;
                    });
                  }}
                >
                  <div className="next_quize1"></div>
                  <div className="next_quize2"></div>
                  去闯关
                </div>
              </div>
            </div>
            <div
              className="go_study_cancle"
              onClick={() => {
                this.setState({ showDig: false });
              }}
            >
              <img src={btn_close}></img>
            </div>
          </div>
        )}
        {/* 弹窗 */}
        {/* 单元测试 底部导航 */}

        {(is_unit_practice ||
          (pass &&
            (!other_poetry_articles_group.poetry_articles[0].pass ||
              !other_poetry_articles_group.poetry_articles[1].pass))) && (
          <div className="is_unit_practice">
            {is_unit_practice && pass ? (
              <div
                className="next_quize next_quize_once "
                onClick={() => {
                  window.location.href = unit_practice_poetry_articles_url;
                }}
              >
                <div className="next_quize1"></div>
                <div className="next_quize2"></div>
                <div className="next_quize3">
                  <img
                    src={rank ? light_star : gery_star}
                    className="practice_star"
                  ></img>
                  <span>再练一遍</span>
                  <img src={practice_once} className="practice_once"></img>
                </div>
              </div>
            ) : (
              <div
                className="next_quize "
                onClick={() => {
                  // console.log(get_quizzes_url, "------------");
                  let url = unit_practice_poetry_articles_url
                    ? unit_practice_poetry_articles_url
                    : other_poetry_articles_group.poetry_articles[1].lock
                    ? other_poetry_articles_group.poetry_articles[0]
                        .get_quizzes_url
                    : other_poetry_articles_group.poetry_articles[1]
                        .get_quizzes_url;
                  window.location.href = url;
                }}
              >
                <div className="next_quize1"></div>
                <div className="next_quize2"></div>
                <div className="next_quize3">
                  <span>{is_unit_practice ? "去闯关" : "继续闯关"}</span>
                  <img src={practice_go} className="practice_go"></img>
                </div>
              </div>
            )}
          </div>
        )}
        {/* 单元测试 底部导航-----浮动菜单 */}
        <RightFloatIcons
          isShowRedMoney={true}
          onRedMoneyClick={this.onInvitationIconClick}
          isShowBack={this.props.float_menu.details_url ? true : false}
          onBackClick={() => {
            window.location.href = this.props.float_menu.details_url;
          }}
          // bottomPosition={
          //   // props.buy_countdown_endtime && !props.bought ? 140 : 100
          // }
        />
        {/* 浮动菜单-------升级提醒 */}
      </>
    );
  }
}
