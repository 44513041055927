/* eslint-disable react/prop-types */
import React, { useEffect, createRef, useCallback, useMemo } from "react";
// import PropTypes from "prop-types";
import {
  MyProvider,
  useSelector,
  useDispatch,
} from "../utils/react-global-state";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import RightFloatIcons from "../study_package_activities/RightFloatIcons";
// import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import AnalysisStore from "./AnalysisStore.js";
import RecommendExams from "./RecommendExams.jsx";

import ModalManager from "../utils/Modal/ModalManage.jsx";

import echarts from "echarts";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import ModalSimple from "../utils/Modal/ModalSimple.jsx";
import "./Analysis.scss";
// import "./RecommendExams.scss";

const DoubleArrow = () => {
  const itemStyle = useMemo(
    () => ({
      transform: "scale(.6, 1.1)",
      display: "inline-block",
      paddingBottom: 4,
    }),
    []
  );
  return (
    <span
      style={{
        color: "currentColor",
        marginLeft: 3,
        fontSize: 12,
        display: "inline-block",
        lineHeight: "1em",
      }}
    >
      <span style={itemStyle}>{">"}</span>
      <span style={{ ...itemStyle, marginLeft: -4 }}>{">"}</span>
    </span>
  );
};

const BannerTop = React.memo(function BannerTop() {
  const baby = useSelector((state) => state.baby);
  return (
    <div className="banner_top">
      <div className="user_baby">
        <img
          src={
            baby.photo_url ||
            (baby.gender === "男" ? iconBoyUser : iconGirlUser)
          }
          alt=""
        />
        <div className="babu_info">
          <div className="name">{baby.nickname}</div>
          <div className="grade">{baby.grade}</div>
        </div>
      </div>
      {/* <div className="version">Beta版</div> */}
      <a className="logo" href={window.location.href}></a>
      {/* <a
        className="thinking_lever_guide"
        href="/kb_dimensions/thinking_level_guide"
      >
        <span>思维能力等级说明</span>
        <DoubleArrow />
      </a> */}
    </div>
  );
});

const RulerDegree = React.memo(function RulerDegree({
  gradeLevel,
  gradeDegree,
  anchor,
  scoreTip,
}) {
  return (
    <div
      className={`ruler_divider ${
        gradeDegree === 10 ? "ruler_divider_fake" : ""
      }`}
      id={anchor}
    >
      {scoreTip}
      <div className="level_text">
        {gradeDegree % 2 !== 0 ? `${gradeLevel}.${gradeDegree}` : ""}
      </div>
    </div>
  );
});

const RulerDegreeWithScoreTip = React.memo(function RulerDegreeWithScoreTip(
  props
) {
  const { gradeLevel, gradeDegree, isGreenLevel } = props;
  const babyCurrentScore = useSelector((state) => state.baby.score);
  // text array
  const BabyCurrentLevel = useSelector((state) => state.baby.level);

  if (babyCurrentScore) {
    const [currentScoreLevel, currentGradeDegree] = babyCurrentScore.split(".");
    const degree = currentGradeDegree.replace("9+", "10") * 1;
    const anchor =
      currentScoreLevel === gradeLevel && degree === gradeDegree
        ? "current_level"
        : "";

    const scoreTip = currentScoreLevel === gradeLevel &&
      degree === gradeDegree && (
        <div className={isGreenLevel ? "top_tip green" : "top_tip yellow"}>
          <div className="inner_tip">
            <span>{babyCurrentScore}</span>
            {BabyCurrentLevel.map((t, index) => (
              <span key={index}>{t}</span>
            ))}
          </div>
        </div>
      );
    return <RulerDegree {...props} scoreTip={scoreTip} anchor={anchor} />;
  }

  return <RulerDegree {...props} />;
});

const LevelsSection = React.memo(function LevelsSection() {
  const gradeLevels = useSelector((state) => state.gradeLevels);
  const rulersContainer = createRef();
  const babyCurrentInternalScore = useSelector(
    (state) => state.baby.internal_score
  );
  useEffect(() => {
    const scrollEle = document.getElementById("current_level");
    if (scrollEle) {
      $("#ruler_sections").animate(
        {
          scrollLeft: $("#current_level").offset().left - $(window).width() / 2,
        },
        0
      );
    } else {
      const firstRuler = $(".ruler").eq(0);
      $("#ruler_sections").animate(
        {
          scrollLeft:
            firstRuler.offset().left +
            firstRuler.width() / 2 -
            $(window).width() / 2,
        },
        0
      );
    }
  }, []);

  return (
    <React.Fragment>
      <div className="ruler_sections" ref={rulersContainer} id="ruler_sections">
        <div className="half_ruler" id="_half_ruler"></div>
        {gradeLevels.map((level, index) => {
          const levelGreen = [...level.levels]
            .reverse()
            .find((l) => l.stage === 0).score;
          return (
            <div className="ruler" key={index} id="_ruler">
              <div className="dot-start">
                <div className="dot_wrapper">{level.grade}</div>
              </div>
              <div className="handler">
                <div className="handler_wrapper_top">
                  {/* <RulerDegreeWithScoreTip
                    gradeLevel={level.grade}
                    isGreenLevel={true}
                    gradeDegree={0}
                  /> */}
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, idx) => (
                    <RulerDegreeWithScoreTip
                      key={idx}
                      isGreenLevel={levelGreen >= babyCurrentInternalScore}
                      gradeLevel={level.grade}
                      gradeDegree={item}
                    />
                  ))}
                  {/* <RulerDegreeWithScoreTip
                    isGreenLevel={levelGreen >= babyCurrentInternalScore}
                    gradeLevel={level.grade}
                    gradeDegree={10}
                  /> */}
                </div>
                <div className="handler_wrapper_bottom">
                  {level.levels.map((l, index) => (
                    <div
                      key={index}
                      className="lever_item"
                      style={{
                        flex:
                          l.score -
                          (level.levels[index - 1]
                            ? level.levels[index - 1].score
                            : 0),
                      }}
                    >
                      <p>{l.name}</p>
                    </div>
                  ))}
                </div>
                <div
                  className="inner_handler"
                  style={{
                    color: "red",
                    backgroundImage: `linear-gradient(
                      90deg,
                      #42dd76 0%,
                      #3dcc6d ${levelGreen / 10 + 1}%,
                      #ffc600 ${levelGreen / 10 - 1}%,
                      #ffd649 100%
                    )`,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
        <div className="half_ruler"></div>
      </div>

      <div className="ruler_legend">
        <div className="left legend_common">基础</div>
        <div className="right legend_common">拓展</div>
      </div>
    </React.Fragment>
  );
});

const useShowRecommendExams = () => {
  const recommend_exams = useSelector((state) => state.recommend_exams);
  const recommend_first = useSelector((state) => state.recommend_first);
  const recommend_title = useSelector((state) => state.recommend_title);
  const baby = useSelector((state) => state.baby);
  const dispatch = useDispatch();
  const modal = useMemo(
    () =>
      new ModalManager({
        render: (_, modalClose) => {
          const onClose = () => {
            dispatch(AnalysisStore.actions.showRecommendExams(false));
            modalClose();
          };
          return (
            <RecommendExams
              baby={baby}
              recommend_first={recommend_first}
              recommend_title={recommend_title}
              recommend_exams={recommend_exams}
              modalClose={onClose}
            />
          );
        },
      }),
    [baby, dispatch, recommend_exams, recommend_first, recommend_title]
  );
  return modal.open;
};

const OverallAnalysisSection = React.memo(function OverallAnalysisSection() {
  const {
    tested_dimension_examination_count: testedExamCount,
    dimension_examinations,
    general: { level, detail },
    dimensions: { detail: dimensionDetail },
  } = useSelector((state) => state.description);

  const dispatch = useDispatch();

  const setShowRecommendExams = () => {
    dispatch(AnalysisStore.actions.showRecommendExams(true));
  };

  return (
    <section className="overall_analysis">
      <span className="title">综合分析</span>
      <p className="data_from">
        <span className="normal">本结果数据来源于：</span>
        {dimension_examinations.map((exam, index) => {
          if (index === dimension_examinations.length - 1) {
            return (
              <a
                href={exam.url}
                key={index}
                className="strong"
              >{`${exam.name}`}</a>
            );
          }
          return (
            <a
              href={exam.url}
              key={index}
              className="strong"
            >{`${exam.name}、`}</a>
          );
        })}
        <span className="normal">
          {testedExamCount > 3 ? `等${testedExamCount}个` : ""}
          。想要得到更精准的测评结果？
        </span>
        <span className="strong bold" onClick={setShowRecommendExams}>
          查看老师的测评推荐
          <DoubleArrow />
        </span>
      </p>
      <div className="overall">总的来说</div>
      <div
        className="analysis"
        dangerouslySetInnerHTML={{
          __html: `<div class="general_level">${level}</div><div class="detail">${
            detail || ""
          }</div>`,
        }}
      />
      <div className="overall">从单个维度来看</div>
      <p
        className="analysis"
        dangerouslySetInnerHTML={{
          __html: `${dimensionDetail}`,
        }}
      />
    </section>
  );
});

const RadarSection = React.memo(function RadarSection() {
  const baby = useSelector((state) => state.baby);
  const kb_dimensions = useSelector((state) => state.kbDimensions);
  const radarLayers = useSelector((state) => state.radarLayers);
  const kb_dimension_groups = kb_dimensions;
  console.log("kb_dimension_groups", kb_dimension_groups);
  useEffect(() => {
    const indicatorArray = []; // 雷达各个边界相应数值
    const minorArray = []; // minor 所获等级数
    const schoolAbilityValues = ["83", "85", "100", "87", "86", "93"]; // 心芽孩子能力值
    const babyAbilityValues = []; // 宝宝能力值
    const groupScores = []; // 测试成绩集合 如: ["G1.5", "", ""]
    let groupScore; // 测试成绩 如: G1.5
    kb_dimension_groups.map((kb_group, index) => {
      let minor;
      let radarScore;
      const description = kb_group.description
        ? `\n(${kb_group.description})`
        : "";
      const name = `${kb_group.name ? `${kb_group.name}` : ""}`;
      const lessNinetyScore = (minor, kb_group) => {
        return minor >= 10
          ? Number((kb_group.score.major.substr(1) + "." + minor).slice(0, -1))
          : kb_group.score.major.substr(1) + ".0";
      };

      if (kb_group.score) {
        minor = Number(`${kb_group.score.minor}`);
        radarScore =
          minor > 90
            ? `${kb_group.score.major.substr(1)}.9+`
            : lessNinetyScore(minor, kb_group);
        groupScore = `${kb_group.score.band}${radarScore}`;
        minorArray.push("minor" in kb_group.score ? minor : "");
      } else {
        groupScore = "";
      }
      indicatorArray.push({
        name: `${name}${groupScore ? `\n${groupScore}` : description}`,
        max: 100,
        color: "#333",
      });
      groupScores.push(groupScore);
      babyAbilityValues.push(kb_group.score ? kb_group.score.unified_score : 0);
    });
    console.log("minorArray (minor)", minorArray);
    console.log("babyAbilityValues（unified_score）: ", babyAbilityValues);
    console.log("schoolAbilityValues (心芽大班孩子水平) ", schoolAbilityValues);
    console.log("indicatorArray: 雷达图显示区域", indicatorArray);
    // console.log("groupScores 整项成绩集合: ", groupScores);

    // 基于准备好的容器(这里的容器是id为chart1的div)，初始化echarts实例
    var chart1 = echarts.init(document.getElementById("chart1"));

    // 指定图表的配置项和数据
    var option = {
      backgroundColor: "rgba(255,255,255,1)", // 背景色，默认无背景	rgba(51,255,255,0.7)
      radar: [
        {
          // 雷达图坐标系组件，只适用于雷达图。
          center: ["50%", "50%"], // 圆中心坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: document.body.clientWidth <= 320 ? "45%" : "50%", // 圆的半径，数组的第一项是内半径，第二项是外半径。
          startAngle: 90, // 坐标系起始角度，也就是第一个指示器轴的角度。[ default: 90 ]
          name: {
            formatter: [
              `{value}`, // 名称
              // `{detailStyle|详情}` // 详情
            ].join("\n"),

            textStyle: {
              // align: "center",
              lineHeight: 18,
              fontSize: document.body.clientWidth <= 320 ? 10 : 11,
              color: "#333",
            },
          },
          nameGap: 15, // 指示器名称和指示器轴的距离。[ default: 15 ]
          splitNumber: `${radarLayers ? `${radarLayers}` : 1}`, // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
          shape: "circle", // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
          axisLine: {
            // (圆内的几条直线)坐标轴轴线相关设置
            lineStyle: {
              color: "#ccc", // 坐标轴线线的颜色
              opacity: 0.5,
              width: 1, // 坐标轴线线宽。
              type: "solid", // 坐标轴线线的类型。
            },
          },
          splitLine: {
            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
            lineStyle: {
              color: "#f0f0f0", // 分隔线颜色
              opacity: 0.8,
              width: 2, // 分隔线线宽
            },
          },
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,1)", "rgba(255,255,255,1)"],
              // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          indicator: indicatorArray,
          triggerEvent: true, // add event
        },
      ],
      series: [
        {
          name: "雷达图", // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
          type: "radar", // 系列类型: 雷达图
          itemStyle: {
            // 折线拐点标志的样式。
            normal: {
              // 普通状态时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 0, // 控制每个折点的显示/隐藏
            },
            emphasis: {
              // 高亮时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 1,
            },
          },
          data: [
            // {
            //   name: "心芽大班孩子中位水平",
            //   value: schoolAbilityValues,
            //   symbol: "circle",
            //   symbolSize: schoolAbilityValues.length,
            //   label: {
            //     normal: {
            //       show: false,
            //       position: "top",
            //       distance: 5,
            //       color: "#8080ff",
            //       fontSize: 14
            //     }
            //   },
            //   itemStyle: {
            //     normal: {
            //       color: "#8080ff",
            //       borderColor: "#8080ff",
            //       borderWidth: 1
            //     }
            //   },
            //   lineStyle: {
            //     normal: {
            //       type: "dashed",
            //       color: "#8080ff",
            //       opacity: 0.5
            //     }
            //   },
            //   areaStyle: {
            //     normal: {
            //       opacity: 0.2,
            //       color: "rgb(128, 128, 255)"
            //     }
            //   }
            // },
            {
              name: "小宝能力值",
              value: babyAbilityValues,
              symbol: "circle",
              symbolSize: babyAbilityValues.length,
              label: {
                normal: {
                  show: false,
                  position: "top",
                  distance: 5,
                  color: "#ffc600",
                  fontSize: 14,
                },
              },
              itemStyle: {
                normal: {
                  color: "#ffc600",
                  borderColor: "#ffc600",
                  borderWidth: 1,
                },
              },
              lineStyle: {
                normal: {
                  opacity: 0.5,
                },
              },
              areaStyle: {
                normal: {
                  opacity: 0.8,
                  color: "#ffc600",
                },
              },
            },
          ],
        },
      ],
    };

    chart1.setOption(option);
    window.onresize = function () {
      chart1.resize();
    };
  }, [kb_dimension_groups, radarLayers]);
  return (
    <div className="skill_module_section">
      <div className="skill_title">{baby.nickname}的能力模型</div>
      {/* <div className="main_text_section">
        <div className="baby_skill_value">{baby.nickname}</div>
        <div className="school_skill_value">心芽大班孩子中位水平</div>
      </div> */}
      <div id="chart1" className="e_chart" />
    </div>
  );
});

const CourseRecommend = React.memo(function CourseRecommend() {
  const courseRecommendation = useSelector(
    (state) => state.courseRecommendation
  );
  return (
    <div className="course_recommend_container">
      <div className="course_commend_text">课程推荐</div>
      <div
        className="course_info_describe"
        dangerouslySetInnerHTML={{
          __html: `${courseRecommendation.text}`,
        }}
      />
      <a
        className="course_recommend_list"
        style={{
          backgroundImage: `url(${courseRecommendation.activity.photo_url})`,
        }}
        href={courseRecommendation.activity.url}
      >
        <h2>{courseRecommendation.activity.name}</h2>
        <p>查看课程</p>
      </a>
    </div>
  );
});

const RecommendationsSection = React.memo(function RecommendationsSection() {
  const recommendations = useSelector((state) => state.recommendations);
  const {
    teacher_photo,
    message,
    cc_qrcode_url,
    teacher_name,
    teacher_wx,
  } = useSelector((state) => state.courseConsultant);
  const examCategory = useCallback(
    (r, index) => (
      <div className="exam_item" key={index}>
        <p>{r.brief}</p>
        <div className="text">
          <div className="exam_level">
            {r.exam_type === 0 ? (
              <span className="exam_type all">综合测评</span>
            ) : (
              <span className="exam_type normal">专项测评</span>
            )}

            <span className="level">{r.name}</span>
          </div>
          <a className="to_exam" href={r.url}>
            去测评
          </a>
        </div>
      </div>
    ),
    []
  );
  const resourceCategory = useCallback((r, index) => {
    return (
      <div className="exam_item" key={index}>
        <div className="text">
          <div className="exam_level">
            <span className="exam_type resource_type">资源推荐</span>
            <span className="level">{r.abbr}</span>
          </div>
          <div className="resource">
            <img src={r.photo_url} alt="" />
            <div className="name_brief">
              <div className="name">{r.name}</div>
              <div className="brief">{r.brief}</div>
            </div>
          </div>
        </div>
        <a className="to_exam" href={r.url}>
          查看资源
        </a>
      </div>
    );
  }, []);

  const categoryMap = useMemo(
    () => ({
      1: resourceCategory,
      0: examCategory,
    }),
    [examCategory, resourceCategory]
  );

  return (
    <section className="recommendation">
      <div className="title">老师指导推荐</div>
      {recommendations.map((r, index) => categoryMap[r.category](r, index))}
      <div className="cc_container">
        <img src={teacher_photo} alt="" className="cc_photo" />
        <div className="right_message">
          <div className="message">
            {message}
            <div className="left_arrow"></div>
          </div>
          <div className="qr">
            <img src={cc_qrcode_url} alt="" />
            <p>{teacher_name}</p>
            <p>{`微信号：${teacher_wx}`}</p>
          </div>
        </div>
      </div>
    </section>
  );
});

const RightFloatIconsSection = React.memo(function RightFloatIconsSection() {
  const popupPoster = useSelector((state) => state.popup_poster);
  const posterCreation = useSelector((state) => state.poster_creation);
  const posterUrl = useSelector((state) => state.poster_url);
  const posterImgSrc = useSelector((state) => state.poster_img_src);
  const posterTemplate = useSelector(
    (state) => state.poster_photo_template_url
  );
  const maxInviteUsersCount = useSelector(
    (state) => state.max_invite_users_count
  );
  const bonus = useSelector((state) => state.bonus);
  const free = "shared_for_analysis";
  useEffect(() => {
    if (popupPoster) {
      const sharePosterProxy = new SharePosterProxy({
        posterUrl: posterUrl,
        posterImgSrc: posterImgSrc,
        posterTemplate: posterTemplate,
        posterCreation: posterCreation,
        mode: "front_end",
        free: free,
        maxInviteUsersCount: maxInviteUsersCount,
      });
      sharePosterProxy.open();
    }
  }, [popupPoster, posterTemplate, posterCreation, posterUrl, posterImgSrc, maxInviteUsersCount]);

  const isShowComment = false;
  const onCommentClick = useCallback(() => {
    if (!window.location.hash) {
      window.location.href = `${window.location.href}"#comment"`;
      window.location.reload(true);
    }
  }, []);

  const onRedMoneyClick = useCallback(() => {
    const sharePosterProxy = new SharePosterProxy({
      posterUrl: posterUrl,
      posterImgSrc: posterImgSrc,
      posterTemplate: posterTemplate,
      posterCreation: posterCreation,
      mode: "front_end",
      free: free,
      maxInviteUsersCount: maxInviteUsersCount,
    });
    sharePosterProxy.open();
  }, [
    posterTemplate,
    posterCreation,
    posterUrl,
    posterImgSrc,
    maxInviteUsersCount,
  ]);

  return (
    <RightFloatIcons
      zIndexLevel={13}
      bottomPosition={40}
      isShowComment={isShowComment}
      onCommentClick={onCommentClick}
      isShowRedMoney={!!bonus}
      onRedMoneyClick={onRedMoneyClick}
    />
  );
});

const Analysis = () => {
  const share = useSelector((state) => state.share);
  const description = useSelector((state) => state.description);
  const show_recommend_exams = useSelector(
    (state) => state.show_recommend_exams
  );
  const gradeLevels = useSelector((state) => state.gradeLevels);
  const courseRecommendation = useSelector(
    (state) => state.courseRecommendation
  );
  const showRecommendExamsModal = useShowRecommendExams();

  useEffect(() => {
    const share_url = share.url;
    const share_title =
      "重磅来袭！心芽匠心打造的《思维能力等级测评》早鸟免费测！";
    const share_description = "600+知识点，1000+题型，精准定位孩子思维水平~";
    const share_image_url = share.image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }, [share.image_url, share.url]);

  useEffect(() => {
    if (show_recommend_exams) {
      showRecommendExamsModal();
    }
  }, [showRecommendExamsModal, show_recommend_exams]);

  return (
    <div className="comp_kb_analysis">
      <BannerTop />
      {gradeLevels && <LevelsSection />}
      {description && <OverallAnalysisSection />}
      <RadarSection />
      {courseRecommendation && <CourseRecommend />}
      <RecommendationsSection />
      <RightFloatIconsSection />
    </div>
  );
};

const WithProvider = (props) => {
  console.log(props);
  return (
    <MyProvider
      store={AnalysisStore.build({
        gradeLevels: props.grade_levels,
        baby: props.baby,
        radarLayers: props.radar_layers,
        description: props.description,
        recommendations: props.recommendations,
        courseConsultant: props.course_consultant,
        recommend_first: props.recommend_first,
        recommend_title: props.recommend_title,
        recommend_exams: props.recommend_exams,
        kbDimensions: props.kb_dimensions,
        show_recommend_exams: !props.show_recommend_exams,
        popup_poster: props.popup_poster,
        poster_url: props.poster_url,
        poster_img_src: props.poster_img_src,
        poster_creation: props.poster_creation,
        poster_photo_template_url: props.poster_photo_template_url,
        courseRecommendation: props.course_recommendation,
        max_invite_users_count: props.max_invite_users_count,
        share: props.share,
        bonus: props.bonus,
      })}
    >
      <Analysis />
    </MyProvider>
  );
};

export default WithProvider;
