import React from "react";
import Image from "@/components/utils/Image";
import { onNavigate } from "@/components/utils/helpers";
import "./Friends.scss";

export default class Hello extends React.Component {

  constructor(props) {
    super(props)

    console.log('props', props);
  }

  renderFriends() {
    const props = this.props;
    return (
      <>
        {
          this.props.friends.map((friend, idx) => {
            return <div style={{ display: 'inline' }} key={friend.id}>{idx > 0 ? '、' : ''}<div className="friend">{friend.photo_url && <img className="avatar" src={friend.photo_url} />}<span style={{ color: '#1493d4' }}>{friend.name}</span></div></div>
          })
        }
      </>
    );
  }

  handleClick(e) {
    const url = e.currentTarget.getAttribute("data-url");
    onNavigate(e, url);
  }

  render() {
    const props = this.props;
    return (
      <div>
        <div style={{ marginTop: '10px', padding: '0 5px' }}><span style={{ color: '#1493d4' }}>{props.current_user.name}</span>您好！</div>
        {(!props.activities || props.activities.length === 0) &&
          <div style={{ textIndent: '30px', marginBottom: '15px', padding: '0 5px' }}>根据您目前在心芽的邀请数据过少，您的朋友目前尚无购课数据，请多邀请朋友后再查阅哦！</div>
        }
        {props.activities && props.activities.length > 0 &&
          <div style={{ textIndent: '30px', marginBottom: '15px', padding: '0 5px' }}>您在心芽的朋友，{this.renderFriends()}等{props.friends_count}位好友，在心芽已购买了这些课：</div>
        }
        <div className="container-activities">
          {props.activities.map(activity =>
            <div
              key={activity.id}
              className="activity_brief_content"
              data-url={activity.buy_url}
              onClick={(e) => this.handleClick(e)}
            >
              <div className="left_thumbnail_common">
                <Image
                  className="lazyload lazyloading"
                  url={activity.recommend_thumb_photo_url}
                />
              </div>
              <div className="right_content_box">
                <div className="content_box">
                  {/* <div><span className="act_type">系列课</span></div> */}
                  <div className="course_title">{activity.name}</div>
                  <div className="recomment_brief">
                    {activity.recommend_brief}
                  </div>
                </div>
                {activity.isShowLabels && (
                  <div className="labels">
                    {!!(
                      activity.lessonsOrCourseCount || activity.course_day_count
                    ) && (
                      <div className="label_left">
                        {!!activity.lessonsOrCourseCount && (
                          <>
                            <span className="lessons">
                              {`${activity.lessonsOrCourseCount}${
                                activity.isPoetry ? "首诗" : "节课"
                              }`}
                            </span>
                          </>
                        )}

                        {!!(
                          activity.lessonsOrCourseCount &&
                            activity.course_day_count &&
                            !activity.is_user_study_package
                        ) && <span className="split">·</span>}

                        {!!activity.course_day_count &&
                            !activity.is_user_study_package && (
                              <span className="days">{`${activity.course_day_count}天`}</span>
                            )}
                      </div>
                    )}
                    {activity.suitable_grades &&
                        activity.suitable_grades.length > 0 && (
                          <div className="label_right">
                            <span className="suit">适合</span>
                            <span className="grades">
                              {activity.suitable_grades.join(" ")}
                            </span>
                          </div>
                        )}
                  </div>
                )}
                <div className="course_study_progress">
                  <div className="left_progress">
                    {activity.bought_count}人已购买
                  </div>
                  {activity.bought_state === "bought" ? (
                    <div className="bought">
                      <Image
                        className="lazyload lazyloading"
                        url={iconConfirm}
                      />
                      {/* <img
                          className="lazyload lazyloading"
                          data-src={iconConfirm}
                        /> */}
                      已购买
                    </div>
                  ) : (
                    <div className="right_now_class">查看详情</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {props.promotion &&
          <div className="container-promotion">
            <div className="title">618特别优惠</div>
            <div><img className="img-promotion" src={props.promotion.photo_url} data-url={props.promotion.url} onClick={(e) => this.handleClick(e)} /></div>
            <div className="description">详情咨询<br />{props.promotion.cc_name}</div>
            <div><img className="img-qrcode" src={props.promotion.cc_qrcode_url} /></div>
          </div>
        }
      </div>
    )
  }
}
