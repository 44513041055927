// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon-gift.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp-floating-gift-btn{width:50px;height:50px;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:18px;background-position:15px 15px;border-radius:50px;text-align:center;line-height:50px;margin-bottom:10px;position:fixed;bottom:95px;right:15px;border:.5px solid rgba(0,0,0,.05);box-shadow:0 1px 2px rgba(0,0,0,.05);z-index:13}", ""]);
// Exports
module.exports = exports;
