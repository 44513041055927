/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TTSRecorder from "../../../admin-utils/generate_tts_helpers/ttsReorder";
import {
  downloadWAV,
  getWAVFile,
} from "../../../admin-utils/generate_tts_helpers/download";
import { request, csrfHeaders } from "../../../components/utils/request";
const Container = styled.div`
  display: flex;
  div {
    margin: 0 10px;
  }
`;

const uploadToQiNiu = (file, token) =>
  new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("name", "");
    fd.append("token", token);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://upload.qiniup.com", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          const obj = JSON.parse(xhr.responseText);
          console.log(obj);
          resolve(obj);
        } else {
          reject(JSON.parse(xhr.responseText).error);
        }
      }
    };
    xhr.onerror = (e) => {
      console.error(e);
      // if (UserAgent.isNativeClient()) {
      //   alert("上传成功！");
      // } else {
      // }
      reject(e);
    };

    xhr.send(fd);
  });

const uploadFile = async ({ file, generateTokenUrl }) => {
  const res = await request({
    url: generateTokenUrl,
    method: "GET",
    headers: csrfHeaders,
  });
  const token = res.data.token;
  const audio = await uploadToQiNiu(file, token);
  console.log(audio);
  return { id: audio.id };
};

export default (props) => {
  console.log(props);

  const [isUpload, setIsUpload] = useState(false);
  const ttsRecorder = useMemo(() => new TTSRecorder(), []);
  const onWillStatusChange = useCallback(function (oldStatus, status) {
    const btnState = {
      init: "立即合成",
      ttsing: "正在合成",
      play: "停止播放",
      endPlay: "重新播放",
      errorTTS: "合成失败",
    };
    $(`.${props.voiceButton}`)
      .removeClass(oldStatus)
      .addClass(status)
      .text(btnState[status]);
  }, []);

  // const onMp3Audio = useCallback(() => {
  //   console.log(ttsRecordeisUploadr.rawAudioData);
  //   if (ttsRecorder.rawAudioData.length) {
  //     downloadMP3(
  //       new DataView(new Int16Array(ttsRecorder.rawAudioData).buffer),
  //       16000,
  //       16
  //     );
  //   } else {
  //     alert("请先合成");
  //   }
  // }, [ttsRecorder]);

  const onWAVAudio = useCallback(() => {
    console.log(ttsRecorder.rawAudioData);
    if (ttsRecorder.rawAudioData.length) {
      downloadWAV(
        new DataView(new Int16Array(ttsRecorder.rawAudioData).buffer),
        16000,
        16
      );
    } else {
      alert("请先合成");
    }
  }, [ttsRecorder]);

  useEffect(() => {
    const textId = props.textId;
    $(`#${textId}`).change(function () {
      ttsRecorder.setParams({
        text: this.value,
      });
    });
    ttsRecorder.onWillStatusChange = onWillStatusChange;
    $(`.${props.voiceButton}`).on("click", function () {
      const text = document.getElementById(textId).value;
      if (!text) {
        alert("请输入评论");
        return;
      }

      const audio_type = props.audioType;
      const speed = props.speed;
      console.log("========", speed);
      let voiceCode = "";
      if (audio_type == "quiz") {
        const temp_type = document.getElementById("temp_type").value;
        if (!temp_type) {
          alert("请选择音频老师");
          return;
        }
        voiceCode = document.getElementById("temp_type").value;
      } else {
        voiceCode = props.voiceCode;
      }
      ttsRecorder.setParams({
        text,
        voiceCode,
        speed,
      });

      if (["init", "endPlay", "errorTTS"].indexOf(ttsRecorder.status) > -1) {
        ttsRecorder.start();
      } else {
        ttsRecorder.stop();
      }
    });
  }, [onWillStatusChange, ttsRecorder]);

  const upload = async () => {
    if (!ttsRecorder.rawAudioData.length) return alert("请先合成");
    if (isUpload) return;
    console.log(
      getWAVFile(
        new DataView(new Int16Array(ttsRecorder.rawAudioData).buffer),
        16000,
        16
      ),
      props.generateTokenUrl
    );
    try {
      setIsUpload(true);
      const { id } = await uploadFile({
        file: getWAVFile(
          new DataView(new Int16Array(ttsRecorder.rawAudioData).buffer),
          16000,
          16
        ),
        generateTokenUrl: props.generateTokenUrl,
      });
      $(`#${props.inputId}`).val(id);
      setIsUpload(false);
    } catch (e) {
      setIsUpload(false);
      alert("上传出错，请重试");
      console.log(e);
    }
  };

  return (
    <Container>
      <div className={`btn btn-secondary audio-ctrl-btn ${props.voiceButton}`}>合成语音</div>
      <div className="btn btn-primary" onClick={upload}>
        {isUpload ? "正在上传" : "上传语音"}
      </div>
      <div className="btn btn-secondary" onClick={onWAVAudio}>
        下载wav语音
      </div>
    </Container>
  );
};
