/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

import { convertURLToJSON, onNavigate } from "../utils/helpers";
import { GA } from "../utils/Env";
import UserAgent from "../utils/UserAgent";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import {
  SharePoster,
  SharePosterProxy,
} from "../promotion_activities/SharePoster";
import { Dialog, DialogManager } from "../utils/Dialog";
import StudyPackageActivity from "./StudyPackageActivityNew";
import { format as formatTime, formatDotDate } from "../utils/formatAudioTime";
import TrialActivity from "./TrialActivity";
import ChoosePhase from "./ChoosePhase";
import { ToastManager } from "../utils/Toast";
import { csrfHeaders, request } from "../utils/request";
import CourseCoupons from "./CourseCouponsNew";
import { onGroupBuy, onDirectBuy } from "./ShoppingBar";
import { WechatFollow } from "./WechatFollow";
import ChooseGradeModal from "../utils/choose_grade_modal/ChooseGradeModal.jsx";
import {
  ActivityBanner,
  ActivityRightFloatIcons,
  ActivityPeriodStatus,
  ActivityCourseFitDescribe,
  ActivityCourseFitDescribeRed,
  ActivityUserRecommend,
} from "./activity_components";
import { ActivityTabGroup } from "./activity_components/ActivityTabGroup";
import { discountModal } from "./InvitateFriendHitModals";
import { showQuestionnaireActivityModal } from "./StudyPackageActivityTemplate";

export const trialShow = (trial_activities, activityId) => {
  return (
    <TrialActivity trialActivities={trial_activities} activityId={activityId} />
  );
};

export const showCouponDialog = (ecoupon_redirect_url) => {
  // eslint-disable-next-line prefer-const
  let confirmDialog;
  const buttons = [
    {
      label: "前往报名",
      onClick: () => {
        confirmDialog.close();
        window.location.href = ecoupon_redirect_url;
      },
    },
  ];
  confirmDialog = DialogManager.open(
    <Dialog buttons={buttons}>
      您有一张免拼团直接报名的优惠券，可用于当前课程报名
    </Dialog>
  );
};

export const beforeSale = (props) => {
  if (props.sale_mode === "normal" && props.day_limit_users_count > 0) {
    const date = new Date(props.buy_starttime);
    const now = new Date();
    const todayStart = new Date();
    todayStart.setHours(date.getHours());
    todayStart.setMinutes(date.getMinutes());
    return now - todayStart < 0;
  } else {
    const buyStartTime = new Date(props.buy_starttime);
    const now = new Date(props.system_time);
    return now - buyStartTime < 0;
  }
};

export const buyStartTimeStr = (props) => {
  const bugStartTime = formatTime(props.buy_starttime, "YYYY-MM-DD HH:mm");
  return bugStartTime;
};

export const beforeSaleHeader = (props) => {
  const buyStartTime = buyStartTimeStr(props);
  let text = "";
  if (
    props.sale_mode == "shopping_group_new_member_only" ||
    props.sale_mode == "shopping_group_bargain" ||
    props.sale_mode == "shopping_group" ||
    props.sale_mode == "invitation_fission"
  ) {
    text = `本期名额已满，下期将于${buyStartTime}开售`;
    const startTime = new Date(props.buy_starttime);
    const now = new Date();
    if (now > startTime) {
      text = "本期名额已满";
    }
    if (props.limit_users_count > 0) {
      text = `将于${buyStartTime}开始报名，限量${props.limit_users_count}份`;
    }
  } else {
    text = `将于${buyStartTime}开始报名`;
    if (props.limit_users_count > 0) {
      text = `将于${buyStartTime}开始报名，限量${props.limit_users_count}份`;
    }
  }
  return (
    <div className="before_sale_count_down tips_text">
      <span className="before_sale_text">{text}</span>
    </div>
  );
};

export const handleBeforeSale = (props) => {
  // eslint-disable-next-line prefer-const
  if (
    props.sale_mode == "shopping_group_new_member_only" ||
    props.sale_mode == "shopping_group_bargain" ||
    props.sale_mode == "shopping_group" ||
    props.sale_mode == "invitation_fission"
  ) {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => confirmDialog.close(),
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        <p>{`下期将于${formatDotDate(props.buy_starttime)}开售\n`}</p>
        <p>{"可添加老师进行咨询"}</p>
        <img
          data-src={props.cc_qrcode_url}
          className="lazyload"
          data-sizes="auto"
          style={{ width: 160 + "px", height: 160 + "px" }}
        />
      </Dialog>
    );
    GA("Courses", "Subscribe", props.study_package_activity_id);
  } else {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => confirmDialog.close(),
      },
    ];
    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>还没到报名时间，请耐心等候</Dialog>
    );
  }
};

export class BaseStudyPackageActivity extends React.Component {
  constructor(props) {
    super(props);
    console.log("BaseStudyPackageActivity_props: ", props);
    this.state = {
      isCourseContainer: "block",
      activeSwitch1: "active",
      selectIndex1: -1,
      end_time: props.end_time,
      studyPackageActivityType: props.study_package_activity_type,
      absenteeActivity: props.absentee_activity,
      hasBoughtAny: props.has_bought_any,
      isShowStartTime: props.is_show_start_time,
      phases: props.phases,
      current_tag_id: 0,
      ecoupons: this.props.ecoupons,
      firstGroup: [],
      ecoupons_available: this.props.ecoupons_available,
    };
  }

  // 点击用户评价
  onClickEvalute = (e) => {
    var url = this.props.share_url;
    const {
      study_package_category_id,
      sub_study_package_activities,
    } = this.props;

    if (this.props.comments_url) {
      onNavigate(e, this.props.comments_url);
    }

    const share_url = url + "#comment";
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    const share_image_url = this.props.share_image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        link: share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
      wx.miniProgram.getEnv(function (res) {
        console.log(res.miniprogram); // true
        if (res.miniprogram) {
          var shareURL = share_url;
          if (!shareURL) {
            shareURL = window.location.href;
          }
          const share_string = convertURLToJSON(shareURL);
          wx.miniProgram.postMessage({
            data: {
              title: share_title,
              imgUrl: share_image_url,
              link: share_string,
            },
          });
        }
      });
    });
  };

  onRedMoneyClick = () => {
    const {
      had_joined_groupon,
      shopping_group,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      bonus,
      bonus_type,
      remaining_shopping_group_count,
      bought,
      sale_mode,
      sub_study_package_activities,
      avatar_location,
    } = this.props;
    console.log("bought", bought);
    const free =
      sale_mode == "invitation_fission"
        ? "invitation_fission_group"
        : (had_joined_groupon ||
            (shopping_group && had_joined_groupon === false)) &&
          remaining_shopping_group_count > 0 &&
          !bought
        ? "in_or_open_shopping_group"
        : "none_shopping_group";

    new SharePosterProxy({
      // posterUrl: this.props.poster_url,
      // posterImgSrc: this.props.poster_img_src,
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus,
      bonus_type,
      sale_mode,
      remaining_shopping_group_count,
      singleCourse: sub_study_package_activities.length <= 1,
      avatar_location: avatar_location,
    }).open();

    GA("shortcut", "shareNormal", this.props.study_package_activity_id);
  };

  onCommentClick = (e) => {
    // if (!window.location.hash) {
    //   window.location.href = window.location.href + "#comment";
    //   window.location.reload(true);
    // } else {
    //   if (window.location.href.includes("#comment")) {
    //     $("#comment_tab").trigger("click");
    //   } else {
    //     window.location.hash = "#comment";
    //     window.location.reload(true);
    //   }
    // }
    if (this.props.comments_url) {
      onNavigate(e, this.props.comments_url);
    }
    GA("shortcut", "packageComment", this.props.study_package_activity_id);
  };

  choosePhaseClick = (phase) => {
    const absentee_time_url = this.props.absentee_time_url;
    var params = {};
    // eslint-disable-next-line eqeqeq
    if (phase.id == -1) {
      params = {
        start_time: phase.start_time,
      };
    } else {
      params = {
        id: phase.id,
      };
    }
    if (this.state.absenteeActivity.order_id) {
      params.order_id = this.state.absenteeActivity.order_id;
    }
    if (this.state.absenteeActivity.groupon_id) {
      params.groupon_id = this.state.absenteeActivity.groupon_id;
    }
    if (this.state.absenteeActivity) {
      params.absentee_activity_id = this.state.absenteeActivity.id;
    }
    this.toast = ToastManager.showLoading("请稍等");
    request({
      url: absentee_time_url,
      data: params,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((res) => {
        this.toast.cancel();
        window.location.href = res.data.redirect_url;
      })
      .catch(() => {
        this.toast.cancel();
      });
  };

  choosePhaseDiss = () => {
    if (
      this.state.absenteeActivity &&
      this.props.current_user_shopping_group &&
      this.props.current_user_shopping_group.state.includes("已支付押金") &&
      this.props.remaining_shopping_group_count > 0
    ) {
      const { alertPoster } = this.props;
      if (typeof alertPoster === "function") {
        const buyEndTime = this.props.buy_endtime;
        const buyEndTimeDate = new Date(buyEndTime);
        const timeNow = new Date();
        if (
          buyEndTimeDate > timeNow &&
          this.props.sub_activities_limit_users_count_all_exceed !== true
        ) {
          const params = Object.assign({}, this.props);
          params.free = "in_or_open_shopping_group";
          alertPoster(params);
        }
      }
    }
  };

  // 引导关注微信公众号
  onWechatFollow = (e) => {
    this.wechatFollowRef.showWechatFollow();
  };

  directBuyCourse = (
    selectMinMoney,
    selectMaxMoney,
    activityTargetId,
    selectName
  ) => {
    let confirmDialog;
    // 还没开售
    if (beforeSale(this.props)) {
      handleBeforeSale(this.props);
      return;
    }
    // 异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (
      (this.props.sale_mode != "shopping_group_new_member_only") &
      (this.props.study_package_group_state === "bought_other")
    ) {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          为了孩子的学习效果，建议您不要同时报名多个课程
        </Dialog>
      );
      return;
    }

    GA("Courses", "directBuy", this.props.study_package_activity_id);

    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (this.props.qrcode_url != null) {
      this.onWechatFollow();
    }
  };

  showCourseCouponPay(
    finalPrice,
    finalState,
    selectActivityId,
    selectName,
    coursePayMode,
    selectIndex,
    trial
  ) {
    if (finalState === "pay_remaining") {
      // eslint-disable-next-line no-unused-expressions
      this.courseCoupon?.setState({
        isCouseCouponDisplay: "block",
        orderGuideDisplay: "block",
        coursePayFinalPrice: finalPrice,
        coursePayFinalState: finalState,
        selectActivityId: selectActivityId,
        selectName: selectName,
        selectedActivityIndex: selectIndex,
      });
      this.setState({
        coursePayMode: coursePayMode,
      });
      return;
    }
    if ("sub_study_package_activities" in this.props) {
      let defa_ticket;
      const activity = this.props.sub_study_package_activities.find((ac) => {
        return ac.id === selectActivityId || `${ac.id}` === selectActivityId;
      });
      if (
        activity &&
        activity.activity_ecoupons &&
        activity.activity_ecoupons.length > 0
      ) {
        defa_ticket = activity.activity_ecoupons.find((ticket, index) => {
          ticket.index = index;
          return ticket;
        });
      }
      let ecoupons_available = this.props.ecoupons_available;
      let ecoupons = this.props.ecoupons;
      if (activity && defa_ticket) {
        ecoupons = activity.activity_ecoupons;
        ecoupons_available = activity.activity_ecoupons.length > 0;
      }

      // eslint-disable-next-line no-unused-expressions
      this.courseCoupon?.setState({
        isCouseCouponDisplay: "block",
        orderGuideDisplay: "block",
        coursePayFinalPrice: finalPrice,
        coursePayFinalState: finalState,
        selectActivityId: selectActivityId,
        selectName: selectName,
        defaultSelectCouponId:
          activity && activity.activity_ecoupons.length > 0 && defa_ticket
            ? defa_ticket.id
            : null,
        defaultSelectPrice:
          activity &&
          activity.activity_ecoupons.length > 0 &&
          defa_ticket &&
          defa_ticket.price,
        selectedActivityIndex: selectIndex,
        trial: trial,
      });
      this.setState({
        coursePayMode: coursePayMode,
        ecoupons_available: ecoupons_available,
        ecoupons: ecoupons,
      });
    } else {
      // eslint-disable-next-line no-unused-expressions
      this.courseCoupon?.setState({
        isCouseCouponDisplay: "block",
        orderGuideDisplay: "block",
        coursePayFinalPrice: finalPrice,
        coursePayFinalState: finalState,
        selectActivityId: selectActivityId,
        selectName: selectName,
      });
      this.setState({
        coursePayMode: coursePayMode,
      });
    }
  }

  // 关闭选择年级弹框
  closeChooseGradeModalStatus = () => {
    this.setState({
      chooseGradeModalStatus: false,
    });
  };

  prompBargainStateModal = () => {
    if (this.props?.share_user?.bargain) {
      const { bargain, photo_url, name } = this.props.share_user;
      const { end_time, user_qrcode_photo_url, amount, status } = bargain;
      discountModal(status, {
        endTime: end_time,
        qrcode: user_qrcode_photo_url,
        userName: name,
        avatar: photo_url,
        amount,
      }).open();
    }
  };

  handleQuestionaireResultClose = (boolState = false) => {
    this.setState({
      questionaireResultCloseStatus: boolState,
    });
  };

  handleQuestionnaireModal = () => {
    const showQuestionnaireModal = showQuestionnaireActivityModal(
      this.props.get_questions_mini_exam_url,
      this.handleQuestionaireResultClose
    );
    if (this.props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        const login_url = decodeURIComponent(this.props.login_url);
        XinYaNativeBridge.navigate({ href: login_url });
      } else {
        window.location.href = this.props.login_url;
      }
    } else {
      showQuestionnaireModal.open();
    }
  };

  componentDidMount() {
    this.prompBargainStateModal();
    // 报名页的用户，如没有添加小孩，则弹出年级选择窗口
    if (this.props.grades) {
      this.setState({
        chooseGradeModalStatus: true,
      });
    }
    const { get_questions_mini_exam_url, popup_questions } = this.props;
    if (popup_questions) {
      const questionnaireModal = showQuestionnaireActivityModal(
        get_questions_mini_exam_url,
        this.handleQuestionaireResultClose
      );
      questionnaireModal.open();
    }

    if (typeof this.props.afterLoadAction === "function") {
      this.props.afterLoadAction();
    }

    if (!UserAgent.isNativeClient()) {
      const handler = (e) => {
        // window.location.href = window.location.href.includes("#comment")

        if (!window.sessionStorage.getItem("popstate")) {
          window.removeEventListener("popstate", handler);
          window.sessionStorage.setItem("popstate", "1");
          window.location.reload();
        }
      };
      window.addEventListener("popstate", handler);
    }

    if (UserAgent.isNativeClient()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }

    const videoStyle = document.getElementById("video");
    if (videoStyle && this.props.study_package_category_id === 18) {
      videoStyle.style.cssText =
        "padding: 0 15px;background: #f5fffe;margin-bottom: 0;display: block;";
    }

    // 已购买当前课程的老用户（非当期）访问
    if (this.props.pre_has_bought) {
      GA("Courses", "Purchased", this.props.study_package_activity_id);
    }

    if (this.props.pre_has_bought && window.location.search === "?id=83") {
      // eslint-disable-next-line prefer-const
      let confirmDialog;
      const buttons = [
        {
          label: "确定",
          onClick: () => confirmDialog.close(),
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          本课程针对非上海地区的一年级学生，与你已报名的《幼升小考点冲刺班》课程内容一样，无需重复报名。
        </Dialog>
      );
    }

    // 异常情况：已购买单课程，准备购买包含单课程的课程包组合 (partial)
    // 异常情况：已购买课程包组合，准备购买组合中包含的单课程 (superset)
    // 异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (
      this.props.study_package_group_state === "partial" ||
      this.props.study_package_group_state === "superset"
    ) {
      // eslint-disable-next-line prefer-const
      let confirmDialog;
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          {this.props.study_package_group_state === "partial"
            ? "您已拥有当前课程中的" +
              this.props.bought_category_name +
              "，请单独报名其它课程"
            : this.props.study_package_group_state === "superset"
            ? "您报名的《" +
              this.props.bought_category_name +
              "》已包含当前课程，无需重复报名"
            : ""}
        </Dialog>
      );
    }

    const {
      share_url,
      time_line_share_url,
      app_message_share_url,
      share_title,
      share_description,
      share_image_url,
    } = this.props;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJsonTimeLine = {
        link: time_line_share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      var sharedJsonAppMessage = {
        link: app_message_share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJsonTimeLine);
      wx.onMenuShareAppMessage(sharedJsonAppMessage);

      wx.miniProgram.getEnv((res) => {
        console.log(res.miniprogram); // true
        if (res.miniprogram) {
          var shareURL = share_url;
          if (!shareURL) {
            shareURL = window.location.href;
          }
          const share_string = convertURLToJSON(shareURL);
          wx.miniProgram.postMessage({
            data: {
              title: share_title,
              imgUrl: share_image_url,
              link: share_string,
            },
          });
        }
      });
    });

    // 不是每日限量购买的情况下, 刷新页面加载完之后, 直接弹框显示
    // 非教具包, 刷新页面加载完之后, 直接弹框显示
    if (
      !StudyPackageActivity.specialCategory.techAidPackages.includes(
        this.props.study_package_category_id
      )
    ) {
      if (this.props.is_show_alert) {
        // eslint-disable-next-line prefer-const
        let confirmDialog;
        const buttons = [
          {
            label: "确定",
            onClick: () => {
              confirmDialog.close();
            },
          },
        ];
        confirmDialog = DialogManager.open(
          <Dialog
            buttons={buttons}
          >{`您报名的《${this.props.pre_study_package_name}》已包含当前课程，无需重复购买`}</Dialog>
        );
      }
    }

    // 老用户购买训练营的提醒
    if (this.props.sync_practice_reminder) {
      // eslint-disable-next-line prefer-const
      let confirmDialog;
      const buttons = [
        {
          label: "确定",
          onClick: () => {
            confirmDialog.close();
          },
        },
      ];
      confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          本训练营课程，主要选自心芽《C2：20以内加减》、《系列课程：数学思维训练课》，仅对标一年级课本新增部分练习卷，已购课家长无需重复购买。
        </Dialog>
      );
    }
  }

  render() {
    const { is_experience, activity_category_type } = this.props;

    console.log("-------CourseCoupons------", this.props);

    return (
      <div className="comp-study-package-activity base_activity">
        <div className="activity_list">
          <div className="activity_pic_list activity_pic">
            <ActivityBanner
              mainVideo={this.props.main_video_url}
              mainPhotoUrl={this.props.main_photo_url}
            />
            <ActivityRightFloatIcons
              isShowComment={this.props.total_comments_count > 0}
              onCommentClick={this.onCommentClick}
              bonus={this.props.bonus || this.props.half_price_ecoupon_promotion}
              onRedMoneyClick={this.onRedMoneyClick}
              bottomPosition={110}
              isContinueUser={
                this.props.has_bought_any &&
                this.props.sub_course_type === "thinking" &&
                !!this.props.vip_batch_ecoupons_url
              }
              onConsultantClick={() => {
                window.location.href =
                  this.props.promotion_method === "scan"
                    ? "/course_consultants?scenario=22&ref=study_package_activity_details&promotion_method=scan"
                    : "/course_consultants?scenario=22&ref=study_package_activity_details";
              }}
              onMoreCourseClick={() => {
                window.location.href = "/activity_groups?ref=more_course";
              }}
              onContinueUserClick={() => {
                window.location.href = this.props.vip_batch_ecoupons_url;
              }}
            />
            {
              // "2019幼升小白皮书（视频精华版）" 或 “名校加油站”报名页 不需要显示
              activity_category_type !== 4 && (
                <ActivityPeriodStatus
                  startTime={this.props.start_time}
                  isExperience={this.props.is_experience}
                  isParentClass={this.props.is_parent_class}
                  studyPackageActivity={this.props.study_package_activity}
                  phases={this.props.phases}
                  phase={this.props.phase}
                  startDate={this.props.start_date}
                  courseWeekCount={this.props.course_week_count}
                  courseDayCount={this.props.course_day_count}
                  brief={this.props.brief}
                  subStudyPackageActivities={
                    this.props.sub_study_package_activities
                  }
                  studyPackageActivityType={this.state.studyPackageActivityType}
                  isUserStudyPackage={this.props.is_user_study_package}
                  isReadingActivity={this.props.sub_course_type === "reading"}
                />
              )
            }
            {this.props.shoppingGroupInfo && this.props.groupRender()}
            {
              // 适合报名该课程的标准
              !!this.props.suitable_brief && (
                <ActivityCourseFitDescribe
                  suitableBrief={this.props.suitable_brief}
                  isExperience={this.props.is_experience}
                />
              )
            }
            {
              // 适合报名该课程的标准
              this.props.sub_study_package_activities && (
                <ActivityCourseFitDescribeRed
                  // suitableBrief={this.props.suitable_brief}
                  sub_study_package_activities={
                    this.props.sub_study_package_activities
                  }
                />
              )
            }
            {
              // 用户推荐, 从别人的分享页进入可以看到
              !is_experience && !!this.props.share_user && (
                <ActivityUserRecommend
                  shareUser={this.props.share_user}
                  totalCommentsCount={this.props.total_comments_count}
                  onClickEvalute={this.onClickEvalute}
                />
              )
            }
          </div>
          <ActivityTabGroup {...this.props} />
          <WechatFollow
            ref={(ref) => (this.wechatFollowRef = ref)}
            qrcodeUrl={this.props.qrcode_url}
          />

          {/* 优惠券 */}

          {
            <CourseCoupons
              ref={(coupon) => (this.courseCoupon = coupon)}
              systemTime={this.props.system_time}
              eCouponData={this.state.ecoupons}
              ecouponsAvailable={this.state.ecoupons_available}
              studyPackageActivityId={this.props.study_package_activity_id}
              studyPackageActivity={this.props.study_package_activity}
              courseName={this.props.study_package_activity.name}
              instance={this.props.study_package_activity.instance}
              totalPrice={this.totalPrice}
              courseStartDate={this.props.start_date}
              shoppingGroup={
                this.state.coursePayMode === "direct"
                  ? null
                  : this.state.firstGroup
              }
              freeUrl={this.props.free_url}
              groupFreeUrl={this.props.group_free_url}
              courseType={this.props.course_type}
              noteUrl={this.props.note_url}
              subStudyPackageActivities={
                this.props.sub_study_package_activities
              }
              virtualAccount={this.props.virtual_account}
              payClickAction={
                this.state.coursePayMode === "group" ? onGroupBuy : onDirectBuy
              }
              coursePayMode={this.state.coursePayMode}
              isShowStartTime={this.state.isShowStartTime}
              activityCategoryType={activity_category_type}
              hasTeachingAidPackage={this.props.has_teaching_aid_package}
              giftCommoditiesUrl={this.props.gift_commodities_url}
              {...this.props}
            />
          }
          {this.props.bottomRender(this.props)}
          {this.state.absenteeActivity && (
            // this.props.bought &&
            <ChoosePhase
              isNoShowChoosePhase={this.props.isNoShowChoosePhase}
              absenteeActivity={this.state.absenteeActivity}
              sureBtnAction={this.choosePhaseClick}
              closeBtnAction={this.choosePhaseDiss}
              isUserStudyPackage={this.props.is_user_study_package}
            />
          )}
          {
            // 弹框选择年级
            this.state.chooseGradeModalStatus && (
              <ChooseGradeModal
                grades={this.props.grades}
                createUserBabyGrade={this.props.create_user_baby_grade}
                chooseGradeModalStatus={this.closeChooseGradeModalStatus}
              />
            )
          }
        </div>
      </div>
    );
  }
}

BaseStudyPackageActivity.propTypes = Object.assign({}, SharePoster.propTypes, {
  trial_activity: PropTypes.object,
  study_package_url: PropTypes.string,
  qrcode_url: PropTypes.string,
  share_url: PropTypes.string.isRequired,
  bottomRender: PropTypes.func.isRequired,
  directBuyCourse: PropTypes.func.isRequired,
});

BaseStudyPackageActivity.defaultProps = {
  free: "study_package",
};
