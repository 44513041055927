import React from "react";
import PropTypes from "prop-types";
import "./TabbarMenu.scss";

export default class TabbarMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tabbar_menu">
        <div className="btn_menubar_bottom">
          <div className="container_btn_courses">
            <a
              href={this.props.first_item_path}
              className={`${this.props.first_item_id} btn-item btn-courses`}
              target="_self"
            >
              <span className="play_courses">课程</span>
            </a>
          </div>

          <div className="container_btn_experience">
            <a
              href={this.props.second_item_path}
              className={`${this.props.second_item_id} btn-item btn-experience`}
              target="_self"
            >
              <span className="play_experience">经验</span>
            </a>
          </div>

          {/* <div className="container_btn_schools">
            <a href={this.props.third_item_path} className={`${this.props.third_item_id} btn-item btn-schools`} target='_self'>
              <span className="play_schools">择校</span>
            </a>
          </div> */}

          <div className="container_btn_avator">
            <a
              href={this.props.fourth_item_path}
              className={`${this.props.fourth_item_id} btn-item btn-avatar`}
              target="_self"
            >
              <span className="play_avatar">我的</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
