import React, { useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Raven from "raven-js";
import ModalManager from "../../utils/Modal/ModalManage.jsx";
import { request, csrfHeaders } from "../../utils/request";
import { generatePoster, showDialog } from "../../utils/helpers.js";

import { ToastManager } from "../../utils/Toast";

import iconClose from "new_experience/icon_close.png";
import iconLongPress from "new_experience/icon_up_ward.png";
import { GalleryGroupContainer } from "../../utils/GalleryGroup/GalleryGroup.js";

import "../../utils/GalleryGroup/GalleryGroup.scss";
import "./MultipleSharePosters.scss";

// const fake = [
//   {
//     url:
//       "https://upload.fireflybaby.cn/poster/129164/b426b74f24a01679b8e5f3238900d8a0",
//     index: 0
//   },
//   {
//     url:
//       "https://upload.fireflybaby.cn/poster/129161/36f26737a4e6be550ff794bc36a701fd",
//     index: 1
//   },
//   {
//     url:
//       "https://upload.fireflybaby.cn/poster/129162/0aad9e74ffef6af8e70953660d692992",
//     index: 2
//   },
//   {
//     url:
//       "https://upload.fireflybaby.cn/poster/129163/406d29feed919a685d9040e468ae7b6a",
//     index: 3
//   }
// ];

const mapPostersAsset = (posterAssets) => {
  return posterAssets.map((asset, index) => {
    if (asset.url) {
      return {
        url: asset.url,
        id: asset.id,
      };
    } else {
      return {
        userPhotoUrl: asset.user_photo_url,
        qiuNiuToken: asset.qiuniu_token,
        userQRCode: asset.user_qrcode_photo,
        id: asset.id,
      };
    }
  });
};

const generateCanvasPosters = (posterTemplates, mappedPosterAssets) => {
  const postersNeedLoaded = mappedPosterAssets.filter((poster) => {
    return !("url" in poster);
  });
  if (postersNeedLoaded.length === 0) {
    console.warn("direct resolve");
    return Promise.resolve(mappedPosterAssets);
  }
  return Promise.all(
    postersNeedLoaded.map((poster) => {
      console.time(`poster id ${poster.id}`);
      return generatePoster(
        [
          posterTemplates.find((tem) => tem.id == poster.id).url,
          poster.userPhotoUrl,
          poster.userQRCode,
        ],
        poster.qiuNiuToken,
        poster.id,
        {
          qrCodePosition: {
            x: 274,
            y: 853,
          },
        }
      ).then((res) => {
        console.timeEnd(`poster id ${poster.id}`);
        return {
          url: res[0],
          id: res[1],
        };
      });
    })
  ).then((loadedPosters) => {
    return mappedPosterAssets.map((asset) => {
      const posterIndex = loadedPosters.findIndex(
        (loadedPoster) => loadedPoster.id == asset.id
      );
      if (posterIndex !== -1 && !asset.url) {
        return {
          url: loadedPosters[posterIndex].url,
          id: loadedPosters[posterIndex].id,
        };
      } else {
        if (asset.url) {
          return asset;
        }
      }
    });
  });
};

const mountPosters = (updater, result) => {
  console.warn(result);
  if (result instanceof Array) {
    updater((lastPosters) => [...lastPosters, ...result]);
    console.log("posters", result);
  }
};

const catchMakePosterErr = (err, reGenerateManyPosters, onModalClose) => {
  Raven.captureException(err);
  const dialogOptions = {
    text: "生成海报出错，请重新尝试",
    mainLabel: "确定",
    subLabel: "取消",
    mainCallback: () => {
      reGenerateManyPosters();
    },
    subCallback: () => {
      onModalClose();
    },
  };
  showDialog.call(this, dialogOptions);
  console.error(err);
};

const defaultFunc = () => {};

const GalleryPostersContainer = React.memo(function GalleryPostersContainer({
  posters,
}) {
  const activeTargetDiv = useCallback(() => {
    return (
      <div className="active" key="active_style">
        <div className="top_left common_target"></div>
        <div className="top_right common_target"></div>
        <div className="bottom_left common_target"></div>
        <div className="bottom_right common_target"></div>
      </div>
    );
  }, []);

  console.log("posters", posters);
  return (
    <div className="gallery_container">
      <GalleryGroupContainer
        animation={{
          transition: "transform .2s ease",
        }}
        styles="gallery_items"
        defaultActiveIndex={0}
        scrollStep={0.5}
        indexDots={{
          containerStyles: "dots",
          dotUnActiveStyle: "in_active_dot",
          dotActiveStyle: "active_dot",
        }}
        leftAndRightControllers={{
          containerStyles: "left_right_step_click",
          leftControllerStyle: "left step_click",
          rightControllerStyle: "right step_click",
        }}
        activeIndexUpdater={defaultFunc}
        itemStyle="item_container"
        unActiveItemStyle="in_active"
        activeItemStyle={activeTargetDiv}
        list={posters}
      >
        {(poster) => <img src={poster.url} />}
      </GalleryGroupContainer>
    </div>
  );
});

GalleryPostersContainer.propTypes = {
  posters: PropTypes.array,
};

const AttentionShareImg = React.memo(function AttentionShareImg({
  isExperienceBought,
  isLimitedTimeOver,
}) {
  return (
    !isLimitedTimeOver &&
    !isExperienceBought && <div className="hours_limited"></div>
  );
});

AttentionShareImg.propTypes = {
  isExperienceBought: PropTypes.bool,
  isLimitedTimeOver: PropTypes.bool,
};

const FirstShareText = React.memo(function FirstShareText({
  isExperienceBought,
  isLimitedTimeOver,
  maxInviteUsersCount,
}) {
  return isExperienceBought ? (
    <span className="text_share_first">
      好的咖啡要和好友一起分享，<strong>好的经验</strong>更是
    </span>
  ) : (
    <p className="first_text">
      <span>只需邀请</span>
      {!isLimitedTimeOver && (
        <span className="delete_number">{`${maxInviteUsersCount + 2}位`}</span>
      )}
      <span className="discount_number">{`${maxInviteUsersCount}位`}</span>
      <span>{`新用户 扫描海报 + 关注公众号`}</span>
    </p>
  );
});

FirstShareText.propTypes = {
  isExperienceBought: PropTypes.bool,
  isLimitedTimeOver: PropTypes.bool,
  maxInviteUsersCount: PropTypes.number,
};

const SecondShareText = React.memo(function SecondShareText({
  isExperienceBought,
}) {
  return isExperienceBought ? (
    <p className="text_share_second">
      每邀请一位好友扫码关注，还可得<strong>5元</strong>奖学金
    </p>
  ) : (
    <p className="second_text">
      <span>即可获得足够奖学金</span>
      <span>{` → `}</span>
      <span className="free">免费报名</span>
    </p>
  );
});

SecondShareText.propTypes = {
  isExperienceBought: PropTypes.bool,
};

const LongPressText = React.memo(function LongPressText() {
  return (
    <p className="long_press_text">
      <img src={iconLongPress} alt="" />
      <span>
        <strong>长按</strong>保存海报，或发送给朋友
      </span>
    </p>
  );
});

const MultipleSharePoster = ({
  onModalOpen,
  onModalFakeClose,
  onModalClose,
  posterCreations,
  posterTemplates,
  isLimitedTimeOver,
  maxInviteUsersCount,
  isExperienceBought,
  disablePopupPosterUrl,
  isPopupPoster,
}) => {
  const [posters, setPosters] = useState([]);
  // const [activePosterIndex, setActivePoster] = useState(0);

  const generateManyPosters = useCallback(
    (rawPosterCreation) => {
      console.log("rawPosterCreation", rawPosterCreation);
      return Promise.all(
        rawPosterCreation.map((item) =>
          request({
            url: item.url,
            method: "GET",
            headers: csrfHeaders,
          }).then((res) => res.data)
        )
      )
        .then(mapPostersAsset)
        .then(generateCanvasPosters.bind(null, posterTemplates))
        .then(mountPosters.bind(null, setPosters));
    },
    [posterTemplates]
  );

  const slicedMakePoster = useCallback(
    (rawPosterCreations) => {
      const toast = ToastManager.showLoading("海报加载中...");
      const totalPosters = [...rawPosterCreations];
      const firstSlicedPosterCreations = totalPosters.splice(0, 2);
      const secondSlicedPosterCreation = totalPosters;

      generateManyPosters(firstSlicedPosterCreations)
        .then(() => {
          toast.cancel();
        })
        .then(() => generateManyPosters(secondSlicedPosterCreation))
        .catch((err) => {
          toast.cancel();
          catchMakePosterErr(
            err,
            slicedMakePoster.bind(null, posterCreations),
            onModalClose
          );
          // setPosters(lastPosters => [...lastPosters, fake[0], fake[1]]);
          // setTimeout(() => {
          //   setPosters(lastPosters => [...lastPosters, fake[2], fake[3]]);
          // }, 2000);
        });
    },
    [generateManyPosters, onModalClose, posterCreations]
  );

  useEffect(() => {
    slicedMakePoster(posterCreations);
  }, [posterCreations, slicedMakePoster]);

  useEffect(() => {
    if (isPopupPoster) {
      setTimeout(() => {
        request({
          url: disablePopupPosterUrl,
          method: "POST",
          headers: csrfHeaders,
        });
      }, 5000);
    }
  }, [disablePopupPosterUrl, isPopupPoster]);

  return (
    <div className="comp_multiple_share_poster">
      <div className="posters_container">
        <img
          src={iconClose}
          alt=""
          className="close_btn"
          onClick={onModalFakeClose}
        />
        <AttentionShareImg
          isLimitedTimeOver={isLimitedTimeOver}
          isExperienceBought={isExperienceBought}
        />
        <FirstShareText
          isLimitedTimeOver={isLimitedTimeOver}
          isExperienceBought={isExperienceBought}
          maxInviteUsersCount={maxInviteUsersCount}
        />
        <SecondShareText isExperienceBought={isExperienceBought} />
        <GalleryPostersContainer posters={posters} />
        <LongPressText />
      </div>
    </div>
  );
};

MultipleSharePoster.propTypes = {
  onModalOpen: PropTypes.func,
  onModalFakeClose: PropTypes.func,
  onModalClose: PropTypes.func,
  posterCreations: PropTypes.array,
  posterTemplates: PropTypes.array,
  isLimitedTimeOver: PropTypes.bool,
  isExperienceBought: PropTypes.bool,
  maxInviteUsersCount: PropTypes.number,
  disablePopupPosterUrl: PropTypes.string,
  isPopupPoster: PropTypes.bool,
};

const useMultipleSharePosters = (
  posterCreations,
  posterTemplates,
  isLimitedTimeOver,
  maxInviteUsersCount,
  isExperienceBought,
  disablePopupPosterUrl
) => {
  const postersModal = useMemo(
    () =>
      new ModalManager({
        render: (open, close, fakeClose, extraProps) => (
          <MultipleSharePoster
            onModalOpen={open}
            onModalClose={close}
            onModalFakeClose={fakeClose}
            posterCreations={posterCreations}
            posterTemplates={posterTemplates}
            isLimitedTimeOver={isLimitedTimeOver}
            maxInviteUsersCount={maxInviteUsersCount}
            isExperienceBought={isExperienceBought}
            disablePopupPosterUrl={disablePopupPosterUrl}
            {...extraProps}
          />
        ),
      }),
    [
      disablePopupPosterUrl,
      isExperienceBought,
      isLimitedTimeOver,
      maxInviteUsersCount,
      posterCreations,
      posterTemplates,
    ]
  );
  return postersModal;
};

export default useMultipleSharePosters;
