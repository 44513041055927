import React from "react";
import PropTypes from "prop-types";

import ComponentProxy from "../core/ComponentProxy";
import { request, csrfHeaders } from "../utils/request";

import "./DialogSignExam.scss";

// ONE
class ExamDialogStart extends React.Component {
  constructor(props) {
    super(props);
  }

  startExamClick = () => {
    new ExamDialogSubjectProxy({
      question: "孩子会做10以内的加减法？",
      subjectEnd: false,
      prepare_check_url: this.props.prepare_check_url,
      training_camp_url: this.props.training_camp_url,
      study_package_activity_url: this.props.study_package_activity_url,
    }).open();

    this.props.onClose();
  };

  render() {
    return (
      <div className="comp-sign-exam-dialog">
        <div className="sign-exam-inpage">
          <div className="sign-exam-notice-container">
            <div className="dialog-exam-box">
              <p>请先回答几个小问题，快速选择适合您孩子的计算课程。</p>
              <div className="tab-foot-start" onClick={this.startExamClick}>
                开始测评
              </div>
              {/* <div className="btn-close-dialog" onClick={this.props.onClose}>&times;</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// TWO
class ExamDialogSubject extends React.Component {
  constructor(props) {
    super(props);
  }

  yesClick = () => {
    console.log("会");
    // this.props.subjectEnd === true 是100以内的加减法
    // this.props.subjectEnd === false 是10以内的加减法

    if (this.props.subjectEnd === true) {
      let params = {
        task_type: "calculate_eval_result",
        value: 2,
      };
      var url = this.props.prepare_check_url;
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          const data = resp.data;
          console.log("正确返回");
          console.log(data);
        })
        .catch((err) => {
          console.log("错误返回");
          console.log(err);
        });
    } else {
      new ExamDialogSubjectProxy({
        question: "孩子会100以内加减法吗？",
        subjectEnd: true,
        prepare_check_url: this.props.prepare_check_url,
        training_camp_url: this.props.training_camp_url,
        study_package_activity_url: this.props.study_package_activity_url,
      }).open();
    }

    this.props.onClose();

    if (this.props.subjectEnd === true) {
      new ExamDialogEndProxy({
        question:
          "《心芽速度闯关练习》目前主要面向已掌握100以内加减法，但计算速度尚不熟练的孩子，适合您的孩子。",
        button_text: "查看速度闯关练习",
        go_url: this.props.training_camp_url,
      }).open();
    }
  };

  noClick = () => {
    // this.props.subjectEnd === false 是10以内的加减法
    console.log("不会");

    if (this.props.subjectEnd === false) {
      let params = {
        task_type: "calculate_eval_result",
        value: 0,
      };
      var url = this.props.prepare_check_url;
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          const data = resp.data;
          console.log("正确返回");
          console.log(data);
        })
        .catch((err) => {
          console.log("错误返回");
          console.log(err);
        });
    } else {
      let params = {
        task_type: "calculate_eval_result",
        value: 1,
      };
      var url = this.props.prepare_check_url;
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          const data = resp.data;
          console.log("正确返回");
          console.log(data);
        })
        .catch((err) => {
          console.log("错误返回");
          console.log(err);
        });
    }
    if (this.props.subjectEnd === false) {
      new ExamDialogEndProxy({
        question:
          "很抱歉！《心芽计算课》目前只面向已掌握10以内加减法的孩子，可能不太适合你的孩子。",
        button_text: "看看课程内容",
        go_url: this.props.study_package_activity_url,
      }).open();
    } else {
      new ExamDialogEndProxy({
        question:
          "《心芽计算课》目前主要面向已掌握10以内，但尚未掌握100以内加减法的孩子，适合您的孩子。",
        button_text: "查看计算课程",
        go_url: this.props.study_package_activity_url,
      }).open();
    }
    this.props.onClose();
  };

  render() {
    return (
      <div className="comp-sign-exam-dialog">
        <div className="sign-exam-inpage">
          <div className="sign-exam-notice-container">
            <div className="dialog-exam-box">
              <p>{this.props.question}</p>
              <div className="tab-foot">
                <div className="foot-left" onClick={this.yesClick}>
                  会
                </div>
                <div className="foot-right" onClick={this.noClick}>
                  不会
                </div>
              </div>
              {/* <div className="btn-close-dialog" onClick={this.props.onClose}>&times;</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// THREE
class ExamDialogEnd extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="comp-sign-exam-dialog">
        <div className="sign-exam-inpage">
          <div className="sign-exam-notice-container">
            <div className="dialog-exam-box">
              <p>{this.props.question}</p>
              <a
                className="tab-foot-start"
                onClick={this.startExamClick}
                href={this.props.go_url}
              >
                {this.props.button_text}
              </a>
              {/* <div className="btn-close-dialog" onClick={this.props.onClose}>&times;</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ExamDialogStartProxy extends ComponentProxy {
  constructor(options) {
    super();
    this.prepare_check_url = options.prepare_check_url;
    this.training_camp_url = options.training_camp_url;
    this.study_package_activity_url = options.study_package_activity_url;
    // console.log("1：" + this.training_camp_url)
    // console.log("1-1：" + this.study_package_activity_url)
  }

  createComponent() {
    return (
      <ExamDialogStart
        prepare_check_url={this.prepare_check_url}
        training_camp_url={this.training_camp_url}
        study_package_activity_url={this.study_package_activity_url}
        onClose={() => this.cancel()}
      />
    );
  }
}

export class ExamDialogSubjectProxy extends ComponentProxy {
  constructor(options) {
    super();
    this.question = options.question;
    this.subjectEnd = options.subjectEnd;
    this.prepare_check_url = options.prepare_check_url;
    this.training_camp_url = options.training_camp_url;
    this.study_package_activity_url = options.study_package_activity_url;
    // console.log("2：" + this.training_camp_url)
    // console.log("2-2：" + this.study_package_activity_url)
  }

  createComponent() {
    return (
      <ExamDialogSubject
        question={this.question}
        prepare_check_url={this.prepare_check_url}
        training_camp_url={this.training_camp_url}
        study_package_activity_url={this.study_package_activity_url}
        subjectEnd={this.subjectEnd}
        onClose={() => this.cancel()}
      />
    );
  }
}

export class ExamDialogEndProxy extends ComponentProxy {
  constructor(options) {
    super();
    this.question = options.question;
    this.button_text = options.button_text;
    this.go_url = options.go_url;
    // console.log("3：" + this.go_url)
  }

  createComponent() {
    return (
      <ExamDialogEnd
        question={this.question}
        button_text={this.button_text}
        go_url={this.go_url}
        onClose={() => this.cancel()}
      />
    );
  }
}

ExamDialogStart.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ExamDialogSubject.propTypes = {
  onClose: PropTypes.func.isRequired,
};

ExamDialogEnd.propTypes = {
  onClose: PropTypes.func.isRequired,
};
