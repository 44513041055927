import React from "react";
import PropTypes from "prop-types";

import ReactMarkdown from "react-markdown";

import "../utils/ReactMarkDown.scss";
import "./CheckIn.scss";

export default class CheckIn extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div className="comp-check-in">
        <div className="check_in_bg"></div>
        <div className="info_reveal">
          <h2>{this.props.baby.nickname}</h2>
          <p>
            正在<i> 心芽学堂 </i>学习《{this.props.content.name}》
          </p>
          <div className="info_head_box">
            <div className="card_day_period_statistics">
              <div className="card_day">
                <p>{this.props.studied_course_day_count}</p>
                <span>学习天数</span>
              </div>
              <div className="card_period">
                <p>{this.props.total_studied_media_lessons_count}</p>
                <span>已学课时</span>
              </div>
            </div>
          </div>
          <div className="seperate-line"></div>
          <div className="card_info_content markdown_content">
            <ReactMarkdown source={this.props.content.checkin_text} />
          </div>
          <div className="card_info_bottom">
            <img src={this.props.content.checkin_qrcode_photo_url} />
            <div className="card_info_left">
              <p>长按识别二维码关注</p>
              <h3>心芽学堂</h3>
              <span>每天15分钟，随时随地学</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
