/* eslint-disable react/prop-types */
import React, {
  memo,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import queryString from "query-string";
import { request, csrfHeaders } from "../../utils/request";
import { format as formatTime } from "../../utils/formatAudioTime.js";
import HeadInfo from "../../../admin-utils/HeadInfo";
import FollowupLog from "./FollowupLog";
import { ToastManager } from "../../utils/Toast";
import ConsultantTabTableContent from "../../../admin-utils/ConsultantTabTableContent";
import TableFilterBtnGroup from "./TableFilterBtnGroup";
import { CheckBoxContain } from "./SystemCourseUser";
import DropdownMenu, {
  MenuItem,
  Menu,
  DropdownButton,
} from "../../../admin-utils/DropdownMenu";
import Button from "../../../admin-utils/Button";
import Input from "../../../admin-utils/Input";
import toast from "../../utils/XinyaUi/toast";

const NoneDate = styled.li`
  color: #337ab7;
  margin-top: 10px;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
  border-radius: 200px;
`;
const StyleInput = styled(Input)`
  & {
    margin-top: 10px;
    height: 28px;
  }
`;
const StyledFollowupLog = styled(FollowupLog)`
  display: block;
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: 20px;
  margin-top: 10px;
  ${MenuItem} {
    text-align: center;
  }
  ${Menu} {
    padding: 10px;
    overflow-y: initial !important;
    overflow-x: initial !important;
  }
  &:first-of-type {
    margin-left: 0;
  }
  /* &:nth-of-type(2) {
    margin-right: 20px;
  } */
  &:last-of-type {
    /* margin-left: 0; */
    ${DropdownButton} {
      min-width: 150px;
    }
    ${Menu} {
      padding: 10px;
      width: 200px;
    }
  }
`;

const StyledConsultantTabTableContent = styled(ConsultantTabTableContent)`
  & {
    margin: 10px 0 20px !important;
  }
  table {
    tbody {
      font-size: 14px;

      tr td {
        min-width: 30px;
        .log_note {
          white-space: pre-wrap !important;
        }
        .attention_unhandled {
          margin-top: -4px;
          border-radius: 30px;
          font-size: 14px;
          color: white;
          background: #3c8af0;
          cursor: pointer;
          text-align: center;
          padding: 4px 6px;
          white-space: nowrap;
          margin-right: 5px;
          margin-left: 8px;
        }
        .attention_unhandled + .attention_unhandled {
          margin-top: 8px;
        }
        .handle_new_status {
          padding: 1px 8px;
          margin-left: 5px;
          font-size: 12px;
          border: 1px solid red;
          border-radius: 3px;
          color: red;
          background: white;
          height: 20px;
        }
      }
      .large_td {
        min-width: 120px;
      }
      .trigger_sources_box {
        p {
          .sub_source {
            display: flex;
            flex-wrap: nowrap;
            padding-bottom: 10px;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
`;

const RecordTr = ({ record, binToCcWxUrl, followUpLogUrl }) => {
  const triggerTypeRef = useRef();
  const triggerSourcesRef = useRef();
  // const triggerHandlerRef = useRef();
  useLayoutEffect(() => {
    const sourcesNodes = triggerSourcesRef.current.childNodes;
    triggerTypeRef.current.childNodes.forEach((n, index) => {
      n.style.cssText += `height: ${
        getComputedStyle(sourcesNodes[index]).height
      }`;
    });
    // triggerHandlerRef.current.childNodes.forEach((n, index) => {
    //   n.style.cssText += `min-height: ${
    //     getComputedStyle(sourcesNodes[index]).height
    //   }; margin-bottom: 10px`;
    // });
  }, []);
  return (
    <tr>
      <td>{record.user && <HeadInfo user={record.user} />}</td>
      <td ref={triggerTypeRef} style={{ verticalAlign: "top" }}>
        {record.triggers.map((trigger, ind) => (
          <p key={ind} data-trigger={ind}>
            {trigger.actionTypeName}
          </p>
        ))}
      </td>
      <td ref={triggerSourcesRef} style={{ verticalAlign: "top" }}>
        {record.triggers.map((trigger, idx) => (
          <div key={idx} data-trigger={idx} className="trigger_sources_box">
            {trigger.sources.map((source, ind) => {
              return source.subSources ? (
                <p key={`${idx}-${ind}`}>
                  {source.subSources.map((item, index) => (
                    <div key={index} className="sub_source">
                      <span style={{ color: "#333" }}>{source.name}（</span>
                      <a href={item.url}>{item.name}</a>
                      <span style={{ color: "#333" }}>）</span>
                      {item.state === 0 ? (
                        <span
                          className="handle_new_status"
                          style={{ marginRight: "5" }}
                        >
                          新触发
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </p>
              ) : (
                <p key={`${idx}-${ind}`}>
                  <div className="sub_source">
                    <a href={source.url}>{source.name}</a>
                    {source.state === 0 ? (
                      <span className="handle_new_status">新触发</span>
                    ) : (
                      ""
                    )}
                  </div>
                </p>
              );
            })}
          </div>
        ))}
      </td>

      <td style={{ verticalAlign: "top" }}>
        {record.triggers.map((trigger, idx) =>
          trigger.sources.map((source, ind) =>
            source.subSources ? (
              source.subSources.map((subSource, i) => (
                <p
                  key={`${idx}-${ind}-${i}`}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {formatTime(subSource.time, "YYYY.MM.DD HH:mm")}
                </p>
              ))
            ) : (
              <p
                key={`${idx}-${ind}`}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {formatTime(source.time, "YYYY.MM.DD HH:mm")}
              </p>
            )
          )
        )}
      </td>

      <td style={{ minWidth: 200 }}>
        <div
          className="user_intent_label log_note"
          style={{ paddingRight: 15 }}
        >
          {record?.log?.logNote}
        </div>
      </td>

      <td style={{ paddingRight: 15 }} className="large_td">
        <div className="user_intent_label">
          {formatTime(record?.log?.createdAt, "YYYY.MM.DD HH:mm")}
        </div>
      </td>

      <td>
        <CheckBoxContain
          userId={record.user.id}
          binToCcWxUrl={binToCcWxUrl}
          defaultChecked={record.user.isBindCcWx}
        />
      </td>

      <td>
        <p>{record.courseConsultant.name}</p>
      </td>

      <td>
        <StyledFollowupLog
          followUpLogUrl={followUpLogUrl}
          btnName={`标记处理`}
        />
      </td>
    </tr>
  );
};

// 思维课高意向跟进
const IntentFollowup = ({ ...props }) => {
  console.log("IntentFollowup_props: ", props);
  const {
    query: {
      action_type,
      source,
      study_package_category_id,
      date_from,
      date_to,
    },
  } = queryString.parseUrl(window.location.href);
  const initialSources = useMemo(
    () => [{ name: "全部", value: "" }, ...props.sources],
    [props.sources]
  );

  const initialCategories = useMemo(
    () => [
      { actionTypeName: "全部", actionType: "", studyPackageCategoryId: "" },
      ...props.categories,
    ],
    [props.categories]
  );

  const defaultSourceIndex = initialSources.findIndex(
    (so) => so.value === source * 1
  );
  const defaultActionIndex = initialCategories.findIndex(
    (cate) =>
      cate.actionType === action_type * 1 &&
      cate.studyPackageCategoryId === study_package_category_id * 1
  );
  const [startDate, setStartDate] = useState(date_from ?? "");
  const [endDate, setEndDate] = useState(date_to ?? "");
  const [newSource, setSource] = useState(
    defaultSourceIndex === -1 ? "" : defaultSourceIndex
  );
  const [actionType, setActionType] = useState(
    defaultActionIndex === -1 ? "" : defaultActionIndex
  );
  const [isChooseDate, setIsChooseDate] = useState(false);
  const pageMounted = useRef();
  const onInputStartTime = useCallback((value) => {
    setStartDate(value);
    // console.log(value);
  }, []);

  const onInputEndTime = useCallback((value) => {
    setEndDate(value);
    // console.log(value);
  }, []);

  const onNoneDateClick = useCallback(() => {
    setEndDate("");
    setStartDate("");
    setIsChooseDate(true);
  }, []);

  const onChooseDate = () => {
    setIsChooseDate(true);
  };

  const renderOptions = useCallback(() => {
    return (
      <React.Fragment>
        <StyleInput
          isDatePicker
          defaultValue={startDate}
          format="yyyy-mm-dd"
          placeholder="起始日期"
          onChange={onInputStartTime}
        />
        <StyleInput
          isDatePicker
          defaultValue={endDate}
          format="yyyy-mm-dd"
          placeholder="截止日期"
          onChange={onInputEndTime}
        />
        <StyledButton className="btn-primary" onClick={onChooseDate}>
          确定
        </StyledButton>
        <li role="separator" className="divider" style={{ marginTop: 10 }}></li>
        <NoneDate onClick={onNoneDateClick}>所有日期</NoneDate>
      </React.Fragment>
    );
  }, [endDate, onInputEndTime, onInputStartTime, onNoneDateClick, startDate]);

  useEffect(() => {
    if (!pageMounted.current) {
      pageMounted.current = true;
    } else {
      const toast = ToastManager.showLoading("加载中...");
      const { query, url } = queryString.parseUrl(window.location.href);
      delete query.page;

      if (actionType !== "") {
        query.action_type = initialCategories[actionType].actionType;
        query.study_package_category_id =
          initialCategories[actionType].studyPackageCategoryId;
      }

      if (newSource !== "") {
        query.source = initialSources[newSource].value;
      }

      if (startDate) {
        query.date_from = startDate.replace(/\./g, "-");
      }
      if (endDate) {
        query.date_to = endDate.replace(/\./g, "-");
      }
      toast.cancel();
      window.location.href = url + "?" + queryString.stringify(query);
    }

    // return () => {
    //   toast.cancel();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionType,
    endDate,
    initialCategories,
    initialSources,
    newSource,
    isChooseDate,
  ]);

  const url = window.location.href;
  const redirectBindCcUrl =
    url.indexOf("?") == -1
      ? `${url.replace("?order_by=bind_cc", "")}?order_by=bind_cc`
      : `${url.replace("&order_by=bind_cc", "")}&order_by=bind_cc`;

  return (
    <>
      <StyledDropdownMenu
        defaultActiveOptionIndex={
          defaultActionIndex === -1 ? 0 : defaultActionIndex
        }
        options={initialCategories}
        onOptionClick={(index) => {
          setActionType(index);
          console.log(index);
        }}
        isOptionClickedChange
        renderOption={(option) => {
          return <span>{option.actionTypeName}</span>;
        }}
      />
      <StyledDropdownMenu
        defaultActiveOptionIndex={
          defaultSourceIndex === -1 ? 0 : defaultSourceIndex
        }
        options={initialSources}
        onOptionClick={(index) => {
          setSource(index);
          console.log(index);
        }}
        isOptionClickedChange
        renderOption={(option) => {
          return <span>{option.name}</span>;
        }}
      />
      <StyledDropdownMenu
        title={startDate || endDate ? `${startDate} - ${endDate}` : "所有日期"}
        renderOptions={renderOptions}
      />
      <StyledConsultantTabTableContent>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>用户</th>
            <th>触发类型</th>
            <th>触发原因</th>
            <th>触发时间</th>
            <th>跟进日志</th>
            <th>提交时间</th>
            <th>
              <a href={redirectBindCcUrl}>绑定老师微信</a>
            </th>
            <th>课程顾问</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {props.records.map((record, index) => (
            <RecordTr
              key={index}
              record={record}
              binToCcWxUrl={props.binToCcWxUrl}
              followUpLogUrl={record.followUpLogUrl}
            />
          ))}
        </tbody>
      </StyledConsultantTabTableContent>
    </>
  );
};
export default memo(IntentFollowup);
