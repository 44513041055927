import React, { PureComponent } from "react";
import logo from "schooser/logo.png";
import title from "schooser/title.png";
import description from "schooser/description.png";
import "./CollectIndex.scss";

export default class CollectIndex extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="component_collect_index">
        <div className="head_icon">
          <img src={logo} alt="" />
        </div>
        <div className="head_title_desc">
          <img src={title} alt="" />
          <img src={description} alt="" />
        </div>
        {/* <a className="collect_exam_paper" href="/schooser/collect_edit">领测试卷</a> */}
        <div className="activity_end">
          <div className="end_text">本次活动已结束</div>
          <a
            className="jump_exam_link"
            href="https://xinya.me/study_package_activity_details?id=298&ref=mxxx"
          >
            查看历年幼升小面试真题
          </a>
        </div>
      </div>
    );
  }
}
