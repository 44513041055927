import React, { Component } from "react";
import PropTypes from "prop-types";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { ToptipsManager } from "../utils/Toptips";
import Picker from "better-picker";
import Uri from "jsuri";
import UserAgent from "../utils/UserAgent";
import { GA } from "../utils/Env";
import { formatDate } from "../utils/formatAudioTime";
import { debounce, daysInMonth } from "../utils/helpers";
import CitySelectList from "../utils/CitySelectList";
import Raven from "raven-js";
import "./UserBaby.scss";

export default class New extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.genderMap = ["女", "男"];
    this.state = {
      addBabyDisplay: "block",
      noBabyDisplay: "none",
      noBabuDisplay: "none",
      activeAddBaby: "active",
      // pickerGradeValue: -1,
      selected: -1,
      nickName: "",
      gender: "",
      grade: "",
      relationship: "",
      onCitySelected: this.props.area && (this.props.area.city ? true : false),
    };
    this.pickDaysItems = [];
  }

  // 添加孩子信息请求
  hasBabyRequest = () => {
    const {
      nickName,
      pickerRelationValue,
      selected,
      onCitySelected,
      canBeSubmit,
    } = this.state;

    const last_pick_date = new Date(
      this.state.pickerYearValue +
        "-" +
        this.state.pickerMonthValue +
        "-" +
        (this.state.pickerDayValue != undefined
          ? this.state.pickerDayValue
          : "01")
    );
    console.log("last_pick_date: ", last_pick_date);
    console.log(
      "last_pick_date /: ",
      formatDate(last_pick_date).replace(/-/g, "/")
    );
    console.log(
      "system_time /: ",
      formatDate(this.props.system_time).replace(/-/g, "/")
    );

    if (
      new Date(formatDate(last_pick_date).replace(/-/g, "/")) >
      new Date(formatDate(this.props.system_time).replace(/-/g, "/"))
    ) {
      ToptipsManager.warn("您选择的月份不能大于当前月份, 请重新选择~");
      return;
    } else {
      if (!canBeSubmit) {
        return;
      }
      this.toast = ToastManager.showLoading("提交中...");
      let params = {
        user_baby: {
          baby_attributes: {
            nickname: nickName,
            // day_of_birth: pickerYearMonthValue,
            day_of_birth:
              this.state.pickerYearValue +
              "-" +
              this.state.pickerMonthValue +
              "-" +
              (this.state.pickerDayValue != undefined
                ? this.state.pickerDayValue
                : "01"),
            gender: this.genderMap.indexOf(selected),
            // grade: this.state.pickerGradeValue,
            secrect_level: this.state.pickerDayValue != undefined ? 1 : 0,
          },
          relationship: pickerRelationValue,
        },
      };
      console.log(params);
      request({
        url: this.props.user_baby_url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          this.toast.cancel();
          let data = resp.data;
          console.log("success data: ", data);

          console.log(
            this.state.pickerYearValue +
              "-" +
              this.state.pickerMonthValue +
              "-" +
              (this.state.pickerDayValue != "undefined"
                ? this.state.pickerDayValue
                : "01")
          );

          if (UserAgent.isNativeClient()) {
            XinYaNativeBridge.navigate({ href: data.redirect_url });
          } else if (UserAgent.isMiniProgram()) {
            wx.miniProgram.navigateTo({
              url: "/pages/study_package_activities/studyPackageActivityList",
            });
          } else {
            window.location.href = data.redirect_url;
          }
        })
        .catch((err) => {
          Raven.captureException(err);
          this.toast.cancel();
        });
    }
  };

  // 还没有孩子请求
  // noBabyRequest = () => {
  //   if (document.getElementById('select_city_address').innerHTML == '请选择所在城市') {
  //     ToptipsManager.warn("请选择所在城市")
  //     return
  //   } else {
  //     window.location.href = new Uri(window.location.href).getQueryParamValue('redirect_url')
  //   }
  // }

  handleSubmit = (event) => {
    event.preventDefault();

    // this.state.addBabyDisplay === 'block' ? this.hasBabyRequest() : this.noBabyRequest()
    this.hasBabyRequest();

    // 注册完成·ga统计
    GA("Register", "kidsInfo", this.props.user.id);
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
    });
    this.makeSubmitAttention();
  };

  componentDidMount() {
    // 已有手机直接进入孩子信息页·ga统计
    if (this.props.from_setting === true) {
      GA("Register", "skipPhone", this.props.user.id);
    }

    // let pickerGradeEl = document.getElementById('picker_grade');
    let pickerYearEl = document.getElementById("picker_year_month");
    let pickerMonthEl = document.getElementById("picker_month");
    let pickerDayEl = document.getElementById("picker_day");
    let pickerRelationEl = document.getElementById("picker_relation");

    // var pickerGrade = new Picker({
    //   data: [this.props.grades],
    //   // selectedIndex: [0]
    // });

    this.props.years.unshift({ text: "年", value: -1 });
    this.props.months.unshift({ text: "月", value: -1 });

    var pickerYear = new Picker({
      data: [this.props.years],
      // selectedIndex: this.props.baby.birth_day && [this.props.years.findIndex((item) => this.props.baby.birth_day.split('-')[0] === item.text), this.props.months.findIndex((item) => this.props.baby.birth_day.split('-')[1].replace(/^0/,'') == item.value)]
    });
    var pickerMonth = new Picker({
      data: [this.props.months],
      // selectedIndex: this.props.baby.birth_day && [this.props.years.findIndex((item) => this.props.baby.birth_day.split('-')[0] === item.text), this.props.months.findIndex((item) => this.props.baby.birth_day.split('-')[1].replace(/^0/,'') == item.value)]
    });

    var pickerRelation = new Picker({
      data: [this.props.relations],
      // selectedIndex: [0]
    });

    // 年级
    // pickerGrade.on('picker.select', (selectedVal, selectedIndex) => {
    //   pickerGradeEl.innerText = this.props.grades[selectedIndex[0]].text
    //   this.setState({
    //     pickerGradeValue: selectedVal[0]
    //   })
    // });
    // pickerGradeEl.addEventListener('click', function () {
    //   pickerGrade.show();
    // });

    // 关系
    pickerRelation.on("picker.select", (selectedVal, selectedIndex) => {
      pickerRelationEl.innerText = this.props.relations[selectedIndex[0]].text;
      this.setState({
        pickerRelationValue: pickerRelationEl.innerText,
      });
      this.makeSubmitAttention();
    });

    pickerRelationEl.addEventListener("click", function () {
      pickerRelation.show();
    });

    // 出生年份
    pickerYear.on("picker.select", (selectedVal, selectedIndex) => {
      // console.log('new_enter 出生年份')
      // console.log(selectedVal)

      if (selectedVal[0] === -1 || selectedVal[1] === -1) {
        ToptipsManager.warn("请认真填写你孩子的出生年月");
        return;
      } else {
        pickerYearEl.innerText = this.props.years[selectedIndex[0]].text;
        this.setState({
          pickerYearValue: `${selectedVal[0]}`,
        });
        this.makeSubmitAttention();
      }
    });
    pickerYearEl.addEventListener("click", function () {
      pickerYear.show();
    });

    // 出生月份
    pickerMonth.on("picker.select", (selectedVal, selectedIndex) => {
      console.log("前一项的年份", this.state.pickerYearValue);
      console.log(
        "月份",
        "selectedVal:" + selectedVal,
        "selectedIndex:" + selectedIndex
      );
      if (selectedVal[0] === -1) {
        ToptipsManager.warn("请认真填写您孩子的出生月份");
        return;
      } else {
        pickerMonthEl.innerText = this.props.months[selectedIndex[0]].text;
        console.log("月份", this.props.months[selectedIndex[0]].text);
        this.setState({
          pickerMonthValue: `${selectedVal[0]}`,
        });
        this.makeSubmitAttention();

        // TODO
        console.log(this.state.pickerYearValue);
        console.log(this.props.months[selectedIndex[0]].text);

        var days = daysInMonth(
          this.state.pickerYearValue,
          this.props.months[selectedIndex[0]].text
        );
        console.log("选好月 天数", days);

        var objDay = [];
        for (let index = 1; index <= days; index++) {
          objDay.push({ text: index, value: index });
        }
        objDay.unshift({ text: "日", value: -1 });
        console.log(260, objDay);

        var pickerDay = new Picker({
          data: [objDay],
          // selectedIndex: baby.day_of_birth && [objDay.findIndex((item) => baby.day_of_birth.split('-')[1].replace(/^0/, '') == item.value)]
        });

        this.pickDaysItems.push(pickerDay);

        pickerDay.on("picker.select", (selectedVal, selectedIndex) => {
          console.log(
            "日",
            "selectedVal:" + selectedVal,
            "selectedIndex:" + selectedIndex
          );
          if (selectedVal[0] === -1) {
            ToptipsManager.warn("请认真填写您孩子的出生日");
            return;
          } else {
            pickerDayEl.innerText = objDay[selectedIndex[0]].text;
            console.log("日", objDay[selectedIndex[0]].text);
            this.setState({
              pickerDayValue: `${selectedVal[0]}`,
            });
            this.makeSubmitAttention();
          }
        });
        pickerDayEl.addEventListener("click", () => {
          console.log(
            "pickerDayEl value: ",
            this.state.pickerYearValue +
              "-" +
              this.state.pickerMonthValue +
              "-" +
              this.state.pickerDayValue
          );
          if (this.state.pickerYearValue == undefined) {
            ToptipsManager.warn("请选择年份");
            return;
          } else if (this.state.pickerMonthValue == undefined) {
            ToptipsManager.warn("请选择月份");
            return;
          } else {
            this.pickDaysItems[this.pickDaysItems.length - 1].show();
          }
        });
      }
    });
    pickerMonthEl.addEventListener("click", () => {
      console.log("开始选择月份");
      pickerMonth.show();
    });
    pickerDayEl.addEventListener("click", () => {
      console.log(
        "pickerDayEl value: ",
        this.state.pickerYearValue +
          "-" +
          this.state.pickerMonthValue +
          "-" +
          this.state.pickerDayValue
      );
      if (this.state.pickerYearValue == undefined) {
        ToptipsManager.warn("请选择年份");
        return;
      } else if (this.state.pickerMonthValue == undefined) {
        ToptipsManager.warn("请选择月份");
        return;
      } else {
        this.pickDaysItems[this.pickDaysItems.length - 1].show();
      }
    });
  }

  makeSubmitAttention = () => {
    this.setState(
      ({
        nickName,
        pickerYearValue,
        pickerRelationValue,
        selected,
        onCitySelected,
      }) => ({
        canBeSubmit:
          nickName &&
          pickerYearValue &&
          pickerRelationValue &&
          selected !== -1 &&
          onCitySelected
            ? true
            : false,
      })
    );
  };

  handleAddBaby = () => {
    this.setState({
      addBabyDisplay: "block",
      noBabyDisplay: "none",
      activeAddBaby: "active",
      activeNoBaby: "",
    });
  };

  // handleNoBaby = () => {
  //   this.setState({
  //     addBabyDisplay: 'none',
  //     noBabyDisplay: 'block',
  //     activeAddBaby: '',
  //     activeNoBaby: 'active'
  //   })
  // }

  handelGenderSelect = (e) => {
    this.setState({
      selected: e.currentTarget.value,
    });
    this.makeSubmitAttention();
  };

  citySelected = () => {
    this.setState({
      onCitySelected: true,
    });
    this.makeSubmitAttention();
  };
  render() {
    const {
      pickerYearValue,
      pickerMonthValue,
      pickerDayValue,
      pickerRelationValue,
      onCitySelected,
      canBeSubmit,
    } = this.state;
    return (
      <div id="baby_form_2" className="babies-new user_has_babay">
        <div className="title_area">
          <p>填写孩子信息</p>
          <p>系统将根据孩子信息推荐个性化内容</p>
        </div>
        {/* <div className="baby_info_tab">
          <div id="add_baby" className= {`${this.state.activeAddBaby} add_baby_info`} onClick={this.handleAddBaby}>
            添加孩子信息
          </div>
          <div id="no_baby" className={`${this.state.activeNoBaby} no_baby_info`} onClick={this.handleNoBaby}>
            还没有孩子
          </div>
        </div> */}
        <div
          className="add_baby_content"
          style={{ display: this.state.addBabyDisplay }}
        >
          <div className="form-row">
            <label className="label">小名:</label>
            <div className="input">
              <input
                type="text"
                value={this.state.nickName}
                name="nickName"
                onChange={this.handleChange}
                style={{ paddingLeft: 38 }}
              />
            </div>
          </div>

          <div className="form-row babies_sex">
            <label className="label">性别:</label>
            <div className="input gender">
              <div className="input_raido">
                <input
                  type="radio"
                  value="男"
                  checked={this.state.selected === "男"}
                  onChange={this.handelGenderSelect}
                />
                <label>男宝</label>
              </div>
              <div className="input_raido">
                <input
                  type="radio"
                  value="女"
                  checked={this.state.selected === "女"}
                  onChange={this.handelGenderSelect}
                />
                <label>女宝</label>
              </div>
            </div>
          </div>

          {/* <div className="form-row babies_birth">
            <label className="label">出生年月:</label>
            <div id="picker_year_month" className={`select_area birth ${!pickerYearMonthValue && 'placeholder'}`} style={{ width: '70%' }}>
              请选择出生年月
              </div>
          </div> */}
          <div className="form-row babies_birth">
            <label className="label label_static_width">出生年月:</label>
            <div className="baby_birth_box">
              <div className="common_div">
                <span
                  id="picker_year_month"
                  className={`birth ${!pickerYearValue && "placeholder"}`}
                  style={{ paddingLeft: 0 }}
                >
                  请选择
                </span>
                <span>年</span>
              </div>
              <div className="common_div">
                <span
                  id="picker_month"
                  className={`month ${!pickerMonthValue && "placeholder"}`}
                >
                  请选择
                </span>
                <span>月</span>
              </div>
              <div className="common_div">
                <span
                  id="picker_day"
                  className={`day ${!pickerDayValue && "placeholder"}`}
                >
                  可不填
                </span>
                <span>日</span>
              </div>
            </div>
          </div>

          <div className="form-row babies_rank">
            <label className="label">你是:</label>
            <div
              id="picker_relation"
              className={`select_area area ${
                !pickerRelationValue && "placeholder"
              }`}
            >
              请选择家长身份
            </div>
          </div>

          {/* <div className="form-row babies_grade" id="babies_grade">
            <label className="label">年级:</label>
            <div id="picker_grade" className="select_area">
              选择年级
              </div>
          </div> */}

          <div className="form-row babies_rank">
            <label className="label city">所在城市:</label>
            <div className={`picker_city ${!onCitySelected && "placeholder"}`}>
              <CitySelectList
                onSelected={this.citySelected}
                provinces={this.props.provinces}
                area={this.props.area}
                user_url={this.props.user_url}
              />
            </div>
          </div>
        </div>

        {/* {
          <div className="no_baby_content" style={{ display: this.state.noBabyDisplay }}>
            <div className="form-row babies_rank">
              <label className="label">居住地:</label>
              <CitySelectList 
                provinces={this.props.provinces} 
                area={this.props.area} 
                user_url={this.props.user_url} 
              />
            </div>
          </div>
        } */}

        <button
          className="handle_submit"
          type="submit"
          onClick={canBeSubmit ? debounce(this.handleSubmit) : null}
          style={{ background: canBeSubmit ? "#6ad33b" : "lightgrey" }}
        >
          确定
        </button>
        {/* <p className="skip_child">我还没有孩子，<span>跳过 <span>>></span></span></p> */}
      </div>
    );
  }
}
New.propTypes = {
  grades: PropTypes.array,
  months: PropTypes.array,
  relations: PropTypes.array,
  years: PropTypes.array,
  user_baby_url: PropTypes.string,
};
