import React from "react";
import WechatShare from "../utils/WechatShare";

export default () => {
  return (
    <WechatShare
      debug
      shareOptions={{
        link:
          "https://test.xinya.me/study_package_activity_details?from_user=163238&id=386&ref=dk",
        title: "小芒果正在学习『T3：大班』，快来为我加油！",
        desc: "",
        imgUrl:
          "https://upload.xinya.me/assets/logo_300-52215309842f5995d6e23979edc8fb87f7c05e5c88d83193ac435b977147f612.png",
      }}
    >
      <div>测试页面</div>
    </WechatShare>
  );
};

// import './Hello.scss'

// export default class Hello extends React.Component {

//   constructor(props) {
//     super(props)
//   }

//   inputOnChange(e) {
//     var recorder = document.getElementById('recorder');
//     var player = document.getElementById('player');
//     var file = e.target.files[0];
//     // Do something with the audio file.
//     alert(file)
//   }

//   startRecord = () =>{
//     alert('开始录音')
//     let self = this
//     wx.onVoiceRecordEnd({
//       complete: res => {
//         alert('停止录音')
//         self.localID = res.localId
//       },
//       success: res => {
//         alert('监听录音停止，并且成功')
//       },
//       fail: res => {
//         alert('监听录音停止，并且失败')
//       }
//     })

//     wx.ready(function() {
//       wx.startRecord({
//         fail: res => {
//           alert('录音失败')
//         },
//         success: res => {
//           alert('开始录音成功')
//           wx.playVoice({
//             localId: ''
//           });
//           wx.stopVoice({
//             localId:  ''
//           });
//         }
//       })
//     })

//   }

//   stopRecord = () => {
//     alert('停止录音')
//     this.audioEL.pause()
//     let self = this
//     wx.stopRecord({
//       success: (res) => {
//         var localId = res.localId;
//         alert(localId)
//         self.localID = localId;
//         alert(self.localID)
//       },
//       fail: res => {
//         alert(res.data)
//       },
//       cancel: res => {
//         alert(res.data)
//       }
//     })

//   }

//   playVoice = () => {
//     if (!this.isPlay) {
//       this.isPlay = true
//     }
//     alert(this.isPlay)
//     if (!this.localID) {
//       alert('不能播放录音')
//     } else if (this.isPlay) {
//       alert(this.localID)
//       alert('开始播放')
//       wx.playVoice({
//         localId: this.localID,
//         success: res => {
//         }
//       })
//       this.isplay = !this.isPlay
//     }
//   }

//   onEnded = () => {
//     alert('音频播放停止')
//   }

//   onTimeUpdate = (time) => {
//     console.log(time)
//   }

//   click = () => {
//     this.audioEL.play()
//   }

//   render() {
//     return (
//       <div className="test">
//         hello, {this.props.name}
//         <input type="file" accept="audio/*" capture="microphone" id="recorder" onChange={this.inputOnChange}/>
//         <audio id="player"></audio>
//         <div className="clearFix" style={{border: '4px solid pink', overflow: 'hidden'}}>
//             <div className="fix" style={{border: '2px solid pink', float: 'left', width: '50%'}}>我是浮动1</div>
//             <div className="fix" style={{border: '2px solid pink', float: 'left', width: '50%'}}>我是浮动2</div>
//         </div>
//         <div className="footer" style={{border: '2px solid pink'}}>我是页脚</div>
//       </div>
//     )
//   }
// }
