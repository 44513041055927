/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const Button = styled.button``;

const StyledButton = ({ className, ...restProps }) => {
  return <Button className={`btn btn-default ${className}`} {...restProps} />;
};

export default StyledButton;
