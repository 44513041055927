import React from "react";
import PropTypes from "prop-types";

import xiaoya_teacher_qrcode from "training_camp_activity/qrcode.png";
import note_one from "training_camp_activity/note_one.png";
import note_two from "training_camp_activity/note_two.png";
import note_three from "training_camp_activity/note_three.png";
import note_four from "training_camp_activity/note_four.jpg";
import note_wechat_program from "training_camp_activity/note_wechat_program.jpg";

import "./TrainingCampNote.scss";

export default class TrainingCampNote extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    return (
      <div className="comp-training-camp-note">
        <img src={xiaoya_teacher_qrcode} />
        <img src={note_one} />
        <img src={note_two} />
        <img src={note_three} />
        <img src={note_four} />
        {!this.props.review ? <img src={note_wechat_program} /> : ""}
      </div>
    );
  }
}
