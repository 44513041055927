import React from "react";

import arrowGoTo from "arrow-right.png";

import "./ShowUser.scss";

const UserBanner = ({ user_photo_url, user_name }) => {
  return (
    <div className="user_banner">
      <div className="user_banner_wrapper">
        <img className="user_avatar" src={user_photo_url} />
        <span>{user_name}</span>
      </div>
    </div>
  );
};

const UserSettingItem = ({ icon, text, url, name, point, additionalIcon }) => {
  return (
    <a className="user_setting_item" href={url}>
      <div className="icon_and_text">
        <img className={`icon ${name}`} src={icon} alt="" />
        <span className="text">{text}</span>
        <span style={{backgroundColor: '#FFFCE9', marginLeft: '20px', display: 'inline-flex', alignItems: 'center', borderRadius: '12px', padding: '2px 4px'}}>
          { additionalIcon &&
            <img className="icon additional-icon" style={{marginLeft: 0}} src={additionalIcon} />
          }
          { point &&
            <span className="baby-point">{point}</span>
          }
        </span>
      </div>
      <img src={arrowGoTo} alt="" className="arrow_go_to" />
    </a>
  );
};

const UserSettingBox = ({ settings }) => {
  return (
    <div className="user_setting_box">
      {settings.map((setting, index) => (
        <UserSettingItem
          icon={setting.icon}
          text={setting.text}
          url={setting.url}
          name={setting.key}
          key={index}
          point={setting.point}
          additionalIcon={setting.additionalIcon}
        />
      ))}
    </div>
  );
};

export default (props) => {
  console.log(props);
  const {
    user: { photo_url, name },
    settings,
  } = props;
  return (
    <div className="comp_show_user">
      <UserBanner user_photo_url={photo_url} user_name={name} />
      <UserSettingBox settings={settings} />
    </div>
  );
};
