import React from "react";

import withAppPrivacy from "@/components/study_package_activities/WithAppPrivacy.jsx";

import "./Index.scss"

class Index extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  renderContent(activity_group) {
    let className;
    switch (activity_group.activity_type) {
      case 0:
        className = 'vertical';
        break;
      case 1:
        className = 'horizontal';
        break;
      case 2:
        className = 'vertical-small';
        break;
    }

    if (className) {
      return (
        <div className={`module-content module-content-${className}`}>
          {activity_group.activities.map((activity, index) => (
            <a key={index} className="image-container" href={activity.url}>
              <img src={activity.photo_url} />
            </a>
          ))}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="modules">
        {this.props.activity_groups.map(item => (
          <div className="module">
            {item.name && <div className="module-header">{item.name}</div>}
            {item.name && item.brief && <div className="module-brief">{item.brief}</div>}
            {this.renderContent(item)}
          </div>
        ))}
      </div>
    );
  }
}

export default withAppPrivacy(Index);
