import React from "react";
import PropTypes from "prop-types";

const TopBar = React.memo(function TopBar({ schoolUrl }) {
  return (
    <div className="top_bar">
      <a href={schoolUrl}>学校</a>
      <span className="selected">经验</span>
    </div>
  );
});

TopBar.propTypes = {
  schoolUrl: PropTypes.string,
};

export default TopBar;
