import React from "react";
import PropTypes from "prop-types";

import FooterMemberCardBtn from "../shared/FooterMemberCardBtn";
import GiftsDialogProxy from "../experiences/GiftsDialog";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { reloadPage } from "../utils/helpers";

import "./SchoolFooter.scss";

export default class SchoolFooter extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      favorited: this.props.favorited,
    };
  }

  onFavorite = (e) => {
    this.favoriteToast = ToastManager.showLoading("提交中...");

    request({
      url: this.props.favorite_url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {
        this.favoriteToast.cancel();

        const data = resp.data;
        if (data.err_code && data.redirect_url) {
          window.location = data.redirect_url;
          return;
        }

        this.setState({
          favorited: data.result,
        });
      })
      .catch((err) => {
        this.favoriteToast.cancel();
      });
  };

  onFreeListen = (e) => {
    if (typeof ga === "function") {
      switch (this.props.target_type) {
        case "Experience":
          ga("send", "event", "Users", "Free", "Experiences");
          break;
        case "ExperienceAlbum":
          ga("send", "event", "Users", "Free", "ExperienceAlbums");
          break;
      }
    }

    new GiftsDialogProxy().open();
  };

  sendStats() {
    if (typeof ga === "function") {
      switch (this.props.target_type) {
        case "Experience":
          ga("send", "event", "Users", "Buy", "Experiences");
          break;
        case "ExperienceAlbum":
          ga("send", "event", "Users", "Buy", "ExperienceAlbums");
          break;
      }
    }
  }

  footerMemberCardBtnBeforeShowDialog = () => {
    this.sendStats();
  };

  onTrialCoupon = (e) => {
    this.sendStats();

    const buttons = [
      {
        label: "取消",
        onClick: () => this.confirmDialog.close(),
      },
      {
        label: "确认兑换",
        onClick: () => {
          this.confirmDialog.close();

          this.placeOrder();
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        本次购买将使用免费体验券兑换，确认兑换吗？
      </Dialog>
    );
  };

  onBuy = (e) => {
    this.sendStats();

    const buttons = [
      {
        label: "取消",
        onClick: () => this.confirmDialog.close(),
      },
      {
        label: "确认购买",
        onClick: () => {
          this.confirmDialog.close();

          this.placeOrder();
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        本次购买将扣除{this.props.card_amount}个会员点，确认购买吗？
      </Dialog>
    );
  };

  placeOrder() {
    this.placeOrderToast = ToastManager.showLoading("提交中...");
    request({
      url: this.props.experience_order_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        target_id: this.props.target_id,
        target_type: this.props.target_type,
      },
    })
      .then((resp) => {
        this.placeOrderToast.cancel();

        const data = resp.data;
        if (data.errno) {
          ToastManager.showError("购买失败");
          return;
        }

        // TODO notify users the error
        if (data.err_code && data.redirect_url) {
          window.location = data.redirect_url;
          return;
        }

        reloadPage();
      })
      .catch((err) => {
        this.placeOrderToast.cancel();
        ToastManager.showError("购买失败");
      });
  }

  createRightBtnTrialCoupon() {
    return (
      <button id="btn_buy" className="btn-buy" onClick={this.onTrialCoupon}>
        <span>
          原价<del>¥{this.props.price}</del>
        </span>
        收听（体验券）
      </button>
    );
  }

  createRightBtn() {
    return (
      <button id="btn_buy" className="btn-buy" onClick={this.onBuy}>
        <span>
          原价<del>¥{this.props.price}</del>
        </span>
        收听（{this.props.card_amount}点）
      </button>
    );
  }

  render() {
    const faved = this.state.favorited;

    let rightBtns = [];
    if (this.props.has_audios && !this.props.can_access_to_audios) {
      rightBtns[0] = (
        <div className="free_listen" onClick={this.onFreeListen}>
          免费听
        </div>
      );

      if (this.props.trial_coupon) {
        rightBtns[1] = this.createRightBtnTrialCoupon();
      } else if (this.props.card || this.props.member_card) {
        rightBtns[1] = this.createRightBtn();
      } else {
        rightBtns[1] = (
          <FooterMemberCardBtn
            {...this.props}
            beforeShowDialog={this.footerMemberCardBtnBeforeShowDialog}
          />
        );
      }
    }

    return (
      <div className="comp-school-ft">
        <div className="btn_fav" onClick={this.onFavorite}>
          <div className={`icon ${faved ? "faved" : ""}`}>
            {faved ? "已收藏" : "收藏"}
          </div>
        </div>

        {rightBtns[0] ? rightBtns[0] : ""}
        {rightBtns[1] ? rightBtns[1] : ""}
      </div>
    );
  }
}

SchoolFooter.propTypes = Object.assign({}, FooterMemberCardBtn.propTypes, {
  favorited: PropTypes.bool.isRequired,
  favorite_url: PropTypes.string.isRequired,

  has_audios: PropTypes.bool.isRequired,
  can_access_to_audios: PropTypes.bool.isRequired,

  price: PropTypes.any.isRequired,
  card_amount: PropTypes.number.isRequired,

  card: PropTypes.bool,
  member_card: PropTypes.bool,
  trial_coupon: PropTypes.bool,

  target_id: PropTypes.number.isRequired,
  target_type: PropTypes.string.isRequired,

  experience_order_url: PropTypes.string.isRequired,
});
