// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/activities/icon_zuo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/activities/icon_you.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".gallery_container{width:100%;min-height:418px}.gallery_container .left_right_step_click{width:100%;position:absolute;top:130px;-webkit-transform:translateY(-50%);transform:translateY(-50%);z-index:99}.gallery_container .left_right_step_click .step_click{width:55px;height:79px;position:absolute;background-repeat:no-repeat;background-size:100%}.gallery_container .left_right_step_click .left{left:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.gallery_container .left_right_step_click .right{right:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.gallery_container .gallery_items{width:100%;white-space:nowrap;position:relative;padding-top:18px}.gallery_container .gallery_items .item_container{margin-top:6px;display:inline-block;vertical-align:top;width:187px}.gallery_container .gallery_items .item_container img{width:100%}.gallery_container .gallery_items .active{width:193px;margin:0 6px}.gallery_container .gallery_items .active img{box-shadow:0 0 15px 5px #fff}.gallery_container .gallery_items .in_active{position:relative;margin-top:5px}.gallery_container .gallery_items .in_active:after{content:\"\";position:absolute;width:100%;height:calc(100% - 7px);left:0;top:0;z-index:5;background:rgba(0,0,0,.4)}.gallery_container .dots{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center;height:20px;margin:15px 0}.gallery_container .dots .in_active_dot{margin:0 5px;width:10px;height:10px;border-radius:50%;border:2px solid #fff}.gallery_container .dots .active_dot{margin:0 5px;width:13px;height:13px;background-color:#fff;border-radius:50%;border:2px solid #fff;box-shadow:0 0 5px 0 #e29500}", ""]);
// Exports
module.exports = exports;
