import React, { useState, useEffect } from "react";
import Crystal from "point_rewards/crystal.png";
import "../shared/tailwindcss.scss";
import ConfirmExchange from "../point_reward/ConfirmExchange";
import doExchangeReward from "../shared/request_exchange_reward";
import DispatchNotify from "../point_reward/DispatchNotify";
import ReactMarkdown from "react-markdown";
import "./Show.scss";

const CommodityDetail = ({point, exchange_redirect_url, shipment_address_id, ...others}) => {
  console.log(others)
  // const enoughPoint = point < commodity.point_cost ? false : true
  const lineStyle = {
    width: '44px',
    height: 0,
    borderTop: '1px solid #BBBBBB',
  }
  let buttonStyle = {
    backgroundImage: "linear-gradient(to bottom, #FFC000, #FF7600)",
    borderRadius: '23px',
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [showNotify, setShowNotify] = useState(false)
  const [notifyText, setNotifyText] = useState('')
  const [babyPoint, setBabyPoint] = useState(point)
  const [commodity, setCommodity] = useState(others.commodity)
  const [enoughPoint, setEnoughPoint] = useState(point < commodity.point_cost ? false : true)

  if (!enoughPoint || commodity.stock <= 0) {
    buttonStyle.opacity = 0.6
  }
  
  const ExchangeButton = () => {
    const shortLineStyle = {
      width: '10px',
      height: '3px',
      top: '4px',
      left: '21px',
    }
    const longLineStyle = {
      width: '23px',
      height: '3px',
      top: '4px',
      left: '36px',
    }

    return (
      <div className="flex justify-center items-center relative p-2 w-full" style={buttonStyle} onClick={exchangeReward}>
        <div className="absolute bg-white" style={shortLineStyle}></div>
        <div className="absolute bg-white" style={longLineStyle}></div>
        <div className="text-white text-lg text-center">{enoughPoint ? (commodity.stock > 0 ? '立即兑换' : '库存不足') : '水晶数量不足'}</div>
      </div>
    )
  }
  const chooseShipmentAddressPath = `/shipment_addresses/detail?redirect_url=${exchange_redirect_url}`
  const exchangeReward = () => {
    if (!enoughPoint || commodity.stock <= 0) {
      console.log('水晶数量不足 或者 库存不足！')
      return
    }
    setShowConfirm(true)
  }

  const considerAgain = () => {
    setShowConfirm(false)
  }

  useEffect(() => {
    if (commodity.chould_exchange_reward) {
      startToExchange()
    }
  }, []);

  const startToExchange = () => {
    const data = {
      commodity_id: commodity.id,
      shipment_address_id: shipment_address_id,
    }
    doExchangeReward({requestData: data}).then((res)=>{
      console.log(res.data)
      if (res.data.code === 200) {
        setShowConfirm(false)
        setNotifyText(res.data.data.text)
        setShowNotify(true)
        setBabyPoint(res.data.data.point)
        setEnoughPoint(babyPoint < commodity.point_cost ? false : true)
        setCommodity(Object.assign({}, commodity, {stock: res.data.data.stock}))
      }
    })
  }

  return (
    <div className="flex flex-col pb-20 commodity-detail-container">
      {showConfirm ? <ConfirmExchange
        photo={commodity.photo}
        pointCost={commodity.point_cost}
        name={commodity.name}
        considerAgain={considerAgain}
        showWindow={setShowConfirm}
        commodityId={commodity.id}
        /> : null}

      <img src={commodity.photo} />

      <div className="flex flex-col bg-white justify-between text-base p-px-15">
        <div className="text-gray-dark font-bold text-xl">{commodity.name}</div>
        <div className="text-gray-light mt-px-5 text-sm">{commodity.brief}</div>
        <div className="flex flex-row justify-between items-center mt-px-15">
          <div className="flex flex-row items-center">
            <img src={Crystal} style={{width: '36px', height: '36px'}} />
            <div className="text-yellow font-bold ml-1.5" style={{fontSize: '26px'}}>{commodity.point_cost}</div>
          </div>
          
          <div className="text-gray-light text-sm">
            <div>仅剩<span className="ml-1.5 text-yellow">{commodity.stock}</span></div>
          </div>
        </div>
      </div>

      <div className="bg-white mt-2 text-center flex flex-row items-center justify-center px-px-15 py-px-12">
        <div style={lineStyle}></div>
        <div className="text-gray-dark text-lg px-3 font-bold">商品介绍</div>
        <div style={lineStyle}></div>
      </div>
      <ReactMarkdown source={commodity.description}  className="markdown_content" />

      <div className="exchange-button fixed bottom-0 bg-white flex p-3">
        {ExchangeButton()}
      </div>

      {showNotify && <DispatchNotify shouldShowWindow={showNotify} notifyText={notifyText} />}
    </div>
  )
}

export default CommodityDetail