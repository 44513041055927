import React, { Component } from "react";
import PropTypes from "prop-types";
import HeaderMenu from "../utils/xinya_header_menu/HeaderMenu";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import {
  GalleryGroupContainer,
  GalleryItem,
} from "../utils/GalleryGroup/GalleryGroup.js";

import "../utils/GalleryGroup/GalleryGroup.scss";

const experience_list = [
  {
    id: 1,
    name: "英语从零基础进阶到初章书的自主阅读，只用了9个月",
  },
  {
    id: 2,
    name: "英语从零基础进阶到初章书的自主阅读，只用了9个月",
  },
  {
    id: 3,
    name: "英语从零基础进阶到初章书的自主阅读，只用了9个月",
  },
  {
    id: 4,
    name: "英语从零基础进阶到初章书的自主阅读，只用了9个月",
  },
  {
    id: 5,
    name: "英语从零基础进阶到初章书的自主阅读，只用了9个月",
  },
];

const ExperienceMediaLesson = () => {
  return <div className="experience_media_lesson">媒体播放</div>;
};

const ExperienceSlideContainer = (props) => {
  const { experienceList, activePosterIndex, updateActiveIndex } = props;
  return (
    <div className="gallery_container">
      <GalleryGroupContainer
        animation={{
          transition: "left .8s",
        }}
        styles={"gallery_items"}
        defaultActiveIndex={activePosterIndex}
        scrollStep={0.5}
        indexDots={{
          containerStyles: "dots",
          dotUnActiveStyle: "in_active_dot",
          dotActiveStyle: "active_dot",
        }}
        leftAndRightControllers={{
          containerStyles: "left_right_step_click",
          leftControllerStyle: "left step_click",
          rightControllerStyle: "right step_click",
        }}
        activeIndexUpdater={updateActiveIndex}
      >
        {experienceList.map((experience, index) => (
          <GalleryItem
            styles={`item_container`}
            key={index}
            activeStyle={"active"}
            unActiveStyle={"in_active"}
          >
            {
              <SimpleExperienceSection
                key={index}
                experience={experience}
                index={index}
              />
            }
          </GalleryItem>
        ))}
      </GalleryGroupContainer>
    </div>
  );
};

const SimpleExperienceSection = ({ experience, index }) => {
  return (
    <div className="simple_experience_section">
      <div className="experience_title">
        <i>{index + 1}.</i>
        <div className="title">{experience.name}</div>
      </div>
      <div className="date_and_progress">
        <div className="date">14:35</div>
        <div className="listen_progress">已听60%</div>
      </div>
    </div>
  );
};

const SimpleExperienceMarkdown = () => {
  const HeaderContent = () => {
    return (
      <div className="header_title">
        <div className="title">
          英语从零基础进阶到初章书的自主阅读，只用了9个月
        </div>
        <div className="tag">
          <span>听力启蒙</span>
          <span>自然拼读</span>
          <span>分级阅读</span>
        </div>
      </div>
    );
  };
  return (
    <div className="simple_experience_markdown">
      <HeaderContent />
      <Tabs defaultAction={this.tabClick} styles="tabs">
        <TabPane tab="详情" styles="tab" activeStyle="active">
          <LazyloadContent content={"详情-markdown-内容"} />
        </TabPane>
        <TabPane tab="讲义" styles="tab" activeStyle="active">
          <LazyloadContent content={"讲义-markdown-内容"} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default class Experiences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePosterIndex: 0,
    };
  }

  updateActiveIndex = (activePosterIndex) => {
    this.setState({
      activePosterIndex,
    });
  };

  render() {
    let { activePosterIndex } = this.state;

    console.log("activePosterIndex: ", activePosterIndex);
    console.log(experience_list);

    return (
      <div className="comp_experience_units_detail">
        <HeaderMenu />
        <ExperienceMediaLesson />
        <ExperienceSlideContainer
          experienceList={experience_list}
          activePosterIndex={activePosterIndex}
          updateActiveIndex={this.updateActiveIndex}
        />

        <SimpleExperienceMarkdown />
      </div>
    );
  }
}

ExperienceUnitsDetail.propTypes = {};
