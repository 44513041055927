import React, { Component } from "react";
import PropTypes from "prop-types";
import { print } from "../utils/helpers";
import LazyloadDimension from "./LazyLoadDimension";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import ImageEdit from "../study_packages/Certificate/ImageEdit";
import Raven from "raven-js";
import { SelectUnitTestDialog } from "./KbDimensionResult";

import passImg from "icon_quiz_pass.png";
import partPassImg from "icon_part_pass.png";
import failImg from "icon_quiz_fail.png";
import doubtImg from "icon_quiz_doubt.png";
import baby from "kb_system/baby.png";
import arrowUp from "kb_system/icon_up_active.png";
import arrowDown from "kb_system/icon_arrow_gray.png";
import arrowDownWhite from "kb_system/icon_arrow_down_white.png";
import knowledge_collection from "placeholder_school_banner.png";
import navBack from "nav-back.png";

import "./KbDimension.scss";
import "./KbDimensionResult.scss";

class DimensionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrowDownDescStatus: false,
    };
  }

  handleArrowDownDesc = () => {
    this.setState({
      arrowDownDescStatus: !this.state.arrowDownDescStatus,
    });
  };
  render() {
    const {
      score,
      abstract,
      description,
      activeTabIdx,
      ThemeColor,
      onTop,
      sTopDistance,
    } = this.props;
    const { arrowDownDescStatus } = this.state;

    let scorePoint;
    if (score) {
      const point = `${score.major.substr(1)}.${score.minor}`;
      scorePoint =
        score.minor > 90
          ? `${score.major.substr(1)}.9+`
          : Number(`${point.slice(0, -1)}`);

      // print("point: ", point + "," + "scorePoint: ", scorePoint);
    }

    return (
      <div
        className="db_dimension_container"
        id="db_dimension_container"
        style={{
          background: ThemeColor[activeTabIdx].titleBackground,
          transform: onTop ? "translateY(-100%)" : "",
          position:
            sTopDistance == 0 || sTopDistance == undefined ? "initial" : "",
        }}
      >
        <div className="db_dimension_section">
          {score && (
            <div className="complete_section_score">
              <div
                className="number_mark"
                style={{
                  color: ThemeColor[activeTabIdx].titleBackground,
                }}
              >
                <strong>{score.major.charAt(0)}</strong>
                <span>{scorePoint}</span>
              </div>
              <div
                className="understand_knowledge"
                style={{
                  color: ThemeColor[activeTabIdx].titleBackground,
                }}
              >
                已掌握{score.grade}
                <br />
                {score.unified_score}%的知识
              </div>
            </div>
          )}
          <div className="abstract_desc_box">
            {abstract && (
              <div className="db_dimension_content">
                <div className="dimension_content">
                  <MarkDownContent kbTheoryDesc={abstract} />
                </div>
              </div>
            )}
            {description && (
              <div className="db_dimension_desc">
                {arrowDownDescStatus && (
                  <div className="desc_more">
                    <MarkDownContent kbTheoryDesc={description} />
                  </div>
                )}
                <div
                  className={
                    arrowDownDescStatus ? "arrow_up_more" : "arrow_down_more"
                  }
                  onClick={this.handleArrowDownDesc}
                >
                  <img src={arrowDownWhite} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class DimensionGradeLists extends Component {
  componentDidMount() {
    this.currentGradeDeal();
  }

  currentGradeDeal = () => {
    let current_grade = document.getElementById("current_grade");
    if (current_grade) {
      current_grade.scrollIntoView();
      // window.scrollBy(0, -80);
      window.scrollBy({
        top: this.props.score ? -80 : -60,
        behavior: "smooth",
      });
    }
  };
  componentDidUpdate() {
    const forbidScroll = document.getElementsByClassName("forbid_scroll");
    if (forbidScroll.length == 0) {
      this.currentGradeDeal();
    }
  }
  render() {
    const {
      handleDetail,
      grade,
      index,
      babyGrade,
      ThemeColor,
      activeTabIdx,
    } = this.props;
    return (
      <div
        className="kb_dimension_list"
        id={babyGrade == grade.kb_theory.grade_name ? "current_grade" : ""}
      >
        {/* {grade.kb_theory && grade.kb_theory.abstract && ( */}
        <div
          className="db_dimension_content skill_require"
          style={{
            background: ThemeColor[activeTabIdx].gradeBackground,
          }}
        >
          <div className="flex_dimention_box">
            <div
              className="dimension_content dimension_content_markdown"
              style={{
                color: ThemeColor[activeTabIdx].gradeFont,
              }}
            >
              <div className="grade_rank">
                {`${
                  grade.kb_theory.grade_name == "3周岁内"
                    ? "托班"
                    : grade.kb_theory.grade_name
                }阶段`}
                <p>
                  （
                  {`${
                    grade.kb_theory.grade_name == "3周岁内"
                      ? "托班"
                      : grade.kb_theory.grade_name
                  }：`}
                  {`${grade.kb_theory.age_from} - 12个月`}）
                </p>
              </div>
              <MarkDownContent kbTheoryDesc={grade.kb_theory.abstract} />
            </div>
            <div className="img_illustration">
              <img src={baby} alt="" />
            </div>
          </div>
          {grade.kb_theory.description && (
            <DetailIntroduce
              handleDetail={handleDetail}
              index={index}
              description={grade.kb_theory.description}
              grade_name={grade.kb_theory.grade_name}
              age_scope={`${grade.kb_theory.age_from} - 12个月`}
              fontColor={ThemeColor[activeTabIdx].gradeFont}
            />
          )}
        </div>
        {/* )} */}
        {grade.kb_courses && <KnowledgeQuizList kbCourses={grade.kb_courses} />}
      </div>
    );
  }
}

const KnowledgeQuizList = ({ kbCourses }) => {
  return (
    <ul className="kb_course_box">
      {kbCourses.map((kb_course) => (
        <li key={kb_course.id}>
          {kb_course.kb_lessons.map((kb_lesson) => (
            <LiQuizList
              key={kb_lesson.id}
              kb_lesson={kb_lesson}
              kb_course={kb_course}
            />
          ))}
        </li>
      ))}
    </ul>
  );
};

class LiQuizList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClose: true,
    };
  }
  // 打开 · 收起描述
  handleIconUpDown = () => {
    this.setState(({ isClose }) => ({
      isClose: !isClose,
    }));
  };

  render() {
    const { kb_lesson, kb_course } = this.props;
    const { isClose } = this.state;
    /**
     * state:
     * 0: 未测试
     * 1: 未掌握
     * 2: 已掌握
     * 3: 部分掌握 // 已移除
     */
    const understandLevel = (state) => {
      switch (state) {
        // 0: 未测试
        case 0:
          return (
            <div className="icon_knowledge_status">
              <p style={{ color: "#999" }}>未测试</p>
              <img src={doubtImg} />
            </div>
          );
          break;

        // 1: 未掌握
        case 1:
          return (
            <div className="icon_knowledge_status">
              <p style={{ color: "#fb3a3a" }}>未掌握</p>
              <img src={failImg} />
            </div>
          );
          break;

        // 2: 已掌握
        case 2:
          return (
            <div className="icon_knowledge_status">
              <p style={{ color: "#6ad33b" }}>已掌握</p>
              <img src={passImg} />
            </div>
          );
          break;

        // 3: 部分掌握
        // case 3:
        //   return (
        //     <div className="icon_knowledge_status">
        //       <p style={{ color: "#f7d519" }}>部分掌握</p>
        //       <img src={partPassImg} />
        //     </div>
        //   );
        //   break;

        default:
          return (
            <div className="icon_knowledge_status">
              <p style={{ color: "#fb3a3a" }}>未掌握</p>
              <img src={failImg} />
            </div>
          );
          break;
      }
    };

    let totalPrevsLength = kb_lesson.prevs.length;
    let totalNextsLength = kb_lesson.nexts.length;

    let allTotalPNLength = totalPrevsLength + totalNextsLength;
    return (
      <div id={kb_lesson.id} className="sub_item_list_box">
        <div
          className="sub_item_list"
          onClick={this.handleIconUpDown}
          style={{ borderBottom: isClose ? "" : "none" }}
        >
          <div className="sub_title">
            <div className="sub_name_left">
              <div className={`${isClose ? "" : "sub_name_active"} sub_name`}>
                <span>【{kb_course.name}】</span>
                {kb_lesson.name && kb_lesson.name}
              </div>
              <p>难度等级：{kb_lesson.difficulty}</p>
            </div>
            <div className="icon_up_down">
              {isClose ? (
                <img src={arrowDown} alt="" />
              ) : (
                <div className="collapse">
                  <img src={arrowUp} alt="" />
                  <p>收起</p>
                </div>
              )}
            </div>
          </div>
          {"state" in kb_lesson && (
            <div className="icon_score_box">
              <div className="line_right"></div>
              {understandLevel(kb_lesson.state)}
            </div>
          )}
        </div>
        {!isClose && (
          <div className="collapse_describe">
            <div className="content_describe">
              {
                // 这里的markdown格式仅针对的是单个知识点展开的内容显示
                kb_lesson.description && (
                  <MarkDownContent kbTheoryDesc={kb_lesson.description} />
                )
              }
            </div>
            {(kb_lesson.prevs.length > 0 || kb_lesson.nexts.length > 0) && (
              <div className="line_progress_guide"></div>
            )}
            <div className="knowledge_relative">
              <ul className="kbs_series_content">
                {kb_lesson.prevs.length > 0 && (
                  <li
                    className={`prev_order ${
                      kb_lesson.nexts.length <= 0 ? "no_kb_nexts" : ""
                    }`}
                  >
                    <div className="progress_kbs">
                      <p>{kb_lesson.prevs[0].difficulty}</p>
                      <div className="progress_name">
                        {kb_lesson.prevs[0].name.length > 10
                          ? kb_lesson.prevs[0].name.slice(0, -1) + "..."
                          : kb_lesson.prevs[0].name}
                      </div>
                    </div>
                  </li>
                )}
                {kb_lesson.prevs.length == 0 && kb_lesson.nexts.length == 0 ? (
                  ""
                ) : (
                  <li className="current_active">
                    <div className="progress_kbs">
                      <p>{kb_lesson.difficulty}</p>
                      <div className="progress_name">{kb_lesson.name}</div>
                    </div>
                  </li>
                )}
                {kb_lesson.nexts.length > 0 && (
                  <li
                    className={`next_order ${
                      kb_lesson.prevs.length <= 0 ? "no_kb_prevs" : ""
                    }`}
                  >
                    <div className="progress_kbs">
                      <p>{kb_lesson.nexts[0].difficulty}</p>
                      <div className="progress_name">
                        {kb_lesson.nexts[0].name.length > 10
                          ? kb_lesson.nexts[0].name.slice(0, -1) + "..."
                          : kb_lesson.nexts[0].name}
                      </div>
                    </div>
                  </li>
                )}
              </ul>
              {allTotalPNLength > 3 && (
                <div className="more_kb_tips">
                  <span>...</span>等{allTotalPNLength}个知识点
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const DetailIntroduce = ({
  handleDetail,
  index,
  description,
  grade_name,
  age_scope,
  fontColor,
}) => {
  return (
    <div
      className="detail_introduce"
      style={{
        color: fontColor,
      }}
      onClick={handleDetail}
      data-index={index}
      data-description={description}
      data-grade-name={grade_name}
      data-age-scope={age_scope}
    >
      专业解读
    </div>
  );
};

const MarkDownContent = ({ kbTheoryDesc }) => {
  return <LazyloadDimension content={kbTheoryDesc} />;
};

const DialogDetailIntroduce = ({
  handleCloseDialog,
  kbTheoryDesc,
  kbGrade,
  kbAge,
}) => {
  return (
    <div className="dialog_detail_introduce">
      <div className="overlay" />
      <div className="dialog_container">
        <div className="title">
          {`${kbGrade == "3周岁内" ? "托班" : kbGrade}阶段`}
          <p>{kbAge}</p>
        </div>
        <div className="close_button_right" onClick={handleCloseDialog}>
          &times;
        </div>
        <MarkDownContent kbTheoryDesc={kbTheoryDesc} />
      </div>
    </div>
  );
};

class DimensionSectionContainer extends Component {
  componentDidMount() {
    let getOnTopStatus = this.props.getOnTopStatus;
    // this.onScrollPageListen.call(this, getOnTopStatus);
  }

  // TODO: 上滑隐藏, 下滑显示
  onScrollPageListen(getOnTopStatus) {
    let scroll = function () {
      let scrollEle = document.getElementById("dimension_section_container");
      return {
        top: scrollEle.scrollTop,
      };
    };
    document.getElementById(
      "dimension_section_container"
    ).onscroll = (function (self, scroll) {
      let sTop = scroll().top;
      return function () {
        let onTop = null;
        // 向上滑动
        if (sTop < scroll().top) {
          onTop = true;
        }
        if (sTop != scroll().top) {
          sTop = scroll().top;
        }
        // console.log("onTop: ", onTop);
        // console.log("sTop: ", sTop);
        getOnTopStatus(onTop, sTop);
      };
    })(this, scroll);
  }

  render() {
    const { renderScore, renderGradeList } = this.props;
    return (
      <div
        className="dimension_section_container"
        id="dimension_section_container"
      >
        <div className={`knowledge`}>
          {renderScore}
          {renderGradeList}
        </div>
      </div>
    );
  }
}

class HeaderSlideMenu extends Component {
  constructor(props) {
    super(props);
    let search_url = window.location.search;
    let alterTabIdx = search_url.charAt(search_url.length - 1);
    this.state = {
      activeTabIdx: alterTabIdx ? alterTabIdx - 1 : 0,
    };
  }

  handleSlideLiClick = (e) => {
    let idx = e.currentTarget.getAttribute("data-index");
    let url = e.currentTarget.getAttribute("data-url");
    this.setState({
      activeTabIdx: idx,
      activeTabUrl: url,
    });
    this.props.getHeadMenuActiveTabIdx(idx);
    this.ajaxRequest.call(this, url, idx);
  };

  componentDidUpdate() {
    // 点击左移
    let idx = this.state.activeTabIdx ? this.state.activeTabIdx : 0;
    let widthValue = 0;
    if (document.getElementById("head_menu_ul")) {
      for (let i = 0; i < idx; i++) {
        widthValue += document.getElementById("head_menu_ul").children[i]
          .offsetWidth;
      }
      // console.log("widthValue: ", widthValue);
      this.tabList.scrollLeft = widthValue - 15;
      // $("#head_menu_ul").animate({ scrollLeft: (widthValue - 15) }, 500);
    }

    // 当前高亮元素的前一个元素 更改样式
    let tab_light = document.getElementsByClassName("tab_light")[0];
    let head_menu_li = document.getElementsByClassName("head_menu_li");
    if (tab_light) {
      let preActiveTabIndex =
        this.state.activeTabIdx == 0 ? 0 : this.state.activeTabIdx - 1;
      if (this.state.activeTabIdx == 0) {
        head_menu_li[preActiveTabIndex].classList.add("pre_first_tab_style");
      } else {
        head_menu_li[preActiveTabIndex].classList.add("pre_tab_style");
      }
    }
  }

  ajaxRequest(url, idx) {
    this.toast = ToastManager.showLoading("加载中...");
    request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        this.props.getSingleRequestData(data, true);
      })
      .catch((err) => {
        this.toast.cancel();
        Raven.captureException(err);
      });
  }

  render() {
    const { kbDimensionsGroup, ThemeColor } = this.props;
    const { activeTabIdx } = this.state;
    const HeadMenuTab = ({ ThemeColor }) => {
      return (
        <div
          className="head_menu_ul"
          id="head_menu_ul"
          ref={(tab) => (this.tabList = tab)}
        >
          {kbDimensionsGroup.map((group, index) => {
            let scorePoint;
            if (group.score) {
              const point = `${group.score.major.substr(1)}.${
                group.score.minor
              }`;
              const point_plus =
                `${group.score.band}` + Number(`${point.slice(0, -1)}`);
              scorePoint =
                group.score.minor > 90
                  ? `${group.score.band}${group.score.major.substr(1)}.9+`
                  : point_plus;

              // print("point619: ", point + "," + "scorePoint: ", scorePoint);
            }
            let tabStyle = {
              backgroundColor:
                ThemeColor[index % (ThemeColor.length - 1)].titleBackground,
              zIndex: kbDimensionsGroup.length - index,
            };
            return (
              <div
                key={index}
                data-index={index}
                data-url={group.get_result_url}
                className={`head_menu_li ${
                  activeTabIdx == index ? "tab_light" : ""
                }`}
                onClick={this.handleSlideLiClick}
                style={tabStyle}
              >
                <div className="sub_menu_text">
                  <span>{group.name}</span>
                  <p>
                    {group.brief
                      ? group.brief
                      : group.score
                      ? scorePoint
                      : "未测试"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <div className="kb_dimension_header" id="kb_dimension_header">
        <HeadMenuTab ThemeColor={ThemeColor} />
      </div>
    );
  }
}

export default class KbDimension extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    const ThemeColor = [
      {
        titleBackground: "#8080ff",
        gradeBackground: "#d9d9ff",
        gradeFont: "#4d4d99",
        shadow: "64,64,128,0.3",
      },
      {
        titleBackground: "#4dc3ff",
        gradeBackground: "#d9f2ff",
        gradeFont: "#4d8099",
        shadow: "64,106,128,0.3",
      },
      {
        titleBackground: "#3dcc6d",
        gradeBackground: "#d9ffe5",
        gradeFont: "#4d9966",
        shadow: "64,128,85,0.3",
      },
      {
        titleBackground: "#ffa64d",
        gradeBackground: "#ffecd9",
        gradeFont: "#99734d",
        shadow: "128,96,64,0.3",
      },
      {
        titleBackground: "#ff6a4d",
        gradeBackground: "#ffdfd9",
        gradeFont: "#99594d",
        shadow: "128,74,64,0.3",
      },
      {
        titleBackground: "#ccbb66",
        gradeBackground: "#fff9d9",
        gradeFont: "#998c4d",
        shadow: "128,117,64,0.3",
      },
      {
        titleBackground: "#bf80ff",
        gradeBackground: "#ecd9ff",
        gradeFont: "#734d99",
        shadow: "96,64,128,0.3",
      },
      {
        titleBackground: "#ff8095",
        gradeBackground: "#ffd9df",
        gradeFont: "#994d59",
        shadow: "128,64,74,0.3",
      },
      {
        titleBackground: "#45e6cb",
        gradeBackground: "#d9fff9",
        gradeFont: "#4d998c",
        shadow: "64,128,117,0.3",
      },
      {
        titleBackground: "#74d941",
        gradeBackground: "#e5ffd9",
        gradeFont: "#66994d",
        shadow: "85,128,64,0.3",
      },
    ];
    this.state = {
      detailIntroduceStatus: false,
      unitTestDialogStatus: false,
      ThemeColor,
    };
  }

  handleDetailIntroduce = (e) => {
    const data_index = e.currentTarget.getAttribute("data-index");
    const data_description = e.currentTarget.getAttribute("data-description");
    const data_grade_name = e.currentTarget.getAttribute("data-grade-name");
    const data_age_scope = e.currentTarget.getAttribute("data-age-scope");
    this.setState({
      detailIntroduceStatus: true,
      kbTheoryIdx: data_index,
      kbTheoryDescription: data_description,
      kbTheoryGradeName: data_grade_name,
      dkbTheoryGradeAge: data_age_scope,
    });
    document
      .getElementById("kb_dimension_header")
      .classList.add("forbid_scroll");
  };

  handleCloseDialog = () => {
    this.setState({
      detailIntroduceStatus: false,
    });
  };

  getSingleRequestData = (data, boolState) => {
    print("回调data: ", data);
    const activeMainContentStatus = boolState;
    const {
      abstract,
      description,
      id,
      grades,
      name,
      score,
      examination,
    } = data;
    this.setState(() => ({
      abstract,
      description,
      id,
      grades,
      name,
      score,
      examination,
      activeMainContentStatus,
    }));
  };

  componentDidMount() {
    // 默认第一个tab
    if (document.getElementsByClassName("head_menu_li")) {
      document
        .getElementsByClassName("head_menu_li")[0]
        .classList.add("tab_light");

      $(".tab_light").trigger("click");
    }
  }

  // 进入单元测试事件
  handleUnitTestDialog = (boolState) => {
    this.setState({
      unitTestDialogStatus: boolState,
    });
    document
      .getElementById("kb_dimension_header")
      .classList.add("forbid_scroll");
  };

  // 获取顶部导航栏激活的 tab_idx
  getHeadMenuActiveTabIdx = (tab_idx) => {
    this.setState({
      activeTabIdx: tab_idx,
    });
  };

  getOnTopStatus = (bool_state, sTop) => {
    this.setState({
      onTop: bool_state,
      sTopDistance: sTop,
    });
  };

  render() {
    const { kb_dimensions } = this.props;
    const {
      detailIntroduceStatus,
      kbTheoryIdx,
      kbTheoryDescription,
      kbTheoryGradeName,
      dkbTheoryGradeAge,
      abstract,
      description,
      grades,
      name,
      score,
      examination,
      unitTestDialogStatus,
      activeMainContentStatus,
      ThemeColor,
      activeTabIdx,
      onTop,
      sTopDistance,
    } = this.state;
    let babyGrade = this.props.baby && this.props.baby.grade;

    let pureExaminations = examination;
    let judgeExaminationScoreExit = examination ? examination.score : "";

    console.log("pureExaminations: ", pureExaminations);

    return (
      <div className="comp_kb_dimension">
        <HeaderSlideMenu
          kbDimensionsGroup={kb_dimensions}
          onTop={onTop}
          getSingleRequestData={this.getSingleRequestData}
          // getSubKbDimensions={this.getSubKbDimensions}
          ThemeColor={ThemeColor}
          getHeadMenuActiveTabIdx={this.getHeadMenuActiveTabIdx}
        />
        {activeMainContentStatus && (
          <DimensionSectionContainer
            getOnTopStatus={this.getOnTopStatus}
            renderScore={
              <DimensionScore
                score={score}
                abstract={abstract}
                description={description}
                ThemeColor={ThemeColor}
                activeTabIdx={activeTabIdx}
                onTop={onTop}
                sTopDistance={sTopDistance}
              />
            }
            renderGradeList={grades.map((grade, index) => (
              <DimensionGradeLists
                key={index}
                grade={grade}
                score={score}
                index={index}
                handleDetail={this.handleDetailIntroduce}
                babyGrade={babyGrade}
                ThemeColor={ThemeColor}
                activeTabIdx={activeTabIdx}
              />
            ))}
          />
        )}

        {
          // 进入单元测试
          unitTestDialogStatus && (
            <SelectUnitTestDialog
              examinations={pureExaminations}
              handleUnitTestDialog={this.handleUnitTestDialog}
            />
          )
        }

        {
          // 底部 “继续测试”
          <LinkTestDimension
            handleUnitTestDialog={this.handleUnitTestDialog}
            usableExamination={pureExaminations}
            examinationScore={judgeExaminationScoreExit}
            ThemeColor={ThemeColor}
            activeTabIdx={activeTabIdx}
          />
        }

        {detailIntroduceStatus && (
          <DialogDetailIntroduce
            handleCloseDialog={this.handleCloseDialog}
            kbTheoryDesc={kbTheoryDescription}
            kbGrade={kbTheoryGradeName}
            kbAge={dkbTheoryGradeAge}
          />
        )}
      </div>
    );
  }
}

const LinkTestDimension = ({
  handleUnitTestDialog,
  usableExamination,
  examinationScore,
  ThemeColor,
  activeTabIdx,
}) => {
  return (
    <div className="link_test_dimension">
      <div className="icon_left_guide">
        <a className="img_box" href="/kb_dimensions/result">
          <img src={navBack} alt="" />
        </a>
        <div
          className="unit_test_section"
          onClick={
            usableExamination ? handleUnitTestDialog.bind(this, true) : null
          }
          style={{
            background: usableExamination
              ? ThemeColor[activeTabIdx].titleBackground
              : "#ddd",
            boxShadow: usableExamination
              ? ThemeColor[activeTabIdx].shadow
              : "1px 2px 4px 0 #ddd",
          }}
        >
          {examinationScore ? "详细测试" : "开始测试"}
        </div>
      </div>
    </div>
  );
};

KbDimension.propTypes = {
  kb_dimension_groups: PropTypes.array,
};
