import React, { Component } from "react";
import UserAgent from "../utils/UserAgent";
import passImg from "icon_quiz_pass.png";
import failImg from "icon_quiz_fail.png";
import doubtImg from "icon_quiz_doubt.png";
import InterviewClearSection from "../utils/interview_clear/InterviewClearSection";

import "./MediaLesson.scss";

const SchoolExamsBox = ({ media_course, handleExamUrlClick }) => {
  return (
    <div className="school_exams_box">
      {media_course.media_lessons.length > 0 && (
        <div className="exam_title">
          {media_course.name}
          {media_course.grade && "（" + media_course.grade + "水平）"}
        </div>
      )}
      <ul>
        {media_course.media_lessons.length > 0 &&
          media_course.media_lessons.map((media_lesson, index) => (
            <li
              key={index}
              data-url={media_lesson.url}
              onClick={handleExamUrlClick}
            >
              {media_lesson.passed == true ? (
                <img src={passImg} />
              ) : media_lesson.passed == false ? (
                <img src={failImg} />
              ) : (
                <img src={doubtImg} />
              )}
              <span>{media_lesson.name}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default class MediaLesson extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  handleExamUrlClick = (e) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute("data-url");
    console.log("ExamUrl", url);
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  render() {
    const { media_courses, title, interview_clear_url } = this.props;
    return (
      <div className="comp_school_exams_list">
        <div className="interview_exam_emphases">历年面试考点：</div>
        {media_courses.map((media_course, idx) => (
          <SchoolExamsBox
            key={idx}
            media_course={media_course}
            handleExamUrlClick={this.handleExamUrlClick}
          />
        ))}
        {
          <InterviewClearSection
            title={title}
            interview_clear_url={interview_clear_url}
            text={"重做本学校题目 »"}
          />
        }
      </div>
    );
  }
}
