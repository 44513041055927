import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import echarts from "echarts";
import { chineseMonthDayDate } from "../utils/formatAudioTime";
import { formatDotDate } from "../utils/formatAudioTime";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import icon_baby from "kb_system/baby.png";
import navBack from "nav-back.png";

import "./KbDimension.scss";

const RadarSection = () => {
  return <div id="chart1" className="e_chart" />;
};

const TopThoughtSkillSection = ({ baby, ratio, score }) => {
  let scorePoint;
  let scorePercent;
  if (score) {
    scorePoint =
      score.minor > 90
        ? `${score.major.substr(1)}.9+`
        : Number(`${score.major.substr(1)}.${score.minor}`.slice(0, -1));
    scorePercent =
      score.minor < 100 ? Number(`0.${score.minor}`.slice(0, -1)) : 1;
    console.log("scorePercent: ", scorePercent);
    console.log("scorePoint: ", scorePoint);
  }
  return (
    <div className="thought_skill_container">
      <div className="baby_info_value">
        <div className="baby_info">
          <img
            src={
              baby.photo_url ||
              (baby.gender === "男" ? iconBoyUser : iconGirlUser)
            }
            alt=""
          />
          <div className="baby_name_birth">
            <div className="baby_nickname">{baby.nickname}</div>
            <span>{formatDotDate(baby.day_of_birth).slice(0, -3)}</span>
          </div>
        </div>
        <div className="skill_value">能力值说明</div>
      </div>
      {/* <div className="top_score_section">
        <div className="skill_left_box">
          {score && (
            <div className="thought_center_value">
              <div className="thought_name">数学思维能力值</div>
              <div className="thought_score">
                <strong>{score.band}</strong>
                <span>{scorePoint}</span>
              </div>
              <div className="thought_value_content">
                {`已掌握${score.grade}${scorePercent * 100}%的知识`}
              </div>
            </div>
          )}
          <div className="complete_progress">
            <div className="quiz_complete_text">
              <p>题型完整度：</p>
              <p>{`${ratio}`}%</p>
            </div>
            <div className="progress_bar">
              <div className="progress" style={{ width: `${ratio}%` }} />
            </div>
          </div>
        </div>
        <div className="img_illustration">
          <img src={icon_baby} alt="" />
        </div>
      </div> */}
      <div className="no_score_section">
        <p>请先完成综合测试</p>
        <div className="button_start">开始测试</div>
        <div className="test_require">
          每份测试卷一个月内仅能做一次，
          <br /> 为保证测试的准确性，请让小宝独立完成
        </div>
      </div>
    </div>
  );
};

const DynamicUpdate = ({ updateDynamicUrl, record }) => {
  return (
    <div className="dynamic_update_container">
      <a className="dynamic_content" target="_blank">
        {record && record.courses ? (
          <div className="content">
            <p>
              {`${chineseMonthDayDate(record.updated_at)}，更新
                ${
                  record.courses.reduce((pre, current, index) => {
                    if (index < 3) {
                      return pre + `《${current.name}》`;
                    } else {
                      return pre;
                    }
                  }, "") + "等"
                }
                ${record.courses.length}个知识点的${
                record.tags_changed_count
              }个题型数据。
                ${
                  record.ratio_updated
                    ? `完整度+${record.ratio_updated / 100}%`
                    : ""
                }
                能力水平：${
                  typeof record.prev_score == "object"
                    ? record.prev_score.minor > 90
                      ? record.prev_score.major + ".9+"
                      : record.prev_score.major +
                        "." +
                        record.prev_score.minor +
                        " -> "
                    : ""
                }${
                record.score.minor > 90
                  ? record.score.major + ".9+"
                  : record.score.major + "." + record.score.minor
              }`}
            </p>
            <span>3分钟前</span>
          </div>
        ) : (
          <p>暂无动态更新</p>
        )}
      </a>
    </div>
  );
};

const TargetSkillCompare = ({ nickName, handleTargetChange }) => {
  return (
    <div className="target_skill_container">
      <div className="title_and_change">
        <div className="title">
          <div className="border_light" />
          <div className="name">能力值</div>
        </div>
        <div
          className="target_change"
          onClick={handleTargetChange.bind(this, true)}
        >
          <span>更换目标</span>
        </div>
      </div>

      <div className="main_text_section">
        <div className="baby_skill_value">{nickName}</div>
        <div className="school_skill_value">同月龄孩子</div>
      </div>
      <RadarSection />
    </div>
  );
};

const TargetChangeLists = ({ handleTargetChange, kbDimensionGroups }) => {
  return (
    <div
      className="dialog_target"
      onClick={handleTargetChange.bind(this, false)}
    >
      <div className="dialog_body">
        <ul>
          {kbDimensionGroups.map((group) => (
            <li key={group.id}>
              <a href="#">{group.name}</a>
              {/* <a href={`#${group.url}`}>{group.name}</a> */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const LinkTest = ({
  handleUnitTestDialog,
  usableExamination,
  score,
}) => {
  const unUseableStyle = {
    background: "#ddd",
    boxShadow: "1px 2px 4px 0 #ddd",
  };
  return (
    <div className="link_test">
      <div className="link_test_box">
        <div
          className="unit_test_section"
          // style={usableExamination ? {} : unUseableStyle}
        >
          {/* {score ? "详细测试" : "开始测试"} */}
          {`推荐测试：数学思维能力值（中班）`}
        </div>
        <a
          href="javascript: void(0)"
          onClick={
            // usableExamination ? handleUnitTestDialog.bind(this, true) : null
            handleUnitTestDialog.bind(this, true)
          }
        >
          更多
        </a>
      </div>
    </div>
  );
};

const UnitTestSection = ({ examination, score }) => {
  const { url, complete_ratio, name, quizzes_count } = examination;
  return (
    <a href={url + "&kb_dimension"}>
      <div
        className={`calendar_course ${
          examination.score || score ? "finish_calendar_course" : ""
        }`}
      >
        <div className="knowledge_content">
          <h2>{name}</h2>
          <div className="course_subscrible_practice">
            <div className="course_time_difficulty">
              <i>题型完整度{complete_ratio}%</i>
              <span>{quizzes_count}题</span>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export const SelectUnitTestDialog = ({
  kbDimensionGroups,
  examinations,
  handleUnitTestDialog,
}) => {
  let examinationsIsArray = Array.isArray(examinations);

  // console.log('201 examinations: ', examinations);
  // console.log('204 examinationsIsArray: ', examinationsIsArray);
  return (
    <div className="select_unit_test">
      <div className="course_detail_section">
        <div className="course_detail_box">
          <div className="head_title">
            <p>请选择单元进入测试</p>
            <div
              className="close_dialog"
              onClick={handleUnitTestDialog.bind(this, false)}
            >
              &times;
            </div>
          </div>
          <div className="calendar_course_list">
            {/* <div className="recommend_unit_test">
              <div className="text">推荐优先测试</div>
              {<UnitTestSection />}
            </div> */}
            <div className="normal_unit_test">
              <div className="text">正常测试</div>
              {kbDimensionGroups ? (
                kbDimensionGroups.map(
                  (kb_group, index) =>
                    kb_group.examination && (
                      <UnitTestSection
                        score={kb_group.score}
                        examination={kb_group.examination}
                        key={index}
                      />
                    )
                )
              ) : // examinations 为数组集合
              examinationsIsArray ? (
                examinations.map((examination, idx) => (
                  <UnitTestSection examination={examination} key={idx} />
                ))
              ) : (
                <UnitTestSection examination={examinations} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KbDimensionResult = React.memo(({ ...props }) => {
  const [dialogTargetChange, setDialogTargetChange] = useState(false);
  const [unitTestDialogStatus, setUnitTestDialogStatus] = useState(false);
  console.log("props：", props);

  const {
    baby,
    ratio,
    kb_dimension_groups,
    score,
    kb_progress_updated_record,
    dynamic_update_url,
  } = props;

  useEffect(() => {
    let newRedirectArray = [];
    let indicatorArray = []; // 雷达各个边界相应数值
    let schoolAbilityValues = ["58", "75", "48"]; // 学校能力值 TODO:
    let babyAbilityValues = []; // 宝宝能力值
    let groupScores = []; // 测试成绩集合 如: ["G1.5", "", ""]
    let groupScore; // 测试成绩 如: G1.5
    kb_dimension_groups.map((kb_group, index) => {
      let minor;
      let radarScore;
      let name = `${kb_group.name ? `${kb_group.name}` : ""}`;

      if (kb_group.score) {
        minor = Number(`${kb_group.score.minor}`);
        radarScore =
          minor > 90
            ? `${kb_group.score.major.substr(1)}.9+`
            : Number(
                (kb_group.score.major.substr(1) + "." + minor).slice(0, -1)
              );
        groupScore = `${kb_group.score.band}${radarScore}`;
      } else {
        groupScore = "";
      }
      // console.log("minor:", minor + "," + "radarScore:", radarScore);
      // console.log("groupScore 单项成绩:", groupScore);

      indicatorArray.push({
        // name: `${name}`,
        name: `${name}\n${groupScore ? groupScore : ""}`,
        max: 100,
        color: "#000",
      });
      groupScores.push(groupScore);
      babyAbilityValues.push(kb_group.score ? kb_group.score.unified_score : 0);
      newRedirectArray.push({
        name: kb_group.name,
        id: kb_group.id,
      });
    });

    const schoolValuesArray = schoolAbilityValues;
    const babyValuesArray = babyAbilityValues;

    console.log("babyValuesArray 宝宝能力值: ", babyValuesArray);
    console.log("schoolValuesArray 学校能力值: ", schoolValuesArray);
    console.log("indicatorArray: 雷达图显示区域", indicatorArray);
    console.log("groupScores 整项成绩集合: ", groupScores);
    // console.log("newRedirectArray 用于点击跳转: ", newRedirectArray);

    // 基于准备好的容器(这里的容器是id为chart1的div)，初始化echarts实例
    var chart1 = echarts.init(document.getElementById("chart1"));

    // 指定图表的配置项和数据
    var option = {
      backgroundColor: "rgba(255,255,255,1)", // 背景色，默认无背景	rgba(51,255,255,0.7)
      radar: [
        {
          // 雷达图坐标系组件，只适用于雷达图。
          center: ["50%", "50%"], // 圆中心坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
          radius: "50%", // 圆的半径，数组的第一项是内半径，第二项是外半径。
          startAngle: 90, // 坐标系起始角度，也就是第一个指示器轴的角度。[ default: 90 ]
          name: {
            formatter: [
              `{value}`, // 名称
              // `{detailStyle|详情}` // 详情
            ].join("\n"),

            textStyle: {
              // align: "center",
              lineHeight: 18,
              color: "#333",
            },
            // rich: {
            //   detailStyle: {
            //     width: 50,
            //     height: 20,
            //     lineHeight: 20,
            //     marginTop: 10,
            //     borderWidth: 1,
            //     backgroundColor: "#3dcc6d",
            //     borderRadius: 10,
            //     color: "#fff",
            //     fontSize: 12, // 雷达图 · 详情按钮的文字大小
            //     align: "center",
            //     fontWeight: 700
            //   },
            //   scoreStyle: {
            //     color: "#333",
            //     height: 22,
            //     fontSize: 14,
            //     fontWeight: 700
            //   }
            // }
          },
          nameGap: 15, // 指示器名称和指示器轴的距离。[ default: 15 ]
          splitNumber: 3, // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
          shape: "circle", // 雷达图绘制类型，支持 'polygon'(多边形) 和 'circle'(圆)。[ default: 'polygon' ]
          axisLine: {
            // (圆内的几条直线)坐标轴轴线相关设置
            lineStyle: {
              color: "#eee", // 坐标轴线线的颜色
              opacity: 0.5,
              width: 1, // 坐标轴线线宽。
              type: "solid", // 坐标轴线线的类型。
            },
          },
          splitLine: {
            // (这里是指所有圆环)坐标轴在 grid 区域中的分隔线。
            lineStyle: {
              color: "#eee", // 分隔线颜色
              opacity: 0.5,
              width: 1.5, // 分隔线线宽
            },
          },
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ["rgba(255,255,255,1)", "rgba(255,255,255,1)"],
              // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          indicator: indicatorArray,
          triggerEvent: true, // add event
        },
      ],
      series: [
        {
          name: "雷达图", // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
          type: "radar", // 系列类型: 雷达图
          itemStyle: {
            // 折线拐点标志的样式。
            normal: {
              // 普通状态时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 0, // 控制每个折点的显示/隐藏
            },
            emphasis: {
              // 高亮时的样式
              lineStyle: {
                width: 1,
              },
              opacity: 1,
            },
          },
          data: [
            {
              name: "小学能力要求值",
              value: schoolValuesArray,
              symbol: "circle",
              symbolSize: schoolValuesArray.length,
              label: {
                normal: {
                  show: false,
                  position: "top",
                  distance: 5,
                  color: "#8080ff",
                  fontSize: 14,
                  formatter: function (params) {
                    console.log("小学能力要求值: ", params);
                    return params.value;
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: "#8080ff",
                  borderColor: "#8080ff",
                  borderWidth: 1,
                },
              },
              lineStyle: {
                normal: {
                  type: "dashed",
                  color: "#f00",
                  opacity: 0.5,
                },
              },
              areaStyle: {
                normal: {
                  color: "#8080ff",
                },
              },
            },
            {
              name: "小宝能力值",
              value: babyValuesArray,
              symbol: "circle",
              symbolSize: babyValuesArray.length,
              label: {
                normal: {
                  show: false,
                  position: "top",
                  distance: 5,
                  color: "#ffc600",
                  fontSize: 14,
                  formatter: function (params) {
                    console.log("小宝能力值: ", params);
                    return params.value;
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: "#ffc600",
                  borderColor: "#ffc600",
                  borderWidth: 1,
                },
              },
              lineStyle: {
                normal: {
                  opacity: 0.5,
                },
              },
              areaStyle: {
                normal: {
                  color: "#ffc600",
                },
              },
            },
          ],
        },
      ],
    };

    chart1.setOption(option);
    window.onresize = function () {
      chart1.resize();
    };

    // 增加 indictor name 点击事件
    chart1.on("click", (params) => {
      console.log("params: ", params);
      let name = params.name.split("\n")[0];
      if (name == "小宝能力值" || name == "小学能力要求值") {
        return;
      }

      // 点击相应的标题和构造数组遍历name做匹配, 找到则跳转
      newRedirectArray.map((item) =>
        name == item.name
          ? window.open("/kb_dimensions?kb_dimension_group_id=" + item.id)
          : ""
      );
    });
  }, []);

  // 更换目标事件
  const handleTargetChange = (boolState) => {
    setDialogTargetChange(boolState);
  };

  // 进入单元测试事件
  const handleUnitTestDialog = (boolState) => {
    setUnitTestDialogStatus(boolState);
  };

  const usableExamination = kb_dimension_groups.some(
    (item) => item.examination
  );
  const usableScore = kb_dimension_groups.some((item) => item.score);

  console.log("usableExamination: ", usableExamination);
  console.log("usableScore: ", usableScore);

  return (
    <div className="comp_kb_dimension_result">
      {baby && (
        <TopThoughtSkillSection baby={baby} ratio={ratio} score={score} />
      )}
      <DynamicUpdate
        updateDynamicUrl={dynamic_update_url}
        record={kb_progress_updated_record}
      />

      <TargetSkillCompare
        nickName={baby ? baby.nickname : ""}
        handleTargetChange={handleTargetChange}
      />
      <RecommendTestSection />

      {
        // 更换目标弹框列表
        dialogTargetChange && (
          <TargetChangeLists
            handleTargetChange={handleTargetChange}
            kbDimensionGroups={kb_dimension_groups}
          />
        )
      }

      {
        // 进入单元测试
        unitTestDialogStatus && (
          <SelectUnitTestDialog
            kbDimensionGroups={kb_dimension_groups}
            handleUnitTestDialog={handleUnitTestDialog}
          />
        )
      }

      {
        // 底部 “继续测试”
        <LinkTest
          handleUnitTestDialog={handleUnitTestDialog}
          usableExamination={usableExamination}
          score={usableScore}
        />
      }
    </div>
  );
});

const RecommendTestSection = () => {
  return (
    <div className="recommend_test_section">
      <div className="text_desc">推荐测试</div>
      <ul>
        <li>
          <div className="content_box">
            <div className="name">数学思维能力值（中班）</div>
            <p>根据数学思维能力值测试（小班）成绩推荐</p>
          </div>
          <a className="go_test" href="#">
            去测试
          </a>
        </li>
        <li>
          <div className="content_box">
            <div className="name">数学思维能力值（中班）</div>
            <p>根据数学思维能力值测试（小班）成绩推荐</p>
          </div>
          <a className="go_test" href="#">
            去测试
          </a>
        </li>
        <li>
          <div className="content_box">
            <div className="name">数学思维能力值（中班）</div>
            <p>根据数学思维能力值测试（小班）成绩推荐</p>
          </div>
          <a className="go_test" href="#">
            去测试
          </a>
        </li>
      </ul>
    </div>
  );
};

export default KbDimensionResult;

KbDimensionResult.propTypes = {
  baby: PropTypes.object,
  kb_dimension_groups: PropTypes.array,
  ratio: PropTypes.number,
  score: PropTypes.object,
};
