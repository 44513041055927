import React from "react";
import PropTypes from "prop-types";

import $ from "jquery";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import UserAgent from "../utils/UserAgent";
import CommentLists from "../utils/comment_lists/CommentLists";

import ReactMarkdown from "react-markdown";
import ProblemFold from "../utils/ProblemFold";

import arrowDown from "arrow-down.png";

import "./StudyPackageGroup.scss";
import "../utils/ReactMarkDown.scss";

class CourseLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChooseDisplay: "none",
    };
  }

  btnCloseRecommendListClick = () => {
    this.setState({
      isChooseDisplay: "none",
    });
  };
  render() {
    return (
      <div
        className="recommend_choose_list"
        style={{ display: this.state.isChooseDisplay }}
      >
        <div className="overlay"></div>
        <div className="course_list_container">
          <h3>请选择课程</h3>
          <ul>
            {this.props.activities.map((activity, index) => (
              <li key={index}>
                <a href={activity.activity_url}>
                  <div className="content_top">
                    <div className="course_price">¥{activity.min_price}起</div>
                    <div className="course_title">{activity.name}</div>
                  </div>
                  <div className="course_desc">{activity.brief}</div>
                  <div className="sign_course">
                    <div className="go_sign">立即报名</div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={this.btnCloseRecommendListClick}
          >
            &times;
          </div>
        </div>
      </div>
    );
  }
}

export default class StudyPackageGroup extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      isCourseEvaluateContainer: "none",
      isCourseContainer: "block",
      activeSwitch1: "active",
      activeSwitch4: "",
      showCourse: false,
      page: 0,
      // comments: []
    };
  }
  componentDidMount() {
    const share_url = this.props.share_url;
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    const share_image_url = this.props.share_image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        link: share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (window.location.href.includes("#comment")) {
      this.onClickEvalute();
      this.setState({
        isCourseContainer: "none",
        activeSwitch1: "",
        activeSwitch2: "",
        activeSwitch3: "active",
      });
      this.problemFoldContainer.setState({
        isCommonProblemBox: "none",
      });
      if (this.isCommentsContainer) {
        this.isCommentsContainer.setState({
          isCourseEvaluateContainer: "block",
        });
      }
    }

    // 判断在小程序中，根据参数 comment 定位到用户评论语块
    if (UserAgent.isMiniProgram()) {
      if (window.location.href.includes("#comment")) {
        this.onClickEvalute();
        this.setState({
          isCourseContainer: "none",
          activeSwitch1: "",
          activeSwitch2: "",
          activeSwitch3: "active",
        });
        this.problemFoldContainer.setState({
          isCommonProblemBox: "none",
        });
        if (this.isCommentsContainer) {
          this.isCommentsContainer.setState({
            isCourseEvaluateContainer: "block",
          });
        }
      }
    }

    $(function () {
      $(window).scroll(function () {
        var offset_top = document.getElementById("course_container").offsetTop;
        var scroll_top = $(document).scrollTop();
        if (scroll_top > offset_top) {
          document
            .getElementById("switch_tab")
            .classList.add("switch_title_fixed");
        } else {
          document
            .getElementById("switch_tab")
            .classList.remove("switch_title_fixed");
        }
      });
    });
  }

  //点击课程介绍
  onClickCourse = (e) => {
    this.setState({
      isCourseContainer: "block",
      activeSwitch1: "active",
      activeSwitch2: "",
      activeSwitch3: "",
      activeSwitch4: "",
      showCourse: false,
    });
    this.problemFoldContainer.setState({
      isCommonProblemBox: "none",
    });
    if (this.isCommentsContainer) {
      this.isCommentsContainer.setState({
        isCourseEvaluateContainer: "none",
      });
    }
  };

  //点击常见问题
  onClickProblem = () => {
    this.setState({
      showCourse: false,
      isCourseContainer: "none",
      activeSwitch1: "",
      activeSwitch2: "active",
      activeSwitch3: "",
      activeSwitch4: "",
    });
    this.problemFoldContainer.setState({
      isCommonProblemBox: "block",
    });
    if (this.isCommentsContainer) {
      this.isCommentsContainer.setState({
        isCourseEvaluateContainer: "none",
      });
    }
  };

  //点击用户评价
  onClickEvalute = () => {
    this.toast = ToastManager.showLoading("拼命加载中...");
    var request_comment_url = this.props.request_comment_url;
    // console.log(request_comment_url)
    // var comments = this.state.comments
    let params = {
      input: {
        page: this.state.page,
        count: 10,
      },
    };
    request({
      url: request_comment_url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        this.setState({
          showCourse: false,
          comments: [].concat(data.comments),
          page: this.state.page + 1,
        });
        console.log(this.state.comments);

        if (this.isCommentsContainer) {
          this.isCommentsContainer.setState({
            isCourseEvaluateContainer: "block",
          });
        }
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
        alert(data);
      });

    this.setState({
      isCourseContainer: "none",
      activeSwitch1: "",
      activeSwitch2: "",
      activeSwitch4: "",
      activeSwitch3: "active",
    });
    this.problemFoldContainer.setState({
      isCommonProblemBox: "none",
    });
  };
  onClickCourse() {
    this.setState({
      showCourse: true,
      isCourseContainer: "none",
      activeSwitch1: "",
      activeSwitch2: "",
      activeSwitch3: "",
      activeSwitch4: "active",
    });
  }

  // 选择相应的课程
  signClick = () => {
    this.courseDilog.setState({
      isChooseDisplay: "block",
    });
  };

  rendererMarkdown = () => {
    if (this.props.description) {
      const imageRenderer = ({ src, alt }) => {
        return <img className="lazyload" data-src={src} alt={alt || ""} />;
      };
      const renderers = { image: imageRenderer };
      const md = (
        <ReactMarkdown
          className="md"
          source={this.props.description}
          renderers={renderers}
        />
      );
      console.log(md);
      return md;
    } else {
      return "";
    }
  };

  render() {
    // const qrcode = this.props.qrcode_url
    const renderers = { imageReference: this.imageRenderer };

    return (
      <div className="comp-study-package-group">
        <div className="activity_list">
          <div className="periods_time_top">
            <img src={this.props.main_photo_url} />
          </div>
          <div id="course_container"></div>
          <ul className="switchTitle" id="switch_tab">
            <li
              className={this.state.activeSwitch1}
              onClick={this.onClickCourse}
            >
              课程介绍
            </li>
            {this.props.faq && (
              <li
                className={this.state.activeSwitch2}
                onClick={this.onClickProblem}
              >
                试听/常见问题
              </li>
            )}
            {this.props.total_comments_count > 0 ? (
              <li
                className={this.state.activeSwitch3}
                onClick={this.onClickEvalute}
              >
                评价 (
                {this.props.total_comments_count >= "10000"
                  ? "9999+"
                  : this.props.total_comments_count}
                )
              </li>
            ) : (
              ""
            )}
            {this.props.promotion_method === "trial" ? (
              <li
                className={this.state.activeSwitch4}
                onClick={this.onClickCourse}
              >
                课程大纲
              </li>
            ) : (
              ""
            )}
          </ul>

          {/* 课程介绍 */}
          <div
            className="course_container"
            style={{ display: this.state.isCourseContainer }}
          >
            <div className="markDownWrapper markdown_content">
              {this.rendererMarkdown()}
            </div>
          </div>

          {/* 试听/常见问题 */}
          <ProblemFold
            faq={this.props.faq}
            ref={(problemFold) => (this.problemFoldContainer = problemFold)}
          />

          {this.state.comments && (
            <CommentLists
              request_comment_url={this.props.request_comment_url}
              total_comments_count={this.props.total_comments_count}
              comments={this.state.comments}
              ref={(isCommentsContainer) =>
                (this.isCommentsContainer = isCommentsContainer)
              }
            />
          )}

          <div className="activity_pay">
            <a
              className="free_experience"
              href={this.props.study_package_trial_url}
            >
              免费试听
            </a>
            <div className="activitiy_sign_in" onClick={this.signClick}>
              立即报名
            </div>
          </div>
        </div>

        <CourseLists
          ref={(course) => (this.courseDilog = course)}
          activities={this.props.activities}
        />
      </div>
    );
  }
}

StudyPackageGroup.propTypes = {
  study_package_trial_url: PropTypes.string,
  activities: PropTypes.array,
  share_url: PropTypes.string.isRequired,
};
