import React from "react";
import ReactMarkdown from "react-markdown";
import {
  debounce,
  print,
  tracking,
  onNavigate,
  promptPageBack,
} from "../utils/helpers";
import { BasicQuizGeneration } from "../day_studies/BasicQuizGeneration";
import UserAgent from "../utils/UserAgent";
import { AccumulationSelf } from "./PracticeArticle";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import practice_end_img from "practice_courses/practice_end_img.png";
import practice_pro_back from "practice_courses/practice_pro_back.png";
import group_item_icon from "practice_courses/group_item_icon.png";
import study_status_to_up from "practice_courses/study_status_to_up.png";
import study_status_to_down from "practice_courses/study_status_to_down.png";
import practice_index_back from "practice_courses/practice_index_back.png";
import icon_quiz_fail from "../../../assets/images/icon_quiz_fail.png";
import icon_quiz_pass from "../../../assets/images/icon_quiz_pass.png";
import icon_play_normal from "../../../assets/images/icon_play_normal.png";
import icon_play_selected from "../../../assets/images/icon_play_selected.gif";
import answer_line from "practice_courses/answer_line.png";
import "../utils/ReactMarkDown.scss";
import "./KnowledgePoints.scss";
import "./PracticeCourses.scss";
import "./PracticeArticle.scss";
import "./PracticeQuestion.scss";
// import { from } from "core-js/fn/array";

const studyStatus = [
  { name: "未测试", backColor: "#FF6868" },
  { name: "未通过", backColor: "#FF6868" },
  { name: "掌握", backColor: "#88B1F7" },
  { name: "熟练掌握", backColor: "#66D167" },
];
// type 0 文本 1 音频 2 图片 3 录音
const promisifiedPlayEffectAudio = (ElementId) => {
  console.log("------------", ElementId);
  return new Promise((resolve, reject) => {
    let audio = document.getElementById(ElementId);
    if (!audio) {
      return;
    }
    console.log("------------", audio);
    audio.play();
    setTimeout(() => {
      resolve();
    }, 500);
  });
};

class KnowledgePoints extends React.Component {
  constructor(props) {
    super(props);
    console.log("KnowlegePoint_props", props);

    this.state = {
      tabsData: [],
    };
  }

  renderAnswer = (
    ans_correct,
    type = 0,
    input_ans,
    correct_ans,
    hint,
    subjective
  ) => {
    return input_ans ? (
      <>
        <div className="back_tari_course">
          <div
            className="back_tari_course_connect_top"
            style={{
              borderRadius:
                hint && !ans_correct ? "10px 10px 0px 0px " : "10px",
            }}
          >
            <div className="back_tari_course_connect_top_1">
              {input_ans && (
                <div>
                  {"你的答案："}
                  {type == 0 && (
                    <div style={{ display: "inline" }}>{input_ans}</div>
                  )}

                  {!subjective && (
                    <span
                      style={{
                        color: ans_correct ? "#66D167" : "#FF6868",
                        fontSize: 14,
                      }}
                    >
                      {ans_correct ? "（正确）" : "（错误）"}
                    </span>
                  )}
                  {type == 3 && input_ans.id ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 2 && input_ans.id ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "error_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "error_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "error_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + input_ans.id}
                        src={input_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}

                  {type == 1 && (
                    <div className="error_ans_img">
                      <img className="ans_img" src={input_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
              {(subjective || !ans_correct) && correct_ans && (
                <div>
                  {subjective ? "参考答案：" : "正确答案："}
                  {type == 0 || type == 3 ? (
                    <div style={{ display: "inline", color: "#66d167" }}>
                      {correct_ans}
                    </div>
                  ) : (
                    ""
                  )}

                  {type == 2 && input_ans.id ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        this.setState({
                          currentPlayId: "correct_" + input_ans.id,
                        });
                        promisifiedPlayEffectAudio(
                          "correct_" + input_ans.id
                        ).then(() => {
                          this.setState({
                            currentPlayId: "",
                          });
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          this.state.currentPlayId == "correct_" + input_ans.id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"correct_" + input_ans.id}
                        src={correct_ans.url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 1 && (
                    <div className="correct_ans_img">
                      <img className="ans_img" src={correct_ans.url}></img>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {hint && !ans_correct && (
            <div className="back_tari_course_connect_bottom">
              <div className="style_line">
                <div
                  style={{
                    height: 15,
                    borderBottom: "1px dashed #FFCD01",
                  }}
                ></div>
              </div>

              {hint && !ans_correct && (
                <div className="style_ans_analysis">
                  <div className="style_ans_title">答案解析</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <ReactMarkdown
                      className="markdown_content"
                      source={hint}
                      escapeHtml={false}
                    ></ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    ) : (
      ""
    );
  };

  renderWorng = (props) => {
    const { lesson_grasp_statistics, history_lesson_grasp_statistics } = props;
    return (
      <div className="render-worng">
        {/* 答题对错数据记录 */}
        <div className="render-worng-count">
          <div className="render-worng-count-correct">
            <div className="count-correct-icon">
              <img src={icon_quiz_pass}></img>
            </div>
            <div className="count-correct-num">
              {"答对：" + props.lesson_grasp_statistics.correct_count + "题"}
            </div>
          </div>
          <div className="render-worng-count-error">
            <div className="count-error-icon">
              <img src={icon_quiz_fail}></img>
            </div>
            <div className="count-error-num">
              {"答错：" + props.lesson_grasp_statistics.error_count + "题"}
            </div>
          </div>
        </div>
        {/* 答题对错数据记录-------题目回答情况 */}
        {lesson_grasp_statistics.quizzes_grasp_statistics.map((it, ix) => {
          return (
            <div className="question-items" key={"question-items" + ix}>
              {(it.poetry_article_title || it.description) && (
                <div className="question-item question-item-article first-item">
                  <div className="practice_question_connect1">
                    <div className="practice_question_connect2">
                      <div className="practice_question_connects">
                        <div
                          style={{ marginBottom: 10 }}
                          className="article_title"
                        >
                          {it.poetry_article_title}
                        </div>
                        {it.author && (
                          <div
                            className="article_author"
                            style={{ marginBottom: 15 }}
                          >
                            {it.author}
                          </div>
                        )}
                        <ReactMarkdown
                          className="markdown_content"
                          source={it.description}
                          escapeHtml={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {it.quizzes.map((itm, idx) => {
                return (
                  <div key={"answer_line" + idx}>
                    {(it.description ||
                      it.poetry_article_title ||
                      idx !== 0) && (
                      <div className="answer_line">
                        <img
                          src={answer_line}
                          className="answer_line-left"
                        ></img>
                        <img
                          src={answer_line}
                          className="answer_line-right"
                        ></img>
                      </div>
                    )}
                    <div
                      className={
                        it.description || it.poetry_article_title || idx !== 0
                          ? "question-item question-item-im"
                          : "question-item question-item-im last-item"
                      }
                    >
                      <div className="practice_question_question_title">
                        {idx + 1 + "、" + itm.question}
                      </div>

                      <div
                        className="practice_question_question_questionaire"
                        style={{ marginTop: 20 }}
                      >
                        <div className="questionaire_type2">
                          {itm.question_body && (
                            <ReactMarkdown
                              className="markdown_content"
                              source={itm.question_body}
                              escapeHtml={false}
                            />
                          )}

                          {itm.question_photo_url && (
                            <div className="img_photo_url">
                              <img
                                className="img_photo"
                                src={itm.question_photo_url}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {itm.quiz_type == 3
                        ? this.renderAnswer(
                            itm.input_correct,
                            itm.quiz_type,
                            {
                              url: itm.answer.input_answer_url,
                              id: itm.answer.input_answer_id,
                            },
                            itm.correct_answer,
                            itm.hint,
                            itm.subjective
                          )
                        : itm.quiz_type == 2
                        ? this.renderAnswer(
                            itm.input_correct,
                            itm.quiz_type,
                            {
                              url: itm.answer.input_answer_url,
                              id: itm.answer.input_answer_id,
                            },
                            {
                              url: itm.answer.correct_answer_url,
                              id: itm.answer.correct_answer_id,
                            },
                            itm.hint,
                            itm.subjective
                          )
                        : itm.quiz_type == 1
                        ? this.renderAnswer(
                            itm.input_correct,
                            itm.quiz_type,
                            {
                              url: itm.answer.input_answer_url,
                              id: itm.answer.input_answer_id,
                            },
                            {
                              url: itm.answer.correct_answer_url,
                              id: itm.answer.correct_answer_id,
                            },
                            itm.hint,
                            itm.subjective
                          )
                        : this.renderAnswer(
                            itm.input_correct,
                            0,
                            itm.input_answer,
                            itm.correct_answer,
                            itm.hint,
                            itm.subjective
                          )}
                    </div>
                  </div>
                );
              })}

              {/* <div className="answer_line">
                <img src={answer_line} className="answer_line-left"></img>
                <img src={answer_line} className="answer_line-right"></img>
              </div>
              <div className="question-item question-item-im"></div> */}
            </div>
          );
        })}
        {/* 题目回答情况------历史回答记录 */}
        {history_lesson_grasp_statistics &&
          history_lesson_grasp_statistics.quizzes_grasp_statistics &&
          history_lesson_grasp_statistics.quizzes_grasp_statistics.length !=
            0 && (
            <div className="history-header-back">
              <div className="history-header">
                <div style={{ marginLeft: -1 }}>以前做过的练习题</div>
              </div>
              {history_lesson_grasp_statistics.quizzes_grasp_statistics.map(
                (it, ix) => {
                  return (
                    <div className="question-items" key={"question-items" + ix}>
                      {it.poetry_article_title && it.description && (
                        <div className="question-item question-item-article first-item">
                          <div className="practice_question_connect1">
                            <div className="practice_question_connect2">
                              <div className="practice_question_connects">
                                <div
                                  style={{ marginBottom: 10 }}
                                  className="article_title"
                                >
                                  {it.poetry_article_title}
                                </div>
                                {it.author && (
                                  <div
                                    className="article_author"
                                    style={{ marginBottom: 15 }}
                                  >
                                    {it.author}
                                  </div>
                                )}
                                <ReactMarkdown
                                  className="markdown_content"
                                  source={it.description}
                                  escapeHtml={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {it.quizzes.map((itm, idx) => {
                        return (
                          <div key={"answer_line" + idx}>
                            {(it.description ||
                              it.poetry_article_title ||
                              idx !== 0) && (
                              <div className="answer_line">
                                <img
                                  src={answer_line}
                                  className="answer_line-left"
                                ></img>
                                <img
                                  src={answer_line}
                                  className="answer_line-right"
                                ></img>
                              </div>
                            )}
                            <div
                              className={
                                it.description ||
                                it.poetry_article_title ||
                                idx !== 0
                                  ? "question-item question-item-im"
                                  : "question-item question-item-im last-item"
                              }
                            >
                              <div className="practice_question_question_title">
                                {idx + 1 + "、" + itm.question}
                              </div>
                              <div
                                className="practice_question_question_questionaire"
                                style={{ marginTop: 20 }}
                              >
                                <div className="questionaire_type2">
                                  {itm.question_body && (
                                    <ReactMarkdown
                                      className="markdown_content"
                                      source={itm.question_body}
                                      escapeHtml={false}
                                    />
                                  )}

                                  {itm.question_photo_url && (
                                    <div className="img_photo_url">
                                      <img
                                        className="img_photo"
                                        src={itm.question_photo_url}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              {itm.quiz_type == 3
                                ? this.renderAnswer(
                                    itm.input_correct,
                                    itm.quiz_type,
                                    {
                                      url: itm.answer.input_answer_url,
                                      id: itm.answer.input_answer_id,
                                    },
                                    itm.correct_answer,
                                    itm.hint,
                                    itm.subjective
                                  )
                                : itm.quiz_type == 2
                                ? this.renderAnswer(
                                    itm.input_correct,
                                    itm.quiz_type,
                                    {
                                      url: itm.answer.input_answer_url,
                                      id: itm.answer.input_answer_id,
                                    },
                                    {
                                      url: itm.answer.correct_answer_url,
                                      id: itm.answer.correct_answer_id,
                                    },
                                    itm.hint,
                                    itm.subjective
                                  )
                                : itm.quiz_type == 1
                                ? this.renderAnswer(
                                    itm.input_correct,
                                    itm.quiz_type,
                                    {
                                      url: itm.answer.input_answer_url,
                                      id: itm.answer.input_answer_id,
                                    },
                                    {
                                      url: itm.answer.correct_answer_url,
                                      id: itm.answer.correct_answer_id,
                                    },
                                    itm.hint,
                                    itm.subjective
                                  )
                                : this.renderAnswer(
                                    itm.input_correct,
                                    0,
                                    itm.input_answer,
                                    itm.correct_answer,
                                    itm.hint,
                                    itm.subjective
                                  )}
                            </div>
                          </div>
                        );
                      })}

                      {/* <div className="answer_line">
                      <img src={answer_line} className="answer_line-left"></img>
                      <img
                        src={answer_line}
                        className="answer_line-right"
                      ></img>
                    </div>
                    <div className="question-item question-item-im"></div> */}
                    </div>
                  );
                }
              )}
            </div>
          )}

        {/* 历史回答记录 */}
      </div>
    );
  };
  renderKnowlege = function (props) {
    return props.lesson_grasp_statistics.material_statistics &&
      props.lesson_grasp_statistics.material_statistics.length != 0 ? (
      <div className="materia_names">
        {props.lesson_grasp_statistics.material_statistics.map(
          (item, index) => {
            return (
              <AccumulationSelf
                className={`accumulation ${
                  index == 0
                    ? "accumulation-left first-item"
                    : index % 2 !== 0
                    ? "accumulation-right"
                    : "accumulation-left"
                }`}
                key={"m" + index}
                idx={index}
              >
                <div className="accumulation-title">
                  {item.poetry_article_name}
                </div>
                <div className="material_names">
                  {item.materia_names &&
                    item.materia_names.map((itm, i) => {
                      return (
                        <div
                          key={"material_names" + i}
                          className="material_name"
                        >
                          {itm}
                        </div>
                      );
                    })}
                </div>
              </AccumulationSelf>
            );
          }
        )}
      </div>
    ) : (
      ""
    );
  };
  setTabsData() {}
  componentDidMount() {
    const { kb_lesson_grasp_info, pass, lesson_grasp_statistics } = this.props;

    if (lesson_grasp_statistics.quizzes_grasp_statistics) {
      if (lesson_grasp_statistics.material_statistics) {
        this.setState({
          tabsData: [
            {
              text: "答题汇总",
              renderCom: this.renderWorng,
            },
            {
              text: "好词好句积累",
              renderCom: this.renderKnowlege,
            },
          ],
        });
      } else {
        this.setState({
          tabsData: [
            {
              text: "答题汇总",
              renderCom: this.renderWorng,
            },
          ],
        });
      }
    } else {
      if (lesson_grasp_statistics.material_statistics) {
        this.setState({
          tabsData: [
            {
              text: "好词好句积累",
              renderCom: this.renderKnowlege,
            },
          ],
        });
      }
    }
  }

  render() {
    const { kb_lesson_grasp_info, pass } = this.props;
    const { tabsData } = this.state;
    console.log(335, tabsData);
    const tabClick = (e) => {
      const tabIdx = e.currentTarget.getAttribute("data-id");
      console.log("tabIdx: ", tabIdx);
      // if (failQuizzes.length > 0) {
      //   dealTabIdx(tabIdx, 2);
      // } else {
      //   dealTabIdx(tabIdx, 1);
      // }
      // activeTabIdx.current = tabIdx;
      // console.log("activeTabIdx.current: ", activeTabIdx.current);
    };
    return (
      <div className="new-course  new-course-connect clear_scroll_bar">
        <div className="course-details">
          <div className="course-details-back">
            <div className="course-details-top">
              <div className="knowledge-points course-details-top-intr">
                <div className="knowledge-point">
                  <div className="knowledge-point-icon"></div>
                  <div className="knowledge-point-title">
                    {kb_lesson_grasp_info.kb_course}
                  </div>
                </div>
                <div className="knowledge-point-left-top">
                  <div className="knowledge-point-left-top-dot"></div>
                  <div className="knowledge-point-left-top-font">
                    <div>{kb_lesson_grasp_info.kb_lesson_name}</div>
                  </div>
                  <div className="knowledge-point-left-bottom">
                    {kb_lesson_grasp_info.subjective ? (
                      // <div>(主观题，请自判对错)</div>
                      ""
                    ) : (
                      <div
                        className="now-study-status"
                        style={{
                          backgroundColor:
                            studyStatus[
                              kb_lesson_grasp_info.current_grasp_state
                            ].backColor,
                        }}
                      >
                        <div className="now-study-status-tit">
                          {
                            studyStatus[
                              kb_lesson_grasp_info.current_grasp_state
                            ].name
                          }
                        </div>
                        {kb_lesson_grasp_info.prev_grasp_state &&
                        kb_lesson_grasp_info.current_grasp_state !==
                          kb_lesson_grasp_info.prev_grasp_state ? (
                          <div className="now-study-status-icon">
                            <img
                              src={
                                kb_lesson_grasp_info.current_grasp_state <
                                kb_lesson_grasp_info.prev_grasp_state
                                  ? study_status_to_down
                                  : study_status_to_up
                              }
                            ></img>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {kb_lesson_grasp_info.subjective ? (
                  <div className="subjective">(主观题，请自判对错)</div>
                ) : (
                  ""
                )}
              </div>
              <div className="course-details-img">
                <img src={practice_end_img} className="practice_end_img"></img>
              </div>
            </div>
            <div className="course-details-bottom">
              {kb_lesson_grasp_info.kb_lesson_description}
            </div>
          </div>
        </div>

        <div className="tabs-connect">
          {tabsData && (
            <Tabs
              styles={`${tabsData.length == 2 ? "tabs" : "tabs tabsingle"} `}
              defaultAction={tabClick}
              defaultActiveIndex={0}
            >
              {tabsData.map((menu, index) =>
                menu ? (
                  <TabPane
                    key={index}
                    tab={menu.text}
                    activeStyle="active"
                    styles={`tab`}
                  >
                    <div className={`quiz_detail_container`}>
                      {menu.renderCom(this.props)}
                    </div>
                  </TabPane>
                ) : (
                  ""
                )
              )}
            </Tabs>
          )}
        </div>
        <div className="detail-botttom is_unit_practice">
          {!(this.props.is_first_kb_lesson || this.props.is_last_kb_lesson) && (
            <div
              className="detail-botttom-pre"
              onClick={() => {
                if (this.props.is_first_kb_lesson || !this.props.prev_url) {
                  return;
                }
                window.location.href = this.props.prev_url;
              }}
            >
              上一个
            </div>
          )}
          <div
            className="detail-botttom-next"
            onClick={() => {
              if (this.props.is_last_kb_lesson) {
                if (!this.props.is_first_kb_lesson && this.props.prev_url) {
                  window.location.href = this.props.prev_url;
                }
              } else {
                if (this.props.next_url) {
                  window.location.href = this.props.next_url;
                }
              }
            }}
          >
            <div className="next_quize ">
              <div className="next_quize1"></div>
              <div className="next_quize2"></div>
              <div className="next_quize3">
                <span>
                  {this.props.is_last_kb_lesson ? "上一个" : "下一个"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KnowledgePoints;
