import React from "react";
import PropTypes from "prop-types";

import MyFinger from "../../utils/MyFinger.jsx";
import "./GalleryGroup.scss";

const isFunction = (func) => typeof func === "function";
class GalleryItemLRControllers extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  get lrControllers() {
    let controllers = [];
    const {
      handleClickStep,
      leftControllerStyle,
      rightControllerStyle,
      galleryLength,
      activeIndex,
    } = this.props;

    const onControllerClock = (e) => {
      const type = e.currentTarget.getAttribute("data-type");
      handleClickStep(type);
    };
    if (activeIndex > 0) {
      controllers.push(
        React.createElement(
          "div",
          {
            className: `${
              isFunction(leftControllerStyle) ? "" : leftControllerStyle
            }`,
            "data-type": "right",
            onClick: onControllerClock,
            key: "left",
          },
          isFunction(leftControllerStyle) ? leftControllerStyle() : null
        )
      );
    }
    if (activeIndex < galleryLength - 1) {
      controllers.push(
        React.createElement(
          "div",
          {
            className: `${
              isFunction(rightControllerStyle) ? "" : rightControllerStyle
            }`,
            "data-type": "left",
            onClick: onControllerClock,
            key: "right",
          },
          isFunction(rightControllerStyle) ? rightControllerStyle() : null
        )
      );
    }

    return controllers;
  }

  render() {
    const { containerStyles } = this.props;
    return <div className={containerStyles}>{this.lrControllers}</div>;
  }
}

GalleryItemLRControllers.propTypes = {
  handleClickStep: PropTypes.func.isRequired,
  leftControllerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  activeIndex: PropTypes.number.isRequired,
  galleryLength: PropTypes.number.isRequired,
  rightControllerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  containerStyles: PropTypes.string.isRequired,
};

class GalleryItemIndexDots extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  get dots() {
    const {
      dotUnActiveStyle,
      activeIndex,
      dotActiveStyle,
      dotClick,
      dotsLength,
    } = this.props;
    let dots = [];
    for (let i = 0; i < dotsLength; i++) {
      const onDotClick = (e) => {
        const clickedIndex = e.currentTarget.getAttribute("data-index");
        dotClick(parseInt(clickedIndex));
      };
      dots.push(
        React.createElement("span", {
          className: `${activeIndex == i ? dotActiveStyle : dotUnActiveStyle}`,
          "data-index": i,
          onClick: onDotClick,
          key: i,
        }),
        null
      );
    }
    return dots;
  }

  render() {
    const { containerStyles } = this.props;
    return <div className={containerStyles}>{this.dots}</div>;
  }
}

GalleryItemIndexDots.propTypes = {
  containerStyles: PropTypes.string.isRequired,
  dotUnActiveStyle: PropTypes.string.isRequired,
  activeIndex: PropTypes.number.isRequired,
  dotActiveStyle: PropTypes.string.isRequired,
  dotClick: PropTypes.func.isRequired,
  dotsLength: PropTypes.number.isRequired,
};

export const GalleryItem = (props) => {
  return (
    <div
      className={`_gallery_item_container_ ${props.itemStyle} ${
        props.isActive
          ? isFunction(props.activeItemStyle)
            ? ""
            : props.activeItemStyle
          : props.unActiveItemStyle
      }`}
      data-id={props.index}
    >
      <div>
        {props.isActive && isFunction(props.activeItemStyle)
          ? props.activeItemStyle()
          : ""}
        {props.toMapItem(props.item)}
      </div>
    </div>
  );
};

GalleryItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  isActive: PropTypes.bool,
  itemStyle: PropTypes.string,
  activeItemStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  unActiveItemStyle: PropTypes.string,
  toMapItem: PropTypes.func,
};

export class GalleryGroupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: props.defaultActiveIndex || 0,
      scrollStyle: {
        transform: "translateX(0px)",
      },
      // 避免首屏动画卡顿
      containerDisplay: "hidden",
    };
    this.galleryLength = this.props.list.length;
    this.galleryItems = [];
  }

  get handleLocationToLeftAndRight() {
    const { activeIndex } = this.state;
    return {
      left: () => {
        return this.locater(activeIndex + 1);
      },
      right: () => {
        return this.locater(activeIndex - 1);
      },
    };
  }

  locater = (activeIndex) => {
    return new Promise((resolve) => {
      if (activeIndex < 0 || activeIndex >= this.galleryLength) {
        return resolve();
      }

      const { scrollStep = 1, activeIndexUpdater } = this.props;
      const container = document.getElementsByClassName(
        "_gallery_item_container_"
      )[0];
      const galleryGroupContainer = document.getElementById(
        "_gallery_group_container"
      );

      const containerStyle =
        container.currentStyle || window.getComputedStyle(container);
      const marginLeft = containerStyle.marginLeft.replace("px", "");
      const normalItemWidth = container.offsetWidth + marginLeft * 2;

      // containerArr.forEach(container => {
      //   const style =
      //     container.currentStyle || window.getComputedStyle(container);
      //   const marginLeft = style.marginLeft.replace("px", "");
      //   normalItemWidth = container.offsetWidth + marginLeft * 2;

      //   activeItemWidth = normalItemWidth;
      // });

      const scroll =
        normalItemWidth * (activeIndex + scrollStep) -
        galleryGroupContainer.offsetWidth / 2;

      activeIndexUpdater(activeIndex);
      this.setState(
        {
          activeIndex,
          scrollStyle: {
            transform: `translateX(${-scroll}px)`,
          },
        },
        resolve
      );
    });
  };

  componentDidMount() {
    if (this.props.list.length) {
      const { activeIndex } = this.state;
      this.showPosterContainer(activeIndex);
    }
  }

  showPosterContainer = (anchorIndex) => {
    this.locater(anchorIndex).then(() => {
      this.setState({
        containerDisplay: "visible",
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.list.length !== this.props.list.length) {
      this.galleryLength = this.props.list.length;
      const { activeIndex } = this.state;
      this.showPosterContainer(activeIndex);
    }

    if (prevProps.defaultActiveIndex != this.props.defaultActiveIndex) {
      this.showPosterContainer(this.props.defaultActiveIndex);
    }
  }

  onSwipe = (evt) => {
    if (evt.direction === "Right") {
      this.handleLocationToLeftAndRight["right"]();
      return;
    }
    if (evt.direction === "Left") {
      this.handleLocationToLeftAndRight["left"]();
      return;
    }
  };

  // getChildrenElement = children => {
  //   const { activeIndex } = this.state;

  //   React.Children.forEach(children, (child, index) => {
  //     const { activeStyle, styles, unActiveStyle } = child.props;
  //     const isActiveItem = activeIndex == index;
  //     let item = "";

  //     const props = {
  //       className: `_gallery_item_container_ ${styles} ${
  //         isActiveItem
  //           ? isFunction(activeStyle)
  //             ? ""
  //             : activeStyle
  //             ? activeStyle
  //             : ""
  //           : unActiveStyle
  //       }`,
  //       "": index
  //     };

  //     if (this.galleryItems[index]) {
  //       item = React.cloneElement(
  //         this.galleryItems[index],
  //         props,
  //         isFunction(child.props.children)
  //           ? child.props.children(isActiveItem)
  //           : child.props.children
  //       );
  //       this.galleryItems.splice(index, 1, item);
  //     } else {
  //       props.key = `${this.galleryItems.length}`;
  //       item = React.createElement(
  //         "div",
  //         props,
  //         isFunction(child.props.children)
  //           ? child.props.children(isActiveItem)
  //           : child.props.children
  //       );
  //       this.galleryItems.push(item);
  //     }
  //   });
  //   return this.galleryItems;
  // };

  dotClick = (clickedIndex) => {
    this.locater(clickedIndex);
  };

  handleClickStep = (click) => {
    this.handleLocationToLeftAndRight[click]();
  };

  render() {
    console.log("GalleryGroupContainer: ", this.props);
    const {
      styles,
      animation,
      indexDots,
      leftAndRightControllers,

      itemStyle,
      activeItemStyle,
      unActiveItemStyle,
      list,
      children,
    } = this.props;
    // const items = this.getChildrenElement(children);
    const { scrollStyle, activeIndex, containerDisplay } = this.state;
    return (
      <MyFinger onSwipe={this.onSwipe}>
        <div
          style={{
            visibility: containerDisplay,
            width: "100%",
            overflow: "hidden",
            position: "relative",
            touchAction: "pan-x",
          }}
          id="_gallery_group_container"
        >
          {leftAndRightControllers && (
            <GalleryItemLRControllers
              {...leftAndRightControllers}
              handleClickStep={this.handleClickStep}
              activeIndex={activeIndex}
              galleryLength={this.galleryLength}
            />
          )}

          <div className={styles} style={{ ...scrollStyle, ...animation }}>
            {list.map((item, index) => (
              <GalleryItem
                key={index}
                index={index}
                item={item}
                isActive={activeIndex === index}
                itemStyle={itemStyle}
                activeItemStyle={activeItemStyle}
                unActiveItemStyle={unActiveItemStyle}
                toMapItem={children}
              />
            ))}
          </div>

          {indexDots && (
            <GalleryItemIndexDots
              {...indexDots}
              activeIndex={activeIndex}
              dotsLength={this.galleryLength}
              dotClick={this.dotClick}
            />
          )}
        </div>
      </MyFinger>
    );
  }
}

GalleryGroupContainer.propTypes = {
  animation: PropTypes.object,
  styles: PropTypes.string,
  defaultActiveIndex: PropTypes.number,
  // active item's width 的倍数
  scrollStep: PropTypes.number,
  indexDots: PropTypes.object,
  leftAndRightControllers: PropTypes.object,
  activeIndexUpdater: PropTypes.func,
};
