import React from "react";
import "./AdCourseRecommend.scss";

export default () => {
  let pathname = window.location.pathname?.split("/")[1];
  let ref_address;
  if (pathname == "promotion_activities") {
    // 直播活动相关
    ref_address = "promotion";
  } else if (pathname == "school_details") {
    // 学校相关
    ref_address = "school_detail";
  }
  console.log("ad_course_recommend_ref: ", ref_address);

  const more_course_url = `https://xinya.me/?ref=${ref_address}`;
  const unit_lists = [
    {
      photo_url:
        "https://upload.fireflybaby.cn/study_package_activity/a50945a29fb14fbd4565b91c40499b45.png",
      title: "数学培优系列",
      description: "系统又全面，学这个就够了",
      price: "¥798",
      url: `https://xinya.me/study_package_activity_details?id=364&ref=${ref_address}`,
    },
    {
      photo_url:
        "https://upload.fireflybaby.cn/study_package_activity/dc3113d14903505f9b6147c1685387bc.png",
      title: "基础计算课",
      description: "让孩子理解计算背后的法则",
      price: "¥198",
      url: `https://xinya.me/study_package_activity_details?id=347&ref=${ref_address}`,
    },
    {
      photo_url:
        "https://upload.fireflybaby.cn/study_package_activity/09ce07c7701370b66ea403be228e1ba4.png",
      title: "分级学古诗",
      description: "跟学科思维，真正的大语文",
      price: "¥598",
      url: `https://xinya.me/study_package_activity_details?id=614&ref=${ref_address}`,
    },
  ];
  return (
    <div className="com_ad_course_recommend">
      <div className="top_title">
        <div className="name">大家都在看的课程</div>
      </div>
      <ul>
        {unit_lists.map((list, index) => (
          <li key={index}>
            <a href={list.url}>
              <img src={`${list.photo_url}`} alt="" />
              <div className="title">{list.title}</div>
              <div className="description">{list.description}</div>
              <div className="price">
                {list.price}
                <span>起</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <a className="go_more_course" href={more_course_url}>
        查看更多课程
      </a>
    </div>
  );
};
