import React from "react";
import PropTypes from "prop-types";

import { request, csrfHeaders } from "../utils/request";
import { DialogManager } from "../utils/Dialog";
import { formatAudioTime } from "../utils/formatAudioTime";
import { ToastManager } from "../utils/Toast";
import { ToptipsManager } from "../utils/Toptips";

import IconAudioRecWave from "audio_recorder/icon_audio_rec_wave.png";
import "./AudioRecorder.scss";

const STATE_READY = "ready";
const STATE_PRE_RECORDING = "pre_recording";
const STATE_RECORDING = "recording";
const STATE_RECORDING_FINISHED = "recording_finished";
const STATE_ENDED = "ended";
const STATE_SUBMITTED = "submitted";

export default class AudioRecorder extends React.Component {
  constructor(props) {
    super(props);

    this.localIds = [];
    this.serverIds = [];

    this.state = {
      state: STATE_READY,
    };
  }

  onStartRecordFirstClick = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Recorder", "Button", "First");
    }
    this.countDown();
  };

  onStartRecordAgainClick = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Recorder", "Button", "Again");
    }
    this.countDown();
  };

  onEndRecordClick = (e) => {
    if (typeof ga === "function") {
      ga("send", "event", "Recorder", "Button", "Submit");
    }
    this.uploadVoice();
  };

  countDown() {
    this.countDownTime = 3;
    this.setState({
      countDownTime: this.countDownTime,
    });
    const countDownHandle = setInterval(() => {
      this.countDownTime -= 1;
      if (this.countDownTime <= 0) {
        clearInterval(countDownHandle);
        this.startRecord();
        this.setState({
          state: STATE_RECORDING,
        });
      } else {
        this.setState({
          countDownTime: this.countDownTime,
        });
      }
    }, 1000);

    this.setState({
      state: STATE_PRE_RECORDING,
    });
  }

  startRecord() {
    wx.onVoiceRecordEnd({
      complete: (res) => {
        this.onRecordEnded(res);
      },
    });
    this.doStartRecord();
  }

  doStartRecord() {
    this.toast = ToastManager.showLoading("请求录音中...");
    wx.startRecord({
      success: () => {
        this.toast.cancel();

        this.recordStartTime = new Date();
        this.recordTimeHandle = setInterval(() => {
          let time = Date.now() - this.recordStartTime;
          time = parseInt(time / 1000);
          this.setState({
            recordTime: formatAudioTime(time),
          });
        }, 1000);
        this.setState({
          recordTime: "00:00",
        });
      },
      fail: (res) => {
        this.toast.cancel();

        DialogManager.showMsg({
          msg: `录音失败: ${JSON.stringify(res)}`,
        });
      },
    });
  }

  stopRecord() {
    wx.stopRecord({
      success: (res) => {
        this.onRecordEnded(res);
      },
      fail: () => {
        // TODO
        DialogManager.showMsg({
          msg: "录音失败",
        });
      },
    });
  }

  onRecordEnded(res) {
    this.localIds.push(res.localId);

    this.setState({
      state: STATE_RECORDING_FINISHED,
    });

    clearInterval(this.recordTimeHandle);
  }

  uploadVoice() {
    if (this.localIds.length > 0) {
      wx.uploadVoice({
        localId: this.localIds.splice(0, 1)[0],
        isShowProgressTips: 1,
        success: (res) => {
          this.serverIds.push(res.serverId);
          this.uploadVoice();
        },
      });
    } else {
      this.createAudio();
    }
  }

  createAudio() {
    this.toast = ToastManager.showLoading("提交中...");

    request({
      url: this.props.create_audio_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        server_ids: this.serverIds,
      },
    })
      .then((resp) => {
        this.toast.cancel();

        const data = resp.data;
        if (data.err_code) {
          DialogManager.showMsg({
            msg: `${data.err_msg ? data.err_msg : "操作失败"}`,
          });
          return;
        }

        const baby = data.baby || {};
        if (baby.school && baby.grade && baby.gender) {
          this.onSubmitted();
        } else {
          this.setState({
            state: STATE_ENDED,
            baby: baby,
          });
        }
      })
      .catch((error) => {
        this.toast.cancel();
      });
  }

  onSubmitted() {
    this.setState({
      state: STATE_SUBMITTED,
    });
  }

  onBabySchool = (e) => {
    const baby = this.state.baby || {};
    baby.school = e.target.value;
    this.setState({
      baby: baby,
    });
  };

  onBabyGrade = (e) => {
    const baby = this.state.baby || {};
    baby.grade = parseInt(e.target.value);
    this.setState({
      baby: baby,
    });
  };

  onBabyGender(gender) {
    const baby = this.state.baby || {};
    baby.gender = gender;
    this.setState({
      baby: baby,
    });
  }

  onSubmitBabyInfo = (e) => {
    const baby = this.state.baby || {};
    if (!baby.school) {
      ToptipsManager.warn("请输入学校");
      return;
    }

    if (typeof baby.grade !== "number" || baby.grade == -1) {
      ToptipsManager.warn("请选择年级");
      return;
    }

    if (typeof baby.gender !== "number") {
      ToptipsManager.warn("请选择性别");
      return;
    }

    this.toast = ToastManager.showLoading("提交中...");
    request({
      url: this.props.update_baby_url,
      method: "POST",
      headers: csrfHeaders,
      data: baby,
    })
      .then((resp) => {
        this.toast.cancel();

        this.onSubmitted();
      })
      .catch((err) => this.toast.cancel());
  };

  render() {
    const props = this.props;
    const state = this.state;
    const baby = state.baby || {};

    return (
      <div className="comp-audio-recorder">
        {state.state != STATE_ENDED ? (
          <div>
            <img className="wave" src={IconAudioRecWave} />

            <div
              className={`middle ${state.state}`}
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              {state.state == STATE_READY ? (
                <div>
                  <p className="topic_title">{props.return_title}</p>
                  关于“<span className="bold">{props.topic}</span>
                  ”请详细说说你的经验。
                  <div className="record_describe">
                    <p>提交的经验需通过审核才会展示；</p>
                    <p>活动期间，通过审核可获得1个点数奖励。</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {state.state == STATE_PRE_RECORDING ? (
                <div>
                  <div>开始录音倒计时</div>
                  <div className="time">{state.countDownTime}</div>
                </div>
              ) : (
                ""
              )}

              {state.state == STATE_RECORDING ? (
                <div>
                  <div>正在录音</div>
                  <div className="time">{this.state.recordTime}</div>
                  <div>单条语音最长1分钟，可在结束后录第二条</div>
                </div>
              ) : (
                ""
              )}

              {state.state == STATE_RECORDING_FINISHED ? (
                <div>
                  <div>录音结束</div>
                  <div>单条语音最长1分钟，可在结束后录第二条</div>
                </div>
              ) : (
                ""
              )}

              {state.state == STATE_SUBMITTED ? (
                <div className="container-submit-succ">
                  <div className="img"></div>
                  <div className="title">提交成功</div>
                  <div>经验将在审核通过后上线</div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="bottom">
              {state.state != STATE_SUBMITTED ? (
                <div className={`controls ${state.state}`}>
                  {state.state == STATE_READY ? (
                    <div
                      className="control control-start"
                      onClick={this.onStartRecordFirstClick}
                    >
                      <div className="img"></div>
                      <div className="text">点击开始录音</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.state == STATE_PRE_RECORDING ||
                  state.state == STATE_RECORDING ? (
                    <div
                      className="control control-stop"
                      onClick={() => this.stopRecord()}
                    >
                      <div className="img"></div>
                      <div className="text">结束</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.state == STATE_RECORDING_FINISHED ? (
                    <div
                      className="control control-start-again"
                      onClick={this.onStartRecordAgainClick}
                    >
                      <div className="img"></div>
                      <div className="text">再录</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {state.state == STATE_RECORDING_FINISHED ? (
                    <div
                      className="control control-submit"
                      onClick={this.onEndRecordClick}
                    >
                      <div className="img"></div>
                      <div className="text">提交</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {state.state == STATE_SUBMITTED && this.props.return_url ? (
                <div
                  class="go-back"
                  onClick={() => (window.location = this.props.return_url)}
                >
                  回到 [{this.props.return_title}]
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="baby_info">
            <div className="baby_info_text">
              <p>非常感谢您提供的经验</p>
              <span>请填写宝宝信息，帮助其他宝爸宝妈更好的参考</span>
            </div>
            <div className="baby_info_box">
              <div className="baby_school">
                <label>学校：</label>
                <input
                  type="text"
                  className=""
                  value={baby.school || ""}
                  onChange={this.onBabySchool}
                />
              </div>

              <div className="baby_grade">
                <label>年级：</label>
                <select
                  value={typeof baby.grade === "number" ? baby.grade : -1}
                  onChange={this.onBabyGrade}
                >
                  <option value="-1">请选择年级</option>
                  {Object.keys(this.props.grades).map((k) => (
                    <option value={k} key={k}>
                      {this.props.grades[k]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="baby_sex">
                <label>性别：</label>
                <div className="sex_select">
                  <label
                    className={`sex_boy ${baby.gender == 1 ? "selected" : ""}`}
                    onClick={() => this.onBabyGender(1)}
                  >
                    王子
                  </label>
                  <label
                    className={`sex_girl ${baby.gender == 0 ? "selected" : ""}`}
                    onClick={() => this.onBabyGender(0)}
                  >
                    公主
                  </label>
                </div>
              </div>
            </div>
            <div className="btn_submit">
              <a href="javascript:void(0);" onClick={this.onSubmitBabyInfo}>
                提交
              </a>
            </div>
            <div className="btn_now_submit" onClick={() => this.onSubmitted()}>
              不想填，直接提交
            </div>
          </div>
        )}
      </div>
    );
  }
}

AudioRecorder.propTypes = {
  create_audio_url: PropTypes.string.isRequired,
  update_baby_url: PropTypes.string.isRequired,

  topic: PropTypes.string.isRequired,

  return_url: PropTypes.string,
  return_title: PropTypes.string,

  /**
   * i.e. {"0": "小班", "1": "中班"}
   */
  grades: PropTypes.object,
};
