import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatDotDate } from "../utils/formatAudioTime";
import Divider from "../utils/content_divider/Divider";

import "./VirtualAccounts.scss";
import icon_invite_credit from "activities/icon_invite_credit.gif";
import icon_award from "activities/icon_award.png";

const CreditMoneyBox = ({ virtualAccount, recordsCount, recordsUrl }) => {
  const hasVirtualAccount = () => {
    // 有奖励金的情况
    return (
      <div className="account_money">
        <span>￥</span> {virtualAccount.money}
      </div>
    );
  };

  const hasTotalMoney = () => {
    // 无奖励金, 但是有 历史“累计收益”
    return virtualAccount.total_money > 0 ? (
      <div className="account_money">
        <span>￥</span> 0
      </div>
    ) : (
      <div className="no_account_money">
        <span>您还没有获得过奖学金，去邀请朋友赚奖学金吧！</span>
      </div>
    );
  };

  return (
    <div className="credit_money_box">
      <div className="balance_box">
        <p>可用金额</p>
        {recordsCount != 0 && (
          <a className="income_reocrd" href={recordsUrl}>
            收益记录
          </a>
        )}
      </div>
      {virtualAccount.money > 0 ? hasVirtualAccount() : hasTotalMoney()}
      {virtualAccount.total_money > 0 && (
        <div className="date_income_box">
          <div className="date_and_total_income">
            <div className="date_deadline">
              有效期至：
              {virtualAccount.end_time
                ? formatDotDate(virtualAccount.end_time)
                : "—"}
            </div>
            <div className="total_income">
              累计收益：￥{virtualAccount.total_money}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CreditRule = () => {
  const awardTexts = [
    {
      text:
        "奖学金可用于报名任一心芽产品（包括倍受好评的数学思维、计算和“分级学古诗”系列课程）；使用时可结余、可与优惠券叠加使用。",
    },
    {
      text:
        "奖学金不可提现，有效期为一年；有新奖学金入账时，按最新的时间往后延一年。",
    },
    {
      text: "如果产生退款，奖学金将同时退回。",
    },
    {
      text: "获取奖学金诚信至上，如果发现非诚信行为，我们将采取以下惩罚措施：",
      punish_texts: [
        {
          text:
            "若邀请的朋友关注后取关，将扣除相应奖学金，且该朋友再关注后无奖学金奖励。",
        },
        {
          text: "若取关的朋友过多，将取消您参与其他活动的资格。",
        },
        {
          text: "若非诚信行为的情况严重，您将被永久拉入黑名单。",
        },
      ],
    },
    {
      text: "活动最终解释权归上海芽圃教育科技有限公司所有。",
    },
  ];
  return (
    <div className="credit_rule">
      <div className="headline_title">
        <Divider content="奖学金使用规则" />
      </div>
      <ul>
        {awardTexts.map((text, index) => (
          <li key={index}>
            <i>{index + 1}.</i>
            <p>{text.text}</p>
            {text.punish_texts && (
              <ol>
                {text.punish_texts.map((text, index) => (
                  <li key={index}>{text.text}</li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const GetCreditMoney = ({ postersUrl, activities, bonus }) => {
  const experience_invite_arrays = [
    // {
    //   bonus: `+${bonus.register}元/次`,
    //   name: "朋友首次扫描海报",
    //   text_introduce: [
    //     { name: "朋友在扫描你的海报后，微信授权登录。" },
    //     {
    //       name: "每个朋友只统计1次（此1次是针对该朋友的统计，如果他之前已经扫描过别人的海报，则本次无奖励）。"
    //     },
    //     { name: "对你而言，如果有多人扫描，你的奖励上不封顶。" }
    //   ]
    // },
    {
      bonus: `+${bonus.subscribe}元/人`,
      name: "朋友接受邀请，关注公众号",
      text_introduce: [
        { name: "朋友在扫描你的海报后，关注公众号。" },
        {
          name:
            "每个朋友只统计1次。（此1次是针对该朋友的统计，即如果他之前关注过，将跳过此步）。",
        },
        { name: "对你而言，如果有多人扫描关注，你的奖励上不封顶。" },
      ],
    },
    {
      bonus: `+${bonus.experience_buy}元/次`,
      name: "朋友接受邀请，首次报名",
      text_introduce: [
        { name: "朋友在扫描你的海报后，首次报名经验/领学习好物系列活动。" },
        {
          name:
            "每个朋友只统计1次（此1次是针对该朋友的统计，如果他之前已经通过别人的邀请报名过报名经验/领学习好物系列活动，则本次无奖励）。",
        },
        { name: "对你而言，如果有多人报名，你的奖励上不封顶。" },
      ],
    },
  ];

  // let sendCoursePoster = {};
  // sendCoursePoster.bonus = 20;
  // sendCoursePoster.name = "发课程海报到朋友圈";
  // sendCoursePoster.text_introduce=[{
  //   name: "每个月限参与1次"
  // }];
  // activities.unshift(sendCoursePoster)

  // console.log('activities 145: ', activities);

  return (
    <div className="get_credit_money">
      <div className="headline_title">
        <Divider content="如何获得奖学金" />
      </div>
      {/* <div className="credit_activity">
        <div className="send_poster">
          <div className="content">
            <div className="head">发海报到朋友圈</div>
            <p>可得￥20奖学金，每个月限参与1次</p>
          </div>
          <a href={postersUrl}>发海报</a>
        </div>
        <div className="invite_new_user">
          <div className="content">
            <div className="head">邀请新用户报名课程</div>
            {
              activities.map((item) => 
                item.name ? <p key={item.id}>{`报名《${item.name}》，可得￥${item.bonus}`}</p> : null
              )
            }
          </div>
          <a href={postersUrl}>去邀请</a>
        </div>
      </div> */}
      <ul className="activity_flow">
        <li>
          <p>
            <strong>1. </strong>选择心仪的经验讲座/课程
          </p>
        </li>
        <li>
          <p>
            <strong>2. </strong>点击“邀请”，生成专属海报
          </p>
        </li>
        <li>
          <p>
            <strong>3. </strong>发送至朋友圈、微信群或好友
          </p>
        </li>
      </ul>
      {
        <AwardIntroduce
          items={experience_invite_arrays}
          title={`经验/领学习好物的邀请分享`}
          inviteText={`去邀请`}
          isExperience={true}
          // postersUrl={``}
        />
      }
      {
        <AwardIntroduce
          items={activities}
          title={`邀请新用户报名课程（拼团除外）`}
          inviteText={`去邀请`}
          postersUrl={postersUrl}
        />
      }
    </div>
  );
};

const AwardIntroduce = ({
  items,
  title,
  inviteText,
  postersUrl,
  isExperience,
}) => {
  return (
    <div
      className={`experience_invite_share ${
        title.includes("邀请新用户报名课程") ? "invite_sign_course" : ""
      }`}
    >
      <div className="share_button">
        <div className="experience_title">{title}</div>
        {postersUrl && (
          <a className="go_invite" href={postersUrl}>
            {inviteText}
          </a>
        )}
      </div>
      {title.includes("邀请新用户报名课程") && (
        <div className="new_user_intro">新用户：非心芽课程用户</div>
      )}
      {items.map((item, index) => (
        <LoopList
          key={index}
          item={item}
          index={index}
          isExperience={isExperience}
        />
      ))}
    </div>
  );
};

const LoopList = ({ item, index, isExperience }) => {
  // console.log('isExperience: ', isExperience);
  return (
    <div className="share_award_content">
      <div className="poster_text">
        <div className="text">
          <span>{index + 1}.</span>
          {isExperience
            ? `${item.name}`
            : // 邀请新用户报名课程
            item.text_introduce
            ? `${item.name}`
            : `报名《${item.name}》`}
        </div>
        <div className="money_award">
          <img src={icon_award} alt="" />
          <div className="award_count">
            <span>
              {isExperience
                ? `${item.bonus}`
                : item.text_introduce
                ? `+${item.bonus}元/次`
                : `+${item.bonus}元/人`}
            </span>
          </div>
        </div>
      </div>
      {item.text_introduce && (
        <div className="content">
          {item.text_introduce.map((text, index) => (
            <p key={index}>
              <span>·</span>
              {text.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

const CreditIncomeRecord = ({ recordsUrl }) => {
  return (
    <div className="income_record">
      <a href={recordsUrl}>奖学金交易记录</a>
    </div>
  );
};

export default class VirtualAccounts extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    const {
      virtual_account,
      virtual_account_records_count,
      virtual_account_records_url,
      invitation_rewards_url,
      activities,
      bonus,
    } = this.props;
    return (
      <div className="comp_virtual_accounts">
        <CreditMoneyBox
          virtualAccount={virtual_account}
          recordsUrl={virtual_account_records_url}
          recordsCount={virtual_account_records_count}
        />
        <CreditRule />
        <GetCreditMoney
          postersUrl={invitation_rewards_url}
          activities={activities}
          bonus={bonus}
        />
        {/* TODO: 样式表 comp_virtual_accounts高度也做了注释, 如要恢复, 样式也需要同时恢复 */}
        {/* <ButtonPoster url={invitation_rewards_url} photo={icon_invite_credit} /> */}
      </div>
    );
  }
}

VirtualAccounts.propTypes = {
  invitation_rewards_url: PropTypes.string,
  virtual_account: PropTypes.object,
  virtual_account_records_count: PropTypes.number,
  virtual_account_records_url: PropTypes.string,
};
