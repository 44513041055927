import React, { useState, useRef } from "react";
import { ToastManager } from "../utils/Toast";
import UserAgent from "../utils/UserAgent";

const Avatar = ({ url, token }) => {
  const [imageUrl, setImageUrl] = useState(() => url);
  const inputRef = useRef();
  const toast = useRef();
  const inputOnChange = async (e) => {
    const file = e.target.files[0];
    console.log(file);

    setImageUrl(() => {
      return window.URL.createObjectURL(file);
    });
    const fd = new FormData(); //构造FormData对象
    fd.append("file", file);
    fd.append("name", file.name || "");
    fd.append("token", token);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://upload.qiniup.com", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          toast.current && toast.current.cancel();
          const obj = JSON.parse(xhr.responseText);
          console.log(obj);
          setImageUrl(obj.url + "?imageView2/1/w/160/h/160");
        } else {
          toast.current && toast.current.cancel();
          // alert(JSON.parse(xhr.responseText).error);
        }
      }
    };
    xhr.onerror = (e) => {
      toast.current && toast.current.cancel();
      console.error(e);
      if (UserAgent.isNativeClient()) alert("上传成功！");
    };

    toast.current = ToastManager.showLoading("图片上传中...");
    xhr.send(fd);
  };

  // useEffect(() => {
  //   const uploader = usePlupload({
  //     eleId: "user-photo",
  //     token,
  //     filesAdded: function (up, files) {
  //       toast.current = ToastManager.showLoading("图片上传中...");
  //       up.start();
  //     },
  //     fileUploaded: function (up, file, result) {
  //       toast.current?.cancel?.();
  //       const data = $.parseJSON(result.response);
  //       console.log("data", data);
  //       setImageUrl(data.url + "?imageView2/1/w/160/h/160");
  //     },
  //     fileUploadError: function (up, err) {
  //       toast.current?.cancel?.();
  //       alert("上传图片出错，请检查网络状况");
  //     },
  //   });
  //   uploader.init();
  // }, []);
  return (
    <div id="user-photo" style={{ cursor: "pointer" }}>
      <div id="user-photo-placeholder">
        <input
          type="file"
          id="single_file"
          style={{ display: "none" }}
          onChange={inputOnChange}
          ref={inputRef}
        />
        <label htmlFor="single_file">
          <img src={imageUrl} alt="" style={{ width: 160, height: 160 }} />
        </label>
      </div>
    </div>
  );
};

export default Avatar;
