import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { formatAudioTime } from "../../utils/formatAudioTime.js";
import ListContainer from "../../utils/ListContainer/ListContainer.jsx";
import playerIcon from "new_experience/icon_player_green.png";
import { useSelector } from "../../utils/react-global-state/index.js";
import { GALog } from "../log.js";

const MemoNavColumn = React.memo(function MemoNavColumn({
  sectionIndex,
  memoNavIndex,
  title,
  subTitle,
  endTime,
  startTime,
  audioPlay,
  triggerMemosNavColumnPlay,
}) {
  const experienceSections = useSelector((state) => state.experienceSections);
  const checkIsTrialSection = useSelector((state) => state.checkIsTrialSection);
  const isExperienceBought = useSelector((state) => state.isExperienceBought);
  const triggerSharePoster = useSelector((state) => state.triggerSharePoster);
  const memoColumnClick = useCallback(() => {
    if (isExperienceBought) {
      GALog.boughtSectionNavClicked(
        experienceSections[sectionIndex].id,
        experienceSections[sectionIndex].memos[memoNavIndex].id
      );
    } else {
      GALog.noneBoughtSectionNavClicked(
        experienceSections[sectionIndex].id,
        experienceSections[sectionIndex].memos[memoNavIndex].id
      );
    }

    // 未购买 并且点击试听模块
    if (checkIsTrialSection(sectionIndex)) {
      audioPlay(startTime);
      return;
    }
    // 未购买
    if (!isExperienceBought) {
      try {
        triggerSharePoster();
      } catch (e) {
        console.log(e);
      }
      return;
    }
    // 正常触发锚点播放
    triggerMemosNavColumnPlay(sectionIndex, memoNavIndex, startTime);
  }, [
    experienceSections,
    sectionIndex,
    memoNavIndex,
    checkIsTrialSection,
    isExperienceBought,
    triggerMemosNavColumnPlay,
    startTime,
    audioPlay,
    triggerSharePoster,
  ]);
  return (
    <div className="memo_column" onClick={memoColumnClick}>
      <div className="memo_item">
        <span className="index">{`${memoNavIndex + 1}.`}</span>
        <div className="wrapper">
          <div className="memo_name">
            <div className="title_block">
              <div>{title}</div>
              <div>{subTitle}</div>
            </div>
          </div>

          <div className="time_stamp">
            <img src={playerIcon} />
            <span>{formatAudioTime(endTime - startTime)}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

MemoNavColumn.propTypes = {
  memoNavIndex: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  endTime: PropTypes.number,
  startTime: PropTypes.number,
  audioPlay: PropTypes.func,
  sectionIndex: PropTypes.number,
  triggerMemosNavColumnPlay: PropTypes.func,
};

const SectionMemosNavBox = React.memo(function SectionMemosNavBox({
  memos,
  audioPlay,
  sectionIndex,
  triggerMemosNavColumnPlay,
}) {
  return (
    <div className="memos_nav_wrapper">
      <div className="memos_name_play">
        <span className="tip_title">本节要点</span>
        <div className="top_pad" />
        <ListContainer
          list={memos}
          listItem={({ itemIndex, ...memo }) => (
            <MemoNavColumn
              sectionIndex={sectionIndex}
              title={memo.name}
              subTitle={memo.brief}
              endTime={memo.end_time}
              startTime={memo.start_time}
              memoNavIndex={itemIndex}
              key={itemIndex}
              audioPlay={audioPlay}
              triggerMemosNavColumnPlay={triggerMemosNavColumnPlay}
            />
          )}
        />
      </div>
    </div>
  );
});

SectionMemosNavBox.propTypes = {
  memos: PropTypes.array,
  audioPlay: PropTypes.func,
  sectionIndex: PropTypes.number,
  triggerMemosNavColumnPlay: PropTypes.func,
};

export default SectionMemosNavBox;
