import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

const StyledInput = styled.input``;

// eslint-disable-next-line react/prop-types
const Input = ({
  className,
  placeholder,
  onChange,
  type,
  isDatePicker,
  format,
  defaultValue,
}) => {
  const [input, setInput] = useState(defaultValue ?? "");
  const inputRef = useRef();
  useEffect(() => {
    if (isDatePicker) {
      $(inputRef.current)
        .datepicker({
          format: format || "yyyy-mm-dd",
        })
        .on("changeDate", function (e) {
          setInput(e.target.value);
          $(inputRef.current).datepicker("hide");
        });
    }
  }, [format, isDatePicker, onChange]);

  useEffect(() => {
    onChange(input);
  }, [input, onChange]);

  const onInputChange = useCallback(
    (e) => {
      setInput(e.target.value);
      onChange(e);
    },
    [onChange]
  );
  return (
    <div className="datepicker">
      <StyledInput
        ref={inputRef}
        className={`form-control ${className}`}
        placeholder={placeholder || ""}
        value={input}
        onChange={onInputChange}
        type={type || "text"}
      />
    </div>
  );
};

export default Input;
