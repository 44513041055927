// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/arrow-down.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/arrow-up.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".study_package_activities-note .container{background:#fff}.comp-study-package-activity-note{padding-bottom:60px}.comp-study-package-activity-note #common_problem h4{padding-right:36px;background-position:97% 50%;background-size:14px 7px;background-repeat:no-repeat;font-weight:700}.comp-study-package-activity-note #common_problem .handleToDown{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.comp-study-package-activity-note #common_problem .handleToUp{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.comp-study-package-activity-note #common_problem hr{margin-top:0}.comp-study-package-activity-note .invite_friend{width:100%;position:fixed;bottom:0;text-align:center;height:60px;line-height:60px;background:#e86d74;color:#fff;font-size:15px;font-weight:700}@media screen and (orientation:landscape)and (min-width:1024px){.comp-study-package-activity-note,.invite_friend{width:100%;max-width:500px;margin:0 auto}}", ""]);
// Exports
module.exports = exports;
