import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import AudioPlayer from "../utils/audio_player/AudioPlayer";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import QuizRecorder from "./MediaLessonRecorder";
import "../study_packages/poetries/v2/components/RemindInstallAppModal.scss";
import poetryAppInstall from "new_poetry/tip_download.png";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
import UserAgent from "../utils/UserAgent";
import "./PoetryArticle.scss";

class InstallAppModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <InnerModal>
        <ModalMask>
          <div className="comp_install_app_modal_container">
            <div className="modal_close" onClick={this.props.closeModal}></div>
            <div className="modal_content">
              <img data-src={poetryAppInstall} className="lazyload" />
              <div className="continue_study">
                <a
                  style={{ display: "block" }}
                  href="https://a.app.qq.com/dom/micro/open.jsp?pkgname=cn.fireflykids.app"
                >
                  安装
                </a>
              </div>
            </div>
          </div>
        </ModalMask>
      </InnerModal>
    );
  }
}

export default class PoetryArticle extends Component {
  constructor(props) {
    super(props);
    console.log("PoetryArticle_props: ", props);
    this.state = {
      babyId: props.baby_id,
      authToken: props.auth_token,
      quizId: props.quiz_id,
      isRecording: false,
      isPlaying: false,
      isRemoveRecord: false,
      audioName: "",
      audioUrl: "",
      installAppModal: false,
    };
  }

  onRecordStartAction = () => {
    console.log("Action:", "start...");
    const alertCountDown = (times) => {
      let countDownNumber = times - 1;
      this.setState({
        countDownNumber: times,
      });

      const alert = setInterval(() => {
        if (countDownNumber > 0) {
          console.log(countDownNumber);
          this.setState({
            countDownNumber: countDownNumber--,
          });
        } else {
          console.log("finished");
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
          this.countDownStop();
          this.setState({
            showRecordCountDown: false,
            disableMaskTap: false,
          });
          this.setIntervalID = alert;
        }
      }, 1000);
      this.setIntervalID = alert;
    };

    const alert = setTimeout(() => {
      console.log("showRecordCountDown");
      this.setState(
        {
          showRecordCountDown: true,
        },
        () => {
          console.log(this.state.showRecordCountDown);
        }
      );
      alertCountDown(10);
    }, 50000);
    this.setTimeoutID = alert;
  };

  onRecordStopAction = () => {
    console.log("Action:", "stop...");
    this.setState({
      showCaption: false,
      showRecordCountDown: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    return;
  };

  onRecordCompleteAction = () => {
    console.log("Action:", "complete...");
    this.setState({
      isRecording: false,
    });
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    return;
  };

  onRecordReStartAction = () => {
    console.log("Action:", "restart...");
    this.onRecordStartAction();
  };

  clearIntervalAndTimeOut(interval, timeout) {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  }

  countDownStop = () => {
    this.quizRecorderEL.handleAudioRecorderStop();
  };

  playerBar = (tab_id) => {
    console.log(tab_id);
    const {
      name,
      audio_url,
      poetry_audio_url,
      recoder_audo_url,
      is_spell,
    } = this.props;
    let isRecording,
      isPlaying,
      audioName,
      audioUrl = null;
    switch (tab_id) {
      case 2:
        if (!UserAgent.isNativeClient()) {
          this.setState({
            installAppModal: true,
          });
        } else {
          isRecording = true;
          isPlaying = false;
          audioName = "";
          audioUrl = "";
        }
        break;
      case 0:
      case 1:
      case 3:
        isRecording = false;
        isPlaying = true;
        audioName =
          tab_id == 3
            ? "我的录音"
            : tab_id == 0
            ? name
            : is_spell
            ? "童声示范"
            : "讲解";
        audioUrl =
          tab_id == 3
            ? recoder_audo_url
            : tab_id == 0
            ? poetry_audio_url
            : audio_url;
        break;
      default:
        break;
    }
    this.setState(
      {
        isRecording,
        isPlaying,
        audioName,
        audioUrl,
      },
      () => {
        if (isPlaying) {
          this.audioPlayerEL.btnPlayClick();
        }
      }
    );
  };

  closeModal = () => {
    this.setState({
      installAppModal: false,
    });
  };

  render() {
    const {
      name,
      author,
      article_type,
      audio_url,
      poetry_audio_url,
      recoder_audo_url,
      photo_url,
      description,
      raven_msg,
    } = this.props;
    let {
      babyId,
      authToken,
      quizId,
      isPlaying,
      isRecording,
      installAppModal,
      isRemoveRecord,
      audioName,
      audioUrl,
    } = this.state;
    const player_bar_menus =
      article_type == 1
        ? ["领读示范", "童声示范", "跟读"]
        : ["听诗", "讲解", "读诗"];
    let play_audio_classs = ["audio_player_bar"];
    if (!poetry_audio_url) {
      play_audio_classs.push("no_poetry_audio");
    }
    if (!audio_url) {
      play_audio_classs.push("no_audio");
    }
    return (
      <div className="comp_poetry_extensive_details">
        {/* <div className="headline">
          <h2>{name}</h2>
          <p>{author}</p>
        </div> */}
        {/* <div className='poetry_detail_photo'>
          <img src={photo_url} />
        </div> */}
        {!UserAgent.isNativeClient() && installAppModal && (
          <InstallAppModal closeModal={() => this.closeModal()} />
        )}
        {<LazyloadContent content={description} />}
        {this.state.showLoading && (
          <div className="show_loading_mask">
            <div className="show_loading"> 录音上传中...</div>
          </div>
        )}
        {this.state.showRecordCountDown && (
          <div className="record_count_down_mask">
            <div className="record_count_down">
              <div className="count_down_number">
                {this.state.countDownNumber}
              </div>
              <div className="count_down_text">录音即将超时</div>
            </div>
          </div>
        )}
        {recoder_audo_url && (
          <div className="my_audio_play" onClick={() => this.playerBar(3)}>
            我的音频
          </div>
        )}
        <div className="audio_position_bottom">
          {isPlaying && (
            <AudioPlayer
              key={audioUrl}
              ref={(audioPlayerEL) => (this.audioPlayerEL = audioPlayerEL)}
              name={audioName}
              entireRecordUrl={audioUrl}
            />
          )}
          {!isRecording && (
            <div className={play_audio_classs.join(" ")}>
              {player_bar_menus.map((menu, i) => {
                return (
                  <div
                    key={i}
                    className={i == 2 ? "record_poetry" : "play_audio"}
                    onClick={() => this.playerBar(i)}
                  >
                    <span>{menu}</span>
                  </div>
                );
              })}
            </div>
          )}
          {isRecording && (
            <div className="audio_recorder_bar">
              <QuizRecorder
                ref={(quizRecorderEL) => (this.quizRecorderEL = quizRecorderEL)}
                isLastQuiz={true}
                babyId={babyId}
                authToken={authToken}
                quizId={quizId}
                onRecordStartAction={this.onRecordStartAction}
                onRecordStopAction={this.onRecordStopAction}
                onRecordCompleteAction={this.onRecordCompleteAction}
                onRecordReStartAction={this.onRecordReStartAction}
                isRemoveRecord={isRemoveRecord}
                ravenMsg={raven_msg}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
