// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/schooser/bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body.controller-schooser.action-collect_index{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:50px}.component_collect_index{position:absolute;top:10px;bottom:10px;left:9px;right:9px;background:#fff}.component_collect_index .head_icon{margin-left:15px;margin-top:15px}.component_collect_index .head_icon img{width:85px}.component_collect_index .head_title_desc{margin-top:100px}.component_collect_index .head_title_desc img{width:275px;display:block;margin:0 auto}.component_collect_index .head_title_desc img:first-child{padding-bottom:30px}.component_collect_index a.collect_exam_paper{height:45px;border-radius:50px;line-height:45px;color:#fff;background:#6ad33b;width:80%}.component_collect_index .activity_end,.component_collect_index a.collect_exam_paper{position:absolute;bottom:10%;text-align:center;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.component_collect_index .activity_end{font-size:18px;width:200px}.component_collect_index .activity_end .jump_exam_link{display:block;color:#1e88e5;text-decoration:underline}@media screen and (max-width:320px){.schooser-collect_show .head_title_desc{margin-top:80px}}", ""]);
// Exports
module.exports = exports;
