import React, { useMemo } from "react";
import PropTypes from "prop-types";
import SectionMemosNavBox from "./SectionMemosNavBox.jsx";
import ExperienceMerit from "./ExperienceMerit.jsx";
import SectionDescription from "./SectionDescription.jsx";
import SectionMemosList from "./SectionMemosList.jsx";
import { useSelector } from "../../utils/react-global-state";

const SectionContent = React.memo(function SectionContent({
  audioPlay,
  sectionIndex,
  triggerMemosNavColumnPlay,
}) {
  const isExperienceBought = useSelector((state) => state.isExperienceBought);
  const experienceSections = useSelector((state) => state.experienceSections);
  const experienceMerit = useSelector((state) => state.experienceMerit);
  const sectionMemos = useMemo(() => experienceSections[sectionIndex].memos, [
    experienceSections,
    sectionIndex,
  ]);
  // const checkIsTrialSection = useSelector(state => state.checkIsTrialSection);
  return (
    <React.Fragment>
      <div className="experience_tab_pane_content">
        {sectionIndex === 0 && (
          <ExperienceMerit experienceMerit={experienceMerit} />
        )}
        <SectionDescription
          isTopDisplay={sectionIndex !== 0}
          sectionDescription={experienceSections[sectionIndex].description}
        />
        {sectionMemos.length > 0 && (
          <SectionMemosNavBox
            sectionIndex={sectionIndex}
            memos={sectionMemos}
            audioPlay={audioPlay}
            triggerMemosNavColumnPlay={triggerMemosNavColumnPlay}
          />
        )}
      </div>
      <SectionMemosList
        sectionMemos={sectionMemos}
        isExperienceBought={isExperienceBought}
      />
    </React.Fragment>
  );
});

SectionContent.propTypes = {
  audioPlay: PropTypes.func,
  sectionIndex: PropTypes.number,
  triggerMemosNavColumnPlay: PropTypes.func,
};

export default SectionContent;
