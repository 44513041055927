import React, { PureComponent } from "react";
import { Dialog, DialogManager } from "../utils/Dialog";
import { formatDate } from "../utils/formatAudioTime";
import PropTypes from "prop-types";
import Raven from "raven-js";
import star from "study_package/icon_star_yellow.png";
import ReactMarkdown from "react-markdown";

import "./PoetryReading.scss";
import "../utils/ReactMarkDown.scss";

import UserAgent from "../utils/UserAgent";
import { onMiniProgram } from "../utils/helpers";

class Description extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { description } = this.props;
    const desc_markdown = description;
    return (
      <div className="description">
        <div className="description_header"></div>
        <div className="description_content">
          <div className="first_line markdown_content">
            <ReactMarkdown source={desc_markdown} />
          </div>
        </div>
      </div>
    );
  }
}

// class CurrentStars extends PureComponent {
//   constructor(props) {
//     super(props)
//   }
//   render() {
//     const { currentStars } = this.props
//     return (
//       <div className="current_stars">当前：{currentStars}</div>
//     )
//   }
// }

const ExternalPoetryItem = ({
  article,
  studyPackageRank,
  render,
  onExternalPoetryClick,
  endTime,
  systemTime,
  startTime,
}) => {
  return (
    <a
      className="external_poetry_item"
      href={article.poetry_article_url}
      onClick={onExternalPoetryClick}
    >
      <div className="external_poetry_wrapper">
        <div className="img_wrapper">
          <img data-src={article.photo_url} className="lazyload" />
        </div>
        <div className="peotry_info">
          <div className="peotry_title">{article.name}</div>
          <div className="author_dynasty">{article.author}</div>
        </div>
      </div>
      {formatDate(systemTime) < formatDate(startTime) ? (
        render()
      ) : (
        <div className="arrow_left"></div>
      )}
    </a>
  );
};

const ExtensivePoetriesList = ({
  articles,
  studyPackageRank,
  onExternalPoetryClick,
  endTime,
  systemTime,
  startTime,
}) => {
  const ExternalPoetryState = () => {
    return (
      <div className="locked">
        <div className="lock"></div>
      </div>
    );
  };
  return (
    <div className="extensive_poetries_list">
      {articles.map((article, index) => (
        <ExternalPoetryItem
          key={index}
          article={article}
          endTime={endTime}
          startTime={startTime}
          systemTime={systemTime}
          studyPackageRank={studyPackageRank}
          render={() => <ExternalPoetryState />}
          onExternalPoetryClick={onExternalPoetryClick}
        />
      ))}
    </div>
  );
};

export default class PoetryReading extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  onExternalPoetryClick = (e) => {
    const { study_package_system_time, study_package_start_time } = this.props;
    if (
      formatDate(study_package_system_time) <
      formatDate(study_package_start_time)
    ) {
      const buttons = [
        {
          label: "关闭",
          onClick: () => this.confirmDialog.close(),
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          <p>等待课程开课后解锁</p>
        </Dialog>
      );
      e.preventDefault();
      return false;
    }
  };

  render() {
    const {
      study_package_rank,
      description,
      articles,
      rule_url,
      study_package_system_time,
      study_package_end_time,
      study_package_start_time,
    } = this.props;
    return (
      <div className="comp_extensive_poetrirs">
        <Description
          description={description}
          // ruleUrl={rule_url}
        />
        {/* <CurrentStars
          currentStars={study_package_rank}
        /> */}
        <ExtensivePoetriesList
          articles={articles}
          studyPackageRank={study_package_rank}
          onExternalPoetryClick={this.onExternalPoetryClick}
          endTime={study_package_end_time}
          startTime={study_package_start_time}
          systemTime={study_package_system_time}
        />
      </div>
    );
  }
}
