/* eslint-disable react/prop-types */
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";

export const Select = ({
  title,
  options,
  defaultOptionId,
  emptyOptionHit,
  selector = "name",
  setForm,
  setOption,
  name,
}) => {
  const [selected, setSelected] = useState(() => {
    const index = options.findIndex((option) => option.id === defaultOptionId);
    return index === -1 ? "" : index;
  });

  useLayoutEffect(() => {
    setForm &&
      setForm((pre) => {
        return {
          ...pre,
          [name]: selected === "" ? "" : options[selected].id,
        };
      });
    setOption && setOption(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {title && <div className="min_width">{title}</div>}
      <select
        className="form-control"
        onChange={(e) => {
          const value = e.target.value;
          setSelected(value);
          setForm &&
            setForm((pre) => {
              return {
                ...pre,
                [name]: value === "" ? "" : options[value].id,
              };
            }, value);
          setOption && setOption(value);
        }}
        value={selected ?? ""}
      >
        <option value="">{emptyOptionHit}</option>
        {options.map((option, index) => (
          <option key={index} value={index}>
            {option[selector]}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
};

const SimpleSelect = ({
  options,
  defaultCheckedIndex,
  emptyOptionHit,
  onChange,
  renderOption,
  className,
}) => {
  const [selected, setSelected] = useState(defaultCheckedIndex);
  useLayoutEffect(() => {
    if (selected != undefined && selected !== "") {
      onChange(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <select
      className={`form-control ${className ?? ""}`}
      onChange={(e) => {
        const index = e.target.value;
        onChange(index);
        setSelected(index);
      }}
      value={selected}
    >
      {emptyOptionHit && <option value="">{emptyOptionHit}</option>}
      {options.map((option, index) => (
        <option key={index} value={index}>
          {renderOption(option)}
        </option>
      ))}
    </select>
  );
};

export const CheckedInput = ({
  defaultChecked,
  name,
  setForm,
  title,
  className,
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  useLayoutEffect(() => {
    setForm((pre) => ({
      ...pre,
      [name]: checked,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);
  return (
    <p className="flex_check_box">
      <input
        id={name}
        type="checkbox"
        name={name}
        onChange={() => setChecked(!checked)}
        checked={checked}
        className={className}
      />
      <span>{title}</span>
    </p>
  );
};

export const NewSession = ({
  session,
  sessionIndex,
  setForm,
  onDeleteSession,
  isSessionExit,
}) => {
  const {
    study_package_categories: categories,
    action_types,
    source_reasons,
  } = session;
  const [innerSession, setInnerSession] = useState({});
  useEffect(() => {
    console.log("NewSession_innerSession", innerSession);
    setForm((pre) => {
      const copy = [...pre];
      copy.splice(sessionIndex, 1, innerSession);
      return copy;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerSession]);

  return (
    <div
      className="flex_style"
      style={
        sessionIndex === 0
          ? { display: "inline-flex", width: "auto", marginTop: 10 }
          : { width: "auto", marginTop: 15 }
      }
    >
      <div
        className="min_width"
        style={{
          marginRight: -5,
          visibility: isSessionExit
            ? "hidden"
            : sessionIndex === 0
            ? "visible"
            : "hidden",
        }}
      >
        跟进结果
      </div>
      <SimpleSelect
        options={categories}
        defaultCheckedIndex={0}
        emptyOptionHit=""
        onChange={(index) => {
          setInnerSession((pre) => ({
            ...pre,
            study_package_category_id: categories[index]?.id,
          }));
        }}
        renderOption={(option) => option.name}
      />
      <SimpleSelect
        options={action_types}
        defaultCheckedIndex={0}
        emptyOptionHit=""
        onChange={(index) => {
          setInnerSession((pre) => ({
            ...pre,
            action_type: action_types[index].name,
          }));
        }}
        renderOption={(option) => option.name}
      />
      {source_reasons && source_reasons.length > 0 && (
        <SimpleSelect
          options={source_reasons}
          defaultCheckedIndex={""}
          emptyOptionHit="请选择用户来源"
          onChange={(index) => {
            setInnerSession((pre) => ({
              ...pre,
              source_reason: source_reasons[index]?.id,
            }));
          }}
          renderOption={(option) => option.zh_title}
          className="source-reason"
        />
      )}
      <Select
        options={session.states}
        defaultOptionId={session.state}
        emptyOptionHit="请选择跟进状态"
        selector="zh_title"
        name="state"
        setOption={(index) => {
          const state = session.states[index];
          console.log("182_state: ", state);
          setInnerSession((pre) => {
            if (pre.state !== state?.id) {
              return {
                ...pre,
                state: state?.id ?? "",
                end_reason: "",
                sub_end_reason: "",
                in_progress_state: "",
              };
            } else {
              return { ...pre };
            }
          });
        }}
      />
      {innerSession.state === 0 && (
        <Select
          options={session.in_progress_states}
          defaultOptionId={session.in_progress_state}
          emptyOptionHit="请选择跟进结果"
          selector="zh_title"
          name="in_progress_state"
          setOption={(index) => {
            const state = session.in_progress_states[index];
            setInnerSession((pre) => {
              if (pre.in_progress_state !== state?.id) {
                return {
                  ...pre,
                  end_reason: "",
                  sub_end_reason: "",
                  in_progress_state: state?.id ?? "",
                };
              } else {
                return { ...pre };
              }
            });
          }}
        />
      )}
      {innerSession.state === 1 && (
        <Select
          options={session.end_reasons}
          defaultOptionId={session.end_reason}
          emptyOptionHit="请选择跟进结果"
          selector="zh_title"
          name="end_reason"
          setOption={(index) => {
            const state = session.end_reasons[index];
            setInnerSession((pre) => {
              if (pre.end_reason !== state?.id) {
                return {
                  ...pre,
                  end_reason: state?.id ?? "",
                  sub_end_reason: "",
                  in_progress_state: "",
                };
              } else {
                return { ...pre };
              }
            });
          }}
        />
      )}
      {innerSession.state === 1 &&
        innerSession.end_reason === 2 &&
        !!session.sub_end_reasons && (
          <Select
            options={session.sub_end_reasons}
            defaultOptionId={session.sub_end_reason}
            emptyOptionHit="请选择理由"
            selector="zh_title"
            name="sub_end_reason"
            setForm={(fn) => {
              setInnerSession(fn);
            }}
          />
        )}
      <div
        className="btn btn-danger"
        type="button"
        onClick={onDeleteSession}
        style={{ marginLeft: 10 }}
      >
        删除
      </div>
    </div>
  );
};

export const Session = ({
  session,
  setForm,
  sessionIndex,
  isDefaultMarkHandle,
}) => {
  const [innerSession, setInnerSession] = useState(() => ({
    id: session.id,
    handle: isDefaultMarkHandle,
  }));
  const pageMounted = useRef();

  useEffect(() => {
    if (!pageMounted.current) {
      pageMounted.current = true;
    } else {
      setInnerSession((pre) => {
        if (
          pre.state !== innerSession.state ||
          pre.end_reason !== innerSession.end_reason ||
          pre.in_progress_state !== innerSession.in_progress_state
        ) {
          return {
            ...pre,
            handle: true,
          };
        } else return pre;
      });
    }
  }, [
    innerSession.state,
    innerSession.end_reason,
    innerSession.in_progress_state,
  ]);

  useEffect(() => {
    console.log("Session_innerSession", innerSession);
    setForm((pre) => {
      const preSessions = [...pre.sessions];
      const preSessionIndex = preSessions.findIndex(
        (se) => se.id === session.id
      );
      if (preSessionIndex !== -1) {
        preSessions.splice(preSessionIndex, 1);
      }
      return {
        ...pre,
        sessions: [...preSessions, innerSession],
      };
    });
  }, [innerSession, session.id, setForm]);

  const onMarkHandle = (e) => {
    const name = e.target.name;
    setInnerSession((pre) => {
      return {
        ...pre,
        [name]: !pre[name],
      };
    });
  };
  return (
    <div
      className="flex_style"
      style={
        sessionIndex === 0
          ? { display: "inline-flex", width: "auto", marginTop: 0 }
          : { marginLeft: 90, width: "auto", marginTop: 15 }
      }
    >
      {sessionIndex === 0 && (
        <div className="min_width" style={{ marginRight: 10 }}>
          跟进结果
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: 14 }}>
          {session.study_package_category_type}
        </div>
        <div style={{ fontSize: 14, paddingLeft: "6px" }}>
          {session.action_type_name}
        </div>
      </div>
      <Select
        options={session.states}
        defaultOptionId={session.state}
        emptyOptionHit="请选择跟进状态"
        selector="zh_title"
        name="state"
        setForm={(fn, selectedIndex) => {
          if (selectedIndex == undefined) {
            setInnerSession(fn);
          } else {
            console.warn("setForm", selectedIndex);
            const state = session.states[selectedIndex];
            console.warn("setForm", selectedIndex, state);
            setInnerSession((pre) => {
              if (pre.state !== state?.id) {
                return {
                  ...pre,
                  state: state?.id,
                  end_reason: "",
                  sub_end_reason: "",
                  in_progress_state: "",
                };
              }
              return { ...pre };
            });
          }
        }}
      />
      {innerSession.state === 0 && (
        <Select
          options={session.in_progress_states}
          defaultOptionId={session.in_progress_state}
          emptyOptionHit="请选择跟进结果"
          selector="zh_title"
          name="in_progress_state"
          setForm={(fn) => {
            setInnerSession(fn);
          }}
        />
      )}
      {innerSession.state === 1 && (
        <Select
          options={session.end_reasons}
          defaultOptionId={session.end_reason}
          emptyOptionHit="请选择跟进结果"
          selector="zh_title"
          name="end_reason"
          setForm={(fn) => {
            setInnerSession(fn);
          }}
        />
      )}
      {innerSession.state === 1 &&
        innerSession.end_reason === 2 &&
        !!session.sub_end_reasons && (
          <Select
            options={session.sub_end_reasons}
            defaultOptionId={session.sub_end_reason}
            emptyOptionHit="请选择理由"
            selector="zh_title"
            name="sub_end_reason"
            setForm={(fn) => {
              setInnerSession(fn);
            }}
          />
        )}
      <p className="flex_check_box" style={{ margin: 0, marginLeft: 10 }}>
        <input
          type="checkbox"
          onChange={onMarkHandle}
          name="handle"
          checked={innerSession.handle}
        />
        <span>本次处理</span>
      </p>
    </div>
  );
};

// const AddLogModalDialog = ({ ...props }) => {
//   const { handleCloseModal } = props;
//   console.log("AddLogModalDialog_props: ", props);

//   const postRequest = () => {
//     const toast = ToastManager.showLoading("拼命加载中...");
//     return request({
//       url: `${props.addFollowUpLogUrl}`,
//       method: "POST",
//       headers: csrfHeaders,
//       data: { user_id: props.user.id, ...form },
//     }).then(() => {
//       toast.cancel();
//     });
//   };

//   const handleFollowUpLog = () => {
//     return postRequest();
//   };

//   const handleAddExtraLog = () => {
//     return postRequest();
//   };

//   const handleTransformLog = () => {
//     return postRequest();
//   };

//   return (
//     <StyleAddLogModalDialog id="addLogModal" className="modal">
//       <div className="modal-dialog">
//         <div className="modal-content">
//           <div className="modal-header">
//             <button
//               className="close"
//               aria-label="Close"
//               data-dismiss="modal"
//               type="button"
//               onClick={handleCloseModal}
//             >
//               <SpanClose aria-hidden="true">&times;</SpanClose>
//             </button>
//             <h4 id="myModalLabel" className="modal-title">
//               添加跟进日志
//             </h4>
//           </div>
//           <div className="modal-body">
//             <div className="flex_style">
//               <Select
//                 title="专属老师"
//                 options={props.teachers}
//                 defaultOptionId={props.user.teacher}
//                 emptyOptionHit="请选择专属老师"
//                 setForm={setForm}
//                 name="teacher"
//               />
//             </div>

//             <div className="flex_style">
//               <div className="min_width">日志内容</div>
//               <textarea
//                 cols="30"
//                 rows="10"
//                 className="margin_left form-control editable"
//                 style={{ maxWidth: "100%", height: "130px" }}
//                 value={form.log_note ?? ""}
//                 onChange={(e) => {
//                   const v = e.target.value;
//                   setForm((pre) => ({
//                     ...pre,
//                     log_note: v,
//                   }));
//                 }}
//               ></textarea>
//             </div>

//             <div className="flex_style" style={{ flexWrap: "wrap" }}>
//               {props.sessions.map((session, index) => (
//                 <Session
//                   session={session}
//                   key={index}
//                   setForm={setForm}
//                   sessionIndex={index}
//                 />
//               ))}
//             </div>
//           </div>
//           <div className="flex_style_wrapper" style={{ margin: "0 24px 25px" }}>
//             <div className="flex_style">
//               <button
//                 className="btn btn-primary"
//                 type="button"
//                 id="add_follow_up_log"
//                 onClick={debounce(handleFollowUpLog)}
//               >
//                 提交
//               </button>
//               <button
//                 className="btn btn-primary finish"
//                 id="attention_handled"
//                 type="button"
//                 onClick={debounce(handleAddExtraLog)}
//               >
//                 增补日志
//               </button>
//               <button
//                 className="btn btn-primary finish"
//                 id="attention_handled"
//                 type="button"
//                 onClick={debounce(handleTransformLog)}
//               >
//                 流转
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </StyleAddLogModalDialog>
//   );
// };

// export default AddLogModalDialog;
