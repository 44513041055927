import React from "react";
import PropTypes from "prop-types";

import ExperienceAlbumSection from "./ExperienceAlbumSection";

export default class ExperienceAlbumSections extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
  }

  render() {
    const listItems = this.props.sections.map((section, i) => (
      <ExperienceAlbumSection
        section={section}
        key={section.id}
        show_description={i == 0}
        listened_experience_order_url={this.props.listened_experience_order_url}
        listened={this.props.listened}
      />
    ));
    return <div>{listItems}</div>;
  }
}

ExperienceAlbumSections.propTypes = {
  sections: PropTypes.array.isRequired,
};
