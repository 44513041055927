import React from "react";
import PropTypes from "prop-types";

import { Offline, Online } from "react-detect-offline";

import "./NetworkDetect.scss";

export default class NetworkDetect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    // <Online> "Online is network connect success" </Online>
    // <Offline> "Offline is network connect fail" </Offline>
    return (
      <Offline>
        <div className="comp-network-detect">
          <div className="network_detect_judge">
            <div className="dialog_newtwork">
              <div className="networkError">
                当前网络连接异常
                <br />
                请确认您的网络连接是否正常
              </div>
            </div>
          </div>
        </div>
      </Offline>
    );
  }
}
