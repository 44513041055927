import { fabric } from "fabric";

class ViewPort {
  constructor(canvas) {
    this.canvas = canvas;
  }

  grabStart(e) {
    this.lastPointer = { x: e.screenX, y: e.screenY };
  }

  grab(e) {
    let grabPointer = { x: e.screenX, y: e.screenY };
    let transform = this.canvas.viewportTransform;
    transform[4] += grabPointer.x - this.lastPointer.x;
    transform[5] += grabPointer.y - this.lastPointer.y;
    this.lastPointer = grabPointer;
  }
}

class CanvasWithViewport extends fabric.Canvas {
  constructor(canvas, options = {}) {
    super(canvas, options);
    this.viewport = new ViewPort(this);
    this.isGrabMode = true;
    this.isGrabbing = false;
  }

  _onMouseMoveInGrabMode(e) {
    if (this.isGrabbing) {
      this.viewport.grab(e);
      this.renderAll();
    }
  }

  _onMouseDownInGrabMode(e) {
    this.isGrabbing = true;
    this.viewport.grabStart(e);
  }

  _onMouseUpInGrabMode(e) {
    if (this.isGrabbing) {
      this.isGrabbing = false;
    }
  }

  __onMouseMove(e) {
    if (this.isGrabMode) {
      this._onMouseMoveInGrabMode(e);
      return;
    }
    super.__onMouseMove(e);
  }

  __onMouseDown(e) {
    if (this.isGrabMode) {
      this._onMouseDownInGrabMode(e);
      return;
    }
    super.__onMouseDown(e);
  }

  __onMouseUp(e) {
    if (this.isGrabMode) {
      this._onMouseUpInGrabMode(e);
      return;
    }
    super.__onMouseUp(e);
  }
}

fabric.CanvasWithViewport = CanvasWithViewport;
