import React, { Component } from "react";
import { request, csrfHeaders } from "../utils/request";
import { debounce } from "../utils/helpers";
import Raven from "raven-js";
import Uri from "jsuri";
import { GA } from "../utils/Env";
import "./NewGrade.scss";
import { isThisQuarter } from "date-fns";

class NewGrade extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    const { grades } = this.props;
    const preSchool = []; // 入园前
    const nurserySchool = []; // 幼儿园
    const primarySchool = []; // 小学
    const juniorSchool = []; // 初中
    const othersSchool = []; // 其他
    const lastGrades = [];

    grades.forEach((item) => {
      this.getTitleId(item, "入园前", preSchool);
      this.getTitleId(item, "幼儿园", nurserySchool);
      this.getTitleId(item, "小学", primarySchool);
      this.getTitleId(item, "初中", juniorSchool);
      this.getTitleId(item, "其他", othersSchool);
    });

    lastGrades.push(
      { category: preSchool[0].category, schools: preSchool },
      { category: nurserySchool[0].category, schools: nurserySchool },
      { category: primarySchool[0].category, schools: primarySchool },
      { category: juniorSchool[0].category, schools: juniorSchool },
      { category: othersSchool[0].category, schools: othersSchool }
    );

    console.log("lastGrades: ", lastGrades);

    this.state = {
      schoolId: -1,
      gradeIndex: -1,
      textLight: false,
      lastGrades,
    };
  }

  getTitleId = (item, category_name, needArray) => {
    item.category == category_name
      ? needArray.push({
          category: item.category,
          ch_title: item.zh_title,
          id: item.id,
        })
      : "";
  };

  handleGradeRequest = () => {
    let url = decodeURIComponent(window.location.href);
    let promotionId = new Uri(url)
      .getQueryParamValue("redirect_url")
      .split("/")[2];
    console.log("promotionId: ", promotionId);

    if (!this.state.textLight) {
      return;
    }

    let params = {
      user_baby: {
        baby_attributes: {
          grade: Number(this.state.schoolId),
        },
      },
    };
    request({
      url: this.props.create_user_baby_grade,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        let data = resp.data;
        window.location.href = data.redirect_url;
      })
      .catch((err) => {
        Raven.captureException(err);
      });

    GA("Baby", "Create", promotionId);
  };

  handleGradeSchool = (e) => {
    let id = e.currentTarget.getAttribute("data-id");
    this.setState({
      schoolId: id,
    });
  };

  handleClickIdx = (e) => {
    let index = e.currentTarget.getAttribute("data-index");
    this.setState({
      gradeIndex: index,
      textLight: true,
    });
  };

  render() {
    const { schoolId, gradeIndex, textLight, lastGrades } = this.state;

    // 九月入学时间
    let september = new Date().getMonth() + 1;

    return (
      <div className="comp_baby_register">
        <div
          className="enter_school_title"
          style={{ visibility: september >= 9 ? "hidden" : "" }} // 9月之后不显示
        >
          请按<strong>9月份</strong>即将入学的年级选择
        </div>
        <ul>
          {lastGrades.map((item, index) => (
            <li key={index} data-index={index} onClick={this.handleClickIdx}>
              {item.category.length > 2 ? (
                <div
                  className={`left_grade ${
                    gradeIndex == index ? "text_light" : ""
                  }`}
                >
                  {item.category}
                </div>
              ) : (
                <div
                  className={`left_grade ${
                    gradeIndex == index ? "text_light" : ""
                  }`}
                >
                  <span>{item.category.slice(0, 1)}</span>
                  <span>{item.category.slice(1, 2)}</span>
                </div>
              )}
              <div className="age_grade_list">
                {item.schools.map((school) => (
                  <div
                    key={school.id}
                    className={`list_item ${
                      schoolId == school.id ? "hight_light" : ""
                    }`}
                    data-id={school.id}
                    onClick={this.handleGradeSchool}
                  >
                    {school.ch_title}
                  </div>
                ))}
              </div>
            </li>
          ))}
        </ul>
        <button
          className={`${textLight ? "button_light" : ""}`}
          onClick={debounce(this.handleGradeRequest)}
        >
          提交
        </button>
      </div>
    );
  }
}

export default NewGrade;
