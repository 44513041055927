/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const StyledHeadInfo = styled.div`
  padding-left: 15px;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
  }

  .info_text {
    padding-left: 10px;
    a {
      font-size: 13px;
      font-weight: 500;
      text-decoration: underline;
      color: #3c8af0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 70px;
      display: block;
    }
    p {
      font-size: 12px;
      color: #999;
      margin: 0;
    }
  }
`;

const HeadInfo = ({ ...props }) => {
  const { user } = props;
  return (
    <StyledHeadInfo>
      <img src={`${user.photoUrl}`} alt="" className="img-circle" />
      <div className="info_text">
        <a href={user.userPath}>{user?.name}</a>
        <p>{user.mobile}</p>
      </div>
    </StyledHeadInfo>
  );
};

export default HeadInfo;
