import React from "react";
import PropTypes from "prop-types";
import Popups from "../day_studies/courses/common/Popups";
import Raven from "raven-js";
import URI from "jsuri";
import QueryString from "query-string";
import {
  request as axios,
  csrfHeaders,
  getAxiosErrorText,
} from "../utils/request";
import { DialogSkip, DialogSkipManager } from "../utils/DialogSkip";
import BWDetect from "../utils/BWDetect.js";
import CountDownTime from "../utils/CountDown";
import { formatAudioTime } from "../utils/formatAudioTime";
import {
  showDialog,
  retryFetch,
  promptPageBack,
  tracking,
  debounce,
} from "../utils/helpers";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";
import qrcode from "media_lesson_activity/app_download.png";
import count_time_icon from "count_down_course/count_time_icon.png";
import ready_go_back from "count_down_course/ready_go_back.png";
import ready_go from "count_down_course/ready_go.gif";
import icon_quiz_pass from "icon_quiz_pass.png";
import QuizRecorder from "./MediaLessonRecorder";
import { HasAwesomeKeyboard } from "../utils/HasAwesomeKeyboard";
import UserAgent from "../utils/UserAgent";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import "./MediaLesson.scss";
import { GA_new } from "../utils/Env.js";

import MediaLessonFillQuiz from "./quiz/MediaLessonFillQuiz";
import MediaLessonQuizOption from "./quiz/MediaLessonQuizOption";

import WrappedMedium from "@/components/media/WrappedMedium";

import CrystalReward from "../point_reward/CrystalReward";
import FullScreenCrystalReward from "../point_reward/FullScreenCrystalReward";
import AnswerCorrectFirstTime from "point_rewards/answer-correct-first-time.png";
import StudyOnTimeText from "point_rewards/study-ontime-reward-5.png";
import StudyHardText from "point_rewards/think-seriously-reward-5.png";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
import CustomSmallIcon from "../shared/CustomSmallIcon";
import Clock from "clock";
import BabyPointIcon from "../point_reward/BabyPointIcon";
import DownAppTip from "./utils/DownAppTip";
import { ToastManager } from "../utils/Toast";
import Hls from "hls.js";

const request = axios.create({
  timeout: 15000,
});

const dialog_stars = [
  dialog_star_0,
  dialog_star_1,
  dialog_star_2,
  dialog_star_3,
];

function printStub() {
}

class MediaLessonItem extends React.Component {
  constructor(props) {
    super(props);

    this.resource = this.props;
    const quiz = this.resource.quizzes[0] ?? { id: -1 }; // 当没有任何 quiz 的时候，构造一个假的 quiz，以减少代码改动。
    this.isShowPoetryDialog = true;
    var lessonRecord = null;
    if (props.last_record) {
      lessonRecord = props.last_record;
      // console.log("props.last_record", lessonRecord);
    }
    if (props.last_play_time) {
      this.lessonDeltaTimeProps = props.last_record["delta_time"];
      this.lessonDeltaTime = props.last_record["delta_time"];
    } else {
      this.lessonDeltaTimeProps = 0;
      this.lessonDeltaTime = 0;
    }
    this.answered = false;
    this.state = {
      babyId: this.resource.baby_id,
      authToken: this.resource.auth_token,
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play",
      btnPlayPause: true,
      totalTime: 0.0,
      width: "0%",
      showTime: "00:00",
      currentQuiz: quiz,
      handleSelected: false,
      firstPlay: false,
      stop: { quiz_id: null },
      displayAlert: false,
      displayBackAlert: false,
      lessonRecord: lessonRecord,
      isShowProgressBarForEditor: false,
      interactionPoints: [],
      poetryMediaLessonDisplay: "none",
      isRemoveRecord: false,
      isReplaying: false,
      preLoadedImage: this.isAudioLesson() ? this.getQuizPhoto(quiz) : [],
      showEndtime: 0,
      // new Date().getTime() +
      // (Number(quiz.timeout_value || 0) +
      //   (quiz.is_first_multiple_race_quiz ? 10 : 9)) *
      //   1000,
      showCount: 0,
      hideReady: quiz?.is_first_multiple_race_quiz ? false : true, //多题回答,
      isMuilFirst: quiz?.is_first_multiple_race_quiz ? true : false, //多题回答
      limitTimes: quiz?.timeout_value ? quiz.timeout_value : 0, //限时
      showMuilResult: false,
      nextLessRecord: null,
      race_quizzes_result: null,
      isCorrectForFirstQuizToday: true,
      showFullScreenReward: false,
      showReward: false,
      fullScreenRewardShowedToday: false,
      shouldReward: this.props.should_reward,
      showStudyOnTime: this.props.study_on_time_reward,
      showStudyHard: false,
      show_finish_all_day_lesson_reward: false,
      finish_all_day_lesson_reward_point: null,
      babyPoint: this.props.baby_point,
      finishLessonResponse: {},
    };
    this.getAudioInfo();
    this.audioPlay = this.audioPlay.bind(this);
    this.submitQuizRecord = this.submitQuizRecord.bind(this);
    this.replayAudio = this.replayAudio.bind(this);
    this.pauseReplayAudio = this.pauseReplayAudio.bind(this);
    this.startSpendPlayTime = new Date();
    this.audioLoadTimeoutSec = 35000;
    this.pauseSingleTime = 0;
    this.pauseSingleTimeStart = 0;
    this.showDialogList = false;
    this.recordTimes = {
      effect_fail_sound: 784,
      effect_pass_sound: 1210,
      effect_noscore_sound: 1153,
      effect_fail_human_sound: 1646,
    };
    this.effectiveTime = 0;
  }

  initializeHls() {
    if (Hls.isSupported()) {
      console.log("---------- HLS is supported! --------------");
      this.video = document.getElementById("replay-video");
      this.hls = new Hls();
      this.hls.loadSource(this.props.resource_url);
      this.videoAttached = false;
      // hls.attachMedia(video);
      this.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          "manifest loaded, found " + data.levels.length + " quality level"
        );
      });
    } else {
      console.log("不支持m3u8");
    }
  }

  // 播放答题音效,加载水晶动画
  promisifiedPlayEffectAudioWithReward = (ElementId, record) => {
    let audio = document.getElementById(ElementId);
    printStub(
      "===========promisifiedPlayEffectAudio===========" +
        this.recordTimes[ElementId]
    );
    return new Promise((resolve, reject) => {
      audio.play();
      setTimeout(() => {
        if (record) {
          this.setCrystalRewardData(record);
        }
        resolve();
      }, this.recordTimes[ElementId] || 2000);
    });
  };

  calcScore = (record) => {
    if (!this.state.shouldReward || !record) {
      return 0;
    }

    let score;
    console.log("~~~~ record is: ", record, "===============");
    const end_answer_time = record.end_answer_time ?? new Date();
    if (!this.state.currentQuiz.no_score) {
      // 计分题
      if (this.isRecorderQuiz(record.question_type)) {
        // 语音题
        score = this.scoreOfRecorderQuiz(
          record,
          record.start_answer_time,
          end_answer_time
        );
      } else {
        score = Math.max(3 - record.total_answer_count, 0);
      }
    } else if (this.state.currentQuiz.timeout_value) {
      // 抢答题
      score = record.total_answer_count == 1 ? 2 : 0;
    } else {
      // 非计分题
      const considerTime = (end_answer_time - record.start_answer_time) / 1000;
      score =
        considerTime >
        (this.state.currentQuiz.avg_time ? this.state.currentQuiz.avg_time : 5)
          ? 2
          : 1;
    }
    console.log("============= score is: ", score, "---------------------");
    return score;
  };

  setCrystalRewardData = (record) => {
    console.log("This is record: ", record);
    record.end_answer_time = record.end_answer_time ?? new Date();

    let score = this.calcScore(record);
    const currentQuiz = { ...this.state.currentQuiz };
    currentQuiz.quizScore = score;

    this.setState({
      currentQuiz: currentQuiz,
    });

    if (record.total_answer_count > 1) {
      this.setState({
        isCorrectForFirstQuizToday: false,
        showFullScreenReward: false,
      });
    } else if (
      this.state.shouldReward &&
      this.props.point_reward_type === "full_screen"
    ) {
      if (
        !this.state.currentQuiz.answered_today &&
        !this.state.currentQuiz.no_score &&
        !this.state.fullScreenRewardShowedToday &&
        this.state.isCorrectForFirstQuizToday
      ) {
        this.setState({
          showFullScreenReward: true,
          fullScreenRewardShowedToday: true,
        });
        this.WrappedMedium.pause();
      }
    }

    // 是否显示非全屏显示的水晶奖励
    if (
      this.state.shouldReward &&
      score > 0 &&
      !this.state.showFullScreenReward
    ) {
      this.setState({
        showReward: true,
      });
      this.WrappedMedium.pause();
      // 1秒钟后才改变水晶数量
      setTimeout(() => {
        this.setState({
          babyPoint: this.state.babyPoint + score,
        });
      }, 1000);
      // 2.5 秒钟后，水晶组件消失
      setTimeout(() => {
        this.setState({
          showReward: false,
        });
        this.audioPlay();
      }, 2500);
    }

    console.log("==============Y==================");
    console.log(record);
    console.log("score is: ", score);
    console.log(this.state.currentQuiz);
    console.log("~~~~~~~~~~~~~~~~~~~~~");
    console.log(
      this.state.shouldReward,
      this.state.showFullScreenReward,
      this.state.showReward,
      this.props.point_reward_type
    );
    console.log("==============Y==================");
  };

  operateSingleAnswerText = ((self) => {
    let singleAnswerText = []; // 储存用户单题答题记录 *每次答题后清空

    const pushSingleAnswer = (answer) => {
      // answer = answer.trim()
      // if(answer.length>160){
      //   answer = answer.split('').splice(0,160).join('')
      // }
      singleAnswerText.push(answer);
    };
    const getSingleAnswers = () => {
      let answers = [...singleAnswerText];
      let answerToText = answers.join("");
      while (answerToText.length > 80) {
        answers.pop();
        answerToText = answers.join("");
      }
      return [...answers];
    };
    const clearSingleAnswerText = () => (singleAnswerText = []);

    return { pushSingleAnswer, getSingleAnswers, clearSingleAnswerText };
  })(this);

  // logAttendCourse = ()=>{
  //   return request({
  //     url: this.props.log_attend_course_url,
  //     method: 'POST',
  //     headers: csrfHeaders,
  //   })
  //   .catch(err => {
  //     printStub(err)
  //   })
  // }
  //获取该组件的倒计时结束时间
  changeTime(time) {
    this.setState({ showEndtime: time });
  }
  doInitProgressBar() {
    if (this.props.last_play_time) {
      const WrappedMedium = document.getElementById("medium_el");
      printStub(WrappedMedium);
      const setCurrentPlayTime = () => {
        //设置播放时间
        printStub("canplaythrough");
        this.WrappedMedium.currentTime = this.props.last_play_time + 0.1;
        $("#medium_el").off("canplaythrough", setCurrentPlayTime);
      };
      $("#medium_el").on("canplaythrough", setCurrentPlayTime);

      let dialogOptions = {
        text: "接着上次的进度继续学习吗？",
        mainLabel: "继续",
        subLabel: "重新开始",
        mainCallback: () => {
          // try {
          //   this.setState({
          //     firstPlay: this.state.firstPlay
          //   },()=>{

          //     $('#medium_el').off('canplaythrough',setCurrentPlayTime)
          //   })
          // } catch (e) {
          //   Raven.captureException(e)
          // }
          this.startSpendPlayTime = new Date();
          this.isContinue = true;
          this.last_record = this.props.last_record || null;
          this.WrappedMedium.play();
        },
        subCallback: () => {
          $("#medium_el").off("canplaythrough", setCurrentPlayTime);
          this.isContinue = false;
          console.log("333333333333333");
          this.WrappedMedium.currentTime = 0.0;
          printStub("重新开始", this.state);
          // let lessonRecord = this.state.lessonRecord
          // lessonRecord['start_play_time'] = new Date()
          // lessonRecord['last_play_time'] = 0
          // this.setState({
          //   firstPlay: true,
          //   lessonRecord: lessonRecord
          // }, () => {
          //   this.summitLessonRecord()
          // })
          // this.WrappedMedium.play()
          var lessonRecord = {
            start_play_time: new Date(),
            // start_play_time1: new Date(),
            last_play_time: 0.0,
          };
          if (!this.last_record || !this.last_record.start_play_time) {
            this.startSpendPlayTime = new Date();
            this.lessonDeltaTime = 0;
          }
          this.setState(
            {
              firstPlay: true,
              lessonRecord: lessonRecord,
            },
            () => {
              this.summitLessonRecord().catch(() => {});
              console.log("22222222222222");
              this.WrappedMedium.currentTime = 0;
              this.WrappedMedium.play();
            }
          );
        },
      };
      showDialog.call(this, dialogOptions);
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          printStub(document.body.clientWidth);
          if (document.body.clientWidth >= 768) {
            return;
          }
          var weui = document.querySelector(".weui-dialog");
          if (weui) {
            var cssweui = "";
            cssweui += "-webkit-transform: rotateZ(90deg);";
            cssweui += "-moz-transform: rotateZ(90deg);";
            cssweui += "-o-transform: rotateZ(90deg);";
            cssweui += "-ms-transform: rotateZ(90deg);";
            cssweui += "transform: rotateZ(90deg);";
            cssweui += `transform-origin: 50% 50%;`;
            cssweui += "position:absolute;";
            cssweui += "top:40%;";
            cssweui += "left:15%;";
            cssweui += `width: 300px;`;
            cssweui += `height: 133px;`;
            weui.style.cssText = cssweui;
          }
        }
      });
    }
  }

  getQuizPhoto = (quiz) => {
    return Array.from(document.documentElement.classList).includes("webp")
      ? quiz.show_photo.webp_url
      : quiz.show_photo.url;
  };

  showPhotoLoadErrorDialog = () => {
    if (this.isShowPhotoError) {
      this.audioPause();
      let dialogOptions = {
        text: "图片加载错误，请检查网络后重试",
        mainLabel: "确定",
        mainCallback: async () => {
          try {
            await this.preLoadedImage(
              this.getQuizPhoto(this.state.currentQuiz)
            );
            this.WrappedMedium.play();
          } catch (e) {
            this.showPhotoLoadErrorDialog();
          }
        },
      };
      showDialog.call(this, dialogOptions);
    } else {
      this.WrappedMedium.play();
    }
  };

  onTimeUpdate = (e) => {
    if (isNaN(this.WrappedMedium.duration)) {
      return;
    }
    let { currentQuiz, stop } = this.state;
    const current = this.WrappedMedium.currentTime;
    // printStub('this.WrappedMedium.onTimeUpdate')
    const duration = this.state.totalTime;
    const percent = current / duration;

    this.updateProgress(percent);

    if (current > 1.0) {
      this.setState({
        playPauseClassName: "btn-play-pause",
        playOtherPauseClassName: "btn-play-pause",
      });
    }

    if (current > currentQuiz.end_time || current < 1) {
      // 下一次update将获取新的currentQuiz
      var quiz = this.findQuiz(current);
      if (quiz && quiz.id != this.state.currentQuiz.id) {
        this.setCurrentQuiz(quiz);
      }
      // 耗时约1s
    }

    // 进入下一个Quiz时，隐藏答案
    if (stop.quiz_id && stop.quiz_id !== currentQuiz.id && currentQuiz.start_time == Math.floor(current)) {
      if (this.isAudioLesson()) {
        this.showPhotoLoadErrorDialog();
      }

      this.setState(
        {
          stop: { quiz_id: null },
          isShowHide: "",
          isReplaying: false,
        },
        () => {
          // There may be race condition between below codes and the above `setCurrentQuiz`.
          // Do we actually need to hide these?
          // if (this.answerOptions) {
          //   this.answerOptions.hiddenAnswer();
          // }
          if (this.answerFills) {
            this.answerFills.hiddenFillAnswer();
          }
        }
      );
    }

    // if (this.state.currentQuiz && this.state.currentQuiz.options && (this.state.currentQuiz.options.length > 0) && (this.state.currentQuiz.end_time == Math.floor(this.WrappedMedium.currentTime))) {

    // Quiz到达EndTime，触发做题逻辑
    console.log(
      "Quiz到达EndTime, end_time, current",
      currentQuiz.start_time,
      currentQuiz.end_time,
      Math.floor(current),
      current
    );
    if (currentQuiz && currentQuiz.end_time == Math.floor(current)) {
      // printStub(current, this.WrappedMedium.currentTime)
      if (!stop.quiz_id) {
        console.log("停下来显示答案: none");
        this.setState(
          {
            stop: { quiz_id: currentQuiz.id },
            isShowHide: "none",
          },
          () => {
            // this.WrappedMedium.pause()
            printStub(this.state.currentQuiz);
            if (
              currentQuiz.question_type === 1 &&
              currentQuiz.options.length > 0
            ) {
              this.needUserAction = true;
              this.WrappedMedium.pause();
              printStub(this.answerOptions);
              printStub("当前是选择题");
              this.answerOptions.showAnswer();
            } else if (currentQuiz.question_type === 0) {
              this.needUserAction = true;
              this.WrappedMedium.pause();
              printStub(this.answerFills);
              printStub("当前是填空题");
              this.answerFills.showFillAnswer();
            } else if (currentQuiz.question_type === 3.1) {
              // 古诗逻辑 -- 部分跟读
              this.needUserAction = true;
              this.WrappedMedium.pause();
              printStub("当前是古诗词语音播放 , 部分跟读");
              this.setState(
                {
                  showRecordBar: true,
                  showCount: this.state.showCount + 1,
                },
                () => {
                  this.WrappedMedium.pause();
                }
              );
            } else if (currentQuiz.question_type === 3.2) {
              // 古诗逻辑 -- 整首朗读
              this.needUserAction = true;
              this.WrappedMedium.pause();
              printStub("当前是古诗词语音播放 3.2，整首朗读");
              this.setState(
                {
                  showRecordBar: true,
                },
                () => {
                  this.WrappedMedium.pause();
                }
              );
            } else if (currentQuiz.question_type === 2) {
              this.needUserAction = true;
              this.WrappedMedium.pause();
              this.setState(
                {
                  showRecordBar: true,
                },
                () => {
                  this.WrappedMedium.pause();
                }
              );
            } else {
              printStub("当前仅仅是图片,没有交互");
              this.setState(
                {
                  isShowHide: "block",
                },
                () => {
                  printStub("isShowHide: block");
                }
              );
            }
          }
        );
      }
    }
  };

  onEnded = (e) => {
    console.log("触发 on end");
    this.setState({
      btnPlayPause: true,
    });
    this.isShowProgressBar.call(this, false);
    printStub(this.state.totalTime, this.state.currentQuiz.end_time);
    if (
      (this.props.course_type === 1 || this.props.recorder_required) &&
      this.isLastQuiz() &&
      "question_type" in this.state.currentQuiz &&
      (this.state.currentQuiz.question_type == 3.1 ||
        this.state.currentQuiz.question_type == 3.2) &&
      !this.props.is_not_support_recorder
    ) {
      printStub("触发 poetry on end, need user action, returned");
      return;
    }
    this.showDialog();
    this.isShowProgressBar.call(this, false);
  };

  onError = () => {
    // printStub(
    //   'Error ' +
    //     this.WrappedMedium.error.code +
    //     '; details: ' +
    //     this.WrappedMedium.error.message
    // )
    if (this.WrappedMedium && this.WrappedMedium.error) {
      console.log(
        " Raven.captureException(this.WrappedMedium.error)",
        this.WrappedMedium.error.message
      );
      let json = JSON.stringify(this.WrappedMedium.error);
      if (json && json !== '{}') {
        Raven.captureException(json);
      }
    }
  };

  handleCanPlayEvent() {
    if (this.audioLoadTimeout) {
      clearTimeout(this.audioLoadTimeout);
      this.audioLoadTimeout = null;
    }
    this.audioCanPlayTime = Date.now();
    console.warn(
      "audio load time",
      this.audioCanPlayTime - this.audioLoadStartTime
    );
  }

  onPlaying = () => {
    console.log("onPlaying");
    if (this.audioLoadTimeout) {
      clearTimeout(this.audioLoadTimeout);
      this.audioLoadTimeout = null;
    }
  };

  onCanPlay = () => {
    console.warn("onCanPlay");
    this.handleCanPlayEvent();
  };

  onCanPlayThrough = () => {
    console.warn("onCanPlayThrough");
    this.handleCanPlayEvent();
  };

  onLoadStart = () => {
    this.audioLoadStartTime = Date.now();
    this.audioLoadTimeout = setTimeout(() => {
      clearTimeout(this.audioLoadTimeout);
      this.audioLoadTimeout = null;
      // 如果用户此时还没有交互， 那么取消提示
      console.log("this.userActedPlay", this.userActedPlay);
      if (!this.userActedPlay) return;
      console.log("clearTimeout(this.audioLoadTimeout)---");
      const dialogOptions = {
        text: "音频加载缓慢，请检查您的网络后重试",
        mainLabel: "确定",
      };
      showDialog.call(this, dialogOptions);
    }, this.audioLoadTimeoutSec);
    // this.audioLoadTimeoutSec += 10000;
    console.warn("onLoadStart");
  };

  onPause = (e) => {
    console.log("触发 on Pause");
    this.setState({
      playPauseClassName: "btn-play",
      playOtherPauseClassName: "btn-play-other",
    });
    if (!this.needUserAction) {
      printStub("触发 on Pause", this.needUserAction);
      this.pauseSingleTimeStart = new Date();
    }
    // 有交互或者is_show_progress为false，暂停不显示进度条
    if (this.needUserAction || !this.props.is_show_progress) {
      return;
    }
    this.isShowProgressBar.call(this, true);
  };

  onPlay = (e) => {
    console.log("on Play");
    this.setState({
      playPauseClassName: "btn-play-loading",
      playOtherPauseClassName: "btn-play-loading",
    });
    if (this.pauseSingleTimeStart) {
      this.pauseSingleTime =
        this.pauseSingleTime +
        (new Date() - new Date(this.pauseSingleTimeStart)) / 1000;
    }
    this.pauseSingleTimeStart = 0;
    if (!this.state.firstPlay) {
      console.log(this.state.firstPlay, "onPlay------------");
      var lessonRecord = {
        start_play_time: new Date(),
        // start_play_time1: new Date(),
        last_play_time: this.props.last_play_time,
      };
      if (!this.last_record || !this.last_record.start_play_time) {
        this.startSpendPlayTime = new Date();
        this.lessonDeltaTime = 0;
      }

      this.setState(
        {
          firstPlay: true,
          lessonRecord: lessonRecord,
        },
        () => {
          // this.startSpendPlayTime = new Date();
          this.summitLessonRecord().catch(() => {});
        }
      );
    }
    // 播放不显示进度条
    this.isShowProgressBar.call(this, false);
    this.needUserAction = false;
  };

  findQuiz(currentTime) {
    var quiz = this.resource.quizzes.find(function (quiz) {
      if (
        quiz.start_time <= Math.floor(currentTime) &&
        Math.floor(currentTime) <= quiz.end_time
      ) {
        return quiz;
      }
    });
    return quiz;
  }

  setThisAnswered(flog) {
    this.answered = flog ? flog : false;
  }

  setCurrentQuiz(quiz) {
    this.answered = false;
    this.effectiveTime = 0;
    if (this.isAudioLesson()) {
      this.preLoadedImage(this.getQuizPhoto(quiz)).catch((e) => {});
    }

    this.setState({
      hideReady: quiz.is_first_multiple_race_quiz ? false : true, //多题回答,
      isMuilFirst: quiz.is_first_multiple_race_quiz ? true : false, //多题回答
      limitTimes: quiz.timeout_value ? quiz.timeout_value : 0, //限时
      showMuilResult: false,
      nextLessRecord: null,
      race_quizzes_result: null,
      currentQuiz: quiz,
    });

    if (this.state.currentQuiz.question_type === 1) {
      this.answerOptions.setOptions(quiz.options);
    } else if (this.state.currentQuiz.question_type === 0) {
      this.answerFills.setFills();
    }
  }

  createAudio(url, data, currentQuiz) {
    this.setState({
      showLoading: true,
    });
    retryFetch()(() => {
      let self = this;
      return request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: { server_ids: [data] },
      });
    })
      .then((resp) => {
        this.setState({
          showLoading: false,
        });
        this.submitRecordQuizRecord.call(this, currentQuiz);
      })
      .catch((error) => {
        console.log(
          " this.submitRecordQuizRecord.call(this, currentQuiz)---2",
          error
        );
        Raven.captureException(error);
        this.setState({
          showLoading: false,
        });
        this.submitRecordQuizRecord.call(this, currentQuiz);
      });
  }

  showDialog() {
    if (this.showDialog.isShowed) {
      console.warn("showDialog has showed!");
      return;
    }
    this.showDialogList = true;
    this.showDialog.isShowed = true;
    const current = this.WrappedMedium.currentTime;
    const duration = this.state.totalTime;
    const percent = current / duration;

    this.setState({
      showTime: formatAudioTime(0),
      playOtherPauseClassName: "btn-play-loading",
    });

    // 提交记录
    // this.lesson_record 的设置是防止this.state.lessonRecord的异步问题
    let lessonRecord = this.lesson_record || {};
    printStub(lessonRecord);
    let record = { ...this.state.lessonRecord, ...lessonRecord };
    printStub(record);
    if (record) {
      record["end_play_time"] = new Date();
      record["is_continue"] = this.isContinue;
    }
    this.setState(
      {
        lessonRecord: record,
      },
      () => {
        this.summitLessonRecord(true).catch(() => {});
      }
    );
    return;
  }

  audioPause() {
    this.WrappedMedium.pause();
  }

  audioPlay() {
    if (this.state.showReward || this.state.showFullScreenReward) {
      return;
    }
    console.log("=============audioPlay", this.WrappedMedium.currentTime);
    // If we increase the audio's currentTime, it may result in even smaller
    // time when onTimeUpdate is called (maybe it rounds down to a proper time),
    // which is not acceptable for us. So we don't increase the time, just let
    // it continue.
    //
    // const current = this.WrappedMedium.currentTime;
    // this.WrappedMedium.currentTime = current + 0.1;
    console.log("=============", this.WrappedMedium.currentTime);
    this.setState({
      isShowHide: "flex",
    });
    this.WrappedMedium.play();

    // printStub('audio play currentTime:'+this.WrappedMedium.currentTime)
  }

  skipTo(percent) {
    this.WrappedMedium.currentTime = this.WrappedMedium.duration * percent;
    var quiz = this.findQuiz(this.WrappedMedium.currentTime);
    if (quiz) {
      this.setCurrentQuiz(quiz);
    }
  }

  updateProgress(percent) {
    const duration = this.state.totalTime;
    var showTime = duration * (1 - percent) <= 0 ? 0 : duration * (1 - percent);
    if (isNaN(showTime)) {
      showTime = 0.0;
    }

    this.setState({
      width: Math.round(100 * percent) + "%",
      showTime: formatAudioTime(showTime),
    });
  }

  seek(relativeLeft) {
    var percent = 0.0;
    percent = relativeLeft / this.containerProgress.offsetWidth;
    if (percent > 1) {
      percent = 1;
    }
    this.skipTo(percent);
    this.updateProgress(percent);
    if (percent < 1 && percent > 0) {
      // 拖动后自动播放
      this.WrappedMedium.play();
    }
  }
  // Event Handle
  btnPlayClick = (e) => {
    this.userActedPlay = true;
    if (
      !this.props.is_not_support_recorder &&
      (this.props.course_type == 1 || this.props.recorder_required)
    ) {
      XinYaNativeBridge.checkPermission(this.startPlay);
    } else {
      this.startPlay();
    }
  };

  startPlay = () => {
    console.log("startPlay-------------");
    if (!this.state.firstPlay) {
      console.log(this.state.firstPlay, "startPlay-------------");
      var lessonRecord = {
        start_play_time: new Date(),
        // start_play_time1: new Date(),
        last_play_time: 0.0,
      };
      if (!this.last_record || !this.last_record.start_play_time) {
        this.startSpendPlayTime = new Date();
        this.lessonDeltaTime = 0;
      }

      this.setState(
        {
          firstPlay: true,
          lessonRecord: lessonRecord,
        },
        () => {
          // this.startSpendPlayTime = new Date();
          this.summitLessonRecord().catch(() => {});
        }
      );
    }
    this.setState(
      (preState) => {
        return {
          btnPlayPause: !preState.btnPlayPause,
        };
      },
      () => {
        if (!this.state.btnPlayPause) {
          this.WrappedMedium.play();
        } else {
          this.WrappedMedium.pause();
        }
      }
    );
  };

  containerProgressClick = (e) => {
    var relativeLeft =
      e.clientX - this.containerProgress.getBoundingClientRect().left;

    printStub("relativeLeft", relativeLeft);
    if (isNaN(relativeLeft)) {
      return;
    }
    this.seek(relativeLeft);
  };

  doAudioPause() {
    return this.audioPause();
  }

  doProgressBarEndMoving(endProgressTouchPointX) {
    let relativeLeft =
      endProgressTouchPointX -
      this.containerProgress.getBoundingClientRect().left;
    if (relativeLeft > this.containerProgress.offsetWidth) {
      this.seek(this.containerProgress.offsetWidth);
      return;
    }
    printStub("relativeLeft", relativeLeft);
    printStub(
      endProgressTouchPointX,
      this.containerProgress.getBoundingClientRect().left
    );
    if (isNaN(relativeLeft)) {
      return;
    }
    this.seek(relativeLeft);
  }

  doProgressBarMoving(movedProgressTouchPointX) {
    let relativeLeft =
      movedProgressTouchPointX -
      this.containerProgress.getBoundingClientRect().left;
    this.setState({
      width: relativeLeft,
    });
  }

  handleMouseStart = (e) => {
    this.handleProgressSelected = true;
    this.doAudioPause.call(this);
  };
  handleMouseEnd = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    this.handleProgressSelected = false;
    const endProgressTouchPointX = e.clientX;

    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };
  handleMouseMove = (e) => {
    if (!this.handleProgressSelected) {
      return false;
    }
    const movedProgressTouchPointX = e.clientX;

    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };
  handleTouchStart = (e) => {
    printStub("touch start");
    this.doAudioPause.call(this);
  };
  handleTouchEnd = (e) => {
    e.preventDefault();
    printStub("touch end");
    const endProgressTouchPointX = e.changedTouches[0].pageX;

    this.doProgressBarEndMoving.call(this, endProgressTouchPointX);
  };
  handleTouchMove = (e) => {
    printStub("touch progress");
    const movedProgressTouchPointX = e.changedTouches[0].pageX;

    this.doProgressBarMoving.call(this, movedProgressTouchPointX);
  };

  isAudioLesson() {
    return this.resource.resource_type === "audio";
  }

  replayText() {
    // return this.isAudioLesson() ? '重听' : '重看'
    return "重听";
  }

  getAudioInfo() {
    if (!this.resource.resource_url) {
      const toast = ToastManager.showToastError("页面错误，请重新刷新页面！");
      setTimeout(() => {
        toast.cancel();
      }, 2000);
      return;
    }
    const url = this.resource.resource_url + "?avinfo";
    request({
      url: url,
      method: "get",
    })
      .then((resp) => {
        const totalTime = parseInt(resp.data.format.duration);
        this.setState({
          totalTime: totalTime,
          showTime: formatAudioTime(totalTime),
        });
      })
      .catch((err) => {
        console.log(" getAudioInfo() {", err);
        Raven.captureException(JSON.stringify(err));
        // printStub(err)
      });
  }
  getNextSteps(url) {
    // const { media_lesson_id } = this.state;
    request({
      url,
      method: "GET",
      headers: csrfHeaders,
    }).then((res) => {
      // this.setState({ showLastModel: true, lastData: res.data });
      if (res.data.next_media_lesson_url) {
        window.location.href = res.data.next_media_lesson_url;
      } else {
        if (res.data.state) {
          this.setState({ showLastModel: true, lastData: res.data });
        }
      }
    });
  }

  finishLessonOperation = (resp) => {
    if (this.props.study_mode == "reading_v2" && this.showDialogList) {
      window.location.href = this.props.steps_url;
      return;
    }
    this.showDialogList = false;

    if (resp.data.lesson_record) {
      this.setState({
        lessonRecord: resp.data.lesson_record,
      });
    }
    if (resp.data.rank != null) {
      // printStub('结束')
      // printStub("rank：" + resp.data.rank)
      this.setState({
        progressRank: resp.data.rank,
        poetryProgress: resp.data.progress,
      });

      this.props.course_type === 0
        ? this.mathCourseDialog(dialog_stars)
        : this.showResultPage();

      if (UserAgent.isWebBrowser()) {
        window.onpopstate = function () {
          promptPageBack("pushStack").clear();
        };
      }

      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          printStub(document.body.clientWidth);
          if (document.body.clientWidth >= 768) {
            return;
          }
          var weui = document.querySelector(".weui-dialog");
          if (weui) {
            var cssweui = "";
            cssweui += "-webkit-transform: rotateZ(90deg);";
            cssweui += "-moz-transform: rotateZ(90deg);";
            cssweui += "-o-transform: rotateZ(90deg);";
            cssweui += "-ms-transform: rotateZ(90deg);";
            cssweui += "transform: rotateZ(90deg);";
            cssweui += `transform-origin: 50% 50%;`;
            cssweui += `min-height: ${document.body.clientWidth}px;`;
            cssweui += "position:absolute;";
            cssweui += "top:50%;";
            cssweui += "left:0;";
            cssweui += `margin-top: -${document.body.clientWidth / 2}px;`;
            cssweui += `margin-left: -${
              (document.body.clientHeight - document.body.clientWidth) / 2
            }px;`;
            cssweui += `min-width: ${document.body.clientHeight}px;`;
            cssweui += `width: 0px;`;
            cssweui += `height: 0px;`;
            weui.style.cssText = cssweui;
          }
          var ft = document.querySelector(".weui-dialog_ft");
          if (ft) {
            var cssft = "";
            cssft += `display: block;`;
            cssft += `width: 100%;`;
            cssft += `margin: 0;`;
            cssft += `position: absolute;`;
            cssft += `top: 60%;`;
            ft.style.cssText = cssft;
          }
        }
      });
      // this.audioTipEL.play()
    }
  };

  summitLessonRecord(isPlayEnd) {
    // printStub('media_lesson_record:', this.state.lessonRecord)

    const url = this.resource.lesson_record_url;
    printStub("=========this.state.lesson_record========");
    printStub(this.state.lessonRecord);
    printStub("=========this.state.lesson_record========");
    const lastRecord = this.last_record || {}; // 继续播放会带入lastRecord
    let lessonRecord = { ...this.state.lessonRecord, ...lastRecord };
    if (this.isLastQuiz()) {
      lessonRecord["delta_time"] = this.calculateLessonDeltaTime();
    }
    if (this.showDialogList) {
      console.log("------end_play_time-------");
      lessonRecord["end_play_time"] = new Date();
    }
    this.startSpendPlayTime = new Date();
    return request({
      url: url,
      method: "PATCH",
      headers: csrfHeaders,
      data: {
        type: "MediaLessonRecord",
        media_lesson_record: lessonRecord,
        study_package_activity_id: this.props.study_package_activity_id,
        day_study_id: this.props.day_study_id,
        lessonDeltaTimeProps: this.lessonDeltaTimeProps,
        lastRecord: this.last_record || null,
      },
    })
      .then((resp) => {
        console.log("This is end? :", resp, "------------------------");
        if (this.props.should_reward && (resp.data.study_hard_reward === true || resp.data.show_finish_all_day_lesson_reward)) {
          console.log("进入奖励阶段！！！！！");
          if (resp.data.study_hard_reward) {
            if (resp.data.show_finish_all_day_lesson_reward) {
              this.handleStudyHardConfirmClickCallback = () => {
                this.setState({
                  show_finish_all_day_lesson_reward: true,
                  finish_all_day_lesson_reward_point: 5 + resp.data.double_reward_point,
                });
              };
            }
            this.setState({
              showStudyHard: true,
              babyPoint: resp.data.baby_point,
              finishLessonResponse: resp,
            });
          } else {
            this.setState({
              show_finish_all_day_lesson_reward: true,
              finish_all_day_lesson_reward_point: 5 + resp.data.double_reward_point,
              babyPoint: resp.data.baby_point,
              finishLessonResponse: resp,
            });
          }
        } else {
          this.finishLessonOperation(resp);
        }
      })
      .catch((error) => {
        this.showDialogList = false;
        if (isPlayEnd) {
          this.WrappedMedium.pause();
          let dialogOptions = {
            text: getAxiosErrorText(error, "提交"),
            mainLabel: "确定",
            mainCallback: async () => {
              try {
                await this.summitLessonRecord(isPlayEnd);
              } catch (e) {}
            },
          };
          showDialog.call(this, dialogOptions);
        }
        console.log(error);
        Raven.captureException(error);
        return Promise.reject(error);
      });
  }

  // 数学思维课结果页弹框
  mathCourseDialog(dialog_stars) {
    printStub("看看有没有进来");
    var that = this;
    // 弹框
    let hint = that.resource.next_hint;
    // if (that.resource.next_type == "MediaLesson") {
    //   hint = "下一课";
    // } else if (that.resource.next_type == "SyncPractice") {
    //   hint = "返回课表";
    // } else if (that.resource.next_type == "StudyReport") {
    //   hint = "查看学习报告";
    // } else if (that.resource.next_type == "Home") {
    //   hint = "结束";
    // } else {
    //   hint = "随堂测试";
    // }
    if (this.props.study_mode == "intelligent") {
      if (this.props.get_step_url) {
        this.getNextSteps(this.props.get_step_url);
      } else if (this.props.redirect_url) {
        window.location.href = this.props.redirect_url;
      }

      return;
    }
    const buttons = [
      {
        label: hint,
        onClick: () => {
          // this.confirmDialog.close()
          window.location.href = that.resource.next_url;
        },
      },
      {
        label: "重学一遍",
        onClick: () => {
          window.location.reload();
        },
      },
    ];
    // printStub(resp.data)
    var audioResource = `https://upload.fireflybaby.cn/star_${this.state.progressRank}.mp3`;
    this.confirmDialog = DialogSkipManager.open(
      <DialogSkip buttons={buttons}>
        <img src={dialog_stars[this.state.progressRank]} />
        <audio
          src={audioResource}
          ref={(audioTipEL) => (this.audioTipEL = audioTipEL)}
        ></audio>
      </DialogSkip>
    );
  }

  // 展示古诗词结果页
  showResultPage() {
    this.setState({
      poetryMediaLessonDisplay: "block",
    });
  }

  submitRecordQuizRecord(currentQuiz, soeData) {
    printStub("submitRecordQuizRecord", this.resource.lesson_record_url);
    const quizID = currentQuiz.id;
    const record = {
      media_resource_quiz_id: quizID,
      start_answer_time: this.start_record_time,
      end_answer_time: this.end_record_time,
      total_answer_count: 1,
      answer_text: [],
      effectiveTime:
        this.effectiveTime && this.effectiveTime >= 0
          ? this.effectiveTime / 1000
          : 0,
    };

    if (soeData) {
      record.total_answer_count = soeData.totalAnswerCount;
      record.answer_text = soeData.answerText;
    } else if (soeData === null) {
      record.total_answer_count = 1;
      record.answer_text = "";
    }
    const url = this.resource.lesson_record_url;
    var lessonRecord = this.state.lessonRecord;
    if (!lessonRecord) {
      lessonRecord = this.props.last_record;
    }
    lessonRecord["delta_time"] = this.calculateLessonDeltaTime();
    lessonRecord["quiz_records"] = [record];
    lessonRecord["last_play_time"] = this.WrappedMedium.currentTime;
    printStub("=====================");
    printStub(lessonRecord);
    printStub("====---------========");
    this.startSpendPlayTime = new Date();
    return request({
      url: url,
      method: "PATCH",
      headers: csrfHeaders,
      data: {
        type: "MediaResourceQuiz",
        media_lesson_record: lessonRecord,
        study_package_activity_id: this.props.study_package_activity_id,
        day_study_id: this.props.day_study_id,
        lessonDeltaTimeProps: this.lessonDeltaTimeProps,
        score: this.state.currentQuiz.quizScore,
        full_screen_reward_showed_today: this.state.fullScreenRewardShowedToday,
      },
    }).then((resp) => {
      this.answered = true;
      this.effectiveTime = 0;
      this.starAction = false;
      // this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
      console.log(this, "0000000000000", this.isLastQuiz());
      if (resp.data.race_quizzes_result) {
        console.log(
          "000000000000000000000000000000000",
          resp.data.race_quizzes_result
        );
        this.setState({
          race_quizzes_result: resp.data.race_quizzes_result,
          nextLessRecord: resp.data.lesson_record,
          showMuilResult: true,
          // showEndtime: new Date().getTime() + 6 * 1000,
        });
      } else {
        if (resp.data.lesson_record) {
          this.lesson_record = resp.data.lesson_record;
          this.setState(
            {
              lessonRecord: resp.data.lesson_record,
            },
            () => {
              if (
                this.props.is_not_support_recorder &&
                !this.start_record_time
              ) {
                this.start_record_time = new Date();
              }
              this.end_record_time = new Date();
              setTimeout(() => {
                this.WrappedMedium.play();
              }, 200);
              // if (
              //   (this.props.course_type === 1 ||
              //     this.props.recorder_required) &&
              //   !this.isLastQuiz()
              // ) {
              //   this.WrappedMedium.play();
              // }
              // this.WrappedMedium.play();
              if (this.isLastQuiz()) {
                this.WrappedMedium.pause();
                this.showDialog();
              }
              this.setState({
                showRecordBar: false,
              });
            }
          );
          if (
            (this.props.course_type === 1 || this.props.recorder_required) &&
            this.isLastQuiz() &&
            "question_type" in this.state.currentQuiz &&
            (this.state.currentQuiz.question_type == 3.1 ||
              this.state.currentQuiz.question_type == 3.2)
          ) {
            console.warn("submitRecordQuizRecord then show showDialog");
            this.showDialog();
          }
        }
      }
    });

    //   console.log(
    //     "-----222222222222222222222---res",
    //     res
    //   );
    //   if (res.data.race_quizzes_result) {
    //     console.log(
    //       "000000000000000000000000000000000",
    //       res.data.race_quizzes_result
    //     );
    //     this.setState({
    //       race_quizzes_result:
    //         res.data.race_quizzes_result,
    //       nextLessRecord: res.data.lesson_record,
    //       showMuilResult: true,
    //     });
    //   } else {
    //     this.setLessonRecord(res.data.lesson_record);
    //     // this.delayExcute();
    //   }
    // });
    // .catch((error) => {
    //   console.log(error);
    //   Raven.captureException(error);
    // });
  }

  calculateLessonDeltaTime = () => {
    const singleQuizSpendTime = parseInt(
      (new Date() - this.startSpendPlayTime) / 1000
    );
    this.lessonDeltaTime =
      this.lessonDeltaTime + singleQuizSpendTime - this.pauseSingleTime;
    // this.lessonDeltaTime + singleQuizSpendTime;
    this.pauseSingleTime = 0;
    return this.lessonDeltaTime;
  };

  rewardComponent = () => {
    if (!this.props.should_reward) {
      return;
    }
    return (
      <>
        {this.state.showFullScreenReward && (
          <FullScreenCrystalReward
            point={this.state.currentQuiz.quizScore}
            totalPoint={this.state.babyPoint}
            textUrl={AnswerCorrectFirstTime}
            handleClick={this.handleFullScreenConfirmClick}
          />
        )}
        {!this.state.showFullScreenReward && this.state.showReward && (
          <CrystalReward
            point={this.state.currentQuiz.quizScore}
            totalPoint={this.state.babyPoint}
          />
        )}
        {this.state.showStudyOnTime && (
          <FullScreenCrystalReward
            point={this.props.study_on_time_reward_point}
            totalPoint={this.props.baby_point}
            textUrl={StudyOnTimeText}
            handleClick={this.handleStudyOnTimeConfirmClick}
          />
        )}
        {this.state.showStudyHard && (
          <FullScreenCrystalReward
            point={this.props.study_hard_reward_point}
            totalPoint={this.state.babyPoint}
            textUrl={StudyHardText}
            handleClick={this.handleStudyHardConfirmClick}
          />
        )}
        {this.state.show_finish_all_day_lesson_reward && (
          <FullScreenCrystalReward
            point={this.state.finish_all_day_lesson_reward_point}
            totalPoint={this.state.babyPoint}
            textUrl={FinishWholeDayStudyIcon}
            handleClick={this.handleFinishAllDayLessonRewardClick}
          />
        )}
      </>
    );
  };

  handleFullScreenConfirmClick = () => {
    this.setState({
      showFullScreenReward: false,
    });
    setTimeout(() => {
      this.audioPlay();
    }, 500);
  };

  handleStudyOnTimeConfirmClick = () => {
    this.setState({
      showStudyOnTime: false,
    });
    setTimeout(() => {
      this.audioPlay();
    }, 500);
  };

  handleStudyHardConfirmClick = () => {
    this.setState({
      showStudyHard: false,
    });
    if (this.handleStudyHardConfirmClickCallback) {
      this.handleStudyHardConfirmClickCallback();
    } else {
      setTimeout(() => {
        this.finishLessonOperation(this.state.finishLessonResponse);
      }, 500);
    }
  };

  handleFinishAllDayLessonRewardClick = () => {
    this.setState({
      show_finish_all_day_lesson_reward: false,
    });
    setTimeout(() => {
      this.finishLessonOperation(this.state.finishLessonResponse);
    }, 500);
  };

  submitQuizRecord(quizRecord, answerText) {
    // printStub('quiz record:',quizRecord)
    const quizID = this.state.currentQuiz.id;
    const record = {
      media_resource_quiz_id: quizID,
      start_answer_time: quizRecord.start_answer_time,
      end_answer_time: quizRecord.end_answer_time ?? new Date(),
      total_answer_count: quizRecord.total_answer_count,
      answer_text: [...this.operateSingleAnswerText.getSingleAnswers()],
    };

    printStub([...this.operateSingleAnswerText.getSingleAnswers()]);
    const url = this.resource.lesson_record_url;
    let lessonRecord = { ...this.state.lessonRecord };
    printStub({ ...lessonRecord });
    if (!lessonRecord) {
      lessonRecord = this.props.last_record;
    }
    lessonRecord["quiz_records"] = [record];
    lessonRecord["last_play_time"] = this.WrappedMedium.currentTime;

    lessonRecord["delta_time"] = this.calculateLessonDeltaTime();
    printStub("=====================");
    printStub(lessonRecord);
    printStub("====---------========");
    this.operateSingleAnswerText.clearSingleAnswerText();
    this.startSpendPlayTime = new Date();

    return request({
      url: url,
      method: "PATCH",
      headers: csrfHeaders,
      data: {
        type: "MediaResourceQuiz",
        media_lesson_record: lessonRecord,
        study_package_activity_id: this.props.study_package_activity_id,
        day_study_id: this.props.day_study_id,
        lessonDeltaTimeProps: this.lessonDeltaTimeProps,
        score: this.state.currentQuiz.quizScore,
        full_screen_reward_showed_today: this.state.fullScreenRewardShowedToday,
      },
    });
  }
  // .then((resp) => {
  //   console.log("--------------");
  // if (resp.data.lesson_record) {
  //   this.setState({
  //     lessonRecord: resp.data.lesson_record,
  //   });
  // }
  // })

  setLessonRecord2(lessonRecord) {
    if (lessonRecord) {
      this.lesson_record = lessonRecord;
      this.setState({
        lessonRecord: lessonRecord,
      });
      if (
        (this.props.course_type === 1 || this.props.recorder_required) &&
        this.isLastQuiz() &&
        "question_type" in this.state.currentQuiz &&
        (this.state.currentQuiz.question_type == 3.1 ||
          this.state.currentQuiz.question_type == 3.2)
      ) {
        console.warn("submitRecordQuizRecord then show showDialog");
        this.showDialog();
      }
    }
    // this.setState({
    //   lessonRecord,
    // });
  }
  delayExcute() {
    // this.hiddenAnswer();
    // this.playAudio();
    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
    this.audioPlay();
    if (this.state.timeOutID) {
      clearTimeout(this.state.timeOutID);
    }
  }

  setLessonRecord(lessonRecord) {
    this.setState({
      lessonRecord,
    });
  }
  replayAudio() {
    if (!this.videoAttached && this.hls) {
      this.videoAttached = true;
      this.hls.attachMedia(this.video);
    }

    let currentQuiz = this.state.currentQuiz;
    try {
      this.replayWrappedMedium.play();
      this.replayWrappedMedium.pause();
      this.replayWrappedMedium.currentTime = currentQuiz.start_time;
      this.replayWrappedMedium.play();
      GA_new(
        "media_lessons_show",
        "replay",
        currentQuiz.id,
        this.props.target_type,
        this.props.target_id
      );
    } catch (e) {
      console.log("replayAudio error");
      this.replayWrappedMedium.currentTime = currentQuiz.start_time;
      this.replayWrappedMedium.play();
      Raven.captureException(JSON.stringify(e));
    }
  }

  pauseReplayAudio() {
    this.replayWrappedMedium.pause();
  }

  replayOnTimeUpdate(e) {
    // printStub('开始重播')
    this.replayWrappedMedium.style.display = "block";
    this.WrappedMedium.style.display = "none";
    this.setState({
      isReplaying: true,
    });
    let currentQuiz = this.state.currentQuiz;
    if (!this.state.replayCurrentTime) {
      // printStub('重新设置重播开始时间')
      this.setState(
        {
          // isReplaying: currentQuiz.timeout_value ? false : true,
          replayCurrentTime: true,
        },
        () => {
          this.replayWrappedMedium.currentTime = currentQuiz.start_time;
          this.replayWrappedMedium.play();
        }
      );
      return;
    }
    if (
      currentQuiz &&
      currentQuiz.end_time == Math.floor(this.replayWrappedMedium.currentTime)
    ) {
      // printStub('重播结束')
      this.WrappedMedium.style.display = "block";
      this.replayWrappedMedium.pause();
      this.replayWrappedMedium.style.display = "none";
      this.setState({
        isReplaying: false,
      });
    }
  }

  onAlertPoetryDialog() {
    let searchParams = new URI(window.location.href).query();
    let parsedQuery = QueryString.parse(searchParams);
    if (
      this.props.is_not_support_recorder &&
      (this.props.course_type === 1 || this.props.recorder_required)
    ) {
      // if (this.props.no_miniprogram) {
      //   return;
      // }
      this.setState({
        isPoetryDialog: true,
        isRemoveRecord: true,
      });
    }
  }

  isShowProgressBar(bool) {
    this.setState(
      () => ({
        isShowProgressBar: bool,
      }),
      () => {
        this.setProgressBarInMiniProgram();
      }
    );
  }

  creatInteractionPoints() {
    let counter = [];
    let mediumEndTime = this.props.quizzes[this.props.quizzes.length - 1]
      .end_time;
    if (!mediumEndTime) {
      return counter;
    }
    this.props.quizzes.forEach((quiz) => {
      if ("question_type" in quiz) {
        counter.push({
          left: Math.round(100 * (quiz.end_time / mediumEndTime)) + "%",
        });
      }
    });
    console.log("counter is: ");
    return counter;
  }

  setProgressBarInMiniProgram() {
    wx.miniProgram.getEnv(function (res) {
      if (res.miniprogram) {
        printStub(document.body.clientWidth);
        if (document.body.clientWidth >= 768) {
          return;
        }
        let progressBar = document.querySelector(".container_course");
        if (progressBar) {
          let cssProgressBar = "";
          cssProgressBar += "bottom:none;";
          cssProgressBar += `top:${document.body.clientWidth - 50}px;`;
          // cssProgressBar += 'z-index:9999999'
          progressBar.style.cssText = cssProgressBar;
        }
      }
    });
  }

  componentDidMount() {
    this.initializeHls();
    // 获取设备屏高，赋值给CSS调用
    var vh =
      window.screen.width > window.screen.height
        ? window.screen.height
        : window.screen.width;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // 音频每次播放一个
    this.lessonEL.addEventListener(
      "play",
      (e) => {
        let audios = document.querySelectorAll("audio");

        for (let i = 0; i < audios.length; i++) {
          let audio = audios[i];
          if (!audio.paused && audio != e.target) {
            // printStub(1607, i, audio, e.target, audio == e.target);
            if (audio.id !== "medium_el") {
              audio.currentTime = 0.0;
            }
            audio.pause();
          }
        }
      },
      true
    );

    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }
    this.doInitProgressBar.call(this);
    var test = window.location.hash;
    if (test == "#test") {
      this.setState({
        isShowProgressBarForEditor: true,
      });
    }

    if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.keepScreenOn();
    }

    if (this.props.is_show_progress) {
      this.isShowProgressBar(true);
    }

    if (this.props.quizzes.length > 0) {
      this.setState({
        interactionPoints: this.creatInteractionPoints.call(this),
      });
    }

    this.onAlertPoetryDialog.call(this);

    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (!UserAgent.isWebBrowser()) {
      if (window.sessionStorage.length > 0) {
        window.sessionStorage.clear();
      }
    }

    // handle back buttom in browser
    if (UserAgent.isWebBrowser()) {
      promptPageBack("pushStack", "要从上课状态中退出吗？").setPrompt();
    }
  }
  handleRemoveAlert = (isDisplay) => {
    printStub("remove");
    this.setState({
      displayAlert: isDisplay,
    });
  };
  preventPageClick = (event) => {
    let evt = event || window.event;
    evt.stopPropagation ? evt.stopPropagation() : (evt.cancelBubble = true);
    event.preventDefault();
    return false;
  };

  onPoetryDialog = (e) => {
    this.setState({
      isPoetryDialog: false,
    });
  };

  isLastQuiz = () => {
    if (this.props.quizzes.length === 0) {
      return true;
    }
    return (
      this.state.currentQuiz.id ===
      this.props.quizzes[this.props.quizzes.length - 1].id
    );
  };
  clearIntervalAndTimeOut(interval, timeout) {
    if (interval) {
      clearInterval(interval);
      interval = null;
      // this.setIntervalID = null;
    }
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
      // this.setTimeoutID = null;
    }
  }
  onRecordStartAction = () => {
    // 处理背景音乐和背景图片
    this.starAction = true;
    printStub("getById caption_bgm");
    let captionAudio = document.getElementById("caption_bgm");
    printStub("captionAudio" + captionAudio);
    if (captionAudio) {
      if (this.judgeIsHaveAudioUrl()) {
        printStub("captionAudio.play" + captionAudio);
        // captionAudio.play()
        const captionAudioBGM = this.setPoetryBGM();
        XinYaNativeBridge.playBgm({ href: captionAudioBGM });
      }
    }
    if ("entire_record_setting" in this.state.currentQuiz) {
      if ("options" in this.state.currentQuiz.entire_record_setting) {
        this.setState({
          showCaption: true,
        });
      }
    }

    this.start_record_time = new Date();
    const alertCountDown = (times) => {
      let countDownNumber = times - 1;
      this.setState({
        countDownNumber: times,
      });
      const alert = setInterval(() => {
        if (countDownNumber) {
          printStub(countDownNumber);
          this.setState({
            countDownNumber: countDownNumber--,
          });
        } else {
          printStub("finished");
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
          this.countDownStop();
          this.setState({
            showRecordCountDown: false,
            disableMaskTap: false,
          });
          this.setIntervalID = alert;
        }
      }, 1000);
      this.setIntervalID = alert;
    };
    const alert = setTimeout(() => {
      printStub("showRecordCountDown");
      this.setState(
        {
          showRecordCountDown: true,
        },
        () => {
          printStub(this.state.showRecordCountDown);
        }
      );
      alertCountDown(10);
    }, 50000);

    this.setTimeoutID = alert;
  };
  onRecordStopAction = () => {
    if (this.state.currentQuiz.timeout_value) {
    } else {
      this.setState({
        showCaption: false,
        showRecordCountDown: false,
      });
    }

    this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
  };
  onRecordCompleteAction = (soeData, effectiveTime) => {
    this.setState({ showEndtime: 0 });

    console.log("=================完成", effectiveTime);
    this.effectiveTime += effectiveTime ?? 0;
    // 如果是is_not_support_recorder true， 那么此时的录音是模拟的， 需要补上this.start_record_time
    if (this.props.is_not_support_recorder && !this.start_record_time) {
      this.start_record_time = new Date();
    }

    this.end_record_time = new Date();
    let currentQuiz = Object.assign({}, this.state.currentQuiz);
    const process = () => {
      if (this.state.isRemoveRecord) {
        this.submitRecordQuizRecord(currentQuiz, soeData).then(() => {
          let msg = this.props.raven_msg;
          msg["quiz_id"] = currentQuiz.id;
          tracking(msg, "onRecordCompleteAction 1");
          if (this.state.showMuilResult) {
            return;
          }
          if (
            (this.props.course_type === 1 || this.props.recorder_required) &&
            !this.isLastQuiz()
          ) {
            this.WrappedMedium.play();
          }
          if (this.isLastQuiz()) {
            this.WrappedMedium.pause();
            this.showDialog();
          }
          this.setState({
            showRecordBar: false,
          });
          this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
        });
        return;
      }

      this.submitRecordQuizRecord.call(this, currentQuiz, soeData).then(() => {
        let msg = this.props.raven_msg;
        msg["quiz_id"] = currentQuiz.id;

        tracking(msg, "onRecordCompleteAction 2");
        if (this.state.showMuilResult) {
          return;
        }
        if (
          (this.props.course_type === 1 ||
            this.props.recorder_required ||
            currentQuiz.question_type === 2) &&
          !this.isLastQuiz()
        ) {
          if (currentQuiz.question_type === 2) {
            setTimeout(() => this.WrappedMedium.play(), 1000);
          } else {
            this.WrappedMedium.play();
          }
        }
        if (this.isLastQuiz()) {
          this.WrappedMedium.pause();
          this.showDialog();
        }
        this.setState({
          showRecordBar: false,
          showRecordCountDown: false,
        });
        this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID);
      });
    };

    // // 语音题水晶奖励

    if (this.isRecorderQuiz(currentQuiz.question_type)) {
      const score = this.scoreOfRecorderQuiz(
        currentQuiz,
        this.start_record_time,
        this.end_record_time
      );
      currentQuiz.quizScore = score;
      this.setState(
        {
          showReward: true,
          currentQuiz: currentQuiz,
          babyPoint: this.state.babyPoint + score,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showReward: false,
            });
            process();
          }, 2000);
        }
      );
    } else {
      process();
    }
  };

  isRecorderQuiz = (quizType) => {
    return [3.1, 3.2].indexOf(quizType) >= 0;
  };

  scoreOfRecorderQuiz = (quiz, start_answer_time, end_answer_time) => {
    let score = 1;
    if (
      (end_answer_time - start_answer_time) / 1000 >=
      (quiz.avg_time ? quiz.avg_time : 5)
    ) {
      score = 2;
    }
    return score;
  };

  replayMedium = () => {
    if (this.isAudioLesson()) {
      return (
        <audio
          src={this.resource.resource_url}
          ref={(replayWrappedMedium) =>
            (this.replayWrappedMedium = replayWrappedMedium)
          }
          onTimeUpdate={this.replayOnTimeUpdate.bind(this)}
        ></audio>
      );
    } else {
      return (
        <video
          src={this.resource.resource_url}
          ref={(replayWrappedMedium) =>
            (this.replayWrappedMedium = replayWrappedMedium)
          }
          onTimeUpdate={this.replayOnTimeUpdate.bind(this)}
          width="100%"
          id="replay-video"
          playsInline={true}
          autoPlay={false}
        ></video>
      );
    }
  };

  onRecordReStartAction = () => {
    // this.clearIntervalAndTimeOut(this.setIntervalID, this.setTimeoutID)
    // this.start_record_time = new Date()
    // let captionAudio = document.getElementById('caption_bgm')
    // if (captionAudio) {
    //   if (this.judgeIsHaveAudioUrl()) {
    //     const captionAudioBGM = this.setPoetryBGM()
    //     XinYaNativeBridge.playBgm({href: captionAudioBGM})
    //   }
    // }
    this.onRecordStartAction();
  };

  countDownStop = () => {
    this.quizRecorderEL?.handleAudioRecorderStop &&
      this.quizRecorderEL.handleAudioRecorderStop();
  };

  judgeIsHaveAudioUrl() {
    if (
      this.state.currentQuiz &&
      "entire_record_setting" in this.state.currentQuiz
    ) {
      if (
        this.state.currentQuiz.entire_record_setting &&
        "media_url" in this.state.currentQuiz.entire_record_setting
      ) {
        return true;
      }
    }
    return false;
  }

  preLoadedImage = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        this.setState(
          {
            preLoadedImage: src,
          },
          () => resolve()
        );
        this.isShowPhotoError = false;
      };
      img.onerror = () => {
        Raven.captureException(`media lesson show photo load error: ${src}`);
        this.setState(
          {
            preLoadedImage: null,
          },
          () => reject()
        );
        this.isShowPhotoError = true;
      };
      img.src = src;
    });
  };

  setPoetryBGM = () => {
    if (this.judgeIsHaveAudioUrl()) {
      return this.state.currentQuiz.entire_record_setting.media_url;
    }
    return "";
  };
  effectTime = () => {
    this.effectiveTime -= 500;
  };

  render() {
    const dialog_stars = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];

    let { isReplaying } = this.state;
    // 古诗词结果页弹框显示引导文案
    let hint = this.resource.next_hint;
    // if (this.resource.next_type == "MediaLesson") {
    //   hint = "下一关";
    // } else if (this.resource.next_type == "StudyReport") {
    //   hint = "打卡并查看学习报告";
    // } else if (this.resource.next_type == "Home") {
    //   hint = "结束";
    // } else {
    //   hint = "下一关：诗词游戏";
    // }
    const captionAudioBGM = this.setPoetryBGM();

    var isLastQuiz = false;
    var quizzes = [];
    this.props.quizzes.forEach(function (quiz) {
      if ("question_type" in quiz) {
        quizzes.push(quiz);
      }
    });
    let lastQuiz = quizzes[quizzes.length - 1];
    if (lastQuiz && lastQuiz.id == this.state.currentQuiz.id) {
      isLastQuiz = true;
    }

    // printStub("this.props.is_not_support_recorder:", this.props.is_not_support_recorder)
    return [
      this.rewardComponent(),
      <div
        ref={(lessonEl) => (this.lessonEL = lessonEl)}
        className="comp-media-lesson"
        id="comp-media-lesson"
        key="comp-media-lesson"
      >
        {this.state.showLastModel && <Popups {...this.state.lastData}></Popups>}
        {this.state.showLoading && (
          <div className="show_loading_mask">
            <div className="show_loading"> 录音上传中...</div>
          </div>
        )}
        {this.state.showRecordCountDown && (
          <div className="record_count_down_mask">
            <div className="record_count_down">
              <div className="count_down_number">
                {this.state.countDownNumber}
              </div>
              <div className="count_down_text">录音即将超时</div>
            </div>
          </div>
        )}

        <audio src={captionAudioBGM} preload="auto" id="caption_bgm"></audio>
        <audio
          src="https://upload.fireflybaby.cn/failure1.mp3"
          id="effect_fail_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/pass.mp3"
          id="effect_pass_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/goon.mp3"
          id="effect_noscore_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/failure_h1.mp3"
          id="effect_fail_human_sound"
          preload="auto"
        />
        <div id="screen_turn">建议使用横屏观看，获得更好体验</div>

        <div className="media-lesson-top-right-icons">
          <CustomSmallIcon
            icon={Clock}
            textContent={this.state.showTime}
            customClassName="time_progress"
            customStyle={{ position: "relative" }}
          />
          {this.props.show_point_icon && (
            <BabyPointIcon
              point={this.state.babyPoint}
              customClassName="media-lesson-baby-point"
              customStyle={{ position: "relative" }}
            />
          )}
        </div>

        {/* <div className={"time_progress"}>{this.state.showTime}</div> */}
        {this.isAudioLesson() && (
          <div className="box_photo" id="box_photo">
            <div
              className="box_photo_wrap"
              style={{ backgroundImage: `url(${this.state.preLoadedImage})` }}
            />
          </div>
        )}
        {this.replayMedium()}
        <WrappedMedium
          src={this.resource.resource_url}
          ref={(WrappedMedium) => (this.WrappedMedium = WrappedMedium)}
          onLoadStart={this.onLoadStart}
          onCanPlayThrough={this.onCanPlayThrough}
          onCanPlay={this.onCanPlay}
          onPlaying={this.onPlaying}
          onTimeUpdate={this.onTimeUpdate}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onEnded={this.onEnded}
          onError={this.onError}
          target_id={this.resource.target_id}
          target_type={this.resource.target_type}
          id="medium_el"
          preload="auto"
          setting_url={this.props.setting_url}
          speed={this.props.speed}
          playbackRateEnabled
          resourcetype={this.resource.resource_type}
          webkit-playsinline="true"
          playsInline={true}
        ></WrappedMedium>
        {/* <Captions
          captions={this.state.currentQuiz.entire_record_setting.options}
        /> */}
        {/* <div className="count-time">11111111111</div> */}
        {this.state.showCaption &&
          "entire_record_setting" in this.state.currentQuiz &&
          this.state.currentQuiz.entire_record_setting.options.length > 0 && (
            <Captions
              captions={this.state.currentQuiz.entire_record_setting.options}
            />
          )}
        <div
          id="btn_course_play"
          style={{
            display: this.state.isShowHide,
          }}
        >
          <span
            className={this.state.playOtherPauseClassName}
            onClick={debounce(this.btnPlayClick)}
          ></span>
        </div>

        {(this.state.isShowProgressBar ||
          this.state.isShowProgressBarForEditor) && (
          <div className="container_course" id="container-course">
            {/* <div id="audio_play" className="btn_audio_play" data-turbolinks-permanent data-created="true">
            <div className="part_left">
              <div className="play-pause">
                <div className={this.state.playPauseClassName} onClick={this.btnPlayClick} style={{display: 'none'}}></div>
              </div>
            </div>
          </div> */}

            <div className="container-time">
              <div
                className="container-progress"
                id="container_progress"
                ref={(containerProgress) =>
                  (this.containerProgress = containerProgress)
                }
                onClick={this.containerProgressClick}
                onMouseUp={this.handleMouseEnd}
                onMouseLeave={this.handleMouseEnd}
                onMouseMove={this.handleMouseMove}
              >
                {this.state.interactionPoints.length > 0 &&
                  this.state.interactionPoints.map((point, index) => (
                    <div
                      className="break_point"
                      key={index}
                      style={{ left: point.left }}
                    ></div>
                  ))}

                <div
                  className="progress"
                  style={{ width: this.state.width }}
                  ref={(progress) => (this.progressBar = progress)}
                >
                  <div
                    className="handle"
                    onMouseDown={this.handleMouseStart}
                    onTouchStart={this.handleTouchStart}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchMove={this.handleTouchMove}
                  ></div>
                </div>
              </div>
              <div className="time">{this.state.showTime}</div>
            </div>
          </div>
        )}

        {this.state.currentQuiz.question_type === 1 ? (
          <MediaLessonQuizOption
            key={this.state.currentQuiz.id}
            ref={(answerOptions) => (this.answerOptions = answerOptions)}
            replayText={this.replayText()}
            hideReplayButton={!this.isAudioLesson()}
            playAudio={this.audioPlay}
            pauseAudio={this.audioPause}
            replay={this.replayAudio}
            isReplaying={isReplaying}
            recordTimes={this.recordTimes}
            pauseReplay={this.pauseReplayAudio}
            summitRecord={this.submitQuizRecord}
            promisifiedPlayEffectAudioWithReward={
              this.promisifiedPlayEffectAudioWithReward
            }
            setLessonRecord={this.setLessonRecord.bind(this)}
            operateSingleAnswerText={this.operateSingleAnswerText}
            isNoScore={this.state.currentQuiz.no_score}
            currentQuiz={this.state.currentQuiz}
            options={
              this.state.currentQuiz ? this.state.currentQuiz.options : null
            }
            indexEnable={
              this.state.currentQuiz
                ? this.state.currentQuiz.index_enable
                : false
            }
            try_round={this.state.currentQuiz.try_round}
          />
        ) : (
          <MediaLessonFillQuiz
            ref={(answerFills) => (this.answerFills = answerFills)}
            key={this.state.currentQuiz.id}
            replayText={this.replayText()}
            playAud={this.audioPlay}
            pauseAud={this.audioPause}
            replayAud={this.replayAudio}
            currentQuiz={this.state.currentQuiz}
            isReplaying={isReplaying}
            submitFillRecord={this.submitQuizRecord}
            promisifiedPlayEffectAudioWithReward={
              this.promisifiedPlayEffectAudioWithReward
            }
            setLessonRecord={this.setLessonRecord.bind(this)}
            answer={this.state.currentQuiz.answer}
            recordTimes={this.recordTimes}
            try_round_fill={this.state.currentQuiz.try_round}
            keyborderTrigger={
              this.props.awesome_keyboard_trigger
                ? this.props.awesome_keyboard_trigger
                : ""
            }
            keyboardInput={
              this.props.awesome_keyboard_input
                ? this.props.awesome_keyboard_input
                : ""
            }
            showAlertForMiniProgram={this.handleRemoveAlert}
            operateSingleAnswerText={this.operateSingleAnswerText}
          />
        )}
        {this.state.isPoetryDialog && (
          <DownAppTip
            onPoetryDialog={this.onPoetryDialog.bind(this)}
            is_show_qrcode={this.props.is_show_qrcode}
            support_recorder_tips={this.props.support_recorder_tips}
          ></DownAppTip>
        )}
        {/* {this.state.showRecordBar && !this.props.is_not_support_recorder && ( */}
        {this.state.showRecordBar && (
          <>
            {this.state.isMuilFirst && !this.state.hideReady ? (
              <CountDownTime
                key={this.state.currentQuiz.id + "readygo"}
                endTime={new Date(new Date().getTime() + 5 * 1000)}
                overText="ready go!"
                onEnd={() => {
                  this.setState(
                    {
                      hideReady: true,
                      showEndtime:
                        new Date().getTime() +
                        (this.state.limitTimes + 1) * 1000,
                    },
                    () => {}
                  );
                }}
              >
                {({ d, h, m, s }) => {
                  return (
                    <div className="ready-go">
                      <audio
                        src="https://upload.fireflybaby.cn/race/start_race.mp3"
                        autoPlay
                      ></audio>
                      <img src={ready_go_back}></img>
                      <img
                        src={ready_go + "?" + this.state.currentQuiz.id}
                      ></img>
                      {/* <div className="icon_back">
                      <img src={ready_go}></img>
                    </div> */}
                    </div>
                  );
                }}
              </CountDownTime>
            ) : (
              ""
            )}
            {this.state.hideReady ? (
              this.state.showMuilResult ? (
                <CountDownTime
                  key={this.state.currentQuiz.id + "result"}
                  endTime={new Date(new Date().getTime() + 7 * 1000)}
                  overText="继续学习!"
                  onEnd={() => {
                    this.setState(
                      {
                        showMuilResult: false,
                        showRecordBar: false,
                        showRecordCountDown: false,
                      },
                      () => {
                        this.setLessonRecord2(this.state.nextLessRecord);
                        this.delayExcute();
                      }
                    );
                  }}
                >
                  {({ d, h, m, s }) => {
                    return (
                      <div className="muil-result">
                        <div
                          className={`muil-result-center ${
                            this.state.race_quizzes_result.correct_count /
                              this.state.race_quizzes_result.total_count >=
                            0.75
                              ? "multiple-pass"
                              : " multiple-fail"
                          } `}
                        >
                          <div className="muil-result-back">
                            <div className="muil-result-quiz-count">
                              <span className="top">
                                <span className="top-count">
                                  {this.state.race_quizzes_result
                                    .correct_count || 0}
                                </span>
                                题
                              </span>
                              <span>答对</span>
                            </div>
                            <div className="muil-result-quiz-time muil-result-quiz-count">
                              <span className="top">
                                <span className="top-count">
                                  {this.state.race_quizzes_result.time || 0}
                                </span>
                                秒
                              </span>
                              <span>用时</span>
                            </div>
                            <div className="muil-result-quiz-moer muil-result-quiz-time muil-result-quiz-count">
                              <span className="top">
                                <span className="top-count">{`${
                                  this.state.race_quizzes_result.percent || 0
                                }\%`}</span>
                                的小朋友
                              </span>
                              <span>超过</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </CountDownTime>
              ) : (
                <>
                  {this.state.limitTimes ? (
                    <div className="count-time">
                      <CountDownTime
                        key={this.state.currentQuiz.id + "countTime"}
                        bindTime={this.changeTime.bind(this)}
                        endTime={
                          new Date(
                            this.state.showEndtime ||
                              new Date().getTime() +
                                (Number(this.state.limitTimes) + 1) * 1000
                          )
                        }
                        overText="答题结束"
                        onEnd={() => {
                          this.setState({ showEndtime: 0 }, () => {
                            this.answered
                              ? ""
                              : this.starAction
                              ? this.quizRecorderEL.audoComplete()
                              : this.submitRecordQuizRecord(
                                  this.state.currentQuiz,
                                  null
                                );
                          });
                        }}
                      >
                        {({ d, h, m, s }) => {
                          return (
                            <span className="timer">
                              {/* <audio
                                src="https://upload.fireflybaby.cn/race/count_down.mp3"
                                loop="loop"
                                autoPlay
                              ></audio> */}
                              <img src={count_time_icon}></img>
                              {/* <span>{h}</span>: */}
                              <span>{m}</span>:<span>{s}</span>
                            </span>
                          );
                        }}
                      </CountDownTime>
                    </div>
                  ) : (
                    ""
                  )}
                  <QuizRecorder
                    // startRecordBar={this.state.startRecordBar}
                    // onRecordingBar={this.state.onRecordingBar}
                    // endRecordBar={this.state.endRecordBar}
                    ref={(quizRecorderEL) =>
                      (this.quizRecorderEL = quizRecorderEL)
                    }
                    key={this.state.currentQuiz.id}
                    replayText={this.replayText()}
                    setThisAnswered={this.setThisAnswered.bind(this)}
                    // quizThis={this}
                    replayAudio={this.replayAudio}
                    isReplaying={isReplaying}
                    showRePlaying={
                      this.state.currentQuiz.timeout_value ? true : false
                    }
                    effectTime={this.effectTime}
                    recordTimes={this.recordTimes}
                    pauseReplay={this.pauseReplayAudio}
                    isLastQuiz={isLastQuiz}
                    babyId={this.state.babyId}
                    authToken={this.state.authToken}
                    quiz={this.state.currentQuiz}
                    quizId={this.state.currentQuiz.id}
                    onRecordStartAction={this.onRecordStartAction}
                    onRecordStopAction={this.onRecordStopAction}
                    onRecordCompleteAction={this.onRecordCompleteAction}
                    onRecordReStartAction={this.onRecordReStartAction}
                    isRemoveRecord={this.state.isRemoveRecord}
                    ravenMsg={this.props.raven_msg}
                    showCount={this.state.showCount}
                    try_round={this.state.currentQuiz.try_round || 1}
                    isNotSupportRecorder={this.props.is_not_support_recorder}
                    promisifiedPlayEffectAudioWithReward={
                      this.promisifiedPlayEffectAudioWithReward
                    }
                  />
                </>
              )
            ) : (
              ""
            )}
          </>
        )}
      </div>,
      <div
        className="mask_for_mini_program"
        key="alert_for_min_program"
        style={{
          display: this.state.displayAlert ? "block" : "none",
        }}
        onClick={this.preventPageClick}
      >
        {/* {this.state.showLastModel && <Popups {...this.state.lastData}></Popups>} */}
        <div
          className="alert_for_min_program"
          style={{ display: this.state.displayAlert ? "block" : "none" }}
        >
          <div className="content">请输入答案</div>
          <div className="button" onClick={() => this.handleRemoveAlert(false)}>
            确定
          </div>
        </div>
      </div>,

      // 古诗词结果页弹框数据显示
      <div
        className="poetry_result"
        key="poetry_result"
        style={{ display: this.state.poetryMediaLessonDisplay }}
      >
        {/* {this.state.showLastModel && <Popups {...this.state.lastData}></Popups>} */}
        <div className="result_page_wrapper">
          <img
            className="rank lazyload"
            src={dialog_stars[this.state.progressRank]}
            alt=""
          />
          <a
            className="go_study_report"
            href={
              this.resource.next_type == "StudyReport"
                ? this.resource.next_url + "&show_poster=true&ref=finish_study"
                : this.resource.next_url
            }
            target="_self"
          >
            {hint}
          </a>
          <div className="progress_lesson">
            <div
              className="progress_line"
              style={{
                width:
                  this.state.poetryProgress &&
                  (this.state.poetryProgress.length == 2
                    ? "50%"
                    : this.state.poetryProgress.length > 4
                    ? "80%"
                    : null),
              }}
            ></div>
            <div className="progress_wrapper">
              {this.state.poetryProgress &&
                this.state.poetryProgress.map((progress, index) => (
                  <div className="progress_block" key={index}>
                    {progress.rank == null ? (
                      <div className="passed_lesson lightgray"></div>
                    ) : (
                      <img
                        className="passed_lesson lazyload"
                        src={icon_quiz_pass}
                        alt=""
                      />
                    )}
                    <div
                      style={{
                        color: progress.rank == null ? null : "#49c114",
                        width: progress.name.length >= 5 ? "60px" : null,
                      }}
                      className="lesson_title"
                    >
                      {progress.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>,
    ];
  }
}

class Captions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCaption:
        "photo_url" in props.captions[0] ? props.captions[0].photo_url : "",
    };
  }

  findCurrentCaption(currentTime) {
    const { captions } = this.props;
    printStub(currentTime);
    let captionPicsLength = captions.length;
    return new Promise((resolve, reject) => {
      let currentCaption = captions.find((caption) => {
        if (
          caption.start_time <= Math.floor(currentTime) &&
          Math.floor(currentTime) <= caption.end_time
        ) {
          resolve(caption);
        }
      });
      if (currentTime > captions[captionPicsLength - 1].end_time) {
        clearInterval(this.handleCaptionChangeInterval);
        resolve(null);
      }
    });
  }

  playCaptionsAndBGM() {
    let currentCaptionTime = Date.now();
    // this.setState({
    //   currentCaption
    // })
    this.handleCaptionChangeInterval = setInterval(() => {
      this.findCurrentCaption
        .call(this, (Date.now() - currentCaptionTime) / 1000)
        .then((currentCaption) => {
          printStub("currentCaption", currentCaption);
          if (currentCaption && "photo_url" in currentCaption) {
            this.setState(
              {
                currentCaption: currentCaption.photo_url,
              },
              () => {
                printStub("currentCaption", this.state.currentCaption);
              }
            );
          }
        })
        .catch((err) => {
          clearInterval(this.handleCaptionChangeInterval);
          printStub(err);
        });
    }, 500);
  }

  componentDidMount() {
    this.playCaptionsAndBGM.call(this);
  }
  componentWillUnmount() {
    clearInterval(this.handleCaptionChangeInterval);
    this.handleCaptionChangeInterval = null;
  }
  render() {
    const { captions } = this.props;

    return (
      <div className="box_photo">
        <img
          src={this.state.currentCaption}
          className="box_photo_img"
          style={{ zIndex: 998 }}
        />
      </div>
    );
  }
}
Captions.propTypes = {
  captions: PropTypes.array.isRequired,
};

export default class MediaLesson extends React.Component {
  constructor(props) {
    super(props);
    console.log("MediaLesson_props", props);
    this.state = {
      hasAwesomeKeyboard: false,
    };
  }

  componentWillMount() {
    let self = this;
    wx.miniProgram.getEnv(function (res) {
      if (res.miniprogram) {
        printStub(document.body.clientWidth);
        if (document.body.clientWidth >= 768) {
          return;
        }
        self.setState({
          hasAwesomeKeyboard: true,
        });
      }
    });
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      BWDetect(10, () => {
        request({
          url: "/net_monitors",
          method: "POST",
          data: { url: window.location.href },
          headers: csrfHeaders,
        });
      });
    });
  }

  render() {
    const MediaLessonHasKeyboard = HasAwesomeKeyboard(MediaLessonItem, {
      hasAwesomeKeyboard: this.state.hasAwesomeKeyboard,
      forcesToLeft: true,
    });
    return (
      <div>
        <MediaLessonHasKeyboard {...this.props} />
      </div>
    );
  }
}
MediaLesson.propTypes = {
  lesson_record_url: PropTypes.string.isRequired,
  lesson_record: PropTypes.object,
  resource_url: PropTypes.string.isRequired,
  quizzes: PropTypes.array.isRequired,
};
