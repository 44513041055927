import React from "react";
import PropTypes from "prop-types";

import ComponentProxy from "../core/ComponentProxy";
import ShareHintDialogProxy from "../shared/ShareHintDialog";

import "./GiftsDialog.scss";

class GiftsDialog extends React.Component {
  onInviteFriends = (e) => {
    // TODO check whether user authenticated
    new ShareHintDialogProxy(
      `<div style="font-size: 18px;font-weight: 700;">每成功邀请3人关注公众号<br>奖励1点</div>`
    ).open();

    this.props.onClose();
  };

  render() {
    return (
      <div className="comp-gifts-dialog">
        <div className="tips_box">
          <div className="tips_main_content">
            <div className="box_share_points">
              <div className="title">赚取点数兑换免费听</div>
              <div className="box_points">
                <ul>
                  <li className="icon_share">
                    <a
                      className="invite_friends_listen"
                      onClick={this.onInviteFriends}
                      href="javascript:void(0);"
                    >
                      <span>+1点</span>
                      <p>每成功邀请3人关注公众号</p>
                    </a>
                  </li>
                  <li className="icon_recommend">
                    <a href="/invite_guests">
                      <span>+10点</span>
                      <p>每推荐1位嘉宾来分享经验 </p>
                    </a>
                  </li>
                  <li className="icon_participate">
                    <a href="/mini_exams/home?exam_type=school_route">
                      <span>+1点</span>
                      <p>每邀请3位朋友完成教育路线测评 </p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="close_btn_right" onClick={this.props.onClose}>
              &times;
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GiftsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default class GiftsDialogProxy extends ComponentProxy {
  createComponent() {
    return <GiftsDialog onClose={() => this.cancel()} />;
  }
}
