import React, { useMemo } from "react";
import { useSelector } from "../../utils/react-global-state";
import Banner1 from "new_experience/banner_pic1.png";
import Banner2 from "new_experience/banner_pic2.png";
import Banner3 from "new_experience/banner_pic3.png";
import Banner4 from "new_experience/banner_pic4.png";
import Banner5 from "new_experience/banner_pic5.png";

const bannerMap = [Banner1, Banner2, Banner3, Banner4, Banner5];

const GuestInfo = React.memo(function GuestInfo() {
  const experienceGuestName = useSelector((state) => state.experienceGuestName);
  const experienceGuestInfo = useSelector((state) => state.experienceGuestInfo);
  const experienceGuestBrief = useSelector(
    (state) => state.experienceGuestBrief
  );
  const experienceGuestAvatar = useSelector(
    (state) => state.experienceGuestAvatar
  );

  return (
    <div className="guest_info">
      <img src={experienceGuestAvatar} alt="" />
      <div className="info_box">
        <span className="top">
          <span className="strong"> {experienceGuestName}</span>
          {experienceGuestInfo && <span>{`（${experienceGuestInfo}）`}</span>}
        </span>
        <span className="bottom">{experienceGuestBrief}</span>
      </div>
    </div>
  );
});

const ExperienceBannerInfo = React.memo(() => {
  const experienceName = useSelector((state) => state.experienceName);
  const getRandomNumber = useSelector((state) => state.getRandomNumber);
  const getRandomNum = useMemo(() => getRandomNumber(1, 5), [getRandomNumber]);
  return (
    <div
      className="banner_info"
      style={{ backgroundImage: `url(${bannerMap[getRandomNum - 1]})` }}
    >
      <h2 className="title">{experienceName}</h2>
      <GuestInfo />
    </div>
  );
});

export default ExperienceBannerInfo;
