import React, { Component } from "react";
import SolveIdea from "./SolveIdea.jsx";
import "./ReportQuizList.scss";

export default class QuizList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const quiz = this.props.quiz;
    const answer_status =
      quiz.correct_answer == quiz.error_answer &&
      quiz.correct_url == quiz.error_url;
    return (
      <div className="practice_report_content">
        {answer_status ? (
          <div className="answer_correct">回答正确</div>
        ) : (
          <div className="answer_error">回答错误</div>
        )}
        <div className="practice_report_info">
          <div className="option_title">
            <span>{quiz.question}</span>
          </div>
          {quiz.question_photo_url != null ? (
            <div className="question_pic">
              <img src={quiz.question_photo_url} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="report_answer">
          <div className={answer_status ? "respond_answer" : "answer_fail"}>
            <p>回答：</p>
            {quiz.error_url != "" && quiz.error_url != null ? (
              <img src={quiz.error_url} />
            ) : (
              quiz.error_answer
            )}
          </div>
          <div className="right_answer">
            <p>正确答案：</p>
            {quiz.correct_url != "" && quiz.correct_url != null ? (
              <img src={quiz.correct_url} />
            ) : (
              quiz.correct_answer
            )}
          </div>
        </div>
        {quiz.hint && <SolveIdea hint={quiz.hint} />}
      </div>
    );
  }
}
