import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { formatAudioTime } from "../utils/formatAudioTime";
import ShareHintDialogProxy from "../shared/ShareHintDialog";
import UserAgent from "../utils/UserAgent";
import ComponentProxy from "../core/ComponentProxy";

import "./ExperienceAlbumSection.scss";

class ExperienceAlbumSectionAudio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audio: props.audio,
      invisible: props.invisible,
    };
    this.global_listened = true;
  }

  componentDidMount() {
    this.loadAudioInfo();
  }

  loadAudioInfo() {
    $.ajax({
      url: `${this.state.audio.src}?avinfo`,
      method: "GET",
      success: (data) => {
        let duration = parseInt(data.format.duration);
        this.setState({
          durationStr: formatAudioTime(duration),
        });
      },
    });
  }

  handleListenedOrderUrl = (e) => {
    // console.log("索引: ", this.props.idx)
    e.preventDefault();
    let listened = this.props.listened;
    let listened_experience_order_url = this.props
      .listened_experience_order_url;

    console.log(
      "listened: " +
        listened +
        "," +
        "listened_url: " +
        listened_experience_order_url
    );

    console.log("121212", this.global_listened);

    if (listened != true && this.global_listened) {
      $.ajax({
        url: listened_experience_order_url,
        method: "POST",
        success: (data) => {
          this.global_listened = false;
        },
      });
    }
  };

  render() {
    const audio = this.state.audio;
    const invisible = this.state.invisible;

    return (
      <li className={`audio-wrapper clearfix ${invisible ? "invisible" : ""}`}>
        <a href="javascript:void(0);">
          <div className="user_topic_content">
            <div className="user_headline user_head">
              <img src={audio.user_photo_url} />
            </div>
            <div className="topic_content clearfix pseudo-clear">
              <audio src={audio.src} data-title={audio.name}></audio>
              <div className="part-right">
                <span className="time">{this.state.durationStr}</span>
              </div>

              <div className="user_topic_info">
                <h3>{audio.user_name}</h3>
                <div
                  className="topic_share_title"
                  onClick={this.handleListenedOrderUrl}
                >
                  <div className="icon_audio">1</div>
                  <div className="audio_name">{audio.name}</div>

                  <span className="overlay-1"></span>
                  <span className="overlay-2"></span>
                  <span className="overlay-3"></span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    );
  }
}

export default class ExperienceAlbumSection extends React.Component {
  constructor(props) {
    super(props);
  }

  inviteTalkClick = (e) => {
    new ShareHintDialogProxy(
      "点击右上角分享给朋友<br><span>邀请朋友提交经验，通过审核后，你与朋友可各得1点奖励。</span>"
    ).open();
  };

  render() {
    const section = this.props.section;
    const countShown = 3;
    const audioListItems = section.audios.map((audio, i) => {
      return (
        <ExperienceAlbumSectionAudio
          audio={audio}
          invisible={i >= countShown}
          key={audio.id}
          listened_experience_order_url={
            this.props.listened_experience_order_url
          }
          listened={this.props.listened}
          idx={i}
        />
      );
    });

    return (
      <div className="comp-experience-album-section">
        <div className="experience_topic_box white_vessel">
          <h3 className="topic_title">{section.name}</h3>

          {audioListItems.length > 0 ? (
            <ul className="experience-audios">
              {audioListItems.slice(0, countShown)}

              {audioListItems.length > countShown ? (
                <li>
                  <span className="btn-more">更多</span>
                </li>
              ) : (
                ""
              )}

              {audioListItems.slice(countShown)}
            </ul>
          ) : (
            <div className="school_evaluate">
              <p dangerouslySetInnerHTML={{ __html: section.description }}></p>
            </div>
          )}

          {UserAgent.isWechat() ? (
            <div>
              <div className="experience_talk_box">
                <div className="audio_talk">
                  <a href={section.audio_rec_url}>说说我的经验</a>
                </div>
                <div className="invite_talk">
                  <a href="javascript:void(0);" onClick={this.inviteTalkClick}>
                    邀请朋友说说
                  </a>
                </div>
              </div>
              {!this.props.show_description ? (
                ""
              ) : (
                <div className="point_award">
                  自己或是邀请朋友提交的经验审核通过，都可获得1个点数奖励 <br />
                  1个点数 = 1个讲座/圆桌主题（少数例外）
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

ExperienceAlbumSection.propTypes = {
  section: PropTypes.object.isRequired,
};
