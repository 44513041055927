import React, { Component } from "react";
import PropTypes from "prop-types";
import { chineseMonthDayDate } from "../utils/formatAudioTime";
import iconUp from "icon_up.png";
import iconDown from "icon_down.png";
import iconArrowRight from "thick_arrow_right.png";
import "./KbDimensionUpdate.scss";

const LeftLine = ({ lineRef }) => {
  return <div className="left_line" ref={lineRef} />;
};

const DynamicContent = ({ record }) => {
  const { courses, updated_at, ratio_updated } = record;
  const preScore = `${record.prev_score.major}.${record.prev_score.minor}`;
  const Score = `${record.score.major}.${record.score.minor}`;
  console.log("preScore: ", preScore + "," + "Score: ", Score);
  return (
    <div className="dynamic_contaniner">
      <div className="dynamic_box">
        <div className="dynamic_date">{chineseMonthDayDate(updated_at)}</div>
        <div className="dynamic_text">
          {ratio_updated && (
            <div className="completion">
              <strong>完整度：</strong>
              <div className="skill_value_direct">
                <span>+{ratio_updated / 100}%</span>
              </div>
            </div>
          )}
          {record.score && (
            <div className="skill_value">
              <strong>能力水平：</strong>
              <div className="skill_value_direct">
                <span
                  className={
                    record.prev_score.minor > record.score.minor
                      ? "decrease_value"
                      : ""
                  }
                >
                  {record.score.minor > 90 ? `${record.score.major}.9+` : Score}
                </span>
                {record.prev_score.minor > record.score.minor ? (
                  <img src={iconDown} />
                ) : (
                  <img src={iconUp} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="dynamic_content_lists">
        {courses.map((course, index) => (
          <div className="content_list" key={index}>
            <div className="title">{course.name}</div>
            {course.kb_lessons.map((lesson, idx) => (
              <div className="sub_list" key={idx}>
                <div className="sub_title">{lesson.name}</div>
                <div className="understand_level">
                  {lesson.prev_state}
                  <img src={iconArrowRight} alt="" />
                  {lesson.state}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default class KbDimensionUpdate extends Component {
  componentDidMount() {
    let dimension_update_id = this.kb_update;
    let left_line_id = this.leftLine;

    let total_height;
    let totalHeightPlus;
    if (dimension_update_id) {
      total_height = dimension_update_id.offsetHeight;
    }

    totalHeightPlus = total_height + 25;
    left_line_id.style.cssText = `height: ${totalHeightPlus}px;`;
    // console.log('total_height: ', total_height);
    // console.log('totalHeightPlus: ', totalHeightPlus);
  }
  render() {
    console.log(this.props);
    const { kb_progress_updated_record } = this.props;
    return (
      <div
        className="comp_kb_dimension_update"
        ref={(kb_update) => (this.kb_update = kb_update)}
      >
        <LeftLine lineRef={(l) => (this.leftLine = l)} />
        {kb_progress_updated_record.map((record, index) => (
          <DynamicContent record={record} key={index} />
        ))}
      </div>
    );
  }
}

KbDimensionUpdate.propTypes = {};
