import React, { Component } from "react";
import PropTypes from "prop-types";
import ExperienceList from "../utils/experience_list/ExperienceList";

import "./PromotionActivityEndDialog.scss";
import icon_xiaoya from "activities/icon_xiaoya.png";

export default class PromotionActivityEndDialog extends Component {
  constructor(props) {
    super(props);
    console.log("PromotionActivityEndDialog", props);
    this.state = {
      handleCloseDialog: true,
    };
  }

  handleCloseDialog = () => {
    this.setState({
      handleCloseDialog: false,
    });
  };

  render() {
    const { handleCloseDialog } = this.state;
    return handleCloseDialog ? (
      <div className="promotion_activity_end">
        <div className="overlay" />
        <div className="experience_container_wait">
          <div className="title">
            <img src={icon_xiaoya} alt="" />
            <p>
              本次经验讲座报名<span>已结束</span>，请等待音频剪辑上线
            </p>
          </div>
          <div className="close_button_right" onClick={this.handleCloseDialog}>
            &times;
          </div>
          <div className="guide_icon">更多经验讲座</div>
          <ExperienceList />
        </div>

        <a className="more_experience" href="#">
          <span>更多经验讲座</span>
        </a>
      </div>
    ) : (
      ""
    );
  }
}

PromotionActivityEndDialog.propTypes = {};
