import React from "react";

import ReactMarkdown from "react-markdown";

import arrowDown from "arrow-down.png";
import arrowUp from "arrow-up.png";

import "../utils/ReactMarkDown.scss";
import "./Note.scss";

export default class Note extends React.Component {
  componentDidMount() {
    function switchQA(e) {
      let positionNode = e.currentTarget;
      if (positionNode.tagName === "H4") {
        let toSwitchNodeOne = positionNode.nextElementSibling;
        let toSwitchNodeTwo = positionNode.nextElementSibling.nextElementSibling
          ? positionNode.nextElementSibling.nextElementSibling
          : "";

        if (
          toSwitchNodeOne.tagName === "P" ||
          toSwitchNodeOne.tagName === "UL" ||
          toSwitchNodeOne.nodeName === "OL"
        ) {
          if (
            toSwitchNodeOne.style.display === "none" ||
            toSwitchNodeOne.style.opacity == "0"
          ) {
            positionNode.className = "handleToUp";
            if (toSwitchNodeOne.style.display) {
              toSwitchNodeOne.style.display = "block";
            }
            if (toSwitchNodeOne.style.opacity) {
              toSwitchNodeOne.style.opacity = "1";
            }
            if (
              toSwitchNodeOne.nextElementSibling &&
              toSwitchNodeOne.nextElementSibling.tagName === "HR"
            ) {
              toSwitchNodeOne.nextElementSibling.style.marginTop = "30px";
            }
          } else {
            positionNode.className = "handleToDown";
            if (toSwitchNodeOne.style.display) {
              toSwitchNodeOne.style.display = "none";
            }
            if (toSwitchNodeOne.style.opacity) {
              toSwitchNodeOne.style.opacity = "0";
            }
            if (
              toSwitchNodeOne.nextElementSibling &&
              toSwitchNodeOne.nextElementSibling.tagName === "HR"
            ) {
              toSwitchNodeOne.nextElementSibling.style.marginTop = "0";
            }
          }
        }
        if (toSwitchNodeTwo) {
          if (
            toSwitchNodeTwo.tagName === "P" ||
            toSwitchNodeTwo.tagName === "UL" ||
            toSwitchNodeOne.nodeName === "OL"
          ) {
            if (
              toSwitchNodeTwo.style.display === "none" ||
              toSwitchNodeOne.style.opacity == "0"
            ) {
              if (toSwitchNodeTwo.style.display) {
                positionNode.className = "handleToUp";
                toSwitchNodeOne.style.display = "block";
                toSwitchNodeTwo.style.display = "block";
              }
              if (toSwitchNodeTwo.style.opacity) {
                toSwitchNodeOne.style.opacity = "1";
                toSwitchNodeTwo.style.opacity = "1";
              }
              if (
                toSwitchNodeTwo.nextElementSibling &&
                toSwitchNodeTwo.nextElementSibling.tagName === "HR"
              ) {
                toSwitchNodeTwo.nextElementSibling.style.marginTop = "30px";
              }
            } else {
              positionNode.className = "handleToDown";
              if (toSwitchNodeTwo.style.display) {
                toSwitchNodeOne.style.display = "none";
                toSwitchNodeTwo.style.display = "none";
              }
              if (toSwitchNodeTwo.style.opacity) {
                toSwitchNodeOne.style.opacity = "0";
                toSwitchNodeTwo.style.opacity = "0";
              }
              if (
                toSwitchNodeTwo.nextElementSibling &&
                toSwitchNodeTwo.nextElementSibling.tagName === "HR"
              ) {
                toSwitchNodeTwo.nextElementSibling.style.marginTop = "0";
              }
            }
          }
        }
      }
    }
    let markDownStrong = [
      ...document.getElementById("common_problem").getElementsByTagName("h4"),
    ];
    if (markDownStrong) {
      markDownStrong.forEach((item, index) => {
        let positionNode = item;
        positionNode.onclick = switchQA;
        let toSwitchNodeOne = positionNode.nextElementSibling;
        let toSwitchNodeTwo = positionNode.nextElementSibling.nextElementSibling
          ? positionNode.nextElementSibling.nextElementSibling
          : "";
        if (positionNode.tagName === "H4") {
          positionNode.className = "handleToDown";
          if (
            toSwitchNodeOne.nodeName === "P" ||
            toSwitchNodeOne.nodeName === "UL" ||
            toSwitchNodeOne.nodeName === "OL"
          ) {
            toSwitchNodeOne.style.cssText = "display:none;";
          }
          if (toSwitchNodeTwo) {
            if (
              toSwitchNodeTwo.nodeName === "P" ||
              toSwitchNodeTwo.nodeName === "UL" ||
              toSwitchNodeOne.nodeName === "OL"
            ) {
              positionNode.style.cssText = `backgroundImage:url(${arrowDown});`;
              toSwitchNodeTwo.style.cssText = "display:none;";
              toSwitchNodeOne.style.cssText = "display:none;";
            }
          }
        }
        if (index == markDownStrong.length - 1) {
          if (
            toSwitchNodeOne.nodeName === "P" ||
            toSwitchNodeOne.nodeName === "UL" ||
            toSwitchNodeOne.nodeName === "OL"
          ) {
            positionNode.style.cssText = `backgroundImage:url(${arrowDown});`;
            toSwitchNodeOne.style.cssText = "opacity:0;";
          }
          if (toSwitchNodeTwo) {
            if (
              toSwitchNodeTwo.nodeName === "P" ||
              toSwitchNodeTwo.nodeName === "UL" ||
              toSwitchNodeOne.nodeName === "OL"
            ) {
              positionNode.style.cssText = `backgroundImage:url(${arrowDown});`;
              toSwitchNodeTwo.style.cssText = "opacity:0;";
              toSwitchNodeOne.style.cssText = "opacity:0;";
            }
          }
        }
      });
    }
  }

  render() {
    return (
      <div className="comp-check-note">
        <div className="markdown_content" id="common_problem">
          <ReactMarkdown source={this.props.description} />
        </div>
      </div>
    );
  }
}
