import React from "react";
import PropTypes from "prop-types";

class PlayerProgressBar extends React.Component {
  render() {
    const {
      handleMouseEnd,
      handleMouseMove,
      containerProgressClick,
      progressPoints,
      handleMouseStart,
      handleTouchStart,
      handleTouchEnd,
      handleTouchMove,
      audioLeftTime,
      audioCurrentTime,
      playerStateCssClass,
      btnPlayerClick,
      sectionName,
      audioPlayProgress,
    } = this.props;
    return (
      <div className="player_progress_bar">
        <div className="flex_box">
          <div
            className={playerStateCssClass}
            onClick={btnPlayerClick}
            id="btn_player_click"
          />
          {/* <div className="played_time">{audioCurrentTime}</div> */}
          <div className="player_name_progress">
            <div className="name">{sectionName}</div>
            <div className="progress_time">
              <div className="player_duration_time">{audioCurrentTime}</div>
              <div
                id="_progress_bar_"
                className="progress_bar"
                onMouseUp={handleMouseEnd}
                onMouseLeave={handleMouseEnd}
                onMouseMove={handleMouseMove}
                onClick={containerProgressClick}
                ref={(r) => (this.bar = r)}
              >
                {progressPoints.length > 0 &&
                  progressPoints.map((point, index) => (
                    <div
                      className="break_point"
                      key={index}
                      style={{ left: point.left }}
                    />
                  ))}

                <div
                  className="progress_bar_status"
                  style={{ width: `${audioPlayProgress}%` }}
                >
                  <span
                    className="handler"
                    onMouseDown={handleMouseStart}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onTouchMove={handleTouchMove}
                  />
                </div>
              </div>
              <div className="player_duration_time">{audioLeftTime}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PlayerProgressBar.propTypes = {
  handleMouseEnd: PropTypes.func,
  handleMouseMove: PropTypes.func,
  containerProgressClick: PropTypes.func,
  progressPoints: PropTypes.array,
  handleMouseStart: PropTypes.func,
  handleTouchStart: PropTypes.func,
  handleTouchEnd: PropTypes.func,
  handleTouchMove: PropTypes.func,
  audioCurrentTime: PropTypes.string,
  audioLeftTime: PropTypes.string,
  playerStateCssClass: PropTypes.string,
  btnPlayerClick: PropTypes.func,
  sectionName: PropTypes.string,
  audioPlayProgress: PropTypes.number,
};

export default PlayerProgressBar;
