/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  createRef,
  useMemo,
} from "react";
import {
  MyProvider,
  CreateStore,
  useSelector,
} from "../utils/react-global-state";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import { ToastManager } from "../utils/Toast";
import ListContainer from "../utils/ListContainer/ListContainer.jsx";
import { ReportQuizItem } from "../utils/report_quiz_item/ReportQuizItem";
import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";
import "./QuestionBook.scss";
import { onNavigate } from "../utils/helpers";

export const ADAPTER_MAP = {
  SYNC_PRACTICE: 1,
  DEFAULT: 2,
};

const courseAndLessonSelectorAdapter = (selector, course) => {
  if (selector === ADAPTER_MAP.SYNC_PRACTICE) {
    return {
      lesson: course.kb_lesson,
      course: course.kb_course,
    };
  }
  if (selector === ADAPTER_MAP.DEFAULT) {
    return {
      lesson: course.media_lesson,
      course: course.media_course,
    };
  }
};

const QuestionBook = (props) => {
  const { examination_url, revisal_url, wrong_quiz_sets } = props;
  console.log(props);
  const onTabClick = useCallback((e, activeTabId) => {}, []);
  const isSyncPractice = useSelector((state) => state.isSyncPractice);

  return (
    <div className="comp_question_book">
      <Tabs defaultAction={onTabClick} styles="tabs">
        <TabPane
          tab={isSyncPractice ? "未掌握知识点" : "未掌握考点"}
          styles="tab"
          activeStyle="active"
        >
          {wrong_quiz_sets.length > 0 ? (
            <QuizzesReport wrongQuizzes={wrong_quiz_sets} />
          ) : (
            <NoneContent url={examination_url} />
          )}
        </TabPane>
        <TabPane tab="已订正" styles="tab" activeStyle="active">
          <CorrectQuizzes
            getQuizzesUrl={revisal_url}
            fallBackNoneContent={() => (
              <NoneContent
                url={examination_url}
                text={"已订正的错题，将会显示在这里"}
              />
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const CourseTitle = ({ course }) => {
  return (
    <p className="course_name">
      {course.grade
        ? `${course.name}（${course.grade}水平）`
        : `${course.name}`}
    </p>
  );
};

const QuizzesReport = ({ wrongQuizzes }) => {
  const isSyncPractice = useSelector((state) => state.isSyncPractice);
  return (
    <div className="report_container">
      <ListContainer
        list={wrongQuizzes}
        listItem={({ itemIndex: courseIndex, ...course }) => (
          <div className="media_course_container" key={courseIndex}>
            {!isSyncPractice && <CourseTitle course={course.course} />}
            <ListContainer
              list={course.lesson}
              listItem={({ itemIndex, ...lesson }) => (
                <SingleLesson lesson={lesson} key={itemIndex} course={course} />
              )}
            />
          </div>
        )}
      />
    </div>
  );
};

const ReportQuizzesList = ({ quizzesSets, quizzesSetIndex }) => {
  return (
    <ListContainer
      list={quizzesSets}
      listItem={({ itemIndex: setIndex, ...sets }) => {
        const quizSets = sets[quizzesSetIndex];
        return (
          <ListContainer
            key={setIndex}
            list={quizSets.quizzes}
            listItem={({ itemIndex: quizIndex, ...quiz }) => (
              <ReportQuizItem
                quiz={quiz}
                iddx={quizIndex}
                key={quizIndex}
                needIndex={true}
                needAnsweredStatus={true}
              />
            )}
          />
        );
      }}
    />
  );
};

const LessonKnowledgeConclusion = ({ quizzesSets, quizzesSetIndex }) => {
  const toLesson = (e, url) => {
    onNavigate(e, url);
  };
  return (
    <ListContainer
      list={quizzesSets}
      listItem={({ itemIndex: setIndex, ...sets }) => {
        const quizSets = sets[quizzesSetIndex];
        return (
          <React.Fragment key={setIndex}>
            {quizSets && quizSets.conclusion && (
              <LessonConclusion
                conclusion={quizSets.conclusion}
                key={`${setIndex}-mark`}
              />
            )}
            {quizSets && quizSets.LessonURL && (
              <div
                className="to_media_lesson"
                key={`${setIndex}-btn`}
                onClick={(e) => toLesson(e, quizSets.LessonURL)}
              >
                5分钟视频精讲
              </div>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

const LessonQuizzesTitle = ({
  onTitleClick,
  lesson: { id, wrong_quiz_url, name, quiz_count, practise_url },
  courseId,
  courseName,
  isClose,
  toPractice,
}) => {
  const isSyncPractice = useSelector((state) => state.isSyncPractice);
  if (isSyncPractice) {
    return (
      <div
        className="kb_bar_title"
        onClick={(e) => onTitleClick(e, courseId, id, wrong_quiz_url)}
      >
        <div className="title_wrapper">
          <span className="lesson_name">{`【${courseName}】${name}`}</span>
          <span className="quiz_count">{`（${quiz_count}道错题）`}</span>
        </div>
        <div className="right_wrapper">
          <img src={isClose ? arrowDown : arrowUp} alt="" />
          <div
            className="practice_button"
            onClick={(e) => toPractice(e, practise_url)}
          >
            类似题
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="bar_title"
      onClick={(e) => onTitleClick(e, courseId, id, wrong_quiz_url)}
    >
      <span className="lesson_name">{`${name}（${quiz_count}道错题）`}</span>
      <div className="wrapper">
        <div
          className="practice_button"
          onClick={(e) => toPractice(e, practise_url)}
        >
          错题练习
        </div>
        <img src={isClose ? arrowDown : arrowUp} alt="" />
      </div>
    </div>
  );
};

const SingleLesson = ({ lesson, course }) => {
  const isSyncPractice = useSelector((state) => state.isSyncPractice);
  const [quizzes, setQuizzes] = useState([]);
  const [isClose, setIsClose] = useState(true);

  const requestQuizzes = useCallback(
    (url, lessonID, courseID) => {
      const toast = ToastManager.showLoading("数据加载中...");
      return request({
        url: url,
        method: "GET",
        headers: csrfHeaders,
        params: !isSyncPractice
          ? {
              media_lesson_id: lessonID,
              media_course_id: courseID,
            }
          : {},
      })
        .then((res) => {
          console.log(res.data);
          setQuizzes((quizzes) => {
            const newQuiz = {};
            newQuiz[`${courseID}-${lessonID}`] = {
              quizzes: res.data.quizzes,
              conclusion: res.data.conclusion,
              LessonURL: res.data.media_lesson_url,
            };
            return [...quizzes, newQuiz];
          });
          toast.cancel();
        })
        .catch((err) => {
          console.log(err);
          toast.cancel();
        });
    },
    [isSyncPractice]
  );

  const getQuizzes = useCallback(
    (e, courseID, lessonID, url) => {
      setIsClose((isClose) => !isClose);

      const isReadyRequest =
        quizzes.findIndex((item) => {
          const quizzesSet = item[`${courseID}-${lessonID}`];
          return !!quizzesSet;
        }) !== -1;

      if (isReadyRequest) {
        return;
      }
      requestQuizzes(url, lessonID, courseID);
    },
    [quizzes, requestQuizzes]
  );

  const toPractice = useCallback((e, url) => {
    e.preventDefault();
    e.stopPropagation();
    onNavigate(e, url);
  }, []);

  return (
    <div className="media_lesson_container" id={lesson.id}>
      <LessonQuizzesTitle
        onTitleClick={getQuizzes}
        lesson={lesson}
        courseName={course.course.name}
        courseId={course.course.id}
        isClose={isClose}
        toPractice={toPractice}
      />
      <div className="quizzes_container">
        {!isClose && (
          <React.Fragment>
            <ReportQuizzesList
              quizzesSets={quizzes}
              quizzesSetIndex={`${course.course.id}-${lesson.id}`}
            />
            {!isSyncPractice && (
              <LessonKnowledgeConclusion
                quizzesSets={quizzes}
                quizzesSetIndex={`${course.course.id}-${lesson.id}`}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const LessonConclusion = ({ conclusion }) => {
  return (
    <div className="knowledge_item_des">
      <h3 className="title">
        <span>知识点讲解：</span>
      </h3>
      <div className="knowledge_desc_content">
        <ReactMarkdown source={conclusion} />
      </div>
    </div>
  );
};

const CorrectQuizzes = ({ getQuizzesUrl, fallBackNoneContent }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [isNoContent, setIsNoContent] = useState(false);
  const [quizCount, setQuizCount] = useState(0);
  const page = useRef(1);
  const quizzesContainerRef = createRef();
  const screenHeight = useMemo(() => document.body.clientHeight, []);

  const doGetQuizzesRequest = useCallback((getQuizzesUrl, currentPage) => {
    return request({
      url: getQuizzesUrl,
      method: "GET",
      headers: csrfHeaders,
      params: {
        per: 20,
        page: currentPage,
      },
    });
  }, []);

  const onScrollHandle = useCallback(
    (event) => {
      const clientHeight = event.target.clientHeight;
      const scrollHeight = event.target.scrollHeight;
      const scrollTop = event.target.scrollTop;
      const isBottom = clientHeight + scrollTop === scrollHeight;
      if (isBottom) {
        console.log("isBottom");
        const toast = ToastManager.showLoading("数据加载中...");
        doGetQuizzesRequest(getQuizzesUrl, page.current)
          .then((res) => {
            const againQuizzes = res.data.quizzes;
            if (againQuizzes.length > 0) {
              page.current++;
              setQuizzes((quizzes) => [...quizzes, ...againQuizzes]);
            }
            toast.cancel();
          })
          .catch((err) => {
            console.log(err);
            toast.cancel();
          });
      }
    },
    [doGetQuizzesRequest, getQuizzesUrl]
  );

  useEffect(() => {
    // 获取题目
    if (getQuizzesUrl) {
      const toast = ToastManager.showLoading("数据加载中...");
      doGetQuizzesRequest(getQuizzesUrl, page.current)
        .then((res) => {
          const quizzes = res.data.quizzes;
          console.log(res.data);
          if (quizzes.length > 0) {
            page.current++;
            setQuizzes((preQuizzes) => [...preQuizzes, ...quizzes]);
            setQuizCount(res.data.quiz_count);
          } else {
            setIsNoContent(true);
          }
          toast.cancel();
        })
        .catch((err) => {
          alert("获取数据出错");
          console.log(err);
          toast.cancel();
        });
    }
  }, [doGetQuizzesRequest, getQuizzesUrl]);

  useEffect(() => {
    if (page.current === 2) {
      // 设置触底请求
      if (quizzesContainerRef.current) {
        quizzesContainerRef.current.addEventListener("scroll", onScrollHandle);
      }
    }
  }, [onScrollHandle, quizzesContainerRef]);

  if (isNoContent) {
    return fallBackNoneContent();
  }

  if (quizzes.length > 0) {
    return (
      <div
        className="correct_quizzes_container"
        style={{
          maxHeight: screenHeight - 55,
        }}
        ref={quizzesContainerRef}
        id="correct_quizzes_container"
      >
        <div className="quizzes_count">{`共${quizCount}题：`}</div>
        {quizzes.map((quiz, iddx) => (
          <ReportQuizItem
            quiz={quiz}
            iddx={iddx}
            key={iddx}
            needIndex={false}
            needAnsweredStatus={true}
          />
        ))}
      </div>
    );
  }

  return null;
};

const NoneContent = (props) => {
  const buttonClick = () => {
    window.location.href = props.url;
  };
  const text = props.text || "暂无错题，请前往练习";
  return (
    <div className="none_content">
      <div className="icon"></div>
      <div className="text">{text}</div>
      <div className="button" onClick={buttonClick}>
        前往练习
      </div>
    </div>
  );
};

const QuestionBookProvider = (props) => {
  const buildNewSetsGroup = props.wrong_quiz_sets.map((set) => {
    return courseAndLessonSelectorAdapter(
      props.quizSetsType || ADAPTER_MAP.DEFAULT,
      set
    );
  });

  return (
    <MyProvider
      store={
        new CreateStore(
          {
            quizSetsType: props.quizSetsType || ADAPTER_MAP.DEFAULT,
            isSyncPractice: props.quizSetsType === ADAPTER_MAP.SYNC_PRACTICE,
          },
          () => ({})
        )
      }
    >
      <QuestionBook {...props} wrong_quiz_sets={buildNewSetsGroup} />
    </MyProvider>
  );
};

export default QuestionBookProvider;
