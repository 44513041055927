import { ApplicationStore } from "../utils/react-global-state";
const SET_SHOW_POSTER_MODAL = "_SET_SHOW_POSTER_MODAL";
class NewExperienceStore extends ApplicationStore {
  constructor(props) {
    super(props);
    this.defaultStates = {};
  }

  actionCreators = () => ({
    setShowPostersModal: (payload) => ({
      type: SET_SHOW_POSTER_MODAL,
      payload,
    }),
  });

  reducer = (states, action) => {
    if (action.type === SET_SHOW_POSTER_MODAL) {
      states.showPosterModal = action.payload;
      return states;
    }
  };
}

export default new NewExperienceStore().init();
