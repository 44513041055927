import React from "react";
import PropTypes from "prop-types";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import "./ThinkingLevelGuide.scss";

const ThinkingLevelGuide = ({ description }) => {
  return (
    <div className="comp_thinking_level_guide">
      <div className="container">
        <LazyloadContent content={description} />
      </div>
    </div>
  );
};

ThinkingLevelGuide.propTypes = {
  description: PropTypes.string,
};

export default ThinkingLevelGuide;
