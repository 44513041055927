import React, { useState, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "./Button";

export const Menu = styled.ul``;

export const MenuItem = styled.li`
  span {
    display: block;
    padding: 3px 20px;
  }
  span:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const MenuContainer = styled.div``;

export const Caret = styled.span``;

export const DropdownButton = styled(Button)``;

export const SelectedMenuItem = styled.span`
  padding-right: 12px;
`;

const DropdownMenu = ({
  title,
  options,
  renderOptions,
  onOptionClick,
  defaultActiveOptionIndex,
  isOptionClickedChange,
  className,
  renderOption,
}) => {
  const [activeOptionIndex, setActiveOptionIndex] = useState(
    defaultActiveOptionIndex ?? -1
  );

  const onMenuClick = useCallback(
    (e) => {
      let clickedEle = e.target;
      const menuChildren = Array.from(e.currentTarget.children);
      while (
        clickedEle !== e.currentTarget &&
        !menuChildren.includes(clickedEle)
      ) {
        clickedEle = clickedEle.parentNode;
      }
      const clickedMenuItemIndex = menuChildren.indexOf(clickedEle);
      console.log("clickedMenuItemIndex", clickedMenuItemIndex);
      if (clickedMenuItemIndex !== -1) {
        if (isOptionClickedChange) setActiveOptionIndex(clickedMenuItemIndex);
        if (typeof onOptionClick === "function") {
          onOptionClick(clickedMenuItemIndex);
        }
      }
    },
    [isOptionClickedChange, onOptionClick]
  );
  console.log("activeOptionIndex", activeOptionIndex);
  return (
    <MenuContainer className={`btn-group ${className}`}>
      <DropdownButton
        type="button"
        className="btn-primary dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <SelectedMenuItem>
          {activeOptionIndex !== -1
            ? renderOption
              ? renderOption(options[activeOptionIndex])
              : options[activeOptionIndex]
            : title}
        </SelectedMenuItem>
        <Caret className="caret"></Caret>
      </DropdownButton>
      <Menu className="dropdown-menu" onClick={onMenuClick}>
        {typeof renderOptions === "function"
          ? renderOptions()
          : options.map((option, index) => (
              <MenuItem key={index}>
                {renderOption ? renderOption(option) : <span>{option}</span>}
              </MenuItem>
            ))}
      </Menu>
    </MenuContainer>
  );
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  renderOptions: PropTypes.func,
  onOptionClick: PropTypes.func,
  defaultActiveOptionIndex: PropTypes.number,
  // 选项点击改变
  isOptionClickedChange: PropTypes.bool,
  renderOption: PropTypes.func,
};

export default DropdownMenu;
