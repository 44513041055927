import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import BackgroundBottom from "2020-618/background_bottom.png";
import Banner from "2020-618/banner.png";
import Gift from "2020-618/gift.png";
import TitleRule from "2020-618/title_rule.png";
import TitleRecommend from "2020-618/title_recommend.png";
import GradeTwo from "2020-618/grade_2.png";
import GradeOne from "2020-618/grade_1.png";
import KingGradeOne from "2020-618/grade_0.png";
import IconAdd from "2020-618/icon_add.png";
import GoCourse from "arrow-right.png";
import Gif from "2020-618/button.gif";
import { media } from "../utils/helpers";

const rules = [
  "<strong>2020年6月16日00:00 - 6月19日23:59</strong>前报名成功<strong>（不含拼团中）</strong>的课程，根据累计实付金额赠送相应的礼品，<strong>礼品不可叠加领取</strong>。",
  "活动结束后将于<strong>6月23日19:00</strong>前完成礼品跟快递地址的核对确认。",
  "礼品将在<strong>2020年7月1日</strong>前寄出，课程退课影响到礼品领取，需扣除礼品的价格，或不影响二次销售的前提下退还至心芽学堂。",
  "活动最终解释权归上海芽圃教育科技有限公司所有。",
];

const T_LEVEL_LINK = "https://xinya.me/study_package_activity_details?id=364";
const C_LEVEL_LINK = "https://xinya.me/study_package_activity_details?id=347";
const POETRY_LINK = "https://xinya.me/study_package_activity_details?id=650";
const SAN_ZI_JING_LINK =
  "https://xinya.me/study_package_activity_details?id=1062";
const SPELL_LINK = "https://xinya.me/study_package_activity_details?id=685";

const COURSE_RECOMMEND = [
  {
    title: GradeTwo,
    courses: [
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/035799d4035a50bcbe885862f25a0f58.jpg",
          name: "数学思维训练课",
          desc: "T6：二年级·上",
          link: T_LEVEL_LINK,
        },
      ],
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/a495b2167d472c84c8020d340e301c43.png",
          name: "基础计算课",
          desc: "C4：表内乘法",
          link: C_LEVEL_LINK,
        },
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/da2e9792661884d6867220516fc5703d.png",
          name: "基础计算课",
          desc: "C5：表内除法",
          isChainPre: true,
          link: C_LEVEL_LINK,
        },
      ],
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/adc11fdcf0db8a584bea473fb0a926c2.jpg",
          name: "分级学古诗",
          desc: "(三级)",
          link: POETRY_LINK,
        },
      ],
    ],
  },
  {
    title: GradeOne,
    courses: [
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/8deecd2ccb3f67469d3987fed684410d.png",
          name: "数学思维训练课",
          desc: "T4：一年级·上",
          link: T_LEVEL_LINK,
        },
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/267b3f3d07f1aaa077623af120451f88.png",
          name: "数学思维训练课",
          desc: "T5：一年级·下",
          isChainPre: true,
          link: T_LEVEL_LINK,
        },
      ],
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/b106be421de51d63a94b0811ea98527a.png",
          name: "基础计算课",
          desc: "C2：20以内加减",
          link: C_LEVEL_LINK,
        },
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/a41ee94118b88163446cbd3c02b4744c.png",
          name: "基础计算课",
          desc: "C3：100以内加减",
          isChainPre: true,
          link: C_LEVEL_LINK,
        },
      ],
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/3bab46d17aa043115657ffaebda9b114.jpg",
          name: "分级学古诗",
          desc: "(一级+二级)",
          link: POETRY_LINK,
        },
      ],
    ],
  },
  {
    title: KingGradeOne,
    courses: [
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/7610914da8d8d0dca6f3c5ab3907ebc9.png",
          name: "数学思维训练课",
          desc: "T3：大班",
          link: T_LEVEL_LINK,
        },
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/4549361368bcdab56157cd72daf4f10b.png",
          name: "基础计算课",
          desc: "C1：10以内加减",
          link: C_LEVEL_LINK,
        },
      ],
      [
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/f3936e1b1051ed7072fcfa187a1eee3d.jpg",
          name: "精讲《三字经》",
          desc: "",
          link: SAN_ZI_JING_LINK,
        },
        {
          photo:
            "https://upload.fireflybaby.cn/recommend_thumb_photo/b96b2067412f014b5774fc01c1cf0218.jpg",
          name: "跟着小芽学拼音",
          desc: "",
          link: SPELL_LINK,
        },
      ],
    ],
  },
];

const ActivityContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  background-image: url(${BackgroundBottom});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 58px;
  .images {
    width: 100%;
    font-size: 0;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const ActivityRule = styled.div`
  margin: 30px 20px 20px 20px;
  width: calc(100% - 40px);
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(195, 42, 145, 0.35);
  border-radius: 14px;
  padding: 35px 15px 20px 15px;
  position: relative;
  ${Image} {
    width: 142px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ruler_wrapper {
    display: flex;
    align-items: flex-start;
    .ruler_index {
      margin-top: -1px;
      margin-right: 5px;
      font-size: 15px;
      font-weight: 700;
      font-style: italic;
      letter-spacing: 1px;
      color: #999999;
    }
  }
  .ruler_wrapper + .ruler_wrapper {
    margin-top: 11px;
  }
`;

const RuleItem = styled.p`
  font-size: 14px;
  strong {
    color: #5e22e0;
    font-weight: 700;
  }
`;

const GradeContainer = styled.div`
  margin: 16px 10px;
  padding: 20px 0 40px 10px;
  border-left: 2px solid white;
  position: relative;
  ${media.aboveTablet`margin: 16px 20px;`};

  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: -14px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff;
  }
  &::after {
    content: "";
    width: 100px;
    height: 20px;
    background-image: url(${({ gradeImg }) => gradeImg});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -18px;
  }
`;

const CourseContainer = styled.a`
  margin: 5px 0px;
  display: block;
  width: 170px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(127, 106, 64, 0.3);
  border-radius: 5px;
  padding: 6px;
  display: flex;
  position: relative;
  ${media.aboveTablet`width: 187px;`};
  padding-right: 20px;
  background-image: url(${GoCourse});
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 8px auto;

  .img {
    width: 47px;
    height: 63px;
    border-radius: 4px;
  }
  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 6px;
    p {
      font-weight: bold;
      font-size: 12px;
      color: #333333;
    }
  }

  &::before {
    ${({ isChainPre }) => (isChainPre ? "content: ''" : "")};
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    transform: translate(-85%, -50%);
    background-image: url(${IconAdd});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const CoursesWrapper = styled.div`
  display: flex;
  ${CourseContainer} + ${CourseContainer} {
    margin-left: 15px;
  }
`;

const ButtonCoupon = styled.a`
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  max-width: 500px;
  img {
    width: 100%;
    display: block;
  }
`;

const Activity = () => {
  useLayoutEffect(() => {
    const elements = document.getElementsByClassName("course_container");
    if (elements) {
      const elementsArr = Array.from(elements);
      const minWidth = Math.min(
        ...elementsArr.map((ele) => ele.getBoundingClientRect().width)
      );
      elementsArr.forEach((ele) => (ele.style.width = `${minWidth}px`));
    }
  }, []);
  return (
    <ActivityContainer>
      <div className="images">
        <Image src={Banner} />
        <Image src={Gift} />
      </div>
      <ActivityRule>
        <Image src={TitleRule} />
        {rules.map((rule, index) => (
          <div className="ruler_wrapper" key={index}>
            <div className="ruler_index">{`${index + 1}. `}</div>
            <RuleItem dangerouslySetInnerHTML={{ __html: rule }}></RuleItem>
          </div>
        ))}
      </ActivityRule>
      <Image
        src={TitleRecommend}
        style={{
          margin: "0 auto",
          width: 194,
          position: "relative",
          left: "50%",
          marginLeft: -97,
          marginBottom: 25,
        }}
        className="lazyload lazyloading"
      />
      {COURSE_RECOMMEND.map((recommend, index) => (
        <GradeContainer
          gradeImg={recommend.title}
          key={index}
          style={{ marginBottom: 0 }}
        >
          {recommend.courses.map((course, index) => (
            <CoursesWrapper key={index}>
              {course.map((courseNest, idx) => (
                <CourseContainer
                  isChainPre={courseNest.isChainPre}
                  href={courseNest.link}
                  key={idx}
                  className="course_container"
                >
                  <img
                    src={courseNest.photo}
                    alt=""
                    className="img lazyload lazyloading"
                  />
                  <div className="desc">
                    <p>{courseNest.name}</p>
                    {courseNest.desc && <p>{courseNest.desc}</p>}
                  </div>
                </CourseContainer>
              ))}
            </CoursesWrapper>
          ))}
        </GradeContainer>
      ))}
      <ButtonCoupon href={"https://xinya.me/batch_ecoupons"}>
        <Image src={Gif} className="lazyload lazyloading" />
      </ButtonCoupon>
    </ActivityContainer>
  );
};

export default Activity;
