import React from "react";
import { request, csrfHeaders } from "../utils/request";
import PropTypes from "prop-types";
import { ToastManager } from "../utils/Toast";
import CityArea from "../utils/city_area/CityArea.jsx";

import "./Detail.scss";

const defaultAreaText = "请选择省市区";

export default class ShipmentAddress extends React.Component {
  constructor(props) {
    super(props);

    console.log(props);
    this.state = {
      name: this.props.address.name || "",
      phone_num: this.props.address.mobile || "",
      address: this.props.address.detail_address || "",
      submitted: false,
      commodiesState: false,
      displayPickerDialog: false,
    };
  }

  objToQuery(obj) {
    let arr = []
    for (let key in obj) {
      arr.push(`${key}=${obj[key]}`)
    }
    return arr.join('&')
  }

  handleSubmit() {
    event.preventDefault();
    // console.log("image_url: " + this.props.image_url)
    let { selectProvinceId, selectSubCityId } = this.state;
    const { area } = this.props;

    if (this.state.name === "") {
      alert("姓名不能为空");
    } else if (!/^1[23456789]\d{9}$/.test(this.state.phone_num)) {
      alert("手机号填写有误");
    } else if (
      document.getElementsByClassName("text")[0].innerText == defaultAreaText
    ) {
      alert("请选择相应的省市区");
    } else if (this.state.address === "") {
      alert("具体地址不能为空");
    } else {
      this.toast = ToastManager.showLoading("提交中...");
      const addressInfo = {
        name: this.state.name,
        mobile: this.state.phone_num,
        detail_address: this.state.address,
        province_id: parseInt(
          selectProvinceId ? selectProvinceId : area && area.province.id
        ),
        city_id: parseInt(
          selectSubCityId ? selectSubCityId : area && area.city.id
        ),
      };
      const data = {
        shipment_address: addressInfo,
        id: this.props.address.id,
      }
      request({
        url: this.props.update_address_url,
        method: "POST",
        headers: csrfHeaders,
        data: data,
      })
        .then((resp) => {
          this.toast.cancel();
          let data = resp.data;
          console.log("data: ", data);

          if (data.code !== 200) {
            alert(data.message)
            return
          }
          let redirectUrl = decodeURIComponent(this.props.redirect_url)
          const queryParams = Object.fromEntries([...new URLSearchParams(redirectUrl.split('?')[1])])
          queryParams.shipment_address_id = data.shipment_address_id
          const queryStr = this.objToQuery(queryParams)
          console.log(redirectUrl, queryParams, queryStr, '====================== redirect url')
          redirectUrl = redirectUrl.split('?')[0] + '?' + queryStr
          location.href = redirectUrl
        })
        .catch((err) => {
          alert(err);
          this.toast.cancel();
        });
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        // console.log(this.state)
      }
    );
  }

  // 获取 CityArea 组件的索引
  getScrollAreaTop = (selectIndex, selectSubIndex) => {
    this.setState({
      selectIndex,
      selectSubIndex,
    });
  };

  selectCityAreaClick = () => {
    this.setState(
      {
        displayPickerDialog: true,
        commodiesState: true,
      },
      () => {
        // 点击之后唤醒scrollTop
        $("#province_list").scrollTop(40 * this.state.selectIndex);
        $("#sub_city_list").scrollTop(40 * this.state.selectSubIndex);
      }
    );
  };

  displayPickerDialog = (bool = false) => {
    this.setState({
      displayPickerDialog: bool,
    });
  };

  // 获取 CityArea模块中的省市
  getAddressCityId = (
    selectProvinceId,
    selectSubCityId,
    selectValue,
    selectSubValue
  ) => {
    this.setState({
      selectProvinceId,
      selectSubCityId,
      selectValue,
      selectSubValue,
    });
  };

  getCloseDialog = () => {
    this.setState({
      commodiesState: false,
    });
  };

  render() {
    let { selectValue, selectSubValue } = this.state;

    const content = this.state.submitted ? (
      <div className="traning_note_box">
        {
          // noteContent
          <div className="success-attention">提交成功！</div>
        }
        {this.props.redirect_url && (
          <a className="traning_declare" href={this.props.redirect_url}>
            {gift_type == 5 || gift_type == 6
              ? "确定"
              : "查看课程安排"}
          </a>
        )}
      </div>
    ) : (
      <div>
        <p className="gift-attention">请填写快递信息</p>
        <div className="address-form-wrapper">
          <div className="formField">

            <div className="field-wrapper">
              <p>收件人姓名：</p>
              <input
                type="text"
                name="name"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.name}
                placeholder="收件人姓名"
              />
            </div>
            <div className="field-wrapper">
              <p>手机：</p>
              <input
                type="text"
                name="phone_num"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.phone_num}
                placeholder="手机号"
              />
            </div>
            <div className="field-wrapper">
              <p>省市区：</p>
              {/* <div className="sign_school_text" onClick={this.handleChooseClick}>
                请选择报名学校
              </div> */}
              {selectValue != undefined ? (
                <div
                  className="text area_text"
                  onClick={this.selectCityAreaClick}
                >
                  {`${selectValue}  ${selectSubValue}`}
                </div>
              ) : this.props.area ? (
                <div
                  className="text area_text"
                  onClick={this.selectCityAreaClick}
                >
                  {`${this.props.area.province.name}  ${this.props.area.city.name}`}
                </div>
              ) : (
                <div className="text" onClick={this.selectCityAreaClick}>
                  请选择省市区
                </div>
              )}
            </div>
            <div className="field-wrapper">
              <p>详细地址：</p>
              <textarea
                type="text"
                name="address"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.address}
                placeholder="请填写详细的收货地址"
              />
            </div>
          </div>
          <button className="submitBtn" onClick={this.handleSubmit.bind(this)}>
            确定
          </button>
        </div>
      </div>
    );

    return (
      <div className="comp-gift-commodies">
        <div className="gift-wrapper">
          <img src={this.props.image_url} />
          <p>{this.props.content}</p>
          {/* <p className="entity_dispatch_note">{entity_dispatch_note}</p> */}
        </div>
        {content}
        {
          <CityArea
            provinces={this.props.provinces}
            area={this.props.area}
            getAddressCityId={this.getAddressCityId}
            displayPickerDialog={this.displayPickerDialog}
            giftCommodies={true}
            propCommodiesState={this.state.commodiesState}
            getCloseDialog={this.getCloseDialog}
            getScrollAreaTop={this.getScrollAreaTop}
          />
        }
      </div>
    );
  }
}
ShipmentAddress.propTypes = {
  image_url: PropTypes.string.isRequired,
  update_address_url: PropTypes.string.isRequired,
};
