import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "../../utils/react-global-state";
import PropTypes from "prop-types";
import { formatAudioTime } from "../../utils/formatAudioTime";
import SectionTags from "./SectionTags.jsx";
import { request } from "../../utils/request";
import Raven from "raven-js";

const ExperienceSectionTab = React.memo(function ExperienceSectionTab({
  finalListenedProgress,
  sectionIndex,
  tags,
  audioTime,
  name,
  isShowTrialTag,
  isAudioPlaying,
  onTabPlayIconClick,
}) {
  const isExperienceBought = useSelector((state) => state.isExperienceBought);

  return (
    <div className="experience_section">
      <div
        className="player_progress_mask"
        style={{
          background:
            "linear-gradient( 90deg,#edffe5 0,#edffe5 " +
            `${finalListenedProgress}` +
            "%,#fff " +
            `${finalListenedProgress}` +
            "%,#fff 100%)",
        }}
      >
        {finalListenedProgress < 100 && (
          <div
            className="line_right"
            style={{ left: `${finalListenedProgress}%` }}
          />
        )}
      </div>
      <SectionTags tags={tags} />
      <div className="duration_time">{audioTime}</div>
      <div className="experience_title">{name}</div>

      {isAudioPlaying ? (
        <div
          className="current_listening"
          onClick={onTabPlayIconClick.bind(null, sectionIndex)}
        >
          正在播放
        </div>
      ) : isShowTrialTag ? (
        <div className="trial_tag">试听</div>
      ) : !isExperienceBought ? (
        <div className="current_lock" />
      ) : (
        <div
          className="current_stop"
          onClick={onTabPlayIconClick.bind(null, sectionIndex)}
        >
          开始播放
        </div>
      )}
    </div>
  );
});

ExperienceSectionTab.propTypes = {
  finalListenedProgress: PropTypes.number,
  tags: PropTypes.array,
  audioTime: PropTypes.string,
  name: PropTypes.string,
  isShowTrialTag: PropTypes.bool,
  isAudioPlaying: PropTypes.bool,
  onTabPlayIconClick: PropTypes.func,
  sectionIndex: PropTypes.number,
};

const ConnectedExperienceSectionTab = React.memo(
  function ConnectedExperienceSectionTab({
    isAudioPlaying,
    sectionListenedUpdate,
    sectionIndex,
    gatherSecsDuration,
    playerCurrentProgress,
    isShowTrialTag,
    onTabPlayIconClick,
  }) {
    const experienceSections = useSelector((state) => state.experienceSections);
    const section = useMemo(() => experienceSections[sectionIndex], [
      experienceSections,
      sectionIndex,
    ]);
    const [audioTime, setAudioTime] = useState("00:00");
    const [nowListenProgressOrigin, setNowListenProgressOrigin] = useState(
      section.max_delta_time
    );

    const getAudioInfo = useCallback((audioUrl) => {
      return request({
        url: `${audioUrl}?avinfo`,
        method: "get",
      }).then((resp) => parseInt(resp.data.format.duration));
    }, []);

    // 取原始收听记录和现在开始听得记录的最大值
    const finalListenedProgress = useMemo(() => {
      return playerCurrentProgress || nowListenProgressOrigin;
    }, [nowListenProgressOrigin, playerCurrentProgress]);

    useEffect(() => {
      if (section.audio) {
        getAudioInfo(section.audio)
          .then((duration) => {
            setAudioTime(formatAudioTime(duration));
            const listened = Math.min(
              Math.round((section.max_delta_time * 100) / duration || 0),
              100
            );
            // 把原始收听的记录存一下
            sectionListenedUpdate.push({
              [sectionIndex]: listened,
            });
            gatherSecsDuration.push({
              [sectionIndex]: duration,
            });
            // 页面可能要展示原始收听的记录
            setNowListenProgressOrigin(listened);
          })
          .catch((err) => {
            Raven.captureException(err);
          });
      }
    }, [
      gatherSecsDuration,
      getAudioInfo,
      nowListenProgressOrigin,
      section.audio,
      section.max_delta_time,
      sectionIndex,
      sectionListenedUpdate,
    ]);

    return (
      <ExperienceSectionTab
        finalListenedProgress={finalListenedProgress}
        tags={section.tags}
        audioTime={audioTime}
        name={section.name}
        sectionIndex={sectionIndex}
        isShowTrialTag={isShowTrialTag}
        isAudioPlaying={isAudioPlaying}
        onTabPlayIconClick={onTabPlayIconClick}
      />
    );
  }
);

ConnectedExperienceSectionTab.propTypes = {
  onTabPlayIconClick: PropTypes.func,
  isAudioPlaying: PropTypes.bool,
  sectionListenedUpdate: PropTypes.object,
  sectionIndex: PropTypes.number,
  gatherSecsDuration: PropTypes.object,
  playerCurrentProgress: PropTypes.number,
  isShowTrialTag: PropTypes.bool,
};

export default ConnectedExperienceSectionTab;
