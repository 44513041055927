import React, { useMemo } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import MDContentRender from "./MDContentRender.jsx";
import CardBlockBox from "./CardBlockBox.jsx";
import { useSelector } from "../../utils/react-global-state/index.js";
import ListContainer from "../../utils/ListContainer/ListContainer.jsx";

const MemoOpinionContent = React.memo(function MemoOpinionContent({ content }) {
  const guestName = useSelector((state) => state.experienceGuestName);
  const renderedContent = useMemo(() => MDContentRender(content), [content]);
  return (
    <CardBlockBox>
      <div className="opinion_card">
        {renderedContent}
        <div className="guest_name">
          <span>-</span>
          <span>-</span>
          <span>-</span>
          <span>-</span>
          <span>{guestName}</span>
        </div>
      </div>
    </CardBlockBox>
  );
});

MemoOpinionContent.propTypes = {
  content: PropTypes.string,
};

const MemoMethodContent = React.memo(function MemoMethodContent({
  title,
  content,
}) {
  const renderedContent = useMemo(() => MDContentRender(content), [content]);
  return (
    <CardBlockBox>
      <div className="method_card">
        <div className="method_title">{title}</div>
        {renderedContent}
      </div>
    </CardBlockBox>
  );
});

MemoMethodContent.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
};

const MemoWikiContent = React.memo(function MemoWikiContent(wiki) {
  const renderedContent = useMemo(() => MDContentRender(wiki.content), [
    wiki.content,
  ]);
  return (
    <div className="resource_wiki_school">
      <div className="wiki_box" key={wiki.id}>
        <div className="wiki_name_type">
          <span className="wiki_label">微词条</span>
          <div className="wiki_name">{wiki.name}</div>
        </div>

        <div className="wiki_content">{renderedContent}</div>
      </div>
    </div>
  );
});

MemoWikiContent.propTypes = {
  wiki: PropTypes.object,
};

const MemoSchoolContent = React.memo(function MemoSchoolContent(school) {
  const renderedContent = useMemo(() => MDContentRender(school.description), [
    school.description,
  ]);
  return (
    <div className="resource_wiki_school">
      <div className="school_box" key={school.id}>
        <div className="school_name_type">
          <div className="source_type">学校</div>
          <div className="school_name">{school.name}</div>
        </div>

        <div className="source_container">
          <img src={school.photo_url} />
          <div className="school_brief">{renderedContent}</div>
        </div>
      </div>
    </div>
  );
});

MemoSchoolContent.propTypes = {
  school: PropTypes.object,
};

const MemoResourceContent = React.memo(function MemoResourceContent(resource) {
  const renderedContent = useMemo(() => MDContentRender(resource.description), [
    resource.description,
  ]);
  return (
    <div className="resource_wiki_school">
      <div className="product_source_box" key={resource.id}>
        <div className="produce_name_type">
          <div className="source_type">{resource.product_type}</div>
          <div
            className="product_name"
            style={{ marginLeft: resource.product_type !== "图书" ? 8 : 0 }}
          >
            {resource.name}
          </div>
        </div>

        <div className="source_container">
          <img src={resource.photo_url} />
          <div className="product_brief">{renderedContent}</div>
        </div>
      </div>
    </div>
  );
});

MemoResourceContent.propTypes = {
  resource: PropTypes.object,
};

const MemoContentTitle = React.memo(function MemoOpinionList({
  memoIndex,
  memoSubName,
  memoName,
}) {
  return (
    <div className="title">
      <p className="name">
        <span>{`${memoIndex + 1}.`}</span>
        {memoName}
      </p>
      <p className="sub_name">{memoSubName}</p>
    </div>
  );
});

MemoContentTitle.propTypes = {
  memoIndex: PropTypes.number,
  memoSubName: PropTypes.string,
  memoName: PropTypes.string,
};

const mapExtraToBlockItem = {
  OPINION: {
    component: MemoOpinionContent,
    getData: (extra) => [{ content: extra.content }],
  },
  METHOD: {
    component: MemoMethodContent,
    getData: (extra) => [{ content: extra.content, title: extra.title }],
  },
  WIKI: {
    component: MemoWikiContent,
    getData: (extra) => extra.wikis,
  },
  PRODUCT: {
    component: MemoResourceContent,
    getData: (extra) => extra.resources,
  },
  SCHOOL: {
    component: MemoSchoolContent,
    getData: (extra) => extra.schools,
  },
};

const MemoContent = React.memo(function MemoContent({
  memoIndex,
  memoSubName,
  memoName,
  memoExtras,
}) {
  return (
    <div
      className="memo_content_container"
      id={`memo_content_container_${memoIndex}`}
    >
      <MemoContentTitle
        memoIndex={memoIndex}
        memoName={memoName}
        memoSubName={memoSubName}
      />
      {memoExtras.map((extra, index) => {
        const Extra = mapExtraToBlockItem[extra.type.toUpperCase()];
        if (Extra) {
          return (
            <ListContainer
              list={Extra.getData(extra)}
              listItem={({ itemIndex, ...extra }) => (
                <Extra.component {...extra} key={itemIndex} />
              )}
              key={index}
            />
          );
        } else {
          return "";
        }
      })}
    </div>
  );
});

MemoContent.propTypes = {
  memoIndex: PropTypes.number,
  memoSubName: PropTypes.string,
  memoName: PropTypes.string,
  memoExtras: PropTypes.array,
};

const SectionMemosList = React.memo(function SectionMemosList({
  sectionMemos,
  isExperienceBought,
}) {
  return (isExperienceBought || true) ? (
    <div className="memo_contents">
      <span className="tip_title">讲义</span>
      <ListContainer
        list={sectionMemos}
        listItem={({ itemIndex, ...memo }) => (
          <MemoContent
            memoIndex={itemIndex}
            memoSubName={memo.brief}
            memoName={memo.name}
            memoExtras={memo.extras}
            key={itemIndex}
          />
        )}
      />
    </div>
  ) : (
    <div className="memo_contents">
      <span className="tip_title">讲义</span>
      <div className="none_buy">请报名后查看</div>
    </div>
  );
});

SectionMemosList.propTypes = {
  sectionMemos: PropTypes.array,
  isExperienceBought: PropTypes.bool,
};

export default SectionMemosList;
