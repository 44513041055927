/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {
  memo,
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { fabric } from "fabric";
import "../../utils/fabric-viewport.js";
import "../../utils/fabric-history.js";
import WithErrorBoundary from "../../utils/WithErrorBoundary.jsx";
import { isDevelopment } from "../../utils/Env.js";
import { ToastManager } from "../../utils/Toast";
import { showDialog, retryFetch, debounce } from "../../utils/helpers";
import { request, csrfHeaders, getAxiosErrorText } from "../../utils/request";
import { uploadBase64Image } from "@/components/utils/qiniu"

import styled from "styled-components";
import icon_close from "icon_header_shadow_close.png";
import icon_rotate from "admin/icon_rotate";
import icon_brush from "admin/icon_brush";
import icon_zoom from "admin/icon_zoom";
import icon_shrink from "admin/icon_shrink";
import icon_eraser from "admin/icon_eraser";
import icon_reset from "admin/icon_reset";
import icon_save from "admin/icon_save";
import icon_position from "admin/icon_position";
import img_back_one from "admin/img_back_one.png";
const StyleCorrectCanvasContainer = styled.div`
  display: flex;
  .input_photo_id {
    width: 120px;
    margin-right: 30px;
  }
`;
const StyleHomeworkContainerDialog = styled.div`
  background: rgba(0, 0, 0, 1);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999999;
  padding: 0px;
`;
const StyledHomeworkDialog = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  img {
    min-width: 300px;
    width: 100%;
  }
  .big_area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .confirm_wrapper {
    width: 100%;
    height: 80px;
    padding-top: 10px
  }
  .confirm {
    display: flex;
    justify-content: space-around;
    height: 70px;
    background: #f4f3f4 linear-gradient(to top, #aaaaaa, #f4f3f4);
    padding: 10px;
    width: 100%;
    .correction_close,
    .correction_rotate,
    .correction_brush,
    .correction_handle,
    .correction_zoom,
    .correction_shrink,
    .correction_eraser,
    .correction_undo,
    .correction_redo,
    .correction_reset,
    .correction_save {
      position: relative;
      cursor: pointer;
      padding: 10px;
      width: 39px;
      height: 39px;
      &:hover {
        span {
          background: #49c114;
          color: #fff;
          border-color: #49c114;
        }
      }
      span {
        position: absolute;
        white-space: nowrap;
        bottom: -12px;
        left: 15px;
        border: 1px solid #707070;
        font-size: 12px;
        background: #fff;
        margin-right: 10px;
        padding: 0 5px;
        &.active {
          background: #49c114;
          color: #fff;
          border-color: #49c114;
        }
      }
    }
    .correction_close {
      background: url(${icon_close}) no-repeat center/35px;
    }
    .correction_rotate {
      background: url(${icon_rotate}) no-repeat center/35px;
    }
    .correction_undo {
      background: url(${img_back_one}) no-repeat center/35px;
    }
    .correction_brush {
      background: url(${icon_brush}) no-repeat center/35px;
    }
    .correction_zoom {
      background: url(${icon_zoom}) no-repeat center/35px;
    }
    .correction_shrink {
      background: url(${icon_shrink}) no-repeat center/35px;
    }
    .correction_handle {
      background: url(${icon_position}) no-repeat center/35px;
    }
    .correction_eraser {
      background: url(${icon_eraser}) no-repeat center/35px;
    }
    .correction_reset {
      background: url(${icon_reset}) no-repeat center/35px;
    }
    .correction_save {
      background: url(${icon_save}) no-repeat center/35px;
    }
  }
`;

const CorrectCanvas = ({ ...props }) => {
  console.log("CorrectHomeworkCanvas_props: ", props);
  const { answerPhotoUrl, generateTokenUrl, photoId } = props;
  const [showHomeworkModal, setShowHomeworkModal] = useState(false);
  // `${answerPhotoUrl}?imageView2/1/w/320/h/320`
  const [lastAnswerPhotoUrl, setLastAnswerPhotoUrl] = useState(
    `${answerPhotoUrl}`
  );
  const [lastPhotoId, setLastPhotoId] = useState(`${photoId ?? ""}`);

  const handlePhotoId = (e) => {
    const id_value = e.target.value;
    console.log("id_value: ", id_value);
    setLastPhotoId(id_value);
  };
  useEffect(() => {
    console.warn("------lastAnswerPhotoUrl: ", lastAnswerPhotoUrl);
  }, [lastAnswerPhotoUrl]);

  const isNotNormalPhoto = lastAnswerPhotoUrl.includes("data:image");
  // !lastAnswerPhotoUrl.includes("jpeg") ||
  // !lastAnswerPhotoUrl.includes("jpg") ||
  // !lastAnswerPhotoUrl.includes("png");

  return (
    <StyleCorrectCanvasContainer>
      {/* <input
        placeholder="作业批改图"
        className="form-control input_photo_id"
        type="text"
        value={lastPhotoId}
        onChange={debounce((e) => handlePhotoId(e))}
      /> */}
      <img
        className={`img-thumbnail`}
        data-photo-id={lastPhotoId}
        onClick={() => setShowHomeworkModal(true)}
        src={`
        ${
          isNotNormalPhoto
            ? lastAnswerPhotoUrl
            : `${lastAnswerPhotoUrl}?imageView2/1/w/320/h/320`
        }
        `}
        alt=""
      />
      {showHomeworkModal && (
        <StyleHomeworkContainerDialog>
          <StyledHomeworkDialog>
            <HomeworkBoardArea
              letterImage={/^data:image/.test(lastAnswerPhotoUrl) ? lastAnswerPhotoUrl : lastAnswerPhotoUrl + '?' + new Date().getTime()}
              generateTokenUrl={generateTokenUrl}
              setShowHomeworkModal={setShowHomeworkModal}
              setLastAnswerPhotoUrl={setLastAnswerPhotoUrl}
              setLastPhotoId={setLastPhotoId}
            />
          </StyledHomeworkDialog>
        </StyleHomeworkContainerDialog>
      )}
    </StyleCorrectCanvasContainer>
  );
};

export const HomeworkBoardArea = memo(
  ({
    letterImage,
    generateTokenUrl,
    setShowHomeworkModal,
    setLastAnswerPhotoUrl,
    setLastPhotoId,
  }) => {
    const canvasRef = useRef();
    const [imgSrc, setImgSrc] = useState(null);
    const [isEnableEraser, setEnableEraser] = useState(false);
    const [dataIndex, setDataIndex] = useState(-1);
    const [isReadyToShow, setReadyToShow] = useState(false);

    let isInTextMode = false;
    let isBrushEnabled = false;
    let isEraserEnabled = false;

    // The canvas width and height may not be equal, you may assign any value to them.
    const maxCanvasWidth = Math.min(900, window.innerWidth);
    const maxCanvasHeight = window.innerHeight - 80;
    const canvasCenterPoint = { x: maxCanvasWidth / 2, y: maxCanvasHeight / 2 };

    let fabricCanvas, fabricImage;
    let originalImageWidth;
    let originalImageHeight;
    let maxImageSize;

    useEffect(() => {
      const canvas = canvasRef.current;
      canvas.width = maxCanvasWidth;
      canvas.height = maxCanvasHeight;

      setReadyToShow(true);

      fabricCanvas = new fabric.CanvasWithViewport("canvas", {
        isDrawingMode: false,
      });

      fabricCanvas.freeDrawingBrush.color = "rgb(255, 0, 0,1)";
      fabricCanvas.freeDrawingBrush.width = 12;
      fabric.Image.fromURL(
        letterImage,
        function (img) {
          originalImageWidth = img.width;
          originalImageHeight = img.height;
          maxImageSize =
            originalImageWidth > originalImageHeight
              ? originalImageWidth
              : originalImageHeight;
          console.log(maxImageSize, "maxImageSize");

          if (maxImageSize > 700 && maxImageSize < 1000) {
            fabricCanvas.freeDrawingBrush.width = 4;
          } else if (maxImageSize > 1000 && maxImageSize < 2000) {
            fabricCanvas.freeDrawingBrush.width = 8;
          } else if (maxImageSize > 2000 && maxImageSize < 3000) {
            fabricCanvas.freeDrawingBrush.width = 12;
          } else if (maxImageSize > 3000 && maxImageSize < 4000) {
            fabricCanvas.freeDrawingBrush.width = 15;
          } else if (maxImageSize > 4000) {
            fabricCanvas.freeDrawingBrush.width = 18;
          } else {
            fabricCanvas.freeDrawingBrush.width = 2;
          }
          const ratio = originalImageWidth / maxCanvasWidth;
          fabricCanvas.zoomToPoint(
            { x: maxCanvasWidth / 2, y: maxCanvasHeight / 2 },
            maxCanvasWidth / originalImageWidth
          );

          fabricImage = img;
          img.set({
            top: (fabricCanvas.height - originalImageHeight) / 2,
            left: (fabricCanvas.width - originalImageWidth) / 2,
            selectable: false,
          });
          fabricCanvas.add(img);

          // Now we can initialize the history, so the image won't be removed by an `undo` action.
          fabricCanvas.initHistory();

          fabricCanvas.on("mouse:wheel", (opt) => {
            var delta = opt.e.deltaY;
            var zoom = fabricCanvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            fabricCanvas.zoomToPoint(
              { x: opt.e.offsetX, y: opt.e.offsetY },
              zoom
            );
            opt.e.preventDefault();
            opt.e.stopPropagation();
          });

          fabricCanvas.on("object:added", (e) => {
            if (e.target instanceof fabric.Textbox) {
              return;
            }
            e.target.selectable = false;
          });

          fabricCanvas.on('selection:cleared', e => {
            let deselected = e.deselected;
            if (deselected && deselected.length > 0) {
              deselected.forEach(e => {
                if (e instanceof fabric.Textbox) {
                  if (!e.text) {
                    fabricCanvas.remove(e);
                  }
                }
              });
            }
          });

          let mouseDownPointer;
          fabricCanvas.on('mouse:down', e => {
            mouseDownPointer = fabricCanvas.getPointer(e.e);
          });
          fabricCanvas.on('mouse:up', e => {
            if (isInTextMode && (!e.target || !(e.target instanceof fabric.Textbox))) {
              let { x, y } = fabricCanvas.getPointer(e.e);
              // let width = Math.abs(x - mouseDownPointer.x);
              let width = 100;
              if (width > 0) {
                let textBox = new fabric.Textbox('', {
                  width,
                  // top: y < mouseDownPointer.y ? y : mouseDownPointer.y,
                  // left: x < mouseDownPointer.x ? x : mouseDownPointer.x,
                  top: y,
                  left: x,
                  fontSize: 28 * ratio,
                  fill: 'red',
                });
                fabricCanvas.add(textBox);
                fabricCanvas.setActiveObject(textBox);
                textBox.enterEditing();
                textBox.hiddenTextarea.focus();
              }
            }
          });

          // TODO If you want to constrain the users can only draw in the region of
          // the image, uncomment below codes and do some changes.
          //
          // fabricCanvas.on('mouse:move', e => {
          //   let { x, y } = e.pointer;
          //   let width, height;
          //   let top, left, bottom, right;
          //   if (img.angle % 180 == 90) {
          //     width = originalImageHeight;
          //     height = originalImageWidth;
          //   } else {
          //     width = originalImageWidth;
          //     height = originalImageHeight;
          //   }
          //   // After the image is rotated, we cannot get the correct top/left
          //   // value. Considering the image is centered in the canvas, we can
          //   // work around to calculate top/left in the below way.
          //   top = (fabricCanvas.height - height) / 2;
          //   left = (fabricCanvas.width - width) / 2;
          //   bottom = top + height;
          //   right = left + width;
          //   let isInImageRegion = x > left && x < right && y > top && y < bottom;
          //   fabricCanvas.isDrawingMode = !!(isInImageRegion && isBrushEnabled);
          // });
        },
        { crossOrigin: "Anonymous" }
      );
    }, []);

    const handleWrittenConfirm = useCallback(() => {
      fabricCanvas.zoomToPoint(canvasCenterPoint, 1);

      // Copy current canvas to a resized tmp canvas, so all objects can be rendered.
      var tmpCanvas = document.createElement("canvas");
      var tmp = new fabric.Canvas(tmpCanvas);
      var tmpCanvasWidth = maxImageSize,
        tmpCanvasHeight = maxImageSize;
      tmp.setDimensions({ width: tmpCanvasWidth, height: tmpCanvasHeight });
      tmp.loadFromJSON(fabricCanvas.toDatalessJSON(), function () {
        tmp.getObjects().forEach((o) => {
          let oldCenterPoint = o.getCenterPoint();
          let centerPoint = { x: tmpCanvasWidth / 2, y: tmpCanvasHeight / 2 };
          let newCenterPoint = {
            x: centerPoint.x + (oldCenterPoint.x - canvasCenterPoint.x),
            y: centerPoint.y + (oldCenterPoint.y - canvasCenterPoint.y),
          };
          o.setPositionByOrigin(newCenterPoint, "center", "center");
          o.setCoords();
        });
        tmp.renderAll();

        // Draw tmp canvas onto a new canvas and export.
        var newCanvas = document.createElement("canvas");
        if (fabricImage.angle % 180 == 90) {
          newCanvas.width = originalImageHeight;
          newCanvas.height = originalImageWidth;
        } else {
          newCanvas.width = originalImageWidth;
          newCanvas.height = originalImageHeight;
        }
        var devicePixelRatio;
        if (typeof window.devicePixelRatio == "number") {
          devicePixelRatio = window.devicePixelRatio;
        } else {
          devicePixelRatio = 1;
        }
        newCanvas
          .getContext("2d")
          .drawImage(
            tmpCanvas,
            ((tmpCanvasWidth - newCanvas.width) / 2) * devicePixelRatio,
            ((tmpCanvasHeight - newCanvas.height) / 2) * devicePixelRatio,
            newCanvas.width * devicePixelRatio,
            newCanvas.height * devicePixelRatio,
            0,
            0,
            newCanvas.width,
            newCanvas.height
          );
        confirmWrite("", newCanvas.toDataURL("image/jpeg"));
        setShowHomeworkModal(false);
      });
    }, []);

    const confirmWrite = async (quizID, base64Img) => {
      const toast = ToastManager.showLoading("提交中...");
      try {
        const resPutImg = await submitWrittenImg(quizID, base64Img);
        console.warn("confirmWrite", resPutImg);
        toast.cancel();
      } catch (e) {
        toast.cancel();
        console.warn("submitWrittenImg error", e);
      }
    };

    const submitWrittenImg = async (id, base64Img) => {
      setLastAnswerPhotoUrl(base64Img);

      const resPutImg = await uploadBase64Image({ imageBase64: base64Img, tokenUrl: generateTokenUrl });
      setLastPhotoId(resPutImg?.id);
      console.log("resPutImg: ", resPutImg);
      if (resPutImg?.id && resPutImg?.url) {
        window.location.reload(true);
      }
      return resPutImg;
    };

    const [photoWidth, setPhotoWidth] = useState(0);
    const [photoHeight, setPhotoheight] = useState(0);
    let scaleDegree = 1;
    var ctx, image_canvas;
    var canvas = document.getElementById("canvas");
    useEffect(() => {
      const sourceImageEle = document.getElementById("sourceImage");
      const image = new Image();
      image.src = letterImage;
      const toast = ToastManager.showLoading("图片加载中...");
      image.onload = () => {
        // TODO: 图片真实宽高
        const imgWidth = image.width;
        const imgHeight = image.height;
        console.log("imgWidth-imgHeight: ", imgWidth, imgHeight);
        // setPhotoWidth(imgWidth);
        // setPhotoheight(imgHeight);

        if (sourceImageEle) {
          console.warn("sourceImageEle: ", sourceImageEle);

          console.log(
            "目标图片的尺寸大小: ",
            sourceImageEle?.width,
            sourceImageEle?.height
          );
          // let canvas_l =
          //   sourceImageEle?.width > sourceImageEle?.height
          //     ? sourceImageEle?.width
          //     : sourceImageEle?.height;
          setPhotoWidth(sourceImageEle?.width);
          setPhotoheight(sourceImageEle?.height);
        }

        toast.cancel();
      };
    }, []);

    const handleRotate = useCallback(() => {
      fabricCanvas.getObjects().forEach((o) => {
        let oldCenterPoint = o.getCenterPoint();
        let newCenterPoint = {
          x: canvasCenterPoint.x + (canvasCenterPoint.y - oldCenterPoint.y),
          y: canvasCenterPoint.y + (oldCenterPoint.x - canvasCenterPoint.x),
        };
        o.set({ angle: (o.angle + 90) % 360 });
        o.setPositionByOrigin(newCenterPoint, "center", "center");
      });
      fabricCanvas.onHistory();
      fabricCanvas.renderAll();
    }, []);

    const onTextClick = useCallback((e, bool) => {
      const data_index = e.currentTarget?.getAttribute("data-index");
      setDataIndex(data_index);
      isInTextMode = true;
      isBrushEnabled = false;
      fabricCanvas.isDrawingMode = false;
      fabricCanvas.isGrabMode = false;
    }, []);

    const onBrushClick = useCallback((e, bool) => {
      const data_index = e.currentTarget?.getAttribute("data-index");
      setDataIndex(data_index);
      isBrushEnabled = true;
      isInTextMode = false;
      fabricCanvas.isDrawingMode = true;
      fabricCanvas.isGrabMode = false;
    }, []);

    const onHandleClick = useCallback((e) => {
      const data_index = e.currentTarget?.getAttribute("data-index");
      setDataIndex(data_index);
      isBrushEnabled = false;
      isInTextMode = false;
      fabricCanvas.isDrawingMode = false;
      fabricCanvas.isGrabMode = true;
    }, []);

    const onCanvasHistory = useCallback((isUndo) => {
      // FIXME When `undo` the first time, the canvas loads the history state
      // from JSON, but the `oCoords` values of the image after loading may
      // change a bit, causing the image moving a bit off its original
      // position.
      if (isUndo) {
        fabricCanvas.undo();
      } else {
        fabricCanvas.redo();
      }
    }, []);

    const onEraserClick = useCallback((e, bool) => {
      const data_index = e.currentTarget.getAttribute("data-index");
      setDataIndex(data_index);
      // setEnableEraser((isEnableEraser) => {
      //   const toSet = typeof bool === "boolean" ? bool : !isEnableEraser;
      //   canvasBoard.enableEraser(toSet);
      //   return toSet;
      // });
      isBrushEnabled = true;
      isEraserEnabled = true;
      fabricCanvas.isDrawingMode = true;
    }, []);

    const onClearBoard = useCallback(() => {
      fabricCanvas.resetHistory();
    }, []);

    const correction_collectons = [
      {
        class: "correction_close",
        click: () => setShowHomeworkModal(false),
        name: "关闭画布",
      },
      {
        class: "correction_rotate",
        click: (e) => handleRotate(),
        name: "旋转",
      },
      {
        class: "correction_brush",
        click: (e) => onTextClick(e, false),
        name: "文字",
      },
      {
        class: "correction_brush",
        click: (e) => onBrushClick(e, false),
        name: "画笔",
      },
      {
        class: "correction_handle",
        click: (e) => onHandleClick(e),
        name: "拖拽",
      },

      // TODO fabricjs doesn't support eraser natively. We may need to use its
      // clip feature or `globalcompositeoperation destination-out` to
      // implement the eraser.
      // {
      //   class: "correction_eraser",
      //   click: (e) => onEraserClick(e, true),
      //   name: "橡皮擦",
      // },

      {
        class: "correction_undo",
        click: (e) => onCanvasHistory(true),
        name: "撤销",
      },
      // {
      //   class: "correction_redo",
      //   click: (e) => onCanvasHistory(false),
      //   name: "重做",
      // },
      {
        class: "correction_reset",
        click: (e) => onClearBoard(),
        name: "重置",
      },
      {
        class: "correction_save",
        click: () => handleWrittenConfirm(),
        name: "保存",
      },
    ];

    return (
      <div className="answer-eara big_area" style={{ position: "relative", visibility: isReadyToShow ? 'visible' : 'hidden' }}>
        <img
          id="sourceImage"
          src={letterImage}
          alt=""
          style={photoWidth && photoHeight ? { display: "none" } : {}}
        />
        <div className="canvas_wrapper">
          <canvas id="canvas" ref={canvasRef}></canvas>
        </div>
        <div className="confirm_wrapper">
          <div className="confirm">
            {correction_collectons.map((item, index) => {
              return (
                <div
                  key={index}
                  className={item.class}
                  data-index={index}
                  onClick={debounce(item.click)}
                >
                  <span className={index == dataIndex ? "active" : ""}>
                    {item.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default WithErrorBoundary(CorrectCanvas);
