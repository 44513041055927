/* eslint-disable react/prop-types */
import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useMemo,
} from "react";
import queryString from "query-string";
import { request, csrfHeaders } from "../../utils/request";
import ConsultantTabTableContent from "../../../admin-utils/ConsultantTabTableContent";
import { ToastManager } from "../../utils/Toast";
import FollowupLog from "./FollowupLog";
import { format as formatTime } from "../../utils/formatAudioTime.js";
import HeadInfo from "../../../admin-utils/HeadInfo";
import DropdownMenu, {
  MenuItem,
  Menu,
  DropdownButton,
} from "../../../admin-utils/DropdownMenu";
import styled from "styled-components";

const StyledDropdownMenu = styled(DropdownMenu)`
  margin-left: 20px;
  margin-top: 10px;
  ${MenuItem} {
    text-align: center;
  }
  ${Menu} {
    padding: 10px;
    overflow-y: initial !important;
    overflow-x: initial !important;
  }
  &:first-of-type {
    margin-left: 0;
  }
  /* &:nth-of-type(2) {
    margin-right: 20px;
  } */
  &:last-of-type {
    /* margin-left: 0; */
    ${DropdownButton} {
      min-width: 150px;
    }
    ${Menu} {
      padding: 10px;
      width: 200px;
    }
  }
`;

const CheckBox = styled.div`
  border: 1px solid #3c8af0;
  color: #3d5774;
  box-shadow: inset 0.5px 0.5px 1px 1px #c8c8c8;
  background-color: #fafafa;
  border: 1px solid #3c8af0;
  padding: 8px;
  border-radius: 3px;
  display: block;
  position: relative;
  width: 18px;
  height: 18px;

  margin: 0 auto;
`;

const CheckedBox = styled.div`
  border: 1px solid #3c8af0;
  color: #3d5774;
  box-shadow: inset 0.5px 0.5px 1px 1px #c8c8c8;
  background-color: #fafafa;
  border: 1px solid #3c8af0;
  padding: 8px;
  border-radius: 3px;
  display: block;
  position: relative;
  width: 18px;
  height: 18px;

  margin: 0 auto;
  &::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    background-color: #3c8af0;
  }
`;
export const CheckBoxContain = ({ userId, binToCcWxUrl, defaultChecked }) => {
  const [isBound, setBind] = useState(defaultChecked);
  const binToCcWx = async (userId) => {
    const toast = ToastManager.showLoading(userId ? "绑定中" : "入群中");
    const data = {
      user_id: userId,
    };
    try {
      let res;
      if (userId)
        res = await request({
          url: binToCcWxUrl,
          method: "POST",
          headers: csrfHeaders,
          data,
        });
      else
        res = await request({
          url: binToCcWxUrl,
          method: "POST",
          headers: csrfHeaders,
        });

      if (res.data.success) {
        setBind(!isBound);
      }
    } catch (e) {
    } finally {
      toast.cancel();
    }
  };
  return isBound ? (
    <CheckedBox onClick={binToCcWx.bind(null, userId)} />
  ) : (
    <CheckBox onClick={binToCcWx.bind(null, userId)} />
  );
};

const StyledConsultantTabTableContent = styled(ConsultantTabTableContent)`
  & {
    margin: 10px 0 20px !important;
  }
  table {
    tbody {
      font-size: 14px;

      tr td {
        min-width: 30px;
        .log_note {
          white-space: pre-wrap !important;
        }
        .attention_unhandled {
          margin-top: -4px;
          border-radius: 30px;
          font-size: 14px;
          color: white;
          background: #3c8af0;
          cursor: pointer;
          text-align: center;
          padding: 4px 6px;
          white-space: nowrap;
          margin-right: 5px;
          margin-left: 8px;
        }
        .attention_unhandled + .attention_unhandled {
          margin-top: 8px;
        }
      }
      .large_td {
        min-width: 120px;
      }
    }
  }
`;

const StyledFollowupLog = styled(FollowupLog)`
  display: block;
`;

const RecordTr = ({ record, binToCcWxUrl, followUpLogUrl }) => {
  const triggerTypeRef = useRef();
  const triggerSourcesRef = useRef();
  // const triggerNewCourseRef = useRef();
  // const triggerHandlerRef = useRef();
  const subSourceNames = (subSources) =>
    subSources.reduce(
      (acc, source) =>
        `<a href=${source.url}>${
          acc ? acc + (source.name ? ", " + source.name : "") : source.name
        }</a>`,
      ""
    );
  useLayoutEffect(() => {
    const sourcesNodes = triggerSourcesRef.current.childNodes;
    triggerTypeRef.current.childNodes.forEach((n, index) => {
      n.style.cssText += `height: ${
        getComputedStyle(sourcesNodes[index]).height
      }`;
    });

    // triggerNewCourseRef.current.childNodes.forEach((n, index) => {
    //   n.style.cssText += `height: ${
    //     getComputedStyle(sourcesNodes[index]).height
    //   }`;
    // });
    // triggerHandlerRef.current.childNodes.forEach((n, index) => {
    //   n.style.cssText += `min-height: ${
    //     getComputedStyle(sourcesNodes[index]).height
    //   }; margin-bottom: 10px`;
    // });
  }, []);

  return (
    <tr>
      <td>{record.user && <HeadInfo user={record.user} />}</td>
      <td ref={triggerTypeRef}>
        {record.triggers.map((trigger, ind) => (
          <p key={ind} data-trigger={ind}>
            {trigger.actionTypeName}
          </p>
        ))}
      </td>
      <td ref={triggerSourcesRef}>
        {record.triggers.map((trigger, idx) => (
          <div key={idx} data-trigger={idx}>
            {trigger.sources.map((source, ind) => {
              return source.subSources ? (
                <p key={`${idx}-${ind}`}>
                  <span>{source.name}</span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `（${subSourceNames(source.subSources)}）`,
                    }}
                  ></span>
                </p>
              ) : (
                <p key={`${idx}-${ind}`}>
                  <a href={source.url}>{source.name}</a>
                </p>
              );
            })}
          </div>
        ))}
      </td>
      <td>
        {record.triggers.map((trigger, idx) =>
          trigger.sources.map((source, ind) =>
            source.subSources ? (
              source.subSources.map((subSource, i) => (
                <p key={`${idx}-${ind}-${i}`}>
                  {formatTime(subSource.time, "YYYY.MM.DD HH:mm")}
                </p>
              ))
            ) : (
              <p key={`${idx}-${ind}`}>
                {formatTime(source.time, "YYYY.MM.DD HH:mm")}
              </p>
            )
          )
        )}
      </td>
      <td>
        {record.triggers.map((trigger, idx) => (
          <p key={idx}>{trigger.courseName}</p>
        ))}
      </td>
      <td>
        {record.triggers.map((trigger, idx) => (
          <p key={idx}>{trigger.coursePhase}</p>
        ))}
      </td>
      <td>
        {record.triggers.map((trigger, idx) => (
          <p key={idx} style={{ textAlign: "center" }}>
            {trigger.isNewCourseUser ? "是" : ""}
          </p>
        ))}
      </td>
      <td>
        <CheckBoxContain
          userId={record.user.id}
          binToCcWxUrl={binToCcWxUrl}
          defaultChecked={record.user.isBindCcWx}
        />
      </td>
      <td>
        {record.triggers.map(
          (trigger, idx) =>
            trigger.checkUrl && (
              <CheckBoxContain
                binToCcWxUrl={trigger.checkUrl}
                key={idx}
                defaultChecked={trigger.ingroup}
              />
            )
        )}
      </td>
      <td>
        <p>{record.courseConsultant.name}</p>
      </td>
      <td>
        {/* {record?.triggers?.map?.((trigger, index) => (
          <div key={index}>
            {trigger.followUpLogUrl && ( */}
        <StyledFollowupLog
          followUpLogUrl={followUpLogUrl}
          btnName={`标记处理`}
        />
        {/* )}
          </div>
        ))} */}
      </td>
    </tr>
  );
};

const SystemCourseUser = (props) => {
  console.log(props);
  const {
    query: { action_type, source, study_package_category_id },
  } = queryString.parseUrl(window.location.href);

  const initialSources = useMemo(
    () => [{ name: "全部", value: "" }, ...props.sources],
    [props.sources]
  );

  const initialCategories = useMemo(
    () => [
      { actionTypeName: "全部", actionType: "", studyPackageCategoryId: "" },
      ...props.categories,
    ],
    [props.categories]
  );

  const defaultSourceIndex = initialSources.findIndex(
    (so) => so.value === source * 1
  );
  const defaultActionIndex = initialCategories.findIndex(
    (cate) =>
      cate.actionType === action_type * 1 &&
      cate.studyPackageCategoryId === study_package_category_id * 1
  );
  const [newSource, setSource] = useState(
    defaultSourceIndex === -1 ? "" : defaultSourceIndex
  );
  const [actionType, setActionType] = useState(
    defaultActionIndex === -1 ? "" : defaultActionIndex
  );
  const pageMounted = useRef();
  useEffect(() => {
    if (!pageMounted.current) {
      pageMounted.current = true;
    } else {
      ToastManager.showLoading("加载中...");
      const { query, url } = queryString.parseUrl(window.location.href);
      delete query.page;

      if (actionType !== "") {
        query.action_type = initialCategories[actionType].actionType;
        query.study_package_category_id =
          initialCategories[actionType].studyPackageCategoryId;
      }

      if (newSource !== "") {
        query.source = initialSources[newSource].value;
      }

      window.location.href = url + "?" + queryString.stringify(query);
    }
  }, [actionType, initialCategories, initialSources, newSource]);

  const url = window.location.href;
  const redirectBindCcUrl =
    url.indexOf("?") == -1
      ? `${url.replace("?order_by=bind_cc", "")}?order_by=bind_cc`
      : `${url.replace("&order_by=bind_cc", "")}&order_by=bind_cc`;

  return (
    <>
      <StyledDropdownMenu
        defaultActiveOptionIndex={
          defaultActionIndex === -1 ? 0 : defaultActionIndex
        }
        options={initialCategories}
        onOptionClick={(index) => {
          setActionType(index);
          console.log(index);
        }}
        isOptionClickedChange
        renderOption={(option) => {
          return <span>{option.actionTypeName}</span>;
        }}
      />
      <StyledDropdownMenu
        defaultActiveOptionIndex={
          defaultSourceIndex === -1 ? 0 : defaultSourceIndex
        }
        options={initialSources}
        onOptionClick={(index) => {
          setSource(index);
          console.log(index);
        }}
        isOptionClickedChange
        renderOption={(option) => {
          return <span>{option.name}</span>;
        }}
      />
      <StyledConsultantTabTableContent>
        <thead>
          <tr>
            <th>用户</th>
            <th>触发类型</th>
            <th>触发原因</th>
            <th>触发时间</th>
            <th>课程</th>
            <th>期数</th>
            <th>首购正价课</th>
            <th>
              <a href={redirectBindCcUrl}>绑定老师微信</a>
            </th>
            <th>入群</th>
            <th>课程顾问</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {props.records.map((record, index) => (
            <RecordTr
              key={index}
              record={record}
              binToCcWxUrl={props.binToCcWxUrl}
              followUpLogUrl={record.followUpLogUrl}
            />
          ))}
        </tbody>
      </StyledConsultantTabTableContent>
    </>
  );
};

export default SystemCourseUser;
