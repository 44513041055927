import React from "react";
import ConsultantQRCode from "./ConsultantQRCode.jsx";
import ConsultantGroupCode from "./ConsultantGroupCode.jsx";
import "./CourseConsultant.scss";
import iconClose from "promotion/icon_close.png";

import { request, csrfHeaders } from "@/components/utils/request";

import { DialogManager, Dialog } from "@/components/utils/Dialog";

const WithCourseConsultant = (props) => {
  if (props.course_teacher == "2") {
    console.log(props);
    const consultantContent = (copyCode) => {
      return [
        <div className="consultant_group_code_container" key="group_code">
          <div className="title">复制入群码</div>
          <div className="code">{props.group_code}</div>
          <div className="copy_btn" onClick={copyCode}>
            点击复制
          </div>
          <div className="alert">
            请<span>“点击复制”</span>入群码，<span>不要截图</span>发送给老师
          </div>
        </div>,
        <div className="consultant_group_qr_code" key="qr_code">
          <div>私信发送入群码的代码给老师</div>
          <div>老师会邀请您加入课程学习群</div>
          <div className="qr_code_box">
            <img src={props.cc_qrcode_url} />
          </div>
        </div>,
      ];
    };
    return <ConsultantGroupCode {...props} renderContent={consultantContent} />;
  } else {
    console.log(props);
    const onAdClick = () => {
      request({
        url: props.course_recommend_url,
        method: "POST",
        headers: csrfHeaders,
      });

      let dialog = DialogManager.open(
        <Dialog className="ad-qrcode-dialog">
          <img className="btn-close" src={iconClose} onClick={() => dialog.close()} />
          <div>
            <img src={props.cc_qrcode_url} style={{ width: '100%' }} />
          </div>
          <div>{props.ad_note}</div>
        </Dialog>
      );
    };
    const consultantContent = () => {
      return (
        <>
          {props.ad_photo_url &&
            <div className="container-ad"><img src={props.ad_photo_url} style={{ width: '100%' }} onClick={onAdClick} /></div>
          }
          {!props.hide_course_teacher &&
            <div className="consultant_qr_code_container">
              <img src={props.teacher_photo} alt="" className="teacher_photo" />
              <span className="teacher_name">{props.teacher_name}</span>
              {props.teacher_wx && (
                <span className="teacher_wx">{"微信号：" + props.teacher_wx}</span>
              )}
              <div className="qr_code_box">
                <img src={props.cc_qrcode_url} />
              </div>
              <span className="description">
                {props.description ? props.description : "长按识别添加学习顾问"}
              </span>
            </div>
          }
        </>
      );
    };
    return <ConsultantQRCode {...props} renderContent={consultantContent} />;
  }
};

export default WithCourseConsultant;
