import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "../../utils/react-global-state";

import Card1 from "new_experience/card_block1.png";
import Card2 from "new_experience/card_block2.png";
import Card3 from "new_experience/card_block3.png";
import Card4 from "new_experience/card_block4.png";
import Card5 from "new_experience/card_block5.png";

const cardImgMap = [Card1, Card2, Card3, Card4, Card5];

const CardBlockBox = React.memo(function CardBlockBox({ children }) {
  const getRandomNumber = useSelector((state) => state.getRandomNumber);
  const getRandomNum = useMemo(() => getRandomNumber(1, 5), [getRandomNumber]);

  return (
    <div className="card_block_box">
      <div
        className="card_block_box_wrapper"
        style={{ backgroundImage: `url(${cardImgMap[getRandomNum - 1]})` }}
      >
        {children}
      </div>
    </div>
  );
});

CardBlockBox.propTypes = {
  children: PropTypes.any,
};

export default CardBlockBox;
