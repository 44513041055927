import React from "react";
import PropTypes from "prop-types";
import { request, csrfHeaders } from "../utils/request";
import { ToptipsManager } from "../utils/Toptips";
import Raven from "raven-js";
// import {Picker} from '../utils/Picker'
import { debounce, isEmpty, print } from "../utils/helpers";
import "./CollectEdit.scss";
export default class CollectEdit extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props)
    const nickname = props.baby ? props.baby.nickname : "";
    this.state = {
      nickname: nickname,
      gender: null,
      grade: null,
      pickerShow: false,
      enableSubmit: false,
    };
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.checkSubmitStatus();
      }
    );
  };

  chooseGrade = (e) => {
    e.preventDefault();
    const groups = this.props.grades.map((k) => {
      return { label: k.text, value: k.value };
    });
    this.setState({
      groups: [
        {
          items: groups,
        },
      ],
      pickerShow: true,
      defaultSelect: [
        {
          itmes: groups[0],
        },
      ],
      onChange: (selected) => {
        // console.log(selected)
        let value = "";
        let label = "";
        selected.forEach((s, i) => {
          var item = this.state.groups[i]["items"][s];
          value = item.value;
          label = item.label;
        });
        this.setState(
          {
            pickerShow: false,
            grade: value,
            gradeLabel: label,
          },
          () => {
            // console.log(this.state.pickerShow)
            // console.log(this.state.grade, this.state.gradeLabel)
            this.checkSubmitStatus();
          }
        );
      },
      onCancel: (e) => {
        // console.log('onCancel')
        this.setState(
          {
            pickerShow: false,
          },
          () => {
            // console.log(this.state.pickerShow)
            this.checkSubmitStatus();
          }
        );
      },
    });
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.checkSubmitStatus()) {
      return;
    }
    let gender = parseInt(this.state.gender);
    let grade = parseInt(this.state.grade);
    let schoolType = parseInt(this.state.schoolType);
    let campusId = parseInt(this.state.campusId);
    if (grade === null && this.props.grades) {
      ToptipsManager.warn("请重新选择年级");
      return;
    } else if (gender === null && this.props.genders) {
      ToptipsManager.warn("请重新选择性别");
      return;
    } else if (schoolType === null) {
      ToptipsManager.warn("请重新选择学校");
      return;
    } else if (campusId === null) {
      ToptipsManager.warn("请重新选择学校");
      return;
    }
    let data = {
      nickname: this.state.nickname,
      school: this.state.school,
      school_type: schoolType,
      campus_id: campusId,
    };

    if (!isNaN(gender) && gender !== null) {
      data["gender"] = gender;
    }
    if (grade !== null && !isNaN(grade)) {
      data["grade"] = grade;
    }
    console.log(data);
    request({
      url: this.props.collectUpdateUrl,
      method: "POST",
      headers: csrfHeaders,
      data: data,
    })
      .then((res) => {
        // console.log(res)
        if (res.data.status_code == 200) {
          window.location.href = "/schooser/collect_index";
        }
      })
      .catch((err) => {
        // console.log(err)
        Raven.captureException(err);
      });
  };

  /**
   * 父组件想要获取的子组件的值
   * fillSchoolText：填写模式下，填写学校名称
   * selectValue：左侧上海区域内的 相应的区名称
   * selectSubCampuseId：右侧学校所选的学校id
   * selectSubValue：右侧学校所选的学校名称
   */

  /**
   * areaData: 点击不同的type获取的接口数据
   * getSchoolStatus: 显示填写学校 / 选择学校的组件
   * selectTypeIndex: 点击 ”对口公办/民办/体制内“对应的索引index
   */
  getSchoolStatusInfo = (areaData, getSchoolStatus, selectTypeIndex) => {
    this.setState({
      areaData,
      getSchoolStatus,
      schoolType: selectTypeIndex,
    });
    // console.log("选择的学校类型 0/1/2: ", selectTypeIndex);

    let areaNames = [];
    let campusesData = [];
    for (let item of areaData) {
      areaNames.push({ name: item.name });
      campusesData.push(item.campuses);
    }
    this.setState(
      {
        areaNames,
        campusesData,
      },
      () => {
        this.checkSubmitStatus();
      }
    );
  };

  /**
   * 获取填写的学校名称
   * fillSchoolText: 填写学校组件，用户input输入的值
   */
  getFillSchoolInfo = (fillSchoolText) => {
    this.setState(
      {
        school: fillSchoolText,
        campusId: -1,
      },
      () => {
        // console.log("父级_获取填写的学校名称: ", this.state.fillSchoolText)
        this.checkSubmitStatus();
      }
    );
  };

  /**
   * selectValue：左侧上海区域内的 相应的区名称
   * selectSubCampuseId：右侧学校所选的学校id
   * selectSubValue：右侧学校所选的学校名称
   */
  getSchoolIdAndName = (selectValue, selectSubCampuseId, selectSubValue) => {
    // console.log("父级_区域名称：", selectValue)
    // console.log("父级_右侧所选学校id：", selectSubCampuseId)
    // console.log("父级_右侧所选学校名称：", selectSubValue)

    let school;
    if (selectSubCampuseId == -1) {
      school = `${selectValue}, ${selectSubValue}`;
    } else {
      school = `${selectSubValue}`;
    }

    this.setState(
      {
        campusId: selectSubCampuseId,
        school: school,
      },
      () => {
        this.checkSubmitStatus();
      }
    );
  };

  /**
   * selectIndex：获取弹框左侧 点击区获取的 index
   * selectSubIndex：获取弹框右侧 点击学校获取的 index
   */
  getAreaAndSchoolIndex = (selectIndex, selectSubIndex) => {
    this.setState(
      {
        selectLeftAreaIndex: selectIndex,
        selectRightSchoolIndex: selectSubIndex,
      },
      () => {
        this.checkSubmitStatus();
      }
    );
  };

  checkSubmitStatus() {
    const {
      nickname,
      grade,
      gender,
      campusId,
      school,
      schoolType,
    } = this.state;
    var canBeSubmit = nickname && school ? true : false;
    if (campusId == null || schoolType == null) {
      canBeSubmit = false;
    }
    if (
      this.props.genders &&
      this.props.grades &&
      (grade == null || gender == null)
    ) {
      canBeSubmit = false;
    }
    // console.log(nickname, school)
    // if ((typeof school === 'string' && school.length != 0) || (typeof nickname === 'string' && nickname.length != 0)) {
    //   console.log('canBeSubmit false')
    //   canBeSubmit = false
    // }

    print(isEmpty(nickname), isEmpty(school));
    if (isEmpty(nickname) || isEmpty(school)) {
      // console.log('canBeSubmit')
      canBeSubmit = false;
    }
    // console.log(nickname, grade, gender, school, schoolType)
    this.setState({
      enableSubmit: canBeSubmit,
    });
    // console.log('enableSubmit',canBeSubmit)
    return canBeSubmit;
  }

  render() {
    const { grades, genders } = this.props;
    const {
      areaData,
      getSchoolStatus,
      areaNames,
      campusesData,
      selectLeftAreaIndex,
      selectRightSchoolIndex,
    } = this.state;
    return (
      <div className="component_collect_edit">
        <div className="collect_edit_box">
          <div className="header_top">
            <div className="get_test_paper">
              <h3>
                填写信息，即可拿到
                <strong>真题测试卷</strong>
              </h3>
            </div>
            <p>我们会保护好你的隐私，信息不会外露</p>
          </div>
          <form onSubmit={debounce(this.submit)}>
            <div className="form-row">
              <label className="label">小名:</label>
              <div className="input">
                <input
                  type="text"
                  value={this.state.nickname}
                  name="nickname"
                  id="nickname"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            {genders && (
              <div className="form-row babies_sex">
                <label className="label">性别:</label>
                <div className="input gender">
                  {genders.map((k) => (
                    <div key={k.value} className="input_radio">
                      <input
                        type="radio"
                        value={k.value}
                        name="gender"
                        id={`raido_${k.value}`}
                        checked={k.value == this.state.gender}
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor={`raido_${k.value}`}
                      >{`${k.text}宝`}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {grades && (
              <div
                className="baby_grade form-row"
                id="grade"
                onClick={this.chooseGrade}
              >
                <label className="label">年级：</label>
                <div className="grade">
                  {this.state.gradeLabel ? (
                    this.state.gradeLabel
                  ) : (
                    <p style={{ color: "#ccc" }}>请选择</p>
                  )}
                </div>
              </div>
            )}

            {/* 报考的学校模块 */}
            <SchoolEntranceButtonSection
              schoolTypes={this.props.schoolTypes}
              getSchoolStatus={this.getSchoolStatusInfo}
            />

            {getSchoolStatus &&
              (areaData.length <= 0 ? (
                <FillSchoolSection
                  areaData={areaData}
                  getFillSchoolText={this.getFillSchoolInfo}
                />
              ) : (
                <ChooseSchoolSection
                  areaData={areaData}
                  getSchoolIdAndName={this.getSchoolIdAndName}
                  getAreaAndSchoolIndex={this.getAreaAndSchoolIndex}
                  areaNames={areaNames}
                  campusesData={campusesData}
                  selectLeftAreaIndex={selectLeftAreaIndex}
                  selectRightSchoolIndex={selectRightSchoolIndex}
                />
              ))}

            <div
              className={
                this.state.enableSubmit
                  ? "input_submit"
                  : "input_submit_disable"
              }
            >
              <input type="submit" value="确定" />
            </div>
          </form>
        </div>
        {/* <Picker groups={this.state.groups} onCancel={this.state.onCancel} show={this.state.pickerShow} onChange={this.state.onChange} defaultSelect={this.state.defaultSelect}></Picker> */}
      </div>
    );
  }
}

class SchoolEntranceButtonSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectTypeIndex: -1,
      areaStatusShow: false, // 判断是否显示 填写或者选择学校的模块
    };
  }

  handleSchoolButtonClick = (e) => {
    e.preventDefault();
    let schoolIndex = e.currentTarget.getAttribute("data-index");
    // console.log(schoolIndex)
    this.setState({
      selectTypeIndex: schoolIndex,
    });

    request({
      url: "/schooser/get_schools?school_type=" + schoolIndex,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        const data = resp.data;
        // console.log(data)
        this.setState({
          areaData: data.areas,
          areaStatusShow: true,
        });

        let areaData = data.areas;
        let { areaStatusShow, selectTypeIndex } = this.state;
        this.props.getSchoolStatus(areaData, areaStatusShow, selectTypeIndex);
      })
      .catch((err) => {
        Raven.captureException(err);
      });
  };

  render() {
    const { schoolTypes } = this.props;
    const { selectTypeIndex } = this.state;
    return (
      <div className="school_entrance_button common_style form-row">
        <label className="label">报考：</label>
        <div className="entrance_button_switch">
          {schoolTypes.map((school, index) => (
            <div
              key={index}
              className={`school_button ${
                selectTypeIndex == index ? "active" : ""
              }`}
              data-index={index}
              onClick={this.handleSchoolButtonClick}
            >
              {school.text}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class FillSchoolSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fillSchoolText: "",
    };
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.props.getFillSchoolText(value);

    this.setState({
      fillSchoolText: value,
    });
  };
  render() {
    const { areaData } = this.props;
    const { fillSchoolText } = this.state;
    return (
      <div
        className="school_choose_list common_style form-row"
        style={{ background: areaData.length > 0 ? null : "initial" }}
      >
        <label className="label">学校：</label>
        <div className="fill_school_section">
          <input
            type="text"
            placeholder="请填写学校名称"
            value={fillSchoolText}
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    );
  }
}

class ChooseSchoolSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolDialogStatus: false,
    };
  }

  handleChooseClick = () => {
    this.setState(
      {
        schoolDialogStatus: true,
      },
      () => {
        let scrollAreaEleTop = document.getElementById("area_list");
        let scrollSchoolEleTop = document.getElementById("sub_school_list");
        scrollAreaEleTop.scrollTop = 40 * this.props.selectLeftAreaIndex;
        scrollSchoolEleTop.scrollTop = 40 * this.props.selectRightSchoolIndex;
      }
    );
  };

  showSchoolDialog = () => {
    this.setState({
      schoolDialogStatus: false,
    });
  };

  getRightSchoolName = (selectSubValue) => {
    this.setState({
      selectSubValue,
    });
  };

  render() {
    const { getSchoolIdAndName, areaNames, campusesData } = this.props;
    const { schoolDialogStatus, selectSubValue } = this.state;
    return (
      <div className="school_choose_list common_style form-row">
        <label className="label">学校：</label>
        <div className="choose_school_section">
          <div className="sign_school_text" onClick={this.handleChooseClick}>
            {selectSubValue ? (
              <p style={{ color: "#333" }}>{selectSubValue}</p>
            ) : (
              "请选择报名学校"
            )}
          </div>
          {/* 选择学校列表弹框 */}
          {
            <SchoolDialogSelectLists
              schoolDialogStatus={schoolDialogStatus}
              areaNames={areaNames}
              campusesData={campusesData}
              getSchoolIdAndName={getSchoolIdAndName}
              showSchoolDialog={this.showSchoolDialog}
              getRightSchoolName={this.getRightSchoolName}
              getAreaAndSchoolIndex={this.props.getAreaAndSchoolIndex}
            />
          }
        </div>
      </div>
    );
  }
}

class SchoolDialogSelectLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIndex: -1,
      selectSubIndex: -1,
    };
  }

  // 关闭学校弹框列表
  btnCloseClick = () => {
    const {
      selectSubCampuseId,
      selectSubValue,
      selectValue,
      selectIndex,
      selectSubIndex,
    } = this.state;
    // console.log("左侧索引Index：", selectIndex + ", " + "右侧索引Index：", selectSubIndex)
    // console.log("左侧区域：", selectValue)
    // console.log("右侧所选学校id: ", selectSubCampuseId + "," + "右侧所选学校名称: ", selectSubValue)
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择您孩子报考的学校");
      return;
    } else if (this.state.selectSubIndex === -1) {
      ToptipsManager.warn("请选择您孩子报考的学校");
      return;
    } else {
      // 传给父级所需要的一些值
      this.props.getSchoolIdAndName(
        selectValue,
        selectSubCampuseId,
        selectSubValue
      );

      // 隐藏弹框
      this.props.showSchoolDialog();

      // 获取右侧所选学校名称
      this.props.getRightSchoolName(selectSubValue);

      // 获取区域索引及学校索引
      this.props.getAreaAndSchoolIndex(selectIndex, selectSubIndex);
    }
  };

  // 点击 上海相应的区
  areaListClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const value = e.currentTarget.getAttribute("data-value");
    const selectCampuses = this.props.campusesData[index];

    this.setState({
      selectIndex: index,
      selectValue: value,
      selectCampuses: selectCampuses,
      selectSubIndex: -1,
    });
  };

  subCampuseClick = (e) => {
    const id = e.currentTarget.getAttribute("sub-school-id");
    const index = e.currentTarget.getAttribute("sub-school-index");
    const value = e.currentTarget.getAttribute("sub-school-value");
    this.setState({
      selectSubCampuseId: id,
      selectSubIndex: index,
      selectSubValue: value,
    });
  };

  render() {
    const { areaNames, schoolDialogStatus } = this.props;
    const { selectCampuses, selectIndex, selectSubIndex } = this.state;
    return (
      <div
        className="school_picker_select"
        style={{ display: schoolDialogStatus ? "block" : "none" }}
      >
        <div className="overlay"></div>
        <div className="pick_mask">
          <h3>选择学校</h3>
          <div className="close_button_right" onClick={this.btnCloseClick}>
            确定
          </div>
          <div style={{ float: "left" }} className="area_list" id="area_list">
            {areaNames &&
              areaNames.map((area, index) => (
                <div
                  key={index}
                  data-index={index}
                  data-value={area.name}
                  onClick={this.areaListClick}
                  className={`area_list_li ${
                    selectIndex == index ? "highlight" : null
                  }`}
                >
                  {area.name}
                </div>
              ))}
          </div>
          <div id="sub_school_list" className="sub_school_value">
            {selectCampuses &&
              selectCampuses.map((school, idx) => (
                <div
                  key={school.id}
                  sub-school-index={idx}
                  sub-school-id={school.id}
                  sub-school-value={school.name}
                  onClick={this.subCampuseClick}
                  className={`sub_school_li ${
                    selectSubIndex == idx ? "highlight" : null
                  }`}
                >
                  {school.name}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

CollectEdit.propTypes = {
  baby: PropTypes.object,
  schoolTypes: PropTypes.array,
};
