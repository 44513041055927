import React from "react";
import PropTypes from "prop-types";

import ComponentProxy from "../core/ComponentProxy";

import BaseMemeberCardRecharge from "../shared/MemberCardRecharge";
import "./MemberCardRecharge.scss";

// class StudyPackageStatus extends React.Component {

//   constructor(props) {
//     super(props)

//     var current_time = new Date()
//     var start_time = new Date(this.props.study_package_activity.start_time)
//     var end_time = new Date(this.props.study_package_activity.end_time)
//     var url = ''
//     var status = ''

//     if (current_time < start_time) {
//       url = "javascript:void(0);"
//       status = '等待开课'
//     } else {
//       url = this.props.study_package_activity.study_package_url
//       if (current_time > end_time) {
//         status = '课程结束'
//       } else {
//         status = '上课中'
//       }
//     }

//     this.state = {
//       showRechargeOptions: this.props.remain_amount <= 0,
//       url: url,
//       status: status
//     }

//   }

//   render() {
//     const study_package_activity = this.props.study_package_activity
//     const status = this.state.status
//     const url = this.state.url

//     return (
//       <a href={url} style={{ backgroundImage:  "url(" + study_package_activity.photo_url + ")" }}>
//         <h2>{study_package_activity.name}</h2>
//         <p>{study_package_activity.start_date} — {study_package_activity.end_date}</p>
//         <span>{status}</span>
//       </a>
//     )
//   }
// }

class OptionItem extends React.Component {
  onClick = (e) => {
    if (typeof this.props.onItemClick === "function") {
      this.props.onItemClick(this.props.option);
    }
  };

  render() {
    const option = this.props.option;
    return (
      <li>
        <a href="javascript:void(0);" onClick={this.onClick}>
          <div className="recharge_point">
            <p>
              <i>{option.count}</i>点／¥{option.price}
            </p>
            <span>
              原价：<del>¥{option.old_price}</del>
            </span>
          </div>
        </a>
      </li>
    );
  }
}

// class OptionItemList extends React.Component {

//   render() {
//     const items = this.props.recharge_options.map(option => <OptionItem option={option} key={option.count} onItemClick={this.props.onItemClick} />)

//     return (
//       <div className="recharge_lists">
//         <ul>
//           {items}
//         </ul>
//       </div>
//     )
//   }
// }

export default class MemberCardRecharge extends BaseMemeberCardRecharge {
  constructor(props) {
    super(props);

    this.state = {
      showRechargeOptions: this.props.remain_amount <= 0,
    };
  }

  onShowRechargeOptionsClick = (e) => {
    this.setState({
      showRechargeOptions: true,
    });
  };

  couponClick = (e) => {
    window.location.href = "/trial_coupons/user_trial_coupon";
  };

  audioShareClick = () => {
    console.log("免费语音报名链接");
  };

  pricesShow(props) {
    if (props.valid_card) {
      return (
        <span style={{ paddingTop: "15px", display: "block" }}>
          有效期至：{props.valid_period}
        </span>
      );
    } else {
      return (
        <div>
          <p>
            原价：<del>¥200</del>
          </p>
          <label>
            限时特惠：<i>¥100</i>
          </label>
        </div>
      );
    }
  }

  render() {
    const showRechargeOptions = this.state.showRechargeOptions;
    const study_package_activities = this.props.study_package_activities;

    // const valid_card = this.props.valid_card
    return (
      <div className="comp-member-card-recharge">
        {/* {
          (study_package_activities.length > 0) ?
          <div className="course_module_entrance">
            <div className="course_entracnce_title">我的课程</div>
            {study_package_activities.map(study_package_activity => <StudyPackageStatus study_package_activity={study_package_activity} key={study_package_activity.id}/>
            )}
          </div> : ''
        } */}
        {!!this.props.remain_amount && (
          <div className="vip_points_recharge">
            <div className="points_recharge_top">
              <div className="vip_coupon">
                <a href="javascript:void(0);" onClick={this.couponClick}>
                  优惠券
                </a>
              </div>
              <div className="user_point_remainder">
                {this.props.remain_amount}
                <span>剩余点数</span>
              </div>
            </div>

            {/* {!showRechargeOptions ?
            <div className="recharge_point_hide" onClick={this.onShowRechargeOptionsClick}>
              <span>充值点数</span>
            </div> : ''}

          <div className="point_recharge_intro" style={{ display: !showRechargeOptions ? 'none' : 'block' }}>
            <div className="recharge_headline">
              <p>充值点数</p>
              <span>1点=1个讲座/圆桌主题(少数例外)</span>
            </div>

            <OptionItemList onItemClick={this.onItemClick} recharge_options={this.props.recharge_options} />
          </div> */}

            {this.props.valid_card && (
              <div className="vip_unlimited_card">
                <div className="unlimited_card">
                  <div className="unlimited_prices">
                    {this.pricesShow(this.props)}
                  </div>
                  <div className="unlimited_card_text">畅听卡</div>
                </div>

                {!this.props.valid_card ? (
                  <div
                    className="btn_submit"
                    onClick={this.onBuySuperCardClick}
                  >
                    <a href="javascript:void(0)">立即开通</a>
                  </div>
                ) : (
                  ""
                )}

                <div className="unlimited_card_explain">
                  <span>
                    {this.props.valid_card
                      ? "有效期内免费畅听所有讲座/圆桌主题"
                      : "1个月内免费畅听所有讲座/圆桌主题"}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="vip_welfare_time" style={{ display: "none" }}>
          <div className="welfare_time_title">VIP会员限时福利</div>
          <a href="javascript:void(0);" onClick={this.audioShareClick}>
            <img
              src="https://upload.fireflybaby.cn/campaign/40/e99230ecaeaedf66118e8bf61c5cc5e5.jpg"
              alt=""
            />
          </a>
        </div>
      </div>
    );
  }
}

MemberCardRecharge.propTypes = Object.assign(
  {},
  BaseMemeberCardRecharge.propTypes,
  {
    remain_amount: PropTypes.number.isRequired,
    study_package_activities: PropTypes.array.isRequired,
  }
);
