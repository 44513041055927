import { ApplicationStore } from "../utils/react-global-state";
class AnalysisStore extends ApplicationStore {
  constructor(props) {
    super(props);
    this.defaultStates = {};
  }

  actionCreators = () => ({
    showRecommendExams: (payload) => ({
      type: "show_recommend_exams",
      payload,
    }),
  });

  reducer = (states, action) => {
    if (action.type === "show_recommend_exams") {
      states.show_recommend_exams = action.payload;
      return { ...states };
    }
  };
}

export default new AnalysisStore().init();
