import React, { Component } from "react";
import { formatDotDate, formatHourMinute } from "../utils/formatAudioTime";
import PropTypes from "prop-types";

import { debounce } from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";

import "./VirtualAccountRecords.scss";
import icon_invite_credit from "activities/icon_invite_credit.gif";
import icon_ticket from "activities/icon_ticket.png";
import icon_rich from "activities/icon_rich.png";
import icon_pj from "activities/icon_pj.png";
import icon_bm from "activities/icon_bm.png";
import icon_yq from "activities/icon_yq.png";
import icon_refund from "activities/icon_refund.png";

import icon_default from "activities/icon_default.png";
import icon_experience from "activities/icon_experience.png";
import icon_lecture from "activities/icon_lecture.png";
import icon_publicnumber from "activities/icon_publicnumber.png";
import icon_scanning from "activities/icon_scanning.png";
import icon_signup from "activities/icon_signup.png";

const ButtonPoster = ({ url, photo }) => {
  return (
    <a className="buttom_poster" href={url}>
      <img src={photo} alt="" />
    </a>
  );
};

const HasIncomeRecord = ({
  virtualAccountRecords,
  dataRecordsStatus,
  handleLoadingMoreRecord,
}) => {
  return (
    <div className="has_income_record">
      <ul>
        {virtualAccountRecords.map((record, index) => (
          <RecordsList record={record} key={index} />
        ))}
      </ul>
      {virtualAccountRecords.length >= 5 && dataRecordsStatus && (
        <div
          className="load_more_record"
          onClick={debounce(handleLoadingMoreRecord)}
        >
          <span>更多记录</span>
        </div>
      )}
    </div>
  );
};

const RecordsList = ({ record }) => {
  let iconImg = "";
  let recordName = "";
  let recordTitle = "";
  let source = record.source;
  let type = record.type;
  /**
   * source: usage like:
   * 0 购买报名
   * 1 消耗金额 (购买1) - 扣除奖学金金额
   * 2 消耗金额 (购买2)
   * 3 朋友接受邀请，报名课程 (邀请用户所得)
   * 4 课程评价奖励
   * 5 后台发放添加操作
   * 6 退款
   * 7 分享海报
   * 8 课程打卡奖励
   * 9 课程完课奖励
   * 10 朋友扫描海报
   * 11 邀请活动小孩信息
   * 12 朋友接受邀请，关注公众号
   * 13 朋友接受邀请，报名经验讲座 / 朋友接受邀请，报名领教育用品系列活动
   */
  switch (source) {
    case 0:
      iconImg = (
        <img
          src={
            type == "StudyPackageActivity" ? `${icon_bm}` : `${icon_experience}`
          }
          alt=""
        />
      );
      recordTitle =
        type == "StudyPackageActivity" ? "报名课程" : "报名经验讲座";
      recordName =
        type == "StudyPackageActivity"
          ? `《${record.name}》`
          : `${record.name}`;
      break;
    case 1:
      iconImg = <img src={icon_rich} alt="" />;
      recordTitle = "奖学金扣除";
      recordName = `《${record.name}》`;
      break;
    case 3:
      iconImg = <img src={icon_signup} alt="" />;
      recordTitle = "朋友接受邀请，报名课程";
      recordName = `《${record.name}》`;
      break;
    case 4:
      iconImg = <img src={icon_pj} alt="" />;
      recordTitle = "课程评价奖励";
      recordName = `《${record.name}》`;
      break;
    case 5:
      iconImg = <img src={icon_rich} alt="" />;
      recordTitle = "后台发放";
      recordName = `《${record.name}》`;
      break;
    case 6:
      iconImg = <img src={icon_refund} alt="" />;
      recordTitle = "退款";
      recordName = `《${record.name}》`;
      break;
    case 7:
      iconImg = <img src={icon_default} alt="" />;
      recordTitle = "分享海报";
      recordName = `${record.name}`;
      break;
    case 8:
      iconImg = <img src={icon_rich} alt="" />;
      recordTitle = "课程打卡奖励";
      recordName = `《${record.name}》`;
      break;
    case 9:
      iconImg = <img src={icon_rich} alt="" />;
      recordTitle = "课程完课奖励";
      recordName = `《${record.name}》`;
      break;
    case 10:
      iconImg = <img src={icon_scanning} alt="" />;
      recordTitle = "朋友扫描海报";
      recordName = `${record.name}`;
      break;
    case 11:
      iconImg = <img src={icon_scanning} alt="" />;
      recordTitle = "邀请活动小孩信息";
      recordName = `${record.name}`;
      break;
    case 12:
      iconImg = <img src={icon_publicnumber} alt="" />;
      recordTitle = "朋友接受邀请，关注公众号";
      recordName = `${record.name}`;
      break;
    case 13:
      iconImg = <img src={icon_lecture} alt="" />;
      recordTitle = `${
        record.activity_mode == "live"
          ? "朋友接受邀请，报名经验讲座"
          : "朋友接受邀请，报名领教育用品系列活动"
      }`;
      recordName = `${record.name}`;
      break;
    default:
      iconImg = <img src={icon_default} alt="" />;
      recordTitle = "其他";
      recordName = `${record.name}`;
      break;
  }

  return (
    <li>
      <div className="course_evaluate_reward">
        <div className="content_box">
          {iconImg}
          <div className="content">
            <div className="title">{recordTitle}</div>
            <div className="name">{record.name && recordName}</div>
            <div className="invite_time_person">
              <p>
                {formatDotDate(record.time) == formatDotDate(new Date())
                  ? "今天"
                  : formatDotDate(record.time)}
                <span>{formatHourMinute(record.time)}</span>
              </p>
              {record.invited_user && <p>{record.invited_user.name}</p>}
            </div>
          </div>
        </div>
        <div className={`money ${record.money < 0 ? "color_blank" : ""}`}>
          {record.money >= 0 ? `+${record.money}` : record.money}元
        </div>
      </div>
    </li>
  );
};

const NoIncomeRecord = () => {
  return (
    <div className="no_income_record">
      <div className="content">
        <img src={icon_ticket} alt="" />
        <p>您还没有获得过奖学金</p>
        <p>去邀请朋友赚奖学金吧！</p>
      </div>
    </div>
  );
};

export default class VirtualAccountRecords extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      data_records_status: true,
      virtual_account_records: this.props.virtual_account_records,
      page: 2,
    };
  }

  handleLoadingMoreRecord = () => {
    this.toast = ToastManager.showLoading("拼命加载中...");
    let request_record_url = this.props.get_records_url;
    let params = {
      input: {
        page: this.state.page,
        count: 10,
      },
    };
    request({
      url: request_record_url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        console.log("data: ", data);
        this.setState({
          virtual_account_records: this.state.virtual_account_records.concat(
            data.virtual_account_records ? data.virtual_account_records : []
          ),
          data_records_status: data.virtual_account_records
            ? data.virtual_account_records.length < 10
              ? false
              : true
            : false,
          page: this.state.page + 1,
        });
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
      });
  };

  render() {
    const { invitation_rewards_url } = this.props;
    const { virtual_account_records, data_records_status } = this.state;
    return (
      <div className="comp_virtual_account_records">
        {virtual_account_records.length > 0 ? (
          <HasIncomeRecord
            virtualAccountRecords={virtual_account_records}
            dataRecordsStatus={data_records_status}
            handleLoadingMoreRecord={this.handleLoadingMoreRecord}
          />
        ) : (
          <NoIncomeRecord />
        )}
        {/* <ButtonPoster url={invitation_rewards_url} photo={icon_invite_credit} /> */}
      </div>
    );
  }
}

VirtualAccountRecords.propTypes = {
  virtual_account_records: PropTypes.array,
};
