/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { memo } from "react";
import styled from "styled-components";
import HeadInfo from "../../../admin-utils/HeadInfo";
import { format as formatTime } from "../../utils/formatAudioTime.js";
import FollowupLog from "./FollowupLog";
import ConsultantTabTableContent from "../../../admin-utils/ConsultantTabTableContent";
import TableFilterBtnGroup from "./TableFilterBtnGroup";

const StyledConsultantTabTableContent = styled(ConsultantTabTableContent)`
  & {
    margin: 10px 0 20px !important;
  }
  table {
    tbody {
      font-size: 14px;
      tr td {
        .log_note {
          white-space: pre-wrap !important;
        }
        .attention_unhandled {
          margin-top: -4px;
          border-radius: 30px;
          font-size: 14px;
          color: white;
          background: #3c8af0;
          cursor: pointer;
          width: 78px;
          text-align: center;
          height: 28px;
          line-height: 28px;
        }
      }
    }
  }
`;

const PotentialFollowup = ({ ...props }) => {
  // console.log("PotentialFollowup_props: ", props);

  const auditStatusText = (audit) => {
    let auditText = "";
    switch (audit.state) {
      case "待审核":
        auditText = <p className="wait_verify">待审核</p>;
        break;
      case "通过":
        auditText = <p className="success_verify">通过</p>;
        break;
      case "驳回":
        auditText = (
          <p className="reject_verify">
            <span>驳回</span>
            <span>{audit?.reason}</span>
          </p>
        );
        break;
      default:
        auditText = "";
        break;
    }
    return auditText;
  };

  return (
    <>
      <TableFilterBtnGroup isShowAuditStateFilter isShowDateFilter />
      <StyledConsultantTabTableContent>
        <thead>
          <tr>
            <th>用户</th>
            <th>年级</th>
            <th>意向课程</th>
            <th>跟进日志</th>
            <th>提交时间</th>
            <th>状态</th>
            <th>来源</th>
            <th>审核人</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {props.records.map((record, index) => (
            <tr key={index}>
              <td>{record.user && <HeadInfo user={record.user} />}</td>
              <td>
                <div className="user_intent_label">{record?.baby?.grade}</div>
              </td>
              <td>
                <div className="user_intent_label">
                  T4：幼升小冲刺班/一年级拓展班
                </div>
              </td>
              <td>
                <div className="user_intent_label log_note">
                  {record?.log?.logNote}
                </div>
              </td>
              <td>
                <div className="user_intent_label">
                  {formatTime(record?.log?.createdAt, "YYYY.MM.DD HH:mm")}
                </div>
              </td>
              <td>
                <div className="user_intent_label">
                  {record.audit && auditStatusText(record.audit)}
                </div>
              </td>
              <td>
                <div className="user_intent_label">
                  {record.highIntentionReason}
                </div>
              </td>
              <td>
                <div className="user_intent_label">
                  {record?.audit?.userName}
                </div>
              </td>
              <td>
                <FollowupLog followUpLogUrl={record.followUpLogUrl} />
              </td>
            </tr>
          ))}
        </tbody>
      </StyledConsultantTabTableContent>
    </>
  );
};
export default memo(PotentialFollowup);
