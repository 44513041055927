import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import gongban from "groups/gongban.jpg";
import minban from "groups/minban.png";
import tizhiwai from "groups/tizhiwai.png";
import "./CollectShow.scss";

export default class CollectShow extends PureComponent {
  render() {
    // console.log(this.props)

    const { qrcode_url, qrcode_group_index } = this.props;
    const qrcode_groups = [gongban, minban, tizhiwai];
    return (
      <div className="component_collect_show">
        <div className="text">
          距离拿到测试卷还有最后一步，扫码进群，和各位家长一起备战吧！
        </div>
        <div className="school_qrcode">
          {qrcode_url ? (
            <img src={qrcode_url} alt="" />
          ) : (
            <img src={qrcode_groups[qrcode_group_index]} alt="" />
          )}
          <p>长按识别二维码加群领测试卷</p>
        </div>
      </div>
    );
  }
}

CollectShow.propTypes = {
  qrcode_url: PropTypes.string,
  qrcode_group_index: PropTypes.number,
};
