// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/logo_mini_new.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_xinya_header_menu{width:100%;height:50px;background-color:#fff;box-shadow:0 2px 4px 0 rgba(0,1,0,.03);display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}.comp_xinya_header_menu .xinya_logo{width:120px;margin-left:5px;height:50px;line-height:50px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0/100%}.comp_xinya_header_menu ul{display:-webkit-flex;display:flex}.comp_xinya_header_menu ul li a{position:relative;font-size:14px;padding:0 5px;margin-right:15px;display:block;text-decoration:none;line-height:50px}.comp_xinya_header_menu ul li a.selected{box-shadow:inset 0 -2px 0 #49c114;color:#49c114}.comp_xinya_header_menu ul li a.selected:after{content:\"\";height:2px;width:100%;background:#49c114;position:absolute;bottom:0;left:0}", ""]);
// Exports
module.exports = exports;
