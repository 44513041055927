import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  GalleryGroupContainer,
  GalleryItem,
} from "../utils/GalleryGroup/GalleryGroup.js";
import "../utils/GalleryGroup/GalleryGroup.scss";
import { showDialog } from "../utils/helpers";
import { GA } from "../utils/Env";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import Raven from "raven-js";
import "./BabyStage.scss";

const posterUrls = [
  {
    poster:
      "https://upload.fireflybaby.cn/article/6d46aacadff20b6e596cf33dd00029c5.jpg",
  },
  {
    poster:
      "https://upload.fireflybaby.cn/article/694f265f8d61249993cc40f278129c42.jpg",
  },
  {
    poster:
      "https://upload.fireflybaby.cn/article/3366f07195a3c85f0e86f37ea17db90f.jpg",
  },
  {
    poster:
      "https://upload.fireflybaby.cn/article/816bf1740627a7e4165f1fe2fb9fe532.jpg",
  },
];

class BabyStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePosterIndex: 0,
      current_choice: -1,
    };
  }

  componentDidMount() {}

  updateActiveIndex = (activePosterIndex) => {
    this.setState({
      activePosterIndex,
    });
  };

  setChoice = (current_choice) => {
    this.setState({
      current_choice,
    });
  };

  submitChoice = () => {
    const { current_choice } = this.state;
    if (current_choice >= 0) {
      this.toast = ToastManager.showLoading("提交中...");
      request({
        url: this.props.update_baby_stage_url,
        method: "POST",
        headers: csrfHeaders,
        data: { stage: current_choice },
      })
        .then((result) => {
          this.toast.cancel();
          window.location.href = result.data.redirect_url;
        })
        .catch((err) => {
          this.toast.cancel();
          Raven.captureException(err);
        });
    } else {
      ToastManager.showToastText("请先选择看法");
    }
  };

  render() {
    const { current_choice, activePosterIndex } = this.state;
    // const activePosterIndex = 0
    console.log(999, activePosterIndex, typeof activePosterIndex);

    const updateActiveIndex = this.updateActiveIndex;
    const choices = [
      "课内基础<br/>就够了",
      "对课内进行<br/>拓展",
      "拔高超常<br/>学习",
    ];

    return (
      <div className={"kb_baby_stage kb_baby_stage_" + activePosterIndex}>
        <div className="stage_gallery_container">
          <GalleryGroupContainer
            animation={{
              transition: "transform .3s ease-in-out",
            }}
            styles={"gallery_items"}
            defaultActiveIndex={activePosterIndex}
            scrollStep={0.5}
            indexDots={{
              containerStyles: "dots",
              dotUnActiveStyle: "in_active_dot",
              dotActiveStyle: "active_dot",
            }}
            activeIndexUpdater={updateActiveIndex}
            itemStyle="item_container"
            unActiveItemStyle="in_active"
            activeItemStyle="active"
            list={posterUrls}
          >
            {(poster) => {
              return (
                <div>
                  <img src={poster.poster}></img>
                  {activePosterIndex === 3 && (
                    <div className="baby_stage_update">
                      <h3>您对孩子的数学思维学习有什么需求？</h3>
                      <div className="baby_stage_choices">
                        <ul>
                          {choices.map((choice, idx) => {
                            return (
                              <li
                                key={idx}
                                className={
                                  current_choice == idx ? "active" : ""
                                }
                                onClick={this.setChoice.bind(this, idx)}
                                dangerouslySetInnerHTML={{ __html: choice }}
                              ></li>
                            );
                          })}
                        </ul>
                      </div>

                      <div
                        onClick={this.submitChoice.bind(this)}
                        className={
                          current_choice >= 0
                            ? "baby_stage_btn active"
                            : "baby_stage_btn"
                        }
                      >
                        <span onClick={this.submitChoice.bind(this)}>
                          进入测评
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </GalleryGroupContainer>
        </div>
      </div>
    );
  }
}

BabyStage.propTypes = {};

export default BabyStage;
