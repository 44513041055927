import React, { Component, PureComponent } from "react";

import icon_school from "ysx_subject/icon_school.png";
import icon_policy from "ysx_subject/icon_policy.png";

import "./Ysx.scss";

// 加油站·白皮书
const ysx_knowledge_activities = [
  {
    name: "2019幼升小(上海)名校加油站",
    description: "向51位名校家长取经，提升孩子上岸几率",
    url: "https://xinya.me/study_package_activity_details?id=262&ref=ysxzt",
  },
  {
    name: "2019幼升小白皮书精华视频版",
    description: "30分钟全面深入剖析魔都幼升小",
    url: "https://xinya.me/study_package_activity_details?id=287&ref=ysxzt",
  },
];

// 学校大全·幼升小政策
const school_policy_lists = [
  {
    name: "学校大全",
    description: "上海市小学详细介绍",
    url: "https://xinya.me/schools/home?ref=ysxzt",
    photo_url: `${icon_school}`,
  },
  {
    name: "幼升小政策",
    description: "2019义务招生政策",
    url:
      "https://mp.weixin.qq.com/mp/homepage?__biz=MzAxMTc0Mzc3OQ==&hid=1&sn=0d2c16b8ef113ee2121ce61e3fb26aeb&ref=ysxzt",
    photo_url: `${icon_policy}`,
  },
];

// 相关工具
const relative_tools = [
  {
    name: "择校测评",
    url: "https://xinya.me/mini_exams?exam_type=school_route&ref=ysxzt",
  },
  {
    name: "义务教育报名系统",
    url: "https://shrxbm.shmec.gov.cn/",
  },
  {
    name: "查对口",
    url:
      "http://act.shanghaicity.openservice.kankanews.com/iloveshanghai/2015/zhaosheng/indexnew.html",
  },
];

// 相关课程
const study_package_activities = [
  {
    name: "系列课程：数学思维训练课",
    description: "数学思维训练课大班、幼升小冲刺班",
    photo_url:
      "https://upload.fireflybaby.cn/study_package_activity/0beb2819fe0c266b3969f0cd4ca16b98.png?imageView2/1/w/350/h/155",
    url: "https://xinya.me/study_package_activity_details?id=263&ref=ysxzt",
  },
  {
    name: "幼升小面试真题汇编（数学思维篇）",
    description: "汇集2011~2018年上海数学思维真题与练习",
    photo_url:
      "https://upload.fireflybaby.cn/bargain_activity/2b84e3f111e791105f8a0ec9ca19391e.png?imageView2/1/w/350/h/155",
    url: "https://xinya.me/study_package_activity_details?id=250&ref=ysxzt",
  },
  {
    name: "系列课程：基础计算课",
    description: "20以内进退位加减法、100以内加减及速度闯关练习",
    photo_url:
      "https://upload.fireflybaby.cn/study_package_activity/0c60502e8353c543a87167e2a57da00a.png?imageView2/1/w/350/h/155",
    url: "https://xinya.me/study_package_activity_details?id=269&ref=ysxzt",
  },
  {
    name: "系列课程：分级学古诗",
    description: "42首精讲古诗、64首泛读古诗",
    photo_url:
      "https://upload.fireflybaby.cn/study_package_activity/d76f41782c669fe866dc1cc3fa594e9e.jpg?imageView2/1/w/350/h/155",
    url: "https://xinya.me/study_package_activity_details?id=266&ref=ysxzt",
  },
];

class HeaderMenu extends PureComponent {
  render() {
    return (
      <header className="xiya_head_box">
        <a
          className="xinya_name"
          href="/study_package_activities?ref=ysxzt"
        ></a>
        <div className="xinya_courses_experiences">
          <a
            className="xinya_courses"
            href="/study_package_activities?ref=ysxzt"
          >
            课程
          </a>
          <a className="xinya_experiences" href="/experiences?ref=ysxzt">
            经验分享
          </a>
        </div>
      </header>
    );
  }
}
class StationBox extends PureComponent {
  render() {
    let ysx_knowledge_activities = this.props.ysx_knowledge_activities;
    return (
      <div className="enterance_station_container">
        <div className="enterance_station_box">
          {ysx_knowledge_activities.map((ysx_activity, index) => (
            <a
              key={index}
              className="enterance_station"
              href={ysx_activity.url}
            >
              <h3>{ysx_activity.name}</h3>
              <div className="gas_station">
                <p>{ysx_activity.description}</p>
                <div className="entrance_sign">
                  <span>立即报名</span>
                </div>
              </div>
            </a>
          ))}
        </div>
        <div className="entrance_school_policy">
          {school_policy_lists.map((school_policy, index) => (
            <a key={index} href={school_policy.url}>
              <div className="school_policy">
                <h3>{school_policy.name}</h3>
                <p>{school_policy.description}</p>
              </div>
              <img src={school_policy.photo_url} alt="" />
            </a>
          ))}
        </div>
      </div>
    );
  }
}
class RelativeTool extends PureComponent {
  render() {
    let relative_tools = this.props.relative_tools;
    return (
      <div className="relative_tool_container">
        <div className="text">相关工具</div>
        <div className="entrance_tool">
          {relative_tools.map((relative_tool, index) => (
            <a key={index} href={relative_tool.url}>
              <p>{relative_tool.name}</p>
            </a>
          ))}
        </div>
      </div>
    );
  }
}
class RelativeCourse extends PureComponent {
  render() {
    let study_package_activities = this.props.study_package_activities;
    return (
      <div className="relative_tool_container">
        <div className="text">相关课程</div>
        <div className="entrance_course_container">
          {study_package_activities.map((study_package_activity, index) => (
            <div className="entrance_course" key={index}>
              <div className="box_shadow">
                <div className="number_course_list">
                  <a
                    href={study_package_activity.url}
                    style={{
                      backgroundImage:
                        "url(" + study_package_activity.photo_url + ")",
                    }}
                  >
                    <h2>{study_package_activity.name}</h2>
                    <p>{study_package_activity.description}</p>
                    <div className="activity_status">
                      <span>查看详情</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
class XinyaVersion extends PureComponent {
  render() {
    return <footer className="xinya_version">© 心芽学堂</footer>;
  }
}

class Ysx extends Component {
  componentDidMount() {
    const share_url = window.location.href;
    const share_title = "2019上海幼升小专题";
    const share_description = "择校/面试/备考——搞定幼升小，快来点我！";
    const share_image_url = this.props.share_image_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        link: share_url,
        title: share_title,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  render() {
    return (
      <div className="comp_ysx_subject">
        <HeaderMenu />
        <StationBox ysx_knowledge_activities={ysx_knowledge_activities} />
        <RelativeTool relative_tools={relative_tools} />
        <RelativeCourse study_package_activities={study_package_activities} />
        <XinyaVersion />
      </div>
    );
  }
}

export default Ysx;
