import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import iconTop from "new_experience/icon_top.png";
import virtualMoney from "new_experience/virtual_money.png";
import shareExperience from "new_experience/share_experience.png";
import { useSelector } from "../../utils/react-global-state";

const LeftFloatIcons = memo(function LeftFloatIcons({
  isShowTopIcon,
  activeSectionIndex,
  onScrollTop,
}) {
  const trialSecIndex = useSelector((state) => state.trialSectionIndex);
  const isExperienceBought = useSelector((state) => state.isExperienceBought);
  const showPosterModal = useSelector((state) => state.showPosterModal);
  const isTrail = useMemo(
    () => !isExperienceBought && trialSecIndex === activeSectionIndex,
    [activeSectionIndex, isExperienceBought, trialSecIndex]
  );
  return (
    <div
      className="right_float_icons"
      style={{ bottom: isTrail ? 140 : isExperienceBought ? 80 : 75 }}
    >
      <div
        className="acts_icons"
        style={{
          paddingLeft: !isExperienceBought ? 4 : 0,
          paddingRight: !isExperienceBought ? 4 : 0,
        }}
      >
        <div className="share_experience icon_common" onClick={showPosterModal}>
          <img src={shareExperience} alt="" />
          <span>分享经验</span>
        </div>

        <a className="virtual_money icon_common" href="/virtual_accounts">
          <img src={virtualMoney} alt="" />
          <span>奖学金</span>
        </a>
      </div>
      {isShowTopIcon && (
        <div className="icon_top icon_common" onClick={onScrollTop}>
          <img src={iconTop} alt="" />
          <span>顶部</span>
        </div>
      )}
    </div>
  );
});

LeftFloatIcons.propTypes = {
  isShowTopIcon: PropTypes.bool,
  activeSectionIndex: PropTypes.number,
  onScrollTop: PropTypes.func,
};

export default LeftFloatIcons;
