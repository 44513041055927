import React, { Component } from "react";
import home_logo_footer from "home_logo_footer.png";
import home_qrcode_wechat from "home_qrcode_wechat.jpg";
import icon_progress_pass from "c4/icon_progress_pass.png";
import "./Signin.scss";
import { ToastManager } from "../utils/Toast";

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      agreeFlag: false,
    };
  }

  login = (e) => {
    e.preventDefault();
    // ToastManager
    if (!this.state.agreeFlag) {
      const toast = ToastManager.showToastText(
        "请先勾选阅读并同意《用户隐私政策》与《用户服务协议》"
      );
      // const toast = ToastManager.showToastError("请填写完整后再提交");
      setTimeout(() => {
        toast.cancel();
      }, 3000);
      return;
    }

    var redirect_url = decodeURIComponent(this.props.redirect_url);
    function redirect_to() {
      if (redirect_url.includes("trial")) {
        XinYaNativeBridge.navigate({
          href: redirect_url,
          type: "after_login",
          target: "_self",
        });
      } else {
        XinYaNativeBridge.navigate({ href: redirect_url, type: "after_login" });
      }
    }
    if (this.props.is_native) {
      XinYaNativeBridge.wechatSignIn(redirect_to);
    } else {
      window.location.href = this.props.wechat_login_url;
    }
  };

  render() {
    return (
      <div className="comp_login_box">
        <div className="login_content">
          <div className="login_content_top">
            <img src={home_logo_footer} alt="" />
            <div className="text">
              <p>登录账号</p>
              <p>随时管理宝宝的课程与学习进度</p>
            </div>
          </div>
          <a className="wechat_login" onClick={this.login}>
            微信账号登录
          </a>

          <a
            className="phone_login_redirect"
            href={this.props.mobile_login_url}
            target="_self"
          >
            通过手机号登录
          </a>
        </div>

        <div className="user_privacy_policy">
          <div
            className="selected-service"
            style={{ backgroundColor: this.state.agreeFlag ? "#49c114" : "" }}
            onClick={() => {
              this.setState((preState) => ({ agreeFlag: !preState.agreeFlag }));
            }}
          >
            {this.state.agreeFlag ? <img src={icon_progress_pass}></img> : ""}
          </div>
          <div className="selected-services">
            <span
              onClick={() => {
                this.setState((preState) => ({
                  agreeFlag: !preState.agreeFlag,
                }));
              }}
            >
              我已阅读并同意
            </span>
            <a href="/privacy">《用户隐私政策》</a>与
            <a href="/agreement">《用户服务协议》</a>
          </div>
        </div>
      </div>
    );
  }
}
