import Raven from "raven-js";
import { request, csrfHeaders } from "../utils/request";
import { GA } from "../utils/Env.js";

// dataFormat: 主从Ids Object
export const logNewExperienceData = (dataFormat) => {
  const formatter = { ...dataFormat };
  let cached = {};
  return {
    logMain: (mainId, logData) => {
      try {
        console.warn("log main", mainId, logData);
        if (Object.keys(formatter).includes(mainId + "")) {
          cached.section_id = mainId;
          Object.keys(logData).forEach((k) => {
            cached[k] = logData[k];
          });
          if (!cached.memos) {
            cached.memos = [];
          }
        }
      } catch (e) {
        console.error(e);
        Raven.captureException(e);
      }
    },
    logSub: (mainId, subId, logData) => {
      try {
        console.warn("log sub", mainId, subId, logData);
        if (cached.section_id !== mainId) {
          console.warn("you need log main data first");
        } else {
          if (formatter[mainId].includes(Number(subId))) {
            if (cached.memos.length === 0) {
              cached.memos.push({
                memo_id: subId,
                ...logData,
              });
            } else {
              const memoIndex = cached.memos.findIndex((memo) => {
                return memo.memo_id === subId;
              });
              if (memoIndex !== -1) {
                Object.keys(logData).forEach((k) => {
                  cached.memos[memoIndex][k] = logData[k];
                });
              } else {
                cached.memos.push({
                  memo_id: subId,
                  ...logData,
                });
              }
            }
          } else {
            console.warn("you need add sub data ids in dataFormat first");
          }
        }
      } catch (e) {
        console.error(e);
        Raven.captureException(e);
      }
    },
    getLoggedData: () => ({ ...cached }),
    clear: () => {
      cached = {};
    },
  };
};

export const cacheData = () => {
  let cached = [];
  return {
    push: (obj) => {
      Object.keys(obj).forEach((k) => {
        if (cached[k] < obj[k] || !cached[k]) {
          cached[k] = obj[k];
        }
      });
    },
    replace: (obj) => {
      Object.keys(obj).forEach((k) => {
        cached[k] = obj[k];
      });
    },
    getAll: () => [...cached],
    getOne: (index) => cached[index],
    clear: () => {
      cached = null;
    },
  };
};

export const postLogData = (url, data) => {
  return request({
    url,
    method: "post",
    data,
    headers: csrfHeaders,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const GALog = {
  boughtTabPlayClicked: (sectionID) => {
    GA("NewExperienceBought", "TabPlayClicked", sectionID);
  },
  boughtTabPauseClicked: (sectionID) => {
    GA("NewExperienceBought", "TabPauseClicked", sectionID);
  },
  boughtSectionNavClicked: (sectionID, memoID) => {
    GA("NewExperienceBought", "SectionNavClicked", `${sectionID}-${memoID}`);
  },
  topScrollClicked: (sectionID) => {
    GA("NewExperienceBought", "TopScrollClicked", sectionID);
  },
  noneBoughtSectionNavClicked: (sectionID, memoID) => {
    GA(
      "NewExperienceNoneBought",
      "SectionNavClicked",
      `${sectionID}-${memoID}`
    );
  },
  noneBoughtTrialSectionPlay: (sectionID) => {
    GA("NewExperienceNoneBought", "TrialSectionPlay", sectionID);
  },
};
