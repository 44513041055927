/* eslint-disable react/prop-types */
import React from "react";
import {
  MyProvider,
  useSelector,
  useDispatch,
} from "../utils/react-global-state";
import NewExperienceStore from "./store.js";
import Raven from "raven-js";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import MediaLessonPlayer from "../utils/media_lesson_player/MediaLessonPlayer.jsx";
import PromotionActivityFooter from "../promotion_activities/PromotionActivityFooter.jsx";
import PromotionActivityPassBox from "../promotion_activities/PromotionActivityPassBox.jsx";

import LeftFloatIcons from "./components/LeftFloatIcons.jsx";
import { logNewExperienceData, postLogData, cacheData, GALog } from "./log.js";
import * as Components from "./components";

import "./NewExperience.scss";

const NewExperienceFooter = React.memo(function NewExperienceFooter({
  canPlay,
  getPlayerAction,
  audioList,
  createProgressPoints,
  activeSectionIndex,
  defaultFunc,
  onPlayerStateChange,
  onTimeUpdate,
  onEnded,
  // onTrialListenClick,
  onPlay,
  onPause,
  initFirstPlay,
  playingSectionIndex,
  ...props
}) {
  const bought = useSelector((state) => state.isExperienceBought);
  const experienceSections = useSelector((state) => state.experienceSections);
  const maxInviteUsersCount = useSelector((state) => state.maxInviteUsersCount);
  const posterCreations = useSelector((state) => state.posterCreations);
  const posterTemplates = useSelector((state) => state.posterTemplates);
  const isLimitedTimeOver = useSelector((state) => state.isLimitedTimeOver);
  const disablePopupPosterUrl = useSelector(
    (state) => state.disablePopupPosterUrl
  );
  const postersModal = Components.useMultipleSharePosters(
    posterCreations,
    posterTemplates,
    isLimitedTimeOver,
    maxInviteUsersCount,
    bought,
    disablePopupPosterUrl
  );
  const storeDispatch = useDispatch();

  storeDispatch(
    NewExperienceStore.actions.setShowPostersModal(postersModal.open)
  );

  return [
    canPlay && (
      <div
        className="experience_sections_audios_player"
        key="player"
        style={{ bottom: !bought ? 50 : 0 }}
      >
        <MediaLessonPlayer
          // isPlayerLocked={() => false}
          initFirstPlay={initFirstPlay}
          audioList={audioList}
          createProgressPoints={createProgressPoints}
          activeAudioIndex={playingSectionIndex}
          renderViewImg={defaultFunc}
          onPlayerStateChange={onPlayerStateChange}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnded}
          onPlay={onPlay}
          onPause={onPause}
          showProgressBar={true}
          renderProgressBar={(props) => (
            <Components.PlayerProgressBar
              {...props}
              sectionName={experienceSections[playingSectionIndex].name}
            />
          )}
          debug={false}
        >
          {getPlayerAction}
        </MediaLessonPlayer>
      </div>
    ),
    bought && !!props.pay_record && (
      <PromotionActivityPassBox {...props} mode="none" />
    ),
    !bought && (
      <div className="payment_footer" key="payment">
        <PromotionActivityFooter
          {...props}
          // onTrialListenClick={onTrialListenClick}
          free="normal"
          mode={PromotionActivityFooter.ModeEnum.live}
          setModalOpen={postersModal.open}
          isLimitedTimeOver={isLimitedTimeOver}
          max_invite_users_count={maxInviteUsersCount}
        />
      </div>
    ),
  ];
});

const ExperienceSectionsSlider = React.memo(function ExperienceSectionsSlider(
  props
) {
  const trialSectionIndex = useSelector((state) => state.trialSectionIndex);
  const experienceSections = useSelector((state) => state.experienceSections);
  const bought = useSelector((state) => state.isExperienceBought);
  const {
    sectionClick,
    audioPlay,
    audiosPlayingState,
    sectionsListenedProgress,
    sectionListenedUpdate,
    gatherSecsDuration,
    onTabPlayIconClick,
    triggerMemosNavColumnPlay,
  } = props;
  const getSecPlayerCurrentProgress = (index) => {
    return sectionsListenedProgress[index];
  };

  const setSectionClassStyle = (first, last) =>
    `${first ? "pad_left" : ""} ${last ? "pad_right" : ""}`;
  return (
    <Tabs
      styles="experience_sections"
      defaultAction={sectionClick}
      defaultActiveIndex={0}
      animatedTabTitle={true}
    >
      {experienceSections &&
        experienceSections.map((section, index) => (
          <TabPane
            tab={
              <Components.ConnectedExperienceSectionTab
                onTabPlayIconClick={onTabPlayIconClick}
                sectionListenedUpdate={sectionListenedUpdate}
                gatherSecsDuration={gatherSecsDuration}
                playerCurrentProgress={getSecPlayerCurrentProgress(index)}
                key={index}
                sectionIndex={index}
                isAudioPlaying={audiosPlayingState[index]}
                isShowTrialTag={!bought && trialSectionIndex === index}
              />
            }
            styles={setSectionClassStyle(
              index === 0,
              index === experienceSections.length - 1
            )}
            activeStyle="active"
            key={index}
          >
            <Components.SectionContent
              triggerMemosNavColumnPlay={triggerMemosNavColumnPlay}
              audioPlay={audioPlay}
              sectionIndex={index}
            />
          </TabPane>
        ))}
    </Tabs>
  );
});

class NewExperiences extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log(props);
    this.audioList = props.experience_sections.map((sec) => ({
      url: sec.audio,
      isLoaded: false,
      duration: 0,
    }));

    this.state = {
      activeSectionIndex: 0,
      playingSectionIndex: 0,
      trialSectionIndex: props.experience_sections.findIndex(
        (sec) => sec.is_trail
      ),
      audiosPlayingState: new Array(this.audioList.length).fill(false),
      playerCurrentPercent: 0,
    };

    this.ListenedProgresses = cacheData();
    this.gatherSecsDuration = cacheData();
    this.storeLogData = this.logUserPlayAudioData(props.experience_sections);
    this.watchMemoListenChange.default = {
      pause: [],
      play: [],
    };
    this.storeLogMemoData = this.watchMemoListenChange();
  }

  logUserPlayAudioData = (experienceSections) => {
    let sectionMemosIdsMap = {};

    sectionMemosIdsMap = experienceSections.reduce((preSection, section) => {
      const memoIds = [];
      if (section.memos && section.memos.length) {
        section.memos.forEach((memo) => {
          memoIds.push(memo.id);
        });
      }
      return { ...preSection, [section.id]: memoIds };
    }, {});
    // console.log(sectionMemosIdsMap);
    return logNewExperienceData(sectionMemosIdsMap);
  };

  watchMemoListenChange = () => {
    let cached = {};

    // 只记录每个memo首次播放的时间
    let nowMemoPlayLoggedMap = [];
    return {
      logMemoAction: (currentTime, state) => {
        try {
          const { experience_sections: experienceSections } = this.props;
          const { playingSectionIndex } = this.state;
          const { memos } = experienceSections[playingSectionIndex];
          if (memos && memos.length) {
            memos.forEach((memo, index) => {
              if (
                memo.start_time <= Math.floor(currentTime) &&
                Math.floor(currentTime) <= memo.end_time &&
                memo.id
              ) {
                const realCurrent = Math.round(currentTime);
                if (cached[memo.id] == undefined) {
                  cached[memo.id] = {
                    pause: [],
                    play: [],
                  };
                }
                if (!nowMemoPlayLoggedMap[index]) {
                  cached[memo.id].play.push(realCurrent);
                  nowMemoPlayLoggedMap.fill(false);
                  nowMemoPlayLoggedMap[index] = true;
                } else {
                  if (state === "PAUSE") {
                    cached[memo.id].pause.push(realCurrent);
                  }
                }
              }
            });
          }
        } catch (e) {
          Raven.captureException(e);
        }
      },
      getMemoLogData: (memoId) => cached[memoId],
      clearMemoLogData: (memoId) => {
        cached[memoId] = null;
        nowMemoPlayLoggedMap = [];
      },
      clearAll: () => {
        cached = null;
      },
    };
  };

  postInterActionData = () => {
    // 如果此时onTimeUpdate 的lastPlayTime不为 null，
    // 那么此值是上一个section播放的最后时间
    // log data
    const { experience_sections: experienceSections } = this.props;
    const { playingSectionIndex } = this.state;
    const section = experienceSections[playingSectionIndex];
    const sectionId = section.id;
    const memos = section.memos;
    const listenedProgress = Math.round(this.onTimeUpdate.lastPlayTime || 0);
    return {
      // 上一个section播放的完整数据上报
      lastAll: (needClear = true) => {
        if (this.sectionLogId == null) return;
        try {
          this.storeLogData.logMain(sectionId, {
            end_time: Number((Date.now() / 1000).toFixed(0)),
            delta_time: listenedProgress,
            section_log_id: this.sectionLogId,
          });

          if (memos && memos.length) {
            memos.forEach((memo) => {
              this.storeLogData.logSub(
                sectionId,
                memo.id,
                this.storeLogMemoData.getMemoLogData(memo.id) ||
                  this.watchMemoListenChange.default
              );
              // 清空该次要点的播放记录
              needClear && this.storeLogMemoData.clearMemoLogData(memo.id);
            });
          }
          console.warn(this.storeLogData.getLoggedData());
          postLogData(this.props.section_log_url, {
            user_id: this.props.user.id,
            section_id: sectionId,
            ...this.storeLogData.getLoggedData(),
          });
          // 清空该次section播放记录
          needClear && this.storeLogData.clear();
        } catch (e) {
          Raven.captureException(e);
        }
      },
      start: () => {
        // section开始播放数据上报
        postLogData(this.props.section_log_url, {
          user_id: this.props.user.id,
          start_time: Number((Date.now() / 1000).toFixed(0)),
          section_id: sectionId,
        }).then((data) => {
          if (data) {
            this.sectionLogId = data.log_id;
          }
        });
      },
    };
  };

  manualSetSectionIndex = (sectionIndex) => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          activeSectionIndex: sectionIndex,
          playingSectionIndex: sectionIndex,
        },
        () => {
          // 更新section 后触发点击section Tab
          $(".experience_section")
            .eq(this.state.playingSectionIndex)
            .trigger("click");
          resolve();
        }
      );
    });
  };

  triggerMemosNavColumnPlay = (
    sectionIndex,
    clickedMemoNavIndex,
    playStartTime
  ) => {
    const memoNavEle = document.getElementById(
      `memo_content_container_${clickedMemoNavIndex}`
    );
    const memoPlay = () => {
      this.audioPlay(playStartTime);
      memoNavEle.scrollIntoView(true);
      if (clickedMemoNavIndex === 0) {
        window.scrollBy(0, -20);
      }
    };
    try {
      if (memoNavEle) {
        const isPlayCurrentSection =
          this.state.playingSectionIndex === sectionIndex;

        if (isPlayCurrentSection) {
          memoPlay();
        } else {
          this.manualSetSectionIndex(sectionIndex)
            .then(memoPlay)
            .catch((e) => {
              console.log(e);
              Raven.captureException(e);
            });
        }
      }
    } catch (e) {
      console.error(e);
      Raven.captureException(e);
    }
  };

  checkLocked = (clickedIndex) => {
    return (
      !this.props.experience.bought &&
      clickedIndex !== this.state.trialSectionIndex
    );
  };

  isTrail = () => {
    const { bought } = this.props.experience;
    const { trialSectionIndex, activeSectionIndex } = this.state;
    return !bought && trialSectionIndex === activeSectionIndex;
  };

  canPlay = () => {
    const { trialSectionIndex, activeSectionIndex } = this.state;
    return (
      this.props.experience.bought || activeSectionIndex === trialSectionIndex
    );
  };

  onTabPlayIconClick = (index) => {
    const { playingSectionIndex, audiosPlayingState } = this.state;

    if (playingSectionIndex === index) {
      // 点击的是正在播放的section
      if ($("#btn_player_click")) {
        $("#btn_player_click").trigger("click");
      }
      // 点击播放 点击暂停
      if (audiosPlayingState.includes(true)) {
        this.updateAudiosPlayingState();
        GALog.boughtTabPauseClicked(
          this.props.experience_sections[this.state.playingSectionIndex].id
        );
      } else {
        this.updateAudiosPlayingState(Number(index));
        GALog.boughtTabPlayClicked(this.props.experience_sections[index].id);
      }
    } else {
      this.updateAudiosPlayingState(Number(index));
      GALog.boughtTabPlayClicked(this.props.experience_sections[index].id);
    }
  };

  isAudioPlaying = (index) => {
    const { activeSectionIndex, audiosPlayingState } = this.state;
    return (
      activeSectionIndex === index &&
      audiosPlayingState[activeSectionIndex] &&
      this.canPlay()
    );
  };

  sectionClick = (e, activeSectionIndex) => {
    this.setState({
      activeSectionIndex: Number(activeSectionIndex),
    });
  };

  // onTrialListenClick = () => {
  //   const { trialSectionIndex } = this.state;
  //   if (trialSectionIndex === -1 || this.props.experience.bought) {
  //     return;
  //   }
  //   this.manualSetSectionIndex(trialSectionIndex);
  // };

  componentDidUpdate(_, prevState) {
    // 触发试听播放
    if (prevState.activeSectionIndex !== this.state.activeSectionIndex) {
      if (typeof this.audioPlay === "function" && this.isTrail()) {
        this.audioPlay(0);
      }
      this.setState({
        playerCurrentPercent: 0,
      });
    }
  }

  componentDidMount() {
    const { share_img, share_title, share_desc, share_url } = this.props;
    try {
      window.wx.ready(function () {
        function on_share_success() {}
        var sharedJson = {
          title: share_title,
          link: share_url,
          desc: share_desc,
          imgUrl: share_img,
          success: on_share_success,
        };
        window.wx.onMenuShareTimeline(sharedJson);
        window.wx.onMenuShareAppMessage(sharedJson);
      });
    } catch (e) {
      Raven.captureException(e);
    }

    this.onShowTopIcon();
  }

  // TODO: replace componentWillUpdate
  componentWillUpdate(_, nextState) {
    const { playerCurrentPercent, playingSectionIndex } = this.state;
    if (nextState.playingSectionIndex !== playingSectionIndex) {
      // 发送上一个section播放的完整数据
      // 如果上一个section为可播放状态
      if (this.canPlay()) {
        this.postInterActionData().lastAll();
      }
      // this.lastSectionRealPlayPercent = playerCurrentPercent;
      // 保存上一个播放进度
      this.ListenedProgresses.replace({
        [playingSectionIndex]: playerCurrentPercent,
      });
      // 设置下一个section 为没有播放过的状态
      this.onPlayerStateChange.firstPlayed = false;
    }
  }

  componentWillUnmount() {
    this.gatherSecsDuration.clear();
    this.ListenedProgresses.clear();
    this.storeLogData.clear();
    this.storeLogMemoData.clearAll();
    window.onscroll = null;
  }

  createProgressPoints = () => {
    const playingSectionIndex = this.state.playingSectionIndex;
    const counter = [];
    const memos = this.props.experience_sections[playingSectionIndex].memos;
    if (memos.length) {
      const audioEndTime = memos[memos.length - 1].end_time;
      if (!audioEndTime) {
        return counter;
      }
      memos.forEach((memo) => {
        counter.push({
          left: Math.round(100 * (memo.end_time / audioEndTime)) + "%",
        });
      });
    }

    try {
      if (
        counter[counter.length - 1] &&
        counter[counter.length - 1].left === "100%"
      ) {
        counter.pop();
      }
    } catch (e) {
      console.log(e);
    }

    return counter;
  };

  initFirstPlay = (playingSectionIndex) => {
    const duration = this.gatherSecsDuration.getOne(playingSectionIndex);
    // console.log(this.ListenedProgresses.getAll());
    const playTime =
      ((this.ListenedProgresses.getOne(playingSectionIndex) || 0) / 100) *
        (duration || 0) ||
      this.props.experience_sections[playingSectionIndex].max_delta_time + 1 ||
      0;
    return playTime <= duration - 3 ? playTime : 0;
  };

  onPlayerStateChange = (state, playerCtx) => {
    console.log("onPlayerStateChange: ", state);
    const { activeSectionIndex, playingSectionIndex } = this.state;
    // 首次播放发送log
    if (!this.onPlayerStateChange.firstPlayed && state === "PLAY") {
      this.onPlayerStateChange.firstPlayed = true;
      this.updateAudiosPlayingState(activeSectionIndex);
      this.postInterActionData().start();

      // 试听ga
      if (this.isTrail()) {
        GALog.noneBoughtTrialSectionPlay(this.state.playingSectionIndex);
      }
    }
    if (state === "STOP") {
      this.onPlayerStateChange.firstPlayed = false;
    }

    // 发送上一个section播放的完整数据
    // 如果上一个section为可播放状态
    // 此为最后一个section， 所以componentWillUpdate不会触发
    if (
      state === "STOP" &&
      playingSectionIndex === this.props.experience_sections.length - 1
    ) {
      this.postInterActionData().lastAll();
      this.ListenedProgresses.push({
        [playingSectionIndex]: 100,
      });
    }

    if (state === "PAUSE") {
      this.updateAudiosPlayingState();
    }
    if (state === "PLAY") {
      this.updateAudiosPlayingState(this.state.playingSectionIndex);
    }
  };

  onTimeUpdate = (playerCtx) => {
    const { playingSectionIndex } = this.state;

    this.onTimeUpdate.lastPlayTime = playerCtx.currentTime;
    this.onTimeUpdate.totalTime = playerCtx.totalTime;

    const playerCurrentPercent = Number(
      ((playerCtx.currentTime * 100) / playerCtx.totalTime || 0).toFixed(2)
    );

    this.ListenedProgresses.replace({
      [playingSectionIndex]: playerCurrentPercent,
    });

    this.setState({
      playerCurrentPercent,
    });

    // 发送上一个section memo播放的完整数据
    const memos = this.props.experience_sections[this.state.playingSectionIndex]
      .memos;
    if (
      memos &&
      memos.length &&
      Math.floor(playerCtx.currentTime) < Math.floor(playerCtx.totalTime)
    ) {
      memos.forEach((memo) => {
        if (
          memo.end_time <= Math.floor(playerCtx.currentTime) &&
          Math.floor(playerCtx.currentTime) < memo.end_time + 1
        ) {
          this.postInterActionData().lastAll(false);
        }
      });
    }
  };

  onEnded = () => {
    const { playingSectionIndex } = this.state;
    const audioListLength = this.audioList.length;
    const nextAudioIndex =
      playingSectionIndex >= audioListLength - 1
        ? audioListLength - 1
        : playingSectionIndex + 1;

    // 下一个是未购买或者不是试听
    if (this.checkLocked(nextAudioIndex)) {
      return;
    }
    this.manualSetSectionIndex(nextAudioIndex);
  };

  updateAudiosPlayingState = (audioIndex) => {
    const { audiosPlayingState } = this.state;
    audiosPlayingState.fill(false);
    if (audioIndex == null) {
      this.setState({
        audiosPlayingState: [...audiosPlayingState],
      });

      return;
    }

    // 已经是playing return
    if (audiosPlayingState[audioIndex]) {
      return;
    }

    audiosPlayingState[audioIndex] = true;
    this.setState({
      playingSectionIndex: audioIndex,
      audiosPlayingState: [...audiosPlayingState],
    });
  };

  getPlayerAction = ({ play, pause }) => {
    // forceUpdate
    this.setState(() => ({
      forceUpdate: Date.now(),
    }));
    this.audioPlay = play;
    this.audioPause = pause;
  };

  onPlay = (playerCtx) => {
    console.log("onPlay", playerCtx.currentTime);
    this.storeLogMemoData.logMemoAction(playerCtx.currentTime, "PLAY");
  };

  onPause = (playerCtx) => {
    console.log("onPause", playerCtx.currentTime);
    this.storeLogMemoData.logMemoAction(playerCtx.currentTime, "PAUSE");
  };

  onShowTopIcon = () => {
    let lastWindowOnScroll = () => {};
    if (typeof window.onscroll === "function") {
      lastWindowOnScroll = window.onscroll;
    }
    window.onscroll = () => {
      try {
        // 讲义出现在视口的距离
        const memosContentViewTop =
          $(".memo_content_container").offset().top - $(window).scrollTop();
        if (memosContentViewTop <= 0) {
          this.setState({
            isShowTopIcon: true,
          });
        } else {
          this.setState({
            isShowTopIcon: false,
          });
        }
        lastWindowOnScroll();
      } catch (e) {
        Raven.captureException(e);
      }
    };
  };

  onScrollTop = () => {
    GALog.topScrollClicked(
      this.props.experience_sections[this.state.activeSectionIndex].id
    );
    $(window).scrollTop(0);
  };

  render() {
    const {
      activeSectionIndex,
      playingSectionIndex,
      audiosPlayingState,
      playerCurrentPercent,
      isShowTopIcon,
    } = this.state;
    const { bought } = this.props.experience;
    const canPlay = this.canPlay();
    const isTrail = this.isTrail();
    return (
      <div
        className="comp_new_experiences"
        style={{
          paddingBottom: isTrail ? 115 : bought ? 65 : 50,
        }}
      >
        <Components.ExperienceBannerInfo />
        <ExperienceSectionsSlider
          onTabPlayIconClick={this.onTabPlayIconClick}
          sectionClick={this.sectionClick}
          audioPlay={this.audioPlay}
          audiosPlayingState={audiosPlayingState}
          triggerMemosNavColumnPlay={this.triggerMemosNavColumnPlay}
          sectionsListenedProgress={this.ListenedProgresses.getAll()}
          sectionListenedUpdate={this.ListenedProgresses}
          gatherSecsDuration={this.gatherSecsDuration}
          playerCurrentPercent={playerCurrentPercent}
        />
        <NewExperienceFooter
          initFirstPlay={this.initFirstPlay}
          canPlay={canPlay}
          audioList={this.audioList}
          createProgressPoints={this.createProgressPoints}
          activeSectionIndex={activeSectionIndex}
          playingSectionIndex={playingSectionIndex}
          defaultFunc={Function.prototype}
          onPlayerStateChange={this.onPlayerStateChange}
          onTimeUpdate={this.onTimeUpdate}
          onEnded={this.onEnded}
          getPlayerAction={this.getPlayerAction}
          // onTrialListenClick={this.onTrialListenClick}
          onPlay={this.onPlay}
          onPause={this.onPause}
          {...this.props}
        />
        <LeftFloatIcons
          isShowTopIcon={isShowTopIcon}
          activeSectionIndex={activeSectionIndex}
          onScrollTop={this.onScrollTop}
        />
      </div>
    );
  }
}

const NewExperiencesProvider = (props) => {
  const experienceGuest = props.experience_guest;
  const isExperienceBought = props.experience.bought;
  const trialSectionIndex = props.experience_sections.findIndex(
    (sec) => sec.is_trail
  );
  const checkIsTrialSection = (sectionIndex) => {
    return !isExperienceBought && trialSectionIndex === sectionIndex;
  };
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * max) + min;
  };
  const triggerSharePoster = () => {
    if ($("#btn-free-listen")) {
      $("#btn-free-listen").trigger("click");
    }
  };

  return (
    <MyProvider
      store={NewExperienceStore.build({
        trialSectionIndex,
        isExperienceBought,
        disablePopupPosterUrl: props.disable_popup_poster_url,
        isLimitedTimeOver: !props.has_valid_user_ecoupon,
        posterTemplates: props.poster_templates,
        posterCreations: props.poster_creations,
        experienceMerit: props.experience.description,
        experienceName: props.experience.name,
        experienceGuestName: experienceGuest.name,
        experienceGuestAvatar: experienceGuest.avatar,
        experienceGuestInfo: experienceGuest.info,
        experienceGuestBrief: experienceGuest.brief,
        experienceSections: props.experience_sections,
        maxInviteUsersCount: props.max_invite_users_count,

        showPosterModal: () => {},
        getRandomNumber,
        triggerSharePoster,
        checkIsTrialSection,
      })}
    >
      <NewExperiences {...props} />
    </MyProvider>
  );
};

export default NewExperiencesProvider;
