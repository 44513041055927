import React, { useEffect, useState } from "react";
// import AddLogModalDialog from "./AddLogModalDialog";
import { request, csrfHeaders } from "../../utils/request";
import { ToastManager } from "../../utils/Toast";
import { Session, CheckedInput, NewSession } from "../../../admin-utils/Select";
import styled from "styled-components";

const StyleFollowupLog = styled.div`
  display: inline-block;
  margin-top: -4px;
  border-radius: 30px;
  font-size: 14px;
  color: white;
  background: #3c8af0;
  cursor: pointer;
  padding: 4px 6px;
  text-align: center;
`;

const CourseSetting = (props) => {
  console.log("CourseSetting_props", props);
  // console.log("MarkDeal_props: ", props);
  // const [start_time, setStartTime] = useState(props.start_time || "");
  const [learn_minute, setLearnMinute] = useState(props.minutesPerTime || 0);
  const [reminder_hour, setReminderHour] = useState(props.reminderHour || 0);
  const [days_in_week, setDaysInWeek] = useState(props.daysInWeek || []);
  const [unlock_mode, setUnlockMode] = useState(props.unlockMode || "");
  const [remark, setRemark] = useState(props.remark || "");

  const ajaxRequest = (url) => {
    const toast = ToastManager.showLoading("拼命加载中...");
    let { id } = props.params;
    let data = {};
    if (props.isThinkin) {
      let days_in_weeks = [];
      timeWeeks.map((it, id) => {
        it.selected && days_in_weeks.push(it.id);
      });
      data = {
        id,
        minutes_per_time: learn_minute || 0,
        days_in_week: days_in_weeks,
        reminder_hour,
        remark,
        unlock_mode: unlock_mode || 0,
      };
    } else {
      data = {
        id,
        reminder_hour,
        remark,
        unlock_mode: unlock_mode || 0,
      };
    }
    // console.log(url, "[[[[[[[[[[[[", data);
    // props.isThinkin
    // data[]
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      params: data,
    })
      .then((resp) => {
        toast.cancel();
        // const data = resp.data;
        // console.log("data: ", data);
        // console.log("正确返回");
        // setBabyExtraInfo({
        //   user: data.user,
        //   baby: data.baby,
        // });
        // setAddFollowUpLogUrl(data.add_follow_up_log_url);
        // setGrades(data.grades);
        // setSourcesReasons(data.source_reasons);
        // setCalculationBasics(data.calculation_basics);
        // setLiteracies(data.literacies);
        // setTeachers(data.teachers);
        // setRequestSuccess(true);
      })
      .catch((err) => {
        toast.cancel();
        // console.log("错误返回");
        // console.log(err);
      });
  };
  const reminberTimesOPtions = [
    { id: -1, title: "不提醒" },
    { id: 0, title: "00:00" },
    { id: 1, title: "01:00" },
    { id: 2, title: "02:00" },
    { id: 3, title: "03:00" },
    { id: 4, title: "04:00" },
    { id: 5, title: "05:00" },
    { id: 6, title: "06:00" },
    { id: 7, title: "07:00" },
    { id: 8, title: "08:00" },
    { id: 9, title: "09:00" },
    { id: 10, title: "10:00" },
    { id: 11, title: "11:00" },
    { id: 12, title: "12:00" },
    { id: 13, title: "13:00" },
    { id: 14, title: "14:00" },
    { id: 15, title: "15:00" },
    { id: 16, title: "16:00" },
    { id: 17, title: "17:00" },
    { id: 18, title: "18:00" },
    { id: 19, title: "19:00" },
    { id: 20, title: "20:00" },
    { id: 21, title: "21:00" },
    { id: 22, title: "22:00" },
    { id: 23, title: "23:00" },
  ];
  const timesOPtions = [15, 30, 45, 60, 120];
  const lockType = [
    { id: 1, name: "全部解锁" },
    { id: 2, name: "其他" },
    // { id: 3, name: "部分解锁" },
    // { id: 4, name: "不解锁" },
    // { id: 5, name: "从不解锁" },
  ];
  const [timeWeeks, setTimeWeeks] = useState([
    { id: 1, name: "周一", selected: false },
    { id: 2, name: "周二", selected: false },
    { id: 3, name: "周三", selected: false },
    { id: 4, name: "周四", selected: false },
    { id: 5, name: "周五", selected: false },
    { id: 6, name: "周六", selected: false },
    { id: 0, name: "周日", selected: false },
  ]);
  const handleClicks = (i) => {
    if (i) {
      timeWeeks[i - 1].selected = !timeWeeks[i - 1].selected;
    } else {
      timeWeeks[6].selected = !timeWeeks[6].selected;
    }

    setTimeWeeks([...timeWeeks]);
    // console.log(i, "");
    // let flag = false;
    // days_in_week.map((it, id) => {
    //   if (it === i) {
    //     flag = true;
    //     days_in_week.splice(id, 1);
    //   }
    // });
    // if (!flag) {
    //   days_in_week.push(i);
    // }
    // setDaysInWeek(days_in_week);
    // let flag = days_in_week.filfter();
  };
  useEffect(() => {
    // timeWeeks.map((item, i) => {
    //   item.selected = false;
    // });
    days_in_week.map((i, d) => {
      if (i) {
        timeWeeks[i - 1].selected = true;
      } else {
        timeWeeks[6].selected = true;
      }
    });
    setTimeWeeks([...timeWeeks]);
    console.log("000000000", timeWeeks);
  }, []);

  const handleAlertTimeInput = (e) => {
    const ccLogTime = e.target.value;
    console.log("1234", ccLogTime);
    setStartTime(ccLogTime);
  };

  const handleClickDatetimepickerIcon = (e) => {
    e.preventDefault();
    $("#datetimepicker1").datetimepicker({
      locale: "zh-cn",
    });
  };

  // useEffect(() => {
  //   console.log(timeWeek, "------------------------------", days_in_week);
  //   timeWeek.length > 0 &&
  //     timeWeek.map((item, ind) => {
  //       // console.log("itrm", item);
  //       if (days_in_week && days_in_week[ind]) timeWeek[ind].selected = true;
  //     });
  //   // console.log(timeWeek, "------------------------------", start_time);
  // }, [timeWeek, days_in_week]);
  return (
    <React.Fragment>
      <div style={{ textAlign: "center", marginBottom: 20 }}>课程设置</div>
      {/* <div className="form-group">
        <label className="col-sm-2 control-label">上课日期</label>
        <div className="col-sm-4">
          <input
            className="form-control datetimepicker col-sm-8"
            id="start_time"
            placeholder="选择开课日期"
          ></input>
        </div>
      </div> */}
      {props.isThinkin && (
        <div className="form-group">
          <label className="col-sm-2 control-label">上课频次</label>
          <div className="col-sm-2">
            <select
              className="form-control"
              onChange={(e) => {
                const value = e.target.value;
                setLearnMinute(value);
                //   setOption && setOption(value);
              }}
              value={learn_minute || ""}
            >
              <option value="">选择一次几分钟</option>
              {timesOPtions.map((item, idx) => {
                return (
                  <option value={item} key={"time" + idx}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            className="col-sm-5"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {timeWeeks.map((option, index) => (
              <div
                key={"timeWeeks" + index}
                data-secected={option.selected}
                style={{
                  borderRadius: 5,
                  padding: "2px 5px",
                  color: option.selected ? "#66D167" : "#333",
                  border: option.selected
                    ? "1px solid #66D167"
                    : "1px solid #333",
                }}
                onClick={() => {
                  handleClicks(option.id);
                }}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="form-group">
        <label className="col-sm-2 control-label">提醒时间</label>
        <div className="col-sm-8">
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.target.value;
              setReminderHour(value);
              //   setOption && setOption(value);
            }}
            value={reminder_hour || 0}
          >
            <option value={-1}>不提醒</option>
            {reminberTimesOPtions.map((item, idx) => {
              return (
                <option value={item.id} key={"time" + idx}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-2 control-label">解锁模式</label>
        <div className="col-sm-8">
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.target.value;
              setUnlockMode(value);
              //   setOption && setOption(value);
            }}
            value={unlock_mode || ""}
          >
            <option value="">请选择解锁模式</option>
            {lockType.map((item, idx) => {
              return (
                <option value={item.id} key={"lock" + idx}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-2 control-label">原因</label>
        <div className="col-sm-8">
          <textarea
            className="form-control editable"
            // required
            placeholder="填写原因"
            value={remark}
            onChange={(e) => {
              //   console.log(e.target.value);
              let value = e.target.value;
              setRemark(value);
            }}
            rows="2"
            cols="40"
          ></textarea>
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-4 col-sm-offset-2">
          <button
            className="btn btn-default"
            type="submit"
            id="order_submittion2"
            onClick={() => {
              ajaxRequest(
                props.submit_url ||
                  "/admin/study_package_activity_orders/create_user_operation"
              );
            }}
          >
            提交
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseSetting;
